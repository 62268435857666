import { Container, Grid, Image, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'


function NETSNonGLP() {
    return (
        <>
            <div>
                <Container className='container-main'>
                    <h1>NETS: Non-GLP Studies</h1>
			        <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>What are non-GLP studies and why are they important in drug development?</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>
                                Good Laboratory Practices (GLP) is a certification issued by regulatory bodies to ensure that the data submitted to them has been obtained using the proper procedures. GLP certification also ensures that the protocols used were thoroughly documented such that any scientist could follow the protocol and replicate the results. Non-GLP practices are not ‘bad’ or ‘inferior’ – rather, they lack the extensive documentation needed to qualify for GLP certification. For the early phases of pre-clinical research it is unnecessary to adhere to these requirements. Carrying out initial non-GLP studies in the areas of{' '} 
                                <Link to='/resources-and-information/nets/acute' rel='noopener noreferrer'>
                                    toxicology 
                                </Link>
                                {' '}and{' '} 
                                <Link to='/resources-and-information/nets/in-vitro' rel='noopener noreferrer'>
                                    pharmacology 
                                </Link>
                                {' '}will preserve both time and funding.
                            </p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Testimonials: How we did it</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>Content not yet available for this section.</p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Relevant Publications</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            {/* <div className='inline-flex mb-15'>
                                <Image src='/icons/guide.png' className='icon-width-16px mr-8' style={{ height: '16px', top: '8px' }} />
                                <p>
                                    <a
                                        href='https://www.fda.gov/downloads/RegulatoryInformation/Guidances/ucm129524.pdf'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        FDA Guidance: Nonclinical Safety Studies for the Conduct of Human Clinical Trials
                                    </a>
                                </p>
                            </div> */}
                            <div className='inline-flex mb-15'>
                                <Image src='/icons/guide.png' className='icon-width-16px mr-8' style={{ height: '16px', top: '8px' }} />
                                <p>
                                    <a
                                        href='/pdf/nets/Guidance_For_Industry.pdf'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        FDA Guidance for Industry: Good Laboratory Practice Questions & Answers
                                    </a>
                                </p>
                            </div>
                            <div className='inline-flex mb-15'>
                                <Image src='/icons/guide.png' className='icon-width-16px mr-8' style={{ height: '16px', top: '8px' }} />
                                <p>
                                    <a
                                        href='https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfcfr/CFRSearch.cfm?CFRPart=58'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        FDA Regulation: Regulation Relating to Good Clinical Practice for Nonclinical Laboratory Studies
                                    </a>
                                </p>
                            </div>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Related Tools</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            {/* <a
                                href='https://www.fda.gov/Drugs/GuidanceComplianceRegulatoryInformation/Guidances/ucm065014.htm'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />FDA Guidance: Pharmacology & Toxicology Guidances</Button>
                            </a> */}
                            <p>Content not yet available for this section.</p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Link to='/resources-and-information/nets/full-view' rel='noopener noreferrer' className='link-button inline-block'><Button className='button-orange-500'><Image src='/icons/link-internal-white.png' className='icon-width-16px' style={{ transform: 'scaleX(-1)' }} />Back to NETS Map full view</Button></Link>
                </Container>
            </div>
        </>
    )
}

export default NETSNonGLP