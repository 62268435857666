import React from 'react'
import { Container, Grid } from 'semantic-ui-react'

function SamSueAnnouncement() {
	return (
		<>
			<Container className='container-main'>
				<h1>
					Sharon Terry Welcomes Susan McClure and Sam Solomon to Genetic Alliance
				</h1>
				<Grid columns={1} padded>
					<Grid.Row>
						<Grid.Column>
							<p>
								Genetic Alliance is delighted to announce that Susan McClure and Sam
								Solomon have joined the Genetic Alliance staff. With a variety of
								skills, they bring a breadth and depth of expertise critical to Genetic
								Alliance’s mission, growth, and communications.
							</p>

							<p>
								Sue is the Genetic Alliance’s first VP of Outreach and Growth. She is
								uniquely suited to assume these reins at Genetic Alliance because of her
								long history serving the public by helping them to learn about health
								and genomics from a people-centered place. Sue is a two-time breast
								cancer survivor. Her personal experience, those of her family, and a
								passion for empowering people to take charge of their own health, fuel
								her entrepreneurial and strategic accomplishments. She also has business
								acumen about organizations operating in the genomics, precision
								medicine, and consumer fields. She is bringing all of this and more to
								Genetic Alliance.
							</p>

							<p>
								Genetic Alliance has a wide range of tools and resources that will
								benefit greatly from Sue’s ability to communicate their value simply and
								succinctly to the relevant audience. Genetic Alliance has a clear
								mandate to deliver our phenomenal resources to empower individuals,
								families, and communities, and Sue is the right person to help us
								transform health and alleviate suffering. You’ll get that when you read
								her LinkedIn{' '}
								<a
									href='https://www.linkedin.com/pulse/whats-name-susan-mcclure/?published=t'
									target='_blank'
									rel='noreferrer'>
									post
								</a>{' '}
								– her heart and intelligence shine through.
							</p>

							<p>
								We also welcome Sam Solomon, Director of Design. Sam is an award-winning
								designer and has spent 8 years working with Sue. He brings impressive
								skills in design, both digital and print. You may have noticed that our
								website is vastly improved even though Sam has only had a few days to
								make it better. Our{' '}
								<a
									href='https://mailchi.mp/geneticalliance/weekly-bulletin-598172'
									target='_blank'
									rel='noreferrer'>
									Weekly Bulletin
								</a>{' '}
								was redesigned by Sam after Sue improved the content editing, and the
								open rate has doubled! Genetic Alliance has always had high-quality
								tools, but they have almost never had high excellent, accessible, and
								interesting design as their vehicle to the public. Thanks to Sam for
								joining our team and stay tuned for fabulous content and accessible,
								appealing design!
							</p>

							<p>
								While all that they offer was sorely needed, the launch of{' '}
								<a
									href='https://ihopegenetichealth.org'
									target='_blank'
									rel='noreferrer'>
									iHope Genetic Health
								</a>{' '}
								has increased our needs and we are excited to have such skilled
								navigators aboard!
							</p>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Container>
		</>
	)
}
export default SamSueAnnouncement
