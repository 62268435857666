import { Container, Grid, Image } from 'semantic-ui-react'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { progressIndicator } from '../../content/HelperFunction'
import ReactHtmlParser from 'react-html-parser'

function ResearchServicesFillContent({ data }) {

    const { pathname, hash, key } = useLocation()
    useEffect(() => {
        // if not a hash link, scroll to top
        if (hash === '') {
            window.scrollTo(0, 0)
        }
        // else scroll to id
        else {
            setTimeout(() => {
                const id = hash.replace('#', '')
                const element = document.getElementById(id)
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' })
                }
            }, 0)
        }
    }, [pathname, hash, key])

    useEffect(() => {
        progressIndicator()
    }, [])

    if (!data) {
        return null
    }
    const bodyData = data.filter(item => item.link === '/our-services/research-services')

    const listBodyContent = []
    if (bodyData && Array.isArray(bodyData) && bodyData.length > 0) {
        bodyData.sort((a, b) => a.index - b.index)
        bodyData.forEach((item, index) => {
            listBodyContent.push(
                <>
                    <div id={item.id} className='mb-25'><Image src={item.imageSrc} /></div>
                    <Grid columns={2} stackable>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3 className='our-services mb-25'>{item.header}</h3>
                            <p className='subtitle'>{item.headerSub}</p>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8} className='mt-15'>
                            {ReactHtmlParser(item.content)}
                        </Grid.Column>
                    </Grid>
                </>
            )
            if (index < bodyData.length - 1) {
                listBodyContent.push(<hr className='rule-blue-300 mt-80-40 mb-80-40' />)
            }
        })
    } else {
        return null
    }

    return (
        <>
            <Container>
                <hr className='rule-orange-500 mt-80-40 mb-80-40' />
                {listBodyContent}
            </Container>
        </>
    )
}

export default ResearchServicesFillContent
