import { Container, Grid, GridColumn, Image } from 'semantic-ui-react'
// import { Link } from 'react-router-dom'

function Carter() {
	return (
		<>
			<Container className='container-main'>
				<h1>IRB Roster</h1>
				<Grid columns={1} padded>
					<GridColumn>
						<Image
							alt='Christine Carter'
							src='/images/IRB/CarterHeadshot.jpeg'
							size='small'
							floated='left'
							rounded
						/>
						<h2>Christine Carter</h2>
						<h3>IRB Board Chair</h3>
						<p>
							Dr. Carter has thirty four years of progressively responsible
							professional experience in providing leadership and scientific resources
							for governmental agencies, academic institutions, corporate institutions,
							and nonprofit research organizations in health-related scientific
							research and contract management. Areas of expertise include
							institutional review board (IRB) executive leadership, research
							infrastructure development, inter-agency and interdisciplinary
							collaborations, research methodology, and contract negotiations. Dr.
							Carter has served on the Genetic Alliance IRB Board since 2013 and as
							Chair since 2014.
						</p>
					</GridColumn>
				</Grid>
			</Container>
		</>
	)
}

export default Carter
