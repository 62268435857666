import { Container, Grid, Image, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'


function NETSBiospecimens() {
    return (
        <>
            <div>
                <Container className='container-main'>
                    <h1>NETS: Biospecimens</h1>
			        <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>What are biospecimens and why are they important in drug development?</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>
                                Biospecimens are biological samples collected from humans such as blood samples, tumor samples, or other tissues. When someone with a disease or condition has a biopsy, surgery, or other procedure, often a small amount of the specimen taken can be stored and used for later research. The specimens are used in biomedical research to learn about how disease affects the human body.
                            </p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Testimonials: How we did it</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <div className='inline-flex'>
                                <Image src='/icons/multimedia.png' className='icon-width-16px mr-8' style={{ height: '16px', top: '8px' }} />
                                <p>
                                    <a
                                        href='https://www.youtube.com/watch?v=U0ES0yDWryM&gl=US&hl=en'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Genetic Alliance: Advocates are Leading in Biobanking
                                    </a>
                                </p>
                            </div>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Relevant Publications</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>Content not yet available for this section.</p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Related Tools</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <a
                                href='https://biolincc.nhlbi.nih.gov/home/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/toolkit.png' className='icon-width-16px' />NHLBI: Biologic Specimen and Data Repository Information Coordinating Center</Button>
                            </a>
                            <a
                                href='https://specimens.cancer.gov/tissue/default.htm'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/toolkit.png' className='icon-width-16px' />NCI: Specimen Resource Locator</Button>
                            </a>
                            <a
                                href='https://dtp.cancer.gov/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/toolkit.png' className='icon-width-16px' />NCI DTP: Tumor Repository</Button>
                            </a>
                            {/* <a
                                href='/pdf/nets/Workshop_On_Release.pdf'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/toolkit.png' className='icon-width-16px' />NIDDK: Data, Biosample, and Genetics Repository</Button>
                            </a> */}
                            <a
                                href='https://biospecimens.cancer.gov/bestpractices/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/guide.png' className='icon-width-16px' />NCI OBBR: Best Practices for Biospecimen Resources</Button>
                            </a>
                            <a
                                href='/pdf/nets/Workshop_On_Release.pdf'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/guide.png' className='icon-width-16px' />NCI: Workshop on Release of Research Results to Participants in Biospecimen Studies</Button>
                            </a>
                            <a
                                href='https://biospecimens.cancer.gov/patientcorner/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/guide.png' className='icon-width-16px' />NCI OBBR: What are Biospecimens and Biorepositories?</Button>
                            </a>
                            {/* <a
                                href='https://biospecimens.cancer.gov/researchnetwork/lifecycle.asp'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/guide.png' className='icon-width-16px' />NCI OBBR: Life Cycle of a Biospecimen</Button>
                            </a> */}
                            {/* <a
                                href='https://biospecimens.cancer.gov/programs/cahub/default.asp'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/multimedia.png' className='icon-width-16px' />NCI OBBR: The Critical Role of Biospecimen in Cancer Research</Button>
                            </a> */}
                            <a
                                href='https://brd.nci.nih.gov/brd/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />NCI OBBR: Biospecimen Research Database</Button>
                            </a>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Link to='/resources-and-information/nets/full-view' rel='noopener noreferrer' className='link-button inline-block'><Button className='button-orange-500'><Image src='/icons/link-internal-white.png' className='icon-width-16px' style={{ transform: 'scaleX(-1)' }} />Back to NETS Map full view</Button></Link>
                </Container>
            </div>
        </>
    )
}

export default NETSBiospecimens