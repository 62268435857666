import { Container, Grid, GridColumn, Image, Icon } from 'semantic-ui-react'
import ShareSocialMedia from '../../../ShareSocialMedia'
import LinkButton from '../../../LinkButton'

function LegislationAndPoliticalAction() {
	const shareUrl = 'https://geneticalliance.org/resources-and-information/advocacy-atlas/legislation-action'
	return (
		<Container className='container-main'>
			<h1>Legislation & Political Action</h1>
			<Grid columns={1} padded>
				<GridColumn>
				<hr />
					<ShareSocialMedia shareUrl={shareUrl} />
					<div>
						<p className='mt-20'>
							<i>As Kelly’s formula got more expensive, we looked for resources, but our income was too high for Medicaid, and the state did not test or pay for Homocystinuria (HCU) medical coverage. That is what started our advocacy activities. I started advocating to get a medical food law in Michigan. My husband and I paid a lobbyist that he knew to help me work with the legislature, and I started to attend the Newborn Screening (NBS) Advisory meeting in the state to meet the other stakeholders. I networked with many people to put together an advocacy packet and team. Parents of children with PKU [Phenylketonuria] only have a limited interest in changing the law since their formula was always covered by the state. Other genetic metabolic parents were more receptive, but our numbers were few. The insurance industry had more resources to fight the law change than we did. Our bill got a sponsor but never went to the committee. Because of the pressure we put on one insurer, they did change the law to cover some medical formula until a patient is 18. It is a start. I certainly raised awareness of the problem through the media and other efforts.
							<br /><br />
							-Story from a parent advocate</i>
						</p>
					</div>

					<h2 className='color-secondary mt-40'>Resources</h2>
					<Grid stackable columns={2} className='mt-15 left aligned'>
						<Grid.Row>
							<Grid.Column>
								<p>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/legislation-action/span_grassrootsadvocacy.pdf'
										rel='noreferrer'
										target='_blank'>
										Public Policy Advocacy: A Grassroots Guide
									</a>
									- Statewide Parent Advocacy Network (SPAN)
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='https://www.parentprojectmd.org/advocacy/'
										rel='noreferrer'
										target='_blank'>
										Advocacy 101
									</a>
									- Parent Project Muscular Dystrophy
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/legislation-action/public_policy_advocacy_lobbying.pdf'
										rel='noreferrer'
										target='_blank'>
										Public Policy Advocacy Presentation
									</a>
									- Statewide Parent Advocacy Network (SPAN)
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/legislation-action/praxis_projecthc_equity.pdf'
										rel='noreferrer'
										target='_blank'>
										Health Care Equity Tool Kit for a Winning Policy Strategy
									</a>
									- The Praxis Project
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/legislation-action/span_brief_policy_advocacy.pdf'
										rel='noreferrer'
										target='_blank'>
										Public Policy Advocacy for Social Change
									</a>
									- Statewide Parent Advocacy Network (SPAN)
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/legislation-action/partners_2007.pdf'
										rel='noreferrer'
										target='_blank'>
										Overview of Training on How to Become an Effective Disability Policy Change Agent
									</a>
									- Powers Pyles Sutter & Verville, P.C.
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/legislation-action/praxis_project_beyondlegislation.pdf'
										rel='noreferrer'
										target='_blank'>
										Policy Options Beyond Legislation
									</a>
									- Adapted from Making Policy Making Change: How Communities Are Taking Law Into Their Own Hands by Makani Themba
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/legislation-action/praxis_project_developinitiative.pdf'
										rel='noreferrer'
										target='_blank'>
										Developing A Policy Initiative
									</a>
									- The Praxis Project
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/legislation-action/CCFAAdvocacyToolkit.pdf'
										rel='noreferrer'
										target='_blank'>
										Advocacy Toolkit
									</a>
									- Crohn’s & Colitis Foundation of America
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/legislation-action/praxis_project_advocacy_spanish.pdf'
										rel='noreferrer'
										target='_blank'>
										Guide On Effective Policy Advocacy
									</a>
									– SPANISH - The Praxis Project
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/legislation-action/meeting_elected_officials.pdf'
										rel='noreferrer'
										target='_blank'>
										Public Policy Advocacy: Meeting with Elected Officials
									</a>
									- Statewide Parent Advocacy Network (SPAN)
								</p>
							</Grid.Column>
							<Grid.Column>
								<div>
									<figure>
										<Image
											src='/images/resources-and-information/advocacy-atlas/legislation-action/alexa_capital.jpeg'
											size='medium'
											centered
										/>
										<figcaption className='mt-20 text-center'>
											<i>Advocates rally in front of the United States Capitol Building.</i>
										</figcaption>
									</figure>
									<figure className='mt-150'>
										<Image
											src='/images/resources-and-information/advocacy-atlas/legislation-action/sarah supreme court.jpeg'
											size='medium'
											centered
										/>
										<figcaption className='mt-20 text-center'>
											<i>Sarah advocates for health insurance coverage in front of the United States Supreme Court.</i>
										</figcaption>
									</figure>
								</div>
							</Grid.Column>
						</Grid.Row>
					</Grid>
					<div>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/legislation-action/together_move_mountains.pdf'
								rel='noreferrer'
								target='_blank'>
								Together, We Can Move Mountains: Parents as Leaders in Improving Systems for Children and Families
							</a>
							- Statewide Parent Advocacy Network (SPAN)
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='https://www.sophe.org/resources/guide-effectively-educating-state-local-policymakers/'
								rel='noreferrer'
								target='_blank'>
								Guide to Effectively Educating State and Local Policymakers
							</a>
							- Society of Public Health Educators, State Health Policy Institute
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/legislation-action/speaking_up_booklet_final1.pdf'
								rel='noreferrer'
								target='_blank'>
								Speak Up - A guide to self advocacy
							</a>
							- The Arc of Washington
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/legislation-action/legislative_policy_guide.pdf'
								rel='noreferrer'
								target='_blank'>
								A Guide to Legislative Advocacy for Youth with Disabilities
							</a>
							- National Consortium on Leadership and Disability for Youth
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='https://www.dailymotion.com/video/xa4p76_diana-autin-2009-regional-conferenc_people'
								rel='noreferrer'
								target='_blank'>
								Video Presentation on Parent Advocacy, by Diana Autin from the Statewide Parent Advocacy Network (SPAN)
							</a>
							- Statewide Parent Advocacy Network
						</p>
					</div>
					<div className='mt-40 text-center'>
						<LinkButton className='ui button' 
						to='/resources-and-information/advocacy-atlas'
						>
							<Icon name='arrow left' /> Return to Advocacy ATLAS Homepage
						</LinkButton>
					</div>
				</GridColumn>
			</Grid>
		</Container>
	)
}

export default LegislationAndPoliticalAction