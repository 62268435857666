import { Container, Grid, Image, Button } from 'semantic-ui-react'
import { Link, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { progressIndicator } from '../../content/HelperFunction'
import ReactHtmlParser from 'react-html-parser'

function OverviewIRBFillContent({ data }) {
    const { pathname, hash, key } = useLocation()
    useEffect(() => {
        // if not a hash link, scroll to top
        if (hash === '') {
            window.scrollTo(0, 0)
        }
        // else scroll to id
        else {
            setTimeout(() => {
                const id = hash.replace('#', '')
                const element = document.getElementById(id)
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' })
                }
            }, 0)
        }
    }, [pathname, hash, key])

    useEffect(() => {
        progressIndicator()
    }, [])

    if (!data) {
        return null
    }
    const datas = data.filter(item => item.link === '/irb/overview')
    if (datas && Array.isArray(datas) && datas.length > 0) {
        datas.sort((a, b) => a.index - b.index)
    } else {
        return null
    }
    return (
        <>
            <Container>
                <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
                <Grid columns={2} stackable>
                    <Grid.Column mobile={16} tablet={16} computer={8}>
                        <h3 id={datas[0].id} className='irb'>{datas[0].header}</h3>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={8} className='mt-15'>
                        {ReactHtmlParser(datas[0].content)}
                    </Grid.Column>
                </Grid>
                <hr className='rule-blue-800 mt-80-60-40 mb-80-60-40' />
                <h3 id={datas[1].id} className='our-services mb-80-60-40'>{datas[1].header}</h3>
                <Grid className='computer-only' columns={3} stackable>
                    <Grid.Column>
                        <div className='mb-25'><Image src='/images/irb/irb-1.png' style={{ width: '60px' }} /></div>
                        <p>{datas[1].content1}</p>
                    </Grid.Column>
                    <Grid.Column >
                        <div className='mb-25'><Image src='/images/irb/irb-2.png' style={{ width: '52px' }} /></div>
                        <p>{datas[1].content2}</p>
                    </Grid.Column>
                    <Grid.Column >
                        <div className='mb-25'><Image src='/images/irb/irb-3.png' style={{ width: '50px' }} /></div>
                        <p>{datas[1].content3}</p>
                    </Grid.Column>
                </Grid>
                <Grid className='mobile-and-tablet-only'>
                    <Grid.Column>
                        <div className='mb-25'><Image src='/images/irb/irb-1.png' style={{ width: '60px' }} /></div>
                        <p>{datas[1].content1}</p>
                        <div className='mb-25'><Image src='/images/irb/irb-2.png' style={{ width: '52px' }} /></div>
                        <p>{datas[1].content2}</p>
                        <div className='mb-25'><Image src='/images/irb/irb-3.png' style={{ width: '50px' }} /></div>
                        <p>{datas[1].content3}</p>
                    </Grid.Column>
                </Grid>
                <hr className='rule-blue-800 mt-80-60-40 mb-80-60-40' />
                <Grid columns={2} stackable>
                    <Grid.Column mobile={16} tablet={16} computer={8}>
                        <h3 id={datas[2].id} className='irb'>{datas[2].header}</h3>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={8} className='mt-15'>
                        <p>{datas[2].content1}{' '}
                            <Link
                                to='/irb/application-process'
                                rel='noopener noreferrer'
                            >
                                {datas[2].content2}
                            </Link>
                            {' '}{datas[2].content3}</p>
                        <div>
                            <a
                                href='mailto:irbadmin@geneticalliance.org'
                                className='link-button'>
                                <Button className='button-neutral-100 mt-25' style={{ margin: '0' }}>
                                    <p className='button-text' style={{ margin: '0 auto' }}>{datas[2].content4}</p> &nbsp;&nbsp;
                                    <Image
                                        className='link-internal-icon'
                                        src='/icons/link-internal-blue.png'
                                    />
                                </Button>
                            </a>
                        </div>
                    </Grid.Column>
                </Grid>
            </Container>
        </>
    )
}

export default OverviewIRBFillContent
