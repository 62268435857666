import { Container, Grid, Image, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'


function NETSPhaseIII() {
    return (
        <>
            <div>
                <Container className='container-main'>
                    <h1>NETS: Phase III Clinical Trials (Safety, Efficacy)</h1>
			        <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>What are Phase III clinical trials and why are they important in drug development?</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>
                                Phase III clinical trials are large studies which confirm drug safety and efficacy. They contain a greater number of participants than{' '} 
                                <Link to='/resources-and-information/nets/phase_II' rel='noopener noreferrer'>
                                    Phase II 
                                </Link>
                                {' '}trials so that{' '}
                                <Link to='/resources-and-information/nets/safety' rel='noopener noreferrer'>
                                    safety 
                                </Link>
                                {' '}and{' '} 
                                <Link to='/resources-and-information/nets/efficacy' rel='noopener noreferrer'>
                                    efficacy 
                                </Link>
                                {' '}can be validated in a larger population. The trials almost always randomly assign participants to one of two groups, the control group or the treatment group. Phase III trials usually include participants of different race, ethnicity and gender, in order to see how a drug acts on a diverse group of people.
                            </p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Testimonials: How we did it</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>Content not yet available for this section.</p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Relevant Publications</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>Content not yet available for this section.</p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Related Tools</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <a
                                href='https://clinicaltrials.gov/ct2/home'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />NIH: ClinicalTrials.gov</Button>
                            </a>
                            <a
                                href='https://www.clinicaltrialsregister.eu/ctr-search/search'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />EU/EEA: EU Clinic Trials Register</Button>
                            </a>
                            <a
                                href='https://www.cancer.net/research-and-advocacy/clinical-trials/phases-clinical-trials'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/multimedia.png' className='icon-width-16px' />ASCO: Phases of Clinical Trials</Button>
                            </a>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Link to='/resources-and-information/nets/full-view' rel='noopener noreferrer' className='link-button inline-block'><Button className='button-orange-500'><Image src='/icons/link-internal-white.png' className='icon-width-16px' style={{ transform: 'scaleX(-1)' }} />Back to NETS Map full view</Button></Link>
                </Container>
            </div>
        </>
    )
}

export default NETSPhaseIII