import { getCookie, isAuth } from 'actions/auth'
import { list, removeBlog } from 'actions/blog'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

const ReadNews = () => {
	const [blogs, setBlogs] = useState([])
	const token = getCookie('token')

	const loadBlogs = () => {
		list().then(data => {
			if (data.error) {
				console.log(data.error)
			} else {
				setBlogs(data)
			}
		})
	}

	useEffect(() => {
		loadBlogs()
	}, [])

	const deleteBlog = slug => {
		removeBlog(slug, token).then(data => {
			if (data.error) {
				console.log(data.error)
				toast.error('Error deleting blog')
			} else {
				toast.success(`"${data.title}" is deleted`)
				loadBlogs()
			}
		})
	}

	const deleteConfirm = slug => {
		let answer = window.confirm('Are you sure you want to delete your blog?')
		if (answer) {
			deleteBlog(slug, token)
			toast.success('Blog deleted successfully')
		}
	}

	const showUpdateButton = blog => {
		if (isAuth() && isAuth().role === 0) {
			return (
				<Link to={`/user/crud/${blog.slug}`} className='link-button'>
					<Button color='blue' className='button-neutral-100'>Update</Button>
				</Link>
			)
		} else if (isAuth() && isAuth().role === 1) {
			return (
				<Link to={`/admin/crud/${blog.slug}`} className='link-button'>
					<Button color='blue' className='button-neutral-100 ml-15'>
						Update
					</Button>
				</Link>
			)
		}
	}

	const showAllBlogs = () => {
		return blogs.map((blog, i) => {
			return (
				<div key={i}>
					<h3>{blog.title}</h3>
					<p className='p-compact mb-25 mt-15' style={{ textAlign: 'left' }}>
						Written by{' '}
						<Link to={`/profile/${blog.postedBy.username}`}>
							{blog.postedBy && blog.postedBy.username}
						</Link>{' '}
						| Published {moment(blog.updatedAt).fromNow()}
					</p>
					<div className='display-flex'>
						<Button
						onClick={() => deleteConfirm(blog.slug)}
						className='button-negative-red'>
						Delete
						</Button>
						{showUpdateButton(blog)}
					</div>
					<hr className='rule-blue-300 mt-40 mb-40' />
				</div>
			)
		})
	}

	return <div>{showAllBlogs()}</div>
}

export default ReadNews
