import { Container, Grid, Button, Image } from 'semantic-ui-react'
import ReactHtmlParser from 'react-html-parser'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { progressIndicator } from '../../content/HelperFunction'

function BurdenOfDiseaseFillContent({ data }) {

    const { pathname, hash, key } = useLocation()
    useEffect(() => {
        // if not a hash link, scroll to top
        if (hash === '') {
            window.scrollTo(0, 0)
        }
        // else scroll to id
        else {
            setTimeout(() => {
                const id = hash.replace('#', '')
                const element = document.getElementById(id)
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' })
                }
            }, 0)
        }
    }, [pathname, hash, key])

    useEffect(() => {
        progressIndicator()
    }, [])

    console.log('BurdenOfDiseaseFillContent data', data)
    if (!data) {
        return null
    }

    const datas = data.filter(item => item.link === '/events/webinars/burden-of-disease')

    if (datas && Array.isArray(datas) && datas.length > 0) {
        datas.sort((a, b) => a.index - b.index)
    } else {
        return null
    }

    return (
        <>
            <div>
                <Container className='container-main'>
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                            <h1 id={datas[0].id}>{datas[0].header}</h1>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                            {ReactHtmlParser(datas[0].content)}
                        </Grid.Column>
                    </Grid>
                    <hr className='rule-orange-500 mt-80-60-40 mb-65-55-25' />
                    <div id={datas[1].id}>
                        {ReactHtmlParser(datas[1].content)}
                        <div className='text-center mt-25 mb-25'>
                            <a
                                href='https://www.youtube.com/watch?v=cEJntySERlw'
                                target='_blank'
                                rel='noreferrer'
                                className='link-button inline-block mb-15'
                                style={{ alignSelf: 'center' }}
                            >
                                <Button className='button-orange-500 mr-15' style={{ margin: '0' }}>
                                    View the recording
                                    <Image src='/icons/link-internal-white.png' className='icon-width-16px' />
                                </Button>
                            </a>
                        </div>
                    </div>
                    <div id={datas[2].id}>
                        {ReactHtmlParser(datas[2].content)}
                    </div>
                </Container>
            </div>
        </>
    )
}

export default BurdenOfDiseaseFillContent