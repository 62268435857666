import { Container, Grid, Image, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'


function NETSEnrollment() {
    return (
        <>
            <div>
                <Container className='container-main'>
                    <h1>NETS: Participant Enrollment</h1>
			        <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>What is participant enrollment and why is it important in drug development?</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>
                                A participant is someone who voluntarily takes part in a research study. Participant enrollment occurs when an individual meets all involvement criteria for a study and agrees to participate (through{' '} 
                                <Link to='/resources-and-information/nets/consent' rel='noopener noreferrer'>
                                    informed consent
                                </Link>
                                ). This participant can now be a part of the study.
                            </p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Testimonials: How we did it</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>Content not yet available for this section.</p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Relevant Publications</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>Content not yet available for this section.</p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Related Tools</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <a
                                href='https://www.youtube.com/watch?v=Z8JIRRIiQHE&ab_channel=GeneticAlliance'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/multimedia.png' className='icon-width-16px' />Genetic Alliance: The Importance of Participation - Rethinking Clinical Trials</Button>
                            </a>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Link to='/resources-and-information/nets/full-view' rel='noopener noreferrer' className='link-button inline-block'><Button className='button-orange-500'><Image src='/icons/link-internal-white.png' className='icon-width-16px' style={{ transform: 'scaleX(-1)' }} />Back to NETS Map full view</Button></Link>
                </Container>
            </div>
        </>
    )
}

export default NETSEnrollment