import { Grid, Image, Container, Button } from 'semantic-ui-react'
import './Footer.css'
import { Link } from 'react-router-dom'

function Footer() {
	return (
		<>
			<div className='footer'>
				<Container className='footer-container'>
					<Grid>
						<Grid.Row columns={2} className='mb-120-60-40'>
							<Grid.Column mobile={16} tablet={8} computer={8}>
								<hr className='rule-navy' />
								<h4>Genetic Alliance Network Announcements</h4>
								<p className='p-small'>
									Periodically, Genetic Alliance will alert you to important action
									items, announcements, and deadlines, including Genetic
									Alliance-specific material as well as information from the genetics,
									health, and advocacy communities. Some weeks there will be no emails;
									other weeks one or more might be sent to the list.
								</p>
								<Link
									to='/mailing-lists'
									rel='noopener noreferrer'
									className='link-button display-contents'>
									<Button className='button-neutral-0 mt-30' style={{ margin: '0' }}>
										Subscribe
										<Image
											className='link-internal-icon'
											src='/icons/link-internal-white.png'
										/>
									</Button>
								</Link>
							</Grid.Column>
							<Grid.Column mobile={16} tablet={8} computer={8}>
								<hr className='rule-navy hr2' />
								<h4>Community Forum Discussion</h4>
								<p className='p-small'>
									Being a part of the Genetic Alliance network gives you access to
									valuable online meeting spaces that provide an opportunity to discuss
									as a virtual community.
								</p>
								<a
									href='https://groups.google.com/g/geneticalliancecommunityforum'
									rel='noopener noreferrer'
									className='link-button display-contents'>
									<Button className='button-neutral-0 mt-30' style={{ margin: '0' }}>
										Subscribe
										<Image
											className='link-internal-icon'
											src='/icons/link-internal-white.png'
										/>
									</Button>
								</a>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row columns={3}>
							<Grid.Column mobile={16} tablet={8} computer={8} className='mb-40'>
								<Image src='/logos/ga-logo-white.png' className='mw-240px' />
								<Image src='/logos/genetic-alliance.png' className='mw-240px mt-15' />
								<p className='p-small blue-400 mt-40 footer-logo-text'>
									Genetic Alliance is registered as a 501©3 nonprofit organization.
									Contributions to Genetic Alliance are tax-deductible to the extent
									permitted by law. Our tax identification number is 52-1571905.
								</p>
								<p className='p-small blue-400 mt-40 footer-logo-text'>Copyright 1995 - 2024, Genetic Alliance</p>
							</Grid.Column>
							{/* <Grid.Column mobile={16} tablet={4} computer={4}>
								<div className='footer-navigation'>
									<p><Link to='/about/jobs-and-internships'>Jobs and Internships</Link></p>
									<p><Link to='/about/archives-and-history'>Archives and History</Link></p>
									<p><Link to='/about/annual-reports'>Annual Reports</Link></p>
									<p><Link to='/about/press-releases'>Press Releases</Link></p>
									<p><Link to='/about/contact-us'>Contact Us</Link></p>
									<p><Link to='/privacy-policy' target='_blank' rel='noopener noreferrer'>Privacy Policy</Link></p>
									<p><Link to='/health-disclaimer' target='_blank' rel='noopener noreferrer'>Health Disclaimer</Link></p>
								</div>
								<Link to='/donate' className='link-button'>
									<Button className='donate-home button-neutral-0 display-contents mt-40'>Donate&nbsp;&nbsp;</Button>
								</Link>
							</Grid.Column> */}
							<Grid.Column mobile={16} tablet={4} computer={4} className='list'>
								<div className='footer-navigation'>
									<p>
										<Link to='/about/jobs-and-internships'>Jobs and Internships</Link>
									</p>
									<p>
										<Link to='/about/archives-and-history'>Archives and History</Link>
									</p>
									<p>
										<Link to='/about/annual-reports'>Annual Reports</Link>
									</p>
									<p>
										<Link to='/about/press-releases'>Press Releases</Link>
									</p>
								</div>
								<div className='footer-navigation'>
									<p>
										<Link to='/about/contact-us'>Contact Us</Link>
									</p>
									<p>
										<Link to='/privacy-policy'>Privacy Policy</Link>
									</p>
									<p>
										<Link to='/health-disclaimer'>Health Disclaimer</Link>
									</p>
									<Link
										to='https://www.paypal.com/donate/?hosted_button_id=W4GSV8T89QKBY'
										target='_blank'
										className='link-button'>
										<Button className='donate-home donate-footer button-neutral-0 display-contents mt-40'>
											Donate
										</Button>
									</Link>
								</div>
							</Grid.Column>
							<Grid.Column mobile={16} tablet={4} computer={4}>
								<div className='mb-50 footer-navigation'>
									<a
										className='mr-40-30-40'
										href='https://twitter.com/GeneticAlliance'
										target='_blank'
										rel='noopener noreferrer'>
										<Image
											src='/icons/twitter-white.png'
											style={{ width: '16px', display: 'inline' }}
										/>
									</a>
									<a
										className='mr-40-30-40'
										href='https://www.facebook.com/GeneticAlliance'
										target='_blank'
										rel='noopener noreferrer'>
										<Image
											src='/icons/facebook-white.png'
											style={{ width: '10px', display: 'inline' }}
										/>
									</a>
									<a
										className='mr-40-30-40'
										href='https://www.linkedin.com/company/genetic-alliance/'
										target='_blank'
										rel='noopener noreferrer'>
										<Image
											src='/icons/linkedin-white.png'
											style={{ width: '16px', display: 'inline' }}
										/>
									</a>
									<a
										href='https://www.youtube.com/user/geneticalliance'
										target='_blank'
										rel='noopener noreferrer'>
										<Image
											src='/icons/youtube-white.png'
											style={{ width: '16px', display: 'inline' }}
										/>
									</a>
								</div>
								<div className='license-computer'>
									<a
										href='https://www.guidestar.org/profile/52-1571905'
										target='_blank'
										rel='noopener noreferrer'
										className='display-contents'>
										<Image
											src='/logos/candid-seal-platinum-2023.png'
											className='width-120px'
										/>
									</a>
									<p className='p-small blue-400 mt-40 mw-192px'>
										Except where otherwise noted, content on this site is licensed under a
										Creative Commons Attribution 3.0 License.
									</p>
								</div>
								<div className='license-mobile'>
									<p className='p-small blue-400 mb-40'>
										Except where otherwise noted, content on this site is licensed under a
										Creative Commons Attribution 3.0 License.
									</p>
									<a
										href='https://www.guidestar.org/profile/52-1571905'
										target='_blank'
										rel='noopener noreferrer'
										className='display-contents'>
										<Image
											src='/logos/candid-seal-platinum-2023.png'
											className='width-120px'
										/>
									</a>
								</div>
							</Grid.Column>
						</Grid.Row>
					</Grid>
					{/* <Grid></Grid> mobile version */}
				</Container>
			</div>
		</>
	)
}

export default Footer
