import {
	FacebookShareButton,
	TwitterShareButton,
	LinkedinShareButton,
	EmailShareButton,
} from 'react-share'

function ShareSocialMedia({ shareUrl }) {
	return (
		<div style={{ minWidth: '250px' }} /*  className='ui right aligned container' */>
			<EmailShareButton className='mr-16 social-share-button' url={shareUrl}>
				<div className="icon-social-container share-email">
					<div className="icon-social-background share-email" />
					<svg className="icon-social-svg share-email" width="17" height="14" viewBox="0 0 17 14" fill='none' xmlns="http://www.w3.org/2000/svg">
						<path className='share-email' d="M15 13H2C1.448 13 1 12.552 1 12V2C1 1.448 1.448 1 2 1H15C15.552 1 16 1.448 16 2V12C16 12.552 15.552 13 15 13Z" stroke-miterlimit="10" strokeLinecap="round" stroke-linejoin="round" />
						<path className='share-email' d="M3 4L8.5 8.5L14 4" stroke-miterlimit="10" strokeLinecap="round" stroke-linejoin="round"/>
						<path className='share-email' d="M3 10L4.5 8.5" stroke-miterlimit="10" strokeLinecap="round" stroke-linejoin="round"/>
						<path className='share-email' d="M14 10L12.5 8.5" stroke-miterlimit="10" strokeLinecap="round" stroke-linejoin="round"/>
					</svg>
		 		</div>
			</EmailShareButton>
			<FacebookShareButton /* className='ml-5' */ className='mr-16 social-share-button' url={shareUrl}>
				<div className="icon-social-container">
					<div className="icon-social-background" />
					<svg className="icon-social-svg" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
						<path d="M16 8.00175C16.0003 6.47283 15.5625 4.97584 14.7385 3.68802C13.9144 2.40021 12.7385 1.37551 11.3501 0.73525C9.96173 0.0949928 8.41893 -0.134003 6.90442 0.0753778C5.38991 0.284758 3.96712 0.923744 2.80451 1.91668C1.6419 2.90961 0.788165 4.2149 0.344396 5.678C-0.0993733 7.14109 -0.114591 8.70071 0.300544 10.1722C0.71568 11.6437 1.54378 12.9654 2.68679 13.9808C3.82981 14.9962 5.23986 15.6629 6.75 15.9017V10.3137H4.719V8.00175H6.75V6.23874C6.70627 5.82719 6.7536 5.41106 6.88863 5.01984C7.02366 4.62862 7.24309 4.27189 7.53136 3.97493C7.81964 3.67797 8.1697 3.44805 8.55674 3.30147C8.94379 3.1549 9.35833 3.09524 9.771 3.12675C10.371 3.13463 10.9696 3.18677 11.562 3.28275V5.25375H10.554C10.3823 5.23129 10.2077 5.24774 10.0432 5.30186C9.87874 5.35599 9.72853 5.44641 9.6037 5.56643C9.47887 5.68646 9.38263 5.83302 9.3221 5.99526C9.26157 6.15751 9.23829 6.33129 9.254 6.50375V8.00375H11.473L11.118 10.3157H9.25V15.9067C11.1322 15.609 12.8464 14.6491 14.0838 13.1999C15.3213 11.7507 16.0008 9.90739 16 8.00175Z" />
					</svg>
		 		</div>
			</FacebookShareButton>
			<TwitterShareButton /* className='ml-5' */ className='mr-16 social-share-button' url={shareUrl}>
				<div className="icon-social-container">
					<div className="icon-social-background" />
					<svg className="icon-social-svg" width="16" height="16" viewBox="0 0 16 13" xmlns="http://www.w3.org/2000/svg">
						<path d="M16 1.5C15.4 1.8 14.8 1.9 14.1 2C14.8 1.6 15.3 1 15.5 0.2C14.9 0.6 14.2 0.8 13.4 1C12.8 0.4 11.9 0 11 0C9.3 0 7.8 1.5 7.8 3.3C7.8 3.6 7.8 3.8 7.9 4C5.2 3.9 2.7 2.6 1.1 0.6C0.8 1.1 0.7 1.6 0.7 2.3C0.7 3.4 1.3 4.4 2.2 5C1.7 5 1.2 4.8 0.7 4.6C0.7 6.2 1.8 7.5 3.3 7.8C3 7.9 2.7 7.9 2.4 7.9C2.2 7.9 2 7.9 1.8 7.8C2.2 9.1 3.4 10.1 4.9 10.1C3.8 11 2.4 11.5 0.8 11.5C0.5 11.5 0.3 11.5 0 11.5C1.5 12.4 3.2 13 5 13C11 13 14.3 8 14.3 3.7C14.3 3.6 14.3 3.4 14.3 3.3C15 2.8 15.6 2.2 16 1.5Z" />
					</svg>
		 		</div>
			</TwitterShareButton>
			<LinkedinShareButton /* className='ml-5' */ className='mr-16 social-share-button' url={shareUrl}>
				<div className="icon-social-container">
					<div className="icon-social-background" />
					<svg className="icon-social-svg" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
						<path d="M15.3 0H0.7C0.3 0 0 0.3 0 0.7V15.4C0 15.7 0.3 16 0.7 16H15.4C15.8 16 16.1 15.7 16.1 15.3V0.7C16 0.3 15.7 0 15.3 0ZM4.7 13.6H2.4V6H4.8V13.6H4.7ZM3.6 5C2.8 5 2.2 4.3 2.2 3.6C2.2 2.8 2.8 2.2 3.6 2.2C4.4 2.2 5 2.8 5 3.6C4.9 4.3 4.3 5 3.6 5ZM13.6 13.6H11.2V9.9C11.2 9 11.2 7.9 10 7.9C8.8 7.9 8.6 8.9 8.6 9.9V13.7H6.2V6H8.5V7C8.8 6.4 9.6 5.8 10.7 5.8C13.1 5.8 13.5 7.4 13.5 9.4V13.6H13.6Z" />
					</svg>
		 		</div>
			</LinkedinShareButton>
		</div>
	)
}

export default ShareSocialMedia