import {
	Container,
	Grid,
	GridColumn,
	Image,
	Button,
} from 'semantic-ui-react'
import { Link, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { progressIndicator } from '../../content/HelperFunction'
import ReactHtmlParser from 'react-html-parser'

function WebinarsFillContent({ data }) {

	const location = useLocation();
	const { pathname, hash, key } = useLocation()
	useEffect(() => {
		// if not a hash link, scroll to top
		if (hash === '') {
			window.scrollTo(0, 0)
		}
		// else scroll to id
		else {
			setTimeout(() => {
				const id = hash.replace('#', '')
				const element = document.getElementById(id)
				if (element) {
					element.scrollIntoView({ behavior: 'smooth' })
				}
			}, 0)
		}
	}, [pathname, hash, key])

	useEffect(() => {
		progressIndicator()
	}, [])

	console.log('WebinarsFillContent data', data)
	if (!data) {
		return null
	}
	const datas = data.filter(item => item.link === '/events/webinars')
	if (datas && Array.isArray(datas) && datas.length > 0) {
		datas.sort((a, b) => a.index - b.index)
	} else {
		return null
	}

	return (
		<Container className='container-main'>
			<h1>{datas[0].header}</h1>
			<Grid columns={1} padded>
				<GridColumn>
					<p>
						{datas[0].content}
					</p>
					<hr />
					<h3 id={datas[1].id}>{datas[1].header}</h3>
					<Grid textAlign='center' columns={2} className='mt-15' stackable>
						<Grid.Row>
							<Grid.Column>
								<Image
									src='/images/events/3.29-webinar-slide.png'
									size='huge'
									centered
								/>
								<p className='mt-15 mb-25'>
									{datas[1].content1}
								</p>

								<Link
									to={{
										pathname: '/events/webinars/burden-of-disease',
										state: { data: location.state },
									}}
									rel='noreferrer' className='inline-block link-button' style={{ alignSelf: 'center' }}>
									<Button className='button-neutral-100' style={{ margin: '0' }}>
										Learn more
										<Image src='/icons/link-internal-blue.png' className='icon-width-16px' />
									</Button>
								</Link>
							</Grid.Column>
							<Grid.Column>
								<Image
									src='/images/events/3.2-webinar-slide.png'
									// height="253px"
									size='huge'
									centered
								/>
								<p className='mt-15 mb-25'>
									{datas[1].content2}
								</p>
								<Link
									to={{
										pathname: '/events/webinars/therapy-development',
										state: { data: location.state },
									}}
									rel='noreferrer' className='inline-block link-button' style={{ alignSelf: 'center' }}>
									<Button className='button-neutral-100' style={{ margin: '0' }}>
										Learn more about this series
										<Image src='/icons/link-internal-blue.png' className='icon-width-16px' />
									</Button>
								</Link>
							</Grid.Column>
						</Grid.Row>
					</Grid>
					<h2 id={datas[2].id} className='mt-40 text-center'>
						{datas[2].header}
					</h2>
					{ReactHtmlParser(datas[2].content)}
					<div className='text-center'>
						<a href='https://forms.gle/8wnUt5AKE6KsUbMn6' target='_blank' rel='noreferrer' className='inline-block link-button'>
							<Button className='button-neutral-100' style={{ margin: '0' }}>
								Advocacy leaders - Sign up to present
								<Image src='/icons/link-internal-blue.png' className='icon-width-16px' />
							</Button>
						</a>
					</div>
					<h2 id={datas[3].id} className='mt-40 text-center'>
						{datas[3].header}
					</h2>
					{ReactHtmlParser(datas[3].content)}
					<div className='text-center'>
						<a href='https://forms.gle/8X6XKp7gnRCUdWFPA' target='_blank' rel='noreferrer' className='inline-block link-button'>
							<Button className='button-neutral-100' style={{ margin: '0' }}>
								Experts - Sign up to present
								<Image src='/icons/link-internal-blue.png' className='icon-width-16px' />
							</Button>
						</a>
					</div>
					<hr className='mt-15' />
					<h3 id={datas[4].id}>{datas[4].header}</h3>
					<p>
						{datas[4].content}
					</p>
					<div className='text-center'>
						<Link to='/events/webinar-archives' rel='noreferrer' className='inline-block link-button'>
							<Button className='button-neutral-100' style={{ margin: '0' }}>
								View Archived Recordings
								<Image src='/icons/link-internal-blue.png' className='icon-width-16px' />
							</Button>
						</Link>
					</div>
				</GridColumn>
			</Grid>
		</Container>
	)
}

export default WebinarsFillContent
