import { Container, Grid, Image } from 'semantic-ui-react'
import ShareSocialMedia from '../../../ShareSocialMedia'

function SamSolomon() {
	const shareUrl = 'http://www.geneticalliance.org/about/staff/sam-solomon'
	return (
		<Container className='mb-80-50-40 container-main'>
			<h1>Sam Solomon</h1>
			<hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
			<Grid>
				<Grid.Column className='mb-5-mobile' mobile={16} tablet={7} computer={4}>
					<Image src='/staff/Solomon.jpg' className='staff-icon-size staff-bio' />
				</Grid.Column>
				<Grid.Column mobile={16} tablet={8} computer={4}>
					<p className='p-compact'>Director of Design</p>
					<ShareSocialMedia shareUrl={shareUrl} />
				</Grid.Column>
				<Grid.Column mobile={16} tablet={16} computer={8}>
					<p>
						Sam is an art director and designer specializing in making complex topics
						approachable to a broad audience, and using design to enact positive
						change in our world. Prior to joining Genetic Alliance, he helped create
						the Genome brand and magazine as the organization’s Creative Director,
						helped redesign foreignaffairs.com and afar.com, and launched countless
						apps, brands, products, and resources.
					</p>
					<p>
						He has been recognized for his work by the Society of Publication
						Designers, Communication Arts, Print, Graphis, and the Dallas Society for
						Visual Communication. In 2017, he sat on the design jury for the Society
						of Publication Designers.
					</p>
				</Grid.Column>
			</Grid>
		</Container>
	)
}

export default SamSolomon
