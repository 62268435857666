import { Container, Grid, GridColumn, Image } from 'semantic-ui-react'
import React, {useState, useEffect} from 'react'
import { useLocation } from 'react-router-dom'
import ModalVideo from 'react-modal-video'
import '../../../node_modules/react-modal-video/scss/modal-video.scss'
import './BlackHistoryMonth.css'


const BlackHistoryMonth = () => {
    const [isOpen, setOpen] = useState(false)
    const [isOpen2, setOpen2] = useState(false)
	
    const { pathname, hash, key } = useLocation();

    useEffect(() => {
        // if not a hash link, scroll to top
        if (hash === '') {
        window.scrollTo(0, 0);
        }
        // else scroll to id
        else {
        setTimeout(() => {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
            element.scrollIntoView({behavior: 'smooth'});
            }
        }, 0);
        }
    }, [pathname, hash, key]);
    return (
		<Container className='container-main'>
			
			<Grid columns={1} padded>
				<GridColumn>
                    <Grid stackable columns={2}>
                            <Grid.Column>
                                <h1>Genetic Alliance Recognizes Black History Month</h1>
                            </Grid.Column>
                            <Grid.Column>
                                <p>
                                    We celebrate two of Genetic Alliance’s senior leaders for their overall leadership. We recognize in this month, and throughout the year, the significance of their leadership in light of structural and systemic racism. The fields in which we work: health care research and services, advocacy, and activism, have been deeply affected by, and often perpetuate, racism. We actively work for justice, inclusion, diversity, and equity. Join us in listening to these leaders.
                                </p>
                            </Grid.Column>
                        </Grid>
                    <hr className='rule-orange-500 mt-100 mb-100' />
                        <div className='mb-50 mw-600 center'>
                            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="fX4XPOGP0lY" onClose={() => setOpen(false)} />
                            <Image centered src="/images/Sandra-Ortega-video.png" size='big' onClick={()=> setOpen(true)}/>
                            <p className='mt-25'>Julia Ortega, VP iHope Genetic Health has excellence in her DNA; Mom Dr. Sandra W. Ortega’s unimaginable life. A journey through systemic racism and a historic first in the United States Air Force.</p>
                            <ModalVideo channel='youtube' autoplay isOpen={isOpen2} videoId="vnamaGHvC1k" onClose={() => setOpen2(false)} />
                            <Image centered src="/images/Natasha-Bonhomme-video.png" size='big' className='mt-100' onClick={()=> setOpen2(true)}/>
                            <p className='mt-25'>Learn how Natasha Bonhomme, VP and Chief Strategy Officer invested her comfort to grow as a Leader.</p>
                        </div>
				</GridColumn>
			</Grid>
		</Container>
	)
}

export default BlackHistoryMonth