import { Container, Grid, Image, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'


function NETSSystem() {
    return (
        <>
            <div>
                <Container className='container-main'>
                    <h1>NETS: HTS System</h1>
			        <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>What is a HTS system and why is it important in drug development?</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>
                                A high-throughput screening (HTS) system consists of the robotic processes and equipment needed for scientists rapidly screens tens of thousands of compounds in an{' '} 
                                <Link to='/resources-and-information/nets/hts' rel='noopener noreferrer'>
                                HTS assay
                                </Link>
                                . This tool allows researchers to rapidly determine whether any of these tens of thousands of compounds are capable of producing the desired biological effect. Additional research is needed to determine whether compounds that have the desired effects in the assay - called active compounds - can be used as therapies. In addition to being used to identify active compounds, HTS systems can also be used to identify active antibodies or genes in a relevant{' '} 
                                <Link to='/resources-and-information/nets/pathway' rel='noopener noreferrer'>
                                molecular pathway
                                </Link>.
                            </p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Testimonials: How we did it</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>Content not yet available for this section.</p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Relevant Publications</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>Content not yet available for this section.</p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Related Tools</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <a
                                href='https://www.ncbi.nlm.nih.gov/books/NBK53196/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/guide.png' className='icon-width-16px' />NIH NCBI: Assay Guidance Manual</Button>
                            </a>
                            {/* <a
                                href='https://sigs.nih.gov/HADIG/Pages/default.aspx'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/partnership.png' className='icon-width-16px' />NIH NCGC: High Throughput Screening Assay Development Interest Group</Button>
                            </a> */}
                            {/* <a
                                href='http://mli.nih.gov/mli/mlpcn/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/partnership.png' className='icon-width-16px' />NIH MLP: Accessing the NIH Chemical Genomics Center through the Molecular Libraries Program</Button>
                            </a> */}
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Link to='/resources-and-information/nets/full-view' rel='noopener noreferrer' className='link-button inline-block'><Button className='button-orange-500'><Image src='/icons/link-internal-white.png' className='icon-width-16px' style={{ transform: 'scaleX(-1)' }} />Back to NETS Map full view</Button></Link>
                </Container>
            </div>
        </>
    )
}

export default NETSSystem