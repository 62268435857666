import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Container, Grid, Image, Tab, Menu } from 'semantic-ui-react'
import IHopeGeneticHealthNewsLetters from './IHopeGeneticHealthNewsLetters'
import IHopeGeneticHealthOverview from './IHopeGeneticHealthOverviewFillContent'
import IHopeGeneticHealthFamilies from './IHopeGeneticHealthFamiliesFillContent'
import IHopeGeneticHealthClinicians from './IHopeGeneticHealthClinicians'
import IHopeGeneticHealthLabs from './IHopeGeneticHealthLabs'
import IHopeGeneticHealthFunders from './IHopeGeneticHealthFunders'
import IHopeGeneticHealthResources from './IHopeGeneticHealthResourcesFillContent'
import './iHopeGeneticHealth.css'
import ReactHtmlParser from 'react-html-parser'

function IHopeGeneticHealthFillContentNew({ data }) {
	console.log('IHopeGeneticHealthFillContent data: ', data)
	const navigate = useNavigate()
	const location = useLocation()
	const panes = [
		{
			menuItem: (
				<Menu.Item>
					<span style={{ display: 'flex' }}>
						<span>Overview</span>
					</span>
				</Menu.Item>
			),
			render: () => (
				<Tab.Pane attached={false}>
					<IHopeGeneticHealthOverview data={data} />
				</Tab.Pane>
			),
		},
		{
			menuItem: (
				<Menu.Item>
					<span style={{ display: 'flex' }}>
						<span>Families</span>
					</span>
				</Menu.Item>
			),
			render: () => (
				<Tab.Pane attached={false}>
					<IHopeGeneticHealthFamilies data={data} />
				</Tab.Pane>
			),
		},
		{
			menuItem: (
				<Menu.Item>
					<span style={{ display: 'flex' }}>
						<span>Clinicians</span>
					</span>
				</Menu.Item>
			),
			render: () => (
				<Tab.Pane attached={false}>
					<IHopeGeneticHealthClinicians data={data} />
				</Tab.Pane>
			),
		},
		{
			menuItem: (
				<Menu.Item>
					<span style={{ display: 'flex' }}>
						<span>Labs</span>
					</span>
				</Menu.Item>
			),
			render: () => (
				<Tab.Pane attached={false}>
					<IHopeGeneticHealthLabs data={data} />
				</Tab.Pane>
			),
		},
		{
			menuItem: (
				<Menu.Item>
					<span style={{ display: 'flex' }}>
						<span>Funders</span>
					</span>
				</Menu.Item>
			),
			render: () => (
				<Tab.Pane attached={false}>
					<IHopeGeneticHealthFunders data={data} />
				</Tab.Pane>
			),
		},
		{
			menuItem: (
				<Menu.Item>
					<span style={{ display: 'flex' }}>
						<span>Resources</span>
					</span>
				</Menu.Item>
			),
			render: () => (
				<Tab.Pane attached={false}>
					<IHopeGeneticHealthResources data={data} />
				</Tab.Pane>
			),
		},
	]
	const [activeTab, setActiveTab] = useState(0)

	const onTabChange = event => {
		const tabURL = event.target.textContent.toLowerCase().replaceAll(' ', '-')
		navigate(`/ihope-genetic-health/${tabURL}`)
	}

	useEffect(() => {
		if (location.pathname === '/ihope-genetic-health/overview') {
			setActiveTab(0)
		}
		if (location.pathname === '/ihope-genetic-health/families') {
			setActiveTab(1)
		}
		if (location.pathname === '/ihope-genetic-health/clinicians') {
			setActiveTab(2)
		}
		if (location.pathname === '/ihope-genetic-health/labs') {
			setActiveTab(3)
		}
		if (location.pathname === '/ihope-genetic-health/funders') {
			setActiveTab(4)
		}
		if (location.pathname === '/ihope-genetic-health/resources') {
			setActiveTab(5)
		}
	}, [location.pathname])

	const buildContent = []
	if (data) {
		console.log('data: ', data)
		const datas = data.filter(item => item.link === '/ihope-genetic-health')
		console.log('otherVideos: ', datas)
		if (datas && Array.isArray(datas) && datas.length > 0) {
			datas.sort((a, b) => b.index - a.index)
			datas.forEach((item, index) => {
				buildContent.push(
					<Grid.Row key={index}>
						<Grid.Column
							mobile={16}
							tablet={16}
							computer={8}
							style={{ alignSelf: 'center' }}>
							<h1>{item.header}</h1>
							{ReactHtmlParser(item.content)}
						</Grid.Column>
						<Grid.Column mobile={16} tablet={16} computer={8}>
							<Image
								src={item.imageSrc}
								alt={item.imageAlt}
							/>
							{ReactHtmlParser(item.image)}
						</Grid.Column>
					</Grid.Row>
				)
			})
		}
	}

	return (
		<div>
			<Container className='container-main'>
				<Grid columns={2} stackable reversed='mobile tablet vertically'>
					{buildContent}
				</Grid>
				{/* <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' /> */}
				<Tab
					menu={{ secondary: true, pointing: true }}
					panes={panes}
					onTabChange={onTabChange}
					className='tab-menu ihope-genetic-health mt-80-60-40'
					activeIndex={activeTab}
				/>
			</Container>
			<IHopeGeneticHealthNewsLetters />
		</div>
	)
}

export default IHopeGeneticHealthFillContentNew
