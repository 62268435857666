import { Button, Image, Progress } from 'semantic-ui-react'
import SaveButton from './SaveButton'

const NextBackStep = ({ step, setStep, totalSteps, next, data, files }) => {
	let percentage = (step / totalSteps) * 100
	const backStep = () => {
		setStep(i => {
			return i - 1
		})
	}
	const nextStep = () => {
		if (next === undefined) {
			setStep(i => {
				return i + 1
			})
		}
		if (next) {
			next()
		}
	}

	return (
		<>
			{step === 1 && (
				<div
					className='mt-40 mb-40'
					style={{ display: 'flex', justifyContent: 'center' }}>
					<Button
						className='button-orange-500 button-quarter-width'
						onClick={nextStep}>
						Next&nbsp;&nbsp;
						<Image src='/icons/link-internal-white.png' className='icon-width-16px' />
					</Button>
				</div>
			)}
			{step !== 1 && (
				<div
					className='mt-40 mb-40'
					style={{ display: 'flex', justifyContent: 'space-between' }}>
					<>
						<Button
							className='button-orange-500 button-quarter-width'
							onClick={backStep}>
							<Image
								src='/icons/link-internal-white.png'
								className='icon-width-16px'
								style={{ transform: 'scaleX(-1)' }}
							/>
							&nbsp;&nbsp;Back
						</Button>
					</>

					{step !== 14 && (
						<>
							<SaveButton step={step} data={data} files={files} />
						</>
					)}
					<Button
						className='button-orange-500 button-quarter-width'
						onClick={nextStep}>
						Next&nbsp;&nbsp;
						<Image src='/icons/link-internal-white.png' className='icon-width-16px' />
					</Button>
				</div>
			)}
			<div className='mb-8'>
				<Progress percent={percentage} className='progress-bar center' />
			</div>
			<p className='p-small text-center'>
				Page {step} of {totalSteps}
			</p>
		</>
	)
}

export default NextBackStep
