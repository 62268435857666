import { Container, Grid } from 'semantic-ui-react'
import Admin from '../../auth/Admin'
import Category from 'components/crud/Category'
import Tag from 'components/crud/Tag'
import NewsMenu from '../NewsMenu'

const CategoryTag = () => {
	return (
		<Admin>
			<Container className='container-news'>
				<NewsMenu />
				<h1 className='mb-80'>Manage Categories and Tags</h1>
                <Grid stackable>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <Category />
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Tag />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
			</Container>
		</Admin>
	)
}

export default CategoryTag
