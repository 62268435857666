import { Container, Grid, GridColumn, Image } from 'semantic-ui-react'
// import { Link } from 'react-router-dom'

function Angrist() {
	return (
		<>
			<Container className='container-main'>
				<h1>IRB Roster</h1>
				<Grid columns={1} padded>
					<GridColumn>
						<Image
							alt='Misha Angrist'
							src='/images/IRB/AngristHeadshot.jpeg'
							size='small'
							floated='left'
							rounded
						/>
						<h2>Misha Angrist</h2>
						<h3>IRB Board Member</h3>
						<p>
							Misha Angrist is an Assistant Professor in Duke's Institute for Genome
							Sciences & Policy, its Science and Society initiative, and a Visiting
							Lecturer in its Sanford School of Public Policy. He holds an MS in
							genetic counseling, a PhD in genetics and an MFA in writing. He is
							interested in the intersection of biology and society, especially as it
							relates to the governance of human participation in research and
							medicine. As the fourth participant in the Personal Genome Project, he
							was among the first to have his entire genome sequenced and made public.
							He chronicled this experience in his book, Here is a Human Being: At the
							Dawn of Personal Genomics (Harper 2010). He has served on the Duke IRB
							since 2009.
						</p>
					</GridColumn>
				</Grid>
			</Container>
		</>
	)
}

export default Angrist
