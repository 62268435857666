export const EngagementServicesIcon = (
	<svg
		className='mr-16'
		width='40'
		height='40'
		viewBox='0 0 40 40'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'>
		<g style={{ mixBlendMode: 'multiply' }}>
			<g style={{ mixBlendMode: 'multiply' }}>
				<path
					className='icon-engagement'
					d='M20.0003 17.3913C24.8028 17.3913 28.696 13.4981 28.696 8.69565C28.696 3.89318 24.8028 0 20.0003 0C15.1979 0 11.3047 3.89318 11.3047 8.69565C11.3047 13.4981 15.1979 17.3913 20.0003 17.3913Z'
				/>
			</g>
		</g>
		<g style={{ mixBlendMode: 'multiply' }}>
			<g style={{ mixBlendMode: 'multiply' }}>
				<path
					className='icon-engagement'
					d='M27.9925 20.7026C32.795 20.7026 36.6882 16.8094 36.6882 12.007C36.6882 7.20449 32.795 3.31131 27.9925 3.31131C23.1901 3.31131 19.2969 7.20449 19.2969 12.007C19.2969 16.8094 23.1901 20.7026 27.9925 20.7026Z'
				/>
			</g>
		</g>
		<g style={{ mixBlendMode: 'multiply' }}>
			<g style={{ mixBlendMode: 'multiply' }}>
				<path
					className='icon-engagement'
					d='M31.305 28.6957C36.1075 28.6957 40.0007 24.8025 40.0007 20C40.0007 15.1975 36.1075 11.3044 31.305 11.3044C26.5026 11.3044 22.6094 15.1975 22.6094 20C22.6094 24.8025 26.5026 28.6957 31.305 28.6957Z'
				/>
			</g>
		</g>
		<g style={{ mixBlendMode: 'multiply' }}>
			<g style={{ mixBlendMode: 'multiply' }}>
				<path
					className='icon-engagement'
					d='M27.9925 36.6887C32.795 36.6887 36.6882 32.7955 36.6882 27.993C36.6882 23.1906 32.795 19.2974 27.9925 19.2974C23.1901 19.2974 19.2969 23.1906 19.2969 27.993C19.2969 32.7955 23.1901 36.6887 27.9925 36.6887Z'
				/>
			</g>
		</g>
		<g style={{ mixBlendMode: 'multiply' }}>
			<g style={{ mixBlendMode: 'multiply' }}>
				<path
					className='icon-engagement'
					d='M20.0003 40C24.8028 40 28.696 36.1068 28.696 31.3043C28.696 26.5019 24.8028 22.6087 20.0003 22.6087C15.1979 22.6087 11.3047 26.5019 11.3047 31.3043C11.3047 36.1068 15.1979 40 20.0003 40Z'
				/>
			</g>
		</g>
		<g style={{ mixBlendMode: 'multiply' }}>
			<g style={{ mixBlendMode: 'multiply' }}>
				<path
					className='icon-engagement'
					d='M12.0082 36.6887C16.8106 36.6887 20.7038 32.7955 20.7038 27.993C20.7038 23.1906 16.8106 19.2974 12.0082 19.2974C7.20568 19.2974 3.3125 23.1906 3.3125 27.993C3.3125 32.7955 7.20568 36.6887 12.0082 36.6887Z'
				/>
			</g>
		</g>
		<g style={{ mixBlendMode: 'multiply' }}>
			<g style={{ mixBlendMode: 'multiply' }}>
				<path
					className='icon-engagement'
					d='M8.69565 28.6957C13.4981 28.6957 17.3913 24.8025 17.3913 20C17.3913 15.1975 13.4981 11.3044 8.69565 11.3044C3.89318 11.3044 0 15.1975 0 20C0 24.8025 3.89318 28.6957 8.69565 28.6957Z'
				/>
			</g>
		</g>
		<g style={{ mixBlendMode: 'multiply' }}>
			<g style={{ mixBlendMode: 'multiply' }}>
				<path
					className='icon-engagement'
					d='M12.0082 20.7026C16.8106 20.7026 20.7038 16.8094 20.7038 12.007C20.7038 7.20449 16.8106 3.31131 12.0082 3.31131C7.20568 3.31131 3.3125 7.20449 3.3125 12.007C3.3125 16.8094 7.20568 20.7026 12.0082 20.7026Z'
				/>
			</g>
		</g>
	</svg>
)

export const TrainingEducationIcon = (
	<svg
		className='mr-16'
		width='70'
		height='40'
		viewBox='0 0 70 40'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'>
		<g style={{ mixBlendMode: 'multiply' }}>
			<g style={{ mixBlendMode: 'multiply' }}>
				<path
					className='icon-training-1'
					d='M5.00013 37.9991C7.76163 37.9991 10.0003 29.9404 10.0003 19.9995C10.0003 10.0585 7.76163 1.99983 5.00013 1.99983C2.23864 1.99983 0 10.0585 0 19.9995C0 29.9404 2.23864 37.9991 5.00013 37.9991Z'
				/>
			</g>
		</g>
		<g style={{ mixBlendMode: 'multiply' }}>
			<g style={{ mixBlendMode: 'multiply' }}>
				<path
					className='icon-training-2'
					d='M20.0003 38.9995C25.5233 38.9995 30.0005 30.4929 30.0005 19.9995C30.0005 9.506 25.5233 0.999374 20.0003 0.999374C14.4773 0.999374 10 9.506 10 19.9995C10 30.4929 14.4773 38.9995 20.0003 38.9995Z'
				/>
			</g>
		</g>
		<g style={{ mixBlendMode: 'multiply' }}>
			<g style={{ mixBlendMode: 'multiply' }}>
				<path
					className='icon-training-3'
					d='M49.9995 39.9989C61.0449 39.9989 69.9989 31.0449 69.9989 19.9995C69.9989 8.95406 61.0449 0 49.9995 0C38.9541 0 30 8.95406 30 19.9995C30 31.0449 38.9541 39.9989 49.9995 39.9989Z'
				/>
			</g>
		</g>
	</svg>
)

export const ResearchServicesIcon = (
	<svg
		className='mr-16'
		width='44'
		height='40'
		viewBox='0 0 44 40'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'>
		<g style={{ mixBlendMode: 'multiply' }}>
			<g style={{ mixBlendMode: 'multiply' }}>
				<path
					className='icon-research'
					d='M21.5521 25.8621C28.6937 25.8621 34.4832 20.0726 34.4832 12.931C34.4832 5.78942 28.6937 0 21.5521 0C14.4105 0 8.62109 5.78942 8.62109 12.931C8.62109 20.0726 14.4105 25.8621 21.5521 25.8621Z'
				/>
			</g>
		</g>
		<g style={{ mixBlendMode: 'multiply' }}>
			<g style={{ mixBlendMode: 'multiply' }}>
				<path
					className='icon-research'
					d='M30.1732 40C37.3148 40 43.1043 34.2106 43.1043 27.069C43.1043 19.9274 37.3148 14.1379 30.1732 14.1379C23.0316 14.1379 17.2422 19.9274 17.2422 27.069C17.2422 34.2106 23.0316 40 30.1732 40Z'
				/>
			</g>
		</g>
		<g style={{ mixBlendMode: 'multiply' }}>
			<g style={{ mixBlendMode: 'multiply' }}>
				<path
					className='icon-research'
					d='M12.931 40C20.0726 40 25.8621 34.2106 25.8621 27.069C25.8621 19.9274 20.0726 14.1379 12.931 14.1379C5.78942 14.1379 0 19.9274 0 27.069C0 34.2106 5.78942 40 12.931 40Z'
				/>
			</g>
		</g>
	</svg>
)

export const NavDownIcon = (
	<svg className='nav-down-icon' width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M0.5 0.5L6 6.5L11.5 0.5" strokeLinecap="round" strokeLinejoin="round"/>
	</svg>
)

export const MenuBars = (
	<svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M0.5 0.5H14.5" stroke="#0B2F4D" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M0.5 6.5H14.5" stroke="#0B2F4D" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M0.5 12.5H14.5" stroke="#0B2F4D" strokeLinecap="round" strokeLinejoin="round"/>
	</svg>
)
