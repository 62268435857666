import { Grid, Form } from 'semantic-ui-react'
import { useEffect, useState } from 'react'
import './IRBApplication.css'
import NextBackStep from './NextBackStep'

function StepTen({
	step,
	setStep,
	totalSteps,
	updateFields,
	recruitOtherDescribe,
	materialOtherDescribe,
	...data
}) {
	let { potentialParticipants, recruitmentMaterials } = data
	
	useEffect(() => { console.log('shark', potentialParticipants) }, [potentialParticipants])
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	const [recruitOtherDescribeError, setRecruitOtherDescribeError] = useState(false)
	const [materialOtherDescribeError, setMaterialOtherDescribeError] = useState(false)
	const [potentialParticipantsError, setPotentialParticipantsError] =
		useState(false)

	const isRecruitOtherDescribeMissing = () => {
		if (potentialParticipants &&
			potentialParticipants.includes('Other') && recruitOtherDescribe === '') {
			setRecruitOtherDescribeError(true)
			return true
		}
		return false
	}

	const isMaterialOtherDescribeMissing = () => {
		if (recruitmentMaterials &&
			recruitmentMaterials.includes('Other') && materialOtherDescribe === '') {
			setMaterialOtherDescribeError(true)
			return true
		}
		return false
	}

	const isRecruitOtherMissing = () => {
		if (
			potentialParticipants &&
			potentialParticipants.length === 0 &&
			recruitOtherDescribe === ''
		) {
			setPotentialParticipantsError(true)
			return true
		}
		return false
	}

	let missingFields = 
		recruitOtherDescribeError ||
		materialOtherDescribeError ||
		potentialParticipantsError

	const next = () => {
		isRecruitOtherMissing()
		isRecruitOtherDescribeMissing()
		isMaterialOtherDescribeMissing()
		if (isRecruitOtherMissing() === false && 
			isRecruitOtherDescribeMissing() === false &&
			isMaterialOtherDescribeMissing() === false) {
			setStep(i => {
				return i + 1
			})
		}
		missingFields = true
	}

	return (
		<Grid centered>
			<Grid.Column computer={12}>
				<Form>
					<h3 className='mb-40'>
						Research Population — in accordance with 45CFR46.111(3)
					</h3>
					<p>
						Potential Research Participants will be recruited via (select all that
						apply) <span className='error-color'>*</span>
					</p>
					<Form.Checkbox
						label='Invitation from your organization/group'
						type='checkbox'
						defaultChecked={
							potentialParticipants &&
							potentialParticipants.includes('Invitation from your organization/group')
						}
						onChange={() => {
							if (
								potentialParticipants &&
								potentialParticipants.includes(
									'Invitation from your organization/group'
								)
							) {
								updateFields({
									potentialParticipants: potentialParticipants.filter(
										item => item !== 'Invitation from your organization/group'
									),
								})
							}
							if (potentialParticipants)
								potentialParticipants.push('Invitation from your organization/group')
							setPotentialParticipantsError(false)
						}}
					/>
					<Form.Checkbox
						label='Recruitment through the community'
						type='checkbox'
						defaultChecked={
							potentialParticipants &&
							potentialParticipants.includes('Recruitment through the community')
						}
						onChange={() => {
							if (
								potentialParticipants &&
								potentialParticipants.includes('Recruitment through the community')
							) {
								updateFields({
									potentialParticipants: potentialParticipants.filter(
										item => item !== 'Recruitment through the community'
									),
								})
							}
							if (potentialParticipants)
								potentialParticipants.push('Recruitment through the community')
							setPotentialParticipantsError(false)
						}}
					/>
					<Form.Checkbox
						label='Invitation through the usual course of clinical care'
						type='checkbox'
						defaultChecked={
							potentialParticipants &&
							potentialParticipants.includes(
								'Invitation through the usual course of clinical care'
							)
						}
						onChange={() => {
							if (
								potentialParticipants &&
								potentialParticipants.includes(
									'Invitation through the usual course of clinical care'
								)
							) {
								updateFields({
									potentialParticipants: potentialParticipants.filter(
										item =>
											item !== 'Invitation through the usual course of clinical care'
									),
								})
							}
							if (potentialParticipants)
								potentialParticipants.push(
									'Invitation through the usual course of clinical care'
								)
							setPotentialParticipantsError(false)
						}}
					/>
					<Form.Checkbox
						label='Self-referral in response to ads or websites'
						type='checkbox'
						defaultChecked={
							potentialParticipants &&
							potentialParticipants.includes(
								'Self-referral in response to ads or websites'
							)
						}
						onChange={() => {
							if (
								potentialParticipants &&
								potentialParticipants.includes(
									'Self-referral in response to ads or websites'
								)
							) {
								updateFields({
									potentialParticipants: potentialParticipants.filter(
										item => item !== 'Self-referral in response to ads or websites'
									),
								})
							}
							if (potentialParticipants)
								potentialParticipants.push(
									'Self-referral in response to ads or websites'
								)
							setPotentialParticipantsError(false)
						}}
					/>
					<Form.Checkbox
						label='Database searches'
						type='checkbox'
						defaultChecked={
							potentialParticipants &&
							potentialParticipants.includes('Database searches')
						}
						onChange={() => {
							if (
								potentialParticipants &&
								potentialParticipants.includes('Database searches')
							) {
								updateFields({
									potentialParticipants: potentialParticipants.filter(
										item => item !== 'Database searches'
									),
								})
							}
							if (potentialParticipants)
								potentialParticipants.push('Database searches')
							setPotentialParticipantsError(false)
						}}
					/>
					<Form.Checkbox
						label='Other - please describe'
						type='checkbox'
						defaultChecked={
							potentialParticipants && potentialParticipants.includes('Other')
						}
						onChange={() => {
							if (
								potentialParticipants &&
								potentialParticipants.includes('Other')
							) {
								updateFields({
									potentialParticipants: potentialParticipants.filter(
										item => item !== 'Other'
									),
								})
								return
							}
							updateFields({ potentialParticipants: [...potentialParticipants, 'Other']})
							setPotentialParticipantsError(false)
						}}
					/>
					{ potentialParticipants &&
					potentialParticipants.includes('Other') && (
						<Form.Input
							placeholder='Other - please describe'
							type='text'
							value={recruitOtherDescribe}
							onChange={e => {
								setPotentialParticipantsError(false)
								setRecruitOtherDescribeError(false)
								updateFields({ recruitOtherDescribe: e.target.value })
							}}
						/>
					)}
					{/* <p className='label-input'>Other:</p>
					<Form.Input
						placeholder='Please Describe'
						type='text'
						value={recruitOtherDescribe}
						onChange={e => {
							setPotentialParticipantsError(false)
							updateFields({ recruitOtherDescribe: e.target.value })
						}}
					/> */}
					{recruitOtherDescribeError && (
						<p className='error'>Description is required.</p>
					)}
					{potentialParticipantsError && (
						<p className='error'>
							At least one option is required above.
						</p>
					)}
					<p className='mt-40'>
						Recruitment materials including, advertisements, flyers, and/or brochures
						will be used and will be distributed via (select all that apply)
					</p>
					<Form.Checkbox
						label='Websites'
						type='checkbox'
						defaultChecked={
							recruitmentMaterials && recruitmentMaterials.includes('Websites')
						}
						onChange={() => {
							if (recruitmentMaterials && recruitmentMaterials.includes('Websites')) {
								updateFields({
									recruitmentMaterials: recruitmentMaterials.filter(
										item => item !== 'Websites'
									),
								})
							}
							if (recruitmentMaterials) recruitmentMaterials.push('Websites')
						}}
					/>
					<Form.Checkbox
						label='Email lists'
						type='checkbox'
						defaultChecked={
							recruitmentMaterials && recruitmentMaterials.includes('Email lists')
						}
						onChange={() => {
							if (
								recruitmentMaterials &&
								recruitmentMaterials.includes('Email lists')
							) {
								updateFields({
									recruitmentMaterials: recruitmentMaterials.filter(
										item => item !== 'Email lists'
									),
								})
							}
							if (recruitmentMaterials) recruitmentMaterials.push('Email lists')
						}}
					/>
					<Form.Checkbox
						label='Social media'
						type='checkbox'
						defaultChecked={
							recruitmentMaterials && recruitmentMaterials.includes('Social media')
						}
						onChange={() => {
							if (
								recruitmentMaterials &&
								recruitmentMaterials.includes('Social media')
							) {
								updateFields({
									recruitmentMaterials: recruitmentMaterials.filter(
										item => item !== 'Social media'
									),
								})
							}
							if (recruitmentMaterials) recruitmentMaterials.push('Social media')
						}}
					/>
					<Form.Checkbox
						label='Hospital/Clinic'
						type='checkbox'
						defaultChecked={
							recruitmentMaterials && recruitmentMaterials.includes('Hospital/Clinic')
						}
						onChange={() => {
							if (
								recruitmentMaterials &&
								recruitmentMaterials.includes('Hospital/Clinic')
							) {
								updateFields({
									recruitmentMaterials: recruitmentMaterials.filter(
										item => item !== 'Hospital/Clinic'
									),
								})
							}
							if (recruitmentMaterials) recruitmentMaterials.push('Hospital/Clinic')
						}}
					/>
					<Form.Checkbox
						label='Local communities'
						type='checkbox'
						defaultChecked={
							recruitmentMaterials &&
							recruitmentMaterials.includes('Local communities')
						}
						onChange={() => {
							if (
								recruitmentMaterials &&
								recruitmentMaterials.includes('Local communities')
							) {
								updateFields({
									recruitmentMaterials: recruitmentMaterials.filter(
										item => item !== 'Local communities'
									),
								})
							}
							if (recruitmentMaterials) recruitmentMaterials.push('Local communities')
						}}
					/>
					<Form.Checkbox
						label='Newspapers'
						type='checkbox'
						defaultChecked={
							recruitmentMaterials && recruitmentMaterials.includes('Newspapers')
						}
						onChange={() => {
							if (
								recruitmentMaterials &&
								recruitmentMaterials.includes('Newspapers')
							) {
								updateFields({
									recruitmentMaterials: recruitmentMaterials.filter(
										item => item !== 'Newspapers'
									),
								})
							}
							if (recruitmentMaterials) recruitmentMaterials.push('Newspapers')
						}}
					/>
					<Form.Checkbox
						label='Radio'
						type='checkbox'
						defaultChecked={
							recruitmentMaterials && recruitmentMaterials.includes('Radio')
						}
						onChange={() => {
							if (recruitmentMaterials && recruitmentMaterials.includes('Radio')) {
								updateFields({
									recruitmentMaterials: recruitmentMaterials.filter(
										item => item !== 'Radio'
									),
								})
							}
							if (recruitmentMaterials) recruitmentMaterials.push('Radio')
						}}
					/>
					<Form.Checkbox
						label='Television'
						type='checkbox'
						defaultChecked={
							recruitmentMaterials && recruitmentMaterials.includes('Television')
						}
						onChange={() => {
							if (
								recruitmentMaterials &&
								recruitmentMaterials.includes('Television')
							) {
								updateFields({
									recruitmentMaterials: recruitmentMaterials.filter(
										item => item !== 'Television'
									),
								})
							}
							if (recruitmentMaterials) recruitmentMaterials.push('Television')
						}}
					/>
					<Form.Checkbox
						label='Other - please describe'
						type='checkbox'
						defaultChecked={
							recruitmentMaterials && recruitmentMaterials.includes('Other')
						}
						onChange={() => {
							if (
								recruitmentMaterials &&
								recruitmentMaterials.includes('Other')
							) {
								updateFields({
									recruitmentMaterials: recruitmentMaterials.filter(
										item => item !== 'Other'
									),
								})
								return
							}
							updateFields({ recruitmentMaterials: [...recruitmentMaterials, 'Other']})
						}}
					/>
					{ recruitmentMaterials &&
					recruitmentMaterials.includes('Other') && (
						<Form.Input
							placeholder='Other - please describe'
							type='text'
							value={materialOtherDescribe}
							onChange={e => {
								setPotentialParticipantsError(false)
								setMaterialOtherDescribeError(false)
								updateFields({ materialOtherDescribe: e.target.value })
							}}
						/>
					)}
					{/* <p className='label-input'>Other:</p>
					<Form.Input
						placeholder='Add a description of the activity/medium – (e.g., recordings or phone messages)'
						type='text'
						value={materialOtherDescribe}
						onChange={e => updateFields({ materialOtherDescribe: e.target.value })}
					/> */}
					{materialOtherDescribeError && (
						<p className='error'>Description is required.</p>
					)}
				</Form>
				{missingFields && (
					<p className='error missing-field'>
						Please fill all required fields above.
					</p>
				)}
				<NextBackStep
					step={step}
					totalSteps={totalSteps}
					next={next}
					setStep={setStep}
					data={data}
				/>
			</Grid.Column>
		</Grid>
	)
}

export default StepTen
