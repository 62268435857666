import { Container, Grid, GridColumn, Image } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import ShareSocialMedia from '../../../ShareSocialMedia'

function AboutAdvocacyATLAS() {
	const shareUrl = 'https://geneticalliance.org/resources-and-information/advocacy-atlas/about'
	return (
		<Container className='container-main'>
			<h1>About the ATLAS</h1>
			<Grid columns={1} padded>
				<GridColumn>
					<hr />
					<ShareSocialMedia shareUrl={shareUrl} />
					<h2 className='color-secondary'>What is the Advocacy ATLAS?</h2>
					<div>
						<p>
							Every day individuals advocate on behalf of themselves or others to communicate needs, share experiences, and take steps to get what they want and need. The online Advocacy ATLAS is a toolkit created by {' '}
							<Link className='link-blue-800'
								to='/'
								>
								Genetic Alliance
							</Link>, {' '}
							<a className='link-blue-800'
								href='https://www.p2pusa.org/'
								rel='noreferrer'
								target='_blank'>
								Parent to Parent USA
							</a>, and {' '}
							<a className='link-blue-800'
								href='https://familyvoices.org/'
								rel='noreferrer'
								target='_blank'>
								Family Voices
							</a>{' '} 
							that provides individuals with special healthcare needs and their families with tools and strategies to advocate for whatever they may need. Resources are grouped by category on the Advocacy ATLAS landing page. Simply click one of the ten topic areas to get started browsing accessible tools!
						</p>

						<p className='mt-40 mb-40'>
							<b>View the archived webinars below to learn more about the Advocacy ATLAS and to hear stories from self and parent advocates about their experiences with advocacy.</b>
						</p>

						<Grid stackable columns={3} className='mt-15 center aligned'>
							<Grid.Row>
								<Grid.Column className='pl-0'>
									<a
										href='https://www.youtube.com/watch?v=ZBDadZ5ahOw&feature=youtu.be'
										target='_blank'
										rel='noreferrer'
										className='mb-40 link-blue-800'>
										<Image
											src='/images/resources-and-information/advocacy-atlas/about/empowering-individuals.jpeg'
											size='medium'
											centered
										/>
									</a>
									<a className='link-blue-800'
										href='https://www.youtube.com/watch?v=ZBDadZ5ahOw&feature=youtu.be'
										target='_blank'
										rel='noreferrer'>
										<p className='mt-20'><b>Webinar 1:</b> Empowering Individuals and Families as Advocates</p>
									</a>
								</Grid.Column>

								<Grid.Column>
									<a
										href='https://www.youtube.com/watch?v=J5ZzvSCeu-U&feature=youtu.be'
										target='_blank'
										rel='noreferrer'
										className='mb-40 link-blue-800'>
										<Image
											src='/images/resources-and-information/advocacy-atlas/about/resources-for-advocacy.jpeg'
											size='medium'
											centered
										/>
									</a>
									<a className='link-blue-800'
										href='https://www.youtube.com/watch?v=J5ZzvSCeu-U&feature=youtu.be'
										target='_blank'
										rel='noreferrer'>
										<p className='mt-20'><b>Webinar 2:</b> Resources for Advocacy Through the Transition Years and Beyond</p>
									</a>
								</Grid.Column>

								<Grid.Column className='pr-0'>
									<a
										href='https://www.youtube.com/watch?v=bnV354YT__0&feature=youtu.be'
										target='_blank'
										rel='noreferrer'
										className='mb-40 link-blue-800'>
										<Image
											src='/images/resources-and-information/advocacy-atlas/about/resources-on-access.jpeg'
											size='medium'
											centered
										/>
									</a>
									<a className='link-blue-800'
										href='https://www.youtube.com/watch?v=bnV354YT__0&feature=youtu.be'
										target='_blank'
										rel='noreferrer'>
										<p className='mt-20'><b>Webinar 3:</b> Resources on Access, Support, and Advocacy in the Health and Insurance Worlds</p>
									</a>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</div>

					<h2 className='color-secondary mt-40'>How was the ATLAS developed?</h2>
					<div>
						<p>
							Genetic Alliance, Parent to Parent USA, and Family Voices collected tools and resources from their vast combined networks of parents, parent advocacy groups, disease-specific advocacy groups, disability groups, and other partners. The development team, made up of leaders from Genetic Alliance, Parent to Parent USA, and Family Voices, developed criteria for inclusion, resource categories, and an easy to use format for the toolkit. Individuals with special healthcare needs and their families were an integral part of design: providing guidance and feedback during each step of development to ensure the toolkit is useful and easy to use. Families shared personal stories about advocacy to be included on the site. Thank you very much to everyone who has contributed to the site! 
						</p>
					</div>

					<h2 className='color-secondary mt-40'>What resources are included in the ATLAS?</h2>
					<div>
						<p>
							All resources have been carefully vetted by the development team to ensure they are relevant and accessible. The criteria used to screen each resource are as follows:<br/><br/>

							<b>Relevance:</b><br/>
							<ul className='list-right list-space'>
								<li>Is this resource considered relevant (up-to-date)?</li>
								<li>Are consumers (families and individuals) the target audience for this resource?</li>
							</ul><br/>

							<b>Content:</b><br/>
							<ul className='list-right list-space'>
								<li>Does the resource use person-first language?</li>
								<li>Is the content of the resource easy to read? (low reading level, accessible language)</li>
								<li>Is the content of the resource considered a best practice/evidence-based?</li>
								<li>Does the resource align with an overall vision of inclusion, community living, and self-determination?</li>
							</ul><br/>

							<b>Format:</b><br/>
							<ul className='list-right list-space'>
								<li>Is the resource easy to navigate and understand? (clear, concise, intuitive)</li>
								<li>All resources must be free to access and available online.</li>
							</ul>
						</p>
						<p className='mt-40'>
							<b>Do you know of additional resources for the Advocacy ATLAS? Send your resources to {' '}
							<a className='link-blue-800' href='mailto:info@geneticalliance.org'>info@geneticalliance.org</a>.</b>
						</p>
					</div>

					<h2 className='color-secondary mt-40'>Thank you to our funding partners</h2>
					<div>
						<p>
						Advocacy ATLAS was developed by Genetic Alliance in partnership with Parent to Parent USA and Family Voices with support from the Health Resource and Service Administration (HRSA), Grant No. U22MC04100, National Coordinating Center for the Regional Genetic Services Collaboratives. This project was funded in part by the Maternal and Child Health Bureau, Health Resources and Services Administration (HRSA) Grant No. U35MC16451, Congenital Conditions Program, in partnership with The Arc and Family Voices. <b>The content of this webpage is solely the responsibility of Genetic Alliance and does not necessarily represent the official views of HRSA.</b>
						</p>
					</div>
				</GridColumn>
			</Grid>
		</Container>
	)
}

export default AboutAdvocacyATLAS
