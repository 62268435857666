import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import renderHtml from 'react-render-html'
import { Image, Card } from 'semantic-ui-react'

const SmallCard = ({ blog }) => {
	return (
		<Card.Group>
			<Card style={{ width: 'auto' }}>
				<Link to={`/news/${blog.slug}`}>
					<Image
						className=''
						// style={{ maxWidth: '440px', height: 'auto' }}
						src={`${process.env.REACT_APP_API_URL}/blog/photo/${blog.slug}`}
						alt={blog.title}
						wrapped
					/>
				</Link>
				<Card.Content>
					<Card.Header>
						<Link to={`/news/${blog.slug}`}>
							<h3 className='mb-15'>{blog.title}</h3>
						</Link>
					</Card.Header>
					<Card.Description>{renderHtml(blog.excerpt)}</Card.Description>
				</Card.Content>

				<Card.Content extra>
					Posted {moment(blog.updatedAt).fromNow()}
					<Link to={`/profile/${blog.postedBy.username}`} className='fr'>
						{blog.postedBy.username}
					</Link>
				</Card.Content>
			</Card>
		</Card.Group>
	)
}

export default SmallCard
