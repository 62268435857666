import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { progressIndicator } from '../../content/HelperFunction'
import { Container, Grid } from 'semantic-ui-react'

function OnlineCurriculumFillContent({ data }) {
    const { pathname, hash, key } = useLocation()
    useEffect(() => {
        // if not a hash link, scroll to top
        if (hash === '') {
            window.scrollTo(0, 0)
        }
        // else scroll to id
        else {
            setTimeout(() => {
                const id = hash.replace('#', '')
                const element = document.getElementById(id)
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' })
                }
            }, 0)
        }
    }, [pathname, hash, key])

    useEffect(() => {
        progressIndicator()
    }, [])

    if (!data) {
        return null
    }
    const datas = data.filter(item => item.link === '/registries/bootcamp/online-curriculum')
    if (datas && Array.isArray(datas) && datas.length > 0) {
        datas.sort((a, b) => a.index - b.index)
    } else {
        return null
    }
    return (
        <>
            <Container>
                <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
                <Grid columns={2} stackable>
                    <Grid.Column mobile={16} tablet={16} computer={8}>
                        <h3 id={datas[0].id} className='bootcamp computer-only' style={{ maxWidth: '600px' }}>{datas[0].header}</h3>
                        <h3 className='bootcamp mobile-and-tablet-only'>{datas[0].header}</h3>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={8} className='mt-15'>
                        <p>
                            <a
                                href='https://www.registrybootcamp.org/'
                                target='_blank'
                                rel='noreferrer'
                            >
                                RegistryBootcamp.org
                            </a>
                            {' '}{datas[0].content}
                        </p>
                    </Grid.Column>
                </Grid>
            </Container>
        </>
    )
}

export default OnlineCurriculumFillContent
