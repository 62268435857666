import { forgotPassword } from 'actions/auth'
import { useState } from 'react'
import { Container, Form, Grid, GridColumn } from 'semantic-ui-react'

const ForgotPassword = () => {
	const [values, setValues] = useState({
		email: '',
		message: '',
		error: '',
		showForm: true,
	})

	const { email, message, error, showForm } = values

	const handleChange = name => e => {
		setValues({ ...values, message: '', error: '', [name]: e.target.value })
	}

	const handleSubmit = e => {
		e.preventDefault()
		setValues({ ...values, message: '', error: '' })
		forgotPassword({ email }).then(data => {
			if (data.error) {
				setValues({ ...values, error: data.error })
			} else {
				setValues({ ...values, email: '', message: data.message, showForm: false })
			}
		})
	}

	const showError = () =>
		error ? <div className='alert alert-info'>{error}</div> : ''
	const showMessage = () =>
		message ? <div className='alert alert-info'>{message}</div> : ''

	const passwordForgotForm = () => (
		<Form onSubmit={handleSubmit}>
			<Form.Field
				label='Email'
				control='input'
				type='email'
				onChange={handleChange('email')}
				className='form-control'
				value={email}
				placeholder='Type your email'
				required
			/>
			<Form.Button className='mt-15' color='blue'>
				Send password reset link
			</Form.Button>
		</Form>
	)

	return (
		<Container className='container-main'>
			<h1 className='text-center'>Forgot Password</h1>
			<Grid centered columns={2}>
				<GridColumn>
					{showMessage()}
					{showError()}
					{showForm && passwordForgotForm()}
				</GridColumn>
			</Grid>
		</Container>
	)
}

export default ForgotPassword
