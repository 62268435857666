import { Link, useLocation } from 'react-router-dom'
import { Container, Grid, GridColumn, Image, Button } from 'semantic-ui-react'
import { useEffect } from 'react'
import { progressIndicator } from '../../content/HelperFunction'
import ReactHtmlParser from 'react-html-parser'

function WebinarArchivesFillContent({ data }) {

	const { pathname, hash, key } = useLocation()
	useEffect(() => {
		// if not a hash link, scroll to top
		if (hash === '') {
			window.scrollTo(0, 0)
		}
		// else scroll to id
		else {
			setTimeout(() => {
				const id = hash.replace('#', '')
				const element = document.getElementById(id)
				if (element) {
					element.scrollIntoView({ behavior: 'smooth' })
				}
			}, 0)
		}
	}, [pathname, hash, key])

	useEffect(() => {
		progressIndicator()
	}, [])

	console.log('WebinarsFillContent data', data)
	if (!data) {
		return null
	}
	
	const datas = data.filter(item => item.link === '/events/webinar-archives')
	if (datas && Array.isArray(datas) && datas.length > 0) {
		datas.sort((a, b) => a.index - b.index)
	} else {
		return null
	}

	return (
		<Container className='container-main'>
			<Grid columns={2}>
				<Grid.Column mobile={16} tablet={8} computer={8}>
					<h1 id={datas[0].id}>{datas[0].header}</h1>
				</Grid.Column>
				<Grid.Column mobile={16} tablet={8} computer={8}>
					<p className='subtitle'>
						{datas[0].content}{' '}
						<Link
							className='link-blue-800 subtitle'
							to='/events/webinars'
						>
							Webinars page
						</Link>.
					</p>
				</Grid.Column>
			</Grid>
			<hr className='rule-orange-500 mt-80-60-40 mb-65-55-25' />
			<Grid columns={2}>
				<Grid.Column mobile={16} tablet={8} computer={8}>
					<h3 id={datas[1].id}>{datas[1].header}</h3>
				</Grid.Column>
				<Grid.Column mobile={16} tablet={8} computer={8} className='display-flex justify-center'>
					<p>
						<Image
							src={datas[1].imageSrc}
							size='large'
						/>
						<a href={datas[1].hrefYouTube} target='_blank' rel='noreferrer' className='display-flex justify-center link-button'>
							<Button className='button-neutral-100 mt-25' style={{ margin: '0' }}>
								{datas[1].buttonText}
								<Image src='/icons/link-internal-blue.png' className='icon-width-16px' />
							</Button>
						</a>
					</p>
				</Grid.Column>
			</Grid>
			<hr className='rule-blue-800 mt-80-60-40 mb-80-60-40' />
			<Grid columns={2}>
				<Grid.Column mobile={16} tablet={8} computer={8}>
					{ReactHtmlParser(datas[2].header)}
				</Grid.Column>
				<Grid.Column mobile={16} tablet={8} computer={8} className='display-flex justify-center'>
					<p>
						<Image
							src={datas[2].imageSrc}
							size='medium'
						/>
						<Link to={datas[2].linkTo} rel='noreferrer' className='display-flex justify-center link-button'>
							<Button className='button-neutral-100 mt-25' style={{ margin: '0' }}>
								{datas[2].buttonText}
								<Image src='/icons/link-internal-blue.png' className='icon-width-16px' />
							</Button>
						</Link>
					</p>
				</Grid.Column>
			</Grid>
			<hr className='rule-blue-800 mt-80-60-40 mb-80-60-40' />
			<Grid columns={2}>
				<Grid.Column mobile={16} tablet={8} computer={8}>
					{ReactHtmlParser(datas[3].header)}
				</Grid.Column>
				<Grid.Column mobile={16} tablet={8} computer={8} className='display-flex justify-center'>
					<p>
						<Image
							src={datas[3].imageSrc}
							size='medium'
						/>
						<Link to={datas[3].linkTo} rel='noreferrer' className='display-flex justify-center link-button'>
							<Button className='button-neutral-100 mt-25' style={{ margin: '0' }}>
								{datas[3].buttonText}
								<Image src='/icons/link-internal-blue.png' className='icon-width-16px' />
							</Button>
						</Link>
					</p>
				</Grid.Column>
			</Grid>
			<hr className='rule-blue-800 mt-80-60-40 mb-80-60-40' />
			<Grid columns={1} padded>
				<GridColumn>
					<Grid stackable columns={2} className='mt-15 left aligned'>
						<Grid.Row>
							<Grid.Column>
								{ReactHtmlParser(datas[4].header)}
							</Grid.Column>
							<Grid.Column className='mt-100'>
								<a
									href={datas[4].hrefYouTube}
									target='_blank'
									rel='noreferrer'>
									<Image
										src={datas[4].imageSrc}
										size='large'
										className='border-solid'
										centered />
								</a>
							</Grid.Column>
						</Grid.Row>
					</Grid>
					{ReactHtmlParser(datas[5].content)}
					<hr className='rule-blue-800 mt-80-60-40 mb-80-60-40' />
					{ReactHtmlParser(datas[6].content)}
				</GridColumn>
			</Grid>
		</Container>
	)
}

export default WebinarArchivesFillContent 
