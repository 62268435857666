import { Grid, Form } from 'semantic-ui-react'
import { useEffect, useState } from 'react'
import './IRBApplication.css'
import NextBackStep from './NextBackStep'

function StepSeven({
	step,
	setStep,
	totalSteps,
	updateFields,
	surveyInstruments,
	surveyInstrumentsList,
	design,
	...data
}) {
	const [designError, setDesignError] = useState(false)
	const [overWordLimit, setOverWordLimit] = useState(false)
	const [surveyInstrumentsError, setSurveyInstrumentsError] = useState(false)
	const [surveyInstrumentsListError, setSurveyInstrumentsListError] = 
		useState(false)

	const countWords = str => {
		let wordCount = 0
		if (str) {
			let words = str.split(' ')
			for (let i = 0; i < words.length; i++) {
				if (words[i] !== '') {
					wordCount++
				}
			}
		}
		return wordCount
	}

	useEffect(() => {
		if (countWords(design) > 500) {
			setOverWordLimit(true)
		} else {
			setOverWordLimit(false)
		}
	}, [design])

	const isDesignMissing = () => {
		if (design === '') {
			setDesignError(true)
			missingFields = true

			return true
		}
		if (design && design.split(' ').length > 500) {
			setDesignError(true)
			return true
		}
		return false
	}

	const isSurveyInstrumentsMissing = () => {
		if (!surveyInstruments) {
			setSurveyInstrumentsError(true)
			return true
		}
		return false
	}

	const isSurveyInstrumentsListMissing = () => {
		if (surveyInstruments === 'Yes' && surveyInstrumentsList === '') {
			setSurveyInstrumentsListError(true)
			return true
		}
		return false
	}

	let missingFields = 
		designError ||
		surveyInstrumentsError ||
		surveyInstrumentsListError

	const next = () => {
		isDesignMissing()
		isSurveyInstrumentsMissing()
		isSurveyInstrumentsListMissing()
		if (isDesignMissing() === false && 
			isSurveyInstrumentsMissing() === false && 
			isSurveyInstrumentsListMissing() === false
			) {
			setStep(i => {
				return i + 1
			})
		}
	}

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<Grid centered>
			<Grid.Column computer={12}>
				<Form>
					<h3 className='mb-40'>Research Design, Methodology, and Data Analysis</h3>
					<p>
						Research design is a plan to answer your research question. A research method is a strategy used to implement that plan. Research design and methods are different but closely related because good research design ensures that the data you obtain will help you answer your research question more effectively. If you know some aspects of your data analysis plan, provide them in this section.
					</p>
					<p className='mt-25 mb-40'>
						Please do not duplicate your abstract.
					</p>
					<Form.TextArea
						label='Research Design, Methodology, and Data Analysis'
						placeholder='Research Design, Methodology, and Data Analysis'
						required
						type='text'
						value={design}
						onChange={e => {
							setDesignError(false)
							updateFields({ design: e.target.value })
						}}
					/>
					<p className={overWordLimit ? 'p-small error' : 'p-small'}>
						{countWords(design)}/500 words
					</p>
					{missingFields && (
						<p className='error'>
							Research Design, Methodology, and Data Analysis is required and must be less than 500 words.
						</p>
					)}
					<p className='mt-40'>
						Does your protocol make use of survey instruments? <span className='error-color'>*</span>
					</p>
					<Form.Group className='display-block'>
						<Form.Radio
							className='mb-15'
							label='Yes'
							type='radio'
							name='surveyInstruments'
							checked={surveyInstruments === 'Yes'}
							value='Yes'
							onChange={e => {
								setSurveyInstrumentsError(false)
								updateFields({ surveyInstruments: 'Yes' })
							}}
						/>
						<Form.Radio
							label='No'
							type='radio'
							name='surveyInstruments'
							checked={surveyInstruments === 'No'}
							value='No'
							onChange={e => {
								setSurveyInstrumentsError(false)
								updateFields({ surveyInstruments: 'No' })
							}}
						/>
					</Form.Group>
					{surveyInstrumentsError && <p className='error'>Please select an option.</p>}
					{surveyInstruments === 'Yes' && (
						<>
							<p className='mt-40'>List out each survey instrument.</p>
							<Form.TextArea
								placeholder='List out each survey instrument'
								label='List out each survey instrument'
								className='mb-0'
								required={surveyInstruments === 'Yes'}
								type='text'
								value={surveyInstrumentsList}
								onChange={e => {
									setSurveyInstrumentsListError(false)
									updateFields({ surveyInstrumentsList: e.target.value })
								}}
							/>
							{surveyInstrumentsListError && (
								<p className='error'>List of survey instruments is required.</p>
							)}
							<p className='mt-40'>
								You will upload copies of your survey instruments on page 14 of this application. Only the survey instruments listed here and attached to this application receive IRB-approval.
							</p>
							<p>
								After IRB approval, a survey can have its wording changed if issues in understanding are discovered during testing. Examples include spelling and grammar corrections, typos that are glaring and cause confusion or misconceptions in a question. In all instances, it is the responsibility of the PI to ensure that the enacted changes do not increase participant burden substantially nor negatively impact the participant experience in responding to the survey. If there is any concern that participant burden may be increased, ambiguity around whether a change would be permitted, or even questions around whether the use of this functionality will reduce data-collection quality and survey integrity, it is the responsibility of the study PI to submit the proposed, modified survey to the IRB for review as part of an amendment application.
							</p>
						</>
					)}
				</Form>
				<NextBackStep
					step={step}
					totalSteps={totalSteps}
					next={next}
					setStep={setStep}
					data={data}
				/>
			</Grid.Column>
		</Grid>
	)
}

export default StepSeven
