import fetch from 'isomorphic-fetch'
import FileDownload from 'js-file-download'
import { handleResponse } from './auth'

export const sendEmailResumed = (id, email) => {

	console.log('id', id)
	console.log('email', email)

	var requestOptions = {
		'method': 'POST',
		// 'url': 'http://localhost:8000/api/irb/application/send-email-resumed',
		'headers': {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			"id": id,
			"email": email
		})

	}

	return fetch(
		`${process.env.REACT_APP_API_URL}/irb/application/send-email-resumed`,
		requestOptions
	).then(response => {
		console.log('response', response)
		handleResponse(response)
		return response.json()
	}).catch(err => console.log(err))
}

export const create = (application, files, token) => {
	// application = setDataForQuickTest()
	console.log('files', files)
	console.log('data', JSON.stringify(application))
	const formData = new FormData()
	if (files) {
		files.forEach(file => {
			formData.append('files', file)
		})
	}
	formData.append('data', JSON.stringify(application))
	var requestOptions = {
		method: 'POST',
		body: formData,
		redirect: 'follow',
		Authorization: `Bearer ${token}`,
	}
	return fetch(
		`${process.env.REACT_APP_API_URL}/irb/application`,
		requestOptions
	)
		.then(response => {
			console.log('response', response) // _id: "6377d4791e7ff01751686b11" -> http://localhost:8000/api/irb/application/6377d4791e7ff01751686b11
			handleResponse(response)
			return response.json()
		})
		.catch(err => console.log(err))
}

export const update = (data, files, token, id) => {
	console.log('update data', JSON.stringify(data))
	console.log('update files', files)
	const formData = new FormData()
	if (files) {
		for (let i = 0; i < files.length; i++) {
			formData.append('files', files[i])
		}
	}
	formData.append('data', JSON.stringify(data))

	var requestOptions = {
		method: 'PUT',
		body: formData,
		redirect: 'follow',
		Authorization: `Bearer ${token}`,
	}

	return fetch(
		`${process.env.REACT_APP_API_URL}/irb/application`,
		requestOptions
	)
		.then(response => {
			handleResponse(response)
			return response.json()
		})
		.catch(err => console.log(err))
}

export const getApplications = () => {
	return fetch(`${process.env.REACT_APP_API_URL}/irb/applications`, {
		method: 'GET',
	})
		.then(response => {
			return response.json()
		})
		.catch(err => console.log(err))
}

export const singleApplication = id => {
	console.log('call singleApplication id', id)
	return fetch(`${process.env.REACT_APP_API_URL}/irb/application/${id}`, {
		method: 'GET',
	})
		.then(response => {
			return response.json()
		})
		.catch(err => console.log(err))
}

export const resumedApplication = id => {
	console.log('call singleApplication id', id)
	return fetch(`${process.env.REACT_APP_API_URL}/irb/application/${id}`, {
		method: 'GET',
	}).then(response => {
		console.log('resumedApplication response', response)
		if (response.status === 'incompleted') {
			return response.json()
		} else {
			return {}
		}
	})
		.catch(err => console.log(err))
}

export const singleApplicationFile = applicationId => {
	return fetch(
		`${process.env.REACT_APP_API_URL}/irb/applicationFile/${applicationId}`,
		{
			method: 'GET',
		}
	)
		.then(response => {
			return response.json()
		})
		.catch(err => console.log(err))
}

/**
 * Call API generate application word file and download it
 * @param {*} id
 */
export const downloadFileGenerateById = id => {
	console.log('id', id)
	var requestOptions = {
		method: 'GET',
		// responseType: 'blob'
	}

	fetch(
		`${process.env.REACT_APP_API_URL}/irb/application/downloadFileGenerate/${id}`,
		requestOptions
	)
		.then(response => response.text())
		.then(result => {
			console.log('result', JSON.parse(result))
			downloadFileUploadedByPath(JSON.parse(result).key, "Application.docx")
		})
		.catch(err => console.log(err))
}

export const downloadFileUploadedByPath = (pathFile, fileName) => {
	console.log('pathFile', pathFile)

	var myHeaders = new Headers()
	myHeaders.append('Content-Type', 'application/json')

	var raw = JSON.stringify({
		key: pathFile,
	})

	var requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow',
	}

	fetch(
		`${process.env.REACT_APP_API_URL}/irb/application/download-file-from-s3`,
		requestOptions
	)
		.then(response => response.blob())
		.then(result => {
			console.log('result', result)
			console.log('fileName', fileName)
			FileDownload(result, fileName)
		})
		.catch(err => console.log(err))
}

export const downloadFileUploadedByInfo = file => {
	console.log('pathFile', file)

	var myHeaders = new Headers()
	myHeaders.append('Content-Type', 'application/json')

	var raw = JSON.stringify({
		key: file.pathFile,
	})

	var requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow',
	}

	fetch(
		`${process.env.REACT_APP_API_URL}/irb/application/download-file-from-s3`,
		requestOptions
	)
		.then(response => response.blob())
		.then(result => {
			console.log('result', result)
			FileDownload(result, file.fileName)
		})
		.catch(err => console.log(err))
}

export const downloadFileUploadedById2 = applicationId => {
	console.log('id', applicationId)
	// applicationId -> applicationFileId -> file

	singleApplicationFile(applicationId).then(data => {
		console.log('data', data)
		if (data.error) {
			console.log('error', data.error)
		} else {
			var myHeaders = new Headers()
			myHeaders.append('Content-Type', 'application/json')

			var raw = JSON.stringify({
				key: 'irb-application/636408752f6405133dbb0820/20221104012909_abc.docx',
			})

			var requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: raw,
				redirect: 'follow',
			}

			// var requestOptions = {
			//     method: 'POST',
			//     responseType: 'blob',
			//     body: JSON.stringify({ key: data.pathFile })
			// };

			fetch(
				`${process.env.REACT_APP_API_URL}/irb/application/download-file-from-s3`,
				requestOptions
			)
				.then(response => response.blob())
				.then(result => {
					console.log('result', result)
					FileDownload(result, 'Application.docx')
				})
				.catch(err => console.log(err))
		}
	})
}

export const uploadSingleFile = file => {
	console.log('file', file)
	const formData = new FormData()
	formData.append('file', file)
	var requestOptions = {
		method: 'POST',
		body: formData,
		redirect: 'follow',
	}
	return fetch(
		`${process.env.REACT_APP_API_URL}/irb/application/upload`,
		requestOptions
	)
		.then(response => {
			return response.json()
		})
		.catch(err => console.log(err))
}

export const uploadMultiFile = files => {
	console.log('files', files)
	const formData = new FormData()
	for (let i = 0; i < files.length; i++) {
		formData.append('files', files[i])
	}
	var requestOptions = {
		method: 'POST',
		body: formData,
		redirect: 'follow',
	}
	return fetch(
		`${process.env.REACT_APP_API_URL}/irb/application/upload-files`,
		requestOptions
	)
		.then(response => {
			return response.json()
		})
		.catch(err => console.log(err))
}

export const uploadDataAndFiles = (application, files, token) => {
	console.log('files', files)
	console.log('data', JSON.stringify(application))

	const formData = new FormData()
	for (let i = 0; i < files.length; i++) {
		formData.append('files', files[i])
	}
	formData.append('data', JSON.stringify(application))
	var requestOptions = {
		method: 'POST',
		body: formData,
		redirect: 'follow',
		Authorization: `Bearer ${token}`,
	}
	return fetch(
		`${process.env.REACT_APP_API_URL}/irb/application/upload-data-and-files`,
		requestOptions
	)
		.then(response => {
			handleResponse(response)
			return response.json()
		})
		.catch(err => console.log(err))
}

export const getApplicationFiles = (applicationId) => {
	return fetch(`${process.env.REACT_APP_API_URL}/irb/applicationFiles/${applicationId}`, {
		method: 'GET',
	})
		.then(response => {
			return response.json()
		})
		.catch(err => console.log(err))
}

// function setDataForQuickTest() {
//     let randomString = Math.random().toString(36).substring(7)
//     let application = {
//         // Step 2
//         studyTitle: randomString,
//         status: 'Completed', // Completed, Draft
//         protocolNumber: 'PXE001',
//         issueName: 'Test Issue Name',
//         orgName: 'Shelokhomes',
//         orgAddress: '13B Barker Stress',
//         orgCity: 'LA',
//         orgState: 'California',
//         orgZip: '10000',
//         orgTelephone: '0123456789',
//         orgFax: 'Test Org Fax',
//         orgEmail: 'xyz@gmail.com',
//         // Step 3 Principle Investigator (PI)
//         piName: 'Test PI Name',
//         piTitle: 'Test PI Title',
//         piDepartment: 'R&D',
//         piEmail: 'abc@gmail.com',
//         piPhone: '0987654321',
//         correspondentSeparate: 'Yes',
//         correspondentContact: 'Test Correspondent Contact',
//         anotherInvestigator: 'Yes',
//         investigatorContact: 'Test Investigator Contact',
//         //StepFour below
//         //checkbox needed
//         // step 4 - page 3 template
//         optionPiAndAllPersonnel: 'Yes',
//         // Step 5 - page 3 template
//         optionPiOrAnyPersonnel: 'Yes',
//         piOrAnyPersonnelExplainContent: 'Test PI or any personnel explain content',
//         //radio buttons needed
//         // conflictContext: 'Test Conflict Text',
//         //checkbox needed
//         // Step 6 Six below
//         rd_techAbstract: 'Test Abstract',
//         // Step 7
//         rd_researchDesign: 'Test Design',
//         // Step 8
//         rd_laySummary: 'Test Summary', // rd_laySummary
//         // Step 9
//         //radio buttons needed
//         numberPeople: '15',
//         numberYears: '3',
//         inclusion: 'Test Inclusion',
//         exclusion: 'Test Exclusion',
//         //radio buttons needed
//         //radio buttons needed
//         population1: 'Test Population 1',
//         population2: 'Test Population 2',
//         population3: 'Test Population 3',
//         // 10 Step 10
//         //bunch of checkboxes
//         recruitOtherDescribe: 'Test Recruit Other Describe',
//         //bunch of checkboxes
//         materialOtherDescribe: 'Test Material Other Describe',
//         // Invitation from
//         hpp_irp_prp_invitation_org: 'Yes',
//         // Recruitment
//         hpp_irp_prp_recruitment: 'Yes',
//         // Invitation
//         hpp_irp_prp_invitation_of_clinical_care: 'Yes',
//         // Self-referral
//         hpp_irp_prp_self: 'Yes',
//         // Referrals
//         hpp_irp_prp_referrals: 'Yes',
//         // data
//         hpp_irp_prp_data: 'Yes',
//         // other
//         hpp_irp_prp_other: 'Yes',
//         hpp_irp_prp_other_describe: 'Test Other Describe',
//         // Websites
//         hpp_irp_rm_websites: 'Yes',
//         // Email lists
//         hpp_irp_rm_email_lists: 'Yes',
//         // Social media
//         hpp_irp_rm_social_media: 'Yes',
//         // Hospital/clinic
//         hpp_irp_rm_hospital_clinic: 'Yes',
//         // Newspapers
//         hpp_irp_rm_newspapers: 'Yes',
//         // Radio
//         hpp_irp_rm_radio: 'Yes',
//         // Television
//         hpp_irp_rm_television: 'Yes',
//         // Other
//         hpp_irp_rm_other: 'Yes',
//         hpp_irp_rm_other_describe: 'Test Other Describe',
//         // Step 12: model web by template General-Webform.docx, api gen by template: PEER-Webform.docx. We are not map
//         //bunch of radio buttons scattered throughout
//         consentProcess: 'Test Consent Process',
//         provideExplain: 'Test Provide Explain',
//         consentMinor: 'Test Consent Minor',
//         coercionExplain: 'Test Coercion Explain',
//         benefitExplain: 'Test Benefit Explain',
//         anticipatedBenefits: 'Test Anticipated Benefits',
//         withdrawExplain: 'Test Withdraw Explain',
//         compensateExplain: 'Test Compensate Explain',
//         // Step 13
//         hpp_dpsc_a: 'Test Privacy',
//         hpp_fdu_a_unknown: 'Test Future Use',
//         // step 14 - upload file. Yes with uploaded file, No without uploaded files.
//         isAttachAppendixA: 'Yes', // need change file name to AppendixA.docx
//         isAttachAppendixB: 'Yes', // need change file name to AppendixB.docx
//         isAttachAppendixC: 'No', // need change file name to AppendixC.docx
//         isAttachAppendixD: 'No', // need change file name to AppendixD.docx
//         isAttachAppendixE: 'No', // need change file name to AppendixE.docx
//     }
//     return application
// }
