import { userPublicProfile } from "actions/user";
import moment from "moment";
import {useState, useEffect} from 'react'
import { Container } from "semantic-ui-react";
import NewsMenu from "./user/NewsMenu";
import { toast } from "react-toastify";

const UserProfile = ({ match }) => {
    const [user, setUser] = useState({});
    const { slug } = match.params;

    useEffect(() => {
        const loadUserProfile = () => {
            userPublicProfile(slug).then(data => {
                if (data.error) {
                    toast.error('User not found')
                } else {
                    setUser(data.user);
                }
            });
        };
        loadUserProfile();
    }
    , [slug]);

    return (
        <Container className='container-news'>
            <NewsMenu />
            <h1>{user.name}</h1>
            <p>Joined {moment(user.createdAt).fromNow()}</p>
        </Container>
    )
}

export default UserProfile;