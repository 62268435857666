import { Container, Grid, Image } from 'semantic-ui-react'
import ShareSocialMedia from '../../../ShareSocialMedia'

function MatthewCaffet() {
	const shareUrl = 'http://www.geneticalliance.org/about/staff/matthew-caffet'
	return (
		<Container className='mb-80-50-40 container-main'>
			<h1>Matthew Caffet</h1>
			<hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
			<Grid>
				<Grid.Column className='mb-5-mobile' mobile={16} tablet={7} computer={4}>
					<Image src='/staff/Caffet.jpg' className='staff-icon-size staff-bio' />
				</Grid.Column>
				<Grid.Column mobile={16} tablet={8} computer={4}>
					<p className='p-compact'>Assistant Director of Strategic Operations</p>
					<ShareSocialMedia shareUrl={shareUrl} />
				</Grid.Column>
				<Grid.Column mobile={16} tablet={16} computer={8}>
					<p>
						Matthew is passionate about engaging everyone from individuals to entire
						communities in order to transform and improve health. He works on
						developing tools and processes, and assisting communities with their
						establishment and advancement efforts. Matthew truly believes that
						creating a culture which empowers people and makes them the champions of
						not only their own health, but the system-wide state of healthcare is
						essential.
					</p>
					<p>
						Matthew graduated from Stanford University in 2019, with a B.S. in
						Biological Chemistry.
					</p>
				</Grid.Column>
			</Grid>
		</Container>
	)
}

export default MatthewCaffet
