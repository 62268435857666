import { Container } from 'semantic-ui-react'

function PatientResearchProtections() {
	return (
		<Container className='mb-50 container-main'>
			<div className='page-title'>Patient Research Protections</div>
			<p>
				Patient research protections are intended to protect the safety and privacy
				of research participants. These protections include requiring a researcher
				to obtain informed consent before conducting research with the human
				participant or their bio-specimens, ensuring the privacy and/or
				de-identification of the participant's identifiable data, and conducting
				frequent review of research studies to ensure that patient research
				protections are being adequately upheld.
			</p>
			<h3>Why support Patient Research Protections?</h3>
			<ul>
				<li>
					Patient research protections ensure that all research approved by the IRB
					is conducted in an ethical manner.
				</li>
				<li>
					Obtaining informed consent, with the ability to revoke it at any time,
					provides autonomy to the human participant. This autonomy allows the
					participant to engage only in research they wish to engage in, whether
					directly or via the use of their bio-specimens.
				</li>
				<li>
					Ensuring the privacy of human participants and/or de-identifying their
					bio-specimens ensures that human participants will not be subject to
					genetic or any other such discrimination as a result of participating in a
					research study.
				</li>
			</ul>
			<h3>
				What has Genetic Alliance done to support Patient Research Protections?
			</h3>
			<h1>PDF LINKS</h1>
		</Container>
	)
}

export default PatientResearchProtections
