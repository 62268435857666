import { Container, Image, Grid } from 'semantic-ui-react'

function Peer() {
	return (
		<Container className='container-main'>
			<h1>Promise for Engaging Everyone Responsibly (PEER)</h1>
			<Grid columns={1}>
				<Grid.Row>
					<Grid.Column>
						<p className='mt-25'>
						PEER is a unique, award-winning technology solution that empowers communities and organizations to build registries and design studies quickly and efficiently to do the research their community cares about. Individuals, families, and communities contribute their health data to accelerate participant-driven research opportunities that address their unique health needs.
						</p>
						<h2>Your community. Your questions. Your insights.</h2>
						<p>
							You have questions - and we’re here to help you discover answers! PEER
							provides you with support, guidance, and infrastructure to do the
							research your community cares about.
						</p>
						<Image src='/photos/peer.png' size='massive' centered />
						<h2>Get started quickly.</h2>
						<h3>
							We provide you with infrastructure to begin engaging your community now -
							and to get quality data in the door when you need it.
						</h3>
						<ul className='list-right list-space'>
							<li>Streamlined application process</li>
							<li>Onboard community members quickly and easily</li>
							<li>
								Engage in simple activities with your members to set research priorities
							</li>
							<li>
								Deploy validated instruments and surveys from our library or create your
								own
							</li>
							<li>
								With just a few clicks, leverage standardized APIs to collect electronic
								health records and genomic data
							</li>
							<li>GDPR- and HIPAA-compliant</li>
						</ul>
						<h2>...All while putting people first.</h2>
						<h3>
							We believe in building products and services from the ground up. People
							and their needs are part of our process every step of the way.
						</h3>
						<ul className='list-right list-space'>
							<li>People control their data</li>
							<li>Questions come to the platform</li>
							<li>User-friendly technology that can be accessed via mobile</li>
							<li>Support for multiple languages</li>
							<li>Governance by the communities we serve</li>
						</ul>
						<h2>
							And because we know running a registry or study can be challenging...
						</h2>
						<h3>
							We leverage more than 25 years of experience to bring you best practices
							for a smooth launch and successful roll-out.
						</h3>
						<ul className='list-right list-space'>
							<li>Explore tried-and-true frameworks for stakeholder engagement</li>
							<li>
								Work with Genetic Alliance IRB for a quick and painless approval process
							</li>
							<li>Connect to, and learn from, other PEER communities</li>
							<li>
								Access expert consultant services for recruitment, engagement, and more
							</li>
						</ul>
						{/* <h2>At $50-150/month, it's priced to meet you where you are!</h2> */}
						<h2>Experience PEER for yourself</h2>
						<p>
							Learn more about how PEER could work for you and your community! Fill in
							our interest form to receive a demo and sign up for one of our question
							and answer sessions.
						</p>
						<div className='ui center aligned container'>
							<iframe
								title='Experience PEER for yourself'
								src='https://docs.google.com/forms/d/e/1FAIpQLScWcBa-iNI0R8SzP-AvpYUTOALvlm_d39NbCk0ASN6Ju8Sc0Q/viewform?embedded=true'
								width='640'
								height='2936'
								frameborder='0'
								marginheight='0'
								marginwidth='0'>
								Loading…
							</iframe>
						</div>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Container>
	)
}

export default Peer
