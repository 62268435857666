import { Container, Grid, Image, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'


function NETSPhaseII() {
    return (
        <>
            <div>
                <Container className='container-main'>
                    <h1>NETS: Phase II Clinical Trials (Safety, POCC, Dose)</h1>
			        <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>What are Phase II clinical trials and why are they important in drug development?</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>
                                Phase II clinical trials are studies to evaluate the effectiveness of an investigational drug or therapy and observe short-term side effects and risks. They continue the study of drug{' '} 
                                <Link to='/resources-and-information/nets/safety' rel='noopener noreferrer'>
                                    safety 
                                </Link>
                                {' '}and{' '} 
                                <Link to='/resources-and-information/nets/dose' rel='noopener noreferrer'>
                                    dose
                                </Link>
                                , and they also aim to provide proof of clinical concept (POCC) - that is, proof that the drug has the desired effect on individuals with a specific condition. This means that, unlike{' '} 
                                <Link to='/resources-and-information/nets/phase_I' rel='noopener noreferrer'>
                                    Phase I 
                                </Link>
                                {' '}trials, Phase II trials include participants affected by the disease or condition of interest as opposed to the unaffected volunteers utilized in Phase I. Phase II clinical trials usually last around two years.
                            </p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Testimonials: How we did it</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>Content not yet available for this section.</p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Relevant Publications</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>Content not yet available for this section.</p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Related Tools</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <a
                                href='https://clinicaltrials.gov/ct2/home'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />NIH: ClinicalTrials.gov</Button>
                            </a>
                            <a
                                href='https://www.clinicaltrialsregister.eu/ctr-search/search'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />EU/EEA: EU Clinic Trials Register</Button>
                            </a>
                            <a
                                href='https://www.cancer.net/research-and-advocacy/clinical-trials/phases-clinical-trials'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/guide.png' className='icon-width-16px' />ASCO: Phases of Clinical Trials</Button>
                            </a>
                            <a
                                href='https://www.cancer.net/navigating-cancer-care/videos/cancer-basics/phases-cancer-clinical-trials-%E2%80%93-introduction-with-neal-meropol-md'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/multimedia.png' className='icon-width-16px' />ASCO: Phases of a Cancer Clinical Trial</Button>
                            </a>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Link to='/resources-and-information/nets/full-view' rel='noopener noreferrer' className='link-button inline-block'><Button className='button-orange-500'><Image src='/icons/link-internal-white.png' className='icon-width-16px' style={{ transform: 'scaleX(-1)' }} />Back to NETS Map full view</Button></Link>
                </Container>
            </div>
        </>
    )
}

export default NETSPhaseII