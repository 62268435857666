import { Grid, Form } from 'semantic-ui-react'
import { useEffect, useState } from 'react'
import './IRBApplication.css'
import NextBackStep from './NextBackStep'

function StepSix({
	step,
	setStep,
	totalSteps,
	updateFields,
	abstract,
	...data
}) {
	const [abstractError, setAbstractError] = useState(false)
	const [overWordLimit, setOverWordLimit] = useState(false)

	const countWords = str => {
		let wordCount = 0
		if (str) {
			let words = str.split(' ')
			for (let i = 0; i < words.length; i++) {
				if (words[i] !== '') {
					wordCount++
				}
			}
		}
		return wordCount
	}

	const isAbstractExceedingWordLimit = () => {
		if (countWords(abstract) > 500) {
			setOverWordLimit(true)
			return true
		}
		setOverWordLimit(false)
		return false
	}

	const isAbstractMissing = () => {
		if (abstract === '') {
			setAbstractError(true)
			return true
		}
		if (abstract.split(' ').length > 500) {
			setAbstractError(true)
			return true
		}
		return false
	}

	const next = () => {
		isAbstractExceedingWordLimit()
		isAbstractMissing()
		if (
			isAbstractMissing() === false &&
			isAbstractExceedingWordLimit() === false
		) {
			setStep(i => {
				return i + 1
			})
		}
	}

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<Grid centered>
			<Grid.Column computer={12}>
				<Form>
					<h3 className='mb-40'>Technical (Scientific) Abstract</h3>
					<p>
						The structured technical abstract must provide a clear and concise
						overview of the proposed work. The structured technical abstract should
						address the following:
					</p>
					<ul>
						<li>
							<b>Background:</b> Present the ideas and reasoning behind the proposed
							work
						</li>
						<li>
							<b>Significance:</b> Describe the impact this data collection and/or
							research will have on your community, on the public, etc. In other words,
							why is this initiative important, how does this meet the community’s
							needs?
						</li>
						<li>
							<b>Objective/Hypothesis:</b> State the objective/hypothesis to be tested
							and provide evidence or rationale that supports the objective/hypothesis
							(if applicable)
						</li>
						<li>
							<b>Specific Aims:</b> Define the goals of the study (high level is fine –
							see example below):
							<br />
							Only through studying the factors and circumstances surrounding ___ can
							advancements in ______be made. Thus, it is proposed that by collecting
							data from _______ , we might begin to understand _________.
						</li>
						<li>
							<b>Participant/Patient Population:</b> Briefly describe the population
							that you will be working with for this study
						</li>
					</ul>
					<p className='mt-25 mb-40'>Please limit your response to 500 words.</p>
					<Form.TextArea
						label='Technical (Scientific) Abstract'
						placeholder='Technical (Scientific) Abstract'
						required
						type='text'
						value={abstract}
						onChange={e => {
							setAbstractError(false)
							updateFields({ abstract: e.target.value })
						}}
					/>
					<p className={overWordLimit ? 'p-small error' : 'p-small'}>
						{countWords(abstract)}/500 words
					</p>
					{abstractError && (
						<p className='error'>
							Technical (Scientific) Abstract is required and must be less than 500
							words.
						</p>
					)}
				</Form>
				<NextBackStep
					step={step}
					totalSteps={totalSteps}
					next={next}
					setStep={setStep}
					data={data}
				/>
			</Grid.Column>
		</Grid>
	)
}

export default StepSix
