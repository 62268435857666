import { useEffect } from 'react'
import { isAuth } from 'actions/auth'
import { useNavigate } from 'react-router-dom'

const Admin = ({ children }) => {
	const navigate = useNavigate()
	useEffect(() => {
		if (!isAuth()) {
			navigate(`/sign-in`)
		} else if (isAuth().role !== 1) {
			navigate(`/`)
		}
	}, [navigate])
	return <>{children}</>
}

export default Admin
