import { Container, Grid, GridColumn } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'

function ArchivesAndHistoryFillContent({ data }) {

	console.log('ArchivesAndHistoryFillContent data', data)
	if (!data) {
		return null
	}

	const datas = data.filter(item => item.link === '/about/archives-and-history' && item.part === 'top')
	const listBottom = data.filter(item => item.link === '/about/archives-and-history' && item.part === 'bottom-list')
	if (datas && Array.isArray(datas) && datas.length > 0) {
		datas.sort((a, b) => a.index - b.index)
	} else {
		return null
	}

	const listBottomContent = []
	if (listBottom && Array.isArray(listBottom) && listBottom.length > 0) {
		listBottom.sort((a, b) => a.index - b.index)
		listBottom.forEach((item, index) => {
			const buildContent = (item.linkTo ?
				<p key={index}>
					<u>
						<em>
							<Link to={item.linkTo} rel='noreferrer'>
								{item.header}
							</Link>
						</em>
					</u>
					{ReactHtmlParser(item.content)}
				</p>
				:
				<p key={index}>
					<a className='link-blue-800' href={item.href}>
						<u>
							<em>{item.header}</em>
						</u>
					</a>
					{ReactHtmlParser(item.content)}
				</p>
			)
			listBottomContent.push(buildContent)
		})
	}

	return (
		<>
			<Container className='container-main'>
				<h1>{datas[0].header}</h1>
				<Grid columns={1} padded>
					<GridColumn>
						<h2>{datas[1].header}</h2>

						{ReactHtmlParser(datas[1].content)}

						<h2>{datas[2].header}</h2>

						{ReactHtmlParser(datas[2].content)}

						{listBottomContent}

						<p>&nbsp;</p>
					</GridColumn>
				</Grid>
			</Container>
		</>
	)
}

export default ArchivesAndHistoryFillContent
