import { Container, Grid, GridColumn, Image } from 'semantic-ui-react'
// import { Link } from 'react-router-dom'

function PatrickLake() {
	return (
		<>
			<Container className='container-main'>
				<h1>IRB Roster</h1>
				<Grid columns={1} padded>
					<GridColumn>
						<Image
							alt='Bray Patrick-Lake'
							src='/images/IRB/Patrick-LakeHeadshot.png'
							size='medium'
							floated='left'
							rounded
						/>
						<h2>Bray Patrick-Lake, MD, MSC</h2>
						<h3>
							Director of Stakeholder Engagement, Duke Clinical Research Institute
						</h3>
						<p>
							As Director of Stakeholder Engagement and Research Together program lead,
							Bray Patrick-Lake supports efforts to actively engage patients, health
							advocacy organizations, and other stakeholders in Duke Clinical Research
							Institute’s local and national research programs. She has led extensive
							efforts through the Clinical Trials Transformation Initiative to
							incorporate patient voice into clinical trial design, conduct, oversight,
							and regulatory frameworks, as well as improvement of the clinical trial
							enterprise. She currently serves as the DCRI Coordinating Center’s
							engagement lead for the Project Baseline study and NIH’s Environmental
							Influences on Child Health Outcomes program. In 2015 – 2016, she
							co-chaired the Advisory Committee to the NIH Director’s Working Group
							responsible for authoring the vision and roadmap to launch the Precision
							Medicine Initiative Cohort Program. She served as the Interim Director of
							Engagement for several months after program launch. She currently serves
							on the National Academies of Science, Engineering, and Medicine (NASEM)
							Board on Health Sciences Policy, the All of Us National Advisory Panel,
							and leads engagement work at Duke’s Coordinating Center for NIH’s
							Environmental Influences on Child Health Outcomes (ECHO) program. She
							recently also served on the NASEM Committee that developed
							recommendations and a framework for the Return of Individual Research
							Results.
						</p>
					</GridColumn>
				</Grid>
			</Container>
		</>
	)
}

export default PatrickLake
