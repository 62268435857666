import { Container, Image, Grid } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import './ResourcesAndInformation.css'


function ResourcesAndInformation() {
    return (
        <>
            <div>
                <Container className='container-main' id='programsAndResources'>
					<h1>Our Programs and Resources</h1>
			        <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
					<Grid>
						<h1>Programs</h1>
						<Grid.Row className='mt-80-60-40'>
							<Grid.Column mobile={16} tablet={8} computer={4}>
								<Link to='/ihope-genetic-health' rel='noopener noreferrer' className='link-button scrim-white-background'>
									<Image src='/images/resources-and-information/ihope-program.png' />
									<h4>iHope Genetic Health</h4>
									<p className='p-small mw-608px'>iHope™ Genetic Health is a program that diagnoses the undiagnosed across the globe. We address a desperate need to make genomic medicine available to all individuals regardless of their social status, income, or geographic location.</p>
								</Link>
							</Grid.Column>
							<Grid.Column mobile={16} tablet={8} computer={4}>
								<Link to='/irb' rel='noopener noreferrer' className='link-button scrim-white-background'>
									<Image src='/images/resources-and-information/irb-program.png' />
									<h4>Institutional Review Board</h4>
									<p className='p-small mw-608px'>Genetic Alliance's Institutional Review Board fosters research and discovery through a people-centered approach to reviewing research protocols. Under Genetic Alliance's IRB, protocols are reviewed with an eye for harm and burden to a participant, without the authoritative paternalistic regulation of other institutions. We value protecting people by ensuring that review occurs in a timely manner, with low costs and minimal restrictions.</p>
								</Link>
							</Grid.Column>
							<Grid.Column mobile={16} tablet={8} computer={4}>
								<Link to='/biobank' rel='noopener noreferrer' className='link-button scrim-white-background'>
									<Image src='/images/resources-and-information/BioBank-logo.png' />
									<h4>BioBank</h4>
									<p className='p-small mw-608px'>The Genetic Alliance BioBank provides communities and organizations the infrastructure needed to collect, archive, and distribute all types of biospecimens.</p>
								</Link>
							</Grid.Column>
							<Grid.Column mobile={16} tablet={8} computer={4}>
								<Link to='/registries/ga-registry' rel='noopener noreferrer' className='link-button scrim-white-background'>
									<Image src='/images/resources-and-information/registry-program.png' />
									<h4>Registry</h4>
									<p className='p-small mw-608px'>The Promise for Engaging Everyone Responsibly (PEER) is a unique, award-winning technology solution for collecting health data directly from individuals. The platform—designed to accommodate any data sharing and privacy preferences—gives individual users complete control over how their data is shared and used for research.</p>
								</Link>
							</Grid.Column>
							<Grid.Column mobile={16} tablet={8} computer={4}>
								<Link to='/registries/bootcamp' rel='noopener noreferrer' className='link-button scrim-white-background'>
									<Image src='/images/resources-and-information/bootcamp-program.png' />
									<h4>Registry Bootcamp</h4>
									<p className='p-small mw-608px'>RegistryBootcamp.org offers advocates, advocacy organizations, and other community groups the information and strategies needed to launch and manage an impactful registry. Whether you are looking for an introduction to registries, a deep dive into a specific sub-topic, or a practical guide, this site has something for you.</p>
								</Link>
							</Grid.Column>
							<Grid.Column mobile={16} tablet={8} computer={4}>
								<Link to='/disease-infosearch' rel='noopener noreferrer' className='link-button scrim-white-background'>
									<Image src='/images/resources-and-information/dis-program.png' />
									<h4>Disease InfoSearch</h4>
									<p className='p-small mw-608px'>Are you looking for disease or condition information, news, and support all in one place? Disease InfoSearch is the right place to start! Disease InfoSearch locates information from a database of more than 10,000 conditions and from thousands of partners.</p>
								</Link>
							</Grid.Column>
						</Grid.Row>
					</Grid>
						<hr className='rule-blue-300 mb-80-60-40' />
					<Grid>
						<h1>Resources</h1>
						<Grid.Row className='mt-80-60-40'>
							<Grid.Column mobile={16} tablet={8} computer={4}>
								<Link to='/resources-and-information/nets' rel='noopener noreferrer' className='link-button scrim-white-background'>
									<Image src='/images/resources-and-information/nets-program.png' />
									<h4>NETS</h4>
									<p className='p-small mw-608px'>Navigating the Ecosystem of Translational Science (NETS) was created to map the drug development process and make currently-existing tools more accessible by compiling them in user-friendly toolkits. This web-based map is designed to both educate and empower participation in translational research.</p>
								</Link>
							</Grid.Column>
							<Grid.Column mobile={16} tablet={8} computer={4}>
								<Link to='/wikiadvocacy' rel='noopener noreferrer' className='link-button scrim-white-background'>
									<Image src='/images/resources-and-information/wikiadvocacy-program.png' />
									<h4>WikiAdvocacy</h4>
									<p className='p-small mw-608px'>WikiAdvocacy is a resource for a range of organizations, from the very small “kitchen-table” group to an established institution looking to expand or refine its mission. Through stories, how-to’s, tips, and exercises, WikiAdvocacy can help focus your organization’s goals and map a plan to meet them.</p>
								</Link>
							</Grid.Column>
							<Grid.Column mobile={16} tablet={8} computer={4}>
								<Link to='/resources-and-information/advocacy-atlas' rel='noopener noreferrer' className='link-button scrim-white-background'>
									<Image src='/images/resources-and-information/atlas-program.png' />
									<h4>Advocacy ATLAS</h4>
									<p className='p-small mw-608px'>	This digital Advocacy ATLAS toolkit was created in partnership between Genetic Alliance, Parent to Parent USA, and Family Voices to provide individuals with special healthcare needs and their families with tools and strategies to advocate for their needs.</p>
								</Link>
							</Grid.Column>
							<Grid.Column mobile={16} tablet={8} computer={4}>
								<Link to='/expecting-health' rel='noopener noreferrer' className='link-button scrim-white-background'>
									<Image src='/images/resources-and-information/expecting-health-program.png' />
									<h4>Expecting Health</h4>
									<p className='p-small mw-608px'>	Expecting Health shares science-based and policy-informed information that reflects the lived experiences of individuals and their families. This is accomplished through the power of relationships; convening the top experts; working with key leaders in health; and engaging with families and communities at the center of the conversation.</p>
								</Link>
							</Grid.Column>
							<Grid.Column mobile={16} tablet={8} computer={4}>
								<a href='https://www.liebertpub.com/loi/gtmb' target='_blank' rel='noopener noreferrer' className='link-button scrim-white-background'>
									<Image src='/images/resources-and-information/genetic-testing-program.png' />
									<h4>Genetic Testing and Molecular Biomarkers</h4>
									<p className='p-small mw-608px'>Genetic Testing and Molecular Biomarkers, official journal of Genetic Alliance, is the leading peer-reviewed journal covering all aspects of human genetic testing including molecular biomarkers. The Journal provides a forum for the development of new technology; the application of testing to decision making in an increasingly varied set of clinical situations; ethical, legal, social, and economic aspects of genetic testing; and issues concerning effective genetic counseling. This is the definitive resource for researchers, clinicians, and scientists who develop, perform, and interpret genetic tests and their results.</p>
								</a>
							</Grid.Column>
							<Grid.Column mobile={16} tablet={8} computer={4}>
								<Link to='/resources-and-information/publications' rel='noopener noreferrer' className='link-button scrim-white-background'>
									<Image src='/images/resources-and-information/publications-program.png' />
									<h4>Publications</h4>
									<p className='p-small mw-608px'>Genetic Alliance publications are aimed at emerging and experienced advocacy leaders, researchers, healthcare providers, and policymakers. All of our publications are available for free download and posting, as long as Genetic Alliance is credited as the author and the content is not modified in any way.</p>
								</Link>
							</Grid.Column>
						</Grid.Row>
					</Grid>
                </Container>
            </div>
        </>
    )
}

export default ResourcesAndInformation