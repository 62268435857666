import { Container, Grid, Image, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'


function NETSGenotypes() {
    return (
        <>
            <div>
                <Container className='container-main'>
                    <h1>NETS: Genotypes</h1>
			        <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>What are genotypes?</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>
                                A genotype is an individual's genetic makeup, which is stored in their DNA. DNA is packaged into 23 chromosomes, and every person has two copies of each chromosome, with one copy inherited from each parent (46 total chromosomes). Each chromosome contains certain genes that code for specific molecular processes and traits, and different versions of the same gene are called alleles. Combinations of these alleles create genotypes, and genotype determines{' '} 
                                <Link to='/resources-and-information/nets/phenotypes' rel='noopener noreferrer'>
                                    phenotype
                                </Link>.
                            </p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Why are genotypes important in drug development?</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>
                                Understanding genotypes is important in drug development because a person may have the genetic variants for a specific severe disease, but they may not show phenotypic evidence of said disease. Because of this, they would not be getting the treatment that they require, leading to potentially life threatening outcomes. An example of this dilemma would be a woman with the BRCA1/2 gene. There would be no outright, phenotypic signs of the gene's presence in the patient's genome until cancer is actually diagnosed, at which point it may be too late to save the woman. 
                            </p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Testimonials: How we did it</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>Content not yet available for this section.</p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Relevant Publications</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <div className='inline-flex mb-15'>
                                <Image src='/icons/worksheet.png' className='icon-width-16px mr-8' style={{ height: '16px', top: '8px' }} />
                                <p>
                                    <a
                                        href='http://www.ncbi.nlm.nih.gov/pubmed/18344981'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Genetics of Gene Expression and Its Effects on Disease
                                    </a>
                                </p>
                            </div>
                            <div className='inline-flex mb-15'>
                                <Image src='/icons/worksheet.png' className='icon-width-16px mr-8' style={{ height: '16px', top: '8px' }} />
                                <p>
                                    <a
                                        href='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2841396/'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        An Integrative Genomics Approach to Infer Casual Associations between Gene Expression and Disease
                                    </a>
                                </p>
                            </div>
                            <div className='inline-flex'>
                                <Image src='/icons/worksheet.png' className='icon-width-16px mr-8' style={{ height: '16px', top: '8px' }} />
                                <p>
                                    <a
                                        href='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2841398/'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Variations in DNA Elucidate Molecular Networks that Cause Disease
                                    </a>
                                </p>
                            </div>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Related Tools</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <a
                                href='https://www.cancer.gov/about-nci/organization/ccg/research/structural-genomics/tcga'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />IH, NHGRI: The Cancer Genome Atlas Data Portal</Button>
                            </a>
                            <a
                                href='https://www.ncbi.nlm.nih.gov/gap'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />NCBI</Button>
                            </a>
                            <a
                                href='https://www.ncbi.nlm.nih.gov/variation/news/NCBI_retiring_HapMap/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />The International HapMap</Button>
                            </a>
                            <a
                                href='http://biosciencedbc.jp/moved-jsnp-biosciencedbc-jp'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />University of Tokyo and Japan Science and Technology Agency: Japanese SNP Database</Button>
                            </a>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Link to='/resources-and-information/nets/full-view' rel='noopener noreferrer' className='link-button inline-block'><Button className='button-orange-500'><Image src='/icons/link-internal-white.png' className='icon-width-16px' style={{ transform: 'scaleX(-1)' }} />Back to NETS Map full view</Button></Link>
                </Container>
            </div>
        </>
    )
}

export default NETSGenotypes