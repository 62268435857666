import { useEffect } from 'react'
import { isAuth } from '../../../actions/auth'
import { useNavigate } from 'react-router-dom'

const Private = ({ children }) => {
	const navigate = useNavigate()
	useEffect(() => {
		if (!isAuth()) {
			navigate(`/sign-in`)
		}
	}, [navigate])
	return <>{children}</>
}

export default Private
