import { Container, Grid } from 'semantic-ui-react'
import Admin from 'components/pages/auth/Admin'
import BlogUpdate from 'components/crud/BlogUpdate'
import NewsMenu from '../../NewsMenu'

const EditBlog = ({match}) => {
	return (
		<Admin>
			<Container className='container-news'>
				<NewsMenu />
				<h1 className='mb-40'>Update blog</h1>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <BlogUpdate match={match} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
			</Container>
		</Admin>
	)
}

export default EditBlog
