import { STATES_LIST } from 'helpers/Constants'
import { Grid, Form } from 'semantic-ui-react'
import './IRBApplication.css'
import { useState, useEffect } from 'react'
import NextBackStep from './NextBackStep'

function StepTwo({ step, totalSteps, setStep, updateFields, ...data }) {
	const {
		studyTitle,
		protocolNumber,
		issueName,
		orgName,
		orgAddress,
		orgCity,
		orgState,
		orgStateOther,
		orgCountry,
		orgZip,
		orgTelephone,
		orgEmail,
	} = data

	const [studyTitleError, setStudyTitleError] = useState(false)
	const [protocolNumberError, setProtocolNumberError] = useState(false)
	const [issueNameError, setIssueNameError] = useState(false)
	const [orgNameError, setOrgNameError] = useState(false)
	const [orgAddressError, setOrgAddressError] = useState(false)
	const [orgCityError, setOrgCityError] = useState(false)
	const [orgStateError, setOrgStateError] = useState(false)
	const [orgStateOtherError, setOrgStateOtherError] = useState(false)
	const [orgZipError, setOrgZipError] = useState(false)
	const [orgCountryError, setOrgCountryError] = useState(false)
	const [orgTelephoneError, setOrgTelephoneError] = useState(false)
	const [orgEmailError, setOrgEmailError] = useState(false)

	const isStudyTitleMissing = () => {
		if (studyTitle === '') {
			setStudyTitleError(true)
			return true
		}
		return false
	}

	const isProtocolNumberMissing = () => {
		if (protocolNumber === '') {
			setProtocolNumberError(true)
			return true
		}
		return false
	}

	const isIssueNameMissing = () => {
		if (issueName === '') {
			setIssueNameError(true)
			return true
		}
		return false
	}

	const isOrgNameMissing = () => {
		if (orgName === '') {
			setOrgNameError(true)
			return true
		}
		return false
	}

	const isOrgAddressMissing = () => {
		if (orgAddress === '') {
			setOrgAddressError(true)
			return true
		}
		return false
	}

	const isOrgCityMissing = () => {
		if (orgCity === '') {
			setOrgCityError(true)
			return true
		}
		return false
	}

	const isOrgStateMissing = () => {
		if (orgState === '') {
			setOrgStateError(true)
			return true
		}
		return false
	}

	const isOrgStateOtherMissing = () => {
		if (orgStateOther === '' && orgState === 'other') {
			setOrgStateOtherError(true)
			return true
		}
		return false
	}

	const isOrgZipMissing = () => {
		if (orgZip === '') {
			setOrgZipError(true)
			return true
		}
		return false
	}

	const isOrgCountryMissing = () => {
		if (orgCountry === '') {
			setOrgCountryError(true)
			return true
		}
		return false
	}

	const isOrgTelephoneMissing = () => {
		if (orgTelephone === '') {
			setOrgTelephoneError(true)
			return true
		}
		return false
	}

	const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
	const isOrgEmailMissing = () => {
		if (orgEmail === '') {
			setOrgEmailError(true)
			return true
		}
		if (orgEmail && !orgEmail.match(mailformat)) {
			setOrgEmailError(true)
			return true
		}
		return false
	}

	let missingFields =
		studyTitleError ||
		protocolNumberError ||
		issueNameError ||
		orgNameError ||
		orgAddressError ||
		orgCityError ||
		orgStateError ||
		orgStateOtherError ||
		orgZipError ||
		orgCountryError ||
		orgTelephoneError ||
		orgEmailError

	const next = () => {
		isStudyTitleMissing()
		isProtocolNumberMissing()
		isIssueNameMissing()
		isOrgNameMissing()
		isOrgAddressMissing()
		isOrgCityMissing()
		isOrgStateMissing()
		isOrgStateOtherMissing()
		isOrgZipMissing()
		isOrgCountryMissing()
		isOrgTelephoneMissing()
		isOrgEmailMissing()
		if (
			isStudyTitleMissing() === false &&
			isProtocolNumberMissing() === false &&
			isIssueNameMissing() === false &&
			isOrgNameMissing() === false &&
			isOrgAddressMissing() === false &&
			isOrgCityMissing() === false &&
			isOrgStateMissing() === false &&
			isOrgStateOtherMissing() === false &&
			isOrgZipMissing() === false &&
			isOrgCountryMissing() === false &&
			isOrgTelephoneMissing() === false &&
			isOrgEmailMissing() === false
		) {
			setStep(i => {
				return i + 1
			})
		}
	}

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<Grid centered>
			<Grid.Column computer={10}>
				<h3 className='mb-40'>Study Title &amp; Contact Information</h3>
				<Form>
					<p className='mb-25'>
						Create your own study title. For example: The [NAME OF ORGANIZATION]
						Study: For the Collection of Information to Facilitate and Advance Better
						Care and/or Research
					</p>
					<Form.Input
						placeholder='Study Title *'
						required
						label='Study Title'
						type='text'
						value={studyTitle}
						onChange={e => {
							updateFields({ studyTitle: e.target.value })
							setStudyTitleError(false)
						}}
					/>
					{studyTitleError && <p className='error mt-5'>Study Title is required.</p>}
					<p className='mt-40 mb-25'>
						Create your protocol number. Use at most four distinctive letters followed
						by the number 001. Subsequent protocols will be 002, 003, etc. As an
						example, PXE International’s first protocol was PXE001.
					</p>
					<Form.Input
						required
						label='Protocol #'
						placeholder='Protocol # *'
						type='text'
						value={protocolNumber}
						onChange={e => {
							updateFields({ protocolNumber: e.target.value })
							setProtocolNumberError(false)
						}}
					/>
					{protocolNumberError && (
						<p className='error mt-5'>Protocol Number is required.</p>
					)}
					<Form.Input
						required
						label='Name of your issue, cause, condition, or disease'
						placeholder='Name of your issue, cause, condition, or disease *'
						type='text'
						value={issueName}
						onChange={e => {
							updateFields({ issueName: e.target.value })
							setIssueNameError(false)
						}}
					/>
					{issueNameError && <p className='error mt-5'>Issue Name is required.</p>}
					<Form.Input
						required
						label='Name of your organization'
						placeholder='Name of your organization *'
						type='text'
						value={orgName}
						onChange={e => {
							updateFields({ orgName: e.target.value })
							setOrgNameError(false)
						}}
					/>
					{orgNameError && (
						<p className='error mt-5'>Organization Name is required.</p>
					)}
					<Form.Input
						required
						label='Sponsor/Organization Address'
						placeholder='Sponsor/Organization Address *'
						type='text'
						value={orgAddress}
						onChange={e => {
							updateFields({ orgAddress: e.target.value })
							setOrgAddressError(false)
						}}
					/>
					{orgAddressError && (
						<p className='error mt-5'>Sponsor/Organization Address is required.</p>
					)}
					<Form.Group className='' widths='equal'>
						<Form.Field>
							<Form.Input
								label='City'
								required
								fluid
								placeholder='City *'
								type='text'
								value={orgCity}
								onChange={e => {
									updateFields({ orgCity: e.target.value })
									setOrgCityError(false)
								}}
							/>
							{orgCityError && <p className='error mt-15'>City is required.</p>}
						</Form.Field>
						<Form.Field>
							<Form.Select
								label='State or Providence'
								required
								fluid
								placeholder='State or Providence *'
								options={STATES_LIST}
								value={orgState}
								onChange={(e, { value }) => {
									updateFields({ orgState: value })
									setOrgStateError(false)
								}}
							/>
							{orgStateError && <p className='error mt-15'>State or Providence is required.</p>}
						</Form.Field>
						<Form.Field>
							<Form.Input
								label='ZIP'
								required
								fluid
								placeholder='ZIP *'
								type='number'
								value={orgZip}
								onChange={e => {
									updateFields({ orgZip: e.target.value })
									setOrgZipError(false)
								}}
							/>
							{orgZipError && <p className='error mt-15'>ZIP is required.</p>}
						</Form.Field>
					</Form.Group>
					{orgState === 'other' && (
						<Form.Field>
							<Form.Input
								label='Other State or Providence'
								placeholder='Other State or Providence *'
								required={orgState === 'other'}
								value={orgStateOther}
								type='text'
								onChange={e => {
									updateFields({ orgStateOther: e.target.value })
									setOrgStateOtherError(false)
								}}
							/>
							{orgStateOtherError && (
							<p className='error'>Other State or Providence is required.</p>
							)}
						</Form.Field>
					)}
					<Form.Input
						label='Country'
						required
						placeholder='Country *'
						type='text'
						value={orgCountry}
						onChange={e => {
							updateFields({ orgCountry: e.target.value })
							setOrgCountryError(false)
						}}
					/>
					{orgCountryError && (
						<p className='error mt-5'>Country is required.</p>
					)}
					<Form.Input
						label='Organization Telephone'
						required
						placeholder='Organization Telephone *'
						type='tel'
						value={orgTelephone}
						onChange={e => {
							let x = e.target.value
								.replace(/\D/g, '')
								.match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
							updateFields({
								orgTelephone: !x[2]
									? x[1]
									: '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : ''),
							})
							setOrgTelephoneError(false)
						}}
					/>
					{orgTelephoneError && (
						<p className='error mt-5'>Organization Telephone is required.</p>
					)}
					<Form.Input
						label='Organization Email'
						required
						placeholder='Organization Email *'
						type='email'
						value={orgEmail}
						onChange={e => {
							updateFields({ orgEmail: e.target.value })
							setOrgEmailError(false)
						}}
					/>
					{orgEmailError && (
						<p className='error mt-5'>
							Organization Email is required and needs to be valid.
						</p>
					)}
					<p>
						This is the email that will receive the confirmation for the submission of
						this webform.
					</p>
				</Form>
				{missingFields && (
					<p className='error missing-field'>
						Please fill all required fields above.
					</p>
				)}
				<NextBackStep
					step={step}
					totalSteps={totalSteps}
					next={next}
					setStep={setStep}
					data={data}
				/>
			</Grid.Column>
		</Grid>
	)
}

export default StepTwo
