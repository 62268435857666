import React, { useState, useEffect } from 'react'
import { getCookie } from '../../actions/auth'
import { getCategories } from '../../actions/category'
import { getTags } from '../../actions/tag'
import { createBlog } from 'actions/blog'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { Form, Input, Grid, Button } from 'semantic-ui-react'
import { toast } from 'react-toastify'
import {
	capitalizeFirstLetter,
	QuillFormats,
	QuillModules,
} from 'components/helpers/HelperFunctions'
import { useNavigate } from 'react-router-dom'

const BlogCreate = () => {
	const navigate = useNavigate()
	const bodyFromLS = JSON.parse(localStorage.getItem('blog')) || {}
	const [body, setBody] = useState(bodyFromLS)

	const [categories, setCategories] = useState([])
	const [tags, setTags] = useState([])

	const [checked, setChecked] = useState([]) // categories
	const [checkedTag, setCheckedTag] = useState([]) // tags

	const [values, setValues] = useState({
		error: '',
		sizeError: '',
		success: '',
		formData: '',
		title: '',
		hidePublishButton: false,
	})

	const { formData, title } = values

	const token = getCookie('token')
	const initCategories = () => {
		getCategories().then(data => {
			if (data.error) {
				setValues({ ...values, error: data.error })
			} else {
				setCategories(data)
			}
		})
	}

	const initTags = () => {
		getTags().then(data => {
			if (data.error) {
				setValues({ ...values, error: data.error })
			} else {
				setTags(data)
			}
		})
	}

	useEffect(() => {
		setValues({ ...values, formData: new FormData() })
		initCategories()
		initTags()
		return () => {
			localStorage.removeItem('blog')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const publishBlog = e => {
		e.preventDefault()
		createBlog(formData, token).then(data => {
			if (data.error) {
				setValues({ ...values, error: data.error })
				toast.error(data.error)
			} else {
				toast.success(`"${data.title}" is created`)
				setValues({
					...values,
					title: '',
					error: '',
					success: `A new blog titled "${data.title}" is created`,
				})
				navigate(`/admin/crud/${data.slug}`)
			}
		})
	}

	const handleChange = name => e => {
		// console.log(e.target.value)
		const value = name === 'photo' ? e.target.files[0] : e.target.value
		formData.set(name, value)
		setValues({ ...values, [name]: value, formData, error: '' })
	}

	const handleBody = e => {
		// console.log(e)
		setBody(e)
		formData.set('body', e)
		if (typeof window !== 'undefined') {
			localStorage.setItem('blog', JSON.stringify(e))
		}
	}

	const handleToggle = c => () => {
		setValues({ ...values, error: '' })
		// return the first index or -1
		const clickedCategory = checked.indexOf(c)
		const all = [...checked]

		if (clickedCategory === -1) {
			all.push(c)
		} else {
			all.splice(clickedCategory, 1)
		}
		// console.log(all);
		setChecked(all)
		formData.set('categories', all)
	}

	const handleTagsToggle = t => () => {
		setValues({ ...values, error: '' })
		// return the first index or -1
		const clickedTag = checkedTag.indexOf(t)
		const all = [...checkedTag]

		if (clickedTag === -1) {
			all.push(t)
		} else {
			all.splice(clickedTag, 1)
		}
		// console.log(all);
		setCheckedTag(all)
		formData.set('tags', all)
	}

	const showCategories = () => {
		return (
			categories &&
			categories.map((c, i) => (
				<li key={i} className=''>
					<input
						id={c.name}
						onChange={handleToggle(c._id)}
						type='checkbox'
						className='mr-8'
					/>
					<label className='form-check-label' htmlFor={c.name}>
						{c.name}
					</label>
				</li>
			))
		)
	}

	const showTags = () => {
		return (
			tags &&
			tags.map((t, i) => (
				<li key={i} className=''>
					<input
						id={t.name}
						onChange={handleTagsToggle(t._id)}
						type='checkbox'
						className='mr-8'
					/>
					<label className='form-check-label' htmlFor={t.name}>
						{t.name}
					</label>
				</li>
			))
		)
	}

	const createBlogForm = () => (
		<Form onSubmit={publishBlog}>
			<Form.Field
				required
				label='Title'
				control={Input}
				id='title'
				value={capitalizeFirstLetter(title)}
				onChange={handleChange('title')}
				type='text'
				className='form-control'
				placeholder='Type Title Name'
			/>

			<ReactQuill
				modules={QuillModules}
				formats={QuillFormats}
				value={body}
				placeholder='Write something amazing...'
				onChange={handleBody}
			/>

			<Button className='button-neutral-100 mt-25 mb-40'>Publish</Button>
		</Form>
	)
	return (
		<>
			<Grid stackable>
				<Grid.Row id='newBlogGridRowGap'>
					<Grid.Column width={11}>{createBlogForm()}</Grid.Column>
					<Grid.Column width={4}>
						<div>
							<h3 className='mb-40'>Featured image</h3>
							<label id='newBlogImageUpload' className='button-neutral-100'>
								Upload featured image
								<input
									onChange={handleChange('photo')}
									type='file'
									accept='image/*'
									hidden
								/>
							</label>
							<div className='p-small mt-8'>Max size: 1 mb</div>
						</div>

						<hr className='rule-blue-800 mt-40 mb-40' />

						<div>
							<h3 className='mb-40'>Categories</h3>
							<ul className='list' style={{ maxHeight: '200px', overflowY: 'auto' }}>
								{showCategories()}
							</ul>
						</div>

						<hr className='rule-blue-800 mt-40 mb-40' />

						<div>
							<h3 className='mb-40'>Tags</h3>
							<ul className='list' style={{ maxHeight: '200px', overflowY: 'auto' }}>
								{showTags()}
							</ul>
						</div>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</>
	)
}

export default BlogCreate
