import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Container, Input } from 'semantic-ui-react'
import * as Realm from 'realm-web'

function Search() {
	const [searchTerm, setSearchTerm] = useState('')
	const [showFoundData, setShowFoundData] = useState([])
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		setSearchTerm(localStorage.getItem('ga-search') || '')
	}, [])

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			if (searchTerm) {
				setIsLoading(true)
				const app = new Realm.App({ id: 'application-0-nxrko' })
				const credentials = Realm.Credentials.anonymous()

				async function searchPxeCms() {
					try {
						const data = await app.logIn(credentials)
						const searchData = await data.functions.searchGaCms(searchTerm)
						const result = []

						searchData.forEach((i, index) => {
							if (i.link) {
								if (i.link === '/policy') {
									result.push({
										_id: i._id + index,
										link: i.link + `#${i.index + '-' + i.dateNumber}`,
										h1: i.header,
										menu: i.menu,
									})
								} else {
									result.push({
										_id: i._id + index,
										link: i.link + (i.id ? `#${i.id}` : ''),
										h1: i.header,
										menu: i.menu,
									})
								}
								console.log('i1', i)
							}
						})

						setShowFoundData(result)
					} catch (err) {
						console.error('Failed to log in', err)
					} finally {
						setIsLoading(false)
					}
				}

				localStorage.setItem('ga-search', searchTerm)
				searchPxeCms()
			}
		}, 500)

		return () => clearTimeout(delayDebounceFn)
	}, [searchTerm])

	return (
		<Container className='container-main'>
			<h1>Search Results:</h1>
			<Input
				icon='search'
				placeholder='Search...'
				value={searchTerm}
				autoComplete='off'
				onChange={e => setSearchTerm(e.target.value)}
			/>
			{searchTerm !== '' && (
				<>
					<p className='mt-25'>
						<strong>
							Results for: "{searchTerm}" | {showFoundData.length || 0} result(s)
						</strong>
					</p>
					{showFoundData.length > 0 && (
						<ul className=''>
							{showFoundData.map(item => (
								<li key={item['_id']}>
									<Link to={item.link}>
										{item.menu ? `${item.menu} - ` : ''} {item.h1}
									</Link>
								</li>
							))}
						</ul>
					)}
				</>
			)}
			{isLoading && searchTerm !== null && (
				<p className='mt-25'>Searching data...</p>
			)}
			{!showFoundData.length && !isLoading && (
				<p>There are no results for this search.</p>
			)}
		</Container>
	)
}

export default Search
