import { Container, Grid, GridColumn } from 'semantic-ui-react'
import LinkButton from '../../LinkButton'
import ReactHtmlParser from 'react-html-parser'


function JobsAndInternshipFillContent({ data }) {

	// console.log('JobsAndInternshipFillContent data', data)
	if (!data) {
		return null
	}

	const datas = data.filter(item => item.link === '/about/jobs-and-internships')
	if (datas && Array.isArray(datas) && datas.length > 0) {
		datas.sort((a, b) => a.index - b.index)
	} else {
		return null
	}

	return (
		<Container className='container-main'>
			<h1>{datas[0].header}</h1>
			<Grid columns={1} padded>
				<GridColumn>
					{ReactHtmlParser(datas[0].content)}
					<Grid columns={2}>
						<GridColumn>
							<h2>{datas[1].header}</h2>
						</GridColumn>
						<GridColumn>
							{ReactHtmlParser(datas[1].content)}
						</GridColumn>
					</Grid>
					<hr className='mb-50 mt-100' />
					<Grid columns={2}>
						<GridColumn>
							<h2>{datas[2].header}</h2>
						</GridColumn>
						<GridColumn>
							{ReactHtmlParser(datas[2].content)}
						</GridColumn>
					</Grid>
					<hr className='mb-50 mt-50' />
					<Grid columns={2}>
						<GridColumn>
							<h2>{datas[3].header}</h2>
						</GridColumn>
						<GridColumn>
							{ReactHtmlParser(datas[3].content)}
							<LinkButton className='ui button mt-15' to='/about/internships'>
								Apply For an Internship
							</LinkButton>
						</GridColumn>
					</Grid>
				</GridColumn>
			</Grid>
		</Container>
	)
}

export default JobsAndInternshipFillContent
