import { Container, Grid, Image } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { HiOutlineArrowNarrowRight } from 'react-icons/hi'

function OurPrograms() {
	return (
		<>
			<Container className='container-main'>
				<h1 className='mb-90-60-40'>Our Programs</h1>
				<Grid columns={4} className='mt-80-60-40 pb-80-40-10'>
					<Grid.Column mobile={16} tablet={8} computer={4}>
						<Link
							to='/biobank'
							rel='noopener noreferrer'
							className='link-button scrim-white-background border-solid'
							style={{ borderColor: '#EDF0F2', borderRadius: '9px' }}>
							<Image
								className='image-radius image-home scrim-white-effect'
								src='/images/home/Home_page_BioBank.png'
							/>
							<div style={{ padding: '20px' }}>
								<h4
									className='link-underline-none link-blue-800 home-tools-link'
									style={{ marginTop: '10px' }}>
									BioBank
								</h4>
								<p style={{ minHeight: '364px' }}>
									The Genetic Alliance BioBank provides the infrastructure needed to
									collect, archive, and distribute all types of biospecimens. Our model
									allows us to offer exceptional and cost-effective biorepository
									services to nonprofit communities and organizations.
								</p>
								<span style={{ display: 'flex', justifyContent: 'end' }}>
									<HiOutlineArrowNarrowRight color='#e4772a' size='40' />
								</span>
							</div>
						</Link>
					</Grid.Column>
					<Grid.Column className='home-tools' mobile={16} tablet={8} computer={4}>
						<Link
							to='/irb'
							rel='noopener noreferrer'
							className='link-button scrim-white-background border-solid'
							style={{ borderColor: '#EDF0F2', borderRadius: '9px' }}>
							<Image
								className='image-radius image-home scrim-white-effect'
								src='/images/home/Home_page_IRB.png'
							/>
							<div style={{ padding: '20px' }}>
								<h4
									className='link-underline-none link-blue-800 home-tools-link'
									style={{ marginTop: '10px' }}>
									IRB
								</h4>
								<p style={{ minHeight: '364px' }}>
									A founding member of SMART IRB, a platform that provides resources for
									multisite research and helps institutions adopt the NIH Single IRB
									review policy, Genetic Alliance’s Institutional Review Board offers
									advantages such as lowers costs, fast turnaround times, and
									user-friendly templates for IRB approval.
								</p>
								<span style={{ display: 'flex', justifyContent: 'end' }}>
									<HiOutlineArrowNarrowRight color='#e4772a' size='40' />
								</span>
							</div>
						</Link>
					</Grid.Column>
					<Grid.Column className='home-tools' mobile={16} tablet={8} computer={4}>
						<Link
							to='/registries/ga-registry'
							rel='noopener noreferrer'
							className='link-button scrim-white-background border-solid'
							style={{ borderColor: '#EDF0F2', borderRadius: '9px' }}>
							<Image
								className='image-radius image-home scrim-white-effect'
								src='/images/home/Home_page_Registry.png'
							/>
							<div style={{ padding: '20px' }}>
								<h4
									className='link-underline-none link-blue-800 home-tools-link'
									style={{ marginTop: '10px' }}>
									Registry
								</h4>
								<p style={{ minHeight: '364px' }}>
									An award-winning registry platform allows communities to quickly and
									efficiently build registries and design studies. Powered by Luna
									technology, the platform operates in compliance with international and
									domestic privacy regulations to empower individuals, families, and
									communities to contribute their health data for participant-driven
									research.
								</p>
								<span style={{ display: 'flex', justifyContent: 'end' }}>
									<HiOutlineArrowNarrowRight color='#e4772a' size='40' />
								</span>
							</div>
						</Link>
					</Grid.Column>
					<Grid.Column mobile={16} tablet={8} computer={4}>
						<Link
							to='/registries/bootcamp'
							rel='noopener noreferrer'
							className='link-button scrim-white-background border-solid'
							style={{ borderColor: '#EDF0F2', borderRadius: '9px' }}>
							<Image
								className='image-radius image-home scrim-white-effect'
								src='/images/home/Home_page_Bootcamp.png'
							/>
							<div style={{ padding: '20px' }}>
								<h4
									className='link-underline-none link-blue-800 home-tools-link'
									style={{ marginTop: '10px' }}>
									Registry Bootcamp
								</h4>
								<p style={{ minHeight: '364px' }}>
									<a
										href='https://registrybootcamp.org/'
										target='_blank'
										rel='noreferrer'>
										RegistryBootcamp.org
									</a>{' '}
									offers advocates, advocacy organizations, and other community groups
									the information and strategies needed to launch and manage an impactful
									registry. Whether you are looking for an introduction to registries, a
									deep dive into a specific sub-topic, or a practical guide, this site
									has something for you.
								</p>
								<span style={{ display: 'flex', justifyContent: 'end' }}>
									<HiOutlineArrowNarrowRight color='#e4772a' size='40' />
								</span>
							</div>
						</Link>
					</Grid.Column>
				</Grid>
			</Container>
		</>
	)
}

export default OurPrograms
