import { Grid, Form } from 'semantic-ui-react'
import { useEffect, useState } from 'react'
import './IRBApplication.css'
import NextBackStep from './NextBackStep'

function StepEight({
	step,
	setStep,
	totalSteps,
	updateFields,
	summary,
	...data
}) {
	const [summaryError, setSummaryError] = useState(false)
	const [overWordLimit, setOverWordLimit] = useState(false)

	const countWords = str => {
		let wordCount = 0
		if (str) {
			let words = str.split(' ')
			for (let i = 0; i < words.length; i++) {
				if (words[i] !== '') {
					wordCount++
				}
			}
		}
		return wordCount
	}

	const isSummaryExceedingWordLimit = () => {
		if (countWords(summary) > 500) {
			setOverWordLimit(true)
			return true
		}
		setOverWordLimit(false)
		return false
	}

	const isSummaryMissing = () => {
		if (summary === '') {
			setSummaryError(true)
			return true
		}
		if (summary.split(' ').length > 500) {
			setSummaryError(true)
			return true
		}
		return false
	}

	const next = () => {
		isSummaryExceedingWordLimit()
		isSummaryMissing()
		if (isSummaryMissing() === false && isSummaryExceedingWordLimit() === false) {
			setStep(i => {
				return i + 1
			})
		}
	}

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<Grid centered>
			<Grid.Column computer={12}>
				<Form>
					<h3 className='mb-40'>Lay Summary</h3>
					<p>
						State the purpose of this research study in language that can be
						understood by a non-scientist. The summary should address the objectives
						of this project and the procedures to be used, with an emphasis on what
						will happen to the participants. The Lay Summary is not often used in
						research settings, but is important to write out as it shows the IRB that
						you are capable of describing your research efforts in a manner that your
						community members and others with less of a scientific or research
						background would be able to understand. Include the following:
					</p>
					<ul>
						<li>
							What is this study about? How did you determine the need for this study
							and does it reflect the needs of the community? What question(s) do you
							hope to answer?
						</li>
						<li>
							What will research participants be asked to do, or what will they
							contribute?
						</li>
						<li>What is your timeframe?</li>
					</ul>
					<p className='mt-25 mb-40'>
						Do not duplicate the abstract and limit your response to 500 words.
					</p>
					<Form.TextArea
						label='Lay Summary'
						placeholder='Lay Summary'
						required
						type='text'
						value={summary}
						onChange={e => {
							setSummaryError(false)
							updateFields({ summary: e.target.value })
						}}
					/>
					<p className={overWordLimit ? 'p-small error' : 'p-small'}>
						{countWords(summary)}/500 words
					</p>
					{summaryError && (
						<p className='error'>
							Lay Summary is required and must be less than 500 words.
						</p>
					)}
				</Form>
				<NextBackStep
					step={step}
					totalSteps={totalSteps}
					next={next}
					setStep={setStep}
					data={data}
				/>
			</Grid.Column>
		</Grid>
	)
}

export default StepEight
