import { Container, Grid, Image, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'


function NETSLead() {
    return (
        <>
            <div>
                <Container className='container-main'>
                    <h1>NETS: Lead Compounds</h1>
			        <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>What are lead compounds and why are they important in drug development?</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>
                                A lead compound is a molecule or collection of molecules that demonstrates the desired response against a specific biological target and therefore has potential therapeutic use. Often, lead compounds are identified from{' '} 
                                <Link to='/resources-and-information/nets/libraries' rel='noopener noreferrer'>
                                    compound libraries 
                                </Link>
                                {' '}using a{' '} 
                                <Link to='/resources-and-information/nets/system' rel='noopener noreferrer'>
                                    HTS system
                                </Link>
                                . Additional research and{' '} 
                                <Link to='/resources-and-information/nets/medicinal' rel='noopener noreferrer'>
                                    medicinal chemistry 
                                </Link>
                                {' '}are needed to turn lead compounds into potential therapies.
                            </p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Testimonials: How we did it</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>Content not yet available for this section.</p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Relevant Publications</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>Content not yet available for this section.</p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Related Tools</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            {/* <a
                                href='https://ncats.nih.gov/research/reengineering/ncgc/ncgc.html'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/guide.png' className='icon-width-16px' />NIH MLP: NIH Chemical Genomics Center</Button>
                            </a>
                            <a
                                href='http://mli.nih.gov/mli/mlpcn/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/partnership.png' className='icon-width-16px' />NIH MLP: Accessing the NCGC through the Molecular Libraries Program</Button>
                            </a>
                            <a
                                href='https://ncats.nih.gov/research/rare-diseases/trnd/trnd.html'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/partnership.png' className='icon-width-16px' />NCATS TRND: Therapeutics for Rare and Neglected Diseases</Button>
                            </a> */}
                            <p>Content not yet available for this section.</p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Link to='/resources-and-information/nets/full-view' rel='noopener noreferrer' className='link-button inline-block'><Button className='button-orange-500'><Image src='/icons/link-internal-white.png' className='icon-width-16px' style={{ transform: 'scaleX(-1)' }} />Back to NETS Map full view</Button></Link>
                </Container>
            </div>
        </>
    )
}

export default NETSLead