import { Container, Grid, Image, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'


function NETSInVitro() {
    return (
        <>
            <div>
                <Container className='container-main'>
                    <h1>NETS: In Vitro &amp; In Vivo Pharmacology</h1>
			        <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>What is in vitro &amp; in vivo pharmacology and why is it important in drug development?</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>
                                Pharmacology is the study of the biological effects of drugs. <i>In vitro</i> pharmacology studies the biological effects of a drug in an isolated environment, such as{' '} 
                                <Link to='/resources-and-information/nets/cell-lines' rel='noopener noreferrer'>
                                    cell lines 
                                </Link>
                                {' '}or tissues. This setup conveniently eliminates whole organism physiological influences allowing for a detailed analysis a compound's impact. <i>In vivo</i> pharmacology is the study of the biological effects of a drug in a complex living organism, and is used to observe the complex physiological effects of a drug.
                            </p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Testimonials: How we did it</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>Content not yet available for this section.</p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Relevant Publications</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>Content not yet available for this section.</p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Related Tools</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            {/* <a
                                href='https://www.fda.gov/Drugs/GuidanceComplianceRegulatoryInformation/Guidances/ucm065014.htm'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/toolkit.png' className='icon-width-16px' />FDA Guidance: Pharmacology &amp; Toxicology Guidances</Button>
                            </a>
                            <a
                                href='https://ncats.nih.gov/research/rare-diseases/trnd/trnd.html'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/partnership.png' className='icon-width-16px' />NCATS TRND: Therapeutics for Rare and Neglected Diseases</Button>
                            </a>
                            <a
                                href='https://www.ngvbcc.org/PharmToxTutorialBasics.action'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/guide.png' className='icon-width-16px' />NHLBI and Indiana University: Pharmacology &amp; Toxicology - The Basics</Button>
                            </a> */}
                            <p>Content not yet available for this section.</p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Link to='/resources-and-information/nets/full-view' rel='noopener noreferrer' className='link-button inline-block'><Button className='button-orange-500'><Image src='/icons/link-internal-white.png' className='icon-width-16px' style={{ transform: 'scaleX(-1)' }} />Back to NETS Map full view</Button></Link>
                </Container>
            </div>
        </>
    )
}

export default NETSInVitro