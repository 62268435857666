import { Container, Grid, GridColumn, Image } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

function Publications() {
	return (
		<Container className='container-main'>
			<Grid>
				<GridColumn>
					<Grid stackable columns={2}>
						<Grid.Column>
							<h1>Publications</h1>
						</Grid.Column>
						<Grid.Column>
							<p className='subtitle'>
								All of our publications are available as free downloads provided authorship is credited to Genetic Alliance in all postings and content is not modified in anyway. Every publication made by Genetic Alliance features content intended for both emerging and experienced advocacy leaders, researchers, healthcare providers, and policy makers. Please direct any questions you may have to {' '}
								<a className='subtitle'
									href='mailto:info@geneticalliance.org'>
									info@geneticalliance.org
								</a>
							</p>
						</Grid.Column>
					</Grid>
					<hr className='rule-orange-500 mt-80-40 mb-40' />
					<Grid stackable columns={2}>
						<Grid.Column>
							<h3>Selected Peer-Reviewed Publications by the Genetic Alliance Staff</h3>
						</Grid.Column>
						<Grid.Column>
							<p>
								<a href='https://drive.google.com/file/d/1lcVeMlwbzPvBbgWQV2AweaxWFk_NlyGV/view' 
								target='_blank'	rel='noreferrer' 
								className='p-compact link-publications'>
									<b>Journal Articles</b><Image style={{float: 'right', top: '10px'}} className='icon-width-16px' src='/icons/outbound-link.png' />
								</a>
							</p>
							<hr className='rule-blue-300' />
							<p className='mt-15'>
								<a href='https://drive.google.com/file/d/1oCsYkBjfZ53wLlqTf0zkJQ_DvYQnk-qS/view' 
								target='_blank'	rel='noreferrer' 
								className='p-compact link-publications'>
									<b>Perspectives</b><Image style={{float: 'right', top: '10px'}} className='icon-width-16px' src='/icons/outbound-link.png' />
								</a>
							</p>
							<hr className='rule-blue-300' />
						</Grid.Column>
					</Grid>
					<hr className='mt-40 mb-40 rule-blue-800 ' />
					<Grid stackable columns={2}>
						<Grid.Column>
							<Grid reversed='mobile vertically'>
								<Grid.Row>
									<Grid.Column>
										<h3 className='mb-25'>Children and Youth with Special Healthcare Needs in Healthy People 2020</h3>
										<p className='p-compact-mobile'>
											Genetic Alliance and Family Voices collaborated with the Division of Services for Children with Special Health Needs in the Health Resources and Services Administration's Maternal and Child Health Bureau to create a family and consumer perspective on the national health promotion agenda and how it relates to CYSHCN and their families.
										</p>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column>
										<Image size='medium' className='img-center-mobile image-radius'
											src='/images/resources-and-information/publications/Publication-Test.png'
										/>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Grid.Column>
						<Grid.Column>
							<p><a href='/pdf/publications/HP2020ConsumerPerspective.pdf' target='_blank'	rel='noreferrer' className='p-compact link-publications'><b>Download</b><Image style={{float: 'right'}} src='/icons/GA-download-icon.png' /></a></p>
							<hr className='rule-blue-300' />
						</Grid.Column>
					</Grid>
					<hr className='mt-40 mb-40 rule-blue-800 ' />
					<Grid stackable columns={2}>
						<Grid.Column>
							<Grid reversed='mobile vertically'>
								<Grid.Row>
									<Grid.Column>
										<h3 className='mb-25'>"Does it Run in the Family?" Toolkit</h3>
										<p className='p-compact-mobile'>
											The Does It Run In the Family? Toolkit combines family health history, oral traditions, and genetics in order to help individuals and families gather their health history and use that information to make positive health choices. The toolkit consists of two booklets and supplementary materials that together help people collect, organize, and understand their family health history. The booklets are designed to be customized by families and communities to better reflect their health concerns.
										</p>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column>
										<Image className='img-center-mobile image-radius'
											src='/images/resources-and-information/publications/Publications-Test2.png'
										/>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Grid.Column>
						<Grid.Column>
							<p className=''><a href='/pdf/publications/book1ga_ll022309.pdf' target='_blank'	rel='noreferrer' className='p-compact link-publications'><b>English Download Pt 1</b> <Image style={{float: 'right'}} src='/icons/GA-download-icon.png' /></a></p>
							<hr className='rule-blue-300' />
							<p className='mt-15'><a href='/pdf/publications/Book2.GA_lowlitFHHT.pdf' target='_blank'	rel='noreferrer' className='p-compact link-publications'><b>English Download Pt 2</b> <Image style={{float: 'right'}} src='/icons/GA-download-icon.png' /></a></p>
							<hr className='rule-blue-300' />
							<p className='mt-15'><a href='/pdf/publications/book1span.pdf' target='_blank'	rel='noreferrer' className='p-compact link-publications'><b>Español Descargar Pt 1</b> <Image style={{float: 'right'}} src='/icons/GA-download-icon.png' /></a></p>
							<hr className='rule-blue-300' />
							<p className='mt-15'><a href='/pdf/publications/GASpanish2.pdf' target='_blank'	rel='noreferrer' className='p-compact link-publications'><b>Español Descargar Pt 2</b> <Image style={{float: 'right'}} src='/icons/GA-download-icon.png' /></a></p>
							<hr className='rule-blue-300' />
						</Grid.Column>
					</Grid>
					<hr className='mt-40 mb-40 rule-blue-800 ' />
					<Grid stackable columns={2}>
						<Grid.Column>
							<Grid reversed='mobile vertically'>
                        		<Grid.Row>
									<Grid.Column>
										<h3 className='mb-25'>How Do I Talk to My Family About My Genetic Condition?</h3>
										<p className='p-compact-mobile'>
											It is not only important to collect family health history from your relatives; it is similarly important to tell them what you know about your own health. Genetic Alliance, in collaboration with the CDC Office of Public Health Genomics to create three complementary pamphlets on screening and genetic testing for Lynch syndrome and the importance of talking to family about this hereditary cancer syndrome. Este toolkit también está disponible en español.
										</p>
									</Grid.Column>
                        		</Grid.Row>
								<Grid.Row>
                            		<Grid.Column>
										<Image size='medium' className='img-center-mobile image-radius'
											src='/images/resources-and-information/publications/LS1_GeneticTesting&CancerPrevention.png'
										/>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Grid.Column>
						<Grid.Column>
							<p className=''><a href='/pdf/publications/LS1_GeneticTesting&CancerPrevention.pdf' target='_blank'	rel='noreferrer' className='p-compact link-publications'><b>Testing and Prevention</b> <Image style={{float: 'right'}} src='/icons/GA-download-icon.png' /></a></p>
							<hr className='rule-blue-300' />
							<p className='mt-15'><a href='/pdf/publications/LS1_SPANISHGeneticTestingCancerPrevention-SPANISH.pdf' target='_blank'	rel='noreferrer' className='p-compact link-publications'><b>Pruebas y Prevención</b> <Image style={{float: 'right'}} src='/icons/GA-download-icon.png' /></a></p>
							<hr className='rule-blue-300' />
							<p className='mt-15'><a href='/pdf/publications/LS2_LynchSyndrome.pdf' target='_blank'	rel='noreferrer' className='p-compact link-publications'><b>Lynch Syndrome</b> <Image style={{float: 'right'}} src='/icons/GA-download-icon.png' /></a></p>
							<hr className='rule-blue-300' />
							<p className='mt-15'><a href='/pdf/publications/LS2_SPANISHLynchSyndrome.pdf' target='_blank'	rel='noreferrer' className='p-compact link-publications'><b>Síndrome de Lynch</b> <Image style={{float: 'right'}} src='/icons/GA-download-icon.png' /></a></p>
							<hr className='rule-blue-300' />
							<p className='mt-15'><a href='/pdf/publications/LS3_TalkingToFamilyAboutLynchSyndrome.pdf' target='_blank'	rel='noreferrer' className='p-compact link-publications'><b>Talking to My Family</b> <Image style={{float: 'right'}} src='/icons/GA-download-icon.png' /></a></p>
							<hr className='rule-blue-300' />
							<p className='mt-15'><a href='/pdf/publications/LS3_SPANISHTalkingToFamilyAboutLS.pdf' target='_blank'	rel='noreferrer' className='p-compact link-publications'><b>Hablando con mi familia</b> <Image style={{float: 'right'}} src='/icons/GA-download-icon.png' /></a></p>
							<hr className='rule-blue-300' />
						</Grid.Column>
					</Grid>
					<hr className='mt-40 mb-40 rule-blue-800 ' />
					<Grid stackable columns={2}>
						<Grid.Column>
							<Grid reversed='mobile vertically'>
                        		<Grid.Row>
                           			 <Grid.Column>
										<h3 className='mb-25'>How-to Guides</h3>
										<p className='p-compact-mobile'>
											Each of Genetic Alliance’s How-To Guides provides advocacy leaders with practical guidelines about a specific advocacy topic. The guides address topics suggested by members—through the CommunityForum Discussion List, Genetic Alliance annual conference, individual conversations, and other forums—about concepts and skills they would like to learn more about, including:
										</p>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column>
										<Image size='medium' className='img-center-mobile image-radius'
											src='/images/resources-and-information/publications/How-to-guides.png'
										/>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Grid.Column>
						<Grid.Column>
							<p className=''><a href='/pdf/publications/creating_effective_brochures.pdf' target='_blank'	rel='noreferrer' className='p-compact link-publications'><b>Effective Brochures</b> <Image style={{float: 'right'}} src='/icons/GA-download-icon.png' /></a></p>
							<hr className='rule-blue-300' />
							<p className='mt-15'><a href='/pdf/publications/attending_with_genetic.pdf' target='_blank'	rel='noreferrer' className='p-compact link-publications'><b>A Guide for Parents</b> <Image style={{float: 'right'}} src='/icons/GA-download-icon.png' /></a></p>
							<hr className='rule-blue-300' />
							<p className='mt-15'><a href='/pdf/publications/advisorycommittees.pdf' target='_blank'	rel='noreferrer' className='p-compact link-publications'><b>Advisory Committees</b> <Image style={{float: 'right'}} src='/icons/GA-download-icon.png' /></a></p>
							<hr className='rule-blue-300' />
						</Grid.Column>
					</Grid>
					<hr className='mt-40 mb-40 rule-blue-800 ' />
					<Grid stackable columns={2}>
						<Grid.Column>
							<Grid reversed='mobile vertically'>
								<Grid.Row>
									<Grid.Column>
										<h3 className='mb-25'>Beyond the DNA- Your Informative Guide to Epigenetics &amp; Health</h3>
										<p className='p-compact-mobile'>
											Have you ever wondered why identical twins begin to look subtly different with age? Or why two people respond differently to the same environmental exposure? And did you know that most common diseases are a result of both your genes and your environment? In fact, only a small number of diseases are a result of just a single mutation in a gene. Beyond The DNA-Your Informative Guide to Epigenetics &amp; Health illustrates how epigenetics works and what it means for you in a fun and interactive way.
										</p>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
                            		<Grid.Column>
										<Image size='medium' className='img-center-mobile image-radius'
											src='/images/resources-and-information/publications/Beyond-the-DNA.png'
										/>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Grid.Column>
						<Grid.Column>
							<p className='mt-50 '><a href='/pdf/publications/BeyondtheDNA.pdf' target='_blank'	rel='noreferrer' className='p-compact link-publications'><b>The Infographic</b> <Image style={{float: 'right'}} src='/icons/GA-download-icon.png' /></a></p>
							<hr className='rule-blue-300' />
						</Grid.Column>
					</Grid>
					<hr className='mt-40 mb-40 rule-blue-800 ' />
					<Grid stackable columns={2}>
						<Grid.Column>
							<Grid reversed='mobile vertically'>
                        		<Grid.Row>
                           			<Grid.Column>
										<h3 className='mb-25'>Making Sense of Your Genes: A Guide to Genetic Counseling</h3>
										<p className='p-compact-mobile'>
											This publication provides a public-friendly introduction to genetic counseling and its applications. A truly collaborative process, this publication is borne out of partnership between Genetic Alliance, the National Society of Genetic Counselors, students at the Johns Hopkins University/National Human Genome Research Institute Genetic Counseling Training Program, and a number of independent advisors and reviewers. The guide includes general information about genetic counseling, tips on how to prepare for an appointment, and details about different specialties in the field.
										</p>
										</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column>
										<Image size='medium' className='img-center-mobile image-radius'
											src='/images/resources-and-information/publications/Sense-of-Your-Genes.png'
										/>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Grid.Column>
						<Grid.Column>
							<p className='mt-50 '><a href='/pdf/publications/guidetogcfinal.pdf' target='_blank'	rel='noreferrer' className='p-compact link-publications'><b>English Download</b> <Image style={{float: 'right'}} src='/icons/GA-download-icon.png' /></a></p>
							<hr className='rule-blue-300' />
							<p className='mt-15'><a href='/pdf/publications/GuidetoGC_Spa.pdf' target='_blank'	rel='noreferrer' className='p-compact link-publications'><b>Español Descargar</b> <Image style={{float: 'right'}} src='/icons/GA-download-icon.png' /></a></p>
							<hr className='rule-blue-300' />
						</Grid.Column>
					</Grid>
					<hr className='mt-40 mb-40 rule-blue-800 ' />
					<Grid stackable columns={2}>
						<Grid.Column>
							<Grid reversed='mobile vertically'>
								<Grid.Row>
									<Grid.Column>
										<h3 className='mb-25'>Genetic Alliance Monographs</h3>
										<p className='p-compact-mobile'>
											Genetic Alliance has five monographs to date: "Genomics, Cancer Care &amp; Advocacy,"; "Genetic Testing Stories,"; "Eyes on the Prize: Truth Telling about Genetic Testing,"; "Community Centered Family Health History: How Do You Make Research Community-Specific and Universally-Relevant?"; and "Trust It or Trash It? Creating and Assessing Genetic Health Information." This series provides an in-depth look at specific topics in the worlds of genetics, health, and advocacy, from personal and community chronicles to high-level discussion and analysis.
										</p>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column>
										<Image size='medium'
											src='/images/resources-and-information/publications/Monographs.png'
											className='img-center-mobile image-radius'
										/>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Grid.Column>
						<Grid.Column>
							<p className=''><a href='/pdf/publications/genomics_monograph.pdf' target='_blank'	rel='noreferrer' className='p-compact link-publications'><b>Cancer Care &amp; Advocacy</b> <Image style={{float: 'right'}} src='/icons/GA-download-icon.png' /></a></p>
							<hr className='rule-blue-300' />
							<p className='mt-15'><a href='/pdf/publications/gtesting_monograph.pdf' target='_blank'	rel='noreferrer' className='p-compact link-publications'><b>Genetic Testing Stories</b> <Image style={{float: 'right'}} src='/icons/GA-download-icon.png' /></a></p>
							<hr className='rule-blue-300' />
							<p className='mt-15'><a href='/pdf/publications/eyes_monograph.pdf' target='_blank'	rel='noreferrer' className='p-compact link-publications'><b>Eyes on the Prize</b> <Image style={{float: 'right'}} src='/icons/GA-download-icon.png' /></a></p>
							<hr className='rule-blue-300' />
							<p className='mt-15'><a href='/pdf/publications/ccfhh_monograph_final.pdf' target='_blank'	rel='noreferrer' className='p-compact link-publications'><b>Community Centered FHH</b> <Image style={{float: 'right'}} src='/icons/GA-download-icon.png' /></a></p>
							<hr className='rule-blue-300' />
							<p className='mt-15'><a href='/pdf/publications/atcg_monograph.pdf' target='_blank'	rel='noreferrer' className='p-compact link-publications'><b>Trust it or Trash it?</b> <Image style={{float: 'right'}} src='/icons/GA-download-icon.png' /></a></p>
							<hr className='rule-blue-300' />
						</Grid.Column>
					</Grid>
					<hr className='mt-40 mb-40 rule-blue-800 ' />
					<Grid stackable columns={2}>
						<Grid.Column>
							<Grid reversed='mobile vertically'>
                        		<Grid.Row>
                           			<Grid.Column>
										<h3 className='mb-25'>Understanding Genetics: A Guide for Patients and Health Professionals</h3>
										<p className='p-compact-mobile'>
											Understanding Genetics is an educational resource for knowledgeable consumers and health professionals, particularly those without specialty training in genetics. The manual begins with a basic introduction to genetics concepts, followed by detailed information on topics such as diagnosis of genetic conditions, family history, newborn screening, genetic counseling, understanding patient stories, and ethical, legal, and social issues in genetics. The manual is customizable by geographic region and includes specific resources to assist in patient care, patient and professional education, and locating specialty genetic services. It was written and reviewed by a wide variety of stakeholders in the genetics and health community.
										</p>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column>
										<Image className='img-center-mobile image-radius'
											src='/images/resources-and-information/publications/UnderstandingGenetics.png'
											size='medium'
										/>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Grid.Column>
						<Grid.Column>
							<p className='mt-50 '><a href='/pdf/publications/UnderstandingGeneticsNYMA.pdf' target='_blank'	rel='noreferrer' className='p-compact link-publications'><b>New York - Mid-Atlantic</b> <Image style={{float: 'right'}} src='/icons/GA-download-icon.png' /></a></p>
							<hr className='rule-blue-300' />
							<p className='mt-15'><a href='/pdf/publications/PacientesyGuiadeProfesionalesdelaalud.pdf' target='_blank'	rel='noreferrer' className='p-compact link-publications'><b>Nueva York y el Atlántico Medio</b> <Image style={{float: 'right'}} src='/icons/GA-download-icon.png' /></a></p>
							<hr className='rule-blue-300' />
							<p className='mt-15'><a href='/pdf/publications/Understanding_Genetics_NewEngland.pdf' target='_blank'	rel='noreferrer' className='p-compact link-publications'><b>New England</b> <Image style={{float: 'right'}} src='/icons/GA-download-icon.png' /></a></p>
							<hr className='rule-blue-300' />
							<p className='mt-15'><a href='/pdf/publications/UnderstandingGeneticsDCFinal.pdf' target='_blank'	rel='noreferrer' className='p-compact link-publications'><b>District of Columbia</b> <Image style={{float: 'right'}} src='/icons/GA-download-icon.png' /></a></p>
							<hr className='rule-blue-300' />
						</Grid.Column>
					</Grid>
					<hr className='mt-40 mb-40 rule-blue-800 ' />
					<Grid stackable columns={2}>
						<Grid.Column>
							<h3 className='mb-25'>Publication Archives</h3>
							<p className='p-compact-mobile'>
								Throughout the years, the Alliance for Genetic Supports Groups issued publications for the benefit of the genetic health community. These publications were available for order, with members receiving copies of various materials included in their membership. Archived in this collection are various publications that the Alliance has issued for sale or distribution throughout the years.
							</p>
						</Grid.Column>
						<Grid.Column>
							<p>
								<Link 
									to='/about/archives-and-history/publications-archive'
									target='_blank' className='p-compact link-publications' 
									rel='noopener noreferrer'
								>
									<b>Archives</b>
									<Image style={{float: 'right'}} src='/icons/GA-link-internal-icon.png' />
								</Link>
							</p>
							<hr className='rule-blue-300' />
						</Grid.Column>
					</Grid>
				</GridColumn>
			</Grid>
		</Container>
	)
}

export default Publications