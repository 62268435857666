import { Container, Grid } from 'semantic-ui-react'
import ReactHtmlParser from 'react-html-parser'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { progressIndicator } from '../../content/HelperFunction'

function TherapyDevelopmentFillContent({ data }) {

    const { pathname, hash, key } = useLocation()
    useEffect(() => {
        // if not a hash link, scroll to top
        if (hash === '') {
            window.scrollTo(0, 0)
        }
        // else scroll to id
        else {
            setTimeout(() => {
                const id = hash.replace('#', '')
                const element = document.getElementById(id)
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' })
                }
            }, 0)
        }
    }, [pathname, hash, key])

    useEffect(() => {
        progressIndicator()
    }, [])

    console.log('TherapyDevelopmentFillContent data', data)
    if (!data) {
        return null
    }

    const listContentHeader = [];
    const listContentBody = [];
    const dataTitle = data.filter(item => item.link === '/events/webinars/therapy-development' && item.part === 'title')
    if (!dataTitle) {
        return null
    }
    const dataHeader = data.filter(item => item.link === '/events/webinars/therapy-development' && item.part === 'header')
    const dataBody = data.filter(item => item.link === '/events/webinars/therapy-development' && item.part === 'body')

    if (dataHeader && Array.isArray(dataHeader) && dataHeader.length > 0) {
        dataHeader.sort((a, b) => a.index - b.index);
        dataHeader.forEach((item, index) => {
            listContentHeader.push(
                <Grid key={index}>
                    <Grid.Column>
                        <h3 id={item.id} className='mb-40'>{item.header}</h3>
                        {ReactHtmlParser(item.content)}
                    </Grid.Column>
                </Grid>)
        })
    }

    if (dataBody && Array.isArray(dataBody) && dataBody.length > 0) {
        dataBody.sort((a, b) => a.index - b.index);
        dataBody.forEach((item, index) => {
            listContentBody.push(
                <div key={index}>
                    <p>
                        <b>{item.header}</b>
                        <br />
                        <em>{item.dateTime}</em>
                        <br />
                        <em>
                            {ReactHtmlParser(item.aTag)}
                        </em>
                    </p>
                    {ReactHtmlParser(item.content)}
                </div>
            )
            if (index !== dataBody.length - 1) {
                listContentBody.push(<hr className='rule-blue-300 mt-40 mb-40' />)
            }
        })
    }

    return (
        <>
            <div>
                <Container className='container-main'>
                    <h1>{dataTitle[0].header}</h1>
                    <hr className='rule-orange-500 mt-80-60-40 mb-65-55-25' />
                    {listContentHeader}
                    <hr className='rule-blue-800 mt-80-60-40 mb-65-55-25' />
                    <Grid>
                        <Grid.Column>
                            <h3 className='mb-40'>Webinars</h3>
                            {listContentBody}
                        </Grid.Column>
                    </Grid>
                </Container>
            </div>
        </>
    )
}

export default TherapyDevelopmentFillContent




