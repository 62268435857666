import { useState, useEffect } from 'react'
import { getCookie } from 'actions/auth'
import { create, getCategories, removeCategory } from 'actions/category'
import { Button, Form, Input } from 'semantic-ui-react'
import { capitalizeEachWord } from 'components/helpers/HelperFunctions'
import { toast } from 'react-toastify';

const Category = () => {
	const [values, setValues] = useState({
		name: '',
		error: false,
		success: false,
		categories: [],
		removed: false,
		reload: false,
	})

	const { name, categories, removed, reload } = values
	const token = getCookie('token')

	useEffect(() => {
		loadCategories()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reload])

	const loadCategories = () => {
		getCategories().then(data => {
			if (data.error) {
				console.log(data.error)
			} else {
				setValues({ ...values, categories: data })
			}
		})
	}

	const showCategories = () => {
		return categories.map((category, index) => {
			return (
				<Button
					onDoubleClick={() => deleteConfirm(category.slug)}
					title='Double click to delete'
					key={index}
					color='blue'
					basic
					className='mt-15 mr-15'>
					{category.name}
				</Button>
			)
		})
	}

	const deleteConfirm = slug => {
		let answer = window.confirm('Are you sure you want to delete this category?')
		if (answer) {
			deleteCategory(slug)
		}
	}

	const deleteCategory = slug => {
		// console.log('delete', slug);
		removeCategory(slug, token).then(data => {
			if (data.error) {
				console.log(data.error)
			} else {
				setValues({
					...values,
					error: false,
					success: false,
					name: '',
					removed: !removed,
					reload: !reload,
				})
                toast.success(`Category is removed`)
			}
		})
	}

	const handleChange = e => {
		setValues({
			...values,
			name: e.target.value,
			error: false,
			success: false,
			removed: '',
		})
	}

	const handleSubmit = e => {
		e.preventDefault()
		create({ name }, token).then(data => {
			if (data.error) {
				setValues({ ...values, error: data.error, success: false })
                toast.error('Category already exist');
			} else {
				setValues({
					...values,
					error: false,
					success: true,
					name: '',
					removed: '',
					reload: !reload,
				})
                toast.success('Category is created');
			}
		})
	}

	const newCategoryForm = () => (
		<Form onSubmit={handleSubmit}>
            <h3 className='mb-25'>Categories</h3>
			<Form.Field
				label='Category Name'
				control={Input}
				id='name'
				value={capitalizeEachWord(name)}
				onChange={handleChange}
				type='text'
				className='form-control'
				placeholder='Type Category Name'
			/>
			<Button className='button-neutral-100 mt-25 mb-50'>Create Category</Button>
		</Form>
	)

	return (
        <>
            {newCategoryForm()}
			<p className='p-small'>Double-click on category name to delete</p>
            {showCategories()}
		</>
	)
}

export default Category
