import { Container, Grid, GridColumn, Form, Input, Checkbox } from 'semantic-ui-react'

function MailingLists() {
  return (
    <>
      <Container className='container-main'>
				<h1>Sign up for our mailing lists</h1>
				<Grid columns={1} padded>
					<GridColumn>
            <Form action="https://geneticalliance.us5.list-manage.com/subscribe/post?u=a9e55c5fcbe8bce63e2c83eb9&amp;id=182db72825" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target='_blank' rel='noreferrer'>
							<Form.Group widths='equal'>
								<Form.Field
									required
									id='form-input-control-first-name'
									control={Input}
									label='First Name'
									placeholder='First Name'
									name='FNAME'
								/>
                <Form.Field
									required
									id='form-input-control-first-name'
									control={Input}
									label='Last Name'
									placeholder='Last Name'
									name='LNAME'
								/>
								<Form.Field
									required
									id='form-input-control-email'
									control={Input}
									label='Email'
									placeholder='Email'
									name='EMAIL'
								/>
							</Form.Group>
              <label>I want to subscribe to</label>
								<Form.Field
                  id='mce-group[13577]-13577-0'
                  control={Checkbox}
                  label='Network Announcements'
                  name='group[13577][1]'
                  className='mt-15'
                  value='1'
							  	/>
                <Form.Field
                  id='mce-group[13577]-13577-1'
                  control={Checkbox}
                  label='Weekly Bulletin'
                  name='group[13577][2]'
                  value='2'
							  	/>
							<Form.Button type='submit' className='mt-15'>
								Subscribe
							</Form.Button>
						</Form>
          </GridColumn>
        </Grid>
      </Container>
    </>
  )
}

export default MailingLists
