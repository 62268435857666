import { Container, Grid, GridColumn, Image, Icon } from 'semantic-ui-react'
import ShareSocialMedia from '../../../ShareSocialMedia'
import LinkButton from '../../../LinkButton'

function EducationServicesAndSupports() {
	const shareUrl = 'https://geneticalliance.org/resources-and-information/advocacy-atlas/education-services'
	return (
		<Container className='container-main'>
			<h1>Education Services & Supports</h1>
			<Grid columns={1} padded>
				<GridColumn>
				<hr />
					<ShareSocialMedia shareUrl={shareUrl} />
					<div>
						<p className='mt-20'>
							<i>When he [my son] was 7 I looked at his educational and developmental assessments and asked why he could not be in a typical 1st grade class since he needed to work on so many of the same things as other 6 and 7 year olds. It was at that point that I had my first taste of advocacy—bringing in my own information, asking pertinent questions and pulling in higher-level school officials. After 3 IEP meetings with up to 15 people at each one, my son began spending most of his day in a typical 1st grade class with some support. He then spent good portions of his elementary years in general education classes, with specialized instruction in his special ed class at certain times during the day.
							<br /><br />
							-Story from a parent advocate</i>
						</p>
					</div>

					<h2 className='color-secondary mt-40'>Resources</h2>
					<Grid stackable columns={2} className='mt-15 left aligned'>
						<Grid.Row>
							<Grid.Column>
								<p>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/education-services/va_specialeducation.pdf'
										rel='noreferrer'
										target='_blank'>
										Organizing Information and Creating an IEP
									</a>
									- Family Navigator Training from Family to Family Network of Virginia
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='https://www.gemssforschools.org/'
										rel='noreferrer'
										target='_blank'>
										Genetics Education Materials for School Success (GEMSS)
									</a>
									- The New England Genetics Collaborative
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/education-services/guidedprobsolving.pdf'
										rel='noreferrer'
										target='_blank'>
										Worksheet: Steps for You As a Family Member to Take to Problem Solve
									</a>
									- Parent to Parent of Virginia
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/education-services/nys_advocthroughletters.pdf'
										rel='noreferrer'
										target='_blank'>
										Advocating Through Letter Writing
									</a>
									- Parent to Parent NYS and Family to Family Health Care Information Center
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/education-services/creating_agreement.pdf'
										rel='noreferrer'
										target='_blank'>
										Creating Agreement, Conflict Resolution, and Collaborative Problem Solving
									</a>
									- Statewide Parent Advocacy Network (SPAN)
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/education-services/creating_agreementsp.pdf'
										rel='noreferrer'
										target='_blank'>
										Creating Agreement, Conflict Resolution, and Collaborative Problem Solving (SPANISH)
									</a>
									- Statewide Parent Advocacy Network (SPAN)
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/education-services/va_collaboration_ negotiation.pdf'
										rel='noreferrer'
										target='_blank'>
										The Art of Collaboration and Negotiation
									</a>
									- Family Navigator Training from Family to Family Network of Virginia
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='https://www.wrightslaw.com/'
										rel='noreferrer'
										target='_blank'>
										Wrightslaw Home
									</a>
									- Peter W.D. Wright and Pamela Darr Wright
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/education-services/PYLN2ndTransitionToolkit.pdf'
										rel='noreferrer'
										target='_blank'>
										Secondary Transition Toolkit
									</a>
									- Pennsylvania Youth Leadership Network
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/education-services/meetingtips.pdf'
										rel='noreferrer'
										target='_blank'>
										Meeting Tip Worksheet
									</a>
									- Parent to Parent of Virginia
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/education-services/5IEPtips.pdf'
										rel='noreferrer'
										target='_blank'>
										IEP Tip Sheet
									</a>
									- Partnership for People with Disabilities
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/education-services/attending_with_genetic.pdf'
										rel='noreferrer'
										target='_blank'>
										Attending School with a Genetic Condition: A Guide for Parents
									</a>
									- Genetic Alliance
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/education-services/leadership_training_seacs.pdf'
										rel='noreferrer'
										target='_blank'>
										Leadership Training for Special Education Advisory Committees
									</a>
									- Building Local SEACs for Better Special Education Services and Student Outcomes 
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/education-services/20_leadership_communication_dualenrollment_0.pdf'
										rel='noreferrer'
										target='_blank'>
										Establishing Inclusive Postsecondary Education Opportunities: Tips for Effective Communication
									</a>
									- Think College
								</p>
							</Grid.Column>
							<Grid.Column>
								<div>
									<figure>
										<Image
											src='/images/resources-and-information/advocacy-atlas/education-services/alexaiepmeeting.jpeg'
											size='medium'
											centered
										/>
										<figcaption className='mt-20 text-center'>
											<i>A team meets to discuss a student’s IEP (Individualized Education Plan).</i>
										</figcaption>
									</figure>
									<figure className='mt-150'>
										<Image
											src='/images/resources-and-information/advocacy-atlas/education-services/grad pic.jpeg'
											size='medium'
											centered
										/>
										<figcaption className='mt-20 text-center'>
											<i>Stephanie was in the hospital during her graduation. Her school wanted to include her as she was homebound medically fragile and sent us the graduation gown asking us to email a picture.  She wasn't feeling well enough at home to take a picture. We brought the gown with us to the hospital not knowing how long she'd be there. The hospital emailed the picture and she is in the yearbook with the rest of her class. 
											<br /><br />
											-Story from a parent advocate</i>
										</figcaption>
									</figure>
								</div>
							</Grid.Column>
						</Grid.Row>
					</Grid>
					<div>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='https://thinkcollege.net/think-college-learn'
								rel='noreferrer'
								target='_blank'>
								Think College Self Paced Learning Modules
							</a>
							- Think College
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/education-services/selfdeterminationcheckliststudentself-assessment.pdf'
								rel='noreferrer'
								target='_blank'>
								Self Determination Student Checklist
							</a>
							- Commonwealth of Virginia Department of Education Training and Technical Assistance Centers
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/education-services/StudentGuideIEP.pdf'
								rel='noreferrer'
								target='_blank'>
								A Student's Guide to the IEP
							</a>
							- The National Information Center for Children and Youth with Disabilities
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/education-services/intern-guide-final.pdf'
								rel='noreferrer'
								target='_blank'>
								Internships: The On-Ramp to Employment, A Guide for Students with Disabilities to Getting and Making the Most of an Internship
							</a>
							- National Consortium on Leadership and Disability for Youth
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/education-services/Empowering-Leadership.pdf'
								rel='noreferrer'
								target='_blank'>
								Empowering Leadership A Systems Change Guide for Autistic College Students and Those with Other Disabilities
							</a>
							- Autistic Self Advocacy Network (ASAN)
						</p>
					</div>
					<div className='mt-40 text-center'>
						<LinkButton className='ui button' 
						to='/resources-and-information/advocacy-atlas'
						>
							<Icon name='arrow left' /> Return to Advocacy ATLAS Homepage
						</LinkButton>
					</div>
				</GridColumn>
			</Grid>
		</Container>
	)
}

export default EducationServicesAndSupports