import { Container, Grid, Image, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'


function NETSRepositories() {
    return (
        <>
            <div>
                <Container className='container-main'>
                    <h1>NETS: Data Repositories</h1>
			        <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>What are data repositories and why are they important in drug development?</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>
                                Data repositories are information warehouses where large amounts of data from multiple sources are stored. Examples of the types of data that can be stored in data repositories include scientific publications, research data, or clinical information.
                            </p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Testimonials: How we did it</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            {/* <div className='inline-flex'>
                                <Image src='/icons/multimedia.png' className='icon-width-16px mr-8' style={{ height: '16px', top: '8px' }} />
                                <p>
                                    <a
                                        href='https://fastercures.webex.com/ec0605ld/eventcenter/recording/recordAction.do%3Bjsessionid%3DKd6dTLBJjTBzhWGL24nrQVmW4dzz2H0vqRyVwVTt0t8nkGyJcWFS%212078350629?theAction=poprecord&actname=/eventcenter/frame/g.do&actappname=ec0605ld&renewticket=0&renewticket=0&apiname=lsr.php&entappname=url0107ld&needFilter=false&&isurlact=true&rID=3938602&entactname=/nbrRecordingURL.do&rKey=c4a6bb5ca4eb771f&recordID=3938602&siteurl=fastercures&rnd=2745508169&SP=EC&AT=pb&format=short'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        FasterCures: Building a Thriving Online Research Community
                                    </a>
                                </p>
                            </div> */}
                            <p>Content not yet available for this section.</p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Relevant Publications</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <div className='inline-flex mb-15'>
                                <Image src='/icons/worksheet.png' className='icon-width-16px mr-8' style={{ height: '16px', top: '8px' }} />
                                <p>
                                    <a
                                        href='/pdf/nets/Consent.pdf'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Group Health Research Institute: Glad You Asked: Participants' Opinions of Re-Consent for dbGaP Data Submission
                                    </a>
                                </p>
                            </div>
                            {/* <div className='inline-flex'>
                                <Image src='/icons/toolkit.png' className='icon-width-16px mr-8' style={{ height: '16px', top: '8px' }} />
                                <p>
                                    <a
                                        href='http://databib.org/index.php'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Databib: Searchable Catalog of Research Data Repositories
                                    </a>
                                </p>
                            </div> */}
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Related Tools</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <a
                                href='https://www.youtube.com/watch?v=ROjB3s9zw80'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/multimedia.png' className='icon-width-16px' />Genetic Alliance: Submitting Information About Variation to Public Databases: An NCBI Perspective</Button>
                            </a>
                            <a
                                href='https://www.diseaseinfosearch.org/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />Disease InfoSearch</Button>
                            </a>
                            <a
                                href='https://pubchem.ncbi.nlm.nih.gov/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />NCBI: PubChem</Button>
                            </a>
                            <a
                                href='https://www.ncbi.nlm.nih.gov/pubmed/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />NCBI: PubMed</Button>
                            </a>
                            <a
                                href='https://brd.nci.nih.gov/brd/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />NCI BBRB: Biospecimen Research Database</Button>
                            </a>
                            <a
                                href='https://www.ncbi.nlm.nih.gov/gap'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />NCBI: dbGaP</Button>
                            </a>
                            <a
                                href='https://www.ncbi.nlm.nih.gov/snp'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />NCBI: dbSNP</Button>
                            </a>
                            <a
                                href='https://www.ncbi.nlm.nih.gov/variation/news/NCBI_retiring_HapMap/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />NCBI: The International HapMap</Button>
                            </a>
                            <a
                                href='https://www.cancer.gov/about-nci/organization/ccg/research/structural-genomics/tcga'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />NIH NHGRI: The Cancer Genome Atlas Data Portal</Button>
                            </a>
                            <a
                                href='http://biosciencedbc.jp/moved-jsnp-biosciencedbc-jp'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />University of Tokyo and Japan Science and Technology Agency: Japanese SNP Database</Button>
                            </a>
                            <a
                                href='http://www.informatics.jax.org/mgihome/projects/aboutmgi.shtml'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />The Jackson Laboratory: Mouse Genome Informatics</Button>
                            </a>
                            <a
                                href='https://biolincc.nhlbi.nih.gov/home/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/toolkit.png' className='icon-width-16px' />NHBLI: Biological Specimen and Data Repository Coordinating Center</Button>
                            </a>
                            <a
                                href='https://repository.niddk.nih.gov/home/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/toolkit.png' className='icon-width-16px' />NIDDK: Data, Biosample, and Genetics Repository</Button>
                            </a>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Link to='/resources-and-information/nets/full-view' rel='noopener noreferrer' className='link-button inline-block'><Button className='button-orange-500'><Image src='/icons/link-internal-white.png' className='icon-width-16px' style={{ transform: 'scaleX(-1)' }} />Back to NETS Map full view</Button></Link>
                </Container>
            </div>
        </>
    )
}

export default NETSRepositories