import React from 'react'
import {
	Container,
	Grid,
	GridColumn,
	Segment,
	SegmentGroup,
} from 'semantic-ui-react'

function EventsArchive() {
	return (
		<>
			<Container className='container-main'>
				<h1>Events Archive</h1>
				<Grid columns={1} padded>
					<GridColumn>
						<p>
							For over two decades, Genetic Alliance has been educating fellow
							advocates and the public on advances in genetic health. Programs have
							covered a wide range of topics, from group networking to impacting
							research. Many of Genetic Alliance's initiatives had led to
							ground-breaking research and reform efforts. Below is a selection of some
							of Genetic Alliance's programs through the years.&nbsp;Conferences, award
							ceremonies, galas, and summits all have contributed special moments to
							the history of Genetic Alliance. For videos, please visit the&nbsp;
							<a
								href='http://www.youtube.com/user/geneticalliance'
								rel=' noopener noreferrer'>
								Genetic Alliance YouTube channel
							</a>
							.
						</p>

						<p>
							Recent and upcoming events can be found at the&nbsp;
							<a href='/events'>events</a> page. For recipients of Genetic Alliance
							conference awards, please <a href='conference-awards'>click here</a>.
						</p>

						<h2>Events (2017-1985)</h2>

						<p>
							<a href='#events2017'>2017</a>&nbsp;|&nbsp;<a href='#events2013'>2013</a>
							&nbsp;|&nbsp;<a href='#events2012'>2012</a>
							<span>&nbsp;|&nbsp;</span>
							<a href='#events2011'>2011</a>
							<span>&nbsp;|&nbsp;</span>
							<a href='#events2010'>2010</a>
							<span>&nbsp;|&nbsp;</span>
							<a href='#events2009'>2009</a>
							<span>&nbsp;|&nbsp;</span>
							<a href='#events2008'>2008</a>
							<span>&nbsp;|&nbsp;</span>
							<a href='#events2007'>2007</a>
							<span>&nbsp;|&nbsp;</span>
							<a href='#events2006'>2006</a>&nbsp;<span>|&nbsp;</span>
							<a href='#events2005'>2005-1980s</a>
						</p>

						<SegmentGroup raised>
							<Segment>
								<h3 id='events2017'>2017</h3>

								<p>
									<strong>
										<a href='http://babysfirsttest.org/newborn-screening/beyond-the-bloodspot-summit'>
											Beyond the Bloodspot: Education and Engagement Summit
										</a>
										,{' '}
									</strong>
									June 5-6, 2017
								</p>

								<p>
									<strong>
										<a href='http://geneticalliance-server.com/geneticalliance.org/30anniversary'>
											Co-Creating a Healthy Future
										</a>
									</strong>{' '}
									<strong>| </strong>
									<a href='/geneticalliance.org/sites/default/files/Archives/30%20Year%20Anniversary%20Cover.pdf'>
										Agenda
									</a>
									&nbsp;|{' '}
									<a href='/geneticalliance.org/sites/default/files/Archives/Conference%20Report%202017.pdf'>
										Conference Report
									</a>
									, March 9-10, 2017
								</p>
							</Segment>
							<Segment>
								<h3 id='events2013'>2013</h3>

								<p>
									<strong>
										<a href='http://geneticalliance-server.com/geneticalliance.org/node/38'>
											Beyond the Bloodspot Summit
										</a>
									</strong>
									&nbsp;<strong>|&nbsp;</strong>
									<a href='/geneticalliance.org/sites/default/files/Archives/BeyondtheBloodspot_Agenda.pdf'>
										Agenda
									</a>{' '}
									|{' '}
									<a href='http://geneticalliance-server.com/geneticalliance.org/node/39'>
										Materials
									</a>
									, August 20, 2013
								</p>

								<p>
									<strong>
										<strong>
											<a href='/geneticalliance.org/sites/default/files/Archives/2013powerfulpatientdataagenda.pdf'>
												Powerful Patient Data Summit
											</a>
											,{' '}
										</strong>
									</strong>
									May 30-31, 2013
								</p>
							</Segment>
							<Segment>
								<h3 id='events2012'>2012</h3>

								<p>
									<strong>
										<strong>
											<a href='/sites/default/files/eventsarchive/2012celebrationprogram.pdf'>
												25th Anniversary Celebration
											</a>
											,&nbsp;
										</strong>
									</strong>
									September 22, 2012
								</p>

								<p>
									<strong>
										<strong>
											<a href='/geneticalliance.org/sites/default/files/Archives/2012bootcampagenda.pdf'>
												Registry and BioBank Boot Camp
											</a>
											,&nbsp;
										</strong>
									</strong>
									August 23, 2012
								</p>

								<p>
									<strong>
										<strong>
											<a href='/geneticalliance.org/sites/default/files/Archives/2012improvinghealthoutcomesagenda.pdf'>
												Improving Health Outcomes, Disease Management, And Accountable Care
												Delivery
											</a>
											,&nbsp;
										</strong>
									</strong>
									March 12, 2012
								</p>
							</Segment>
							<Segment>
								<h3 id='events2011'>2011</h3>

								<p>
									<strong>
										<strong>
											<a href='/geneticalliance.org/sites/default/files/Archives/2011bootcampagenda.pdf'>
												Registry and BioBank Boot Camp
											</a>
											,&nbsp;
										</strong>
									</strong>
									August 19, 2011
								</p>

								<p>
									<strong>
										<strong>
											<a href='/sites/default/files/eventsarchive/2011conferenceprogram.pdf'>
												2011 Genetic Alliance Annual Conference
											</a>
											&nbsp;<strong>| </strong>
										</strong>
									</strong>
									<a href='https://youtube.com/playlist?list=PLB51204E9A3D00D51'>
										Awards
									</a>
									,&nbsp;June 24-26, 2011
								</p>

								<p>
									<strong>
										<strong>
											<strong>
												<a href='/geneticalliance.org/sites/default/files/Archives/2011geneticsdayprogram.pdf'>
													2011 Genetics Day on the Hill
												</a>
												,&nbsp;
											</strong>
										</strong>
									</strong>
									June 23, 2011
								</p>

								<p>
									<strong>
										<strong>
											<strong>
												<a href='/geneticalliance.org/sites/default/files/Archives/2011genescreen.pdf'>
													2011 Gene Screen
												</a>
											</strong>
										</strong>
									</strong>
									,&nbsp;June 23, 2011
								</p>
							</Segment>
							<Segment>
								<h3 id='events2010'>2010</h3>

								<p>
									<strong>
										<strong>
											<strong>
												<a
													href='/geneticalliance.org/sites/default/files/Archives/2010bootcampagenda.pdf'
													it=''
													january=''
													july=''
													march=''
													on=''
													or=''>
													Genetic Alliance Registry and Repository Boot Camp
												</a>
											</strong>
										</strong>
									</strong>
									,&nbsp;January 10, 2010
								</p>

								<p>
									<strong>
										<strong>2010&nbsp;Genetic Alliance Annual Conference&nbsp;</strong>
									</strong>
									|{' '}
									<a href='https://youtube.com/playlist?list=PLB51204E9A3D00D51'>
										Awards
									</a>
									&nbsp;| Gallery,&nbsp;July&nbsp;16-18, 2010
								</p>

								<p>
									<strong>
										<strong>
											<strong>2010&nbsp;Genetics Day on the Hill </strong>
										</strong>
									</strong>
									| Gallery,&nbsp;June, 2010
								</p>
							</Segment>
							<Segment>
								<h3 id='events2009'>2009</h3>

								<p>
									<strong>
										<strong>2009&nbsp;Genetic Alliance Annual Conference&nbsp;</strong>
									</strong>
									|{' '}
									<a href='https://youtube.com/playlist?list=PLEA1ED449B6EAD6C1'>
										Awards
									</a>
									&nbsp;| Gallery,&nbsp;July 17-19, 2009
								</p>

								<p>
									<strong>
										<strong>
											<strong>
												<a href='/geneticalliance.org/sites/default/files/Archives/2009geneticsdayprogram.pdf'>
													2009 Genetics Day on the Hill
												</a>
												&nbsp;
											</strong>
										</strong>
									</strong>
									| Gallery,&nbsp;June, 2009
								</p>

								<p>
									<strong>
										<strong>
											<strong>
												<a href='/geneticalliance.org/sites/default/files/Archives/2009genescreen.pdf'>
													2009 Gene Screen
												</a>
											</strong>
										</strong>
									</strong>
									,&nbsp;July 16, 2009
								</p>
							</Segment>
							<Segment>
								<h3 id='events2008'>2008</h3>

								<p>
									<strong>
										<strong>
											<strong>
												<a href='/sites/default/files/eventsarchive/2008conferenceprogram.pdf'>
													2008 Genetic Alliance Annual Conference
												</a>
												,
											</strong>
										</strong>
									</strong>
									&nbsp;July 11-13, 2008
								</p>

								<p>
									<strong>
										<strong>
											<strong>
												<a href='/geneticalliance.org/sites/default/files/Archives/2008geneticsdayprogram.pdf'>
													2008 Genetics Day on the Hill
												</a>
											</strong>
										</strong>
									</strong>
									,&nbsp;July 10, 2008
								</p>

								<p>
									<strong>
										<strong>
											<strong>
												<a href='/geneticalliance.org/sites/default/files/Archives/2008inthefamily.pdf'>
													In the Family
												</a>
											</strong>
										</strong>
									</strong>
									,&nbsp;July 10, 2008
								</p>

								<p>
									<strong>
										<strong>
											<strong>GENA Celebration |</strong>
										</strong>
									</strong>{' '}
									<a href='https://youtube.com/playlist?list=PLB3006D57C71FF814'>
										Video
									</a>{' '}
									| Gallery, April&nbsp;2008
								</p>
							</Segment>
							<Segment>
								<h3 id='events2007'>2007</h3>

								<p>
									Genetic Testing Summit
									<strong>
										<strong>
											<strong>&nbsp;</strong>
										</strong>
									</strong>
									| Gallery, September&nbsp;20-21, 2007
								</p>

								<p>
									<strong>
										<strong>
											<strong>
												<a href='/sites/default/files/eventsarchive/2007conferenceprogram.pdf'>
													2007 Genetic Alliance Annual Conference
												</a>
												&nbsp;
											</strong>
										</strong>
									</strong>
									| Gallery,&nbsp;July 27-29, 2007
								</p>

								<p>
									<strong>
										<strong>
											<strong>
												<a href='/geneticalliance.org/sites/default/files/Archives/2007geneticsdayprogram.pdf'>
													2007 Genetics Day on the Hill
												</a>
												&nbsp;
											</strong>
										</strong>
									</strong>
									| Gallery,&nbsp;July 26, 2007
								</p>

								<p>
									<strong>
										<strong>
											<strong>
												<a href='/geneticalliance.org/sites/default/files/Archives/2007galaprogram.pdf'>
													Gala 2007
												</a>
												&nbsp;
											</strong>
										</strong>
									</strong>
									| Gallery,&nbsp;July 25, 2007
								</p>
							</Segment>
							<Segment>
								<h3 id='events2006'>2006</h3>

								<p>
									<strong>
										<strong>
											<strong>
												<a href='/sites/default/files/eventsarchive/200620thcelebrationprogram.pdf'>
													20th Anniversary Celebration
												</a>
												&nbsp;
											</strong>
										</strong>
									</strong>
									| Gallery,&nbsp;September 27, 2006
								</p>

								<p>
									<strong>
										<strong>
											<strong>
												<a href='/geneticalliance.org/sites/default/files/Archives/2006geneticsdayprogram.pdf'>
													2006 Genetics Day on the Hill
												</a>
											</strong>
										</strong>
									</strong>
									,&nbsp;September 27, 2006
								</p>

								<p>
									<strong>
										<strong>
											<strong>
												<a href='/geneticalliance.org/sites/default/files/Archives/2006conferenceprogram.pdf'>
													2006 Genetic Alliance Annual Conference
												</a>
												&nbsp;
											</strong>
										</strong>
									</strong>
									| Gallery,&nbsp;July 28-30, 2006
								</p>
							</Segment>
							<Segment>
								<h3 id='events2005'>2005-1980s</h3>

								<p>
									<strong>
										<strong>
											<strong>
												<a href='/sites/default/files/eventsarchive/2007conferenceprogram.pdf'>
													2005&nbsp;Genetic Alliance Annual Conference
												</a>
												&nbsp;
											</strong>
										</strong>
									</strong>
									| Gallery,&nbsp;July 28-31, 2005
								</p>

								<p>
									<strong>
										<strong>
											<strong>Genetic Alliance Youth Day&nbsp;</strong>
										</strong>
									</strong>
									| Gallery, September, 2004
								</p>

								<p>
									<strong>
										<strong>
											<strong>
												<a href='/geneticalliance.org/sites/default/files/Archives/2006conferenceprogram.pdf'>
													2004&nbsp;Genetic Alliance Annual Conference
												</a>
												&nbsp;
											</strong>
										</strong>
									</strong>
									| Gallery,&nbsp;July 23-25, 2004
								</p>

								<p>
									<strong>
										<strong>
											<a href='/geneticalliance.org/sites/default/files/Archives/2001peoplesgenome2001.pdf'>
												People's Genome Celebration
											</a>
											&nbsp;
										</strong>
									</strong>
									| Gallery, June 9, 2001
								</p>

								<p>
									<strong>
										<strong>
											<a href='/geneticalliance.org/sites/default/files/Archives/1998forgingpartner.pdf'>
												Forging Genetic Partnerships: Research, Policymakers, and Consumers
											</a>
										</strong>
									</strong>
									, September 11-13, 1998
								</p>

								<p>
									<strong>
										<strong>
											<a href='/geneticalliance.org/sites/default/files/Archives/1996joantribfinal.pdf'>
												Tenth Anniversary Gala
											</a>
											&nbsp;<strong>|</strong>
										</strong>
									</strong>{' '}
									Gallery, October 12, 1996
								</p>

								<p>
									<strong>
										<strong>
											<a href='/geneticalliance.org/sites/default/files/Archives/1996membershipmeeting.pdf'>
												Computers as Tools for Networking, Support, Information &amp;
												Administration
											</a>
										</strong>
									</strong>
									, October 12, 1996
								</p>

								<p>
									<strong>
										<strong>
											<a href='/sites/default/files/eventsarchive/1993mediareportingconference.pdf'>
												Media Reporting in the Genetic Age
											</a>
											,
										</strong>
									</strong>
									&nbsp;1993
								</p>

								<p>
									<strong>
										<strong>
											<a href='/sites/default/files/eventsarchive/1993mediareportingconference.pdf'>
												Conference Proceedings
											</a>
											&nbsp;|&nbsp;
											<a href='/sites/default/files/eventsarchive/1993mediareportingpoints.pdf'>
												Points to Consider
											</a>
											&nbsp;|&nbsp;
											<a href='/sites/default/files/eventsarchive/1993mediareporting1993.pdf'>
												A Guide for Consumer Groups
											</a>
										</strong>
									</strong>
								</p>

								<p>
									<strong>
										<strong>
											<a href='/geneticalliance.org/sites/default/files/Archives/1993healthflux.pdf'>
												Health Care In Flux: How Will Families With Special Needs Fit In?
											</a>
											&nbsp;
										</strong>
									</strong>
									| Gallery<span>, November 20-21, 1993</span>
								</p>

								<p>
									<strong>
										<strong>
											<a href='/geneticalliance.org/sites/default/files/Archives/1992emptypocket.pdf'>
												A National Conference on The Empty Pocket Syndrome: How to Get Funds
											</a>
										</strong>
									</strong>
									<span>, November 21-22, 1992</span>
								</p>

								<p>
									<strong>
										<strong>
											<a href='/geneticalliance.org/sites/default/files/Archives/1991ignsg.pdf'>
												International Gathering of Network Support Groups: Satellite
												Meeting, 8th International Congress of Human Genetics
											</a>
											&nbsp;
										</strong>
									</strong>
									| Gallery<span>, October 5, 1991</span>
								</p>

								<p>
									<strong>
										<strong>
											<a href='/geneticalliance.org/sites/default/files/Archives/1990peersupport.pdf'>
												A National Conference on Peer Support Training
											</a>
										</strong>
									</strong>
									<span>, March 30-31, and April 1, 1990</span>
								</p>

								<p>
									<strong>
										<strong>
											<a href='/geneticalliance.org/sites/default/files/Archives/1988empowermentflyer.pdf'>
												Empowerment: A Symposium
											</a>
											<span>&nbsp;-&nbsp;</span>
											<a href='/geneticalliance.org/sites/default/files/Archives/1988empower.pdf'>
												Agenda
											</a>
										</strong>
									</strong>
									<span>, November 4-5, 1988</span>
								</p>

								<p>
									<strong>
										<strong>
											<a href='/geneticalliance.org/sites/default/files/Archives/1985volpartners.pdf'>
												Genetics Support Groups: Volunteers and Professionals as Partners
											</a>
										</strong>
									</strong>
									<span>, June 2-5, 1985</span>
								</p>

								<p>
									<strong>
										<strong>
											<a href='/geneticalliance.org/sites/default/files/Archives/1983gendisorders.pdf'>
												Genetic Disorders &amp; Birth Defects in Families and Society:
												Towards Interdisciplinary Understanding
											</a>
											<span>&nbsp;-&nbsp;</span>
											<a href='/geneticalliance.org/sites/default/files/Archives/1985volunteers.pdf'>
												Agenda
											</a>
										</strong>
									</strong>
									<span>, April 25-26, 1983</span>
								</p>
							</Segment>
						</SegmentGroup>
					</GridColumn>
				</Grid>
			</Container>
		</>
	)
}

export default EventsArchive
