import { useState, useEffect } from 'react'
import { Container } from 'semantic-ui-react'
import './IRBApplication.css'
import StepThree from './StepThree'
import StepOne from './StepOne'
import StepTwo from './StepTwo'
import StepFour from './StepFour'
import StepFive from './StepFive'
import StepSix from './StepSix'
import StepSeven from './StepSeven'
import StepEight from './StepEight'
import StepNine from './StepNine'
import StepTen from './StepTen'
import StepEleven from './StepEleven'
import StepTwelve from './StepTwelve'
import StepThirteen from './StepThirteen'
import StepFourteen from './StepFourteen'
import { singleApplication } from 'actions/application'

function IRBApplication({ match }) {
	const { id } = match.params

	// let randomString = Math.random().toString(36).substring(7)
	const INITIAL_DATA = {
		// Step 2
		// studyTitle: `Auto fill study title ${randomString}`,
		// protocolNumber: 'PXE001',
		// issueName: `Test Issue Name ${randomString}`,
		// orgName: `Shelokhomes ${randomString}`,
		// orgAddress: `13B Barker Stress ${randomString}`,
		// orgCity: 'LA',
		// orgState: 'California',
		// orgZip: '10000',
		// orgTelephone: '0123456789',
		// orgFax: 'Test Org Fax',
		// orgEmail: 'xyz@gmail.com',
		//StepTwo Study Title & Contact Information
		studyTitle: '',
		protocolNumber: '',
		issueName: '',
		orgName: '',
		orgAddress: '',
		orgCity: '',
		orgState: '',
		orgStateOther: '',
		orgZip: '',
		orgCountry: '',
		orgTelephone: '',
		orgEmail: '',

		// Step 3 Principle Investigator (PI)
		// piName: 'Test PI Name',
		// piTitle: 'Test PI Title',
		// piDepartment: 'R&D',
		// piEmail: 'abc@gmail.com',
		// piPhone: '0987654321',
		// investigatorOther: 'Yes',
		// investigatorContact: 'Test Investigator Contact',
		//StepThree
		piName: '',
		piTitle: '',
		piDepartment: '',
		piEmail: '',
		piPhone: '',
		investigatorOther: '',
		investigatorContact: '',

		//StepFour Human Research Protection Training
		// confirmStepFour: 'True', //checkbox needed
		//StepFour
		confirmStepFour: '',

		//StepFive Conflict of Interest
		// conflictInterest: 'No',
		// conflictContext: '',
		// reviewExemption: '', // single checkbox
		//StepFive
		conflictInterest: '',
		conflictContext: '',
		// reviewExemption: '',

		//StepSix Technical (Scientific) Abstract
		// abstract: 'qwe',
		//StepSix
		abstract: '',

		//StepSeven Research Design, Methodology, and Data Analysis
		// design: 'asd',
		//surveyInstruments: '',
		// surveyInstrumentsList: '',
		//StepSeven
		design: '',
		surveyInstruments: '',
		surveyInstrumentsList: '',

		//StepEight Lay Summary
		// summary: 'zxc',
		//StepEight
		summary: '',

		//StepNine Human Participant Protection
		// openEnded: 'No',
		// numberPeople: '1',
		// numberYears: '2',
		// inclusion: 'qaz',
		// exclusion: 'wsx',
		// postmortem: 'No',
		// previousData: 'No',
		// population1: '',
		// population2: '',
		// population3: '',
		//StepNine
		openEnded: '',
		numberPeople: '',
		numberYears: '',
		inclusion: '',
		exclusion: '',
		postmortem: '',
		previousData: '',
		population1: '',
		population2: '',
		population3: '',

		//StepTen Research Population
		// potentialParticipants: ['Database searches'],
		// recruitOtherDescribe: '',
		// recruitmentMaterials: ['Websites'],
		// materialOtherDescribe: '',
		//StepTen
		potentialParticipants: [],
		recruitOtherDescribe: '',
		recruitmentMaterials: [],
		materialOtherDescribe: '',

		//StepTwelve Additional Consent Information
		// consentProcess: '1',
		// sufficientOpportunity: 'True',
		// opportunityExplain: '',
		// consentMinor: '2',
		// coercion: 'True',
		// coercionExplain: '',
		// benefit: 'True',
		// benefitExplain: '',
		// anticipatedBenefits: '3',
		// withdraw: 'True',
		// withdrawExplain: '',
		// compensate: 'No',
		// compensateExplain: '',
		//StepTwelve
		consentProcess: '',
		sufficientOpportunity: '',
		opportunityExplain: '',
		consentMinor: '',
		coercion: '',
		coercionExplain: '',
		benefit: '',
		benefitExplain: '',
		anticipatedBenefits: '',
		withdraw: '',
		withdrawExplain: '',
		compensate: '',
		compensateExplain: '',

		//StepThirteen Data Privacy, Security, and Confidentiality & Future Data Use
		// privacy: 'p',
		// futureUse: 'f',
		// status: '',
		//StepThirteen
		privacy: '',
		futureUse: '',
		status: '',
	}
	const [data, setData] = useState(INITIAL_DATA)
	useEffect(() => {
		if (id) {
			singleApplication(id).then(res => {
				if (res.status === 'incompleted') {
					console.log('first call get data by id res:', res)
					setData(res)
					setStep(res.defaultStep)
				}
			})
		}
	}, [id])

	const [step, setStep] = useState(1)
	let totalSteps = 14
	function updateFields(newData) {
		setData(prev => {
			console.log('prev', prev)
			console.log('newData', newData)
			const after = { ...prev, ...newData }
			console.log('after', after)
			return after
		})
	}

	console.log('re-render')

	return (
		<div id='irbApplication'>
			<Container className='container-main'>
				<h1>IRB Application</h1>
				<hr className='rule-orange-500 mt-80 mb-80' />
				{step === 1 && (
					<StepOne
						step={step}
						totalSteps={totalSteps}
						setStep={setStep}
						updateFields={updateFields}
						{...data}
					/>
				)}
				{step === 2 && (
					<StepTwo
						step={step}
						totalSteps={totalSteps}
						setStep={setStep}
						updateFields={updateFields}
						{...data}
					/>
				)}
				{step === 3 && (
					<StepThree
						step={step}
						totalSteps={totalSteps}
						setStep={setStep}
						updateFields={updateFields}
						{...data}
					/>
				)}
				{step === 4 && (
					<StepFour
						step={step}
						totalSteps={totalSteps}
						setStep={setStep}
						updateFields={updateFields}
						{...data}
					/>
				)}
				{step === 5 && (
					<StepFive
						step={step}
						totalSteps={totalSteps}
						setStep={setStep}
						updateFields={updateFields}
						{...data}
					/>
				)}
				{step === 6 && (
					<StepSix
						step={step}
						totalSteps={totalSteps}
						setStep={setStep}
						updateFields={updateFields}
						{...data}
					/>
				)}
				{step === 7 && (
					<StepSeven
						step={step}
						totalSteps={totalSteps}
						setStep={setStep}
						updateFields={updateFields}
						{...data}
					/>
				)}
				{step === 8 && (
					<StepEight
						step={step}
						totalSteps={totalSteps}
						setStep={setStep}
						updateFields={updateFields}
						{...data}
					/>
				)}
				{step === 9 && (
					<StepNine
						step={step}
						totalSteps={totalSteps}
						setStep={setStep}
						updateFields={updateFields}
						{...data}
					/>
				)}
				{step === 10 && (
					<StepTen
						step={step}
						totalSteps={totalSteps}
						setStep={setStep}
						updateFields={updateFields}
						{...data}
					/>
				)}
				{step === 11 && (
					<StepEleven
						step={step}
						totalSteps={totalSteps}
						setStep={setStep}
						updateFields={updateFields}
						{...data}
					/>
				)}
				{step === 12 && (
					<StepTwelve
						step={step}
						totalSteps={totalSteps}
						setStep={setStep}
						updateFields={updateFields}
						{...data}
					/>
				)}
				{step === 13 && (
					<StepThirteen
						step={step}
						totalSteps={totalSteps}
						setStep={setStep}
						updateFields={updateFields}
						{...data}
					/>
				)}
				{step === 14 && (
					<StepFourteen
						step={step}
						totalSteps={totalSteps}
						setStep={setStep}
						updateFields={updateFields}
						{...data}
					/>
				)}
				{/* <NextBackStep step={step} setStep={setStep} totalSteps={totalSteps} /> */}
			</Container>
		</div>
	)
}

export default IRBApplication
