import { Container, Grid, Image, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'


function NETSStudySponsor() {
    return (
        <>
            <div>
                <Container className='container-main'>
                    <h1>NETS: Study Sponsor</h1>
			        <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>What are study sponsors and why are they important in drug development?</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>
                                Study sponsors are the companies, agencies, universities or organizations responsible for overseeing a clinical research project. They are responsible for managing all of the processes involved in clinical research, including{' '} 
                                <Link to='/resources-and-information/nets/recruitment'>
                                    recruiting participants 
                                </Link>
                                {' '}and{' '}
                                <Link to='/resources-and-information/nets/study-staff'>
                                    qualified study staff
                                </Link>
                                , obtaining all necessary{' '} 
                                <Link to='/resources-and-information/nets/irb'>
                                    IRB approval
                                </Link>
                                , and managing all{' '} 
                                <Link to='/resources-and-information/nets/agreements'>
                                    contractual and legal agreements
                                </Link>.
                            </p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Testimonials: How we did it</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>Content not yet available for this section.</p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Relevant Publications</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8} style={{ display: 'flex' }}>
                            <p>Content not yet available for this section.</p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Related Tools</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <a
                                href='https://www.nhlbi.nih.gov/health-topics/clinical-trials'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25'
                            >
                                <Button className='button-white'><Image src='/icons/guide.png' className='icon-width-16px' />NIH NHLBI: Who sponsors clinical trials?</Button>
                            </a>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Link to='/resources-and-information/nets/full-view' rel='noopener noreferrer' className='link-button inline-block'><Button className='button-orange-500'><Image src='/icons/link-internal-white.png' className='icon-width-16px' style={{ transform: 'scaleX(-1)' }} />Back to NETS Map full view</Button></Link>
                </Container>
            </div>
        </>
    )
}

export default NETSStudySponsor