import { Container, Grid, Image } from 'semantic-ui-react'

function PublicationsArchive() {
	return (
		<>
            <div>
                <Container className='container-main'>
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                            <h1>Publications Archive</h1>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                            <p className='subtitle'>
								Throughout the years, the Alliance for Genetic Supports Groups issued publications for the benefit of the genetic health community. These publications were available for order, with members receiving copies of various materials included in their membership. Archived in this collection are various publications that the Alliance has issued for sale or distribution throughout the years.
                            </p>
                        </Grid.Column>
                    </Grid>
			        <hr className='rule-orange-500 mt-80-60-40 mb-65-55-25' />
					<Grid stackable columns={2}>
						<Grid.Column>
							<h3>Digital Collection</h3>
						</Grid.Column>
						<Grid.Column>
							<p>
								<a href='/pdf/publications-archive/1997informedconsent.pdf' 
								target='_blank'	rel='noreferrer' 
								className='p-compact link-publications'>
									<b>Informed Consent: Participation in Genetic Research Studies, 1997</b><Image style={{float: 'right', top: '10px'}} className='icon-width-16px' src='/icons/link-internal-blue.png' />
								</a>
							</p>
							<hr className='rule-blue-300' />
							<p className='mt-15'>
								<a href='/pdf/publications-archive/1992integratingconsumers.pdf' 
								target='_blank'	rel='noreferrer' 
								className='p-compact link-publications'>
									<b>Integrating Consumers Into the Regionals Genetics Network, 1992</b><Image style={{float: 'right', top: '10px'}} className='icon-width-16px' src='/icons/link-internal-blue.png' />
								</a>
							</p>
							<hr className='rule-blue-300' />
						</Grid.Column>
					</Grid>
                </Container>
            </div>
        </>
	)
}
export default PublicationsArchive