import { Grid, Checkbox } from 'semantic-ui-react'
import { useEffect, useState } from 'react'
import './IRBApplication.css'
import NextBackStep from './NextBackStep'

function StepFour({ step, setStep, totalSteps, updateFields, ...data }) {
	const [confirmStepFour, setConfirmStepFour] = useState(false)
	const [showRequired, setShowRequired] = useState(false)

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	const next = () => {
		console.log('confirmStepFour', confirmStepFour)
		if (confirmStepFour) {
			setStep(i => {
				console.log('i', i)
				return i + 1
			})
		}
		setShowRequired(true)
	}
	return (
		<Grid centered>
			<Grid.Column computer={12}>
				<h3 className='mb-40'>Human Research Protection Training </h3>
				<p className='mb-40'>
					Check this box to confirm that the PI and all personnel anyone involved in
					any aspect of this project – (engagement, recruitment, data handling) have
					completed one of the following Human Research Protection Training such as
				</p>
				<p>
					A&#41;{' '}
					<a
						href='https://www.hhs.gov/ohrp/education-and-outreach/online-education/human-research-protection-training/index.html'
						target='_blank'
						rel='noreferrer'>
						OHRP’s
					</a>
					<br />
					B&#41;{' '}
					<a href='https://phrptraining.com/#!/' target='_blank' rel='noreferrer'>
						Protecting Human Research Participants course
					</a>
					<br />
					C&#41; Another equivalent (such as CITI)
				</p>
				<p className='mt-40'>
					<b>Training must be completed within the last 3 years.</b> All personnel
					must be listed in Appendix A, and you will be asked to upload their CVs/NIH
					Biosketches and Certificates of Human Research Protection Training
					completion later.
				</p>
				<p className='mt-40 mb-40'>
					Human Research Protection Training Confirmation{' '}
					<span className='error-color'>*</span>
				</p>
				<Checkbox
					label='I confirm that the PI(s) and all personnel have completed a Human Research Protection Training course (or equivalent) within the last three years.'
					type='checkbox'
					checked={confirmStepFour === true}
					value={confirmStepFour ? 1 : 0}
					onChange={() => setConfirmStepFour(!confirmStepFour)}
				/>
				{showRequired && !confirmStepFour && (
					<p className='mt-15 error'>Confirmation is required.</p>
				)}
				<NextBackStep
					step={step}
					totalSteps={totalSteps}
					next={next}
					setStep={setStep}
					data={data}
				/>
			</Grid.Column>
		</Grid>
	)
}

export default StepFour
