import fetch from 'isomorphic-fetch'
import { handleResponse } from './auth'

export const userPublicProfile = username => {
    return fetch(`${process.env.REACT_APP_API_URL}/user/${username}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then(response => {
            handleResponse(response)
            return response.json()
        })
        .catch(err => console.log(err))
}