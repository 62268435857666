import { Container, Grid, GridColumn, Image } from 'semantic-ui-react'
// import { Link } from 'react-router-dom'

function Dreyfus() {
	return (
		<>
			<Container className='container-main'>
				<h1>IRB Roster</h1>
				<Grid columns={1} padded>
					<GridColumn>
						<Image
							alt='Jennifer Dreyfus'
							src='/images/IRB/DreyfusHeadshot.jpeg'
							size='small'
							floated='left'
							rounded
						/>
						<h2>Jennifer Dreyfus</h2>
						<h3>IRB Board Member</h3>
						<p>
							Jennifer Dreyfus is the Principal of Dreyfus Consulting, LLC, where she
							works with scientists, policy makers and health care providers at the
							intersection of genetics, healthcare and managing risk. Her focus is on
							policy and regulatory analysis; real world application of bioethics; and
							program planning and implementation. Ms. Dreyfus was a Fellow at the
							Presidential Commission for the Study of Bioethical Issues, working on
							the Privacy and Progress in Whole Genome Sequencing Report. Prior to
							this, Ms. Dreyfus was the Principal of Competitive Health Strategies, LLC
							– a consulting firm specializing in the interface between health care
							providers and payers, particularly in the areas of managing care for
							populations and understanding financial risk. She worked with a wide
							variety of providers, including hospitals, academic medical centers and
							specialty societies. Ms. Dreyfus held executive positions in healthcare,
							including Senior Administrator at Greater Southeast Community Hospital;
							Director of Provider Relations at NYLCare Health Plans of the
							Mid-Atlantic; and Director of Provider Contracting at CareFirst BlueCross
							BlueShield. Her background and unique expertise make her skilled at
							bridging bioethics, policy and business. Ms. Dreyfus received her MBA
							from Wharton and a Masters in Bioethics from the University of
							Pennsylvania.
						</p>
					</GridColumn>
				</Grid>
			</Container>
		</>
	)
}

export default Dreyfus
