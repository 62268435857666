import { Container, Grid, Image, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'


function NETSPhaseI() {
    return (
        <>
            <div>
                <Container className='container-main'>
                    <h1>NETS: Phase I Clinical Trials (PK, Safety, Dose)</h1>
			        <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>What are Phase I clinical trials and why are they important in drug development?</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>
                                In Phase I clinical trials researchers test a new drug or treatment on a small group of people for the first time to evaluate its safety, determine a safe dosage range, and identify side effects: in other words, they study its{' '}
                                <Link to='/resources-and-information/nets/safety' rel='noopener noreferrer'>
                                    safety 
                                </Link>
                                {' '}and{' '} 
                                <Link to='/resources-and-information/nets/dose' rel='noopener noreferrer'>
                                    dose
                                </Link>
                                . The{' '} 
                                <Link to='/resources-and-information/nets/pk' rel='noopener noreferrer'>
                                    pharmacokinetics (PK) 
                                </Link>
                                {' '}of the drug or therapy - are examined as well. The participants in Phase I trials are healthy or unaffected volunteers.
                            </p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Testimonials: How we did it</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>Content not yet available for this section.</p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Relevant Publications</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>Content not yet available for this section.</p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Related Tools</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <a
                                href='https://clinicaltrials.gov/ct2/home'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />NIH: ClinicalTrials.gov</Button>
                            </a>
                            <a
                                href='https://www.clinicaltrialsregister.eu/ctr-search/search'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />EU/EEA: EU Clinic Trials Register</Button>
                            </a>
                            <a
                                href='https://www.cancer.net/research-and-advocacy/clinical-trials/phases-clinical-trials'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/guide.png' className='icon-width-16px' />ASCO: Phases of Clinical Trials</Button>
                            </a>
                            <a
                                href='https://www.cancer.net/navigating-cancer-care/videos/cancer-basics/phases-cancer-clinical-trials-%E2%80%93-introduction-with-neal-meropol-md'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/multimedia.png' className='icon-width-16px' />ASCO: Phases of a Cancer Clinical Trial</Button>
                            </a>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Link to='/resources-and-information/nets/full-view' rel='noopener noreferrer' className='link-button inline-block'><Button className='button-orange-500'><Image src='/icons/link-internal-white.png' className='icon-width-16px' style={{ transform: 'scaleX(-1)' }} />Back to NETS Map full view</Button></Link>
                </Container>
            </div>
        </>
    )
}

export default NETSPhaseI