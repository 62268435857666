import { Container, Grid } from 'semantic-ui-react'
import Admin from '../../auth/Admin'
import BlogCreate from 'components/crud/BlogCreate'
import NewsMenu from '../NewsMenu'

const Blog = () => {
	return (
		<Admin>
			<Container className='container-news'>
				<NewsMenu />
				<h1 className='mb-80'>Create a new blog</h1>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <BlogCreate />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
			</Container>
		</Admin>
	)
}

export default Blog
