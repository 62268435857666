import { Container, Grid, Image, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'


function NETSBiorepositories() {
    return (
        <>
            <div>
                <Container className='container-main'>
                    <h1>NETS: Biorepositories</h1>
			        <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>What are biorepositories and why are they important in drug development?</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>
                                Biorepositories are places for storing biological samples (also called{' '} 
                                <Link to='/resources-and-information/nets/biospecimens' rel='noopener noreferrer'>
                                    biospecimens
                                </Link>
                                ) such as blood samples, tumor samples, or other tissue. The type of biological samples stored in biorepositories will vary depending upon the type of disease and nature of research.
                            </p>
                            <div className='inline-flex mb-15'>
                                <Image src='/icons/guide.png' className='icon-width-16px mr-8' style={{ height: '16px', top: '8px' }} />
                                <p>
                                    <a
                                        href='https://biospecimens.cancer.gov/patientcorner/'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        NCI BBRB: What are biospecimens and biorepositories?
                                    </a>
                                </p>
                            </div>
                            {/* <div className='inline-flex mb-15'>
                                <Image src='/icons/guide.png' className='icon-width-16px mr-8' style={{ height: '16px', top: '8px' }} />
                                <p>
                                    <a
                                        href='https://biospecimens.cancer.gov/researchnetwork/lifecycle.asp'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        NCI BBRB: Life Cycle of a Biospecimen
                                    </a>
                                </p>
                            </div> */}
                            {/* <div className='inline-flex'>
                                <Image src='/icons/multimedia.png' className='icon-width-16px mr-8' style={{ height: '16px', top: '8px' }} />
                                <p>
                                    <a
                                        href='https://biospecimens.cancer.gov/programs/cahub/default.asp'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        NCI BBRB: The Critical Role of Biospecimens in Cancer Research
                                    </a>
                                </p>
                            </div> */}
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Testimonials: How we did it</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <div className='inline-flex mb-15'>
                                <Image src='/icons/multimedia.png' className='icon-width-16px mr-8' style={{ height: '16px', top: '8px' }} />
                                <p>
                                    <a
                                        href='https://www.youtube.com/watch?v=U0ES0yDWryM&gl=US&hl=en'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Genetic Alliance: Advocates are Leading in Biobanking
                                    </a>
                                </p>
                            </div>
                            <div className='inline-flex'>
                                <Image src='/icons/multimedia.png' className='icon-width-16px mr-8' style={{ height: '16px', top: '8px' }} />
                                <p>
                                    <a
                                        href='https://www.youtube.com/watch?v=aa6dtTksHso&feature=youtu.be'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Genetic Alliance: Making Your Organization’s Biobank a Reality
                                    </a>
                                </p>
                            </div>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Relevant Publications</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <div className='inline-flex mb-15'>
                                <Image src='/icons/worksheet.png' className='icon-width-16px mr-8' style={{ height: '16px', top: '8px' }} />
                                <p>
                                    <a
                                        href='/pdf/nets/Landscape_Analysis.pdf'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Genetic Alliance: Landscape Analysis of Registries and Biobanks
                                    </a>
                                </p>
                            </div>
                            <div className='inline-flex mb-15'>
                                <Image src='/icons/worksheet.png' className='icon-width-16px mr-8' style={{ height: '16px', top: '8px' }} />
                                <p>
                                    <a
                                        href='http://www.geneticalliance.org/about/mailinglist/biobank'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Genetic Alliance: Registry and Biobank Bulletin
                                    </a>
                                </p>
                            </div>
                            <div className='inline-flex mb-15'>
                                <Image src='/icons/guide.png' className='icon-width-16px mr-8' style={{ height: '16px', top: '8px' }} />
                                <p>
                                    <a
                                        href='/pdf/nets/Workshop_On_Release.pdf'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        NCI: Workshop on Release of Research Results to Participants in Biospecimen Studies
                                    </a>
                                </p>
                            </div>
                            <div className='inline-flex'>
                                <Image src='/icons/guide.png' className='icon-width-16px mr-8' style={{ height: '16px', top: '8px' }} />
                                <p>
                                    <a
                                        href='http://biospecimens.cancer.gov/bestpractices/'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        NCI BBRB: Best Practices for Biospecimen Resources
                                    </a>
                                </p>
                            </div>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Related Tools</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            {/* <a
                                href='http://www.biobank.org/toolbox'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/toolkit.png' className='icon-width-16px' />Genetic Alliance Registry and Biobank Toolbox</Button>
                            </a> */}
                            <a
                                href='https://specimens.cancer.gov/tissue/default.htm'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/toolkit.png' className='icon-width-16px' />NCI: Specimen Resource Locator</Button>
                            </a>
                            <a
                                href='https://dtp.cancer.gov/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/toolkit.png' className='icon-width-16px' />NCI DTP: Tumor Repository</Button>
                            </a>
                            {/* <a
                                href='http://biospecimens.ordr.info.nih.gov/index.html'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />NIH ORDR: Rare Diseases Human Biospecimens/Biorepositories Database</Button>
                            </a> */}
                            <a
                                href='https://biolincc.nhlbi.nih.gov/home/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/toolkit.png' className='icon-width-16px' />NHLBI: Biologic Specimen and Data Repository Information Coordinating Center</Button>
                            </a>
                            <a
                                href='https://repository.niddk.nih.gov/home/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />NIDDK: Central Repository (Data, Biosamples, and Genetics Repositories)</Button>
                            </a>
                            {/* <a
                                href='http://specimencentral.com/biobank-directory.aspx'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/toolkit.png' className='icon-width-16px' />SpecimenCentral: Global Directory of Biobanks and Biospecimen</Button>
                            </a> */}
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Link to='/resources-and-information/nets/full-view' rel='noopener noreferrer' className='link-button inline-block'><Button className='button-orange-500'><Image src='/icons/link-internal-white.png' className='icon-width-16px' style={{ transform: 'scaleX(-1)' }} />Back to NETS Map full view</Button></Link>
                </Container>
            </div>
        </>
    )
}

export default NETSBiorepositories