import { useState, useEffect } from 'react'
import { listRelated, singleBlog } from 'actions/blog'
import { Button, Container, Image, Grid, GridColumn } from 'semantic-ui-react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import SmallCard from 'components/blog/SmallCard'
import NewsMenu from '../user/NewsMenu'

const SingleBlog = ({ match }) => {
	const [blog, setBlog] = useState({})
	const { slug } = match.params
	const [related, setRelated] = useState([])

	useEffect(() => {
		const loadSingleBlog = () => {
			singleBlog(slug).then(data => {
				if (data.error) {
					console.log(data.error)
				} else {
					setBlog(data)
				}
			})
		}
		loadSingleBlog()
	}, [slug])

	useEffect(() => {
		const loadRelatedBlog = () => {
			listRelated(blog).then(data => {
				if (data.error) {
					console.log(data.error)
				} else {
					setRelated(data)
				}
			})
		}
		if (blog) {
			loadRelatedBlog()
		}
	}, [blog])

	const showBlogCategories = blog =>
		blog.categories &&
		blog.categories.map((c, i) => (
			<Link key={i} to={`/categories/${c.slug}`}>
				<Button size='mini' color='blue' outline='true'>
					{c.name}
				</Button>
			</Link>
		))

	const showBlogTags = blog =>
		blog.tags &&
		blog.tags.map((t, i) => (
			<Link key={i} to={`/tags/${t.slug}`}>
				<Button size='mini' color='blue' basic>
					{t.name}
				</Button>
			</Link>
		))

	const showRelatedBlogs = () => {
		return related.map((blog, i) => (
			<GridColumn>
				<SmallCard key={i} blog={blog} />
			</GridColumn>
		))
	}

	return (
		<>
			<Container className='container-news'>
				<NewsMenu />
				<Image
					src={`${process.env.REACT_APP_API_URL}/blog/photo/${blog.slug}`}
					alt={blog.title}
					className='featured-image'
				/>
				<section>
					<section>
						<h1 className='text-center display-title'>{blog.title}</h1>
						<p className='p-compact mark mb-15 mt-15'>
							Written by{' '}
							<Link to={`/profile/${blog.postedBy && blog.postedBy.username}`}>
								{blog.postedBy && blog.postedBy.username}
							</Link>{' '}
							| Published {moment(blog.updatedAt).fromNow()}
						</p>
					</section>
					<section className='mb-25'>
						{showBlogCategories(blog)}
						{showBlogTags(blog)}
					</section>
				</section>
				<section
					className='ml-25 mr-25'
					dangerouslySetInnerHTML={{
						__html: blog.body && blog.body.replace(/(<? *script)/gi, 'illegalscript'),
					}}
				/>
				<section className='mt-40'>
					<h2 className='text-center'>Related Blogs</h2>
					<Grid columns={3} divided>
						<Grid.Row>{showRelatedBlogs()}</Grid.Row>
					</Grid>
					<p>show comments</p>
				</section>
			</Container>
		</>
	)
}

export default SingleBlog
