import { Container, Grid, GridColumn, Image } from 'semantic-ui-react'
// import { Link } from 'react-router-dom'

function Baker() {
	return (
		<>
			<Container className='container-main'>
				<h1>IRB Roster</h1>
				<Grid columns={1} padded>
					<GridColumn>
						<Image
							alt='Dixie Baker'
							src='/images/IRB/BakerHeadshot.jpeg'
							size='small'
							floated='left'
							rounded
						/>
						<h2>Dixie Baker</h2>
						<h3>IRB Board Member</h3>
						<p>
							Dr. Dixie Baker is a Senior Partner at Martin, Blanck and Associates,
							where she provides consulting services in the areas of health information
							technology (HIT), health information exchange, privacy and security, and
							the sharing and protection of genomic data. Prior to joining Martin,
							Blanck in 2012, she was a Senior Vice President and Technical Fellow at
							Science Applications International Corporation (SAIC), where she served
							as the Chief Technology Officer for the Health and Life Sciences
							business, and as a member of SAIC’s Institutional Review Board (IRB),
							which provided oversight for all SAIC research involving human subjects.
							In 2009, the Secretary of the Department of Health and Human Services
							appointed Dr. Baker a member of the HIT Standards Committee (HITSC), a
							Federal Advisory Committee created by the American Recovery and
							Reinvestment Act (ARRA) to recommend standards, implementation
							specifications, and certification criteria for electronic health record
							(EHR) technology. Currently serving her second term, Dr. Baker chairs the
							HITSC Privacy and Security Workgroup and the Nationwide Health
							Information Network (NwHIN) Power Team, and also serves on the Privacy
							and Security Policy Tiger Team of the HIT Policy Committee. In addition,
							Dr. Baker co-chairs the Security Working Group of the Global Alliance for
							Genomics and Health, an international collaboration for the sharing of
							genomic data for biomedical research. She is a Fellow of the Healthcare
							Information and Management Systems Society (HIMSS), and in 2011 was
							presented the HIMSS John A. Page Outstanding Service award.
						</p>

						<p>
							In 2010, Dr. Baker was selected as one of the “Federal 100” top
							executives from government, industry and academia who had the greatest
							impact on the government’s information-systems community. In 2013,
							healthcareinfosecurity.com selected her as one of its inaugural “Top 10
							Influencers in Health Information Security.” Dr. Baker holds an MS in
							Computer Science and a PhD in Special Education from the University of
							Southern California, and MS and BS degrees from Florida State University
							and The Ohio State University respectively.
						</p>
					</GridColumn>
				</Grid>
			</Container>
		</>
	)
}

export default Baker
