import { Container, Grid, GridColumn, Image, Icon} from 'semantic-ui-react'
import ShareSocialMedia from '../../../ShareSocialMedia'
import LinkButton from '../../../LinkButton'

function StepsToEmploymentSuccess() {
	const shareUrl = 'https://geneticalliance.org/resources-and-information/advocacy-atlas/employment-success'
	return (
		<Container className='container-main'>
			<h1>Steps to Employment Success</h1>
			<Grid columns={1} padded>
				<GridColumn>
					<hr />
					<ShareSocialMedia shareUrl={shareUrl} />
					<div>
						<p className='mt-20'>
							<i>When Elise was in her next-to-the-last year of school, the system cut back the vocational teacher who took people out into the community for job experiences. I wrote a school board member that I knew and told him that I was not going to sign my daughter IEP because she was not going to get job training in the community. She told the principal, who asked to see my letter. The principal said my letter made absolute sense, and she said to put the vocational training into the IEP, and she would sign it. The result: Elise got three mornings a week of job training from the one reinstated voc teacher in the whole county. The next year she got five mornings. I ended up being chosen as a parent rep on a county school system task force studying transition—a real learning experience.
							<br /><br />
							-Story from a parent advocate</i>
						</p>
					</div>

					<h2 className='color-secondary mt-40'>Resources</h2>
					<Grid stackable columns={2} className='mt-15 left aligned'>
						<Grid.Row>
							<Grid.Column>
								<p>
									<a className='link-blue-800'
										href='https://dcmp.org/learn/682'
										target='_blank'
										rel='noreferrer'>
										Getting a Job! Online Training
									</a>
									- This online training was developed and designed for students who are deaf or hard of hearing and the professionals who work with them.
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/employment-success/speaking_up_booklet_final1.pdf'
										target='_blank'
										rel='noreferrer'>
										Speak Up - A guide to self advocacy
									</a>
									- The Arc of Washington
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/employment-success/Lifeafterhighschool.pdf'
										target='_blank'
										rel='noreferrer'>
										Life after high school, ten skills to teach your child
									</a>
									- Diane Adreon, MA
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='https://www.secondarytransition.org/'
										target='_blank'
										rel='noreferrer'>
										Pennsylvania Secondary Transition Guide
									</a>
									- Transition resources to facilitate a young person’s progress toward the attainment of post-secondary goals related to education, employment, and community living. 
								</p>
							</Grid.Column>
							<Grid.Column>
								<div>
									<figure>
										<Image
											src='/images/resources-and-information/advocacy-atlas/employment-success/employmentpic.jpeg'
											size='medium'
											centered
										/>
										<figcaption className='mt-20 text-center'>
											<i>Alexa poses in front of the pastry case at the coffee shop where she works.</i>
										</figcaption>
									</figure>
								</div>
							</Grid.Column>
						</Grid.Row>
					</Grid>
					<div>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/employment-success/journeytoadulthood.pdf'
								target='_blank'
								rel='noreferrer'>
								Journey to Adulthood: A transitions travel guide
							</a>
							- National Health Care Transition Center
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/employment-success/RoadtoWork.pdf'
								target='_blank'
								rel='noreferrer'>
								The Road to Work: The Speak-Up Action Manual on Finding and Keeping Work
							</a>
							- Youths 4 Advocacy
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='https://askjan.org/soar.cfm'
								target='_blank'
								rel='noreferrer'>
								Searchable Online Accommodation Resource (SOAR)
							</a>
							- Job Accommodation Network (JAN)
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/employment-success/MyChoiceMyFuture.pdf'
								target='_blank'
								rel='noreferrer'>
								My Choice My Future Making Informed Choices
							</a>
							- Eastern Los Angeles Regional Center
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/employment-success/NCSETIssueBrief_1.4.pdf'
								target='_blank'
								rel='noreferrer'>
								Creating Mentoring Opportunities for Youth with Disabilities: Issues and Suggested Strategies
							</a>
							- National Center on Secondary Education and Transition
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/employment-success/intern-guide-final.pdf'
								target='_blank'
								rel='noreferrer'>
								Internships: The On-Ramp to Employment, A Guide for Students with Disabilities to Getting and Making the Most of an Internship
							</a>
							- National Consortium on Leadership and Disability for Youth
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/employment-success/Careers_2012.pdf'
								target='_blank'
								rel='noreferrer'>
								Exploring Careers: Information for Parents of Students with Disabilities
							</a>
							- Pennsylvania Department of Education
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/employment-success/Employment Kit for Indiviudals with Autism.pdf'
								target='_blank'
								rel='noreferrer'>
								Family Services Employment Tool Kit
							</a>
							- Autism Speaks
						</p>
					</div>
					<div className='mt-40 text-center'>
						<LinkButton className='ui button' 
						to='/resources-and-information/advocacy-atlas'
						>
							<Icon name='arrow left' /> Return to Advocacy ATLAS Homepage
						</LinkButton>
					</div>
				</GridColumn>
			</Grid>
		</Container>
	)
}

export default StepsToEmploymentSuccess