import { Container, Grid, GridColumn } from 'semantic-ui-react'
import ShareSocialMedia from './../../ShareSocialMedia'

function GeneticAllianceCoreValues() {
    const shareUrl = 'http://www.geneticalliance.org/about/values'
	return (
		<Container className='container-main'>
			<h1>Genetic Alliance Core Values</h1>
			<Grid columns={1} padded>
				<GridColumn>
                    <hr /> 
                    <ShareSocialMedia shareUrl={shareUrl} />
                    <div>
                        <p>
                            Genetic Alliance commits to accomplish our mission with integrity, and to live by these values in everything we do.
                        </p>
                       <ul className='list-space list-right'>
                            <li>
                                <b><i>Collectivity</i></b>: We believe that everyone values health and well-being for themselves and those they love.
                            </li>
                            <li>
                                <b><i>Openness</i></b>: To achieve better health for all, we must embrace the diverse priorities of our allies while contributing to a common goal.
                            </li>
                            <li>
                                <b><i>Transparency</i></b>: We must actively understand and honestly communicate our biases and ambitions...
                            </li>
                            <li>
                                <b><i>Trustworthiness</i></b>: ...practicing our mission, in order to cultivate and sustain trust.
                            </li>
                            <li>
                                <b><i>Vulnerability</i></b>: And yet, we must also risk our comfort and be willing to trust in others...
                            </li>
                            <li>
                                <b><i>Intersectionality</i></b>: ...for the factors that influence health and happiness are myriad and too great for any one organization to solve.
                            </li>
                            <li>
                                <b><i>Commitment to Agency</i></b>: Indeed, we must support the inherent power of people’s choices and build systems that meet their needs...
                            </li>
                            <li>
                                <b><i>Inclusivity</i></b>: ...to ensure that these benefits are available to all people.
                            </li>
                            <li>
                                <b><i>Responsibility</i></b>: We are responsible for the health of ourselves and all others, and...
                            </li>
                            <li>
                                <b><i>Tempered Urgency</i></b>: ...we must act with purpose and haste to create the change we seek within our lifetimes.
                            </li>
                       </ul>
                    </div>
				</GridColumn>
			</Grid>
		</Container>
	)
}

export default GeneticAllianceCoreValues