import React from 'react'

function IHopeGeneticHealthLabs() {
    return (
        <>
            <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
            <div className='computer-display-flex computer-justify-center mb-80-60-40'>
                <p className='max-width-864px'>
                    If you are a lab that is interested in offering clinical whole genome sequencing (cWGS), please fill out this survey:{' '}
                        <a  
                            href='https://docs.google.com/forms/d/e/1FAIpQLSeZxUH_LTw81Av-lCvx4RlrO3lXusw57lEoa7RJ1nMogDu1oA/viewform'
                            target='_blank'
                            rel='noreferrer'
                        >
                            Laboratory Capacity Questionnaire
                        </a>
                </p>
            </div>
        </>
    )
}

export default IHopeGeneticHealthLabs