import React from 'react'
import {
	Form,
	Button,
	Grid,
	GridRow,
	GridColumn,
	Container,
	Image,
} from 'semantic-ui-react'
import { useState } from 'react'
import emailjs from 'emailjs-com'
import { useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import axios from 'axios'

const IHopeGeneticHealthNewsLetters = () => {
	const [id, setId] = useState('')
	const [emailSent, setEmailSent] = useState(false)

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm()

	const sendEmail = () => {
		emailjs
			.sendForm(
				'service_715mwtp',
				'template_1jyzufq',
				'#newsletterForm',
				'user_RvBcfNskpg2k1v812rots'
			)
			.then(
				result => {
					// console.log(result.text);
					// setFirstName('')
					// setLastName('')
					// setEmail('')
					// setId('')
					setEmailSent(true)
				},
				error => {
					console.log(error.text)
				}
			)
	}

	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [email, setEmail] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')
	const [country, setCountry] = useState('')
	const [interest, setInterest] = useState('')
	const [institution, setInstitution] = useState('')
	const [idea, setIdea] = useState('')
	const [institutionOptional, setInstitutionOptional] = useState('')
	const [team, setTeam] = useState('')
	const [contribute, setContribute] = useState('')
	const [additionalComment, setAdditionalComment] = useState('')

	const submitHandler = () => {
		//The next five lines are for the date and time of form submission in Eastern Time (ET).
		const currentdatetime = new Date()
		const datetime =
			currentdatetime.toLocaleDateString('en-US', {
				timeZone: 'America/New_York',
			}) +
			' @ ' +
			currentdatetime.toLocaleTimeString('en-US', {
				timeZone: 'America/New_York',
			}) +
			' EST'
		axios.post(
			`https://sheet.best/api/sheets/a868edce-b530-402f-8771-92aef051ec15`,
			{
				firstName,
				lastName,
				email,
				phoneNumber,
				country,
				id,
				interest,
				institution,
				idea,
				institutionOptional,
				team,
				contribute,
				additionalComment,
				dateAndTime: datetime,
			}
		)
	}

	// const checkKeyDown = (e) => {
	//   if (e.code === 'Enter') e.preventDefault();
	// };

	const [formSubmitted, setFormSubmitted] = useState(false)

	const sendData = () => {
		sendEmail()
		submitHandler()
		setFormSubmitted(true)
		setEmailSent(true)
	}

	return (
		<div className='pb-30-10' style={{ background: '#E1EDF7' }}>
			<Container className='container-main' id='iHopeGeneticHealthNewsLetters'>
				<Grid stackable>
					<GridRow columns={2} className='pt-80-60-40'>
						<GridColumn mobile={16} tablet={16} computer={8}>
							<h3 className='width-560px mb-40'>
								Stay informed about new developments at iHope Genetic Health
							</h3>
						</GridColumn>
						<GridColumn mobile={16} tablet={16} computer={8}>
							{emailSent ? (
								<p className='mt-40'>
									Thank you for your message, we will be in touch in no time!
								</p>
							) : (
								<Form
									id='newsletterForm'
									onSubmit={handleSubmit(sendData)}
									className='maxWidth-528'>
									<Form.Group widths='equal' className='mb-0'>
										<Form.Field>
											<label className='field-title'>FIRST NAME</label>
											<input
												className='p-compact inner-border-form'
												autoComplete='new-password'
												label='First Name'
												placeholder='First Name'
												type='text'
												name='firstName'
												{...register('firstName', { required: 'First Name is required.' })}
												onChange={e =>
													setFirstName(e.target.value)
												} /* onKeyDown={(e) => checkKeyDown(e)} */
											/>
										</Form.Field>
										<Form.Field>
											<label className='field-title'>LAST NAME</label>
											<input
												className='p-compact inner-border-form'
												autoComplete='new-password'
												placeholder='Last Name'
												type='text'
												name='lastName'
												{...register('lastName', { required: 'Last Name is required.' })}
												onChange={e =>
													setLastName(e.target.value)
												} /* onKeyDown={(e) => checkKeyDown(e)} */
											/>
										</Form.Field>
									</Form.Group>
									<div
										className='form-group inline-flex mt-20'
										style={{ width: '100%', gap: '16px' }}>
										<ErrorMessage
											errors={errors}
											name='firstName'
											render={({ message }) => (
												<p
													className={
														formSubmitted
															? 'display-none p-compact-negative mr-100'
															: 'error mr-100 mb-20'
													}
													style={{ width: '100%' }}>
													{message}
												</p>
											)}
										/>
										<ErrorMessage
											errors={errors}
											name='lastName'
											render={({ message }) => (
												<p
													className={
														formSubmitted
															? 'display-none p-compact-negative mb-20'
															: 'error mb-20'
													}
													style={{ width: '100%' }}>
													{message}
												</p>
											)}
										/>
									</div>

									<Form.Field className='mb-0'>
										<label className='field-title'>EMAIL ADDRESS</label>
										<input
											className='p-compact inner-border-form'
											placeholder='Email Address'
											type='email'
											name='email'
											{...register('email', { required: 'Email is required.' })}
											onChange={e =>
												setEmail(e.target.value)
											} /* onKeyDown={(e) => checkKeyDown(e)} */
										/>
									</Form.Field>
									<ErrorMessage
										errors={errors}
										name='email'
										render={({ message }) => (
											<p
												className={
													formSubmitted ? 'display-none p-compact-negative' : 'error'
												}>
												{message}
											</p>
										)}
									/>
									<Form.Field className='mt-20 mb-0'>
										<label className='field-title'>PHONE NUMBER</label>
										<input
											className='p-compact inner-border-form'
											autoComplete='new-password'
											placeholder='Phone Number'
											type='tel'
											name='phoneNumber'
											{...register('phoneNumber', {
												required: 'Phone Number is required.',
											})}
											onChange={e =>
												setPhoneNumber(e.target.value)
											} /* onKeyDown={(e) => checkKeyDown(e)} */
										/>
									</Form.Field>
									<ErrorMessage
										errors={errors}
										name='phoneNumber'
										render={({ message }) => (
											<p
												className={
													formSubmitted ? 'display-none p-compact-negative' : 'error'
												}>
												{message}
											</p>
										)}
									/>
									<Form.Field className='mt-20 mb-0'>
										<label className='field-title'>COUNTRY</label>
										<input
											className='p-compact inner-border-form'
											autoComplete='new-password'
											placeholder='Country'
											type='text'
											name='c'
											{...register('c', { required: 'Country is required.' })}
											onChange={e =>
												setCountry(e.target.value)
											} /* onKeyDown={(e) => checkKeyDown(e)} */
										/>
									</Form.Field>
									<ErrorMessage
										errors={errors}
										name='c'
										render={({ message }) => (
											<p
												className={
													formSubmitted ? 'display-none p-compact-negative' : 'error'
												}>
												{message}
											</p>
										)}
									/>
									<Form.Field className='mt-40 mb-40'>
										<label className='field-title'>iAM</label>
										<select
											className='p-compact inner-border-form'
											required
											name='id'
											{...register('id', { required: 'Identification is required.' })}
											onChange={e =>
												setId(e.target.value)
											} /* onKeyDown={(e) => checkKeyDown(e)} */
										>
											<option value=''>iAm*</option>
											<option value='a family'>a family</option>
											<option value='a partner'>a partner</option>
											<option value='a donor'>a donor</option>
										</select>
									</Form.Field>
									<ErrorMessage
										errors={errors}
										name='id'
										render={({ message }) => (
											<p
												className={
													formSubmitted ? 'display-none p-compact-negative' : 'error'
												}>
												{message}
											</p>
										)}
									/>

									{id === 'a family' && (
										<>
											<Form.Field className='mb-0'>
												<label className='field-title'>
													TELL US ABOUT YOUR INTEREST IN THIS PROGRAM.
												</label>
												<textarea
													className='p-compact inner-border-form'
													autoComplete='new-password'
													placeholder='Tell us about your interest in this program.'
													type='text'
													name='in'
													{...register('in', { required: 'Interest is required.' })}
													style={{ height: '6em', minHeight: '6em', maxHeight: '24em' }}
													onChange={e => setInterest(e.target.value)}
												/>
											</Form.Field>
											<ErrorMessage
												errors={errors}
												name='in'
												render={({ message }) => (
													<p
														className={
															formSubmitted ? 'display-none p-compact-negative' : 'error'
														}>
														{message}
													</p>
												)}
											/>
											<Form.Field className='mt-40 mb-40'>
												<label className='field-title'>
													IS THERE ANYTHING ELSE YOU WOULD LIKE THE IHOPE GENETIC HEALTH TEAM
													TO KNOW?
												</label>
												<textarea
													className='p-compact inner-border-form'
													autoComplete='new-password'
													placeholder='Is there anything else you would iHope Genetic Health team to know?'
													type='text'
													name='aC'
													style={{ height: '6em', minHeight: '6em', maxHeight: '24em' }}
													onChange={e => setAdditionalComment(e.target.value)}
												/>
											</Form.Field>
										</>
									)}
									{id === 'a partner' && (
										<>
											<Form.Field className='mt-40 mb-0'>
												<label className='field-title'>
													INSTITUTION/COMPANY/ORGANIZATION'S NAME (IF YOU ARE ACTING AS AN
													INDEPENDENT ENTITY, WRITE A DESCRIPTION OF THAT)
												</label>
												<textarea
													className='p-compact inner-border-form'
													autoComplete='new-password'
													placeholder="Institution/company/organization's name (if you are acting as an independent entity, write a description of that)"
													type='text'
													name='ins'
													{...register('ins', { required: 'Institution is required.' })}
													style={{ height: '6em', minHeight: '6em', maxHeight: '24em' }}
													onChange={e => setInstitution(e.target.value)}
												/>
											</Form.Field>
											<ErrorMessage
												errors={errors}
												name='ins'
												render={({ message }) => (
													<p
														className={
															formSubmitted ? 'display-none p-compact-negative' : 'error'
														}>
														{message}
													</p>
												)}
											/>
											<Form.Field className='mt-40 mb-0'>
												<label className='field-title'>
													WHAT DO YOU HAVE TO OFFER IGH? HOW DO YOU SEE YOURSELF PARTNERING
													WITH US? ALL IDEAS WELCOME!
												</label>
												<textarea
													className='p-compact inner-border-form'
													autoComplete='new-password'
													placeholder='What do you have to offer iGH? How do you see yourself partnering with us? All ideas welcome!'
													type='text'
													name='i'
													{...register('i', { required: 'Idea is required.' })}
													style={{ height: '6em', minHeight: '6em', maxHeight: '24em' }}
													onChange={e => setIdea(e.target.value)}
												/>
											</Form.Field>
											<ErrorMessage
												errors={errors}
												name='i'
												render={({ message }) => (
													<p
														className={
															formSubmitted ? 'display-none p-compact-negative' : 'error'
														}>
														{message}
													</p>
												)}
											/>
											<Form.Field className='mt-40 mb-40'>
												<label className='field-title'>
													IS THERE ANYTHING ELSE YOU WOULD LIKE THE IHOPE GENETIC HEALTH TEAM
													TO KNOW?
												</label>
												<textarea
													className='p-compact inner-border-form'
													autoComplete='new-password'
													placeholder='Is there anything else you would iHope Genetic Health team to know?'
													type='text'
													name='aC'
													style={{ height: '6em', minHeight: '6em', maxHeight: '24em' }}
													onChange={e => setAdditionalComment(e.target.value)}
												/>
											</Form.Field>
										</>
									)}
									{id === 'a donor' && (
										<>
											<Form.Field className='mt-40 mb-0'>
												<label className='field-title'>
													INSTITUTION'S NAME (IF APPLICABLE)
												</label>
												<input
													className='p-compact inner-border-form'
													autoComplete='new-password'
													placeholder="Institution's Name"
													type='text'
													name='iO'
													onChange={e =>
														setInstitutionOptional(e.target.value)
													} /* onKeyDown={(e) => checkKeyDown(e)} */
												/>
											</Form.Field>
											<Form.Field className='mt-40 mb-0'>
												<label className='field-title'>
													WE HAVE A NUMBER OF WAYS TO CONTRIBUTE, HOW WOULD YOU LIKE TO
													DONATE?
												</label>
												<textarea
													className='p-compact inner-border-form'
													autoComplete='new-password'
													placeholder='We have a number of ways to contribute, how would you like to donate?'
													type='text'
													name='cb'
													{...register('cb', { required: 'Contribute is required.' })}
													style={{ height: '6em', minHeight: '6em', maxHeight: '24em' }}
													onChange={e => setContribute(e.target.value)}
												/>
											</Form.Field>
											<ErrorMessage
												errors={errors}
												name='cb'
												render={({ message }) => (
													<p
														className={
															formSubmitted ? 'display-none p-compact-negative' : 'error'
														}>
														{message}
													</p>
												)}
											/>
											<Form.Field className='mt-40 mb-0'>
												<label className='field-title'>
													WOULD YOU LIKE TO SPEAK TO A MEMBER OF THE TEAM BEFORE MAKING YOUR
													DONATION?
												</label>
												<textarea
													className='p-compact inner-border-form'
													autoComplete='new-password'
													placeholder='Would you like to speak to a member of the team before making your donation?'
													type='text'
													name='tm'
													style={{ height: '6em', minHeight: '6em', maxHeight: '24em' }}
													onChange={e => setTeam(e.target.value)}
												/>
											</Form.Field>
											<Form.Field className='mt-40 mb-40'>
												<label className='field-title'>
													IS THERE ANYTHING ELSE YOU WOULD LIKE THE IHOPE GENETIC HEALTH TEAM
													TO KNOW?
												</label>
												<textarea
													className='p-compact inner-border-form'
													autoComplete='new-password'
													placeholder='Is there anything else you would iHope Genetic Health team to know?'
													type='text'
													name='aC'
													style={{ height: '6em', minHeight: '6em', maxHeight: '24em' }}
													onChange={e => setAdditionalComment(e.target.value)}
												/>
											</Form.Field>
										</>
									)}
									{/* <ReCAPTCHA sitekey="6LcSf0AdAAAAAIZ46Fg1jJV8ZHZHeAmi6wRszpJe" /> */}
									{/* <ReCAPTCHA sitekey="6LcSf0AdAAAAACYyvVVdAHXUEmRFkqhdYwJHPIoU" /> */}
									{/* <div class="g-recaptcha" data-sitekey="6LcSf0AdAAAAACYyvVVdAHXUEmRFkqhdYwJHPIoU"></div> */}
									{/* <ReCAPTCHA sitekey="6LcSf0AdAAAAAIZ46Fg1jJV8ZHZHeAmi6wRszpJe" /> */}
									<Form.Field>
										<div
											className='g-recaptcha'
											data-sitekey='6LeKPOUkAAAAAK59AGnvDQ88l2YnhPOEFCEHaX2I'></div>
									</Form.Field>
									<Button
										type='submit'
										className='button-blue-800 mt-40'
										style={{ margin: '0' }}>
										Submit
										<Image
											src='/icons/link-internal-white.png'
											className='icon-width-16px'
										/>
									</Button>
								</Form>
							)}
						</GridColumn>
					</GridRow>
				</Grid>
			</Container>
		</div>
	)
}

export default IHopeGeneticHealthNewsLetters
