import { Grid, Form } from 'semantic-ui-react'
import './IRBApplication.css'
import { useState, useEffect } from 'react'
import NextBackStep from './NextBackStep'

function StepEight({
	step,
	setStep,
	totalSteps,
	updateFields,
	openEnded,
	postmortem,
	previousData,
	numberPeople,
	numberYears,
	inclusion,
	exclusion,
	population1,
	population2,
	population3,
	...data
}) {
	const [openEndedError, setOpenEndedError] = useState(false)
	const [postmortemError, setPostmortemError] = useState(false)
	const [previousDataError, setPreviousDataError] = useState(false)
	const [numberPeopleError, setNumberPeopleError] = useState(false)
	const [numberYearsError, setNumberYearsError] = useState(false)
	const [inclusionError, setInclusionError] = useState(false)
	const [exclusionError, setExclusionError] = useState(false)

	const isOpenEndedMissing = () => {
		if (!openEnded) {
			setOpenEndedError(true)
			return true
		}
		return false
	}
	const isPostmortemMissing = () => {
		if (!postmortem) {
			setPostmortemError(true)
			return true
		}
		return false
	}
	const isPreviousDataMissing = () => {
		if (!previousData) {
			setPreviousDataError(true)
			return true
		}
		return false
	}
	const isNumberPeopleMissing = () => {
		if (!numberPeople) {
			setNumberPeopleError(true)
			return true
		}
		return false
	}
	const isNumberYearsMissing = () => {
		if (!numberYears) {
			setNumberYearsError(true)
			return true
		}
		return false
	}
	const isInclusionMissing = () => {
		if (!inclusion) {
			setInclusionError(true)
			return true
		}
		return false
	}
	const isExclusionMissing = () => {
		if (!exclusion) {
			setExclusionError(true)
			return true
		}
		return false
	}

	let missingFields =
		openEndedError ||
		postmortemError ||
		previousDataError ||
		numberPeopleError ||
		numberYearsError ||
		inclusionError ||
		exclusionError

	const next = () => {
		isOpenEndedMissing()
		isPostmortemMissing()
		isPreviousDataMissing()
		isNumberPeopleMissing()
		isNumberYearsMissing()
		isInclusionMissing()
		isExclusionMissing()
		if (
			isOpenEndedMissing() === false &&
			isPostmortemMissing() === false &&
			isPreviousDataMissing() === false &&
			isNumberPeopleMissing() === false &&
			isNumberYearsMissing() === false &&
			isInclusionMissing() === false &&
			isExclusionMissing() === false
		) {
			setStep(i => {
				return i + 1
			})
		}
		missingFields = true
	}

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<Grid centered>
			<Grid.Column computer={12}>
				<h3 className='mb-40'>
					Human Participants{' '}
				</h3>
				<p>
					At the end of published pieces, the statement on Human Participant Protection (in accordance with 45CFR46.111(3)) shows whether studies have been approved or exempted by a named IRB. If human participants are involved, a statement of approval by an institutional review board (IRB) and the participants’ informed consent (unless otherwise waived) are required. The fields below outline the parameters of your study population.
				</p>
				<hr className='rule-blue-300 mt-40 mb-40' />
				{
					<Form>
						<p>
							Is this an open-ended study? <span className='error-color'>*</span>
						</p>
						<Form.Group className='display-block'>
							<Form.Radio
								className='mb-15'
								label='Yes'
								type='radio'
								name='openEnded'
								checked={openEnded === 'Yes'}
								value='Yes'
								onChange={e => updateFields({ openEnded: 'Yes' })}
							/>
							<Form.Radio
								label='No'
								type='radio'
								name='openEnded'
								checked={openEnded === 'No'}
								value='No'
								onChange={e => updateFields({ openEnded: 'No' })}
							/>
						</Form.Group>
						{openEndedError && <p className='error'>Please select an option.</p>}
						<p className='mt-40'>
							We estimate that ________ people will join over the next _______ years. <span className='error-color'>*</span>
						</p>
						<Form.Group className='mb-40'>
							<Form.Field>
								<Form.Input
									required
									fluid
									className='width-300'
									label='Number of people'
									placeholder='Number of people *'
									type='number'
									value={numberPeople}
									onChange={e => {
										updateFields({ numberPeople: e.target.value })
										setNumberPeopleError(false)
									}}
								/>
								{numberPeopleError && (
									<p className='error mt-5'>Number of people is required.</p>
								)}
							</Form.Field>
							<Form.Field>
								<Form.Input
									required
									fluid
									className='width-300'
									label='Number of years'
									placeholder='Number of years *'
									type='number'
									value={numberYears}
									onChange={e => {
										updateFields({ numberYears: e.target.value })
										setNumberYearsError(false)
									}}
								/>
								{numberYearsError && (
									<p className='error mt-5'>Number of years is required.</p>
								)}
							</Form.Field>
						</Form.Group>
						<p className='mt-40'>
							What are the inclusion criteria for your study? These are items which distinguish individuals who are eligible to join your study. Be as detailed as possible{' '}
							<span className='error-color'>*</span>
						</p>
						<Form.TextArea
							required
							label='Inclusion criteria'
							placeholder='Inclusion criteria'
							type='text'
							value={inclusion}
							onChange={e => {
								updateFields({ inclusion: e.target.value })
								setInclusionError(false)
							}}
						/>{' '}
						{inclusionError && (
							<p className='error mt-5'>Inclusion criteria are required.</p>
						)}
						<p className='mt-40'>
							What are the exclusion criteria for your study? These are items which would bar an individual from joining your study, even if they met the inclusion criteria. Be as detailed as possible <span className='error-color'>*</span>
						</p>
						<Form.TextArea
							required
							label='Exclusion criteria'
							placeholder='Exclusion criteria'
							type='text'
							value={exclusion}
							onChange={e => {
								updateFields({ exclusion: e.target.value })
								setExclusionError(false)
							}}
						/>{' '}
						{exclusionError && (
							<p className='error mt-5'>Exclusion criteria are required.</p>
						)}
						<p className='mt-40'>
							Are postmortem (individuals who passed prior to study initiation)
							participants eligible to contribute data after death?{' '}
							<span className='error-color'>*</span>
						</p>
						<Form.Group className='display-block'>
							<Form.Radio
								className='mb-15'
								label='Yes'
								type='radio'
								name='postmortem'
								checked={postmortem === 'Yes'}
								value='Yes'
								onChange={e => updateFields({ postmortem: 'Yes' })}
							/>
							<Form.Radio
								label='No'
								type='radio'
								name='postmortem'
								checked={postmortem === 'No'}
								value='No'
								onChange={e => updateFields({ postmortem: 'No' })}
							/>
						</Form.Group>
						{postmortemError && <p className='error'>Please select an option.</p>}
						<p className='mt-40'>
							Will data contributed by individuals who become deceased during the study
							have their previously contributed data remain in the study beyond the
							duration of their life? <span className='error-color'>*</span>
						</p>
						<Form.Group className='display-block'>
							<Form.Radio
								className='mb-15'
								label='Yes'
								type='radio'
								name='previousData'
								checked={previousData === 'Yes'}
								value='Yes'
								onChange={e => updateFields({ previousData: 'Yes' })}
							/>
							<Form.Radio
								label='No'
								type='radio'
								name='previousData'
								checked={previousData === 'No'}
								value='No'
								onChange={e => updateFields({ previousData: 'No' })}
							/>
						</Form.Group>
						{previousDataError && <p className='error'>Please select an option.</p>}
						<p className='mt-40'>
							List any vulnerable populations that will be specifically included or
							excluded below, in addition to a brief description as to why (leave these
							blanks empty if none are being specifically included/excluded). For
							information on vulnerable populations, visit:{' '}
							<a
								href='https://grants.nih.gov/policy/humansubjects/policies-and-regulations/vulnerable-populations.htm'
								target='_blank'
								rel='noreferrer'>
								https://grants.nih.gov/policy/humansubjects/policies-and-regulations/vulnerable-populations.htm
							</a>
						</p>
						<Form.TextArea
							label='Population 1 name and reason'
							placeholder='Population 1 name and reason'
							type='text'
							value={population1}
							onChange={e => updateFields({ population1: e.target.value })}
						/>{' '}
						<Form.TextArea
							label='Population 2 name and reason'
							placeholder='Population 2 name and reason'
							type='text'
							value={population2}
							onChange={e => updateFields({ population2: e.target.value })}
						/>{' '}
						<Form.TextArea
							label='Population 3 name and reason'
							placeholder='Population 3 name and reason'
							type='text'
							value={population3}
							onChange={e => updateFields({ population3: e.target.value })}
						/>{' '}
					</Form>
				}
				{missingFields && (
					<p className='error missing-field'>
						Please fill all required fields above.
					</p>
				)}
				<NextBackStep
					step={step}
					totalSteps={totalSteps}
					next={next}
					setStep={setStep}
					data={data}
				/>
			</Grid.Column>
		</Grid>
	)
}

export default StepEight
