import { Container, Grid, Image, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'


function NETSClinicalTrials() {
    return (
        <>
            <div>
                <Container className='container-main'>
                    <h1>NETS: Clinical Trials</h1>
			        <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>What are clinical trials and why are they important in drug development?</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>
                                Clinical trials are studies to test the safety and efficacy of new therapeutic treatments, such as drugs or biologics.{' '}
                                <Link to='/resources-and-information/nets/sponsor'>
                                    Clinical trial sponsors 
                                </Link>{' '}
                                must obtain FDA approval before they may begin trials. The FDA requires sponsors to submit data showing safety and efficacy in animal models before they will permit testing in humans. Clinical trials can also be used to compare the safety and efficacy of different therapeutic treatments.
                            </p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Testimonials: How we did it</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>
                                Content not yet available for this section.
                            </p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Relevant Publications</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8} style={{ display: 'flex' }}>
                            <Image src='/icons/worksheet.png' className='icon-width-16px mr-8' style={{ height: '16px', top: '8px' }} />
                            <p>
                                <a
                                    href='https://annals.org/aim/fullarticle/744634/rethinking-randomized-clinical-trials-comparative-effectiveness-research-need-transformational-change'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    Annals of Internal Medicine: Rethinking Randomized Clinical Trials for Comparative Effectiveness Research
                                </a>
                            </p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Related Tools</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <a
                                href='https://www.clinicaltrials.gov/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />ClinicalTrials.gov</Button>
                            </a>
                            <a
                                href='https://www.clinicaltrialsregister.eu/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />EU Clinical Trials Register</Button>
                            </a>
                            <a
                                href='https://www.fda.gov/ScienceResearch/SpecialTopics/RunningClinicalTrials/default.htm'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/toolkit.png' className='icon-width-16px' />FDA: Clinical Trials and Human Subjects Protection</Button>
                            </a>
                            <a
                                href='https://www.ncbi.nlm.nih.gov/books/NBK279348/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25'
                            >
                                <Button className='button-white'><Image src='/icons/guide.png' className='icon-width-16px' />PubMed Health: Clinical Trials</Button>
                            </a>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Link to='/resources-and-information/nets/full-view' rel='noopener noreferrer' className='link-button inline-block'><Button className='button-orange-500'><Image src='/icons/link-internal-white.png' className='icon-width-16px' style={{ transform: 'scaleX(-1)' }} />Back to NETS Map full view</Button></Link>
                </Container>
            </div>
        </>
    )
}

export default NETSClinicalTrials