import { singleCategory } from "actions/category"
import Card from "components/blog/Card"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { Container } from "semantic-ui-react"
import NewsMenu from "../user/NewsMenu"

const SingleCategory = ({match}) => {
    const {slug} = match.params
    const [category, setCategory] = useState('')
    const [blogs, setBlogs] = useState([])

    useEffect(() => {
        singleCategory(slug).then(data => {
            if (data.error) {
                console.log(data.error)
                toast.error('Category not found')
            } else {
                setCategory(data.category)
                setBlogs(data.blogs)
            }
        })
    }, [slug])

    return (
        <Container className='container-news'>
			<NewsMenu />
			<h1>{category.name}</h1>
			{blogs.map((blog, i) => (
                <Card blog={blog} key={i} />
            ))}
		</Container>
    )
}

export default SingleCategory