import { Container, Grid, Button, Image } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { progressIndicator } from '../content/HelperFunction'

function PrivacyPolicyFillContent({ data }) {
	console.log('PrivacyPolicyFillContent data', data)
	const { pathname, hash, key } = useLocation()
	useEffect(() => {
		// if not a hash link, scroll to top
		if (hash === '') {
			window.scrollTo(0, 0)
		}
		// else scroll to id
		else {
			setTimeout(() => {
				const id = hash.replace('#', '')
				const element = document.getElementById(id)
				if (element) {
					element.scrollIntoView({ behavior: 'smooth' })
				}
			}, 0)
		}
	}, [pathname, hash, key])

	useEffect(() => {
		progressIndicator()
	}, [])

	if (!data) {
		return null
	}
	const title = data.filter(item => item.link === '/privacy-policy' && item.part === 'title')
	const body = data.filter(item => item.link === '/privacy-policy' && item.part === 'body')
	if (title && Array.isArray(title) && title.length > 0) {
		title.sort((a, b) => a.index - b.index)
	} else {
		return null
	}
	if (body && Array.isArray(body) && body.length > 0) {
		body.sort((a, b) => a.index - b.index)
	} else {
		return null
	}

	return (
		<Container className='container-main'>
			<h1>{title[0].header}</h1>
			<Grid columns={1} padded>
				<Grid.Column>
					<p><em>{title[0].dateTime}</em></p>
					<p>
						<Link
							className='link-blue-800'
							to='/home'
							target='_blank'
							rel='noopener noreferrer'>
							Genetic Alliance
						</Link>
						{ReactHtmlParser(title[0].content1)}
					</p>
					{ReactHtmlParser(title[0].content2)}
					<h3 id={body[0].id}>{body[0].header}</h3>
					{ReactHtmlParser(body[0].content)}
					<h3 id={body[1].id}>{body[1].header}</h3>
					{ReactHtmlParser(body[1].content)}
					<h3 id={body[2].id}>{body[2].header}</h3>
					{ReactHtmlParser(body[2].content)}
					<h3 id={body[3].id}>{body[3].header}</h3>
					{ReactHtmlParser(body[3].content1)}
					<p>
						{ReactHtmlParser(body[3].content2)}
						<Link
							className='link-blue-800'
							to='/about/contact-us'
							target='_blank'
							rel='noopener noreferrer'>
							contact us
						</Link>
						{ReactHtmlParser(body[3].content3)}
					</p>
					<h3 id={body[4].id}>{body[4].header}</h3>
					{ReactHtmlParser(body[4].content)}
					<h3 id={body[5].id}>{body[5].header}</h3>
					<p>
						{ReactHtmlParser(body[5].content)}
						<Link
							style={{ display: 'inline-flex' }}
							to='/about/contact-us'
							target='_blank'
							rel='noopener noreferrer'>
							<Button className='button-orange-500' style={{ marginTop: '10px' }}>
								Contact Us&nbsp;&nbsp;
								<Image src='/icons/arrow-right-white.svg' />
							</Button>
						</Link>
					</p>
				</Grid.Column>
			</Grid>
		</Container>
	)
}

export default PrivacyPolicyFillContent
