import { useNavigate } from 'react-router-dom'
import Private from '../auth/Private'
import { isAuth, signOut } from 'actions/auth'
import { Container, Menu } from 'semantic-ui-react'
import { useState } from 'react'

const UserIndex = () => {
	const navigate = useNavigate()
	const [activeItem, setActiveItem] = useState(null)
	const handleItemClick = (e, { name }) => setActiveItem(name)
	return (
		<Private>
			<Container className='container-main'>
				<h1>User Dashboard</h1>
				<Menu vertical>
					<Menu.Item
						className='nav-links sans-menu no-underline'
						name='messages'
						active={activeItem === 'dashboard'}
						onClick={handleItemClick}>
						{`${
							isAuth().name.charAt(0).toUpperCase() + isAuth().name.slice(1) || ''
						}'s Dashboard`}
					</Menu.Item>
					<Menu.Item
						className='nav-links sans-menu no-underline'
						name='messages'
						onClick={() => signOut(() => navigate(`/sign-in`))}>
						Sign Out
					</Menu.Item>
				</Menu>
			</Container>
		</Private>
	)
}

export default UserIndex
