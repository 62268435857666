import React from 'react'
import { isAuth, signOut } from 'actions/auth'
import { useNavigate } from 'react-router-dom'
import { Menu } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'
import Search from 'components/blog/Search'

const NewsMenu = () => {
	const navigate = useNavigate()
	return (
		<Menu secondary>
			{isAuth() && isAuth().role === 0 && (
				<Menu.Item>
					<NavLink className='nav-links sans-menu no-underline' to='/user'>{`${
						isAuth().name.charAt(0).toUpperCase() + isAuth().name.slice(1)
					}'s Dashboard`}</NavLink>
				</Menu.Item>
			)}

			{isAuth() && isAuth().role === 1 && (
				<Menu.Item>
					<NavLink className='nav-links sans-menu no-underline' to='/admin'>{`${
						isAuth().name.charAt(0).toUpperCase() + isAuth().name.slice(1)
					}'s Dashboard`}</NavLink>
				</Menu.Item>
			)}

			<Menu.Item>
				<NavLink className='nav-links sans-menu no-underline' to='/news'>
					News
				</NavLink>
			</Menu.Item>

			<Menu.Item
				name='logout'
				className='nav-links sans-menu no-underline'
				onClick={() => signOut(() => navigate(`/sign-in`))}
			/>

			<Menu.Menu position='right'>
				<Menu.Item>
					<Search />
				</Menu.Item>
			</Menu.Menu>
		</Menu>
	)
}

export default NewsMenu
