import {
	Container,
	Grid,
	GridColumn,
	Image,
} from 'semantic-ui-react'

function DancingWithData() {
	return (
		<Container className='container-main'>
			<h1>Dancing With Data Webinar Series</h1>
			<Grid columns={1} padded>
				<GridColumn>
					<hr />
					<div>
						<h2 className='mt-40'>Past Webinars</h2>
						<Grid columns={3} className='mt-15 center aligned'>
							<a
								href='https://youtu.be/bge3C6YLQhg'
								target='_blank'
								rel='noreferrer'
								className='mb-40'>
								<Image
									src='/images/events/dancing-with-data/matthew.png'
									size='medium'
									centered
								/>
							</a>
							<a
								href='https://youtu.be/zsfC3tWKiyA'
								target='_blank'
								rel='noreferrer'
								className='mb-40'>
								<Image
									src='/images/events/dancing-with-data/abel.png'
									size='medium'
									centered
								/>
							</a>
							<a
								href='https://youtu.be/1jDQVM_D8-U'
								target='_blank'
								rel='noreferrer'
								className='mb-40'>
								<Image
									src='/images/events/dancing-with-data/scott.png'
									size='medium'
									centered
								/>
							</a>
						</Grid>
					</div>
				</GridColumn>
			</Grid>
		</Container>
	)
}

export default DancingWithData
