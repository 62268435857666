import { useState, useEffect } from 'react'
import jwt from 'jsonwebtoken'
import { signUp } from 'actions/auth'
import { Container } from 'semantic-ui-react'

const ActivateAccount = ({ match }) => {
	const [values, setValues] = useState({
		name: '',
		token: '',
		error: '',
		loading: false,
		success: false,
		showButton: true,
	})

	const { name, token, error, loading, success, showButton } = values

	useEffect(() => {
		let token = match.params.token
		if (token) {
			const { name } = jwt.decode(token)
			setValues({ ...values, name, token })
		}
		// eslint-disable-next-line
	}, [])

	const clickSubmit = e => {
		e.preventDefault()
		setValues({ ...values, loading: true, error: false })
		signUp({ token }).then(data => {
			if (data.error) {
				setValues({
					...values,
					error: data.error,
					loading: false,
					showButton: false,
				})
			} else {
				setValues({ ...values, loading: false, success: true, showButton: false })
			}
		})
	}

	const showLoading = () => (loading ? <h2>Loading...</h2> : '')

	return (
		<Container className='container-main'>
			<h1 className='text-center'>Activate Account</h1>
			<h3 className='pb-20'>Hey {name}, Ready to activate your account?</h3>
			{showLoading()}
			{error && error}
			{success && 'You have successfully activated your account. Please sign in.'}
			{showButton && (
				<button className='btn btn-outline-primary' onClick={clickSubmit}>
					Activate Account
				</button>
			)}
		</Container>
	)
}

export default ActivateAccount
