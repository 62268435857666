import { Container, Grid, Image, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'


function NETSIND() {
    return (
        <>
            <div>
                <Container className='container-main'>
                    <h1>NETS: IND Application</h1>
			        <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>What is an IND application and why is it important in drug development?</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>
                                An investigational new drug (IND) application is an application that must be submitted to the FDA to obtain permission to begin a clinical trial. An IND application is submitted by the clinical trial sponsor and must include documentation of the pharmacology and toxicology studies demonstrating that the compound has proven safe in animal testing. It will also contain manufacturing information, clinical protocols for the proposed clinical trial, and information on the study investigators and staff. This report must be submitted to the FDA before human testing may begin.
                            </p>
                            <p>
                                In Europe, a clinical trial authorization (CTA) application must be submitted prior to beginning clinical trials. Applications are submitted to National Health Agencies in each country where trials will be conducted by drug sponsors or their representatives. Clinical trial sponsors must be established within the European Community, or have a legal representative who is. Like INDs, CTAs must include documentation of pharmacology and toxicology studies, manufacturing information, clinical protocols and information on study investigators and staff.
                            </p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Testimonials: How we did it</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>Content not yet available for this section.</p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Relevant Publications</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <div className='inline-flex mb-15'>
                                <Image src='/icons/guide.png' className='icon-width-16px mr-8' style={{ height: '16px', top: '8px' }} />
                                <p>
                                    <a
                                        href='https://www.fda.gov/Drugs/DevelopmentApprovalProcess/HowDrugsareDevelopedandApproved/ApprovalApplications/InvestigationalNewDrugINDApplication/ucm176259.htm'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        FDA: Information for Clinical Investigators on INDs
                                    </a>
                                </p>
                            </div>
                            <div className='inline-flex mb-15'>
                                <Image src='/icons/guide.png' className='icon-width-16px mr-8' style={{ height: '16px', top: '8px' }} />
                                <p>
                                    <a
                                        href='https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfcfr/CFRsearch.cfm?CFRPart=312'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        FDA: Code of Regulation for an Investigational New Drug Application
                                    </a>
                                </p>
                            </div>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Related Tools</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <a
                                href='https://www.fda.gov/drugs/developmentapprovalprocess/howdrugsaredevelopedandapproved/approvalapplications/investigationalnewdrugindapplication/default.htm'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/guide.png' className='icon-width-16px' />FDA: Overview of Investigational New Drug Applications</Button>
                            </a>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Link to='/resources-and-information/nets/full-view' rel='noopener noreferrer' className='link-button inline-block'><Button className='button-orange-500'><Image src='/icons/link-internal-white.png' className='icon-width-16px' style={{ transform: 'scaleX(-1)' }} />Back to NETS Map full view</Button></Link>
                </Container>
            </div>
        </>
    )
}

export default NETSIND