import { Container, Grid, GridColumn, Image, Icon } from 'semantic-ui-react'
import ShareSocialMedia from '../../../ShareSocialMedia'
import LinkButton from '../../../LinkButton'

function TransitionToAdulthood() {
	const shareUrl = 'https://geneticalliance.org/resources-and-information/advocacy-atlas/transition-adulthood'
	return (
		<Container className='container-main'>
			<h1>Transition to Adulthood</h1>
			<Grid columns={1} padded>
				<GridColumn>
				<hr />
					<ShareSocialMedia shareUrl={shareUrl} />
					<div>
						<p className='mt-20'>
							<i>With the help of my family and school, I have advocated for support and services to help me while in school and through the transition to living on my own. Throughout high school, my parents and I were not satisfied with the handling of my IEP, so we spoke up for what we felt I needed to be successful as an adult. I talked with my parents and school about going to college. My parents and school contacted the SAVE Program, which helped provide transition support through my IEP to go to college. I took classes and had an internship to help prepare me for jobs. I had on-the-job training with the custodial and maintenance staff all year long and worked on campus in the Lakers Café. My transition required many steps, including finding my own apartment and learning about housing and independent living support. Independent Living Choices (ILC) helps me with independent living skills like budgeting, keeping track of my checkbook, and spending. Also, through ILC I get out and spend time with people (by attending support group get-togethers) when I can. I was able to participate in the Partners In Policymaking Program, a leadership and advocacy training that empowers people with developmental disabilities and their families and learn how to advocate better for myself.
							<br /><br />
							“My Road to Transitioning” —a story by Caryn Johnson, young adult advocate</i>
						</p>
					</div>

					<h2 className='color-secondary mt-40'>Resources</h2>
					<Grid stackable columns={2} className='mt-15 left aligned'>
						<Grid.Row>
							<Grid.Column>
								<p>
									<a className='link-blue-800'
										href='https://www.secondarytransition.org/'
										rel='noreferrer'
										target='_blank'>
										Pennsylvania Secondary Transition Guide
									</a>
									- Pennsylvania Department of Education
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/transition-adulthood/Road Map.pdf'
										rel='noreferrer'
										target='_blank'>
										Secondary Transition Roadmaps
									</a>
									- Pennsylvania Department of Education
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/transition-adulthood/TransitiontoAdultHealthcare-EN-complete.pdf'
										rel='noreferrer'
										target='_blank'>
										Being a Healthy Adult: How to Advocate for Your Health and Health Care
									</a>
									- Kathy Roberson, M.S.W, The Boggs Center on Developmental Disabilities
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='https://rwjms.rutgers.edu/boggscenter/projects/documents/Cover1.pdf'
										rel='noreferrer'
										target='_blank'>
										Keeping It Real: How to Get the Supports You Need for the Life You Want
									</a>
									- The Boggs Center on Developmental Disabilities
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='https://healthytransitionsny.org/'
										rel='noreferrer'
										target='_blank'>
										Healthy Transitions: Moving from Pediatric to Adult Health Care
									</a>
									- New York State Institute for Health Transition Training for Youth with Developmental Disabilities 
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='http://www.slideshare.net/ryanwexelblatt/transition-to-adulthood-in-pennsylvania-workbook-for-parents-teachers-caregivers'
										rel='noreferrer'
										target='_blank'>
										Transition Health Care Checklist: Transition to Adult Living in Pennsylvania
									</a>
									- Pennsylvania Department of Health
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/transition-adulthood/journeytoadulthood.pdf'
										rel='noreferrer'
										target='_blank'>
										Journey to Adulthood: A transitions travel guide
									</a>
									- National Health Care Transition Center 
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='https://fliphtml5.com/bhkw/udto'
										rel='noreferrer'
										target='_blank'>
										Transition Travel Guide: Resources for Roadblocks
									</a>
									- National Health Care Transition Center
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='https://www.dol.gov/agencies/odep/categories/youth'
										rel='noreferrer'
										target='_blank'>
										Disability Employment Policy Resources by Topic
									</a>
									- Office of Disability Employment Policy 
								</p>
							</Grid.Column>
							<Grid.Column>
								<div>
									<figure>
										<Image
											src='/images/resources-and-information/advocacy-atlas/transition-adulthood/cropgraduation.jpeg'
											size='medium'
											centered
										/>
										<figcaption className='mt-20 text-center'>
											<i>Family members pose following a graduation ceremony.</i>
										</figcaption>
									</figure>
									<figure className='mt-150'>
										<Image
											src='/images/resources-and-information/advocacy-atlas/transition-adulthood/cropfriends.jpeg'
											size='medium'
											centered
										/>
										<figcaption className='mt-20 text-center'>
											<i>Two friends collaborate as part of a youth leadership organization.</i>
										</figcaption>
									</figure>
								</div>
							</Grid.Column>
						</Grid.Row>
					</Grid>
					<div>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/transition-adulthood/PYLN2ndTransitionToolkit.pdf'
								rel='noreferrer'
								target='_blank'>
								Secondary Transition Toolkit
							</a>
							- Pennsylvania Youth Leadership Network
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/transition-adulthood/PYLN Healthcare Toolkit.pdf'
								rel='noreferrer'
								target='_blank'>
								Secondary Health Care Transition Toolkit
							</a>
							- Pennsylvania Youth Leadership Network
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='https://www.youtube.com/watch?v=XFoBycw3rnQ'
								rel='noreferrer'
								target='_blank'>
								Heading for College with Special Healthcare Needs
							</a>
							- National Health Care Transition Center
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/transition-adulthood/envisioning_my_future_gray.pdf'
								rel='noreferrer'
								target='_blank'>
								Health Care Transition Guide for Young Adults
							</a>
							- Children's Medical Services (CMS) Network 
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/transition-adulthood/20_leadership_communication_dualenrollment_0.pdf'
								rel='noreferrer'
								target='_blank'>
								Establishing Inclusive Postsecondary Education Opportunities: Tips for Effective Communication
							</a>
							- Think College
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='https://thinkcollege.net/think-college-learn'
								rel='noreferrer'
								target='_blank'>
								Think College Self Paced Learning Modules
							</a>
							- Think College 
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/transition-adulthood/selfdeterminationcheckliststudentself-assessment.pdf'
								rel='noreferrer'
								target='_blank'>
								Self Determination Student Checklist
							</a>
							- Commonwealth of Virginia Department of Education Training and Technical Assistance Centers
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/transition-adulthood/Empowering-Leadership.pdf'
								rel='noreferrer'
								target='_blank'>
								Empowering Leadership A Systems Change Guide for Autistic College Students and Those with Other Disabilities
							</a>
							- Autistic Self Advocacy Network (ASAN)
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/transition-adulthood/CaregiverChecklist-RE.pdf'
								rel='noreferrer'
								target='_blank'>
								Transition Checklist for Parents/Caregivers
							</a>
							- Statewide Parent Advocacy Network (SPAN)
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/transition-adulthood/Transition_IEP.pdf'
								rel='noreferrer'
								target='_blank'>
								Integrating Health into the Transition IEP
							</a>
							- Family Voices, Diana Autin & Carolyn Hayer
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='http://supporteddecisionmaking.org/'
								rel='noreferrer'
								target='_blank'>
								Guardianship and Alternatives for Decision-Making Support
							</a>
							- National Health Care Transition Center
						</p>
					</div>
					<div className='mt-40 text-center'>
						<LinkButton className='ui button' 
						to='/resources-and-information/advocacy-atlas'
						>
							<Icon name='arrow left' /> Return to Advocacy ATLAS Homepage
						</LinkButton>
					</div>
				</GridColumn>
			</Grid>
		</Container>
	)
}

export default TransitionToAdulthood