import { Container, Grid, Image, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'


function NETSPharmacology() {
    return (
        <>
            <div>
                <Container className='container-main'>
                    <h1>NETS: Pharmacology</h1>
			        <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>What is pharmacology and why is it important in drug development?</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>
                                Pharmacology is the study of the biological effects of a drug and its interaction with living systems. After studying the <i>in vitro</i> and <i>in vivo</i> pharmacology of various lead compounds, researchers conduct additional pharmacology studies with chosen compounds for preclinical and clinical research. To learn more about pharmacology and why it is important for drug development, click on the links below.
                            </p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Testimonials: How we did it</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>Content not yet available for this section.</p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Relevant Publications</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>Content not yet available for this section.</p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Related Tools</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <a
                                href='https://www.pharmgkb.org/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />PharmGKB: The Pharmacogenomics Knowledge Base</Button>
                            </a>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Link to='/resources-and-information/nets/full-view' rel='noopener noreferrer' className='link-button inline-block'><Button className='button-orange-500'><Image src='/icons/link-internal-white.png' className='icon-width-16px' style={{ transform: 'scaleX(-1)' }} />Back to NETS Map full view</Button></Link>
                </Container>
            </div>
        </>
    )
}

export default NETSPharmacology