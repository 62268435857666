import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { progressIndicator } from '../../content/HelperFunction'
import { Container, Grid } from 'semantic-ui-react'
import ReactHtmlParser from 'react-html-parser'

function LiveWorkshopsFillContent({ data }) {

    const { pathname, hash, key } = useLocation()
    useEffect(() => {
        // if not a hash link, scroll to top
        if (hash === '') {
            window.scrollTo(0, 0)
        }
        // else scroll to id
        else {
            setTimeout(() => {
                const id = hash.replace('#', '')
                const element = document.getElementById(id)
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' })
                }
            }, 0)
        }
    }, [pathname, hash, key])

    useEffect(() => {
        progressIndicator()
    }, [])

    if (!data) {
        return null
    }
    const datas = data.filter(item => item.link === '/registries/bootcamp/live-workshops')
    if (datas && Array.isArray(datas) && datas.length > 0) {
        datas.sort((a, b) => a.index - b.index)
    } else {
        return null
    }
    return (
        <>
            <Container>
                <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
                <Grid columns={2} stackable>
                    <Grid.Column mobile={16} tablet={16} computer={8}>
                        <h3 id={datas[0].id}>{datas[0].header}</h3>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={8} className='mt-15'>
                        <p>{datas[0].content1}{' '}
                            <a
                                href='https://forms.gle/5BWiPQNTRvBmmEE28'
                                target='_blank'
                                rel='noreferrer'
                            >
                                contact us
                            </a>
                            .</p>
                        <p>
                            {datas[0].content2}
                        </p>
                        <hr className='rule-blue-300 mt-30 mb-30' />
                        {ReactHtmlParser(datas[0].content3)}
                        <hr className='rule-blue-300 mt-30 mb-30' />
                        {ReactHtmlParser(datas[0].content4)}
                        <hr className='rule-blue-300 mt-30 mb-30' />
                        {ReactHtmlParser(datas[0].content5)}
                        <hr className='rule-blue-300 mt-30 mb-30' />
                        {ReactHtmlParser(datas[0].content6)}
                    </Grid.Column>
                </Grid>
            </Container>
        </>
    )
}

export default LiveWorkshopsFillContent
