import { Container, Menu } from 'semantic-ui-react'
import Admin from '../../auth/Admin'
import { NavLink } from 'react-router-dom'
import NewsMenu from '../NewsMenu'

const AdminIndex = () => {
	return (
		<Admin>
			<Container className='container-news'>
				<NewsMenu />
				<h1>Admin Dashboard</h1>
				<Menu vertical>
					<Menu.Item>
						<NavLink
							className='nav-links sans-menu no-underline'
							to='/admin/crud/category-tag'>
							Create Category
						</NavLink>
					</Menu.Item>
					<Menu.Item>
						<NavLink
							className='nav-links sans-menu no-underline'
							to='/admin/crud/category-tag'>
							Create Tag
						</NavLink>
					</Menu.Item>
					<Menu.Item>
						<NavLink
							className='nav-links sans-menu no-underline'
							to='/admin/crud/new'>
							Create Blog
						</NavLink>
					</Menu.Item>
					<Menu.Item>
						<NavLink
							className='nav-links sans-menu no-underline'
							to='/admin/crud/news'>
							Update/Delete Blogs
						</NavLink>
					</Menu.Item>
					<Menu.Item>
						<NavLink
							className='nav-links sans-menu no-underline'
							to='/admin/irb/application'>
							IRB Applications
						</NavLink>
					</Menu.Item>
				</Menu>
			</Container>
		</Admin>
	)
}

export default AdminIndex
