import { useState } from 'react'
import { Image, Button } from 'semantic-ui-react'

export default function Carousel() {
	const [activeIndex, setActiveIndex] = useState(0)
	const carouselLength = 3
	const updateIndex = newIndex => {
		if (newIndex < 0) {
			newIndex = 0
		} else if (newIndex >= carouselLength) {
			newIndex = carouselLength - 1
		}

		setActiveIndex(newIndex)
	}
	return (
		<div className='carousel mt-120-60-40'>
			<div
				className='inner'
				style={{ transform: `translate(-${activeIndex * 100}%)` }}>
				<div className='carousel-item' style={{ width: '100%' }}>
					<img
						className='carousel-img'
						src='/carousel/S2_FORBES.png'
						alt='Forbes Aug 10, 2022'
					/>
					<div className='carousel-item-text'>
						<h4>
							<em>
								"What an amazing article! I'm sharing it all over our social media right
								now and adding it to my "evidence FOR" stack as we build our registry".
							</em>
						</h4>
						<h5>
							Leslie Edwin
							<br />
							President
							<br />
							Cushing's Support & Research Foundation
						</h5>
						<a
							href='https://www.forbes.com/sites/ashoka/2022/08/10/making-genomic-testing-available-to-all-and-safeguarding-peoples-control-of-their-health-data/?sh=6eb83d687296'
							target='_blank'
							rel='noopener noreferrer'
							className='link-button display-contents'>
							<Button className='button-neutral-100' style={{ margin: '0' }}>
								<p style={{ margin: '0 auto' }}>Interview with Sharon Terry</p>{' '}
								&nbsp;&nbsp;
								<Image
									className='link-internal-icon'
									src='/icons/link-internal-blue.png'
								/>
							</Button>
						</a>
					</div>
				</div>
				<div className='carousel-item' style={{ width: '100%' }}>
					<img
						className='carousel-img'
						src='/carousel/S3_PBS.png'
						alt='PBS News Hour'
					/>
					<div className='carousel-item-text'>
						<p className='p-compact'>
							I wanted to offer ASHG’s congratulations and thanks for your powerful
							story on the PBS NewsHour “Brief but Spectacular” series! Of course, the
							human genetics, policy and patient advocacy communities know well your
							decades-long commitment and leadership on research innovation and
							access…Thank you again!
						</p>
						<h5>
							Mona V. Miller
							<br />
							Chief Executive Officer
							<br />
							American Society of Human Genetics
						</h5>
						<p className='p-compact'>PBS Newshour</p>
						<a
							href='https://www.pbs.org/newshour/brief/417013/sharon-fontaine-terry'
							target='_blank'
							rel='noopener noreferrer'
							className='link-button display-contents'>
							<Button className='button-neutral-100' style={{ margin: '0' }}>
								<p style={{ margin: '0 auto' }}>Learn More</p> &nbsp;&nbsp;
								<Image
									className='link-internal-icon'
									src='/icons/link-internal-blue.png'
								/>
							</Button>
						</a>
					</div>
				</div>
				<div className='carousel-item' style={{ width: '100%' }}>
					<img
						className='carousel-img'
						src='/carousel/S1_ASHG.jpg'
						alt='2021 Advocacy Award'
					/>
					<div className='carousel-item-text'>
						<h3 className='mb-20'>2021 Advocacy Award</h3>
						<h4>
							<em>
								Francis Collins, MD, PhD, and Diane Baker introduce the 2021 ASHG
								Advocacy Award recipient, Sharon Terry, MA.
							</em>
						</h4>
						<a
							href='https://learning.ashg.org/2021awards'
							target='_blank'
							rel='noopener noreferrer'
							className='link-button display-contents'>
							<Button className='button-neutral-100' style={{ margin: '0' }}>
								<p style={{ margin: '0 auto' }}>Watch award presentation</p>{' '}
								&nbsp;&nbsp;
								<Image
									className='link-internal-icon'
									src='/icons/link-internal-blue.png'
								/>
							</Button>
						</a>
					</div>
				</div>
				{/* <div className='carousel-item' style={{ width: '100%' }}>
					<img className='carousel-img' src='/carousel/Ashoka.jpg' alt='ASHOKA' />
					<div className='carousel-item-text'>
						<p className='p-compact'>
							You’re not just pioneering biomedical research, but also revolutionizing
							data safety and cooperatives, showing how we can flip paradigms if we put
							people at the center, unleash their changemaking agency, and collaborate
							and share profits.
						</p>
						<p className='p-compact'>
							I’m pausing in a moment of gratitude, for you and your community.
						</p>
						<h5>
							Konstanze Frischen <br />
							Ashoka
						</h5>
					</div>
				</div> */}
			</div>

			<div className='carousel-buttons'>
				<button
					className='button-arrow'
					onClick={() => {
						updateIndex(activeIndex - 1)
					}}>
					<span className='material-symbols-outlined'>arrow_back_ios</span>{' '}
				</button>
				<div className='indicators'>
					{[0, 1, 2].map(index => {
						return (
							<button
								className='indicator-buttons'
								onClick={() => {
									updateIndex(index)
								}}>
								<span
									className={`material-symbols-outlined ${
										index === activeIndex ? 'indicator-symbol-active' : 'indicator-symbol'
									}`}>
									radio_button_checked
								</span>
							</button>
						)
					})}
				</div>
				<button
					className='button-arrow'
					onClick={() => {
						updateIndex(activeIndex + 1)
					}}>
					<span className='material-symbols-outlined'>arrow_forward_ios</span>
				</button>
			</div>
		</div>
	)
}
