import './App.css'
import { Routes, Route, Navigate } from 'react-router-dom'
import Navbar from './components/layout/navigation/Navbar'
import * as Realm from 'realm-web'
import { useEffect, useState } from 'react'
// import Header from './components/layout/Header'
import Search from './components/pages/search/Search'
import Footer from './components/layout/footer/Footer'
import Home from './components/pages/home/Home'
import About from './components/pages/about/AboutFillContent'
import ContactUs from './components/pages/about/ContactUs'
import JobsAndInternship from './components/pages/about/JobsAndInternshipFillContent'
import AnnualReports from './components/pages/about/annual-reports/AnnualReportsFillContent'
import ArchivesAndHistory from './components/pages/about/ArchivesAndHistoryFillContent'
import PublicationsArchive from 'components/pages/about/archivesandhistory/PublicationsArchive'
import GeneticAllianceThroughTheYears from './components/pages/about/archivesandhistory/GeneticAllianceThroughTheYears'
import CelebratingOurDirectors from './components/pages/about/archivesandhistory/CelebratingOurDirectors'
import AnnualReportsArchive from './components/pages/about/archivesandhistory/AnnualReportsArchive'
import EventsArchive from './components/pages/about/archivesandhistory/EventsArchive'
import NewsletterArchive from './components/pages/about/archivesandhistory/NewsletterArchive'
import News from './components/pages/about/NewsFillContent'
import SamSueAnnouncement from 'components/pages/about/news/SamSueAnnouncement'
import JuliaAnnouncement from 'components/pages/about/news/JuliaAnnouncement'
import ClinWikiMergeAnnouncement from './components/pages/about/news/ClinWikiMergeAnnouncement'
import PressReleases from './components/pages/about/PressReleasesFillContent'
import Registries from 'components/pages/registries/Registries'
import Peer from './components/pages/registries/Peer'
import DiseaseInfoSearch from './components/pages/DiseaseInfoSearchFillContent'
import PrivacyPolicy from 'components/pages/PrivacyPolicyFillContent'
import HealthDisclaimer from 'components/pages/HealthDisclaimerFillContent'
import IRBRoster from './components/pages/IRB/IRBRoster'
import Carter from './components/pages/IRB/IRBroster/Carter'
import Angrist from './components/pages/IRB/IRBroster/Angrist'
import Baker from './components/pages/IRB/IRBroster/Baker'
import Dreyfus from './components/pages/IRB/IRBroster/Dreyfus'
import FitzGerald from './components/pages/IRB/IRBroster/FitzGerald'
import PatrickLake from './components/pages/IRB/IRBroster/PatrickLake'
import PoliciesAndProcedures from './components/pages/IRB/PoliciesAndProcedures'
import BioBank from './components/pages/BioBankFillContent'
import Policy from './components/pages/policy/PolicyFillContent'
import PatientResearchProtections from './components/pages/policy/PatientResearchProtections'
import Other from './components/pages/policy/Other'
import ResourcesAndInformation from './components/pages/resources-and-information/ResourcesAndInformation'
import AdvocacyATLAS from './components/pages/resources-and-information/AdvocacyAtlas/advocacy-atlas/AdvocacyATLAS'
import AboutAdvocacyATLAS from './components/pages/resources-and-information/AdvocacyAtlas/AboutAdvocacyATLAS'
import AccessHealthcare from './components/pages/resources-and-information/AdvocacyAtlas/AccessHealthcare'
import AccessibleCommunities from './components/pages/resources-and-information/AdvocacyAtlas/AccessibleCommunities'
import AdvocacyAndLeadershipSkills from './components/pages/resources-and-information/AdvocacyAtlas/AdvocacyAndLeadershipSkills'
import CommunicatingAboutYourHealth from './components/pages/resources-and-information/AdvocacyAtlas/CommunicatingAboutYourHealth'
import EducationServicesAndSupports from './components/pages/resources-and-information/AdvocacyAtlas/EducationServicesAndSupports'
import InsuranceAndFinancialAssistance from './components/pages/resources-and-information/AdvocacyAtlas/InsuranceAndFinancialAssistance'
import LegislationAndPoliticalAction from './components/pages/resources-and-information/AdvocacyAtlas/LegislationAndPoliticalAction'
import StepsToEmploymentSuccess from './components/pages/resources-and-information/AdvocacyAtlas/StepsToEmploymentSuccess'
import TransitionToAdulthood from './components/pages/resources-and-information/AdvocacyAtlas/TransitionToAdulthood'
import YouthLeadership from './components/pages/resources-and-information/AdvocacyAtlas/YouthLeadership'
// import WikiAdvocacy from './components/pages/resources-and-information/WikiAdvocacy'
import Nets from './components/pages/resources-and-information/NetsFillContent'
import Publications from './components/pages/resources-and-information/Publications'
// import Newsletters from './components/pages/resources-and-information/Newsletters'
import ScrollToTop from 'components/ScrollToTop'
import OneStaff from 'components/content/OneStaff'
// import SharonTerry from 'components/pages/about/staff/SharonTerry'
// import NatashaBonhomme from 'components/pages/about/staff/NatashaBonhomme'
// import RuthChild from 'components/pages/about/staff/RuthChild'
// import SusanMcClure from 'components/pages/about/staff/SusanMcClure'
import SamSolomon from 'components/pages/about/staff/SamSolomon'
import KatherineLambertson from 'components/pages/about/staff/KatherineLambertson'
// import AlysonKrokosky from 'components/pages/about/staff/AlysonKrokosky'
import MatthewCaffet from 'components/pages/about/staff/MatthewCaffet'
// import MaryPeckiconis from 'components/pages/about/staff/MaryPeckiconis'
// import VilmaWhittier from 'components/pages/about/staff/VilmaWhittier'
// import JuliaOrtega from 'components/pages/about/staff/JuliaOrtega'
// import NoushinVaccani from 'components/pages/about/staff/NoushinVaccani'
// import JessicaGreen from 'components/pages/about/staff/JessicaGreen'
// import GeorgiaBoley from 'components/pages/about/staff/GeorgiaBoley'
// import KempBattle from 'components/pages/about/council/KempBattle'
// import ShantanuGaur from 'components/pages/about/council/ShantanuGaur'
// import KellyEdwards from 'components/pages/about/council/KellyEdwards'
// import UsamaMalik from 'components/pages/about/council/UsamaMalik'
// import RyanTaft from 'components/pages/about/council/RyanTaft'
// import JuliaOrtega from 'components/pages/about/council/JuliaOrtega'
// import VaniRajan from 'components/pages/about/staff/VaniRajan'
import Events from 'components/pages/events/Events'
import WebinarArchives from 'components/pages/events/WebinarArchivesFillContent'
import Webinars from 'components/pages/events/WebinarsFillContent'
// import Bootcamp from 'components/pages/registries/Bootcamp'
import Internships from 'components/pages/Internships'
import InternshipApplication from 'components/pages/InternshipApplication'
import DancingWithData from 'components/pages/events/DancingWithData'
import LessonsFromTheField from 'components/pages/events/LessonsFromTheField'
import EndingTheDiagnosticOdyssey from 'components/pages/policy/EndingTheDiagnosticOdyssey'
import MailingLists from 'components/pages/MailingLists'
import MandateForQualityGeneticServices from 'components/pages/about/MandateForQualityGeneticServices'
import GeneticAllianceCoreValues from 'components/pages/about/GeneticAllianceCoreValues'
import Manifesto from 'components/pages/about/Manifesto'
import Mission from 'components/pages/about/Mission'
import BlackHistoryMonth from 'components/pages/BlackHistoryMonth'
import OurServices from 'components/pages/home/OurServicesFillContent'
import GAInstitutionalReviewBoard from 'components/pages/IRB/GAInstitutionalReviewBoardFillContent'
import GARegistry from 'components/pages/registries/GARegistryFillContent'
import NETSClinicalTrials from 'components/pages/resources-and-information/NETS/NETSClinicalTrials'
import NETSStudySponsor from 'components/pages/resources-and-information/NETS/NETSStudySponsor'
import NETSRecruitment from 'components/pages/resources-and-information/NETS/NETSRecruitment'
import NETSStudyStaff from 'components/pages/resources-and-information/NETS/NETSStudyStaff'
import NETSIRB from 'components/pages/resources-and-information/NETS/NETSIRB'
import NETSAgreements from 'components/pages/resources-and-information/NETS/NETSAgreements'
import NETSConsent from 'components/pages/resources-and-information/NETS/NETSConsent'
import NETSBioinformatics from 'components/pages/resources-and-information/NETS/NETSBioinformatics'
import NETSHypothesis from 'components/pages/resources-and-information/NETS/NETSHypothesis'
import NETSPathophysiology from 'components/pages/resources-and-information/NETS/NETSPathophysiology'
import NETSTargets from 'components/pages/resources-and-information/NETS/NETSTargets'
import NETSRepositories from 'components/pages/resources-and-information/NETS/NETSRepositories'
import NETSBiorepositories from 'components/pages/resources-and-information/NETS/NETSBiorepositories'
import NETSBiospecimens from 'components/pages/resources-and-information/NETS/NETSBiospecimens'
import NETSCellLines from 'components/pages/resources-and-information/NETS/NETSCellLines'
import NETSAnimalModels from 'components/pages/resources-and-information/NETS/NETSAnimalModels'
import NETSGenotypes from 'components/pages/resources-and-information/NETS/NETSGenotypes'
import NETSPhenotypes from 'components/pages/resources-and-information/NETS/NETSPhenotypes'
import NETSDevelopment from 'components/pages/resources-and-information/NETS/NETSDevelopment'
import NETSHTS from 'components/pages/resources-and-information/NETS/NETSHTS'
import NETSSystem from 'components/pages/resources-and-information/NETS/NETSSystem'
import NETSPathway from 'components/pages/resources-and-information/NETS/NETSPathway'
import NETSLibraries from 'components/pages/resources-and-information/NETS/NETSLibraries'
import NETSMisses from 'components/pages/resources-and-information/NETS/NETSMisses'
import NETSHits from 'components/pages/resources-and-information/NETS/NETSHits'
import NETSUnapproved from 'components/pages/resources-and-information/NETS/NETSUnapproved'
import NETSApproved from 'components/pages/resources-and-information/NETS/NETSApproved'
import NETSLead from 'components/pages/resources-and-information/NETS/NETSLead'
import NETSManufacture from 'components/pages/resources-and-information/NETS/NETSManufacture'
import NETSMedicinal from 'components/pages/resources-and-information/NETS/NETSMedicinal'
import NETSNonGLP from 'components/pages/resources-and-information/NETS/NETSNonGLP'
import NETSInVitro from 'components/pages/resources-and-information/NETS/NETSInVitro'
import NETSAcute from 'components/pages/resources-and-information/NETS/NETSAcute'
import NETSSafety from 'components/pages/resources-and-information/NETS/NETSSafety'
import NETSDose from 'components/pages/resources-and-information/NETS/NETSDose'
import NETSEfficacy from 'components/pages/resources-and-information/NETS/NETSEfficacy'
import NETSCompound from 'components/pages/resources-and-information/NETS/NETSCompound'
import NETSCGMP from 'components/pages/resources-and-information/NETS/NETSCGMP'
import NETSGLP from 'components/pages/resources-and-information/NETS/NETSGLP'
import NETSPK from 'components/pages/resources-and-information/NETS/NETSPK'
import NETSPharmacology from 'components/pages/resources-and-information/NETS/NETSPharmacology'
import NETSToxicology from 'components/pages/resources-and-information/NETS/NETSToxicology'
import NETSProduct from 'components/pages/resources-and-information/NETS/NETSProduct'
import NETSResearcher from 'components/pages/resources-and-information/NETS/NETSResearcher'
import NETSRegistries from 'components/pages/resources-and-information/NETS/NETSRegistries'
import NETSCohorts from 'components/pages/resources-and-information/NETS/NETSCohorts'
import NETSStudies from 'components/pages/resources-and-information/NETS/NETSStudies'
import NETSOutcomes from 'components/pages/resources-and-information/NETS/NETSOutcomes'
import NETSEnrollment from 'components/pages/resources-and-information/NETS/NETSEnrollment'
import NETSEndpoint from 'components/pages/resources-and-information/NETS/NETSEndpoint'
import NETSUpdate from 'components/pages/resources-and-information/NETS/NETSUpdate'
import NETSSubpopulations from 'components/pages/resources-and-information/NETS/NETSSubpopulations'
import NETSResponders from 'components/pages/resources-and-information/NETS/NETSResponders'
import NETSPhaseI from 'components/pages/resources-and-information/NETS/NETSPhaseI'
import NETSPhaseII from 'components/pages/resources-and-information/NETS/NETSPhaseII'
import NETSPhaseIII from 'components/pages/resources-and-information/NETS/NETSPhaseIII'
import NETSReevaluate from 'components/pages/resources-and-information/NETS/NETSReevaluate'
import NETSCare from 'components/pages/resources-and-information/NETS/NETSCare'
import NETSIND from 'components/pages/resources-and-information/NETS/NETSIND'
import NETSHealthAuthorities from 'components/pages/resources-and-information/NETS/NETSHealthAuthorities'
import NETSNDA from 'components/pages/resources-and-information/NETS/NETSNDA'
import NETSIndication from 'components/pages/resources-and-information/NETS/NETSIndication'
import NETSRejected from 'components/pages/resources-and-information/NETS/NETSRejected'
import NETSTherapy from 'components/pages/resources-and-information/NETS/NETSTherapy'
import NETSPhaseIV from 'components/pages/resources-and-information/NETS/NETSPhaseIV'
import NETSCoverage from 'components/pages/resources-and-information/NETS/NETSCoverage'
import NETSClinicalPractice from 'components/pages/resources-and-information/NETS/NETSClinicalPractice'
import NETSFullView from 'components/pages/resources-and-information/NETS/NETSFullView'
import NETSNeighborhoodView from 'components/pages/resources-and-information/NETS/NETSNeighborhoodView'
import NETSAccess from 'components/pages/resources-and-information/NETS/NETSAccess'
import NETSPreparation from 'components/pages/resources-and-information/NETS/NETSPreparation'
import NETSNetsDesign from 'components/pages/resources-and-information/NETS/NETSNetsDesign'
import SignUp from 'components/pages/auth/SignUp'
import SignIn from 'components/pages/auth/SignIn'
import IRBApplication from 'components/pages/IRB/IRBApplication/IRBApplication'
import UserIndex from 'components/pages/user/UserIndex'
import AdminIndex from 'components/pages/user/admin/AdminIndex'
// import CustomSwitch from 'components/CustomSwitch'
import CategoryTag from 'components/pages/user/admin/CategoryTag'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Blog from 'components/pages/user/admin/Blog'
import Blogs from 'components/pages/blogs/Blogs'
import SingleBlog from 'components/pages/blogs/SingleBlog'
import CrudBlogs from 'components/pages/user/admin/Blogs'
import EditBlog from 'components/pages/user/admin/crud/EditBlog'
import SingleCategory from 'components/pages/categories/SingleCategory'
import SingleTag from 'components/pages/tags/SingleTag'
import UserProfile from 'components/pages/UserProfile'
import ForgotPassword from 'components/pages/auth/ForgotPassword'
import IRBApplicationDashboard from 'components/pages/user/admin/IRBApplicationDashboard'
import ActivateAccount from 'components/pages/auth/ActivateAccount'
import ResetPassword from 'components/pages/auth/ResetPassword'
import RegistryBootcamp from 'components/pages/registries/RegistryBootcampFillContent'
// import MPS from 'components/pages/MPS'
import TherapyDevelopment from 'components/pages/events/TherapyDevelopmentFillContent'
import BurdenOfDisease from 'components/pages/events/BurdenOfDiseaseFillContent'
import CrossConditionBurdenOfDiseaseStudy from 'components/pages/resources-and-information/studies/CrossConditionBurdenOfDiseaseStudy'
import GeneticAllianceStudies from 'components/pages/resources-and-information/studies/GeneticAllianceStudies'
import IHopeGeneticHealth from 'components/pages/IHopeGeneticHealthFillContentNew'

function App() {
	const [data, setData] = useState([])
	useEffect(() => {
		const getData = async () => {
			const app = new Realm.App({ id: 'application-0-nxrko' })
			const credentials = Realm.Credentials.anonymous()
			try {
				const data = await app.logIn(credentials)
				const cmsData = await data.functions.getGaCms()
				console.log('cmsData', cmsData)
				setData(cmsData)
			} catch (err) {
				console.error('Failed to log in', err)
			}
		}
		getData()
	}, [])

	return (
		<>
			<ScrollToTop />
			<Navbar />
			{/* <CustomSwitch> */}
			<Routes>
				{/* <Header /> */}
				<Route path='/' element={<Home />} />
				<Route path='/auth/password/forgot' element={<ForgotPassword />} />
				<Route path='/auth/password/reset/:token' element={<ResetPassword />} />
				<Route path='/auth/account/activate/:token' element={<ActivateAccount />} />
				<Route path='/sign-up' element={<SignUp />} />
				<Route path='/sign-in' element={<SignIn />} />
				<Route path='/user' element={<UserIndex />} />
				<Route path='/profile/:slug' element={<UserProfile />} />
				<Route path='/categories/:slug' element={<SingleCategory />} />
				<Route path='/tags/:slug' element={<SingleTag />} />
				<Route path='/news/:slug' element={<SingleBlog />} />
				<Route path='/news' element={<Blogs />} />
				<Route
					path='/admin/irb/application'
					element={<IRBApplicationDashboard />}
				/>
				<Route path='/admin/crud/news' element={<CrudBlogs />} />
				<Route path='/admin/crud/new' element={<Blog />} />
				<Route path='/admin/crud/category-tag' element={<CategoryTag />} />
				<Route path='/admin/crud/:slug' element={<EditBlog />} />
				<Route path='/admin' element={<AdminIndex />} />
				<Route path='/mailing-lists' element={<MailingLists />} />
				<Route path='/our-services' element={<OurServices data={data} />} />
				<Route path='/our-services/engagement-services' element={<OurServices data={data} />} />
				<Route
					path='/our-services/training-and-education'
					element={<OurServices data={data} />}
				/>
				<Route path='/our-services/research-services' element={<OurServices data={data} />} />
				<Route path='/about/staff/sharon-terry' element={<OneStaff data={data} isStaff={true} linkURL='sharon-terry' />} />
				<Route path='/about/staff/natasha-bonhomme' element={<OneStaff data={data} isStaff={true} linkURL='natasha-bonhomme' />} />
				<Route path='/about/staff/ruth-child' element={<OneStaff data={data} isStaff={true} linkURL='ruth-child' />} />
				<Route path='/about/staff/susan-mcclure' element={<OneStaff data={data} isStaff={true} linkURL='susan-mcclure' />} />
				<Route path='/about/staff/sam-solomon' element={<SamSolomon />} />
				<Route path='/about/staff/katherine-lambertson' element={<KatherineLambertson />} />
				<Route path='/about/staff/alyson-krokosky' element={<OneStaff data={data} isStaff={true} linkURL='alyson-krokosky' />} />
				<Route path='/about/staff/matthew-caffet' element={<MatthewCaffet />} />
				<Route path='/about/staff/mary-peckiconis' element={<OneStaff data={data} isStaff={true} linkURL='mary-peckiconis' />} />
				<Route path='/about/staff/vilma-whittier' element={<OneStaff data={data} isStaff={true} linkURL='vilma-whittier' />} />
				{/* <Route path='/about/staff/julia-ortega' element={<JuliaOrtega} /> /> */}
				<Route path='/about/staff/noushin-vaccani' element={<OneStaff data={data} isStaff={true} linkURL='noushin-vaccani' />} />
				{/* <Route path='/about/staff/jessica-green' element={<JessicaGreen} /> /> */}
				<Route path='/about/staff/georgia-boley' element={<OneStaff data={data} isStaff={true} linkURL='georgia-boley' />} />
				{/* <Route path='/about/staff/vani-rajan' element={<VaniRajan} /> /> */}
				<Route path='/about/contact-us' element={<ContactUs />} />
				<Route path='/about/council/sharon-terry' element={<OneStaff data={data} linkURL='sharon-terry' />} />
				<Route path='/about/council/kemp-battle' element={<OneStaff data={data} linkURL='kemp-battle' />} />
				{/* <Route path='/about/council/shantanu-gaur' element={<ShantanuGaur} /> /> */}
				<Route path='/about/council/kelly-edwards' element={<OneStaff data={data} linkURL='kelly-edwards' />} />
				<Route path='/about/council/usama-malik' element={<OneStaff data={data} linkURL='usama-malik' />} />
				<Route path='/about/council/ryan-taft' element={<OneStaff data={data} linkURL='ryan-taft' />} />
				<Route path='/about/council/julia-ortega' element={<OneStaff data={data} linkURL='julia-ortega' />} />
				<Route path='/about/jobs-and-internships' element={<JobsAndInternship data={data} />} />
				<Route
					path='/about/internships/internship-application'
					element={<InternshipApplication />}
				/>
				<Route path='/about/internships' element={<Internships />} />
				<Route path='/about/annual-reports' element={<AnnualReports data={data} />} />
				<Route
					path='/about/archives-and-history/genetic-alliance-through-the-years'
					element={<GeneticAllianceThroughTheYears />}
				/>
				<Route
					path='/about/archives-and-history/celebrating-our-directors'
					element={<CelebratingOurDirectors />}
				/>
				<Route
					path='/about/archives-and-history/annual-reports'
					element={<AnnualReportsArchive />}
				/>
				<Route
					path='/about/archives-and-history/events-archive'
					element={<EventsArchive />}
				/>
				<Route
					path='/about/archives-and-history/newsletter-archive'
					element={<NewsletterArchive />}
				/>
				<Route
					path='/about/archives-and-history/publications-archive'
					element={<PublicationsArchive />}
				/>
				<Route
					path='/about/archives-and-history'
					element={<ArchivesAndHistory data={data} />}
				/>
				<Route
					path='/about/news/welcome-susan-mcclure-and-sam-solomon-to-genetic-alliance'
					element={<SamSueAnnouncement />}
				/>
				<Route
					path='/about/news/welcome-julia-ortega-to-genetic-alliance'
					element={<JuliaAnnouncement />}
				/>
				<Route
					path='/about/news/genetic-alliance-clinwiki-merge'
					element={<ClinWikiMergeAnnouncement />}
				/>
				<Route path='/about/news' element={<News data={data} />} />
				<Route path='/about/press-releases' element={<PressReleases data={data} />} />
				<Route
					path='/about/mandate-for-quality-genetic-services'
					element={<MandateForQualityGeneticServices />}
				/>
				<Route path='/about/values' element={<GeneticAllianceCoreValues />} />
				<Route path='/about/manifesto' element={<Manifesto />} />
				<Route path='/about/mission' element={<Mission />} />
				<Route
					path='/about/mailinglist'
					element={<Navigate to='/mailing-lists' />}
				/>
				<Route path='/about' element={<About data={data} />} />
				<Route
					path='/events/webinars/dancing-with-data'
					element={<DancingWithData />}
				/>
				<Route
					path='/events/webinars/lessons-from-the-field'
					element={<LessonsFromTheField />}
				/>
				<Route
					path='/events/webinars/therapy-development'
					element={<TherapyDevelopment data={data} />}
				/>
				<Route
					path='/events/webinars/burden-of-disease'
					element={<BurdenOfDisease data={data} />}
				/>
				<Route path='/events/webinars' element={<Webinars data={data} />} />
				<Route path='/events/webinar-archives' element={<WebinarArchives data={data} />} />
				<Route path='/events' element={<Events />} />
				<Route path='/peer' element={<Navigate to='/registries/ga-registry' />} />
				<Route
					path='/promise-for-engaging-everyone-responsibly'
					element={<Navigate to='/registries/ga-registry' />}
				/>
				<Route
					path='/registries/promise-for-engaging-everyone-responsibly'
					element={<Navigate to='/registries/ga-registry' />}
				/>
				<Route
					path='/registries/promise-for-engaging-everyone-responsibly'
					element={<Peer />}
				/>
				<Route path='/registries/bootcamp' element={<RegistryBootcamp data={data} />} />
				<Route
					path='/registries/bootcamp/online-curriculum'
					element={<RegistryBootcamp data={data} />}
				/>
				<Route
					path='/registries/bootcamp/live-workshops'
					element={<RegistryBootcamp data={data} />}
				/>
				<Route
					path='/registries/bootcamp/registry-services'
					element={<RegistryBootcamp data={data} />}
				/>
				<Route
					path='/registry-bootcamp'
					element={<Navigate to='/registries/bootcamp' />}
				/>
				<Route path='/bootcamp' element={<Navigate to='/registries/bootcamp' />} />
				<Route path='/registries/ga-registry' element={<GARegistry data={data} />} />
				<Route path='/registries' element={<Registries />} />
				<Route path='/privacy-policy' element={<PrivacyPolicy data={data} />} />
				<Route path='/health-disclaimer' element={<HealthDisclaimer data={data} />} />
				<Route
					path='/studies/cross-condition-burden-of-disease/individual-participation'
					element={<CrossConditionBurdenOfDiseaseStudy />}
				/>
				<Route
					path='/studies/cross-condition-burden-of-disease/community-participation'
					element={<CrossConditionBurdenOfDiseaseStudy />}
				/>
				<Route
					path='/studies/cross-condition-burden-of-disease'
					element={<CrossConditionBurdenOfDiseaseStudy />}
				/>
				{/* <Route path='/studies/overview-sample' element={<GeneticAllianceStudiesOverview} /> /> */}
				<Route path='/studies' element={<GeneticAllianceStudies />} />
				<Route path='/ihope-genetic-health' element={<IHopeGeneticHealth data={data} />} />
				<Route
					path='/ihope-genetic-health/overview'
					element={<IHopeGeneticHealth data={data} />}
				/>
				<Route
					path='/ihope-genetic-health/families'
					element={<IHopeGeneticHealth data={data} />}
				/>
				<Route
					path='/ihope-genetic-health/clinicians'
					element={<IHopeGeneticHealth data={data} />}
				/>
				<Route path='/ihope-genetic-health/labs' element={<IHopeGeneticHealth data={data} />} />
				<Route
					path='/ihope-genetic-health/funders'
					element={<IHopeGeneticHealth data={data} />}
				/>
				<Route
					path='/ihope-genetic-health/resources'
					element={<IHopeGeneticHealth data={data} />}
				/>
				<Route path='/disease-infosearch' element={<DiseaseInfoSearch data={data} />} />
				<Route path='/irb/irb-roster/patrick-lake' element={<PatrickLake />} />
				<Route path='/irb/irb-roster/fitzgerald' element={<FitzGerald />} />
				<Route path='/irb/irb-roster/dreyfus' element={<Dreyfus />} />
				<Route path='/irb/irb-roster/baker' element={<Baker />} />
				<Route path='/irb/irb-roster/angrist' element={<Angrist />} />
				<Route path='/irb/irb-roster/carter' element={<Carter />} />
				<Route path='/irb/irb-roster' element={<IRBRoster />} />
				<Route
					path='/irb/policies-and-procedures'
					element={<PoliciesAndProcedures />}
				/>
				<Route path='/irb/application/:id' element={<IRBApplication />} />
				<Route path='/irb/application' element={<IRBApplication />} />
				<Route path='/irb' element={<GAInstitutionalReviewBoard data={data} />} />
				<Route path='/irb/overview' element={<GAInstitutionalReviewBoard data={data} />} />
				<Route path='/irb/irb-members' element={<GAInstitutionalReviewBoard data={data} />} />
				<Route
					path='/irb/application-process'
					element={<GAInstitutionalReviewBoard data={data} />}
				/>
				<Route path='/biobank' element={<BioBank data={data} />} />
				<Route
					path='/policy/ending-the-diagnostic-odyssey'
					element={<EndingTheDiagnosticOdyssey />}
				/>
				<Route
					path='/policy/patient-research-protections'
					element={<PatientResearchProtections />}
				/>
				<Route path='/policy/other' element={<Other />} />
				<Route path='/policy' element={<Policy data={data} />} />
				<Route
					path='/resources-and-information/advocacy-atlas/advocacy-leadership-skills'
					element={<AdvocacyAndLeadershipSkills />}
				/>
				<Route
					path='/resources-and-information/advocacy-atlas/communicating-health'
					element={<CommunicatingAboutYourHealth />}
				/>
				<Route
					path='/resources-and-information/advocacy-atlas/education-services'
					element={<EducationServicesAndSupports />}
				/>
				<Route
					path='/resources-and-information/advocacy-atlas/financial-assistance'
					element={<InsuranceAndFinancialAssistance />}
				/>
				<Route
					path='/resources-and-information/advocacy-atlas/legislation-action'
					element={<LegislationAndPoliticalAction />}
				/>
				<Route
					path='/resources-and-information/advocacy-atlas/employment-success'
					element={<StepsToEmploymentSuccess />}
				/>
				<Route
					path='/resources-and-information/advocacy-atlas/transition-adulthood'
					element={<TransitionToAdulthood />}
				/>
				<Route
					path='/resources-and-information/advocacy-atlas/youth-leadership'
					element={<YouthLeadership />}
				/>
				<Route
					path='/resources-and-information/advocacy-atlas/accessible-communities'
					element={<AccessibleCommunities />}
				/>
				<Route
					path='/resources-and-information/advocacy-atlas/access-healthcare'
					element={<AccessHealthcare />}
				/>
				<Route
					path='/resources-and-information/advocacy-atlas/about'
					element={<AboutAdvocacyATLAS />}
				/>
				<Route
					path='/resources-and-information/advocacy-atlas'
					element={<AdvocacyATLAS />}
				/>
				{/* <Route
					path='/wikiadvocacy'
					element={() => {
						window.location.replace('https://wikiadvocacy.org/Main_Page')
					}}
				/>
				<Route
					path='/expecting-health'
					element={() => {
						window.location.replace('https://expectinghealth.org/')
					}}
				/> */}
				{/*<Route path='/resources-and-information/wikiadvocacy' element={<WikiAdvocacy} /> /> */}
				<Route
					path='/resources-and-information/nets/recruitment'
					element={<NETSRecruitment />}
				/>
				<Route
					path='/resources-and-information/nets/study-staff'
					element={<NETSStudyStaff />}
				/>
				<Route path='/resources-and-information/nets/irb' element={<NETSIRB />} />
				<Route
					path='/resources-and-information/nets/agreements'
					element={<NETSAgreements />}
				/>
				<Route
					path='/resources-and-information/nets/consent'
					element={<NETSConsent />}
				/>
				<Route
					path='/resources-and-information/nets/sponsor'
					element={<NETSStudySponsor />}
				/>
				<Route
					path='/resources-and-information/nets/bioinformatics'
					element={<NETSBioinformatics />}
				/>
				<Route
					path='/resources-and-information/nets/hypothesis'
					element={<NETSHypothesis />}
				/>
				<Route
					path='/resources-and-information/nets/pathophysiology'
					element={<NETSPathophysiology />}
				/>
				<Route
					path='/resources-and-information/nets/targets'
					element={<NETSTargets />}
				/>
				<Route
					path='/resources-and-information/nets/clinical-trials'
					element={<NETSClinicalTrials />}
				/>
				<Route
					path='/resources-and-information/nets/repositories'
					element={<NETSRepositories />}
				/>
				<Route
					path='/resources-and-information/nets/biorepositories'
					element={<NETSBiorepositories />}
				/>
				<Route
					path='/resources-and-information/nets/biospecimens'
					element={<NETSBiospecimens />}
				/>
				<Route
					path='/resources-and-information/nets/cell-lines'
					element={<NETSCellLines />}
				/>
				<Route
					path='/resources-and-information/nets/animal-models'
					element={<NETSAnimalModels />}
				/>
				<Route
					path='/resources-and-information/nets/genotypes'
					element={<NETSGenotypes />}
				/>
				<Route
					path='/resources-and-information/nets/phenotypes'
					element={<NETSPhenotypes />}
				/>
				<Route
					path='/resources-and-information/nets/development'
					element={<NETSDevelopment />}
				/>
				<Route path='/resources-and-information/nets/hts' element={<NETSHTS />} />
				<Route
					path='/resources-and-information/nets/system'
					element={<NETSSystem />}
				/>
				<Route
					path='/resources-and-information/nets/pathway'
					element={<NETSPathway />}
				/>
				<Route
					path='/resources-and-information/nets/libraries'
					element={<NETSLibraries />}
				/>
				<Route
					path='/resources-and-information/nets/misses'
					element={<NETSMisses />}
				/>
				<Route path='/resources-and-information/nets/hits' element={<NETSHits />} />
				<Route
					path='/resources-and-information/nets/unapproved'
					element={<NETSUnapproved />}
				/>
				<Route
					path='/resources-and-information/nets/approved'
					element={<NETSApproved />}
				/>
				<Route path='/resources-and-information/nets/lead' element={<NETSLead />} />
				<Route
					path='/resources-and-information/nets/manufacture'
					element={<NETSManufacture />}
				/>
				<Route
					path='/resources-and-information/nets/medicinal'
					element={<NETSMedicinal />}
				/>
				<Route
					path='/resources-and-information/nets/non-glp'
					element={<NETSNonGLP />}
				/>
				<Route
					path='/resources-and-information/nets/in-vitro'
					element={<NETSInVitro />}
				/>
				<Route
					path='/resources-and-information/nets/acute'
					element={<NETSAcute />}
				/>
				<Route
					path='/resources-and-information/nets/safety'
					element={<NETSSafety />}
				/>
				<Route path='/resources-and-information/nets/dose' element={<NETSDose />} />
				<Route
					path='/resources-and-information/nets/efficacy'
					element={<NETSEfficacy />}
				/>
				<Route
					path='/resources-and-information/nets/compound'
					element={<NETSCompound />}
				/>
				<Route path='/resources-and-information/nets/cgmp' element={<NETSCGMP />} />
				<Route path='/resources-and-information/nets/glp' element={<NETSGLP />} />
				<Route path='/resources-and-information/nets/pk' element={<NETSPK />} />
				<Route
					path='/resources-and-information/nets/pharmacology'
					element={<NETSPharmacology />}
				/>
				<Route
					path='/resources-and-information/nets/toxicology'
					element={<NETSToxicology />}
				/>
				<Route
					path='/resources-and-information/nets/product'
					element={<NETSProduct />}
				/>
				<Route
					path='/resources-and-information/nets/researcher'
					element={<NETSResearcher />}
				/>
				<Route
					path='/resources-and-information/nets/registries'
					element={<NETSRegistries />}
				/>
				<Route
					path='/resources-and-information/nets/cohorts'
					element={<NETSCohorts />}
				/>
				<Route
					path='/resources-and-information/nets/studies'
					element={<NETSStudies />}
				/>
				<Route
					path='/resources-and-information/nets/outcomes'
					element={<NETSOutcomes />}
				/>
				<Route
					path='/resources-and-information/nets/enrollment'
					element={<NETSEnrollment />}
				/>
				<Route
					path='/resources-and-information/nets/endpoint'
					element={<NETSEndpoint />}
				/>
				<Route
					path='/resources-and-information/nets/update'
					element={<NETSUpdate />}
				/>
				<Route
					path='/resources-and-information/nets/subpopulations'
					element={<NETSSubpopulations />}
				/>
				<Route
					path='/resources-and-information/nets/responders'
					element={<NETSResponders />}
				/>
				<Route
					path='/resources-and-information/nets/phase-i'
					element={<NETSPhaseI />}
				/>
				<Route
					path='/resources-and-information/nets/phase-ii'
					element={<NETSPhaseII />}
				/>
				<Route
					path='/resources-and-information/nets/phase-iii'
					element={<NETSPhaseIII />}
				/>
				<Route
					path='/resources-and-information/nets/re-evaluate'
					element={<NETSReevaluate />}
				/>
				<Route path='/resources-and-information/nets/care' element={<NETSCare />} />
				<Route path='/resources-and-information/nets/ind' element={<NETSIND />} />
				<Route
					path='/resources-and-information/nets/health-authorities'
					element={<NETSHealthAuthorities />}
				/>
				<Route path='/resources-and-information/nets/nda' element={<NETSNDA />} />
				<Route
					path='/resources-and-information/nets/indication'
					element={<NETSIndication />}
				/>
				<Route
					path='/resources-and-information/nets/rejected'
					element={<NETSRejected />}
				/>
				<Route
					path='/resources-and-information/nets/therapy'
					element={<NETSTherapy />}
				/>
				<Route
					path='/resources-and-information/nets/phase-iv'
					element={<NETSPhaseIV />}
				/>
				<Route
					path='/resources-and-information/nets/coverage'
					element={<NETSCoverage />}
				/>
				<Route
					path='/resources-and-information/nets/clinical-practice'
					element={<NETSClinicalPractice />}
				/>
				<Route
					path='/resources-and-information/nets/full-view'
					element={<NETSFullView />}
				/>
				<Route
					path='/resources-and-information/nets/neighborhood-view'
					element={<NETSNeighborhoodView />}
				/>
				<Route
					path='/resources-and-information/nets/access'
					element={<NETSAccess />}
				/>
				<Route
					path='/resources-and-information/nets/preparation'
					element={<NETSPreparation />}
				/>
				<Route
					path='/resources-and-information/nets/nets-design'
					element={<NETSNetsDesign />}
				/>
				<Route path='/resources-and-information/nets' element={<Nets data={data} />} />
				<Route
					path='/resources-and-information/publications'
					element={<Publications />}
				/>
				{/* <Route path='/resources-and-information/newsletters' element={<Newsletters} /> /> */}
				<Route
					path='/resources-and-information'
					element={<ResourcesAndInformation />}
				/>
				<Route path='/black-history-month' element={<BlackHistoryMonth />} />
				<Route path='/search' element={<Search />} />
				{/* <Route path='/mps' element={<MPS />} /> */}
				<Route path='*' element={<Navigate to='/' />} />
			</Routes>
			{/* </CustomSwitch> */}
			<Footer />
			<ToastContainer />
		</>
	)
}

export default App
