import { Container, Grid, Image } from 'semantic-ui-react'
import ShareSocialMedia from '../../../ShareSocialMedia'
import { Link } from 'react-router-dom'

function KatherineLambertson() {
	const shareUrl = 'http://www.geneticalliance.org/about/staff/katherine-lambertson'
	return (
		<Container className='mb-80-50-40 container-main'>
			<h1>Katherine Lambertson</h1>
			<hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
			<Grid>
				<Grid.Column className='mb-5-mobile' mobile={16} tablet={7} computer={4}>
					<Image src='/staff/Lambertson.jpg' className='staff-icon-size staff-bio' />
				</Grid.Column>
				<Grid.Column mobile={16} tablet={8} computer={4}>
					<p className='p-compact'>Director of People Centered Research</p>
					<ShareSocialMedia shareUrl={shareUrl} />
				</Grid.Column>
				<Grid.Column mobile={16} tablet={16} computer={8}>
					<p>
						Katherine Lambertson is the Director of People-Centered Research at Genetic Alliance, where she works to support communities in doing the research that they and their members care about. She leads Genetic Alliance’s registry training program,{' '} 
						<Link
							to='/registries/bootcamp'
							rel='noopener noreferrer'
						>
						Registry Bootcamp
						</Link>
						, and also works one-on-one with communities to convene stakeholders, develop research protocols, and build valuable datasets.
					</p>
					<p>
						Katherine cares deeply about the role that we as people play in the systems that govern our lives, beginning with the systems that support our health. She is committed to developing tools and methodologies that empower people and communities to take ownership of their health and wellbeing. She is also passionate about the connection between our capacity to serve our communities and our access to support – not only programmatic support, but support for ourselves as whole beings. In addition to her community-led research work, Katherine works with teams and communities to cultivate practices for accessing deeper awareness, increased connection, and greater vitality in their work and in their lives.
					</p>
					<p>
						Katherine has had many roles at Genetic Alliance over the years, after she first joined the team as an intern in 2013. She has worked with many communities and initiatives to develop and launch registries and data sharing campaigns. She has supported Genetic Alliance’s efforts to connect disease advocacy organizations and researchers within the complex world of drug development, through tools like{' '} 
						<a
							href='/pdf/staff-bio/an-end-to-the-myth.pdf'
							rel='noopener noreferrer'
						>
						the Navigating the Ecosystem of Translational Science (NETS) map
						</a>
						. She has also taken Genetic Alliance’s registry services through several iterations, resulting in Genetic Alliances' current registry support services and partnership with{' '} 
						<a
							href='https://www.lunadna.com/lunapbc/'
							target='_blank'
							rel='noopener noreferrer'
						>
						LunaPBC
						</a>
						. Katherine graduated with honors from the University of Virginia in 2013, with a B.S. in Biochemistry and a B.A. in Linguistics.
					</p>
				</Grid.Column>
			</Grid>
		</Container>
	)
}

export default KatherineLambertson
