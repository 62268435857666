import { Grid } from 'semantic-ui-react'
import { useEffect } from 'react'
import './IRBApplication.css'
import NextBackStep from './NextBackStep'

function StepOne({ step, setStep, totalSteps }) {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<Grid centered>
			<Grid.Column computer={12}>
				<h3 className='mb-40'>General Instructions</h3>
				<p>
					This Webform is how we will be going forward with Genetic Alliance IRB protocol submissions and is for <b>single-site initial submissions</b> only. Please contact{' '} 
					<a
						href='mailto:irbadmin@geneticalliance.org'
						target='_blank'
						rel='noreferrer'>
						irbadmin@geneticalliance.org
					</a>{' '}
					for all other IRB life cycle documents (amendments, progress reports, exemptions, reliance agreements, multi-site studies &amp; closures). This webform is for general applications. If you want to submit a protocol that will use the registry platform <b>Luna</b>, please email{' '}
					<a
						href='mailto:irbadmin@geneticalliance.org'
						target='_blank'
						rel='noreferrer'>
						irbadmin@geneticalliance.org
					</a>{' '}
					for a separate template.
				</p>
				<p>
					This online application is designed to make submitting an IRB initial application more streamlined and user-friendly. <b>Make sure to read all of the instructions before starting.</b> You will be using the buttons at the bottom to page through the main body of the application. On every page, you have the option to save your progress by clicking the "Save Draft" button at the bottom. You will be able to return to this as often as you like and pick up right where you left off. We recommend having a separate document containing all your answers in case you forget to save or keep track of the changes you made.
				</p>
				<hr className='rule-blue-300 mt-40 mb-40' />
				<p className='p-compact-bold mb-25'>References</p>
				<a
					className=''
					href='https://docs.google.com/document/d/1sL_Ej6lESwOLFo_qFABMDULklmfzrl0lAQdlfnzQfAM/edit?usp=sharing'
					target='_blank'
					rel='noreferrer'>
					The Study Design Worksheet
				</a>
				<p className='mt-25'>
					This asks a few questions to assist you in writing your abstract, research
					and analysis description, and lay summary. You do not have to turn this in;
					it is only for your use and benefit as you see fit. This may be helpful to
					those newer to the IRB process.
				</p>
				<hr className='rule-blue-300 mt-40 mb-40' />
				<p className='p-compact-bold mb-25'>Required Documents</p>
				<p className='mb-25'>
					The following documents are required for submission, and you will upload them at the end of this application. Access to these documents requires you to have a Google account to log in to: 
				</p>
				<p>
					<a
						href='https://docs.google.com/document/d/1e0lKifV0A1Ol-kDgvmnm7S1IBg3ghvOb/edit?usp=sharing&ouid=113313084326423560032&rtpof=true&sd=true'
						target='_blank'
						rel='noreferrer'>
						Appendix A: Key Personnel
					</a>
				</p>
				<p>
					<a
						href='https://docs.google.com/document/d/1A_nADv7FYwm2DQtwg1Ic1rfZKuxj8H2g/edit?usp=sharing&ouid=113313084326423560032&rtpof=true&sd=true'
						target='_blank'
						rel='noreferrer'>
						Appendix B: Outreach Plan
					</a>
				</p>
				<p>
					<a
						href='https://docs.google.com/document/d/1h6ELIe_QHFr_BdmAq912mRW9hAGb_HPC/edit?usp=sharing&ouid=113313084326423560032&rtpof=true&sd=true'
						target='_blank'
						rel='noreferrer'>
						Appendix C: Participant Outreach and Recruitment Samples
					</a>
				</p>
				<p>
					Appendix D: Informed Consent Document - more information on page 11
				</p>
				<p>
					Signature Page
				</p>
				<p>
					CVs/NIH Biosketches and Certificates of Human Research Protection Training completion for all Key Personnel
				</p>
				<hr className='rule-blue-300 mt-40 mb-40' />
				<p className='p-compact-bold mb-25'>Additional Documents</p>
				<p>
					If you have additional documents or attachments that you feel are necessary to submit to the IRB, you can upload them at the end. Each document should be titled as an additional appendix starting with the letter <b>E</b> (all surveys are all titled with appendix E). Below are examples of additional appendices you may add:
				</p>
				<p>
					Appendix E - Welcome Survey, Sleep Questionaire, and Physical Activity Survey
				</p>
				<p>
					Appendix F - Schedule of Clinical Events
				</p>
				<p>
					Appendix G - Data Agreement
				</p>
				<p>
					Appendix H - Supplemental Literature
				</p>
				<hr className='rule-blue-300 mt-40 mb-40' />
				<p className='p-compact-bold mb-25'>Application Contents</p>
				<p>
					Page 2: Study Title &amp; Contact Information
				</p>
				<p>
					Page 3: Principle Investigator (PI) Information
				</p>
				<p>
					Page 4: Human Research Protection Training
				</p>
				<p>
					Page 5: Conflict of Interest
				</p>
				<p>
					Page 6: Technical (Scientific) Abstract
				</p>
				<p>
					Page 7: Research Design, Methodology, and Data Analysis
				</p>
				<p>
					Page 8: Lay Summary
				</p>
				<p>
					Page 9: Human Participants
				</p>
				<p>
					Page 10: Research Population
				</p>
				<p>
					Page 11: Informed Consent
				</p>
				<p>
					Page 12: Additional Consent Information
				</p>
				<p>
					Page 13: Data Privacy, Security, and Confidentiality and Future Data Use
				</p>
				<p>
					Page 14: Uploads (Appendicies, Signature Page, and Credentials)
				</p>
				<hr className='rule-blue-300 mt-40 mb-40' />
				<p className='p-compact-bold mb-25'>Direct Application</p>
				<p className='mt-25'>
					We suggest using the webform. It is helpful to those newer to the IRB
					process as it gives detailed instructions and writing samples. However, if
					you wish to fill out the template yourself, it is linked{' '}
					<a
						href='https://docs.google.com/document/d/1i9ufKTutY9Z3An9wxkNl_28tIaGx4zBT/edit?usp=sharing&ouid=113313084326423560032&rtpof=true&sd=true'
						target='_blank'
						rel='noreferrer'>
						here
					</a>
					. If you have any questions, email the IRB admin directly at{' '}
					<a className='link-blue-800' href='mailto:irbadmin@geneticalliance.org'>
						irbadmin@geneticalliance.org
					</a>
					.
				</p>
				<NextBackStep step={step} setStep={setStep} totalSteps={totalSteps} />
			</Grid.Column>
		</Grid>
	)
}

export default StepOne
