import { Container, Grid, GridColumn, Image, Icon } from 'semantic-ui-react'
import ShareSocialMedia from '../../../ShareSocialMedia'
import LinkButton from '../../../LinkButton'

function InsuranceAndFinancialAssistance() {
	const shareUrl = 'https://geneticalliance.org/resources-and-information/advocacy-atlas/financial-assistance'
	return (
		<Container className='container-main'>
			<h1>Insurance & Financial Assistance</h1>
			<Grid columns={1} padded>
				<GridColumn>
				<hr />
					<ShareSocialMedia shareUrl={shareUrl} />
					<div>
						<p className='mt-20'>
							<i>When we decided to try private therapy until the early intervention would start in the fall, I looked at my and my husband's employee handbook for guidelines. Both carriers required a doctor's prescription, listed qualifications of the physical therapist, and supervision of the therapy by a physician. So following guidelines for both primary and secondary insurance, we started physical therapy. It wasn't until months of claims were submitted that the insurance company sent denial letters. That's when I found out that only 1/3 of families appeal denied claims, and insurance companies depend on people either not appealing or giving up, even though most of the time, it's turned around on the first appeal. But families must be persistent. Keep copies of everything and send it a certified return receipt. We requested the doctor who prescribed the therapy send a note saying it was medically necessary. Doctor's notes are golden, and often all that is needed is a prescription, but if that isn't sufficient, a note of "medically necessary" will usually resolve this. The insurance companies kept asking for additional information such as treatment and diagnosis codes, tax id, therapist license, etc. This went on for a year and a half, then they finally relented. Ironically, the following year my husband's insurer put an addendum in the employee handbook that they no longer cover these types of therapies for children. Fortunately, by that time our child was getting services through early intervention and later related services under IDEA at school.
							<br /><br />
							-Story from a parent advocate</i>
						</p>
					</div>

					<h2 className='color-secondary mt-40'>Resources</h2>
					<Grid stackable columns={2} className='mt-15 left aligned'>
						<Grid.Row>
							<Grid.Column>
								<p>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/financial-assistance/carecoordinationnotebook.pdf'
										rel='noreferrer'
										target='_blank'>
										Care Coordination Notebook: Financing and Managing Your Child’s Health Care
									</a>
									- Partnership for People with Disabilities and Parent to Parent of Virginia
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/financial-assistance/policymattersLINKED3.pdf'
										target='_blank'
										rel='noreferrer'>
										The Affordable Care Act: What Disability Advocates Need to Know
									</a>
									- The Arc
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='http://www.floridahats.org/health-insurance-financing/'
										target='_blank'
										rel='noreferrer'>
										FloridaHATS
									</a>
									- FloridaHATS
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='https://oley.org/page/writingappealletters?&terms'
										target='_blank'
										rel='noreferrer'>
										Writing Winning Insurance Appeal Letters
									</a>
									- The Oley Foundation
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/financial-assistance/healthcareadvocacylifespan.pdf'
										target='_blank'
										rel='noreferrer'>
										Health Care Advocacy Across the Lifespan: What Parents of Children with Disabilities Need to Know
									</a>
									- Statewide Parent Advocacy Network of New Jersey (SPAN) 
								</p>
							</Grid.Column>
							<Grid.Column>
								<div>
									<figure>
										<Image
											src='/images/resources-and-information/advocacy-atlas/financial-assistance/sarah supreme court.jpeg'
											size='medium'
											centered
										/>
										<figcaption className='mt-20 text-center'>
											<i>Sarah advocates for health insurance coverage in front of the United States Supreme Court.</i>
										</figcaption>
									</figure>
								</div>
							</Grid.Column>
						</Grid.Row>
					</Grid>
					<div>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/financial-assistance/101026 FINAL PDF FORMAT 8 5x5 5  _Turner Syndrome Booklet_Ad.pdf'
								target='_blank'
								rel='noreferrer'>
								A Lifetime of Advocacy: What Families and Individuals with Turner Syndrome Need to Know
							</a>
							- Valerie A. Powers Smith, Esq., Turner Syndrome Foundation
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='https://www.secondarytransition.org/'
								target='_blank'
								rel='noreferrer'>
								Secondary Transition Guide
							</a>
							- Pennsylvania Youth Leadership Network
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/financial-assistance/GU-Back-to-Basics-How-to-Discuss-the-Cost-of-Health-Care-Treatments-With-Your-Provider.pdf'
								target='_blank'
								rel='noreferrer'>
								A Greater Understanding Patients: How to Discuss the Costs of Healthcare Treatments With Your Provider
							</a>
							- Patient Advocate Foundation
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/financial-assistance/8_GuidetoCommunityServices.pdf'
								target='_blank'
								rel='noreferrer'>
								Guide To Community Services in Oklahoma for People with Disabilities and Their Families (Oklahoma)
							</a>
							- Center for Learning and Leadership
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='https://slideplayer.com/slide/2837778/'
								target='_blank'
								rel='noreferrer'>
								Taking the Next Step: Life After High School
							</a>
							- National Youth Leadership Network
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/financial-assistance/Managed_care_doingitright.pdf'
								target='_blank'
								rel='noreferrer'>
								Managed Care: Doing It Right
							</a>
							- The National Advisory Board on Improving Health Care Services for Seniors and People with Disabilities (NAB)
						</p>
					</div>
					<div className='mt-40 text-center'>
						<LinkButton className='ui button' 
						to='/resources-and-information/advocacy-atlas'
						>
							<Icon name='arrow left' /> Return to Advocacy ATLAS Homepage
						</LinkButton>
					</div>
				</GridColumn>
			</Grid>
		</Container>
	)
}

export default InsuranceAndFinancialAssistance