import fetch from 'isomorphic-fetch'
import cookie from 'js-cookie'
import { toast } from 'react-toastify'

export const handleResponse = response => {
	if (response.status === 401) {
		signOut(() => {
			window.location.href =
				'/sign-in?expired=Your session is expired. Please sign in.'
			toast.error('Your session is expired. Please sign in.')
		})
	}
}

export const preSignUp = user => {
	return fetch(`${process.env.REACT_APP_API_URL}/pre-signup`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(user),
	})
		.then(response => {
			return response.json()
		})
		.catch(err => {
			console.log(err)
		})
}

export const signUp = user => {
	return fetch(`${process.env.REACT_APP_API_URL}/sign-up`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(user),
	})
		.then(response => {
			return response.json()
		})
		.catch(err => console.log(err))
}

export const signIn = user => {
	return fetch(`${process.env.REACT_APP_API_URL}/sign-in`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(user),
	})
		.then(response => {
			return response.json()
		})
		.catch(err => console.log(err))
}

export const signOut = next => {
	removeCookie('token')
	removeLocalStorage('user')
	next()

	return fetch(`${process.env.REACT_APP_API_URL}/sign-out`, {
		method: 'GET',
	})
		.then(response => {
			return response.json()
		})
		.catch(err => console.log(err))
}

// set cookie
export const setCookie = (key, value) => {
	if (process.browser) {
		cookie.set(key, value, {
			expires: 1,
		})
	}
}

export const removeCookie = key => {
	if (process.browser) {
		cookie.remove(key, {
			expires: 1,
		})
	}
}

// get cookie
export const getCookie = key => {
	if (process.browser) {
		return cookie.get(key)
	}
}

// local storage
export const setLocalStorage = (key, value) => {
	if (process.browser) {
		localStorage.setItem(key, JSON.stringify(value))
	}
}

export const removeLocalStorage = key => {
	if (process.browser) {
		localStorage.removeItem(key)
	}
}

// authenticate user by passing data to cookie and local storage during sign in
export const authenticate = (data, next) => {
	setCookie('token', data.token)
	setLocalStorage('user', data.user)
	next()
}

// access user info from local storage
export const isAuth = () => {
	if (process.browser) {
		const cookieChecked = getCookie('token')
		if (cookieChecked) {
			if (localStorage.getItem('user')) {
				return JSON.parse(localStorage.getItem('user'))
			} else {
				return false
			}
		}
	}
	return false
}

export const forgotPassword = email => {
	return fetch(`${process.env.REACT_APP_API_URL}/forgot-password`, {
		method: 'PUT',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(email),
	})
		.then(response => {
			return response.json()
		})
		.catch(err => console.log(err))
}

export const resetPassword = resetInfo => {
	return fetch(`${process.env.REACT_APP_API_URL}/reset-password`, {
		method: 'PUT',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(resetInfo),
	})
		.then(response => {
			return response.json()
		})
		.catch(err => console.log(err))
}
