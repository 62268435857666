import { Container, Grid, GridColumn, Image, Icon} from 'semantic-ui-react'
import ShareSocialMedia from '../../../ShareSocialMedia'
import LinkButton from '../../../LinkButton'

function YouthLeadership() {
	const shareUrl = 'https://geneticalliance.org/resources-and-information/advocacy-atlas/youth-leadership'
	return (
		<Container className='container-main'>
			<h1>Youth Leadership</h1>
			<Grid columns={1} padded>
				<GridColumn>
					<hr />
					<ShareSocialMedia shareUrl={shareUrl} />
					<div>
						<p className='mt-20'>
							<i>I’ve been involved in Kids as Self Advocates (KASA) since I was fifteen, beginning as a young, new board member, and making my way up to being co-chair. KASA was my first real exposure to national advocacy, and helped me understand how disability rights impacts the country and the world. KASA exposed me to the wide world outside of my hometown in Wisconsin, it was one of the first organizations in which I was able to have a leadership role, one of the first organizations in which I felt I was heard. I’d tried to run for leadership roles in High School, but didn’t really understand how to express myself, nor work in a team. KASA has helped me improve my teamwork skills and my public speaking skills, and taught me about my rights as a person with a disability. I was able to take this knowledge to Pakistan, and start a non-profit project that aids young people with disabilities in Pakistan, helping them learn their rights.
							<br /><br />
							-Hamza’s story about his involvement with KASA, a Family Voices project</i>
						</p>
					</div>

					<h2 className='color-secondary mt-40'>Resources</h2>
					<Grid stackable columns={2} className='mt-15 left aligned'>
						<Grid.Row>
							<Grid.Column>
								<p>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/youth-leadership/mentor_guide.pdf'
										target='_blank'
										rel='noreferrer'>
										Plotting the Course for Success: An Individualized Mentoring Plan for Youth with Disabilities
									</a>
									- National Consortium on Leadership and Disability for Youth 
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/youth-leadership/Am_I_Learning_to_Lead_Self_Assmt_Youth.pdf'
										target='_blank'
										rel='noreferrer'>
										Learning to Lead: A Self-Assessment for Youth
									</a>
									- National Consortium on Leadership and Disability for Youth
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/youth-leadership/manual.pdf'
										target='_blank'
										rel='noreferrer'>
										Speak Up! Action Manual for Peer Leader Teams
									</a>
									- Acting for Advocacy
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/youth-leadership/Empowering-Leadership.pdf'
										target='_blank'
										rel='noreferrer'>
										Empowering Leadership A Systems Change Guide for Autistic College Students and Those with Other Disabilities
									</a>
									- Autistic Self Advocacy Network (ASAN)
								</p>
							</Grid.Column>
							<Grid.Column>
								<div>
									<figure>
										<Image
											src='/images/resources-and-information/advocacy-atlas/youth-leadership/alexaaward.jpeg'
											size='medium'
											centered
										/>
										<figcaption className='mt-20 text-center'>
											<i>Alexa gives Senator Harkin an award for his leadership.</i>
										</figcaption>
									</figure>
								</div>
							</Grid.Column>
						</Grid.Row>
					</Grid>
					<div>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/youth-leadership/legislative_policy_guide.pdf'
								target='_blank'
								rel='noreferrer'>
								A Guide to Legislative Advocacy for Youth with Disabilities
							</a>
							- National Consortium on Leadership and Disability for Youth
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/youth-leadership/RoadtoWork.pdf'
								target='_blank'
								rel='noreferrer'>
								The Road to Work: The Speak-Up Action Manual on Finding and Keeping Work
							</a>
							- Youths 4 Advocacy 
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/youth-leadership/PYLN2ndTransitionToolkit.pdf'
								target='_blank'
								rel='noreferrer'>
								Secondary Transition Toolkit
							</a>
							- Pennsylvania Youth Leadership Network
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/youth-leadership/PYLN Healthcare Toolkit.pdf'
								target='_blank'
								rel='noreferrer'>
								Secondary Health Care Transition Toolkit
							</a>
							- Pennsylvania Youth Leadership Network
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='https://www.eastpennsd.org/wp-content/uploads/2019/06/Transition-Checklist-flyer.pdf'
								target='_blank'
								rel='noreferrer'>
								Transition Health Care Checklist: Transition to Adult Living in Pennsylvania
							</a>
							- Pennsylvania Department of Health
						</p>
					</div>
					<div className='mt-40 text-center'>
						<LinkButton className='ui button' 
						to='/resources-and-information/advocacy-atlas'
						>
							<Icon name='arrow left' /> Return to Advocacy ATLAS Homepage
						</LinkButton>
					</div>
				</GridColumn>
			</Grid>
		</Container>
	)
}

export default YouthLeadership