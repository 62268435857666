import fetch from 'isomorphic-fetch'
import queryString from 'query-string'
import { handleResponse } from './auth'

export const createBlog = (blog, token) => {
    return fetch(`${process.env.REACT_APP_API_URL}/blog`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: blog,
    })
        .then(response => {
            handleResponse(response)
            return response.json()
        })
        .catch(err => console.log(err))
}

export const listBlogsWithCategoriesAndTags = (skip, limit) => {
    const data = {
        limit,
        skip,
    }
    return fetch(`${process.env.REACT_APP_API_URL}/blogs-categories-tags`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}

export const singleBlog = slug => {
    return fetch(`${process.env.REACT_APP_API_URL}/blog/${slug}`, {
        method: 'GET',
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}

export const listRelated = blog => {
    return fetch(`${process.env.REACT_APP_API_URL}/blogs/related`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(blog),
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}

export const list = () => {
    return fetch(`${process.env.REACT_APP_API_URL}/blogs`, {
        method: 'GET',
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}

export const removeBlog = (slug, token) => {
    return fetch(`${process.env.REACT_APP_API_URL}/blog/${slug}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then(response => {
            handleResponse(response)
            return response.json()
        })
        .catch(err => console.log(err))
}

export const updateBlog = (blog, token, slug) => {
    return fetch(`${process.env.REACT_APP_API_URL}/blog/${slug}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: blog,
    })
        .then(response => {
            handleResponse(response)
            return response.json()
        })
        .catch(err => console.log(err))
}

export const listSearch = params => {
    let query = queryString.stringify(params)
    return fetch(`${process.env.REACT_APP_API_URL}/blogs/search?${query}`, {
        method: 'GET',
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}