import { Container, Grid, Image, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import './GeneticAllianceStudies.css'

function GeneticAllianceStudies() {
	return (
		<div id='geneticAllianceStudies'>
			<Container className='container-main'>
				<Grid columns={2} stackable>
					<Grid.Column mobile={16} tablet={16} computer={8}>
						<h1>Genetic Alliance Studies</h1>
					</Grid.Column>
					<Grid.Column mobile={16} tablet={16} computer={8}>
						<p className='subtitle'>
							Genetic Alliance has a long history in catalyzing cross condition
							research. We are a firm believer in “a rising tide lifts all boats”, and
							we are constantly looking for ways to influence the tides. All for one
							and one for all. Let’s do this together. See our current opportunities
							below.
						</p>
					</Grid.Column>
				</Grid>
				<hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
				<div className='genetic-alliance-studies-overview-mq1 mb-80-60-40'>
					<p className='subtitle' style={{ maxWidth: '864px' }}>
						Genetic Alliance has been a leader in rigorous studies that help to drive
						research with people at the center. We created IRB, biorepository,
						registry, and study systems long before any lay people were involved in
						such things! In 2019 we took a huge stride forward and forged a
						collaboration with our partner Luna for all of our registry and study
						needs. Join us in building a loud and noisy presence in the research
						world.
					</p>
					<Image
						src='/logos/genetic-alliance-luna-dna.png'
						style={{ maxWidth: '500px', width: '100%' }}
					/>
				</div>
				<hr className='rule-blue-800 mt-80-60-40 mb-80-60-40' />
				<Grid columns={2}>
					<Grid.Column mobile={16} tablet={16} computer={8}>
						<h3>Participation is simple, safe, and secure</h3>
					</Grid.Column>
					<Grid.Column mobile={16} tablet={16} computer={8}>
						<p>
							When you or your community participate in our studies, all survey
							responses, data, and records collected are de-identified and held to the
							highest security and{' '}
							<a
								href='https://support.lunadna.com/support/solutions/articles/43000040805-lunadna-privacy-policy'
								target='_blank'
								rel='noreferrer'>
								privacy
							</a>{' '}
							standards. Neither the Genetic Alliance nor our partners are able to
							associate your name with any of your data unless you have explicitly
							granted your consent.{' '}
							<Link to='/irb' rel='noreferrer'>
								The Genetic Alliance Institutional Review Board (IRB)
							</Link>{' '}
							provides oversight by reviewing and approving all studies to ensure they
							follow responsible information collection practices and have legitimate
							benefit to the community.
						</p>
					</Grid.Column>
				</Grid>
			</Container>

			<div style={{ background: '#E1EDF7' }} className='mt-80-60-40'>
				<Container className='container-colored-section studies-margin-left-right'>
					<Grid
						columns={2}
						className='studies-flex-direction-column studies-blue-section'>
						<Grid.Column
							mobile={16}
							tablet={16}
							computer={8}
							className='studies-color-block-blue-image'>
							<Image
								src='/images/resources-and-information/studies/helping-hand.png'
								style={{ width: '100%' }}
							/>
						</Grid.Column>
						<Grid.Column
							mobile={16}
							tablet={16}
							computer={8}
							className='studies-color-block-blue-text'>
							<h4>Cross Condition Burden of Disease Study</h4>
							<p className='subtitle'>
								You live daily with the effects of your disease on your life: including
								your relationships, work, and play. This is a burden – large or small.
								It may even change over time. Share your experience of living with your
								condition.
							</p>
							<Link
								to='/studies/cross-condition-burden-of-disease'
								rel='noreferrer'
								className='inline-block link-button'>
								<Button className='button-blue-800' style={{ margin: '0' }}>
									Learn more
									<Image
										src='/icons/link-internal-white.png'
										className='icon-width-16px'
									/>
								</Button>
							</Link>
						</Grid.Column>
					</Grid>
				</Container>
			</div>
			<div style={{ background: '#FFC741' }}>
				<Container className='container-colored-section studies-margin-left-right'>
					<Grid
						columns={2}
						reversed='mobile vertically'
						className='studies-flex-direction-column studies-yellow-section'>
						<Grid.Column
							mobile={16}
							tablet={16}
							computer={8}
							className='studies-color-block-yellow-text'>
							<h4>Tell Us About Your Rare Disease Study</h4>
							<p className='subtitle studies-mw-600'>
								As you well know, your experience counts! Researchers and policy-makers
								must understand the breadth and scope of what it means to live with both
								common and rare diseases. You can share this one minute survey with your
								family and peers as well. We all count!
							</p>
							<a
								href='https://id.lunadna.com/referrer/peer-group-tell-about-your-disease'
								rel='noreferrer'
								target='_blank'
								className='inline-block link-button'>
								<Button className='button-blue-800' style={{ margin: '0' }}>
									Join
									<Image
										src='/icons/link-outbound-white.svg'
										className='icon-width-16px'
									/>
								</Button>
							</a>
						</Grid.Column>
						<Grid.Column
							mobile={16}
							tablet={16}
							computer={8}
							className='studies-color-block-yellow-image'>
							<Image
								src='/images/resources-and-information/studies/friendly-discussion.png'
								size='big'
								style={{ width: '100%' }}
							/>
						</Grid.Column>
					</Grid>
				</Container>
			</div>

			<Container className='container-general mt-80-60-40'>
				<Grid columns={2}>
					<Grid.Column mobile={16} tablet={16} computer={8}>
						<h3>You are always in charge of your data</h3>
					</Grid.Column>
					<Grid.Column mobile={16} tablet={16} computer={8}>
						<p>
							Genetic Alliance understands that your health information is private. The
							surveys are completely confidential - all data, records, and survey
							responses collected in the surveys will be de-identified and held to the
							highest security and{' '}
							<a
								href='https://support.lunadna.com/support/solutions/articles/43000040805-lunadna-privacy-policy'
								target='_blank'
								rel='noreferrer'>
								privacy
							</a>{' '}
							standards. You own your data every step of the way: you can view,
							download or delete your profile at any time, and your data cannot be
							shared outside of the platform without your permission.
						</p>
						<p>
							The surveys are hosted on LunaDNA. LunaDNA is HIPAA (Health Insurance
							Portability and Accountability Act) and GDPR (General Data Protection
							Regulation ) compliant, following both U.S. and E.U. privacy regulations.
							The LunaDNA platform allows you to answer questionnaires with
							state-of-the-art privacy and security. Genetic Alliance will never see
							your name, email or other personal identifying information.
						</p>
					</Grid.Column>
				</Grid>
				<hr className='rule-blue-800 mt-80-60-40 mb-80-60-40' />
				<Grid columns={2}>
					<Grid.Column mobile={16} tablet={16} computer={8}>
						<h3>Frequently asked questions</h3>
					</Grid.Column>
					<Grid.Column mobile={16} tablet={16} computer={8}>
						<h4>Who can see/access the information I enter?</h4>
						<p>
							The Genetic Alliance Institutional Review Board approved each study.
							Researchers will only see your de-identified data (i.e., none of your
							personal information like email addresses or names will be exposed to
							researchers) when they perform data analysis.
						</p>
						<hr className='rule-blue-300 mt-40 mb-40' />
						<h4>How is my information protected?</h4>
						<p>
							All information you share is kept confidential. Shared Data (e.g., DNA
							data, Health, Surveys, etc.) is de-identified in the Luna platform. Data
							is encrypted in transit (i.e., during upload and download by the member)
							and at rest (i.e., while stored). Personal Data (e.g., information that
							identifies you like your name, email address, etc.) is stored separately
							from Shared Data. Different types of Shared Data are stored separately to
							make re-combing the data much more challenging. Please see the{' '}
							<a
								href='https://support.lunadna.com/support/solutions/articles/43000040805-lunadna-privacy-policy'
								target='_blank'
								rel='noreferrer'>
								Luna Privacy Policy
							</a>{' '}
							for additional details.
						</p>
						<p>
							You control your data at all times. You can delete some or all of it,
							delete your entire Luna account, or revoke your consent at any time.
						</p>
					</Grid.Column>
				</Grid>
			</Container>
		</div>
	)
}

export default GeneticAllianceStudies
