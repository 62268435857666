import { Container, Grid, Image, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'


function NETSHTS() {
    return (
        <>
            <div>
                <Container className='container-main'>
                    <h1>NETS: HTS Assay</h1>
			        <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>What is a HTS assay and why is it important in drug development?</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>
                                An assay is an analytical test that measures the activity of a compound on a{' '} 
                                <Link to='/resources-and-information/nets/targets' rel='noopener noreferrer'>
                                    target of interest
                                </Link>
                                . Assays are used to screen libraries of different compounds to determine if any compounds have a desired biological effects on a target of interest. A high-throughput screening (HTS) assay is an assay that has been designed to be compatible with{' '} 
                                <Link to='/resources-and-information/nets/system' rel='noopener noreferrer'>
                                    HTS systems
                                </Link>
                                . With an{' '} 
                                <Link to='/resources-and-information/nets/system' rel='noopener noreferrer'>
                                    HTS system 
                                </Link>
                                {' '}and HTS compatible assays, tens of thousands of compounds can be tested rapidly to determine whether any of them have the desired biological effects.
                            </p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Testimonials: How we did it</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>Content not yet available for this section.</p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Relevant Publications</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>Content not yet available for this section.</p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Related Tools</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <a
                                href='https://pubchem.ncbi.nlm.nih.gov/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/toolkit.png' className='icon-width-16px' />NCBI: PubChem</Button>
                            </a>
                            <a
                                href='https://www.ncbi.nlm.nih.gov/pcassay?term=NCGC%5Bsourcename%5D&cmd=search'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/toolkit.png' className='icon-width-16px' />NCGC: Assay Data in Pubchem</Button>
                            </a>
                            {/* <a
                                href='http://assay.nih.gov/assay/index.php/Software'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/toolkit.png' className='icon-width-16px' />NCGC: Assay Software Tools</Button>
                            </a> */}
                            <a
                                href='https://ncats.nih.gov/ncgc'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/guide.png' className='icon-width-16px' />NCGC: High Throughput Screening Assay Guidance Criteria</Button>
                            </a>
                            {/* <a
                                href='http://mli.nih.gov/mli/mlpcn/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/partnership.png' className='icon-width-16px' />Molecular Libraries Program: Accessing the NCGC through the Molecular Libraries Program</Button>
                            </a> */}
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Link to='/resources-and-information/nets/full-view' rel='noopener noreferrer' className='link-button inline-block'><Button className='button-orange-500'><Image src='/icons/link-internal-white.png' className='icon-width-16px' style={{ transform: 'scaleX(-1)' }} />Back to NETS Map full view</Button></Link>
                </Container>
            </div>
        </>
    )
}

export default NETSHTS