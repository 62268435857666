import { Button, Image } from 'semantic-ui-react'
import { useState } from 'react'
import { create, update, sendEmailResumed } from 'actions/application'
import { toast } from 'react-toastify'
import Modal from 'react-modal'
import './IRBApplication.css'

const SaveButton = ({ data, step, files }) => {
	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			padding: '25px',
		},
	}

	const [isOpenModal, setIsOpenModal] = useState(false)
	const [yourLink, setYourLink] = useState('')
	const [id, setId] = useState('')

	function closeModal() {
		setIsOpenModal(false)
	}

	function afterOpenModal() { }

	function save() {
		console.log('click save')
		var statusNew = 'incompleted'
		if (step !== undefined) {
			// console.log('data', data)
			const dataSubmit = { ...data, ...{ status: statusNew, defaultStep: step } }
			// console.log('dataSubmit', dataSubmit)
			if (data._id) {
				// other times save is update application
				console.log('behind first times save is update application')
				update(dataSubmit, files, 'token')
					.then(res => {
						console.log(res)
						setYourLink(`${process.env.REACT_APP_URL}/irb/application/${res._id}`)
						setId(res._id)
						toast.success('Save successfully')
						return res
					})
					.then(res => {
						setIsOpenModal(true)
						return res
					})
			} else {
				// first time save is create application
				console.log('first times save is create')
				create(dataSubmit, files, 'token')
					.then(res => {
						console.log(res)
						setYourLink(`${process.env.REACT_APP_URL}/irb/application/${res._id}`)
						setId(res._id)
						toast.success('Save successfully')
						return res
					})
					.then(res => {
						setIsOpenModal(true)
						return res
					})
			}
		}
	}

	function sendEmail() {
		console.log('send email')
		var copyText = document.getElementById('yourEmail')
		// validate email
		sendEmailResumed(id, copyText.value)
		toast.success('Send the text: ' + copyText.value)
	}

	return (
		<div id='popupSave'>
			<Button
				className='button-neutral-100'
				onClick={e => {
					save()
				}}>
				{step !== 14 ? 'Save' : 'Submit'}
			</Button>
			<>
				{isOpenModal && (
					<>
						<Modal
							isOpen={isOpenModal}
							onAfterOpen={afterOpenModal}
							onRequestClose={closeModal}
							style={customStyles}
							contentLabel=''>
							{/* need more css */}
							
							<Image onClick={closeModal} src='/icons/search-exit.png' className='icon-width-16px' style={{ float: 'right', cursor: 'pointer' }} />
							<br />
							<label style={{ fontFamily: 'neue-haas-unica', fontWeight: "350", color: "#0b2f4d", fontSize: "16px" }}
							><b>Copy your form link to resume later</b></label>
							<br />
							<input style={{ borderColor: "gray", borderWidth: "1px", height: '35px', paddingLeft: '8px', marginRight: '8px' }} type='text' value={yourLink} id='myInput' className='mt-8 mb-25' />
							&nbsp;
							{/* <button
								style={{ borderColor: "gray", borderWidth: "1px", backgroundColor: "#93c47d", color: "white", opacity: "0.8" }}
								onClick={() => {
									var copyText = document.getElementById('myInput')
									copyText.select()
									copyText.setSelectionRange(0, 99999)
									document.execCommand('copy')
									toast.success('Copied the text: ' + copyText.value)
								}}
							>Copy</button> */}
							<Button 
								className='button-neutral-100 inline'
								onClick={() => {
									var copyText = document.getElementById('myInput')
									copyText.select()
									copyText.setSelectionRange(0, 99999)
									document.execCommand('copy')
									toast.success('Copied the text: ' + copyText.value)
								}}
							>
								Copy
							</Button>
							<br />
							<label style={{ fontFamily: 'neue-haas-unica', fontWeight: "350", color: "#0b2f4d", fontSize: "16px", }}
							><b>Email me my link</b></label>
							<br />
							<input className="text mt-8" style={{ borderColor: "gray", borderWidth: "1px", height: '35px', paddingLeft: '8px', marginRight: '8px' }} type='email' id='yourEmail' />
							&nbsp;
							{/* <button style={{ borderColor: "gray", borderWidth: "1px", backgroundColor: "#93c47d", color: "white", opacity: "0.8" }}
								onClick={() => { sendEmail() }} >Send email</button> */}
							<Button className='button-neutral-100 inline'
								onClick={() => { sendEmail() }}
							>Send email</Button>
						</Modal>
					</>
				)}
			</>
		</div >
	)
}

export default SaveButton
