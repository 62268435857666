import { Container, Grid, GridColumn, Image, Icon} from 'semantic-ui-react'
import ShareSocialMedia from '../../../ShareSocialMedia'
import LinkButton from '../../../LinkButton'

function AccessHealthcare() {
	const shareUrl = 'https://geneticalliance.org/resources-and-information/advocacy-atlas/access-healthcare'
	return (
		<Container className='container-main'>
			<h1>Access to Healthcare</h1>
			<Grid columns={1} padded>
				<GridColumn>
					<hr />
					<ShareSocialMedia shareUrl={shareUrl} />
					<div>
						<p className='mt-20'>
							<i>When we found out our son was going to have Cooley’s Anemia, we started educating ourselves about that genetic disorder and the treatment he would require. I discovered there would be a considerable health care cost and researched potential ways to address this issue. I decided I would try and improve the system (healthcare) not only for my son but also for other families. Since that time (23 years ago), I have been involved in advocacy through several organizations and continue to write, call, and advocate for accessible, quality, and affordable healthcare.
							<br /><br />
							-Story from a parent advocate</i>
						</p>
					</div>

					<h2 className='color-secondary mt-40'>Resources</h2>
					<Grid stackable columns={2} className='mt-15 left aligned'>
						<Grid.Row>
							<Grid.Column>
								<p>
									<a className='link-blue-800'
										href='https://www.youtube.com/watch?app=desktop&v=qAqVL5We8X0&ab_channel=ICHPUF'
										target='_blank'
										rel='noreferrer'>
										Talking With Your Doctor and Other Health Care Professionals-Video
									</a>
									- Institute for Child Health Policy at the University of Florida, Health Care Transition Initiative
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/access-healthcare/civil-tips.pdf'
										target='_blank'
										rel='noreferrer'>
										Advocacy Tip Sheet
									</a>
									- Kids as Self Advocates (KASA)
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='https://leaf.expert/resources/civil.php'
										target='_blank'
										rel='noreferrer'>
										
										Civil Rights and Advocacy Resources
									</a>
									- Kids as Self Advocates (KASA)
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/access-healthcare/PYLN2ndTransitionToolkit.pdf'
										target='_blank'
										rel='noreferrer'>
										Secondary Transition Toolkit
									</a>
									- Pennsylvania Youth Leadership Network
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/access-healthcare/PYLNHealthcareToolkit.pdf'
										target='_blank'
										rel='noreferrer'>
										Secondary Health Care Transition Toolkit
									</a>
									- Pennsylvania Youth Leadership Network
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/access-healthcare/Being_a_Healthy_Adult_ENG.pdf'
										target='_blank'
										rel='noreferrer'>
										Being a Healthy Adult: How to Advocate for Your Health and Health Care
									</a>
									- Kathy Roberson, M.S.W, The Boggs Center on Developmental Disabilities
								</p>
							</Grid.Column>
							<Grid.Column>
								<div>
									<figure>
										<Image
											src='/images/resources-and-information/advocacy-atlas/access-healthcare/twogirlsforweb.jpeg'
											size='medium'
											centered
										/>
										<figcaption className='mt-20 text-center'>
											<i>Two friends share a moment. Rebecca (left), age 5, has Down syndrome, and Lilly, age 4, has Praeder Willy syndrome.</i>
										</figcaption>
									</figure>
								</div>
							</Grid.Column>
						</Grid.Row>
					</Grid>
					<div className='mt-40 text-center'>
						<LinkButton className='ui button' 
						to='/resources-and-information/advocacy-atlas'
						>
							<Icon name='arrow left' /> Return to Advocacy ATLAS Homepage
						</LinkButton>
					</div>
				</GridColumn>
			</Grid>
		</Container>
	)
}

export default AccessHealthcare