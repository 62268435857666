import { Container, Grid } from 'semantic-ui-react'
import Admin from '../../auth/Admin'
import ReadNews from 'components/crud/BlogRead'
import NewsMenu from '../NewsMenu'

const CrudBlogs = () => {
	return (
		<Admin>
			<Container className='container-news'>
				<NewsMenu />
				<h1 className='mb-80'>Manage News</h1>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <ReadNews />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
			</Container>
		</Admin>
	)
}

export default CrudBlogs
