import { Link } from 'react-router-dom'
import { Container, Image, Grid } from 'semantic-ui-react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { progressIndicator } from '../content/HelperFunction'
import ReactHtmlParser from 'react-html-parser'

function BioBankFillContent({ data }) {
	const { pathname, hash, key } = useLocation()
	useEffect(() => {
		// if not a hash link, scroll to top
		if (hash === '') {
			window.scrollTo(0, 0)
		}
		// else scroll to id
		else {
			setTimeout(() => {
				const id = hash.replace('#', '')
				const element = document.getElementById(id)
				if (element) {
					element.scrollIntoView({ behavior: 'smooth' })
				}
			}, 0)
		}
	}, [pathname, hash, key])

	useEffect(() => {
		progressIndicator()
	}, [])

	if (!data) {
		return null
	}
	const datas = data.filter(item => item.link === '/biobank')
	if (datas && Array.isArray(datas) && datas.length > 0) {
		datas.sort((a, b) => a.index - b.index)
	} else {
		return null
	}
	return (
		<Container className='container-main'>
			<h1 id={datas[0].id}>{datas[0].header}</h1>
			<Grid columns={1} padded>
				<Grid.Column>
					<Image src='/photos/Biobank.jpg' size='massive' centered />
					<p>
						{ReactHtmlParser(datas[0].content1)}
					</p>

					<p>
						{datas[0].content2}
					</p>

					<p>
						{datas[0].content3} <Link className='link-blue-800' to='/about/contact-us'>contact us</Link> and visit
						our{' '}
						<Link className='link-blue-800' to='/promise-for-engaging-everyone-responsibly'>PEER page.</Link>
					</p>
					<h2 id={datas[1].id}>{datas[1].header}</h2>
					<p>
						{datas[1].content1}
					</p>

					<p>
						{datas[1].content2}{' '}
						<a className='link-blue-800'
							href='mailto:biobank@geneticalliance.org'
							target='_blank'
							rel='noreferrer'>
							biobank@geneticalliance.org
						</a>
					</p>

					<h2 id={datas[2].id}>{datas[2].header}</h2>
					<p>
						{datas[2].content}{' '}
					</p>
					<h2 id={datas[3].id}>{datas[3].header}</h2>
					<p>
						{datas[3].content}{' '}
						<Link className='link-blue-800' to='/about/contact-us'>contact us</Link> here.
					</p>
				</Grid.Column>
			</Grid>
		</Container>
	)
}

export default BioBankFillContent
