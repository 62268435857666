import { Grid, Form } from 'semantic-ui-react'
import { useState, useEffect } from 'react'
import './IRBApplication.css'
import NextBackStep from './NextBackStep'

function StepFive({
	step,
	setStep,
	totalSteps,
	updateFields,
	conflictInterest,
	conflictContext,
	// reviewExemption,
	...data
}) {
	const [conflictError, setConflictError] = useState(false)
	const [conflictContextError, setConflictContextError] = useState(false)

	const isConflictMissing = () => {
		if (!conflictInterest) {
			setConflictError(true)
			return true
		}
		return false
	}

	const isConflictContextMissing = () => {
		if (conflictContext === '' && conflictInterest === 'Yes') {
			setConflictContextError(true)
			return true
		}
		return false
	}

	const next = () => {
		isConflictMissing()
		isConflictContextMissing()
		if (isConflictMissing() === false && isConflictContextMissing() === false) {
			setStep(i => {
				return i + 1
			})
		}
	}

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<Grid centered>
			<Grid.Column computer={12}>
				<Form>
					<h3 className='mb-40'>Conflict of Interest</h3>
					<p className='mb-40'>
						Will the PI(s) or any key personnel benefit significantly (financially or
						personally) from this research? <span className='error-color'>*</span>
					</p>
					<Form.Group className='display-block'>
						<Form.Radio
							className='mb-15'
							label='Yes'
							type='radio'
							name='conflictInterest'
							checked={conflictInterest === 'Yes'}
							value='Yes'
							onChange={e => {
								setConflictError(false)
								updateFields({ conflictInterest: 'Yes' })
							}}
						/>
						<Form.Radio
							label='No'
							type='radio'
							name='conflictInterest'
							checked={conflictInterest === 'No'}
							value='No'
							onChange={e => {
								setConflictError(false)
								updateFields({ conflictInterest: 'No' })
							}}
						/>
					</Form.Group>
					{conflictError && <p className='error'>Please select an option.</p>}
					{conflictInterest === 'Yes' && (
						<>
							<p className='mt-40'>If yes, please explain.</p>
							<Form.TextArea
								placeholder='Explain any conflict of interest'
								label='Explain any conflict of interest'
								className='mb-0'
								required={conflictInterest === 'Yes'}
								type='text'
								value={conflictContext}
								onChange={e => {
									setConflictContextError(false)
									updateFields({ conflictContext: e.target.value })
								}}
							/>
							{conflictContextError && (
								<p className='error'>Explanation is required.</p>
							)}
						</>
					)}
					<p className='mt-40 mb-40'>
						If you believe your research is exempt from IRB approval, please email{' '} 
						<a
							href='mailto:irbadmin@geneticalliance.org'
							target='_blank'
							rel='noreferrer'>
							irbadmin@geneticalliance.org
						</a>{' '} 
						for further information and discussion. Please see the{' '} 
						<a
							href='https://www.ecfr.gov/current/title-45/subtitle-A/subchapter-A/part-46'
							target='_blank'
							rel='noreferrer'
						>
							Review for Exemption 
						</a>{' '}
						under Exemption 2 of the Revised Common Rule [Refer to 45 CFR 46.104 under the Revised Common Rule].
					</p>
					{/* <Form.Checkbox
						label='Review for exemption under Exemption 2 of the Revised Common Rule is desired. [Refer to 45 CFR 46.104(d)(2) of the revised Common Rule.]'
						type='checkbox'
						defaultChecked={
							reviewExemption && reviewExemption.includes('Yes') ? true : false
						}
						onChange={() => {
							if (reviewExemption === 'Yes' || reviewExemption === '') {
								updateFields({ reviewExemption: 'No' })
							} else {
								updateFields({ reviewExemption: 'Yes' })
							}
						}}
					/> */}
					{/* <p>
						<a
							href='https://www.ecfr.gov/current/title-45/subtitle-A/subchapter-A/part-46?toc=1'
							target='_blank'
							rel='noreferrer'>
							Click here for more information
						</a>
					</p> */}
				</Form>
				<NextBackStep
					step={step}
					totalSteps={totalSteps}
					next={next}
					setStep={setStep}
					data={data}
				/>
			</Grid.Column>
		</Grid>
	)
}

export default StepFive
