import { Container, Button } from 'semantic-ui-react'
import Admin from '../../auth/Admin'
import NewsMenu from '../NewsMenu'
import { useTable, usePagination } from 'react-table'
import {
	getApplications,
	// downloadFileGenerateById,
	// downloadFileUploadedByInfo,
	// getApplicationFiles
} from 'actions/application'
import { useEffect, useState, useMemo } from 'react'
import './Table.css'
import Popup from 'reactjs-popup'
import DownloadApplicationFiles from './DownloadApplicationFiles'
// import 'reactjs-popup/dist/index.css';
import './Popup.css'

const IRBApplicationDashboard = () => {
	const [applicationIdDownloadFile, setApplicationIdDownloadFile] = useState()
	const [data, setData] = useState([])
	const [openDownloadApplicationFile, setOpenApplicationFile] = useState(false)
	const closeModal = () => setOpenApplicationFile(false)
	useEffect(() => {
		getApplications().then(res => {
			setData(res)
			console.log('res', res)
		})
	}, [setData])

	const columns = useMemo(
		() => [
			// { Header: 'Application ID', accessor: '_id' },
			// { Header: 'Name', accessor: 'piName' },
			// { Header: 'Title', accessor: 'piTitle' },
			// { Header: 'Department/Institution/Company', accessor: 'piDepartment' },
			// { Header: 'Email', accessor: 'piEmail' },
			// { Header: 'Phone', accessor: 'piPhone' },
			// { Header: 'Download File', accessor: 'file' },

			{ Header: 'Application ID', accessor: 'studyTitle' },
			{ Header: 'Name', accessor: 'protocolNumber' },
			{ Header: 'Title', accessor: 'issueName' },
			{ Header: 'Department/Institution/Company', accessor: 'orgName' },
			{ Header: 'Email', accessor: 'orgEmail' },
			{ Header: 'Phone', accessor: 'orgTelephone' },
			// {
			// 	Header: 'Appendix A',
			// 	accessor: 'fileAttachAppendixA',
			// 	Cell: cell => {
			// 		if (cell.row.original.fileAttachAppendixA) {
			// 			return (
			// 				<Button
			// 					onClick={() =>
			// 						downloadFileUploadedByInfo(cell.row.original.fileAttachAppendixA)
			// 					}
			// 					className='btn btn-primary'>
			// 					Download Appendix A
			// 				</Button>
			// 			)
			// 		} else {
			// 			return null
			// 		}
			// 	},
			// },
			// {
			// 	Header: 'Appendix B',
			// 	accessor: 'fileAttachAppendixB',
			// 	Cell: cell => {
			// 		if (cell.row.original.fileAttachAppendixB) {
			// 			return (
			// 				<Button
			// 					onClick={() =>
			// 						downloadFileUploadedByInfo(cell.row.original.fileAttachAppendixB)
			// 					}
			// 					className='btn btn-primary'>
			// 					Download Appendix B
			// 				</Button>
			// 			)
			// 		} else {
			// 			return <div></div>
			// 		}
			// 	},
			// },
			// {
			// 	Header: 'Appendix C',
			// 	accessor: 'fileAttachAppendixC',
			// 	Cell: cell => {
			// 		if (cell.row.original.fileAttachAppendixC) {
			// 			return (
			// 				<Button
			// 					onClick={() =>
			// 						downloadFileUploadedByInfo(cell.row.original.fileAttachAppendixC)
			// 					}
			// 					className='btn btn-primary'>
			// 					Download Appendix C
			// 				</Button>
			// 			)
			// 		} else {
			// 			return <div></div>
			// 		}
			// 	},
			// },
			// {
			// 	Header: 'Appendix D',
			// 	accessor: 'fileAttachAppendixD',
			// 	Cell: cell => {
			// 		if (cell.row.original.fileAttachAppendixD) {
			// 			return (
			// 				<Button
			// 					onClick={() =>
			// 						downloadFileUploadedByInfo(cell.row.original.fileAttachAppendixD)
			// 					}
			// 					className='btn btn-primary'>
			// 					Download Appendix D
			// 				</Button>
			// 			)
			// 		} else {
			// 			return <div></div>
			// 		}
			// 	},
			// },
			// {
			// 	Header: 'Appendix E',
			// 	accessor: 'fileAttachAppendixE',
			// 	Cell: cell => {
			// 		if (cell.row.original.fileAttachAppendixE) {
			// 			return (
			// 				<Button
			// 					onClick={() =>
			// 						downloadFileUploadedByInfo(cell.row.original.fileAttachAppendixE)
			// 					}
			// 					className='btn btn-primary'>
			// 					Download Appendix E
			// 				</Button>
			// 			)
			// 		} else {
			// 			return <div></div>
			// 		}
			// 	},
			// },
			// open popup
			{
				Header: 'List File',
				accessor: '_id',
				Cell: cell => (
					<Button
						className='button-neutral-200'
						onClick={e => onOpenListFile(cell.row.original._id)}>
						Open List File
					</Button>
				),
			},
			// {
			// 	Header: 'Download File',
			// 	accessor: '_id',
			// 	Cell: cell => (
			// 		// <a href='#' onClick={downloadFileById('634acc32efe507528329b4e8')}> Download Application </a>
			// 		<Button onClick={() => downloadFileGenerateById(cell.value)}>
			// 			Download
			// 		</Button>
			// 	),
			// },
		],
		[]
	)
	const memoData = useMemo(() => data, [data])

	const tableInstance = useTable({ columns, data: memoData }, usePagination)

	function onOpenListFile(applicationIdDownloadFile) {
		console.log('open list file')
		setOpenApplicationFile(true)
		setApplicationIdDownloadFile(applicationIdDownloadFile)
	}

	console.log('openListFile', openDownloadApplicationFile)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page, // Instead of using 'rows', we'll use page,
		// which has only the rows for the active page

		// The rest of these things are super handy, too ;)
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize },
	} = tableInstance

	return (
		<Admin>
			<Container className='container-news'>
				<NewsMenu />
				<h1 className='mb-80'>IRB Application Dashboard</h1>
				{data && (
					<>
						<table {...getTableProps()} className='mb-40'>
							<thead>
								{headerGroups.map(headerGroup => (
									<tr {...headerGroup.getHeaderGroupProps()}>
										{headerGroup.headers.map(column => (
											<th {...column.getHeaderProps()}>{column.render('Header')}</th>
										))}
									</tr>
								))}
							</thead>
							<tbody {...getTableBodyProps()}>
								{page.map((row, i) => {
									prepareRow(row)
									return (
										<tr {...row.getRowProps()}>
											{row.cells.map(cell => {
												return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
											})}
										</tr>
									)
								})}
							</tbody>
						</table>
						<div className='pagination mt-15'>
							<button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
								{'<<'}
							</button>{' '}
							<button onClick={() => previousPage()} disabled={!canPreviousPage}>
								{'<'}
							</button>{' '}
							<button onClick={() => nextPage()} disabled={!canNextPage}>
								{'>'}
							</button>{' '}
							<button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
								{'>>'}
							</button>{' '}
							<span>
								Page{' '}
								<strong>
									{pageIndex + 1} of {pageOptions.length}
								</strong>{' '}
							</span>
							<span>
								| Go to page:{' '}
								<input
									type='number'
									defaultValue={pageIndex + 1}
									onChange={e => {
										const page = e.target.value ? Number(e.target.value) - 1 : 0
										gotoPage(page)
									}}
									style={{ width: '100px' }}
								/>
							</span>{' '}
							<select
								value={pageSize}
								onChange={e => {
									setPageSize(Number(e.target.value))
								}}>
								{[10, 20, 30, 40, 50].map(pageSize => (
									<option key={pageSize} value={pageSize}>
										Show {pageSize}
									</option>
								))}
							</select>
						</div>
					</>
				)}
				{openDownloadApplicationFile && (
					<>
						<Popup
							open={openDownloadApplicationFile}
							closeOnDocumentClick
							onClose={closeModal}>
							<div className='modal'>
								<a href='/#' className='close' onClick={closeModal}>
									&times;
								</a>
								<DownloadApplicationFiles applicationId={applicationIdDownloadFile} />
							</div>
						</Popup>
					</>
				)}
			</Container>
		</Admin>
	)
}

export default IRBApplicationDashboard
