import React, { useState, useEffect } from 'react'
import { listBlogsWithCategoriesAndTags } from 'actions/blog'
import { Button, Container } from 'semantic-ui-react'
import NewsMenu from '../user/NewsMenu'
import Card from 'components/blog/Card'
import { Link } from 'react-router-dom'

const Blogs = () => {
	const [blogs, setBlogs] = useState([])
	const [categories, setCategories] = useState([])
	const [tags, setTags] = useState([])

	const [size, setSize] = useState(0)
	const [skip, setSkip] = useState(0)
	const [limit, setLimit] = useState(0)
	const [loadedBlogs, setLoadedBlogs] = useState([])

	const loadMore = () => {
		let toSkip = skip + limit
		listBlogsWithCategoriesAndTags(toSkip, limit).then(data => {
			if (data.error) {
				console.log(data.error)
			} else {
				console.log('data', data)
				setLoadedBlogs([...loadedBlogs, ...data.blogs])
				// setCategories([...categories, ...data.categories])
				// setTags([...tags, ...data.tags])
				setSize(data.size)
				setSkip(toSkip)
			}
		})
	}

	const loadMoreButton = () => {
		return (
			size > 0 &&
			size >= limit && (
				<Button
					onClick={loadMore}
					color='blue'
					basic
				>
					Load more
				</Button>
			)
		)
	}
		
	const showAllBlogs = () => {
		return blogs.map((blog, i) => {
			return (
				<article key={i}>
					<Card blog={blog} />
					<hr />
				</article>
			)
		})
	}

	const showAllCategories = () => {
		return categories.map((c, i) => {
			return (
				<Link key={i} to={`/categories/${c.slug}`}>
					<Button size='mini' color='blue' outline="true">
						{c.name}
					</Button>
				</Link>
			)
		})
	}

	const showAllTags = () => {
		return tags.map((t, i) => {
			return (
				<Link key={i} to={`/tags/${t.slug}`}>
					<Button size='mini' color='blue' basic className='mt-15'>
						{t.name}
					</Button>
				</Link>
			)
		})
	}

	const showLoadedBlogs = () => {
		return loadedBlogs.map((blog, i) => {
			return (
				<article key={i}>
					<Card blog={blog} />
					<hr />
				</article>
			)
		})
	}

	useEffect(() => {
		let skip = 0
		let limit = 3
		return listBlogsWithCategoriesAndTags(skip, limit).then(data => {
			if (data.error) {
				console.log(data.error)
			} else {
				setBlogs(data.blogs)
				setCategories(data.categories)
				setTags(data.tags)
				setSize(data.size)
				setLimit(limit)
				setSkip(skip)
			}
		})
	}, [])
    
	return (
		<Container className='container-news'>
			<NewsMenu />
			<h1>News</h1>
			<section className='text-center'>
				{showAllCategories()}
                <br />
				{showAllTags()}
			</section>
			<div>{showAllBlogs()}</div>
			<div>{showLoadedBlogs()}</div>
			<div className='text-center pt-5 pb-5'>{loadMoreButton()}</div>
		</Container>
	)
}

export default Blogs
