import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import renderHtml from 'react-render-html'
import { Grid, Button, Image } from 'semantic-ui-react'

const Card = ({ blog }) => {
	const showBlogCategories = blog =>
		blog.categories.map((c, i) => (
			<Link key={i} to={`/categories/${c.slug}`}>
				<Button size='mini' color='blue' outline='true'>
					{c.name}
				</Button>
			</Link>
		))

	const showBlogTags = blog =>
		blog.tags.map((t, i) => (
			<Link key={i} to={`/tags/${t.slug}`}>
				<Button size='mini' color='blue' basic>
					{t.name}
				</Button>
			</Link>
		))

	return (
		<>
			<header className='mt-25'>
				<Link to={`/news/${blog.slug}`}>
					<h3 className='mb-15'>{blog.title}</h3>
				</Link>
			</header>
			<section>
				<p className='p-compact mark mb-15'>
					Written by{' '}
					<Link to={`/profile/${blog.postedBy.username}`}>
						{blog.postedBy.username}
					</Link>{' '}
					| Published {moment(blog.updatedAt).fromNow()}
				</p>
			</section>
			<section className='mb-25'>
				{showBlogCategories(blog)}
				{showBlogTags(blog)}
			</section>
			<Grid className='mb-25'>
				<Grid.Row>
					<Grid.Column width={6}>
						<section>
							<Image
								className=''
								style={{ height: 'auto', width: '100%' }}
								src={`${process.env.REACT_APP_API_URL}/blog/photo/${blog.slug}`}
								alt={blog.title}
							/>
						</section>
					</Grid.Column>
					<Grid.Column width={10}>
						<section>
							<p>{renderHtml(blog.excerpt)}</p>
							<Link to={`/news/${blog.slug}`}>
								<Button color='blue' outline='true'>
									Read more
								</Button>
							</Link>
						</section>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</>
	)
}

export default Card
