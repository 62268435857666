import { Container } from 'semantic-ui-react'

function Other() {
	return (
		<Container className='mb-50 container-main'>
			<div className='page-title'>Other</div>
			<h3>Other Policy Statements made by Genetic Alliance</h3>
			<h1>PDF LINKS</h1>
		</Container>
	)
}

export default Other
