export const STATES_LIST = [
	{ key: 'AL', text: 'Alabama', value: 'alabama' },
	{ key: 'AK', text: 'Alaska', value: 'alaska' },
	{ key: 'AZ', text: 'Arizona', value: 'arizona' },
	{ key: 'AR', text: 'Arkansas', value: 'arkansas' },
	{ key: 'CA', text: 'California', value: 'california' },
	{ key: 'CO', text: 'Colorado', value: 'colorado' },
	{ key: 'CT', text: 'Connecticut', value: 'connecticut' },
	{ key: 'DE', text: 'Delaware', value: 'delaware' },
	{ key: 'FL', text: 'Florida', value: 'florida' },
	{ key: 'GA', text: 'Georgia', value: 'georgia' },
	{ key: 'HI', text: 'Hawaii', value: 'hawaii' },
	{ key: 'ID', text: 'Idaho', value: 'idaho' },
	{ key: 'IL', text: 'Illinois', value: 'illinois' },
	{ key: 'IN', text: 'Indiana', value: 'indiana' },
	{ key: 'IA', text: 'Iowa', value: 'iowa' },
	{ key: 'KS', text: 'Kansas', value: 'kansas' },
	{ key: 'KY', text: 'Kentucky', value: 'kentucky' },
	{ key: 'LA', text: 'Louisiana', value: 'louisiana' },
	{ key: 'ME', text: 'Maine', value: 'maine' },
	{ key: 'MD', text: 'Maryland', value: 'maryland' },
	{ key: 'MA', text: 'Massachusetts', value: 'massachusetts' },
	{ key: 'MI', text: 'Michigan', value: 'michigan' },
	{ key: 'MN', text: 'Minnesota', value: 'minnesota' },
	{ key: 'MS', text: 'Mississippi', value: 'mississippi' },
	{ key: 'MO', text: 'Missouri', value: 'missouri' },
	{ key: 'MT', text: 'Montana', value: 'montana' },
	{ key: 'NE', text: 'Nebraska', value: 'nebraska' },
	{ key: 'NV', text: 'Nevada', value: 'nevada' },
	{ key: 'NH', text: 'New Hampshire', value: 'newHampshire' },
	{ key: 'NJ', text: 'New Jersey', value: 'newJersey' },
	{ key: 'NM', text: 'New Mexico', value: 'newMexico' },
	{ key: 'NY', text: 'New York', value: 'newYork' },
	{ key: 'NC', text: 'North Carolina', value: 'northCarolina' },
	{ key: 'ND', text: 'North Dakota', value: 'northDakota' },
	{ key: 'OH', text: 'Ohio', value: 'ohio' },
	{ key: 'OK', text: 'Oklahoma', value: 'oklahoma' },
	{ key: 'OR', text: 'Oregon', value: 'oregon' },
	{ key: 'PA', text: 'Pennsylvania', value: 'pennsylvania' },
	{ key: 'RI', text: 'Rhode Island', value: 'rhodeIsland' },
	{ key: 'SC', text: 'South Carolina', value: 'southCarolina' },
	{ key: 'SD', text: 'South Dakota', value: 'southDakota' },
	{ key: 'TN', text: 'Tennessee', value: 'tennessee' },
	{ key: 'TX', text: 'Texas', value: 'texas' },
	{ key: 'UT', text: 'Utah', value: 'utah' },
	{ key: 'VT', text: 'Vermont', value: 'vermont' },
	{ key: 'VA', text: 'Virginia', value: 'virginia' },
	{ key: 'WA', text: 'Washington', value: 'washington' },
	{ key: 'WV', text: 'West Virginia', value: 'westVirginia' },
	{ key: 'WI', text: 'Wisconsin', value: 'wisconsin' },
	{ key: 'WY', text: 'Wyoming', value: 'wyoming' },
	{ key: 'Other', text: 'Other', value: 'other'}
]
