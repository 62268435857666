import { useState, useEffect } from 'react'
import { getCookie, isAuth } from '../../actions/auth'
import { getCategories } from '../../actions/category'
import { getTags } from '../../actions/tag'
import { singleBlog, updateBlog } from '../../actions/blog'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {
	QuillModules,
	QuillFormats,
	capitalizeFirstLetter,
} from '../helpers/HelperFunctions'
import { useNavigate } from 'react-router-dom'
import { Form, Grid, Input } from 'semantic-ui-react'
import { toast } from 'react-toastify'

const BlogUpdate = ({ match }) => {
	const navigate = useNavigate()
	const token = getCookie('token')
	const [body, setBody] = useState('')

	const [categories, setCategories] = useState([])
	const [tags, setTags] = useState([])

	const [checked, setChecked] = useState([]) // categories
	const [checkedTag, setCheckedTag] = useState([]) // tags

	const [values, setValues] = useState({
		title: '',
		body: '',
		formData: '',
	})

	const { title, formData } = values

	const initBlog = () => {
		singleBlog(match.params.slug).then(data => {
			if (data.error) {
				toast.error('Error loading blog')
			} else {
				setBody(data.body)
				setValues({ ...values, title: data.title })
				setCategoriesArray(data.categories)
				setTagsArray(data.tags)
			}
		})
	}

	const setCategoriesArray = blogCategories => {
		let ca = []
		blogCategories.map((c, i) => ca.push(c._id))
		setChecked(ca)
	}

	const setTagsArray = blogTags => {
		let ta = []
		blogTags.map((t, i) => ta.push(t._id))
		setCheckedTag(ta)
	}

	const initCategories = () => {
		getCategories().then(data => {
			if (data.error) {
				toast.error('Error loading categories')
			} else {
				setCategories(data)
			}
		})
	}

	const initTags = () => {
		getTags().then(data => {
			if (data.error) {
				toast.error('Error loading tags')
			} else {
				setTags(data)
			}
		})
	}

	useEffect(() => {
		setValues({ ...values, formData: new FormData() })
		initBlog()
		initCategories()
		initTags()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [match])

	const handleChange = name => e => {
		// console.log(e.target.value)
		const value = name === 'photo' ? e.target.files[0] : e.target.value
		formData.set(name, value)
		setValues({ ...values, [name]: value, formData })
	}

	const handleToggle = c => () => {
		setValues({ ...values })
		// return the first index or -1
		const clickedCategory = checked.indexOf(c)
		const all = [...checked]

		if (clickedCategory === -1) {
			all.push(c)
		} else {
			all.splice(clickedCategory, 1)
		}
		// console.log(all);
		setChecked(all)
		formData.set('categories', all)
	}

	const handleTagsToggle = t => () => {
		setValues({ ...values })
		// return the first index or -1
		const clickedTag = checkedTag.indexOf(t)
		const all = [...checkedTag]

		if (clickedTag === -1) {
			all.push(t)
		} else {
			all.splice(clickedTag, 1)
		}
		// console.log(all);
		setCheckedTag(all)
		formData.set('tags', all)
	}

	const handleBody = e => {
		setBody(e)
		formData && formData.set('body', e)
	}

	const editBlog = e => {
		e.preventDefault()
		updateBlog(formData, token, match.params.slug).then(data => {
			if (data.error) {
				toast.error('Error updating blog')
			} else {
				toast.success(`Blog titled "${data.title}" is updated`)
				setValues({ ...values, title: '' })
				if (isAuth() && isAuth().role === 1) {
					navigate(`/admin/crud/${match.params.slug}`)
				} else if (isAuth() && isAuth().role === 0) {
					navigate(`/user/crud/${match.params.slug}`)
				}
			}
		})
	}

	const showCategories = () => {
		return (
			categories &&
			categories.map((c, i) => (
				<li key={i} className=''>
					<input
						id={c.name}
						onChange={handleToggle(c._id)}
						type='checkbox'
						className='mr-8'
						checked={checked.indexOf(c._id) === -1 ? false : true}
					/>
					<label className='form-check-label' htmlFor={c.name}>
						{c.name}
					</label>
				</li>
			))
		)
	}

	const showTags = () => {
		return (
			tags &&
			tags.map((t, i) => (
				<li key={i} className=''>
					<input
						id={t.name}
						onChange={handleTagsToggle(t._id)}
						type='checkbox'
						className='mr-8'
						checked={checkedTag.indexOf(t._id) === -1 ? false : true}
					/>
					<label className='form-check-label' htmlFor={t.name}>
						{t.name}
					</label>
				</li>
			))
		)
	}

	const updateBlogForm = () => (
		<Form onSubmit={editBlog}>
			<Form.Field
				required
				label='Title'
				control={Input}
				id='title'
				value={capitalizeFirstLetter(title)}
				onChange={handleChange('title')}
				type='text'
				className='form-control'
				placeholder='Type Title Name'
			/>

			<ReactQuill
				modules={QuillModules}
				formats={QuillFormats}
				value={body}
				placeholder='Write something amazing...'
				onChange={handleBody}
			/>

			<Form.Button className='mt-15 mb-15' color='blue'>
				Update
			</Form.Button>
		</Form>
	)

	return (
		<Grid>
			<Grid.Row>
				<Grid.Column width={11}>{updateBlogForm()}</Grid.Column>
				<Grid.Column width={5}>
					<h3>Featured image</h3>
					<hr />
					{body && (
						<img
							src={`${process.env.REACT_APP_API_URL}/blog/photo/${match.params.slug}`}
							alt={title}
							style={{ width: '100%' }}
						/>
					)}
					<div className='p-small mb-5 mt-15'>Max size: 1mb</div>
					<label className='ui blue button mb-25'>
						Upload featured image
						<input
							onChange={handleChange('photo')}
							type='file'
							accept='image/*'
							hidden
						/>
					</label>
					<div>
						<h3>Categories</h3>
						<hr />
						<ul className='list' style={{ maxHeight: '200px', overflowY: 'auto' }}>
							{showCategories()}
						</ul>
					</div>

					<div>
						<h3>Tags</h3>
						<hr />
						<ul className='list' style={{ maxHeight: '200px', overflowY: 'auto' }}>
							{showTags()}
						</ul>
					</div>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	)
}

export default BlogUpdate
