import { Container, Grid, GridColumn } from 'semantic-ui-react'
import ShareSocialMedia from './../../ShareSocialMedia'

function Manifesto() {
    const shareUrl = 'http://www.geneticalliance.org/about/manifesto'
	return (
		<Container className='container-main'>
			<h1>Genetic Alliance: A Manifesto.</h1>
			<Grid columns={1} padded>
				<GridColumn>
                    <hr /> 
                    <ShareSocialMedia shareUrl={shareUrl} />
                    <div>
                        <br />
                        <p>
                            Say: We are a loving person. Say: We will be a loving person. We are a network and we are here. We, fleet-footed allies. We, living along a continuum of vulnerability. Our path is lined with mirrors. Our signposts reflect deeper than the skin. Let us draw you up a society, or else look to some horizon. Language is a prism. So much governance balances on the body. There is no Smithsonian of the Self. Let us walk with you through the nucleotide wilds. Let us be transparent. Let us be the right amount of innovative or disruptive. Let us empower the I and embolden the Thou.
                        </p>
                        <p>
                            Let us be lost enough to develop the strength and dexterity of survivalists. Let us be resolute enough to help. Let us be us, individuals and families and communities. Let us tell a story. Let us have a conversation. Let us speak together as the autumn cools into winter. Let us understand a collectivity of kindness. And let us be present.
                        </p>
                    </div>
				</GridColumn>
			</Grid>
		</Container>
	)
}

export default Manifesto