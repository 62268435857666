import { Link } from 'react-router-dom'
import { Container, Grid } from 'semantic-ui-react'

function Registries() {
	return (
		<Container className='container-main'>
			<h1>Registries</h1>
			<Grid columns={1}>
				<Grid.Row>
					<Grid.Column>
						<p class='mt-15'>
							Launching a registry can feel daunting but is manageable with proper
							planning and the right platform.
						</p>
						<p>
							<strong>
								<Link className='link-blue-800' to='/registries/bootcamp'>Registry Bootcamp</Link>
							</strong>
							, a program offered by Genetic Alliance, is designed to help anyone
							launch a successful registry. This course will provide a deep dive into
							the world of registries and will guide communities through the steps
							necessary to begin designing and developing their own registries. Learn
							more (nav to bootcamp page).
						</p>
						<p>
							<strong>
								<Link className='link-blue-800' to='/registries/promise-for-engaging-everyone-responsibly'>
									The Promise for Engaging Everyone Responsibly (PEER)
								</Link>
							</strong>{' '}
							is Genetic Alliance’s unique, award-winning technology solution for
							collecting health data directly from individuals. The platform --
							informed by 25 years of working with advocacy communities to build
							registries -- is designed to accommodate any data sharing and privacy
							preferences, giving individual users complete control over how their data
							is shared and used for research. Learn more (nav to PEER page).
						</p>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Container>
	)
}

export default Registries
