import { Container, Grid, Image, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'


function NETSTargets() {
    return (
        <>
            <div>
                <Container className='container-main'>
                    <h1>NETS: Therapeutic Targets</h1>
			        <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>What are therapeutic targets and why are they important in drug development?</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>
                                Therapeutic targets are molecular sites for therapeutic intervention. By altering the expression or function of therapeutic targets using drugs or other therapies, researchers hope to either cure the underlying cause of a disorder or relieve disease symptoms.
                            </p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Testimonials: How we did it</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            {/* <div className='inline-flex mb-15'>
                                <Image src='/icons/guide.png' className='icon-width-16px mr-8' style={{ height: '16px', top: '8px' }} />
                                <p>
                                    <a
                                        href='http://sagebase.net/research/case_studies.php'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Sage Bionetworks: Case Studies
                                    </a>
                                </p>
                            </div>
                            <div className='inline-flex mb-15'>
                                <Image src='/icons/guide.png' className='icon-width-16px mr-8' style={{ height: '16px', top: '8px' }} />
                                <p>
                                    <a
                                        href='http://sagebase.net/research/resources.php'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Sage Bionetworks: Network Biology Resources
                                    </a>
                                </p>
                            </div>
                            <div className='inline-flex'>
                                <Image src='/icons/multimedia.png' className='icon-width-16px mr-8' style={{ height: '16px', top: '8px' }} />
                                <p>
                                    <a
                                        href='https://www.als.net/Podcasts/Item/?id=8'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        ALS TDI: Testing Therapeutics
                                    </a>
                                </p>
                            </div> */}
                            <p>Content not yet available for this section.</p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Relevant Publications</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <div className='inline-flex mb-15'>
                                <Image src='/icons/worksheet.png' className='icon-width-16px mr-8' style={{ height: '16px', top: '8px' }} />
                                <p>
                                    <a
                                        href='https://www.ncbi.nlm.nih.gov/pubmed/19741703'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Sage Bionetworks: Molecular Networks as Sensors and Drivers of Common Human Diseases
                                    </a>
                                </p>
                            </div>
                            <div className='inline-flex mb-15'>
                                <Image src='/icons/worksheet.png' className='icon-width-16px mr-8' style={{ height: '16px', top: '8px' }} />
                                <p>
                                    <a
                                        href='http://www.ncbi.nlm.nih.gov/pubmed/19337271'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Sage Bionetworks: A Network View of Disease and Compound Screening
                                    </a>
                                </p>
                            </div>
                            <div className='inline-flex'>
                                <Image src='/icons/worksheet.png' className='icon-width-16px mr-8' style={{ height: '16px', top: '8px' }} />
                                <p>
                                    <a
                                        href='https://www.ncbi.nlm.nih.gov/pubmed/20407459'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Sage Bionetworks: The Need for Precompetitive Integrative Bionetwork Disease Model Building
                                    </a>
                                </p>
                            </div>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Related Tools</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <a
                                href='https://www.wikipathways.org/index.php/WikiPathways'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />WikiPathways: Pathways for the People</Button>
                            </a>
                            {/* <a
                                href='http://sagebase.net/research/tools.php'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/toolkit.png' className='icon-width-16px' />Sage Bionetworks: Key Driver Analysis Tool</Button>
                            </a> */}
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Link to='/resources-and-information/nets/full-view' rel='noopener noreferrer' className='link-button inline-block'><Button className='button-orange-500 '><Image src='/icons/link-internal-white.png' className='icon-width-16px' style={{ transform: 'scaleX(-1)' }} />Back to NETS Map full view</Button></Link>
                </Container>
            </div>
        </>
    )
}

export default NETSTargets