import { useState, useEffect } from 'react'
import {
	Container,
	Grid,
	GridColumn,
	Form,
	Input,
	Icon,
	Button,
} from 'semantic-ui-react'
import { Link, useNavigate } from 'react-router-dom'
import { isAuth, preSignUp } from 'actions/auth'
import { capitalizeEachWord } from 'components/helpers/HelperFunctions'

const SignUp = () => {
	const navigate = useNavigate()
	const [values, setValues] = useState({
		name: 'jay',
		email: 'jaynguyen0101@gmail.com',
		password: '123456',
		error: '',
		loading: false,
		message: '',
		showForm: true,
	})

	const { name, email, password, error, loading, message, showForm } = values

	useEffect(() => {
		isAuth() && navigate('/')
	}, [navigate])

	const handleSubmit = e => {
		e.preventDefault()
		setValues({ ...values, loading: true, error: false })
		const user = { name, email, password }
		preSignUp(user).then(data => {
			if (data.error) {
				setValues({ ...values, error: data.error, loading: false })
			} else {
				setValues({
					...values,
					name: '',
					email: '',
					password: '',
					error: '',
					loading: false,
					message: data.message,
					showForm: false,
				})
			}
		})
	}

	const handleChange = name => e => {
		setValues({ ...values, error: false, [name]: e.target.value })
	}
	const showError = () => (error ? <p className='error'>{error}</p> : '')
	const showMessage = () => (message ? <p className=''>{message}</p> : '')

	const signUpForm = () => {
		return (
			<Form onSubmit={handleSubmit}>
				<Form.Field
					required
					label='Name'
					control={Input}
					id='name'
					value={capitalizeEachWord(name)}
					onChange={handleChange('name')}
					type='text'
					className='form-control'
					placeholder='Type your name'
				/>
				<Form.Field
					required
					label='Email'
					control={Input}
					id='email'
					value={email}
					onChange={handleChange('email')}
					type='email'
					className='form-control'
					placeholder='Type your email'
				/>
				<Form.Field
					required
					label='Password'
					id='password'
					control={Input}
					value={password}
					onChange={handleChange('password')}
					type='password'
					className='form-control'
					placeholder='Type your password'
				/>
				<Button className='button-neutral-100 mt-25 mb-15'>
					{loading ? (
						<Icon name='circle notched' className='center' loading />
					) : (
						'Sign Up'
					)}
				</Button>
			</Form>
		)
	}

	return (
		<Container className='container-main'>
			<h1 className='text-center'>Sign Up</h1>
			<p className='text-center mb-40'>
				Already have an account? <Link to='/sign-in'>Sign In</Link>
			</p>
			<Grid centered columns={2}>
				<GridColumn>
					{showForm && signUpForm()}
					{showError()}
					{showMessage()}
				</GridColumn>
			</Grid>
		</Container>
	)
}

export default SignUp
