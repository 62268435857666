import React, { useState } from 'react'
import { Grid, Image } from 'semantic-ui-react'
import ModalVideo from 'react-modal-video'
import '../../../../../node_modules/react-modal-video/scss/modal-video.scss'
import './CrossConditionBurdenOfDiseaseCommunity.css'

function CrossConditionBurdenOfDiseaseCommunity() {
    const [isOpen1, setOpen1] = useState(false)

    return (
        <>
            <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' /> 
            <div className='cross-condition-community-subtitle-paragraph'>
                <p className='subtitle max-width-680px'>
                    We seek to give advocacy organizations and communities the data they need to assure all interested parties that the disease burden in their community can be measured and communicated.
                </p>
            </div>
            <hr className='rule-blue-800 mt-80-60-40 mb-80-60-40' />
            <Grid columns={2}>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                    <h3>Overview</h3>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                    <p>
                        Cross Condition Burden of Disease Study will assess the individual and population-level burden within and across diseases. In partnership with QualityMetric, a leader in the development of validated instruments for PROs and COAs (Clinical Outcomes Assessment), the study will include their{' '} 
                        <a
                            href='https://www.qualitymetric.com/health-surveys/the-sf-36v2-health-survey/'
                            target='_blank'
                            rel='noreferrer'>
                            SF-36v2® validated instrument
                        </a>
                        . It is a short-form health questionnaire that measures each of the following eight health domains: physical functioning, role-physical, bodily pain, general health, vitality, social functioning, role-emotional, and mental health. Each health domain score contributes to the Physical Component Summary (PCS) and Mental Component Summary (MCS) scores.
                    </p>
                </Grid.Column>
            </Grid>
            <hr className='rule-blue-800 mt-80-60-40 mb-80-60-40' />
            <Grid columns={2}>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                    <h3>Why is this study important to your disease community?</h3>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                    <p>
                        More and more entities, including regulators, biopharma companies, clinicians, policymakers, payors, and researchers, recognize the value and importance of patient reported outcomes (PRO) and require them as essential in developing treatments and interventions that improve people’s lives. Recently, the FDA has elevated PROs as necessary in product validation in clinical trials and post-trials. 
                    </p>
                </Grid.Column>
            </Grid>
            <hr className='rule-blue-800 mt-80-60-40 mb-80-60-40' />
            <h3 className='mb-80-60-40'>Insights from this study will equip you with the information you need</h3>
            <Grid columns={4}>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                    <Image src='/icons/lightbulb-orange.png' style={{ width: '70px' }} />
                    <p className='mt-25'>Educate researchers on the burden experienced by those with the disease</p>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                    <Image src='/icons/clipboard-orange.png' style={{ width: '70px' }} />
                    <p className='mt-25'>Drive researchers to find treatments that focus on critical areas of burden</p>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                    <Image src='/icons/connections-orange.png' style={{ width: '70px' }} />
                    <p className='mt-25'>Identify unmet clinical needs and improper care management for your communities</p>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                    <Image src='/icons/checklist-orange.png' style={{ width: '70px' }} />
                    <p className='mt-25'>Form the basis of your ability to elevate your own custom, disease-specific surveys to the rigorous level required in clinical trials</p>
                </Grid.Column>
            </Grid>
            <hr className='rule-blue-800 mt-80-60-40 mb-80-60-40' />
            <Grid columns={2}>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                    <h3>We are covering all of the costs for you to do a burden of disease study in your community</h3>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                    <p>
                        This study will be administered via Luna, the platform Genetic Alliance uses so that each person can decide how and with whom to share their data. <u>It will be free to any nonprofit to use Luna’s platform and the instrument.</u> QualityMetric will provide access to the instrument and scoring.
                    </p>
                    <p>
                        Each community can use insights from this study as they wish. In addition, we will analyze that data across conditions to offer it to policy makers across the globe as a testimony to the immense burden of disease.
                    </p>
                </Grid.Column>
            </Grid>
            <hr className='rule-blue-800 mt-80-60-40 mb-80-60-40' />
            <Grid columns={2}>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                    <h3>Webinar about the study</h3>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                    <p>
                        In this webinar, Mark Kosinski, the Chief Commercial Science Officer at QualityMetric, describes the SF-36v2 instrument.
                    </p>
                </Grid.Column>
            </Grid>
            <ModalVideo channel='youtube' autoplay isOpen={isOpen1} videoId="cEJntySERlw" onClose={() => setOpen1(false)} />
            <Image src="/images/Mark-Kosisnski-video.jpg" className='modal-video-mb-30 cursor-pointer mt-80-60-40 mb-80-60-40 center' size='big' onClick={()=> setOpen1(true)}/>
            <hr className='rule-blue-800 mt-80-60-40 mb-80-60-40' />
            <Grid columns={2}>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                    <h3>Contact us</h3>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                    <p>
                        Contact us for more information about how to deploy this study to your members. It is simple and free for nonprofit organizations and grassroots communities.
                    </p>
                </Grid.Column>
            </Grid>
            {/* alternative Contact Us section versions */}
            {/* <div className='cross-condition-burden-of-disease-community-mq1 mb-80-60-40'>
                <div className='cross-condition-burden-of-disease-community-mq2'>
                    <h3 style={{ maxWidth: '864px' }}>
                    Contact Us
                    </h3>
                    <p style={{ maxWidth: '864px' }}>
                        Contact us for more information about how to deploy this study to your members. It is simple and free for nonprofit organizations and grassroots communities.
                    </p>
                </div>
            </div> */}
            {/* <div className='computer-display-flex computer-justify-center'>
                <h3>
                    Contact Us
                </h3>
                <p className='max-width-864px'>
                    We seek to give advocacy organizations and communities the data they need to assure all interested parties that the disease burden in their community can be measured and communicated.
                </p>
            </div> */}

        </>
    )
}

export default CrossConditionBurdenOfDiseaseCommunity