import React from 'react'
import { Container, Grid, GridColumn } from 'semantic-ui-react'

function NewsletterArchive() {
	return (
		<>
			<Container className='container-main'>
				<h1>Newsletter Archive</h1>
				<Grid columns={1} padded>
					<GridColumn>
						<p>
							From 2004 to 2010, Genetic Alliance released a newsletter titled{' '}
							<em>Advocacy in Genetics</em> in order to spread information related to
							Alliance initiatives and genetic disease advocacy. Prior to this, the
							Alliance of Genetic Support Groups published and distributed a number of
							issues of its newsletter, <em>Alliance Alert, </em>in order to promote
							awareness and to facilitate education. The <em>Alliance Alert </em>ran
							from 1989 to 1998 as a printed newsletter, the digitized issues of which
							are provided below.
						</p>

						<p>
							Contained in this archive is a collection of all newsletter issues
							published by Genetic Alliance and the Alliance of Genetic Support Groups.
						</p>

						<div>
							<h3>Advocacy in Genetics Newsletters (2010-2004)</h3>

							<p id='newsletter2010'>2010: Summer/Fall | Spring</p>

							<p>
								<strong>2009:</strong> Fall | Spring
							</p>

							<p>
								<strong>2008:</strong>Fall/Winter | Summer | Spring
							</p>

							<p>
								<strong>2007:</strong>{' '}
								<a href='/geneticalliance.org/sites/default/files/Archives/2007FallWinter.pdf'>
									Fall/Winter
								</a>{' '}
								|{' '}
								<a href='/geneticalliance.org/sites/default/files/Archives/2007Summer.pdf'>
									Summer
								</a>{' '}
								|{' '}
								<a href='/geneticalliance.org/sites/default/files/Archives/2007Spring.pdf'>
									Spring
								</a>
							</p>

							<p>
								<strong>2006:</strong>{' '}
								<a href='/geneticalliance.org/sites/default/files/Archives/2007FallWinter_0.pdf'>
									November
								</a>{' '}
								|{' '}
								<a href='/geneticalliance.org/sites/default/files/Archives/2006October.pdf'>
									October
								</a>{' '}
								|{' '}
								<a href='/geneticalliance.org/sites/default/files/Archives/2006Bonus.pdf'>
									Bonus Issue
								</a>
							</p>

							<p>
								<strong>2005:</strong>{' '}
								<a href='/geneticalliance.org/sites/default/files/Archives/2005Summer.pdf'>
									Summer
								</a>{' '}
								|{' '}
								<a href='/geneticalliance.org/sites/default/files/Archives/2005Spring.pdf'>
									Spring
								</a>
							</p>

							<p>
								<strong>2004:</strong>{' '}
								<a href='/geneticalliance.org/sites/default/files/Archives/2004Winter.pdf'>
									Winter
								</a>
							</p>

							<hr />
							<h3>Alliance Alert (1998-1989)</h3>

							<p id='newsletter1998'>
								1998:
								<a href='/geneticalliance.org/sites/default/files/Archives/199801alliancealert.pdf'>
									January
								</a>{' '}
								|{' '}
								<a href='/geneticalliance.org/sites/default/files/Archives/199804alliancealert.pdf'>
									April
								</a>{' '}
								|{' '}
								<a href='/geneticalliance.org/sites/default/files/Archives/199805alliancealert.pdf'>
									May
								</a>{' '}
								|{' '}
								<a href='/geneticalliance.org/sites/default/files/Archives/199806alliancealert.pdf'>
									June
								</a>{' '}
								|{' '}
								<a href='/sites/default/files/newsletterarchive/199806alliancealert.pdf'>
									July
								</a>{' '}
								|{' '}
								<a href='/geneticalliance.org/sites/default/files/Archives/199808alliancealert.pdf'>
									August
								</a>
							</p>

							<p id='newsletter1997'>
								1997:{' '}
								<a href='/geneticalliance.org/sites/default/files/Archives/199701alliancealert.pdf'>
									January
								</a>{' '}
								|{' '}
								<a href='/geneticalliance.org/sites/default/files/Archives/199703alliancealert.pdf'>
									March
								</a>{' '}
								|{' '}
								<a href='/geneticalliance.org/sites/default/files/Archives/199705alliancealert.pdf'>
									May
								</a>{' '}
								|{' '}
								<a href='/geneticalliance.org/sites/default/files/Archives/199706alliancealert.pdf'>
									June
								</a>{' '}
								|{' '}
								<a href='/geneticalliance.org/sites/default/files/Archives/199708alliancealert.pdf'>
									August
								</a>{' '}
								|{' '}
								<a href='/geneticalliance.org/sites/default/files/Archives/199709alliancealert.pdf'>
									September
								</a>{' '}
								|{' '}
								<a href='/geneticalliance.org/sites/default/files/Archives/199710alliancealert.pdf'>
									October
								</a>{' '}
								|{' '}
								<a href='/geneticalliance.org/sites/default/files/Archives/199711alliancealert.pdf'>
									November
								</a>{' '}
								|{' '}
								<a href='/geneticalliance.org/sites/default/files/Archives/199712alliancealert.pdf'>
									December
								</a>
							</p>
						</div>

						<p id='newsletter1996'>
							1996:{' '}
							<a href='/geneticalliance.org/sites/default/files/Archives/199601alliancealert.pdf'>
								January
							</a>{' '}
							|{' '}
							<a href='/geneticalliance.org/sites/default/files/Archives/199604alliancealert.pdf'>
								April
							</a>{' '}
							|{' '}
							<a href='/geneticalliance.org/sites/default/files/Archives/199606alliancealert.pdf'>
								June
							</a>{' '}
							|{' '}
							<a href='/geneticalliance.org/sites/default/files/Archives/199609alliancealert.pdf'>
								September
							</a>{' '}
							|{' '}
							<a href='/geneticalliance.org/sites/default/files/Archives/199611alliancealert.pdf'>
								November
							</a>{' '}
							|{' '}
							<a href='/geneticalliance.org/sites/default/files/Archives/199612alliancealert.pdf'>
								December
							</a>
						</p>

						<p id='newsletter1995'>
							1995:{' '}
							<a href='/geneticalliance.org/sites/default/files/Archives/199509alliancealert.pdf'>
								September
							</a>{' '}
							|{' '}
							<a href='/geneticalliance.org/sites/default/files/Archives/199510alliancealert.pdf'>
								October
							</a>{' '}
							|{' '}
							<a href='/geneticalliance.org/sites/default/files/Archives/199511alliancealert.pdf'>
								November
							</a>
						</p>

						<p id='newsletter1994'>
							1994:{' '}
							<a href='/geneticalliance.org/sites/default/files/Archives/199403alliancealert.pdf'>
								March
							</a>{' '}
							|{' '}
							<a href='/geneticalliance.org/sites/default/files/Archives/199405alliancealert.pdf'>
								May
							</a>{' '}
							|{' '}
							<a href='/geneticalliance.org/sites/default/files/Archives/199406alliancealert.pdf'>
								June
							</a>{' '}
							|{' '}
							<a href='/geneticalliance.org/sites/default/files/Archives/199408alliancealert.pdf'>
								August
							</a>{' '}
							|{' '}
							<a href='/geneticalliance.org/sites/default/files/Archives/199409alliancealert.pdf'>
								September
							</a>
						</p>

						<div>
							<p id='newsletter1993'>
								1993:{' '}
								<a href='/geneticalliance.org/sites/default/files/Archives/199304alliancealert.pdf'>
									April
								</a>{' '}
								|{' '}
								<a href='/geneticalliance.org/sites/default/files/Archives/199305alliancealert.pdf'>
									May
								</a>{' '}
								|{' '}
								<a href='/geneticalliance.org/sites/default/files/Archives/199306alliancealert.pdf'>
									June
								</a>
							</p>
						</div>

						<div>
							<p id='newsletter1992'>
								1992:{' '}
								<a href='/geneticalliance.org/sites/default/files/Archives/199206alliancealert.pdf'>
									June
								</a>{' '}
								|{' '}
								<a href='/geneticalliance.org/sites/default/files/Archives/199208alliancealert.pdf'>
									August
								</a>{' '}
								|{' '}
								<a href='/geneticalliance.org/sites/default/files/Archives/199211alliancealert.pdf'>
									November
								</a>
							</p>
						</div>

						<p>
							<strong>1991:</strong>{' '}
							<a href='/geneticalliance.org/sites/default/files/Archives/199107alliancealert.pdf'>
								July
							</a>{' '}
							|{' '}
							<a href='/geneticalliance.org/sites/default/files/Archives/199110alliancealert.pdf'>
								October
							</a>{' '}
							|{' '}
							<a href='/geneticalliance.org/sites/default/files/Archives/1991newsletter.pdf'>
								November
							</a>
						</p>

						<p>
							<strong>1990:</strong>{' '}
							<a href='/geneticalliance.org/sites/default/files/Archives/199001alliancealert.pdf'>
								January
							</a>{' '}
							|{' '}
							<a href='/geneticalliance.org/sites/default/files/Archives/199008alliancealert.pdf'>
								August
							</a>{' '}
							|{' '}
							<a href='/geneticalliance.org/sites/default/files/Archives/199009alliancealert.pdf'>
								September
							</a>
						</p>

						<p id='newsletter1980s'>
							1980s:{' '}
							<a href='/geneticalliance.org/sites/default/files/Archives/1985newsletter.pdf'>
								Winter 1985
							</a>{' '}
							|{' '}
							<a href='/geneticalliance.org/sites/default/files/Archives/1986newsletter.pdf'>
								Winter 1986
							</a>{' '}
							|{' '}
							<a href='/geneticalliance.org/sites/default/files/Archives/1988newsletter.pdf'>
								Spring 1988
							</a>{' '}
							|{' '}
							<a href='/geneticalliance.org/sites/default/files/Archives/198904alliancealert.pdf'>
								April 1989
							</a>{' '}
							|{' '}
							<a href='/geneticalliance.org/sites/default/files/Archives/1989sumalliancealert.pdf'>
								Summer 1989
							</a>{' '}
							|{' '}
							<a href='/geneticalliance.org/sites/default/files/Archives/198909alliancealert.pdf'>
								September 1989{' '}
							</a>
							|{' '}
							<a href='/geneticalliance.org/sites/default/files/Archives/198911alliancealert.pdf'>
								November 1989
							</a>{' '}
							|{' '}
							<a href='/geneticalliance.org/sites/default/files/Archives/198912alliancealert.pdf'>
								December 1989
							</a>
						</p>
					</GridColumn>
				</Grid>
			</Container>
		</>
	)
}

export default NewsletterArchive
