import { Image, Grid } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import './Staff.css'

function StaffFillContent({ data }) {
	const staff = data.filter((item) => item.menu === 'Staff');
	if (staff && Array.isArray(staff) && staff.length > 0) {
		staff.sort((a, b) => a.orderBy - b.orderBy)
	}
	return (
		<div>
			<h1 className='mb-80-35-15'>Staff</h1>
			<Grid columns={4} className='computer-only'>
				{staff.map((person, index) => (
					<Grid.Column key={index}>
						<Link to={`/about/staff/${person.linkURL}`}>
							<Image src={`/staff/${person.imageURL}`} className='staff-icon-size' />
						</Link>
						<p className='p-compact  mt-8 mb-0'>
							<b>{person.name}</b>
						</p>
						<p className='p-compact mb-50-30-10 mt-0'>
							{person.title}
						</p>
					</Grid.Column>
				))}
			</Grid>
			<Grid columns={3} className='tablet-only'>
				{staff.map((person, index) => (
					<Grid.Column key={index}>
						<Link to={`/about/staff/${person.linkURL}`}>
							<Image src={`/staff/${person.imageURL}`} className='staff-icon-size' />
						</Link>
						<p className='p-compact  mt-8 mb-0'>
							<b>{person.name}</b>
						</p>
						<p className='p-compact mb-50-30-10 mt-0'>
							{person.title}
						</p>
					</Grid.Column>
				))}
			</Grid>
			<Grid columns={2} className='mobile-only'>
				{staff.map((person, index) => (
					<Grid.Column key={index}>
						<Link to={`/about/staff/${person.linkURL}`}>
							<Image src={`/staff/${person.imageURL}`} className='staff-icon-size' />
						</Link>
						<p className='p-compact  mt-8 mb-0'>
							<b>{person.name}</b>
						</p>
						<p className='p-compact mb-50-30-10 mt-0'>
							{person.title}
						</p>
					</Grid.Column>
				))}
			</Grid>
		</div>
	)
}

export default StaffFillContent
