import { capitalizeEachWord } from 'components/helpers/HelperFunctions'
import { useState, useEffect } from 'react'
import { Button, Form, Input } from 'semantic-ui-react'
import { getCookie } from '../../actions/auth'
import { create, getTags, removeTag } from '../../actions/tag'
import { toast } from 'react-toastify';

const Tag = () => {
	const [values, setValues] = useState({
		name: '',
		error: false,
		success: false,
		tags: [],
		removed: false,
		reload: false,
	})

	const { name, tags, removed, reload } = values
	const token = getCookie('token')

	useEffect(() => {
		loadTags()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reload])

	const loadTags = () => {
		getTags().then(data => {
			if (data.error) {
				console.log(data.error)
			} else {
				setValues({ ...values, tags: data })
			}
		})
	}

	const showTags = () => {
		return tags.map((tag, index) => {
			return (
				<Button
					onDoubleClick={() => deleteConfirm(tag.slug)}
					title='Double click to delete'
					key={index}
                    basic
					color='blue'
					className='mt-15 mr-15'>
					{tag.name}
				</Button>
			)
		})
	}

	const deleteConfirm = slug => {
		let answer = window.confirm('Are you sure you want to delete this tag?')
		if (answer) {
			deleteTag(slug)
		}
	}

	const deleteTag = slug => {
		// console.log('delete', slug);
		removeTag(slug, token).then(data => {
			if (data.error) {
				console.log(data.error)
			} else {
				setValues({
					...values,
					error: false,
					success: false,
					name: '',
					removed: !removed,
					reload: !reload,
				})
                toast.success(`Tag is removed`)
			}
		})
	}

	const handleChange = e => {
		setValues({
			...values,
			name: e.target.value,
			error: false,
			success: false,
			removed: '',
		})
	}

    const clickSubmit = e => {
		e.preventDefault()
		// console.log('create category', name);
		create({ name }, token).then(data => {
			if (data.error) {
				setValues({ ...values, error: data.error, success: false })
                toast.error('Tag already exist');
			} else {
				setValues({
					...values,
					error: false,
					success: true,
					name: '',
					removed: '',
					reload: !reload,
				})
                toast.success('Tag is created');
			}
		})
	}

	const newTagFom = () => (
		<Form onSubmit={clickSubmit}>
            <h3 className='mb-25'>Tags</h3>
            <Form.Field
				label='Tag Name'
				control={Input}
				id='name'
				value={capitalizeEachWord(name)}
				onChange={handleChange}
				type='text'
				className='form-control'
				placeholder='Type Tag Name'
			/>
			<Button className='button-neutral-100 mt-25 mb-50'>Create Tag</Button>
		</Form>
	)

	return (
		<>
			{newTagFom()}
			<p className='p-small'>Double-click on tag name to delete</p>
			{showTags()}
		</>
	)
}

export default Tag
