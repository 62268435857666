import { Container, Grid, GridColumn, Image } from 'semantic-ui-react'
// import { Link } from 'react-router-dom'

function FitzGerald() {
	return (
		<>
			<Container className='container-main'>
				<h1>IRB Roster</h1>
				<Grid columns={1} padded>
					<GridColumn>
						<Image
							alt='Kevin FitzGerald'
							src='/images/IRB/FitzGeraldHeadshot.jpeg'
							size='small'
							floated='left'
							rounded
						/>
						<h2>Kevin FitzGerald</h2>
						<h3>IRB Board Member</h3>
						<p>
							Kevin T. FitzGerald, S.J., Ph.D., Ph.D., is the Dr. David Lauler Chair of
							Catholic Health Care Ethics in the Center for Clinical Bioethics at
							Georgetown University. He is also an Associate Professor in the
							Department of Oncology at the Georgetown University Medical Center. He
							received a Ph.D. in molecular genetics, and a Ph.D. in bioethics, from
							Georgetown University. His research efforts focus on the investigation of
							abnormal gene expression in cancer, and on ethical issues in biomedical
							research. He has published both scientific and ethical articles in
							peer-reviewed journals, books, and in the popular press.
						</p>

						<p>
							Fr. FitzGerald has given presentations nationally and internationally,
							and often been interviewed by the news media, on such topics as human
							genetic engineering, cloning, stem cell research, and personalized
							medicine. He is a founding member of Do No Harm, a member of the ethics
							committee for the March of Dimes, and a member of the American
							Association for the Advancement of Science Program of Dialogue on
							Science, Ethics, and Religion. In addition, he served until March 2009 as
							a member of the DHHS Secretary’s Advisory Committee on Genetics, Health,
							and Society.
						</p>
					</GridColumn>
				</Grid>
			</Container>
		</>
	)
}

export default FitzGerald
