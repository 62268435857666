// import { Link } from 'react-router-dom'
import { Container, Grid, Image, Button } from 'semantic-ui-react'

function Events() {
	return (
		<Container className='container-main'>
			<Grid columns={1} padded>
				<Grid.Column>
					<Grid stackable>
						<Grid.Row columns={2}>
							<Grid.Column>
								<h1>Events</h1>
							</Grid.Column>
							<Grid.Column>
								<p className='subtitle'>
									Genetic Alliance partners with many stakeholders, hosting events that explore a wide variety of topics in the field of genetics and health. These initiatives lead to groundbreaking research and reform efforts. As a result of our events, the public learns more about advances in genetics and genomics, how it affects them as consumers or patients, and how to get involved.
								</p>
							</Grid.Column>
						</Grid.Row>
					</Grid>
					<hr className='mt-80-60-40 mb-80-60-40 rule-orange-500' />
					<h3 className='mb-80-60-40'>Featured Events</h3>
					<Grid columns={2} stackable className='mb-40'>
						<Grid.Row>
							<Grid.Column>
								<Image 
									src='/images/events/GA_ResearchSymposium_QR.png'
									size='large'
									centered
								/>
								<div className='text-center mt-15'>
									<a	className='link-blue-800'
										href='/images/events/GA_ResearchSymposium_QR.png'
										target='_blank'
										rel='noreferrer'
									>
										View Details
									</a>
								</div>
							</Grid.Column>
							<Grid.Column>
								<h3>Genetic Alliance Research Symposium</h3>
								<p><i>July 7, 2022</i></p>
								<p>Disease advocacy and community groups present a poster, slides, pitch deck, or other findings related to their registry, biobank, studies, or other research endeavors.</p>
								<p><b>Guest Speaker:</b> Deb Thompson,<i> Social Choice and Decentralization Possibilities Offered from Technology</i></p>
								<p><b>Presenters:</b></p>
								<p>xCures - Tim Stuhlmiller, <i>Platform Introduction and CNS Cancer Real World Data Summary</i></p>
								<p>G-PACT - Amanda Brown, <i>Delayed Gastric Emptying and Symptom Variations</i></p>
								<p>PXE International - Catt Troutman, <i>PXE International Registry Report - Preliminary Findings</i></p>
								<p>Bobby Jones Chiari & Syringomyella Foundation - Kaitlyn Esposito, <i>Transforming Research Paradigms Through Partnerships</i></p>
								<p>KCNT1 Epilepsy Foundation - Sarah Drislane, <i>KCNT1 Patient Registry Results</i></p>
								<p>Timothy Syndrome Alliance - Andy Golden, <i>Lessons Learned from Our Natural History Study</i></p>
								<p>Colorectal Cancer Canada - Maria El Bizri, <i>Environmental Scan of Biomarker Testing in Canada</i></p>
								<p>National Psoriasis Foundation - George Gondo, <i>Patient-Centered Research at the NPF</i></p>
							</Grid.Column>
						</Grid.Row>
					</Grid>
					<div className='center'>
						<a
							href='https://youtu.be/Fg6Hr_VHO5I'
							rel='noreferrer'
							target='_blank'
							className='link-button display-contents'
						>
							<Button
								className='button-orange-500 center '
							>
								Watch the recording&nbsp;&nbsp;
								<Image src='/icons/arrow-right-white.svg' />
							</Button>
						</a>
					</div>
					<hr className='mt-80-60-40 mb-80-60-40 rule-blue-300' />
					<div>
						<div className='mb-40'>
							<h3 className='mb-80-60-40'>Events Archive</h3>
							<h4>2018</h4>
							<p>
								September 20–21, 2018 -{' '}
								<a className='link-blue-800'
									href='https://drive.google.com/file/d/1NjsiZlZIg-3Cj0Hq0UEhsYC8sZHJK9nB/view?usp=sharing'
									rel='noreferrer'
									target='_blank'>
									People Driven: Empowering Advocacy- and Community-Initiated Research Workshop
								</a>
							</p>
						</div>
						<div className='mb-40'>
							<h4>2017</h4>
							<p>
								June 5-6, 2017 -{' '}
								<a className='link-blue-800'
									href='https://www.babysfirsttest.org/newborn-screening/beyond-the-bloodspot-summit'
									rel='noreferrer'
									target='_blank'>
									Beyond the Bloodspot: Education and Engagement Summit
								</a>
							</p>
							<p>
								March 9-10, 2017 -{' '}
								<a className='link-blue-800'
									href='https://drive.google.com/file/d/1U424Wytw9lZntiRKmB-GkVuhFm0zNqhv/view?usp=sharing'
									rel='noreferrer'
									target='_blank'>
									Co-Creating a Healthy Future
								</a>
							</p>
						</div>
						<div className='mb-40'>
							<h4>2013</h4>
							<p>
								August 20, 2013 -{' '}
								<a className='link-blue-800'
									href='https://drive.google.com/file/d/1nMvC5Hw9WnAyKFdGRlwamGA_22xO7m41/view?usp=sharing'
									rel='noreferrer'
									target='_blank'>
									Beyond the Bloodspot Summit
								</a>
							</p>
							<p>
								May 30-31, 2013 -{' '}
								<a className='link-blue-800'
									href='https://drive.google.com/file/d/1rDhZYrfJz4xqnaBh9yyDBXjOq2EXE0PS/view?usp=sharing'
									rel='noreferrer'
									target='_blank'>
									Powerful Patient Data Summit
								</a>
							</p>
						</div>
						<div className='mb-40'>
							<h4>2012</h4>
							<p>
								September 22, 2012 -{' '}
								<a className='link-blue-800'
									href='https://drive.google.com/file/d/1LKCe8YgWbRmyMuy3w-XXtHBV9Bg8Rlhn/view?usp=sharing'
									rel='noreferrer'
									target='_blank'>
									25th Anniversary Celebration
								</a>
							</p>
							<p>
								August 23, 2012 -{' '}
								<a className='link-blue-800'
									href='https://drive.google.com/file/d/1Rt-QNY51QgZVVRs_IWeSF-XgLnvRDjb_/view?usp=sharing'
									rel='noreferrer'
									target='_blank'>
									Registry and BioBank Boot Camp
								</a>
							</p>
							<p>
								March 12, 2012 -{' '}
								<a className='link-blue-800'
									href='https://drive.google.com/file/d/1pw99FYD_SSkn7dNJnE_aW90RQlYCEF6A/view?usp=sharing'
									rel='noreferrer'
									target='_blank'>
									Improving Health Outcomes, Disease Management, And Accountable Care Delivery
								</a>
							</p>
						</div>
						<div className='mb-40'>
							<h4>2011</h4>
							<p>
								August 19, 2011 -{' '}
								<a className='link-blue-800'
									href='https://drive.google.com/file/d/1SSoECJJrpKJgdEdeLqyBlryvwlG9EuCh/view?usp=sharing'
									rel='noreferrer'
									target='_blank'>
									Registry and BioBank Boot Camp
								</a>
							</p>
							<p>
								June 24-26, 2011 -{' '}
								<a className='link-blue-800'
									href='https://drive.google.com/file/d/1HJfFLyJy70DSv9gQtzj8l-cS4Xz55-Wc/view?usp=sharing'
									rel='noreferrer'
									target='_blank'>
									2011 Genetic Alliance Annual Conference
								</a>
							</p>
							<p>
								June 23, 2011 -{' '}
								<a className='link-blue-800'
									href='https://drive.google.com/file/d/14WsZQAfbV79DGnuC1QbohKRpasWf8SZ3/view?usp=sharing'
									rel='noreferrer'
									target='_blank'>
									2011 Genetics Day on the Hill
								</a>
							</p>
							<p>
								June 23, 2011 -{' '}
								<a className='link-blue-800'
									href='https://drive.google.com/file/d/12oc2in-pN5teVU4FWCsQ2NaczPVf3BvG/view?usp=sharing'
									rel='noreferrer'
									target='_blank'>
									2011 Gene Screen
								</a>
							</p>
						</div>
						<div className='mb-40'>
							<h4>2010</h4>
							<p>
								January 10, 2010 -{' '}
								<a className='link-blue-800'
									href='https://drive.google.com/file/d/1mYBGmJ9gDSqYc594UANXDFkiwnC_y22D/view?usp=sharing'
									rel='noreferrer'
									target='_blank'>
									Genetic Alliance Registry and Repository Boot Camp
								</a>
							</p>
						</div>
						<div className='mb-40'>
							<h4>2009</h4>
							<p>
								July 17-19, 2009 -{' '}
								<a className='link-blue-800'
									href='https://drive.google.com/file/d/19NmCaW8sRlaFTWmeVAHVMa3kRI8f4-tC/view?usp=sharing'
									rel='noreferrer'
									target='_blank'>
									2009 Genetic Alliance Annual Conference
								</a>
							</p>
							<p>
								July 16, 2009 -{' '}
								<a className='link-blue-800'
									href='https://drive.google.com/file/d/1fYlNC8zXlo7QlY4j8Ul32V318xnXV-mn/view?usp=sharing'
									rel='noreferrer'
									target='_blank'>
									2009 Genetics Day on the Hill
								</a>
							</p>
							<p>
								July 16, 2009 -{' '}
								<a className='link-blue-800'
									href='https://drive.google.com/file/d/1kDDL3XbVYD6IHMR85IK7BEQ5YgcD1gGH/view?usp=sharing'
									rel='noreferrer'
									target='_blank'>
									2009 Gene Screen
								</a>
							</p>
						</div>
						<div className='mb-40'>
							<h4>2008</h4>
							<p>
								July 11-13, 2008 -{' '}
								<a className='link-blue-800'
									href='https://drive.google.com/file/d/18AKOT4GI19QdD0TZV-WXLa7QuuskTZcO/view?usp=sharing'
									rel='noreferrer'
									target='_blank'>
									2008 Genetic Alliance Annual Conference
								</a>
							</p>
							<p>
								July 10, 2008 -{' '}
								<a className='link-blue-800'
									href='https://drive.google.com/file/d/1VS68uOgAV3OAlwtjAGn5rszvy23X8XwW/view?usp=sharing'
									rel='noreferrer'
									target='_blank'>
									2008 Genetics Day on the Hill
								</a>
							</p>
						</div>
						<div className='mb-40'>
							<h4>2007</h4>
							<p>
								July 27-29, 2007 -{' '}
								<a className='link-blue-800'
									href='https://drive.google.com/file/d/1nf9VXbqUIavfTsvrsg0xNdzBFcc5_Xvn/view?usp=sharing'
									rel='noreferrer'
									target='_blank'>
									2007 Genetic Alliance Annual Conference
								</a>
							</p>
							<p>
								July 26, 2007 -{' '}
								<a className='link-blue-800'
									href='https://drive.google.com/file/d/1i9iFAB79xkSTlDuR6gH4eDZPDyS9m60X/view?usp=sharing'
									rel='noreferrer'
									target='_blank'>
									2007 Genetics Day on the Hill
								</a>
							</p>
							<p>
								July 25, 2007 -{' '}
								<a className='link-blue-800'
									href='https://drive.google.com/file/d/1XXQ8441WUrXoJ1orBaGDONPml6YVWdR-/view?usp=sharing'
									rel='noreferrer'
									target='_blank'>
									2007 Genetic Alliance Gala
								</a>
							</p>
						</div>
						<div className='mb-40'>
							<h4>2006</h4>
							<p>
								September 27, 2006 -{' '}
								<a className='link-blue-800'
									href='https://drive.google.com/file/d/1hXz1Y5HoPn_agQaNTFbZZTA0KVQaRyeW/view?usp=sharing'
									rel='noreferrer'
									target='_blank'>
									20th Anniversary Gala
								</a>
							</p>
							<p>
								September 27, 2006 -{' '}
								<a className='link-blue-800'
									href='https://drive.google.com/file/d/1We8EExiLLC3Zri6A0M2UDGpCCO3RFOVl/view?usp=sharing'
									rel='noreferrer'
									target='_blank'>
									2006 Genetics Day on the Hill
								</a>
							</p>
							<p>
								July 28-30, 2006 -{' '}
								<a className='link-blue-800'
									href='https://drive.google.com/file/d/1md75yBLO_zFN01gVzRPGIuFfQhLVz4n1/view?usp=sharing'
									rel='noreferrer'
									target='_blank'>
									Genetic Alliance Annual Conference
								</a>
							</p>
						</div>
						<div className='mb-40'>
							<h4>2005 - 1983</h4>
							<p>
								July 28-31, 2005 -{' '}
								<a className='link-blue-800'
									href='https://drive.google.com/file/d/1twJ9mHhs6P46iqH6BP4Ln131GL88RMu7/view?usp=sharing'
									rel='noreferrer'
									target='_blank'>
									Genetic Alliance Annual Conference
								</a>
							</p>
							<p>
								July 23-25, 2004 -{' '}
								<a className='link-blue-800'
									href='https://drive.google.com/file/d/1hGwWLr9REtVzd5N1lZlcr_6uihnJHxm9/view?usp=sharing'
									rel='noreferrer'
									target='_blank'>
									Genetic Alliance Annual Conference
								</a>
							</p>
							<p>
								August 1-3, 2003 -{' '}
								<a className='link-blue-800'
									href='https://drive.google.com/file/d/1tnl2c8uPn4M2NPzjGvff9fM2SQOzudVC/view?usp=sharing'
									rel='noreferrer'
									target='_blank'>
									Genetic Alliance Annual Conference
								</a>
							</p>
							<p>
								June 9, 2001 -{' '}
								<a className='link-blue-800'
									href='https://drive.google.com/file/d/1WPjldBEMNpVAfDohDQb1IvrBr9xWPCyE/view?usp=sharing'
									rel='noreferrer'
									target='_blank'>
									People's Genome Celebration
								</a>
							</p>
							<p>
								September 11-13, 1998 -{' '}
								<a className='link-blue-800'
									href='https://drive.google.com/file/d/1Thv5QvzAPrY8Bhs6TtuQw-MxvLYpNC8f/view?usp=sharing'
									rel='noreferrer'
									target='_blank'>
									Forging Genetic Partnerships: Research, Policymakers, and Consumers
								</a>
							</p>
							<p>
								October 12, 1996 -{' '}
								<a className='link-blue-800'
									href='https://drive.google.com/file/d/19XN-SZb2bMuFLWVwdZBawHfNcsoU-_lN/view?usp=sharing'
									rel='noreferrer'
									target='_blank'>
									Tenth Anniversary Gala
								</a>
							</p>
							<p>
								1993 - Media Reporting in the Genetic Age -{' '}
								<a className='link-blue-800'
									href='https://drive.google.com/file/d/18TauCnyCd_7sOG1AqMBlrGp_w5xty0fo/view?usp=sharing'
									rel='noreferrer'
									target='_blank'>
									Conference Proceedings, Points to Consider, A Guide for Consumer Groups
								</a>
							</p>
							<p>
								November 20-21, 1993 -{' '}
								<a className='link-blue-800'
									href='https://drive.google.com/file/d/1DfC99yqvKnjv9YbnMggtprVDboD05Ls5/view?usp=sharing'
									rel='noreferrer'
									target='_blank'>
									Health Care In Flux: How Will Families With Special Needs Fit In?
								</a>
							</p>
							<p>
								November 21-22, 1992 -{' '}
								<a className='link-blue-800'
									href='https://drive.google.com/file/d/1xgw3dmZtmGY5GWyOqX0zufzFhTgh85wZ/view?usp=sharing'
									rel='noreferrer'
									target='_blank'>
									A National Conference on The Empty Pocket Syndrome: How to Get Funds
								</a>
							</p>
							<p>
								October 5, 1991 -{' '}
								<a className='link-blue-800'
									href='https://drive.google.com/file/d/10QBUybSxAyGryUtCdLaQifRV_B8suer9/view?usp=sharing'
									rel='noreferrer'
									target='_blank'>
									International Gathering of Network Support Groups: Satellite Meeting, 8th International Congress of Human Genetics
								</a>
							</p>
							<p>
								March 30-31, and April 1, 1990 -{' '}
								<a className='link-blue-800'
									href='https://drive.google.com/file/d/1m1obrC41I2ShYbN2UmnwR1Mznz6cxfQj/view?usp=sharing'
									rel='noreferrer'
									target='_blank'>
									A National Conference on Peer Support Training
								</a>
							</p>
							<p>
								November 4-5, 1988 -{' '}
								<a className='link-blue-800'
									href='https://drive.google.com/file/d/1f0UT5nEJFNHcBM2HK6iFp6LeoL3F9Fcz/view?usp=sharing'
									rel='noreferrer'
									target='_blank'>
									Empowerment: A Symposium
								</a>
							</p>
							<p>
								June 2-5, 1985 -{' '}
								<a className='link-blue-800'
									href='https://drive.google.com/file/d/19ugvaC54S1KdubrksNadGat_IS9QXZ-v/view?usp=sharing'
									rel='noreferrer'
									target='_blank'>
									Genetics Support Groups: Volunteers and Professionals as Partners
								</a>
							</p>
							<p>
								April 25-26, 1983 -{' '}
								<a className='link-blue-800'
									href='https://drive.google.com/file/d/17ygM3bhhAvzhJQwHMfT4TBPZmGCIdON5/view?usp=sharing'
									rel='noreferrer'
									target='_blank'>
									Genetic Disorders &amp; Birth Defects in Families and Society: Towards Interdisciplinary Understanding
								</a>
							</p>
						</div>
					</div>
					

				</Grid.Column>
			</Grid>
		</Container>
	)
}

export default Events
