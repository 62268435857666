import { useState} from 'react'
import { Container, Form, Grid, GridColumn, Button, Image } from 'semantic-ui-react'
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import emailjs from 'emailjs-com'

function InternshipApplication() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [resumeError, setResumeError] = useState(false)
    const [coverLetterError, setCoverLetterError] = useState(false)
    const [writingSampleError, setWritingSampleError] = useState(false)
    const [referenceLetter1Error, setReferenceLetter1Error] = useState(false)
    const [referenceLetter2Error, setReferenceLetter2Error] = useState(false)
    const [sent, setSent] = useState(false);
    const isResumeOversize = () => {
        const file = document.getElementById("resume").files[0];
        if (file?.size > 5242880) {
            setResumeError(true)
            return true
        }
        setCoverLetterError(false)
        return false
    }
    const isCoverLetterOversize = () => {
        const file = document.getElementById("coverLetter").files[0];
        if (file?.size > 5242880) {
            setCoverLetterError(true)
            return true
        }
        setCoverLetterError(false)
        return false
    }
    const isWritingSampleOversize = () => {
        const file = document.getElementById("writingSample").files[0];
        if (file?.size > 5242880) {
            setWritingSampleError(true)
            return true
        }
        setWritingSampleError(false)
        return false
    }
    const isReferenceLetter1Oversize = () => {
        const file = document.getElementById("referenceLetter1").files[0];
        if (file?.size > 5242880) {
            setReferenceLetter1Error(true)
            return true
        }
        setReferenceLetter1Error(false)
        return false
    }
    const isReferenceLetter2Oversize = () => {
        const file = document.getElementById("referenceLetter2").files[0];
        if (file?.size > 5242880) {
            setReferenceLetter2Error(true)
            return true

        }
        setReferenceLetter2Error(false)
        return false
    }
    const sendEmail = (formData, e) => {
        isResumeOversize()
        isCoverLetterOversize()
        isWritingSampleOversize()
        isReferenceLetter1Oversize()
        isReferenceLetter2Oversize()
        if (isResumeOversize() === false && isCoverLetterOversize() === false && isWritingSampleOversize() === false && isReferenceLetter1Oversize() === false && isReferenceLetter2Oversize() === false) {
            emailjs
                .sendForm(
                    'service_u4yjgnd',
                    'template_fd4n8ke',
                    e.target,
                    'user_RvBcfNskpg2k1v812rots'
                )
                .then(
                    result => {
                        setSent(true)
                        e.target.reset()
                    },
                    error => {
                        // console.log(error.text)
                    }
                )
        }
	}
	return (
		<Container className='container-main'>
			<h1 className='color-tertiary'>Internship Application</h1>
            <hr className='rule-orange-500' style={{marginTop: '80px', marginBottom: '80px'}} />
			{!sent && <Grid columns={1} padded>
				<GridColumn className='mw-700 center'>
					<Form method="post" onSubmit={handleSubmit(sendEmail)} encType="multipart/form-data">
                        <Form.Field className='mb-40'>
                            <label className='field-title'>FIRST AND LAST NAME<span className='error-color'>*</span></label>
                            <input className='p-compact inner-border-form' placeholder='First Last' type='text' name='fullName' {...register("fullName", { required: 'Name is required.' })} />
                        </Form.Field>
                        <ErrorMessage errors={errors} name="fullName" render={({ message }) => <p className='error'>{message}</p>} />

                        <Form.Field className='mb-40'>
                            <label className='field-title'>PERSONAL EMAIL<span className='error-color'>*</span></label>
                            <input className='p-compact inner-border-form' placeholder='Email Address' type="email" name='email' {...register("email", { required: 'Email is required.' })} />
                        </Form.Field>
                        <ErrorMessage errors={errors} name="email" render={({ message }) => <p className='error'>{message}</p>} />

                        <Form.Field className='mb-40'>
                            <label className='field-title'>PHONE NUMBER<span className='error-color'>*</span></label>
                            <input className='p-compact inner-border-form' type="text" placeholder='###-###-####' name='phone'{...register("phone", { required: 'Phone is required.' })}/>
                        </Form.Field>
                        <ErrorMessage errors={errors} name="phone" render={({ message }) => <p className='error'>{message}</p>} />

                        <Form.Field className='mb-40'>
                            <label className='field-title'>ADDRESS #1<span className='error-color'>*</span></label>
                            <input className='p-compact inner-border-form' type='text' placeholder='Address' name='address1' {...register("address1", { required: 'Address is required.' })}/>
                        </Form.Field>
                        <ErrorMessage errors={errors} name="address1" render={({ message }) => <p className='error'>{message}</p>} />

                        <Form.Field className='mb-40'>
                            <label className='field-title'>ADDRESS #2</label>
                            <input className='p-compact inner-border-form' type='text' placeholder='Address' name='address2' {...register("address2")}/>
                        </Form.Field>

                        <Form.Group widths='equal' className='mb-40'>
                            <Form.Field>
                                <label className='field-title'>CITY<span className='error-color'>*</span></label>
                                <input className='p-compact inner-border-form' type='text' placeholder='City' name='city' {...register("city", { required: 'City is required.' })}/>
                            </Form.Field>
                            <Form.Field>
                                <label className='field-title'>STATE<span className='error-color'>*</span></label>
                                <input className='p-compact inner-border-form' type='text' placeholder='State' name='state' {...register("state", { required: 'State is required.' })}/>
                            </Form.Field>
                            <Form.Field>
                                <label className='field-title'>ZIP CODE<span className='error-color'>*</span></label>
                                <input className='p-compact inner-border-form' type='text' placeholder='Zip' name='zipcode' {...register("zipcode", { required: 'Zipcode is required.' })}/>
                            </Form.Field>
                        </Form.Group>
                        <ErrorMessage errors={errors} name="city" render={({ message }) => <p className='error'>{message}</p>} />             
                        <ErrorMessage errors={errors} name="state" render={({ message }) => <p className='error'>{message}</p>} />
                        <ErrorMessage errors={errors} name="zipcode" render={({ message }) => <p className='error'>{message}</p>} />
                        <Form.Field className='mb-40'>
                            <label className='field-title'>EDUCATIONAL INSTITUTION NAME<span className='error-color'>*</span></label>
                            <input className='p-compact inner-border-form' type='text' placeholder='Educational Institution Name' name='institution' {...register("institution", { required: 'Educational Institution Name is required.' })}/>
                        </Form.Field>
                        <ErrorMessage errors={errors} name="institution" render={({ message }) => <p className='error'>{message}</p>} />                        
                        <Form.Field className='mb-40'>
                            <label className='field-title'>MAJOR<span className='error-color'>*</span></label>
                            <input className='p-compact inner-border-form' type='text' placeholder='Major' name='major' {...register("major", { required: 'Major is required.' })}/>
                        </Form.Field>
                        <ErrorMessage errors={errors} name="major" render={({ message }) => <p className='error'>{message}</p>} />

                        <Form.Field className='mb-40'>
                            <label className='field-title'>CURRENT GRADE LEVEL<span className='error-color'>*</span></label>
                            <select className='p-compact inner-border-form' required name="grade" {...register("grade", { required: 'Grade is required.' })}>
                                <option value="">Please select an option...</option>
                                <option value="Associates Program">Associates Program</option>
                                <option value="Bachelors Program">Bachelors Program</option>
                                <option value="Graduate Program">Graduate Program</option>
                            </select>
                        </Form.Field>
                        <ErrorMessage errors={errors} name="grade" render={({ message }) => <p className='error'>{message}</p>} />

                        <Form.Field className='mb-40'>
                            <label className='field-title'>APPROXIMATE START DATE FOR YOUR INTERNSHIP<span className='error-color'>*</span></label>
                            <input className='p-compact inner-border-form' type="date" placeholder="Start Date" {...register("startDate", { required: 'Start Date is required.' })}/>
                        </Form.Field>
                        <ErrorMessage errors={errors} name="startDate" render={({ message }) => <p className='error'>{message}</p>} />

                        <Form.Field style={{marginBottom: '70px'}}>
                            <label className='field-title'>HOW DID YOU LEARN OF OUR INTERNSHIP PROGRAM?<span className='error-color'>*</span></label>
                            <textarea className='p-compact inner-border-form' type='text' placeholder='Message' name='internshipProgram' {...register("internshipProgram", { required: 'This field is required.' })}/>
                        </Form.Field>
                        <ErrorMessage errors={errors} name="internshipProgram" render={({ message }) => <p className='error'>{message}</p>} />

                        <h3 className='color-tertiary' style={{marginBottom: '30px'}}>Requirements for Consideration</h3>
                        <p className='p-compact'><b>One (1) page letter of interest or cover letter</b></p>
                        <p className='p-compact'>
                            Please share how working with Genetic Alliance will advance your professional and academic goals. In particular, describe what skills you offer and those you wish to gain during your internship.
                        </p>
                        <Form.Field>
                            <label className='field-title'>UPLOAD YOUR COVER LETTER<span className='error-color'>*</span></label>
                            <input className='p-compact inner-border-form' type='file' required name='coverLetter' id='coverLetter' {...register("coverLetter")}
                            accept="application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
                        </Form.Field>
                        {coverLetterError && <p className='error'>The size of Cover Letter is too big, please select a file less than 5MB.</p>}
                        <hr className='mt-30 mb-30 rule-blue-300' />
                        <Form.Field>
                            <p className='p-compact'><b>Resume</b></p>
                            <label className='field-title'>UPLOAD YOUR RESUME<span className='error-color'>*</span></label>
                            <input className='p-compact inner-border-form' type='file' required name='resume' id='resume' {...register("resume")}
                            accept="application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
                        </Form.Field>
                        {resumeError && <p className='error'>The size of Resume is too big, please select a file less than 5MB.</p>}
                        <hr className='mt-30 mb-30 rule-blue-300' />
                        <p className='p-compact'><b>3-5 page writing sample</b></p>
                        <p className='p-compact'>
                            Please do not submit a sample that was the product of a group’s work. If you select a writing sample that is more than five pages, please only upload a 5-page excerpt of the paper. 
                        </p>
                        <Form.Field>
                            <label className='field-title'>UPLOAD YOUR WRITING SAMPLE<span className='error-color'>*</span></label>
                            <input className='p-compact inner-border-form' type='file' required name='writingSample' id='writingSample' {...register("writingSample")}
                            accept="application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
                        </Form.Field>
                        {writingSampleError && <p className='error'>The size of Writing Sample is too big, please select a file less than 5MB.</p>}
                        <hr className='mt-30 mb-30 rule-blue-300' />
                        <p className='p-compact'><b>References/Letters of Recommendations</b></p>
                        <p className='p-compact'>
                            Two letters of recommendation from professors or previous employers. Applicants may submit letters with the application materials, or recommenders may send them separately. Recommenders may submit the letters of recommendation to: internship@geneticalliance.org. 
                        </p>
                        <Form.Field>
                            <label className='field-title'>UPLOAD YOUR REFERENCE LETTER 1<span className='error-color'>*</span></label>
                            <input className='p-compact inner-border-form' type='file' required name='referenceLetter1' id='referenceLetter1' {...register("referenceLetter1")} 
                            accept="application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
                        </Form.Field>
                        {referenceLetter1Error && <p className='error'>The size of Reference Letter 1 is too big, please select a file less than 5MB.</p>}
                        <Form.Field>
                            <label className='field-title'>UPLOAD YOUR REFERENCE LETTER 2<span className='error-color'>*</span></label>
                            <input className='p-compact inner-border-form' type='file' required name='referenceLetter2' id='referenceLetter2' {...register("referenceLetter2")} 
                            accept="application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
                        </Form.Field>
                        {referenceLetter2Error && <p className='error'>The size of Reference Letter 2 is too big, please select a file less than 5MB.</p>}
                        <hr className='mt-30 mb-30 rule-blue-300' />
                        <div>
                            <Button className='button-orange-500 center' type='submit' style={{ width: '100%' }}>Submit &nbsp;<Image src='/icons/arrow-right-white.svg'/></Button>
                        </div>
                    </Form>
				</GridColumn>
			</Grid>}
            {sent && <p>We have received your application. Thank you for your interest in the Genetic Alliance internship. </p>}
		</Container>
	)
}
export default InternshipApplication
