import { Container, Grid, Image, Button } from 'semantic-ui-react'
import { Link, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { progressIndicator } from '../../content/HelperFunction'
import ReactHtmlParser from 'react-html-parser'


function TrainingAndEducationFillContent({ data }) {

    console.log('EngagementServicesFillContent data', data)
    const { pathname, hash, key } = useLocation()
    useEffect(() => {
        // if not a hash link, scroll to top
        if (hash === '') {
            window.scrollTo(0, 0)
        }
        // else scroll to id
        else {
            setTimeout(() => {
                const id = hash.replace('#', '')
                const element = document.getElementById(id)
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' })
                }
            }, 0)
        }
    }, [pathname, hash, key])

    useEffect(() => {
        progressIndicator()
    }, [])

    if (!data) {
        return null
    }
    const bodyData = data.filter(item => item.link === '/our-services/training-and-education' && item.type === 'normal')
    const bodyDataSpecial = data.filter(item => item.link === '/our-services/training-and-education' && item.type === 'special' && item.header === 'Research Training')
    console.log('bodyDataSpecial', bodyDataSpecial)
    const listBodyContent = []
    if (bodyData && Array.isArray(bodyData) && bodyData.length > 0) {
        bodyData.sort((a, b) => a.index - b.index)
        bodyData.forEach((item, index) => {
            listBodyContent.push(
                <>
                    <div id={item.id} className='mb-25'><Image src={item.imageSrc} /></div>
                    <Grid columns={2} stackable>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3 className='our-services mb-25'>{ReactHtmlParser(item.header)}</h3>
                            <p className='subtitle'>{ReactHtmlParser(item.headerSub)}</p>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8} className='mt-15'>
                            {ReactHtmlParser(item.content)}
                            {item.content2 && item.content2.length > 0 && ReactHtmlParser(item.content2)}
                        </Grid.Column>
                    </Grid>
                </>
            )
            if (index < bodyData.length - 1) {
                listBodyContent.push(<hr className='rule-blue-300 mt-80-40 mb-80-40' />)
            }
        })
    } else {
        return null
    }

    return (
        <>
            <Container>
                <hr className='rule-orange-500 mt-80-40 mb-80-40' />
                {listBodyContent}
                <hr className='rule-blue-300 mt-80-40 mb-80-40' />
                <div className='mb-25'><Image src={bodyDataSpecial[0].imageSrc} /></div>
                <Grid columns={2} stackable>
                    <Grid.Column mobile={16} tablet={16} computer={8}>
                        <h3 className='our-services mb-25'>{bodyDataSpecial[0].header}</h3>
                        <p className='subtitle'>{ReactHtmlParser(bodyDataSpecial[0].headerSub)}</p>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={8} className='mt-15'>
                        <p>
                            {ReactHtmlParser(bodyDataSpecial[0].content1)}{' '}
                            <Link
                                to='/registries/bootcamp'
                                rel='noopener noreferrer'
                            >
                                Registry Bootcamp
                            </Link>
                            {' '}{ReactHtmlParser(bodyDataSpecial[0].content2)}{' '}
                            <Link
                                to='/biobank'
                                rel='noopener noreferrer'
                            >
                                BioBank
                            </Link>
                            , the{' '}
                            <Link
                                to='/irb'
                                rel='noopener noreferrer'
                            >
                                IRB
                            </Link>
                            , and the{' '}
                            <Link
                                to='/registries/promise-for-engaging-everyone-responsibly'
                                rel='noopener noreferrer'
                            >
                                Registry
                            </Link>
                            . {ReactHtmlParser(bodyDataSpecial[0].content3)}</p>
                        <div>
                            <Link
                                to='/registries/bootcamp'
                                rel='noopener noreferrer'
                                className='link-button'>
                                <Button className='button-neutral-100 mt-40 mb-40' style={{ margin: '0' }}>
                                    <p style={{ margin: '0 auto' }}>Get started with registrybootcamp.org</p> &nbsp;&nbsp;
                                    <Image
                                        className='link-internal-icon'
                                        src='/icons/link-internal-blue.png'
                                    />
                                </Button>
                            </Link>
                        </div>
                        <p>{ReactHtmlParser(bodyDataSpecial[0].content4)}</p>
                    </Grid.Column>
                </Grid>
            </Container>
        </>
    )
}

export default TrainingAndEducationFillContent
