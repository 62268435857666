import { Container, Grid, Button } from 'semantic-ui-react'
import ReactHtmlParser from 'react-html-parser'

function DiseaseInfoSearchFillContent({ data }) {
	console.log('DiseaseInfoSearchFillContent data: ', data)
	if (!data) {
		return null
	}

	const buildContent = []
	const datas = data.filter(item => item.link === '/disease-infosearch')
	console.log('DiseaseInfoSearchFillContent datas: ', datas)
	if (datas && Array.isArray(datas) && datas.length > 0) {
		datas.sort((a, b) => a.index - b.index)
		datas.forEach((item, index) => {
			buildContent.push(
				<Grid.Row key={index}>
					<Grid.Column>
						{item.sizeHeader === 1 ? <h1>{item.header}</h1> : item.sizeHeader === 2 ? <h2>{item.header}</h2> : <h3>{item.header}</h3>}
						{ReactHtmlParser(item.content)}
						{item.haveButton ? (
							<div className='text-center mb-50'>
								<Button>
									<a
										href={item.buttonLink}
										target='_blank'
										rel='noreferrer'
										style={{ color: 'rgba(0,0,0, 0.9)' }}>
										{item.buttonText}
									</a>
								</Button>
							</div>) : null}
					</Grid.Column>
				</Grid.Row>
			)
		})
	}
	return (
		<Container className='container-main'>
			{buildContent}
		</Container>
	)
}

export default DiseaseInfoSearchFillContent
