import { Container, Grid, Embed } from 'semantic-ui-react'
import ReactHtmlParser from 'react-html-parser'
import { Link, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { progressIndicator } from '../../content/HelperFunction'

function NewsFillContent({ data }) {

	const { pathname, hash, key } = useLocation()
	useEffect(() => {
		// if not a hash link, scroll to top
		if (hash === '') {
			window.scrollTo(0, 0)
		}
		// else scroll to id
		else {
			setTimeout(() => {
				const id = hash.replace('#', '')
				const element = document.getElementById(id)
				if (element) {
					element.scrollIntoView({ behavior: 'smooth' })
				}
			}, 0)
		}
	}, [pathname, hash, key])

	useEffect(() => {
		progressIndicator()
	}, [])

	const datas = data.filter((item) => item.menu === 'News');

	const news = []

	if (datas && Array.isArray(datas)) {
		datas.sort((a, b) => b.year - a.year || b.dateNumber - a.dateNumber)
	}

	const groupedData = datas.reduce((grouped, item) => {
		const { year } = item;
		if (!grouped.has(year)) {
			grouped.set(year, []);
		}
		grouped.get(year).push(item);
		return grouped;
	}, new Map());

	console.log(groupedData);

	if (groupedData && groupedData.size > 0) {
		groupedData.forEach((value, key) => (
			news.push(
				<>
					<h2 key={key} id={key}>{key}</h2>
					{
						value.map(item => (
							// console.log(item),
							item.useLink ?
								<p>
									{item.dateString} -{' '}
									<Link className='link-blue-800' to={item.linkTo}>
										{ReactHtmlParser(item.content)}
									</Link>
									{item.Embed ? <Embed src={item.linkTo} width='560' /> : null}
								</p>
								:
								<>
									{ReactHtmlParser(item.content)}
									{item.conponent && item.conponent === 'Embed' ? <Embed src={item.linkTo} width='560' /> : null}
								</>
							// <p>
							// 	{item.dateString} -{' '}
							// 	<a className='link-blue-800'
							// 		href={item.linkTo}
							// 		rel='noreferrer'
							// 		target='_blank'>
							// 		{ReactHtmlParser(item.content)}
							// 	</a>
							// </p>
						))
					}
				</>
			)
		))
	}

	return (
		<>
			<Container className='container-main'>
				<h1>News</h1>
				<Grid columns={1} padded>
					<Grid.Row>
						<Grid.Column>
							<p>
								See what others have to say about Genetic Alliance in this list of news
								articles, videos, talks, and more from external sources around the
								world.{' '}
							</p>
							<hr />
							{news}
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Container>
		</>
	)
}

export default NewsFillContent
