import { Container, Grid, GridColumn } from 'semantic-ui-react'
import ShareSocialMedia from './../../ShareSocialMedia'

function MandateForQualityGeneticServices() {
    const shareUrl = 'http://www.geneticalliance.org/about/mandate-for-quality-genetic-services'
	return (
		<Container className='container-main'>
			<h1>Mandate for Quality Genetic Services</h1>
			<Grid columns={1} padded>
				<GridColumn>
                    <hr /> 
                    <ShareSocialMedia shareUrl={shareUrl} />
                    <div>
                        <p>
                        <b>Access to quality genetic services is critical to comprehensive healthcare.</b>
                        </p>
                        <h2>
                            It is essential that:
                        </h2>
                        <ul className='list-space list-right'>
                            <li>
                                Individuals and families partner with their healthcare providers to identify needs, develop and monitor treatment plans, and manage their genetic condition.
                            </li>
                            <li>
                                Genetic information, resources, and services are integrated in home-based, community-based, and hospital care for individuals across the life span.
                            </li>
                            <li>Referrals to support groups and resources are offered at regular office visits.</li>
                            <li>
                                Healthcare providers refer individuals to appropriate specialists, as needed, including those outside of their health insurance plan.
                            </li>
                            <li>
                                Providers and payers consider the psychosocial, as well as the medical, effects of a genetic condition—on both the individual and his or her family—at each stage of life.
                            </li>
                            <li>Healthcare insurance plans reimburse diagnosis and treatment for all genetic conditions.</li>
                            <li>
                                Quality resources are available to assist individuals and their families in understanding family health history, signs/symptoms, and screening/testing options along with their implications, diagnosis, treatment, and long-term follow-up, if needed.
                            </li>
                            <li>Healthcare providers with experience in genetic services are available to all individuals.</li>
                            <li>
                                Providers, payers, and employers have policies and procedures to ensure the appropriate use of genetic information and comply with GINA where applicable.
                            </li>
                            <li>
                                Information about genetic conditions is provided to individuals and families in a culturally competent manner, which may include: language, educational level, and appropriate media.
                            </li>
                            <li>
                                Information about genetic research and clinical trials is available to affected individuals and integrated into clinical practice when appropriate.
                            </li>
                        </ul>
                        <br />
                        <p>
                            Approved by the Genetic Alliance Council, November 2013
                        </p>
                    </div>
				</GridColumn>
			</Grid>
		</Container>
	)
}

export default MandateForQualityGeneticServices