import { Container, Grid } from 'semantic-ui-react'
import ReactHtmlParser from 'react-html-parser'


function ListPolicy(props) {
    const { items } = props
    // console.log('ListPolicy', items)
    // console.log('check', items && Array.isArray(items) && items.length > 0)
    const listItems = []
    let haveSubtitle = false
    if (items && Array.isArray(items) && items.length > 0) {
        if (items[0].subtitle) {
            haveSubtitle = true
        }
        items.forEach((item, index) => {
            // console.log('item', item)
            listItems.push(
                <div key={index + 1}>
                    <p id={item.index + '-' + item.dateNumber} className='field-title'>{item.header}</p>
                    <a href={item.href}
                        target='_blank'
                        rel='noreferrer'>
                        {item.aContent}
                    </a>
                    {item.content1 ? ReactHtmlParser(item.content1) : null}
                    <hr className='rule-blue-200 mt-25 mb-25' />
                </div>)
        })
    }
    // console.log('listItems', listItems)
    return (
        haveSubtitle ?
            <Container className='container-mobile'>
                <Grid columns={3}>
                    <Grid.Column className='subtitle' mobile={16} tablet={16} computer={7}>
                        Genetic Alliance leads the charge to free data. From our early work in the Free the Data campaign, to our implementation of data sharing in collaboration with our platform partner Luna, we have changed the landscape for good.
                    </Grid.Column>
                    {/* <Grid.Column className='computer-only' mobile={0} tablet={0} computer={1}></Grid.Column> */}
                    <Grid.Column mobile={16} tablet={16} computer={8}>
                        {listItems}
                    </Grid.Column>
                </Grid>
            </Container>
            :
            <Container className='container-mobile'>
                <Grid columns={2}>
                    <Grid.Column className='computer-only' />
                    <Grid.Column mobile={16} tablet={16} computer={8}>
                        {listItems}
                    </Grid.Column>
                </Grid>
            </Container>
    )
}

export default ListPolicy
