import { useState, useEffect } from 'react'
import { Container } from 'semantic-ui-react'
import { singleTag } from 'actions/tag'
import NewsMenu from '../user/NewsMenu'
import { toast } from 'react-toastify'
import Card from 'components/blog/Card'


const SingleTag = ({ match }) => {
    const {slug} = match.params
    const [tag, setTag] = useState('')
    const [blogs, setBlogs] = useState([])

    useEffect(() => {
        singleTag(slug).then(data => {
            if (data.error) {
                console.log(data.error)
                toast.error('Tag not found')
            } else {
                setTag(data.tag)
                setBlogs(data.blogs)
            }
        })
    }, [slug])

    return (
        <Container className='container-news'>
        <NewsMenu />
        <h1>{tag.name}</h1>
        {blogs.map((blog, i) => (
            <Card blog={blog} key={i} />
        ))}
    </Container>
    )
}

export default SingleTag