import { Grid } from 'semantic-ui-react'
import ReactHtmlParser from 'react-html-parser'



function IHopeGeneticHealthResourcesFillContent(props) {
    const data = props.data
    console.log('IHopeGeneticHealthResourcesFillContent data', data)
    if (!data) {
        return null
    }
    const datas = data.filter(item => item.link === '/ihope-genetic-health/resources')
    const listDatas = []
    if (datas && Array.isArray(datas)) {
		datas.sort((a, b) => a.index - b.index)
        datas.forEach(item => {
            listDatas.push(
                <>
                    <Grid key={item.id} columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3 id={item.id}>{item.header}</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            {ReactHtmlParser(item.content)}
                        </Grid.Column>
                    </Grid>
                    <hr className='rule-blue-300 mt-40 mb-40' />
                </>)
        })
	}

    return (
        <>
            <div className='mb-80-60-40'>
                <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
                {listDatas}
            </div>
        </>
    )
}

export default IHopeGeneticHealthResourcesFillContent
