import { Container, Grid, Image } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import './NETSNeighborhoodView.css'

function NETSNeighborhoodView() {
	const [fullScreen, setFullScreen] = useState(false)

    return (
        <>
            <div>
                <Container className='container-main' id='netsNeighborhoodView'>
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                            <h1>Navigating the Ecosystem of Translational Science (NETS)</h1>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                            <p className='subtitle'>
                                Click concepts on the map below to view information and resources. Let us know if you are aware of others we should add.
                            </p>
                        </Grid.Column>
                    </Grid>
			        <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
                    <div className='computer-only neighborhood-view-computer'>
                        <nav id="neighborhoodViewComputer" style={{background: `url('/images/resources-and-information/nets/NETS-neighborhood-view.png')`}}>
                            <ul>
                                <Link to="/resources-and-information/nets/biorepositories"><li class="menu-1" title="Data repositories are information warehouses where large amounts of data from multiple sources are stored. Examples of the types of data that can be stored in data repositories include scientific publications, research data, or clinical information."></li></Link>
                                <Link to="/resources-and-information/nets/repositories"><li class="menu-2" title="Biorepositories are places for collecting and storing biological samples (also called biospecimen) such as blood, urine, tumor, or other tissue. The type of biological samples stored in biorepositories will vary depending upon the type of disease and/or nature of research."></li></Link>
                                <Link to="/resources-and-information/nets/pathophysiology"><li class="menu-3" title="Disease Pathophysiology is the study of how disease alters the functions of cells, organs, organ systems, and how these changes in turn affect the entire body."></li></Link>
                                <Link to="/resources-and-information/nets/targets"><li class="menu-4" title="Therapeutic Targets are molecular sites for therapeutic intervention. The hope is that altering the expression or function of therapeutic targets using drugs or other therapies will either cure the underlying cause of the disorder or ameliorate disease symptoms."></li></Link>

                                <Link to="/resources-and-information/nets/hts"><li class="menu-5" title="A HTS assay is an assay that has been designed to be compatible with high throughput screening (HTS) systems. With a HTS system and HTS compatible assays, tens of thousands of compounds can be tested rapidly to determine whether any of them have the desired effects."></li></Link>
                                <Link to="/resources-and-information/nets/system"><li class="menu-6" title="A HTS system a robotic process that conducts biochemical, genetic or pharmacological tests on large numbers of candidate compounds to identify active compounds, antibodies or genes."></li></Link>
                                <Link to="/resources-and-information/nets/hits"><li class="menu-7" title="A ‘hit’ is a compound that produce the desired effect in HTS system and therefore qualifies for additional investigation into its potential therapeutic effects."></li></Link>
                                <Link to="/resources-and-information/nets/approved"><li class="menu-8" title="An approved compound is a compound that is already approved for another use in humans. Occasionally existing medications can be repurposed to treat disease other than the condition they were initially designed for."></li></Link>

                                <Link to="/resources-and-information/nets/lead"><li class="menu-9" title="A lead compound is a chemical structure or a collection of molecules that demonstrate the desired response against a specific target and therefore has potential therapeutic use. Often, they are identified through using from compound libraries using a HTS system."></li></Link>
                                <Link to="/resources-and-information/nets/compound"><li class="menu-10" title="A compound for preclinical and clinical studies is a lead compound that has been optimized using medicinal chemistry to reduce the off-target effects and improve its interaction with the target molecule."></li></Link>

                                <Link to="/resources-and-information/nets/ind"><li class="menu-11" title="An investigational new drug (IND) application is an application that must be submitted to the FDA to obtain permission to begin a clinical trial. An IND application is submitted by the clinical trial sponsor and must include documentation demonstrating that the compound has proven safe in animal testing and includes animal pharmacology and toxicology studies. It will also contain manufacturing information, clinical protocols for the proposed clinical trial, and investigator information. This report must be submitted to the FDA before human testing may begin. "></li></Link>
                                <Link to="/resources-and-information/nets/nda"><li class="menu-12" title="NDAs and BLAs are FDA applications required for the medical use of a substance. According to the FDA, a Biologic License Application (BLA) is a request for permission to introduce a biologic product, a medical product from a natural source, into interstate commerce. The document includes applicant information, product manufacturing information, pre-clinical studies, clinical studies and labeling information. A New Drug Application (NDA) is the formal request to market a new pharmaceutical product. The application contains information from all previous steps, including data collected from clinical trials and is reviewed by the FDA."></li></Link>
                                <Link to="/resources-and-information/nets/therapy"><li class="menu-13" title="An approved therapy is an medical product that has proven to be safe & effective in clinical trials and has therefore received FDA marketing approval."></li></Link>

                                <Link to="/resources-and-information/nets/clinical-trials"><li class="menu-14" title="Clinical trials are studies to test the safety and efficacy of new therapeutic treatments, such as drugs or biologics. Clinical trial sponsors must obtain FDA approval before they may begin trials. The FDA requires sponsors to submit data showing safety and efficacy in animal models before they will permit testing in humans. Clinical trials can also be used to compare the safety and efficacy of different therapeutic treatments. "></li></Link>
                                <Link to="/resources-and-information/nets/biospecimens"><li class="menu-15" title="Biospecimens are biological samples collected from humans such as blood, urine, tumor and other materials. They are used in biomedical research to learn about how disease affects the human body."></li></Link>
                                <Link to="/resources-and-information/nets/registries"><li class="menu-16" title="Registries are databases of information on individuals affected by a certain disease or condition. They type of data included in registries will vary depending on the purpose of the registry. Contact registries, which are useful for organizing the disease community and clinical cohorts, include names, contact information, and perhaps some demographics. Registries that are intended to be used for research will also include some clinical information, such as age of onset, symptoms, medical images or test results. They type of information included in a registry will vary with disease."></li></Link>
                                <Link to="/resources-and-information/nets/studies"><li class="menu-17" title="Natural history studies are studies that observe individuals with a certain disease or people who are at high risk of developing the disease. The purpose of natural history studies is to understand and characterize the normal progression of a disease or condition."></li></Link>
                                <Link to="/resources-and-information/nets/cohorts"><li class="menu-18" title="Clinical cohorts are a group of individuals that share a common characteristic or disease. Grouping individuals with a shared characteriztic or disease can be helpful in organizing and recruiting participants that are eligible for a particular research study or clinical trial."></li></Link>
                                <Link to="/resources-and-information/nets/access"><li class="menu-19" title="Accessible therapies are therapies that are available to all individuals that can benefit from them, including those individuals that are uninsured or unable to afford the therapy. Many pharmaceutical companies and disease advocacy organizations provide resources to help make therapies accessible to all individuals who will benefit from treatment."></li></Link>
                            </ul>
                        </nav>
                    </div>
                    <div className='tablet-only neighborhood-view-tablet'>
                        <nav id="neighborhoodViewTablet" style={{background: `url('/images/resources-and-information/nets/NETS-neighborhood-view-tablet.png')`}}>
                            <ul>
                            <Link to="/resources-and-information/nets/biorepositories"><li class="menu-1" title="Data repositories are information warehouses where large amounts of data from multiple sources are stored. Examples of the types of data that can be stored in data repositories include scientific publications, research data, or clinical information."></li></Link>
                                <Link to="/resources-and-information/nets/repositories"><li class="menu-2" title="Biorepositories are places for collecting and storing biological samples (also called biospecimen) such as blood, urine, tumor, or other tissue. The type of biological samples stored in biorepositories will vary depending upon the type of disease and/or nature of research."></li></Link>
                                <Link to="/resources-and-information/nets/pathophysiology"><li class="menu-3" title="Disease Pathophysiology is the study of how disease alters the functions of cells, organs, organ systems, and how these changes in turn affect the entire body."></li></Link>
                                <Link to="/resources-and-information/nets/targets"><li class="menu-4" title="Therapeutic Targets are molecular sites for therapeutic intervention. The hope is that altering the expression or function of therapeutic targets using drugs or other therapies will either cure the underlying cause of the disorder or ameliorate disease symptoms."></li></Link>

                                <Link to="/resources-and-information/nets/hts"><li class="menu-5" title="A HTS assay is an assay that has been designed to be compatible with high throughput screening (HTS) systems. With a HTS system and HTS compatible assays, tens of thousands of compounds can be tested rapidly to determine whether any of them have the desired effects."></li></Link>
                                <Link to="/resources-and-information/nets/system"><li class="menu-6" title="A HTS system a robotic process that conducts biochemical, genetic or pharmacological tests on large numbers of candidate compounds to identify active compounds, antibodies or genes."></li></Link>
                                <Link to="/resources-and-information/nets/hits"><li class="menu-7" title="A ‘hit’ is a compound that produce the desired effect in HTS system and therefore qualifies for additional investigation into its potential therapeutic effects."></li></Link>
                                <Link to="/resources-and-information/nets/approved"><li class="menu-8" title="An approved compound is a compound that is already approved for another use in humans. Occasionally existing medications can be repurposed to treat disease other than the condition they were initially designed for."></li></Link>

                                <Link to="/resources-and-information/nets/lead"><li class="menu-9" title="A lead compound is a chemical structure or a collection of molecules that demonstrate the desired response against a specific target and therefore has potential therapeutic use. Often, they are identified through using from compound libraries using a HTS system."></li></Link>
                                <Link to="/resources-and-information/nets/compound"><li class="menu-10" title="A compound for preclinical and clinical studies is a lead compound that has been optimized using medicinal chemistry to reduce the off-target effects and improve its interaction with the target molecule."></li></Link>

                                <Link to="/resources-and-information/nets/ind"><li class="menu-11" title="An investigational new drug (IND) application is an application that must be submitted to the FDA to obtain permission to begin a clinical trial. An IND application is submitted by the clinical trial sponsor and must include documentation demonstrating that the compound has proven safe in animal testing and includes animal pharmacology and toxicology studies. It will also contain manufacturing information, clinical protocols for the proposed clinical trial, and investigator information. This report must be submitted to the FDA before human testing may begin. "></li></Link>
                                <Link to="/resources-and-information/nets/nda"><li class="menu-12" title="NDAs and BLAs are FDA applications required for the medical use of a substance. According to the FDA, a Biologic License Application (BLA) is a request for permission to introduce a biologic product, a medical product from a natural source, into interstate commerce. The document includes applicant information, product manufacturing information, pre-clinical studies, clinical studies and labeling information. A New Drug Application (NDA) is the formal request to market a new pharmaceutical product. The application contains information from all previous steps, including data collected from clinical trials and is reviewed by the FDA."></li></Link>
                                <Link to="/resources-and-information/nets/therapy"><li class="menu-13" title="An approved therapy is an medical product that has proven to be safe & effective in clinical trials and has therefore received FDA marketing approval."></li></Link>

                                <Link to="/resources-and-information/nets/clinical-trials"><li class="menu-14" title="Clinical trials are studies to test the safety and efficacy of new therapeutic treatments, such as drugs or biologics. Clinical trial sponsors must obtain FDA approval before they may begin trials. The FDA requires sponsors to submit data showing safety and efficacy in animal models before they will permit testing in humans. Clinical trials can also be used to compare the safety and efficacy of different therapeutic treatments. "></li></Link>
                                <Link to="/resources-and-information/nets/biospecimens"><li class="menu-15" title="Biospecimens are biological samples collected from humans such as blood, urine, tumor and other materials. They are used in biomedical research to learn about how disease affects the human body."></li></Link>
                                <Link to="/resources-and-information/nets/registries"><li class="menu-16" title="Registries are databases of information on individuals affected by a certain disease or condition. They type of data included in registries will vary depending on the purpose of the registry. Contact registries, which are useful for organizing the disease community and clinical cohorts, include names, contact information, and perhaps some demographics. Registries that are intended to be used for research will also include some clinical information, such as age of onset, symptoms, medical images or test results. They type of information included in a registry will vary with disease."></li></Link>
                                <Link to="/resources-and-information/nets/studies"><li class="menu-17" title="Natural history studies are studies that observe individuals with a certain disease or people who are at high risk of developing the disease. The purpose of natural history studies is to understand and characterize the normal progression of a disease or condition."></li></Link>
                                <Link to="/resources-and-information/nets/cohorts"><li class="menu-18" title="Clinical cohorts are a group of individuals that share a common characteristic or disease. Grouping individuals with a shared characteriztic or disease can be helpful in organizing and recruiting participants that are eligible for a particular research study or clinical trial."></li></Link>
                                <Link to="/resources-and-information/nets/access"><li class="menu-19" title="Accessible therapies are therapies that are available to all individuals that can benefit from them, including those individuals that are uninsured or unable to afford the therapy. Many pharmaceutical companies and disease advocacy organizations provide resources to help make therapies accessible to all individuals who will benefit from treatment."></li></Link>
                            </ul>
                        </nav>
                    </div>
                    <div className='mobile-only neighborhood-view-mobile'>
                        <Image id='neighborhoodViewMobile' src='/images/resources-and-information/nets/NETS-neighborhood-view.png' onClick={() => setFullScreen(true)} />
                    </div>
                    <div className={fullScreen ? 'show-full-screen' : 'hide-full-screen'} style={{overflow: 'overlay'}}>
                    <div className='full-screen-exit-container' onClick={() => setFullScreen(false)}><Image src='/icons/search-exit.png' className='full-screen-exit-image icon-width-16px' /></div>
                        <div>
                            <nav id="fullScreenNeighborhoodView" style={{background: `url('/images/resources-and-information/nets/NETS-neighborhood-view.png')`}}>
                                <ul style={{margin:'0px'}}>
                                <Link to="/resources-and-information/nets/biorepositories" onClick={() => setFullScreen(false)}><li class="menu-1" title="Data repositories are information warehouses where large amounts of data from multiple sources are stored. Examples of the types of data that can be stored in data repositories include scientific publications, research data, or clinical information."></li></Link>
                                <Link to="/resources-and-information/nets/repositories" onClick={() => setFullScreen(false)}><li class="menu-2" title="Biorepositories are places for collecting and storing biological samples (also called biospecimen) such as blood, urine, tumor, or other tissue. The type of biological samples stored in biorepositories will vary depending upon the type of disease and/or nature of research."></li></Link>
                                <Link to="/resources-and-information/nets/pathophysiology" onClick={() => setFullScreen(false)}><li class="menu-3" title="Disease Pathophysiology is the study of how disease alters the functions of cells, organs, organ systems, and how these changes in turn affect the entire body."></li></Link>
                                <Link to="/resources-and-information/nets/targets" onClick={() => setFullScreen(false)}><li class="menu-4" title="Therapeutic Targets are molecular sites for therapeutic intervention. The hope is that altering the expression or function of therapeutic targets using drugs or other therapies will either cure the underlying cause of the disorder or ameliorate disease symptoms."></li></Link>

                                <Link to="/resources-and-information/nets/hts" onClick={() => setFullScreen(false)}><li class="menu-5" title="A HTS assay is an assay that has been designed to be compatible with high throughput screening (HTS) systems. With a HTS system and HTS compatible assays, tens of thousands of compounds can be tested rapidly to determine whether any of them have the desired effects."></li></Link>
                                <Link to="/resources-and-information/nets/system" onClick={() => setFullScreen(false)}><li class="menu-6" title="A HTS system a robotic process that conducts biochemical, genetic or pharmacological tests on large numbers of candidate compounds to identify active compounds, antibodies or genes."></li></Link>
                                <Link to="/resources-and-information/nets/hits" onClick={() => setFullScreen(false)}><li class="menu-7" title="A ‘hit’ is a compound that produce the desired effect in HTS system and therefore qualifies for additional investigation into its potential therapeutic effects."></li></Link>
                                <Link to="/resources-and-information/nets/approved" onClick={() => setFullScreen(false)}><li class="menu-8" title="An approved compound is a compound that is already approved for another use in humans. Occasionally existing medications can be repurposed to treat disease other than the condition they were initially designed for."></li></Link>

                                <Link to="/resources-and-information/nets/lead" onClick={() => setFullScreen(false)}><li class="menu-9" title="A lead compound is a chemical structure or a collection of molecules that demonstrate the desired response against a specific target and therefore has potential therapeutic use. Often, they are identified through using from compound libraries using a HTS system."></li></Link>
                                <Link to="/resources-and-information/nets/compound" onClick={() => setFullScreen(false)}><li class="menu-10" title="A compound for preclinical and clinical studies is a lead compound that has been optimized using medicinal chemistry to reduce the off-target effects and improve its interaction with the target molecule."></li></Link>

                                <Link to="/resources-and-information/nets/ind" onClick={() => setFullScreen(false)}><li class="menu-11" title="An investigational new drug (IND) application is an application that must be submitted to the FDA to obtain permission to begin a clinical trial. An IND application is submitted by the clinical trial sponsor and must include documentation demonstrating that the compound has proven safe in animal testing and includes animal pharmacology and toxicology studies. It will also contain manufacturing information, clinical protocols for the proposed clinical trial, and investigator information. This report must be submitted to the FDA before human testing may begin. "></li></Link>
                                <Link to="/resources-and-information/nets/nda" onClick={() => setFullScreen(false)}><li class="menu-12" title="NDAs and BLAs are FDA applications required for the medical use of a substance. According to the FDA, a Biologic License Application (BLA) is a request for permission to introduce a biologic product, a medical product from a natural source, into interstate commerce. The document includes applicant information, product manufacturing information, pre-clinical studies, clinical studies and labeling information. A New Drug Application (NDA) is the formal request to market a new pharmaceutical product. The application contains information from all previous steps, including data collected from clinical trials and is reviewed by the FDA."></li></Link>
                                <Link to="/resources-and-information/nets/therapy" onClick={() => setFullScreen(false)}><li class="menu-13" title="An approved therapy is an medical product that has proven to be safe & effective in clinical trials and has therefore received FDA marketing approval."></li></Link>

                                <Link to="/resources-and-information/nets/clinical-trials" onClick={() => setFullScreen(false)}><li class="menu-14" title="Clinical trials are studies to test the safety and efficacy of new therapeutic treatments, such as drugs or biologics. Clinical trial sponsors must obtain FDA approval before they may begin trials. The FDA requires sponsors to submit data showing safety and efficacy in animal models before they will permit testing in humans. Clinical trials can also be used to compare the safety and efficacy of different therapeutic treatments. "></li></Link>
                                <Link to="/resources-and-information/nets/biospecimens" onClick={() => setFullScreen(false)}><li class="menu-15" title="Biospecimens are biological samples collected from humans such as blood, urine, tumor and other materials. They are used in biomedical research to learn about how disease affects the human body."></li></Link>
                                <Link to="/resources-and-information/nets/registries" onClick={() => setFullScreen(false)}><li class="menu-16" title="Registries are databases of information on individuals affected by a certain disease or condition. They type of data included in registries will vary depending on the purpose of the registry. Contact registries, which are useful for organizing the disease community and clinical cohorts, include names, contact information, and perhaps some demographics. Registries that are intended to be used for research will also include some clinical information, such as age of onset, symptoms, medical images or test results. They type of information included in a registry will vary with disease."></li></Link>
                                <Link to="/resources-and-information/nets/studies" onClick={() => setFullScreen(false)}><li class="menu-17" title="Natural history studies are studies that observe individuals with a certain disease or people who are at high risk of developing the disease. The purpose of natural history studies is to understand and characterize the normal progression of a disease or condition."></li></Link>
                                <Link to="/resources-and-information/nets/cohorts" onClick={() => setFullScreen(false)}><li class="menu-18" title="Clinical cohorts are a group of individuals that share a common characteristic or disease. Grouping individuals with a shared characteriztic or disease can be helpful in organizing and recruiting participants that are eligible for a particular research study or clinical trial."></li></Link>
                                <Link to="/resources-and-information/nets/access" onClick={() => setFullScreen(false)}><li class="menu-19" title="Accessible therapies are therapies that are available to all individuals that can benefit from them, including those individuals that are uninsured or unable to afford the therapy. Many pharmaceutical companies and disease advocacy organizations provide resources to help make therapies accessible to all individuals who will benefit from treatment."></li></Link>
                                    
                                </ul>
                            </nav>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default NETSNeighborhoodView