import { Grid, Form } from 'semantic-ui-react'
import './IRBApplication.css'
import React, { useState, useEffect } from 'react'
import NextBackStep from './NextBackStep'

function StepTwelve({
	step,
	setStep,
	totalSteps,
	updateFields,
	consentProcess,
	sufficientOpportunity,
	opportunityExplain,
	consentMinor,
	coercion,
	coercionExplain,
	benefit,
	benefitExplain,
	anticipatedBenefits,
	withdraw,
	withdrawExplain,
	compensate,
	compensateExplain,
	...data
}) {
	const [consentProcessError, setConsentProcessError] = useState(false)
	const [sufficientOpportunityError, setSufficientOpportunityError] =
		useState(false)
	const [opportunityExplainError, setOpportunityExplainError] = useState(false)
	const [consentMinorError, setConsentMinorError] = useState(false)
	const [coercionError, setCoercionError] = useState(false)
	const [coercionExplainError, setCoercionExplainError] = useState(false)
	const [benefitError, setBenefitError] = useState(false)
	const [benefitExplainError, setBenefitExplainError] = useState(false)
	const [anticipatedBenefitsError, setAnticipatedBenefitsError] = useState(false)
	const [withdrawError, setWithdrawError] = useState(false)
	const [withdrawExplainError, setWithdrawExplainError] = useState(false)
	const [compensateError, setCompensateError] = useState(false)
	const [compensateExplainError, setCompensateExplainError] = useState(false)
	// const [overWordLimit, setOverWordLimit] = useState(false)

	// const countWords = str => {
	// 	let wordCount = 0
	// 	if (str) {
	// 		let words = str.split(' ')
	// 		for (let i = 0; i < words.length; i++) {
	// 			if (words[i] !== '') {
	// 				wordCount++
	// 			}
	// 		}
	// 	}
	// 	return wordCount
	// }

	// useEffect(() => {
	// 	if (countWords(consentProcess) > 200) {
	// 		setOverWordLimit(true)
	// 	} else {
	// 		setOverWordLimit(false)
	// 	}
	// }, [consentProcess])

	const isConsentProcessMissing = () => {
		if (consentProcess === '') {
			setConsentProcessError(true)
			// setOverWordLimit(true)
			return true
		}
		if (consentProcess && consentProcess.split(' ').length > 200) {
			setConsentProcessError(true)
			return true
		}
		return false
	}
	const isSufficientOpportunityMissing = () => {
		if (!sufficientOpportunity) {
			setSufficientOpportunityError(true)
			return true
		}
		return false
	}
	const isOpportunityExplainMissing = () => {
		if (opportunityExplain === '' && sufficientOpportunity === 'False') {
			setOpportunityExplainError(true)
			return true
		}
		return false
	}
	const isConsentMinorMissing = () => {
		if (consentMinor === '') {
			setConsentMinorError(true)
			return true
		}
		return false
	}
	const isCoercionMissing = () => {
		if (!coercion) {
			setCoercionError(true)
			return true
		}
		return false
	}
	const isCoercionExplainMissing = () => {
		if (coercionExplain === '' && coercion === 'False') {
			setCoercionExplainError(true)
			return true
		}
		return false
	}
	const isBenefitMissing = () => {
		if (!benefit) {
			setBenefitError(true)
			return true
		}
		return false
	}
	const isBenefitExplainMissing = () => {
		if (benefitExplain === '' && benefit === 'False') {
			setBenefitExplainError(true)
			return true
		}
		return false
	}
	const isAnticipatedBenefitsMissing = () => {
		if (anticipatedBenefits === '') {
			setAnticipatedBenefitsError(true)
			return true
		}
		return false
	}
	const isWithdrawMissing = () => {
		if (!withdraw) {
			setWithdrawError(true)
			return true
		}
		return false
	}
	const isWithdrawExplainMissing = () => {
		if (withdrawExplain === '' && withdraw === 'False') {
			setWithdrawExplainError(true)
			return true
		}
		return false
	}
	const isCompensateMissing = () => {
		if (!compensate) {
			setCompensateError(true)
			return true
		}
		return false
	}
	const isCompensateExplainMissing = () => {
		if (compensateExplain === '' && compensate === 'Yes') {
			setCompensateExplainError(true)
			return true
		}
		return false
	}

	let missingFields =
		consentProcessError ||
		sufficientOpportunityError ||
		opportunityExplainError ||
		consentMinorError ||
		coercionError ||
		coercionExplainError ||
		benefitError ||
		benefitExplainError ||
		anticipatedBenefitsError ||
		withdrawError ||
		withdrawExplainError ||
		compensateError ||
		compensateExplainError

	const next = () => {
		isConsentProcessMissing()
		isSufficientOpportunityMissing()
		isOpportunityExplainMissing()
		isConsentMinorMissing()
		isCoercionMissing()
		isCoercionExplainMissing()
		isBenefitMissing()
		isBenefitExplainMissing()
		isAnticipatedBenefitsMissing()
		isWithdrawMissing()
		isWithdrawExplainMissing()
		isCompensateMissing()
		isCompensateExplainMissing()
		if (
			isConsentProcessMissing() === false &&
			isSufficientOpportunityMissing() === false &&
			isOpportunityExplainMissing() === false &&
			isConsentMinorMissing() === false &&
			isCoercionMissing() === false &&
			isCoercionExplainMissing() === false &&
			isBenefitMissing() === false &&
			isBenefitExplainMissing() === false &&
			isAnticipatedBenefitsMissing() === false &&
			isWithdrawMissing() === false &&
			isWithdrawExplainMissing() === false &&
			isCompensateMissing() === false &&
			isCompensateExplainMissing() === false
		) {
			setStep(i => {
				return i + 1
			})
		}
		missingFields = true
	}

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<Grid centered>
			<Grid.Column computer={12}>
				<h3 className='mb-40'>Additional Consent Information</h3>
				<p>Process for obtaining informed consent: </p>
				{
					<Form>
						<Form.TextArea
							label='Process for obtaining informed consent'
							placeholder='This study will ... please give a short summary of your consenting procedure (max 200 words) '
							required
							type='text'
							value={consentProcess}
							onChange={e => {
								setConsentProcessError(false)
								updateFields({ consentProcess: e.target.value })
							}}
						/>
						{/* <p className={overWordLimit ? 'p-small error' : 'p-small'}>
							{countWords(consentProcess)}/200 words
						</p> */}
						{consentProcessError && (
							<p className='error'>Process for obtaining informed consent is required.</p>
						)}
						<p className='mt-40'>
							Potential participants will be provided with sufficient opportunity and
							time to consider whether or not to participate.{' '}
							<span className='error-color'>*</span>
						</p>
						<Form.Group className='display-block'>
							<Form.Radio
								className='mb-15'
								label='True'
								type='radio'
								name='sufficientOpportunity'
								checked={sufficientOpportunity === 'True'}
								value='True'
								onChange={e => updateFields({ sufficientOpportunity: 'True' })}
							/>
							<Form.Radio
								label='False'
								type='radio'
								name='sufficientOpportunity'
								checked={sufficientOpportunity === 'False'}
								value='False'
								onChange={e => updateFields({ sufficientOpportunity: 'False' })}
							/>
						</Form.Group>
						{sufficientOpportunityError && (
							<p className='error'>Please select an option.</p>
						)}
						{sufficientOpportunity === 'False' && (
							<>
								<Form.Input
									className='ml-30 mt-25'
									required={sufficientOpportunity === 'False'}
									label='Explain'
									placeholder='Please explain'
									type='text'
									value={opportunityExplain}
									onChange={e => {
										setOpportunityExplainError(false)
										updateFields({ opportunityExplain: e.target.value })
									}}
								/>
								{opportunityExplainError && (
									<p className='error'>Explanation is required.</p>
								)}
							</>
						)}
						<p className='mt-40'>
							Parent/Guardian permissions and the handling of consent for legal minor
							(under 18 years of age) shall _____{' '}
							<span className='error-color'>*</span>
						</p>
						<Form.TextArea
							label='Parent/Guardian permissions and handling of consent for legal minor'
							placeholder='Parent/Guardian permissions and the handling of consent for legal minor (under 18 years of age) shall ...'
							required
							type='text'
							value={consentMinor}
							onChange={e => {
								setConsentMinorError(false)
								updateFields({ consentMinor: e.target.value })
							}}
						/>
						{consentMinorError && (
							<p className='error'>
								Parent/Guardian permissions and handling of consent for legal minor is
								required.
							</p>
						)}
						<p className='mt-40'>
							There are no special issues in this study where there is a possibility of
							coercion or undue influence. <span className='error-color'>*</span>
						</p>
						<Form.Group className='display-block'>
							<Form.Radio
								className='mb-15'
								label='True'
								type='radio'
								name='coercion'
								checked={coercion === 'True'}
								value='True'
								onChange={e => updateFields({ coercion: 'True' })}
							/>
							<Form.Radio
								label='False'
								type='radio'
								name='coercion'
								checked={coercion === 'False'}
								value='False'
								onChange={e => updateFields({ coercion: 'False' })}
							/>
						</Form.Group>
						{coercionError && <p className='error'>Please select an option.</p>}
						{coercion === 'False' && (
							<>
								<Form.Input
									className='ml-30 mt-25'
									required={coercion === 'False'}
									label='Explain'
									placeholder='Please explain'
									type='text'
									value={coercionExplain}
									onChange={e => {
										setCoercionExplainError(false)
										updateFields({ coercionExplain: e.target.value })
									}}
								/>
								{coercionExplainError && (
									<p className='error'>Explanation is required.</p>
								)}
							</>
						)}
						<p className='mt-40'>
							Risks and inconveniences are both minimal. The risk/benefit ratio weighs
							clearly toward benefit. <span className='error-color'>*</span>
						</p>
						<Form.Group className='display-block'>
							<Form.Radio
								className='mb-15'
								label='True'
								type='radio'
								name='benefit'
								checked={benefit === 'True'}
								value='True'
								onChange={e => updateFields({ benefit: 'True' })}
							/>
							<Form.Radio
								label='False'
								type='radio'
								name='benefit'
								checked={benefit === 'False'}
								value='False'
								onChange={e => updateFields({ benefit: 'False' })}
							/>
						</Form.Group>
						{benefitError && <p className='error'>Please select an option.</p>}
						{benefit === 'False' && (
							<>
								<Form.Input
									className='ml-30 mt-25'
									required={benefit === 'False'}
									label='Explain'
									placeholder='Please explain'
									type='text'
									value={benefitExplain}
									onChange={e => {
										setBenefitExplainError(false)
										updateFields({ benefitExplain: e.target.value })
									}}
								/>
								{benefitExplainError && (
									<p className='error'>Explanation is required.</p>
								)}
							</>
						)}
						<p className='mt-40'>
							Anticipated benefits to individual participants are _____ (if there are
							none, indicate that there are none){' '}
							<span className='error-color'>*</span>
						</p>
						<Form.TextArea
							label='Anticipated benefits to individual participants'
							placeholder='Anticipated benefits to individual participants are ...'
							required
							type='text'
							value={anticipatedBenefits}
							onChange={e => {
								setAnticipatedBenefitsError(false)
								updateFields({ anticipatedBenefits: e.target.value })
							}}
						/>
						{anticipatedBenefitsError && (
							<p className='error'>
								Anticipated benefits to individual participants is required.
							</p>
						)}
						<p className='mt-40'>
							Participants may withdraw from this study at any time with full control
							over that. <span className='error-color'>*</span>
						</p>
						<Form.Group className='display-block'>
							<Form.Radio
								className='mb-15'
								label='True'
								type='radio'
								name='withdraw'
								checked={withdraw === 'True'}
								value='True'
								onChange={e => updateFields({ withdraw: 'True' })}
							/>
							<Form.Radio
								label='False'
								type='radio'
								name='withdraw'
								checked={withdraw === 'False'}
								value='False'
								onChange={e => updateFields({ withdraw: 'False' })}
							/>
						</Form.Group>
						{withdrawError && <p className='error'>Please select an option.</p>}
						{withdraw === 'False' && (
							<>
								<Form.Input
									className='ml-30 mt-25'
									required={withdraw === 'False'}
									label='Explain'
									placeholder='Please explain'
									type='text'
									value={withdrawExplain}
									onChange={e => {
										setWithdrawExplainError(false)
										updateFields({ withdrawExplain: e.target.value })
									}}
								/>
								{withdrawExplainError && (
									<p className='error'>Explanation is required.</p>
								)}
							</>
						)}
						<p className='mt-40'>
							Will participants be compensated or reimbursed in exchange for their
							participation? <span className='error-color'>*</span>
						</p>
						<Form.Group className='display-block'>
							<Form.Radio
								className='mb-15'
								label='Yes'
								type='radio'
								name='compensate'
								checked={compensate === 'Yes'}
								value='Yes'
								onChange={e => updateFields({ compensate: 'Yes' })}
							/>
							<Form.Radio
								label='No'
								type='radio'
								name='compensate'
								checked={compensate === 'No'}
								value='No'
								onChange={e => updateFields({ compensate: 'No' })}
							/>
						</Form.Group>
						{compensateError && <p className='error'>Please select an option.</p>}
						{compensate === 'Yes' && (
							<>
								<Form.Input
									className='ml-30 mt-25'
									required={compensate === 'Yes'}
									label='Explain'
									placeholder='Please explain'
									type='text'
									value={compensateExplain}
									onChange={e => {
										setCompensateExplainError(false)
										updateFields({ compensateExplain: e.target.value })
									}}
								/>
								{compensateExplainError && (
									<p className='error'>Explanation is required.</p>
								)}
							</>
						)}
					</Form>
				}
				{missingFields && (
					<p className='error missing-field'>
						Please fill all required fields above.
					</p>
				)}
				<NextBackStep
					step={step}
					totalSteps={totalSteps}
					next={next}
					setStep={setStep}
					data={data}
				/>
			</Grid.Column>
		</Grid>
	)
}

export default StepTwelve
