import { Container, Grid, Image } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import './NETSFullView.css'

function NETSFullView() {
	const [fullScreen, setFullScreen] = useState(false)

    return (
        <>
            <div>
                <Container className='container-main' id='netsFullView'>
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                            <h1>Navigating the Ecosystem of Translational Science (NETS)</h1>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                            <p className='subtitle'>
                                Click concepts on the map below to view information and resources. Let us know if you are aware of others we should add.
                            </p>
                        </Grid.Column>
                    </Grid>
			        <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
                    <div className='computer-only full-view-computer'>
                        <nav id="fullViewComputer" style={{background: `url('/images/resources-and-information/nets/NETS-Final-1900px.jpg')`, backgroundSize: '864px 994px'}}>
                            <ul>
                                <Link to="/resources-and-information/nets/bioinformatics"><li class="menu-1" title="The application of computer science and information technology to the field of molecular biology."></li></Link>
                                <Link to="/resources-and-information/nets/hypothesis"><li class="menu-2" title="Proposed explanations for a phenomenon that are formed from observations, and tested during experimentation."></li></Link>
                                <Link to="/resources-and-information/nets/pathophysiology"><li class="menu-3" title="The study of how diseases alter the functions of cells, organs, organ systems, and how these changes affect the entire body."></li></Link>
                                <Link to="/resources-and-information/nets/targets"><li class="menu-4" title="Molecular sites used for therapeutic intervention to try and cure a disease/disorder or provide symptom relief. "></li></Link>
                                <Link to="/resources-and-information/nets/repositories"><li class="menu-5" title="Information warehouses where large amounts of data  from multiple sources (such as  scientific publications, research data, and clinical information) are stored."></li></Link>
                                <Link to="/resources-and-information/nets/cell-lines"><li class="menu-6" title="Collections of sample cells extracted from donors and stored to give researchers continual access to specific cell types, without the need for additional sample donations."></li></Link>
                                <Link to="/resources-and-information/nets/animal-models"><li class="menu-7" title="Non-human organisms used in research and engineered to reproduce the physical or molecular changes that occur in human disease."></li></Link>
                                <Link to="/resources-and-information/nets/pathway"><li class="menu-8" title="A series of interactions that occur between the molecules in a cell. Understanding these pathways for a disease can help scientists select the best therapeutic targets."></li></Link>
                                <Link to="/resources-and-information/nets/biorepositories"><li class="menu-9" title="Places for collecting and storing biological samples such as blood, urine, tumors, and other tissues."></li></Link>
                                <Link to="/resources-and-information/nets/genotypes"><li class="menu-10" title="An individualís genetic makeup, coded in their DNA. Expression of an individualís genotype determines their phenotype or observable traits."></li></Link>
                                <Link to="/resources-and-information/nets/phenotypes"><li class="menu-11" title="An individualís observable traits (such as height or eye color). Phenotypes are influenced by an individual's genotype and environmental factors."></li></Link>

                                <li class='purified-protein' title='Coming soon!'/>
                                <li class='nbe-development' title='Coming soon!'/>
                                <Link to="/resources-and-information/nets/development"><li class="menu-12" title="An assay is an analytical test that measures the activity of a chemical compound on a target of interest.  Different assays are made for different diseases and molecular targets."></li></Link>
                                <Link to="/resources-and-information/nets/hts"><li class="menu-13" title="An assay (test for measuring a compound for desired effects) compatible with high throughput screening (HTS) systems. With both components, thousands of compounds can be tested rapidly to determine whether any of them have desired effects."></li></Link>
                                <Link to="/resources-and-information/nets/system"><li class="menu-14" title="Consists of the robotic processes and equipment needed for scientists to conduct high throughput experiments - biochemical, genetic or pharmocological tests on large numbers of candidate compounds, typially used to identify compounds that have a desired effect on a therapeutic target."></li></Link>
                                <Link to="/resources-and-information/nets/libraries"><li class="menu-15" title="Stores of chemical compounds and small molecules, with detailed information on physical descriptions and known physiological interactions."></li></Link>
                                <Link to="/resources-and-information/nets/misses"><li class="menu-16" title="Compounds that do not produce the desired effect in a specific HTS assay, but may be effective in an assay for a different disease."></li></Link>
                                <Link to="/resources-and-information/nets/hits"><li class="menu-17" title="A compound that produces the desired effect in an HTS system and therefore qualifies for additional investigation into its potential therapeutic effects."></li></Link>
                                <Link to="/resources-and-information/nets/approved"><li class="menu-18" title="A compound that is already approved for a specific use in humans, but is sometimes repurposed to treat a different disease than the one it was initially designed for."></li></Link>
                                <Link to="/resources-and-information/nets/unapproved"><li class="menu-19" title="A compound that has not been approved for use in humans. These compounds may be classified as new chemical entities (NCEs) or new molecular entities (NMEs)."></li></Link>

                                <Link to="/resources-and-information/nets/lead"><li class="menu-20" title="A molecule or a collection of molecules that has potential for therapeutic use after demonstrating the desired response against a specific target."></li></Link>
                                <Link to="/resources-and-information/nets/manufacture"><li class="menu-21" title="Refers to producing a set of data containing detailed information on the chemical structure and stability of a compound, and the production and quality controls used in its manufacture."></li></Link>
                                <Link to="/resources-and-information/nets/medicinal"><li class="menu-22" title="The design and production of compounds intended to be used as medicine in humans. Compounds with potential therapeutic effects can be optimized to reduce unwanted side effects and improve their interaction with the therapeutic target."></li></Link>
                                <Link to="/resources-and-information/nets/non-glp"><li class="menu-23" title="Early toxicology and pharmacology studies that do not need to follow Good Laboratory Practices (GLP) - a certification issued by regulatory bodies to ensure that the data submitted to them has been obtained using the proper procedures and is thoroughly documented."></li></Link>
                                <Link to="/resources-and-information/nets/acute"><li class="menu-24" title="The study of adverse effects that result soon after a single dose or multiple doses of a substance administered in close succession."></li></Link>
                                <Link to="/resources-and-information/nets/in-vitro"><li class="menu-25" title="The study of the biological effects of a drug. <em>In vitro</em> studies test drugs in an isolated environment (such as a cell line or tissue), while <em>in vivo studies</em> tests drugs in a complex living organism."></li></Link>
                                <Link to="/resources-and-information/nets/safety"><li class="menu-26" title="Refers to a therapeutic index or a numerical value that represents a drugs harmful effects compared to its therapeutic effects, determined through acute toxicology studies."></li></Link>
                                <Link to="/resources-and-information/nets/dose"><li class="menu-27" title="The quantity or concentration of drug that is administered. During pre-clinical research, data is collected on the safety and efficacy at different doses."></li></Link>
                                <Link to="/resources-and-information/nets/efficacy"><li class="menu-28" title="Demonstrate that a compound is capable of having the desired therapeutic effects."></li></Link>
                                <Link to="/resources-and-information/nets/compound"><li class="menu-29" title="A lead compound or biologic that has been optimized using medicinal chemistry to reduce off-target effects, and improve its interaction with the therapeutic target."></li></Link>
                                <Link to="/resources-and-information/nets/cgmp"><li class="menu-30" title="Current Good Manufacturing Practices (cGMP) are part of a system covering the manufacture and testing of drugs. Before a drug can be tested in humans, regulatory bodies require sponsors to show the drug has been made in accordance with these standards."></li></Link>
                                <Link to="/resources-and-information/nets/glp"><li class="menu-31" title="Later preclinical studies in pharmacology and toxicology should be completed using the documentation procedures found in the GLP (Good Laboratory Practices) standards."></li></Link>
                                <Link to="/resources-and-information/nets/pk"><li class="menu-32" title="Pharmacokinetics (PK) is the study of how a drug is metabolized and distributed throughout the body. PK (ADME) refers to PK studies that quantitatively measure the absorption, distribution, metabolism and elimination (ADME) of a compound in a living organism."></li></Link>
                                <Link to="/resources-and-information/nets/pharmacology"><li class="menu-33" title="The study of the biological effects of a drug and its interaction with living systems."></li></Link>
                                <Link to="/resources-and-information/nets/toxicology"><li class="menu-34" title="Studies on the toxic effects, behavioral and physical, of a drug when it's administered over an extended period of time."></li></Link>
                                <Link to="/resources-and-information/nets/product"><li class="menu-35" title="Ensures consistent drug accessibility by confirming the adequacy and availability of all drug ingredients, manufacturing capacity and delivery methods before a therapy is made available to the public."></li></Link>

                                <Link to="/resources-and-information/nets/ind"><li class="menu-36" title="Investigational New Drug (IND) or, in Europe, a clinical trial authorization (CTA) application. Both must be submitted to the relevant health authorities to obtain permission to begin a clinical trial. Click on the box to see IND application requirements."></li></Link>
                                <Link to="/resources-and-information/nets/health-authorities"><li class="menu-37" title="Health Adminitration (HA) reporting meetings allow drug sponsors to discuss IND applications with regulatory officials, and make any necessary updates before the official ruling on a new drug application."></li></Link>
                                <Link to="/resources-and-information/nets/nda"><li class="menu-38" title="FDA applications required for the medical use of a substance. A Biologic License Application (BLA) requests permission to introduce a biologic product into interstate commerce. A New Drug Application (NDA) is the formal request to market a new pharmaceutical product. Click on the box to find out what materials each application requires."></li></Link>
                                <Link to="/resources-and-information/nets/indication"><li class="menu-39" title="After a drug has been on the market for some time, new uses may be added to the drug's prescribing information as an FDA-approved indication."></li></Link>
                                <Link to="/resources-and-information/nets/rejected"><li class="menu-40" title="An IND, NDA, or BLA that is rejected by the FDA because it failed to provide evidence that the investigational compound is safe or effective."></li></Link>
                                <Link to="/resources-and-information/nets/therapy"><li class="menu-41" title="A medical product that has proven to be safe & effective in clinical trials and has therefore received marketing approval."></li></Link>

                                <Link to="/resources-and-information/nets/phase-iv"><li class="menu-42" title="Clinical trials that take place after the drug is approved for use. During Phase IV studies information is gathered about the drug's risks, benefits, and optimal use in larger, more diverse populations. Side effects associated with long-term use are addressed as well."></li></Link>
                                <Link to="/resources-and-information/nets/coverage"><li class="menu-43" title="Refers to establishing drug cost assistance offered through insurance providers, by requesting that these organizations add a new drug to their list of covered or preferred drugs."></li></Link>
                                <Link to="/resources-and-information/nets/clinical-practice"><li class="menu-44" title="The process of determining how to introduce a drug for general use in the clinic setting. This can include educating health care professionals and developing resources."></li></Link>

                                <Link to="/resources-and-information/nets/researcher"><li class="menu-45" title="A person who works in a laboratory or clinical research setting and conducts experiments or studies to learn more about a particular disease or aspect of that disease."></li></Link>
                                <Link to="/resources-and-information/nets/sponsor"><li class="menu-46" title="The company, university, or organization responsible for overseeing al aspects of a clinical research project."></li></Link>
                                <Link to="/resources-and-information/nets/biospecimens"><li class="menu-47" title="Biospecimens are biological samples collected from humans such as blood, urine, tumor and other materials. They are used in biomedical research to learn about how disease affects the human body."></li></Link>
                                <Link to="/resources-and-information/nets/registries"><li class="menu-48" title="Databases of information on individuals affected by a certain disease or condition. There are different types of registries, each requiring different information."></li></Link>
                                <Link to="/resources-and-information/nets/irb"><li class="menu-49" title="An institutional review board (IRB) is a committee of health professionals and researchers that gives approval for human research studies on the basis of protecting the rights and privacy of participants."></li></Link>
                                <Link to="/resources-and-information/nets/agreements"><li class="menu-50" title="Formally document the responsibilities of different professionals involved in clinical research."></li></Link>
                                <Link to="/resources-and-information/nets/cohorts"><li class="menu-51" title="Groups of individuals that share a common characteristic or disease. Cohorts can be helpful in organizing and recruiting eligible participants for a particular research study or clinical trial."></li></Link>
                                <Link to="/resources-and-information/nets/recruitment"><li class="menu-52" title="The process of developing a protocol for conducting clinical research. Describes the what, how and why of the study, as well as participant eligibility and recruitment strategies."></li></Link>
                                <Link to="/resources-and-information/nets/study-staff"><li class="menu-53" title="Oversee the entire clinical trial, and are deemed qualified by their level of education, training and experience in performing their assigned task."></li></Link>
                                <Link to="/resources-and-information/nets/clinical-trials"><li class="menu-54" title="Studies to test the safety and efficacy of new therapeutic treatments, such as drugs or biologics. "></li></Link>
                                <Link to="/resources-and-information/nets/studies"><li class="menu-55" title="Studies that observe individuals with a certain disease or people who are at high risk of developing the disease in order to understand the normal progression of the disease."></li></Link>
                                <Link to="/resources-and-information/nets/consent"><li class="menu-56" title="When a researcher or investigator explains all aspects of a study, including the risks and benefits, to the participant. Participants learn about their rights and usually sign a form stating they understand the study and agree to participate."></li></Link>
                                <Link to="/resources-and-information/nets/enrollment"><li class="menu-57" title="When a participant meets all criterion for partaking in a study and agrees to participate (through informed consent), they become eligible to enroll in the study."></li></Link>
                                <Link to="/resources-and-information/nets/outcomes"><li class="menu-58" title="Measurements of the impact of therapeutic intervention used to evaluate the benefits and drawbacks of investigational drugs or therapies."></li></Link>
                                <Link to="/resources-and-information/nets/endpoint"><li class="menu-59" title="Characteristics that reflect how recipients of a drug or therapy feels, functions, or survives and show the effect of a therapeutic intervention."></li></Link>
                                <Link to="/resources-and-information/nets/update"><li class="menu-60" title="A study that adds new information to an existing natural history study."></li></Link>
                                <Link to="/resources-and-information/nets/phase-i"><li class="menu-61" title="Initial studies evaluating the pharmacokinetics (PK), safety, dosage range, and side effects of investigational drugs or therapies in humans. Participants are healthy volunteers."></li></Link>
                                <Link to="/resources-and-information/nets/subpopulations"><li class="menu-62" title="The process of grouping individuals affected by a disease by their commonalities, to understand why affected individuals have different symptoms and responses to drugs."></li></Link>
                                <Link to="/resources-and-information/nets/phase-ii"><li class="menu-63" title="Studies to evaluate the effectiveness of an investigational drug or therapy and observe short-term side effects or risks. Tests a larger group of participants than Phase I, including those affected by the disease or condition of interest."></li></Link>
                                <Link to="/resources-and-information/nets/responders"><li class="menu-64" title="Individuals in whom a drug produces a desired response, and individuals who do not experience a desired response to a drug."></li></Link>
                                <Link to="/resources-and-information/nets/phase-iii"><li class="menu-65" title="Large studies which confirm drug safety and efficacy, containing a greater number of participants than Phase II trials. Participants are usually randomly assiged to a control or treatment group."></li></Link>
                                <Link to="/resources-and-information/nets/re-evaluate"><li class="menu-66" title="When new data suggests an investigational compound has adverse effects or is ineffective, health administrations may re-evaluate the drug. If the health administration revokes their approval the drug is discontinued. "></li></Link>
                                <Link to="/resources-and-information/nets/care"><li class="menu-67" title="The consensus among medical professionals on the proper use of the new drug and treatment of patients."></li></Link>
                                
                                <Link to="/resources-and-information/nets/preparation"><li class="menu-68" title="The process of developing a protocol for conducting clinical research. Describes the what, how and why of the study, as well as participant eligibility and recruitment strategies."></li></Link>
                                <Link to="/resources-and-information/nets/nets-design"><li class="menu-69" title="The protocol that is developed for a clinical trial or study to test a hypothesis. It addresses factors such as the number of participants and controls necessary for a valid study."></li></Link>
                            </ul>
                        </nav>
                        <div className='footnote full-view-computer mt-40'>
                            <p className='p-small'>* Investigational treatments may be candidates for compassionate use.</p>
                            <p className='p-small'>† This phase of drug development contributes to epidemiology.</p>
                        </div>
                    </div>
                    <div className='tablet-only full-view-tablet'>
                        <nav id="fullViewTablet" style={{background: `url('/images/resources-and-information/nets/NETS-Final-1900px.jpg')`, backgroundSize: '750px 863px'}}>
                            <ul>
                                <Link to="/resources-and-information/nets/bioinformatics"><li class="menu-1" title="The application of computer science and information technology to the field of molecular biology."></li></Link>
                                <Link to="/resources-and-information/nets/hypothesis"><li class="menu-2" title="Proposed explanations for a phenomenon that are formed from observations, and tested during experimentation."></li></Link>
                                <Link to="/resources-and-information/nets/pathophysiology"><li class="menu-3" title="The study of how diseases alter the functions of cells, organs, organ systems, and how these changes affect the entire body."></li></Link>
                                <Link to="/resources-and-information/nets/targets"><li class="menu-4" title="Molecular sites used for therapeutic intervention to try and cure a disease/disorder or provide symptom relief. "></li></Link>
                                <Link to="/resources-and-information/nets/repositories"><li class="menu-5" title="Information warehouses where large amounts of data  from multiple sources (such as  scientific publications, research data, and clinical information) are stored."></li></Link>
                                <Link to="/resources-and-information/nets/cell-lines"><li class="menu-6" title="Collections of sample cells extracted from donors and stored to give researchers continual access to specific cell types, without the need for additional sample donations."></li></Link>
                                <Link to="/resources-and-information/nets/animal-models"><li class="menu-7" title="Non-human organisms used in research and engineered to reproduce the physical or molecular changes that occur in human disease."></li></Link>
                                <Link to="/resources-and-information/nets/pathway"><li class="menu-8" title="A series of interactions that occur between the molecules in a cell. Understanding these pathways for a disease can help scientists select the best therapeutic targets."></li></Link>
                                <Link to="/resources-and-information/nets/biorepositories"><li class="menu-9" title="Places for collecting and storing biological samples such as blood, urine, tumors, and other tissues."></li></Link>
                                <Link to="/resources-and-information/nets/genotypes"><li class="menu-10" title="An individualís genetic makeup, coded in their DNA. Expression of an individualís genotype determines their phenotype or observable traits."></li></Link>
                                <Link to="/resources-and-information/nets/phenotypes"><li class="menu-11" title="An individualís observable traits (such as height or eye color). Phenotypes are influenced by an individual's genotype and environmental factors."></li></Link>

                                <li class='purified-protein' title='Coming soon!'/>
                                <li class='nbe-development' title='Coming soon!'/>
                                <Link to="/resources-and-information/nets/development"><li class="menu-12" title="An assay is an analytical test that measures the activity of a chemical compound on a target of interest.  Different assays are made for different diseases and molecular targets."></li></Link>
                                <Link to="/resources-and-information/nets/hts"><li class="menu-13" title="An assay (test for measuring a compound for desired effects) compatible with high throughput screening (HTS) systems. With both components, thousands of compounds can be tested rapidly to determine whether any of them have desired effects."></li></Link>
                                <Link to="/resources-and-information/nets/system"><li class="menu-14" title="Consists of the robotic processes and equipment needed for scientists to conduct high throughput experiments - biochemical, genetic or pharmocological tests on large numbers of candidate compounds, typially used to identify compounds that have a desired effect on a therapeutic target."></li></Link>
                                <Link to="/resources-and-information/nets/libraries"><li class="menu-15" title="Stores of chemical compounds and small molecules, with detailed information on physical descriptions and known physiological interactions."></li></Link>
                                <Link to="/resources-and-information/nets/misses"><li class="menu-16" title="Compounds that do not produce the desired effect in a specific HTS assay, but may be effective in an assay for a different disease."></li></Link>
                                <Link to="/resources-and-information/nets/hits"><li class="menu-17" title="A compound that produces the desired effect in an HTS system and therefore qualifies for additional investigation into its potential therapeutic effects."></li></Link>
                                <Link to="/resources-and-information/nets/approved"><li class="menu-18" title="A compound that is already approved for a specific use in humans, but is sometimes repurposed to treat a different disease than the one it was initially designed for."></li></Link>
                                <Link to="/resources-and-information/nets/unapproved"><li class="menu-19" title="A compound that has not been approved for use in humans. These compounds may be classified as new chemical entities (NCEs) or new molecular entities (NMEs)."></li></Link>

                                <Link to="/resources-and-information/nets/lead"><li class="menu-20" title="A molecule or a collection of molecules that has potential for therapeutic use after demonstrating the desired response against a specific target."></li></Link>
                                <Link to="/resources-and-information/nets/manufacture"><li class="menu-21" title="Refers to producing a set of data containing detailed information on the chemical structure and stability of a compound, and the production and quality controls used in its manufacture."></li></Link>
                                <Link to="/resources-and-information/nets/medicinal"><li class="menu-22" title="The design and production of compounds intended to be used as medicine in humans. Compounds with potential therapeutic effects can be optimized to reduce unwanted side effects and improve their interaction with the therapeutic target."></li></Link>
                                <Link to="/resources-and-information/nets/non-glp"><li class="menu-23" title="Early toxicology and pharmacology studies that do not need to follow Good Laboratory Practices (GLP) - a certification issued by regulatory bodies to ensure that the data submitted to them has been obtained using the proper procedures and is thoroughly documented."></li></Link>
                                <Link to="/resources-and-information/nets/acute"><li class="menu-24" title="The study of adverse effects that result soon after a single dose or multiple doses of a substance administered in close succession."></li></Link>
                                <Link to="/resources-and-information/nets/in-vitro"><li class="menu-25" title="The study of the biological effects of a drug. <em>In vitro</em> studies test drugs in an isolated environment (such as a cell line or tissue), while <em>in vivo studies</em> tests drugs in a complex living organism."></li></Link>
                                <Link to="/resources-and-information/nets/safety"><li class="menu-26" title="Refers to a therapeutic index or a numerical value that represents a drugs harmful effects compared to its therapeutic effects, determined through acute toxicology studies."></li></Link>
                                <Link to="/resources-and-information/nets/dose"><li class="menu-27" title="The quantity or concentration of drug that is administered. During pre-clinical research, data is collected on the safety and efficacy at different doses."></li></Link>
                                <Link to="/resources-and-information/nets/efficacy"><li class="menu-28" title="Demonstrate that a compound is capable of having the desired therapeutic effects."></li></Link>
                                <Link to="/resources-and-information/nets/compound"><li class="menu-29" title="A lead compound or biologic that has been optimized using medicinal chemistry to reduce off-target effects, and improve its interaction with the therapeutic target."></li></Link>
                                <Link to="/resources-and-information/nets/cgmp"><li class="menu-30" title="Current Good Manufacturing Practices (cGMP) are part of a system covering the manufacture and testing of drugs. Before a drug can be tested in humans, regulatory bodies require sponsors to show the drug has been made in accordance with these standards."></li></Link>
                                <Link to="/resources-and-information/nets/glp"><li class="menu-31" title="Later preclinical studies in pharmacology and toxicology should be completed using the documentation procedures found in the GLP (Good Laboratory Practices) standards."></li></Link>
                                <Link to="/resources-and-information/nets/pk"><li class="menu-32" title="Pharmacokinetics (PK) is the study of how a drug is metabolized and distributed throughout the body. PK (ADME) refers to PK studies that quantitatively measure the absorption, distribution, metabolism and elimination (ADME) of a compound in a living organism."></li></Link>
                                <Link to="/resources-and-information/nets/pharmacology"><li class="menu-33" title="The study of the biological effects of a drug and its interaction with living systems."></li></Link>
                                <Link to="/resources-and-information/nets/toxicology"><li class="menu-34" title="Studies on the toxic effects, behavioral and physical, of a drug when it's administered over an extended period of time."></li></Link>
                                <Link to="/resources-and-information/nets/product"><li class="menu-35" title="Ensures consistent drug accessibility by confirming the adequacy and availability of all drug ingredients, manufacturing capacity and delivery methods before a therapy is made available to the public."></li></Link>

                                <Link to="/resources-and-information/nets/ind"><li class="menu-36" title="Investigational New Drug (IND) or, in Europe, a clinical trial authorization (CTA) application. Both must be submitted to the relevant health authorities to obtain permission to begin a clinical trial. Click on the box to see IND application requirements."></li></Link>
                                <Link to="/resources-and-information/nets/health-authorities"><li class="menu-37" title="Health Adminitration (HA) reporting meetings allow drug sponsors to discuss IND applications with regulatory officials, and make any necessary updates before the official ruling on a new drug application."></li></Link>
                                <Link to="/resources-and-information/nets/nda"><li class="menu-38" title="FDA applications required for the medical use of a substance. A Biologic License Application (BLA) requests permission to introduce a biologic product into interstate commerce. A New Drug Application (NDA) is the formal request to market a new pharmaceutical product. Click on the box to find out what materials each application requires."></li></Link>
                                <Link to="/resources-and-information/nets/indication"><li class="menu-39" title="After a drug has been on the market for some time, new uses may be added to the drug's prescribing information as an FDA-approved indication."></li></Link>
                                <Link to="/resources-and-information/nets/rejected"><li class="menu-40" title="An IND, NDA, or BLA that is rejected by the FDA because it failed to provide evidence that the investigational compound is safe or effective."></li></Link>
                                <Link to="/resources-and-information/nets/therapy"><li class="menu-41" title="A medical product that has proven to be safe & effective in clinical trials and has therefore received marketing approval."></li></Link>

                                <Link to="/resources-and-information/nets/phase-iv"><li class="menu-42" title="Clinical trials that take place after the drug is approved for use. During Phase IV studies information is gathered about the drug's risks, benefits, and optimal use in larger, more diverse populations. Side effects associated with long-term use are addressed as well."></li></Link>
                                <Link to="/resources-and-information/nets/coverage"><li class="menu-43" title="Refers to establishing drug cost assistance offered through insurance providers, by requesting that these organizations add a new drug to their list of covered or preferred drugs."></li></Link>
                                <Link to="/resources-and-information/nets/clinical-practice"><li class="menu-44" title="The process of determining how to introduce a drug for general use in the clinic setting. This can include educating health care professionals and developing resources."></li></Link>

                                <Link to="/resources-and-information/nets/researcher"><li class="menu-45" title="A person who works in a laboratory or clinical research setting and conducts experiments or studies to learn more about a particular disease or aspect of that disease."></li></Link>
                                <Link to="/resources-and-information/nets/sponsor"><li class="menu-46" title="The company, university, or organization responsible for overseeing al aspects of a clinical research project."></li></Link>
                                <Link to="/resources-and-information/nets/biospecimens"><li class="menu-47" title="Biospecimens are biological samples collected from humans such as blood, urine, tumor and other materials. They are used in biomedical research to learn about how disease affects the human body."></li></Link>
                                <Link to="/resources-and-information/nets/registries"><li class="menu-48" title="Databases of information on individuals affected by a certain disease or condition. There are different types of registries, each requiring different information."></li></Link>
                                <Link to="/resources-and-information/nets/irb"><li class="menu-49" title="An institutional review board (IRB) is a committee of health professionals and researchers that gives approval for human research studies on the basis of protecting the rights and privacy of participants."></li></Link>
                                <Link to="/resources-and-information/nets/agreements"><li class="menu-50" title="Formally document the responsibilities of different professionals involved in clinical research."></li></Link>
                                <Link to="/resources-and-information/nets/cohorts"><li class="menu-51" title="Groups of individuals that share a common characteristic or disease. Cohorts can be helpful in organizing and recruiting eligible participants for a particular research study or clinical trial."></li></Link>
                                <Link to="/resources-and-information/nets/recruitment"><li class="menu-52" title="The process of developing a protocol for conducting clinical research. Describes the what, how and why of the study, as well as participant eligibility and recruitment strategies."></li></Link>
                                <Link to="/resources-and-information/nets/study-staff"><li class="menu-53" title="Oversee the entire clinical trial, and are deemed qualified by their level of education, training and experience in performing their assigned task."></li></Link>
                                <Link to="/resources-and-information/nets/clinical-trials"><li class="menu-54" title="Studies to test the safety and efficacy of new therapeutic treatments, such as drugs or biologics. "></li></Link>
                                <Link to="/resources-and-information/nets/studies"><li class="menu-55" title="Studies that observe individuals with a certain disease or people who are at high risk of developing the disease in order to understand the normal progression of the disease."></li></Link>
                                <Link to="/resources-and-information/nets/consent"><li class="menu-56" title="When a researcher or investigator explains all aspects of a study, including the risks and benefits, to the participant. Participants learn about their rights and usually sign a form stating they understand the study and agree to participate."></li></Link>
                                <Link to="/resources-and-information/nets/enrollment"><li class="menu-57" title="When a participant meets all criterion for partaking in a study and agrees to participate (through informed consent), they become eligible to enroll in the study."></li></Link>
                                <Link to="/resources-and-information/nets/outcomes"><li class="menu-58" title="Measurements of the impact of therapeutic intervention used to evaluate the benefits and drawbacks of investigational drugs or therapies."></li></Link>
                                <Link to="/resources-and-information/nets/endpoint"><li class="menu-59" title="Characteristics that reflect how recipients of a drug or therapy feels, functions, or survives and show the effect of a therapeutic intervention."></li></Link>
                                <Link to="/resources-and-information/nets/update"><li class="menu-60" title="A study that adds new information to an existing natural history study."></li></Link>
                                <Link to="/resources-and-information/nets/phase-i"><li class="menu-61" title="Initial studies evaluating the pharmacokinetics (PK), safety, dosage range, and side effects of investigational drugs or therapies in humans. Participants are healthy volunteers."></li></Link>
                                <Link to="/resources-and-information/nets/subpopulations"><li class="menu-62" title="The process of grouping individuals affected by a disease by their commonalities, to understand why affected individuals have different symptoms and responses to drugs."></li></Link>
                                <Link to="/resources-and-information/nets/phase-ii"><li class="menu-63" title="Studies to evaluate the effectiveness of an investigational drug or therapy and observe short-term side effects or risks. Tests a larger group of participants than Phase I, including those affected by the disease or condition of interest."></li></Link>
                                <Link to="/resources-and-information/nets/responders"><li class="menu-64" title="Individuals in whom a drug produces a desired response, and individuals who do not experience a desired response to a drug."></li></Link>
                                <Link to="/resources-and-information/nets/phase-iii"><li class="menu-65" title="Large studies which confirm drug safety and efficacy, containing a greater number of participants than Phase II trials. Participants are usually randomly assiged to a control or treatment group."></li></Link>
                                <Link to="/resources-and-information/nets/re-evaluate"><li class="menu-66" title="When new data suggests an investigational compound has adverse effects or is ineffective, health administrations may re-evaluate the drug. If the health administration revokes their approval the drug is discontinued. "></li></Link>
                                <Link to="/resources-and-information/nets/care"><li class="menu-67" title="The consensus among medical professionals on the proper use of the new drug and treatment of patients."></li></Link>
                                
                                <Link to="/resources-and-information/nets/preparation"><li class="menu-68" title="The process of developing a protocol for conducting clinical research. Describes the what, how and why of the study, as well as participant eligibility and recruitment strategies."></li></Link>
                                <Link to="/resources-and-information/nets/nets-design"><li class="menu-69" title="The protocol that is developed for a clinical trial or study to test a hypothesis. It addresses factors such as the number of participants and controls necessary for a valid study."></li></Link>
                            </ul>
                        </nav>
                        <div className='footnote full-view-tablet mt-40'>
                            <p className='p-small'>* Investigational treatments may be candidates for compassionate use.</p>
                            <p className='p-small'>† This phase of drug development contributes to epidemiology.</p>
                        </div>
                    </div>
                    <div className='mobile-only full-view-mobile'>
                        <Image id='fullViewMobile' src='/images/resources-and-information/nets/NETS-full-view.png' onClick={() => setFullScreen(true)} />
                        <div className='footnote full-view-mobile mt-40'>
                            <p className='p-small'>* Investigational treatments may be candidates for compassionate use.</p>
                            <p className='p-small'>† This phase of drug development contributes to epidemiology.</p>
                        </div>
                    </div>
                    <div className={fullScreen ? 'show-full-screen' : 'hide-full-screen'} style={{overflow: 'overlay'}}>
                    <div className='full-screen-exit-container' onClick={() => setFullScreen(false)}><Image src='/icons/search-exit.png' className='full-screen-exit-image icon-width-16px' /></div>
                        <div>
                            <nav id="fullScreenFullView" style={{background: `url('/images/resources-and-information/nets/NETS-Final-1900px.jpg')`, backgroundSize: '864px 994px'}}>
                                <ul style={{margin:'0px'}}>
                                    <Link to="/resources-and-information/nets/bioinformatics" onClick={() => setFullScreen(false)}><li class="menu-1" title="The application of computer science and information technology to the field of molecular biology."></li></Link>
                                    <Link to="/resources-and-information/nets/hypothesis" onClick={() => setFullScreen(false)}><li class="menu-2" title="Proposed explanations for a phenomenon that are formed from observations, and tested during experimentation."></li></Link>
                                    <Link to="/resources-and-information/nets/pathophysiology" onClick={() => setFullScreen(false)}><li class="menu-3" title="The study of how diseases alter the functions of cells, organs, organ systems, and how these changes affect the entire body."></li></Link>
                                    <Link to="/resources-and-information/nets/targets" onClick={() => setFullScreen(false)}><li class="menu-4" title="Molecular sites used for therapeutic intervention to try and cure a disease/disorder or provide symptom relief. "></li></Link>
                                    <Link to="/resources-and-information/nets/repositories" onClick={() => setFullScreen(false)}><li class="menu-5" title="Information warehouses where large amounts of data  from multiple sources (such as  scientific publications, research data, and clinical information) are stored."></li></Link>
                                    <Link to="/resources-and-information/nets/cell-lines" onClick={() => setFullScreen(false)}><li class="menu-6" title="Collections of sample cells extracted from donors and stored to give researchers continual access to specific cell types, without the need for additional sample donations."></li></Link>
                                    <Link to="/resources-and-information/nets/animal-models" onClick={() => setFullScreen(false)}><li class="menu-7" title="Non-human organisms used in research and engineered to reproduce the physical or molecular changes that occur in human disease."></li></Link>
                                    <Link to="/resources-and-information/nets/pathway" onClick={() => setFullScreen(false)}><li class="menu-8" title="A series of interactions that occur between the molecules in a cell. Understanding these pathways for a disease can help scientists select the best therapeutic targets."></li></Link>
                                    <Link to="/resources-and-information/nets/biorepositories" onClick={() => setFullScreen(false)}><li class="menu-9" title="Places for collecting and storing biological samples such as blood, urine, tumors, and other tissues."></li></Link>
                                    <Link to="/resources-and-information/nets/genotypes" onClick={() => setFullScreen(false)}><li class="menu-10" title="An individualís genetic makeup, coded in their DNA. Expression of an individualís genotype determines their phenotype or observable traits."></li></Link>
                                    <Link to="/resources-and-information/nets/phenotypes" onClick={() => setFullScreen(false)}><li class="menu-11" title="An individualís observable traits (such as height or eye color). Phenotypes are influenced by an individual's genotype and environmental factors."></li></Link>

                                    <li class='purified-protein' title='Coming soon!'/>
                                    <li class='nbe-development' title='Coming soon!'/>
                                    <Link to="/resources-and-information/nets/development" onClick={() => setFullScreen(false)}><li class="menu-12" title="An assay is an analytical test that measures the activity of a chemical compound on a target of interest.  Different assays are made for different diseases and molecular targets."></li></Link>
                                    <Link to="/resources-and-information/nets/hts" onClick={() => setFullScreen(false)}><li class="menu-13" title="An assay (test for measuring a compound for desired effects) compatible with high throughput screening (HTS) systems. With both components, thousands of compounds can be tested rapidly to determine whether any of them have desired effects."></li></Link>
                                    <Link to="/resources-and-information/nets/system" onClick={() => setFullScreen(false)}><li class="menu-14" title="Consists of the robotic processes and equipment needed for scientists to conduct high throughput experiments - biochemical, genetic or pharmocological tests on large numbers of candidate compounds, typially used to identify compounds that have a desired effect on a therapeutic target."></li></Link>
                                    <Link to="/resources-and-information/nets/libraries" onClick={() => setFullScreen(false)}><li class="menu-15" title="Stores of chemical compounds and small molecules, with detailed information on physical descriptions and known physiological interactions."></li></Link>
                                    <Link to="/resources-and-information/nets/misses" onClick={() => setFullScreen(false)}><li class="menu-16" title="Compounds that do not produce the desired effect in a specific HTS assay, but may be effective in an assay for a different disease."></li></Link>
                                    <Link to="/resources-and-information/nets/hits" onClick={() => setFullScreen(false)}><li class="menu-17" title="A compound that produces the desired effect in an HTS system and therefore qualifies for additional investigation into its potential therapeutic effects."></li></Link>
                                    <Link to="/resources-and-information/nets/approved" onClick={() => setFullScreen(false)}><li class="menu-18" title="A compound that is already approved for a specific use in humans, but is sometimes repurposed to treat a different disease than the one it was initially designed for."></li></Link>
                                    <Link to="/resources-and-information/nets/unapproved" onClick={() => setFullScreen(false)}><li class="menu-19" title="A compound that has not been approved for use in humans. These compounds may be classified as new chemical entities (NCEs) or new molecular entities (NMEs)."></li></Link>

                                    <Link to="/resources-and-information/nets/lead" onClick={() => setFullScreen(false)}><li class="menu-20" title="A molecule or a collection of molecules that has potential for therapeutic use after demonstrating the desired response against a specific target."></li></Link>
                                    <Link to="/resources-and-information/nets/manufacture" onClick={() => setFullScreen(false)}><li class="menu-21" title="Refers to producing a set of data containing detailed information on the chemical structure and stability of a compound, and the production and quality controls used in its manufacture."></li></Link>
                                    <Link to="/resources-and-information/nets/medicinal" onClick={() => setFullScreen(false)}><li class="menu-22" title="The design and production of compounds intended to be used as medicine in humans. Compounds with potential therapeutic effects can be optimized to reduce unwanted side effects and improve their interaction with the therapeutic target."></li></Link>
                                    <Link to="/resources-and-information/nets/non-glp" onClick={() => setFullScreen(false)}><li class="menu-23" title="Early toxicology and pharmacology studies that do not need to follow Good Laboratory Practices (GLP) - a certification issued by regulatory bodies to ensure that the data submitted to them has been obtained using the proper procedures and is thoroughly documented."></li></Link>
                                    <Link to="/resources-and-information/nets/acute" onClick={() => setFullScreen(false)}><li class="menu-24" title="The study of adverse effects that result soon after a single dose or multiple doses of a substance administered in close succession."></li></Link>
                                    <Link to="/resources-and-information/nets/in-vitro" onClick={() => setFullScreen(false)}><li class="menu-25" title="The study of the biological effects of a drug. <em>In vitro</em> studies test drugs in an isolated environment (such as a cell line or tissue), while <em>in vivo studies</em> tests drugs in a complex living organism."></li></Link>
                                    <Link to="/resources-and-information/nets/safety" onClick={() => setFullScreen(false)}><li class="menu-26" title="Refers to a therapeutic index or a numerical value that represents a drugs harmful effects compared to its therapeutic effects, determined through acute toxicology studies."></li></Link>
                                    <Link to="/resources-and-information/nets/dose" onClick={() => setFullScreen(false)}><li class="menu-27" title="The quantity or concentration of drug that is administered. During pre-clinical research, data is collected on the safety and efficacy at different doses."></li></Link>
                                    <Link to="/resources-and-information/nets/efficacy" onClick={() => setFullScreen(false)}><li class="menu-28" title="Demonstrate that a compound is capable of having the desired therapeutic effects."></li></Link>
                                    <Link to="/resources-and-information/nets/compound" onClick={() => setFullScreen(false)}><li class="menu-29" title="A lead compound or biologic that has been optimized using medicinal chemistry to reduce off-target effects, and improve its interaction with the therapeutic target."></li></Link>
                                    <Link to="/resources-and-information/nets/cgmp" onClick={() => setFullScreen(false)}><li class="menu-30" title="Current Good Manufacturing Practices (cGMP) are part of a system covering the manufacture and testing of drugs. Before a drug can be tested in humans, regulatory bodies require sponsors to show the drug has been made in accordance with these standards."></li></Link>
                                    <Link to="/resources-and-information/nets/glp" onClick={() => setFullScreen(false)}><li class="menu-31" title="Later preclinical studies in pharmacology and toxicology should be completed using the documentation procedures found in the GLP (Good Laboratory Practices) standards."></li></Link>
                                    <Link to="/resources-and-information/nets/pk" onClick={() => setFullScreen(false)}><li class="menu-32" title="Pharmacokinetics (PK) is the study of how a drug is metabolized and distributed throughout the body. PK (ADME) refers to PK studies that quantitatively measure the absorption, distribution, metabolism and elimination (ADME) of a compound in a living organism."></li></Link>
                                    <Link to="/resources-and-information/nets/pharmacology" onClick={() => setFullScreen(false)}><li class="menu-33" title="The study of the biological effects of a drug and its interaction with living systems."></li></Link>
                                    <Link to="/resources-and-information/nets/toxicology" onClick={() => setFullScreen(false)}><li class="menu-34" title="Studies on the toxic effects, behavioral and physical, of a drug when it's administered over an extended period of time."></li></Link>
                                    <Link to="/resources-and-information/nets/product" onClick={() => setFullScreen(false)}><li class="menu-35" title="Ensures consistent drug accessibility by confirming the adequacy and availability of all drug ingredients, manufacturing capacity and delivery methods before a therapy is made available to the public."></li></Link>

                                    <Link to="/resources-and-information/nets/ind" onClick={() => setFullScreen(false)}><li class="menu-36" title="Investigational New Drug (IND) or, in Europe, a clinical trial authorization (CTA) application. Both must be submitted to the relevant health authorities to obtain permission to begin a clinical trial. Click on the box to see IND application requirements."></li></Link>
                                    <Link to="/resources-and-information/nets/health-authorities" onClick={() => setFullScreen(false)}><li class="menu-37" title="Health Adminitration (HA) reporting meetings allow drug sponsors to discuss IND applications with regulatory officials, and make any necessary updates before the official ruling on a new drug application."></li></Link>
                                    <Link to="/resources-and-information/nets/nda" onClick={() => setFullScreen(false)}><li class="menu-38" title="FDA applications required for the medical use of a substance. A Biologic License Application (BLA) requests permission to introduce a biologic product into interstate commerce. A New Drug Application (NDA) is the formal request to market a new pharmaceutical product. Click on the box to find out what materials each application requires."></li></Link>
                                    <Link to="/resources-and-information/nets/indication" onClick={() => setFullScreen(false)}><li class="menu-39" title="After a drug has been on the market for some time, new uses may be added to the drug's prescribing information as an FDA-approved indication."></li></Link>
                                    <Link to="/resources-and-information/nets/rejected" onClick={() => setFullScreen(false)}><li class="menu-40" title="An IND, NDA, or BLA that is rejected by the FDA because it failed to provide evidence that the investigational compound is safe or effective."></li></Link>
                                    <Link to="/resources-and-information/nets/therapy" onClick={() => setFullScreen(false)}><li class="menu-41" title="A medical product that has proven to be safe & effective in clinical trials and has therefore received marketing approval."></li></Link>

                                    <Link to="/resources-and-information/nets/phase-iv" onClick={() => setFullScreen(false)}><li class="menu-42" title="Clinical trials that take place after the drug is approved for use. During Phase IV studies information is gathered about the drug's risks, benefits, and optimal use in larger, more diverse populations. Side effects associated with long-term use are addressed as well."></li></Link>
                                    <Link to="/resources-and-information/nets/coverage" onClick={() => setFullScreen(false)}><li class="menu-43" title="Refers to establishing drug cost assistance offered through insurance providers, by requesting that these organizations add a new drug to their list of covered or preferred drugs."></li></Link>
                                    <Link to="/resources-and-information/nets/clinical-practice" onClick={() => setFullScreen(false)}><li class="menu-44" title="The process of determining how to introduce a drug for general use in the clinic setting. This can include educating health care professionals and developing resources."></li></Link>

                                    <Link to="/resources-and-information/nets/researcher" onClick={() => setFullScreen(false)}><li class="menu-45" title="A person who works in a laboratory or clinical research setting and conducts experiments or studies to learn more about a particular disease or aspect of that disease."></li></Link>
                                    <Link to="/resources-and-information/nets/sponsor" onClick={() => setFullScreen(false)}><li class="menu-46" title="The company, university, or organization responsible for overseeing al aspects of a clinical research project."></li></Link>
                                    <Link to="/resources-and-information/nets/biospecimens" onClick={() => setFullScreen(false)}><li class="menu-47" title="Biospecimens are biological samples collected from humans such as blood, urine, tumor and other materials. They are used in biomedical research to learn about how disease affects the human body."></li></Link>
                                    <Link to="/resources-and-information/nets/registries" onClick={() => setFullScreen(false)}><li class="menu-48" title="Databases of information on individuals affected by a certain disease or condition. There are different types of registries, each requiring different information."></li></Link>
                                    <Link to="/resources-and-information/nets/irb" onClick={() => setFullScreen(false)}><li class="menu-49" title="An institutional review board (IRB) is a committee of health professionals and researchers that gives approval for human research studies on the basis of protecting the rights and privacy of participants."></li></Link>
                                    <Link to="/resources-and-information/nets/agreements" onClick={() => setFullScreen(false)}><li class="menu-50" title="Formally document the responsibilities of different professionals involved in clinical research."></li></Link>
                                    <Link to="/resources-and-information/nets/cohorts" onClick={() => setFullScreen(false)}><li class="menu-51" title="Groups of individuals that share a common characteristic or disease. Cohorts can be helpful in organizing and recruiting eligible participants for a particular research study or clinical trial."></li></Link>
                                    <Link to="/resources-and-information/nets/recruitment" onClick={() => setFullScreen(false)}><li class="menu-52" title="The process of developing a protocol for conducting clinical research. Describes the what, how and why of the study, as well as participant eligibility and recruitment strategies."></li></Link>
                                    <Link to="/resources-and-information/nets/study-staff" onClick={() => setFullScreen(false)}><li class="menu-53" title="Oversee the entire clinical trial, and are deemed qualified by their level of education, training and experience in performing their assigned task."></li></Link>
                                    <Link to="/resources-and-information/nets/clinical-trials" onClick={() => setFullScreen(false)}><li class="menu-54" title="Studies to test the safety and efficacy of new therapeutic treatments, such as drugs or biologics. "></li></Link>
                                    <Link to="/resources-and-information/nets/studies" onClick={() => setFullScreen(false)}><li class="menu-55" title="Studies that observe individuals with a certain disease or people who are at high risk of developing the disease in order to understand the normal progression of the disease."></li></Link>
                                    <Link to="/resources-and-information/nets/consent" onClick={() => setFullScreen(false)}><li class="menu-56" title="When a researcher or investigator explains all aspects of a study, including the risks and benefits, to the participant. Participants learn about their rights and usually sign a form stating they understand the study and agree to participate."></li></Link>
                                    <Link to="/resources-and-information/nets/enrollment" onClick={() => setFullScreen(false)}><li class="menu-57" title="When a participant meets all criterion for partaking in a study and agrees to participate (through informed consent), they become eligible to enroll in the study."></li></Link>
                                    <Link to="/resources-and-information/nets/outcomes" onClick={() => setFullScreen(false)}><li class="menu-58" title="Measurements of the impact of therapeutic intervention used to evaluate the benefits and drawbacks of investigational drugs or therapies."></li></Link>
                                    <Link to="/resources-and-information/nets/endpoint" onClick={() => setFullScreen(false)}><li class="menu-59" title="Characteristics that reflect how recipients of a drug or therapy feels, functions, or survives and show the effect of a therapeutic intervention."></li></Link>
                                    <Link to="/resources-and-information/nets/update" onClick={() => setFullScreen(false)}><li class="menu-60" title="A study that adds new information to an existing natural history study."></li></Link>
                                    <Link to="/resources-and-information/nets/phase-i" onClick={() => setFullScreen(false)}><li class="menu-61" title="Initial studies evaluating the pharmacokinetics (PK), safety, dosage range, and side effects of investigational drugs or therapies in humans. Participants are healthy volunteers."></li></Link>
                                    <Link to="/resources-and-information/nets/subpopulations" onClick={() => setFullScreen(false)}><li class="menu-62" title="The process of grouping individuals affected by a disease by their commonalities, to understand why affected individuals have different symptoms and responses to drugs."></li></Link>
                                    <Link to="/resources-and-information/nets/phase-ii" onClick={() => setFullScreen(false)}><li class="menu-63" title="Studies to evaluate the effectiveness of an investigational drug or therapy and observe short-term side effects or risks. Tests a larger group of participants than Phase I, including those affected by the disease or condition of interest."></li></Link>
                                    <Link to="/resources-and-information/nets/responders" onClick={() => setFullScreen(false)}><li class="menu-64" title="Individuals in whom a drug produces a desired response, and individuals who do not experience a desired response to a drug."></li></Link>
                                    <Link to="/resources-and-information/nets/phase-iii" onClick={() => setFullScreen(false)}><li class="menu-65" title="Large studies which confirm drug safety and efficacy, containing a greater number of participants than Phase II trials. Participants are usually randomly assiged to a control or treatment group."></li></Link>
                                    <Link to="/resources-and-information/nets/re-evaluate" onClick={() => setFullScreen(false)}><li class="menu-66" title="When new data suggests an investigational compound has adverse effects or is ineffective, health administrations may re-evaluate the drug. If the health administration revokes their approval the drug is discontinued. "></li></Link>
                                    <Link to="/resources-and-information/nets/care" onClick={() => setFullScreen(false)}><li class="menu-67" title="The consensus among medical professionals on the proper use of the new drug and treatment of patients."></li></Link>
                                    
                                    <Link to="/resources-and-information/nets/preparation" onClick={() => setFullScreen(false)}><li class="menu-68" title="The process of developing a protocol for conducting clinical research. Describes the what, how and why of the study, as well as participant eligibility and recruitment strategies."></li></Link>
                                    <Link to="/resources-and-information/nets/nets-design" onClick={() => setFullScreen(false)}><li class="menu-69" title="The protocol that is developed for a clinical trial or study to test a hypothesis. It addresses factors such as the number of participants and controls necessary for a valid study."></li></Link>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default NETSFullView