import { Button, Container, Grid, Image } from 'semantic-ui-react'
import ShareSocialMedia from '../ShareSocialMedia'
import ReactHtmlParser from 'react-html-parser'

function OneStaff({ data, linkURL, ...props }) {
	const { isStaff, shareUrl } = props;
	// const item = props.item;
	// const shareUrl = item.shareUrl;
	const item = data.find(i => (isStaff ? i.menu === 'Staff' : i.menu === 'Council') && i.linkURL === linkURL)
	console.log("item", item)
	return (
		<Container className='mb-80-50-40 container-main'>
			<h1>{item.name}</h1>
			<hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
			<Grid>
				<Grid.Column className='mb-5-mobile' mobile={16} tablet={7} computer={4}>
					<Image src={item.imageURLDetail} className='staff-icon-size staff-bio' />
				</Grid.Column>
				<Grid.Column mobile={16} tablet={8} computer={4}>
					<p className='p-compact'>{item.title}</p>
					<ShareSocialMedia shareUrl={shareUrl} />
					{item.href ? <a
						href={item.href}
						target='_blank'
						rel='noreferrer'
						className='link-button'
					>
						<Button className='button-white mt-15'><Image src='/icons/document.png' className='icon-width-16px' />&nbsp;&nbsp;{item.buttonContent}&nbsp;&nbsp;<Image src='/icons/link-internal-blue.png' className='icon-width-16px' /></Button>
					</a>
						: null}
				</Grid.Column>
				<Grid.Column mobile={16} tablet={16} computer={8}>
					{ReactHtmlParser(item.content)}
				</Grid.Column>
			</Grid>
		</Container>
	)
}

export default OneStaff
