import { Grid, Image } from 'semantic-ui-react'
// import { council } from './CouncilList'
import { Link, useLocation } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'
import { progressIndicator } from '../../../content/HelperFunction'
import { useEffect } from 'react'

function CouncilFillContent({ data }) {
	const { pathname, hash, key } = useLocation()
	useEffect(() => {
		// if not a hash link, scroll to top
		if (hash === '') {
			window.scrollTo(0, 0)
		}
		// else scroll to id
		else {
			setTimeout(() => {
				const id = hash.replace('#', '')
				const element = document.getElementById(id)
				if (element) {
					element.scrollIntoView({ behavior: 'smooth' })
				}
			}, 0)
		}
	}, [pathname, hash, key])

	useEffect(() => {
		progressIndicator()
	}, [])

	const council = data.filter(
		item => item.title !== undefined && item.menu === 'Council'
	)
	if (council && Array.isArray(council) && council.length > 0) {
		council.sort((a, b) => a.orderBy - b.orderBy)
	}

	const councilTopContent = data.filter(
		item => item.link === '/about' && item.part === 'council-top-content'
	)
	// console.log('councilTopContent', councilTopContent)
	const councilBottomContent = data.filter(
		item => item.link === '/about' && item.part === 'council-bottom-content'
	)
	// console.log('councilBottomContent', councilBottomContent)
	const listRows = []
	if (
		councilBottomContent &&
		Array.isArray(councilBottomContent) &&
		councilBottomContent.length > 0
	) {
		councilBottomContent.sort((a, b) => a.orderBy - b.orderBy)
		let countRow = councilBottomContent.length / 3
		if (councilBottomContent.length % 3 > 0) {
			countRow++
		}
		for (let i = 0; i < countRow; i++) {
			const listCols = []
			for (let j = 0; j < 3; j++) {
				listCols.push(
					<Grid.Column key={i * 3 + j}>
						<p
							id={councilBottomContent[i * 3 + j].id}
							className='header-tile'
							style={{ marginBottom: '16px' }}>
							{councilBottomContent[i * 3 + j].header}
						</p>
						{ReactHtmlParser(councilBottomContent[i * 3 + j].content)}
					</Grid.Column>
				)
			}
			listRows.push(
				<Grid.Row key={i} className='mb-80-40-20 grid-row'>
					{listCols}
				</Grid.Row>
			)
		}
	}

	return (
		<div>
			<h1 className='mb-80-35-15'>Council</h1>
			<Grid columns={4} className='computer-only'>
				{council.map((person, index) => (
					<Grid.Column key={index}>
						<Link to={`/about/council/${person.linkURL}`}>
							<Image
								src={`/council/${person.imageURL}`}
								centered
								className='staff-icon-size'
							/>
						</Link>
						<p className='p-compact  mt-8 mb-0'>
							<b>{person.name}</b>
						</p>
						<p className='p-compact mb-50-30-10 mt-0'>
							{person.title}
							<br />
							{person.title2}
						</p>
					</Grid.Column>
				))}
			</Grid>
			<Grid columns={3} className='tablet-only'>
				{council.map((person, index) => (
					<Grid.Column key={index}>
						<Link to={`/about/council/${person.linkURL}`}>
							<Image
								src={`/council/${person.imageURL}`}
								centered
								className='staff-icon-size'
							/>
						</Link>
						<p className='p-compact  mt-8 mb-0'>
							<b>{person.name}</b>
						</p>
						<p className='p-compact mb-50-30-10 mt-0'>
							{person.title}
							<br />
							{person.title2}
						</p>
					</Grid.Column>
				))}
			</Grid>
			<Grid columns={2} className='mobile-only'>
				{council.map((person, index) => (
					<Grid.Column key={index}>
						<Link to={`/about/council/${person.linkURL}`}>
							<Image
								src={`/council/${person.imageURL}`}
								centered
								className='staff-icon-size'
							/>
						</Link>
						<p className='p-compact  mt-8 mb-0'>
							<b>{person.name}</b>
						</p>
						<p className='p-compact mb-50-30-10 mt-0'>
							{person.title}
							<br />
							{person.title2}
						</p>
					</Grid.Column>
				))}
			</Grid>
			<hr className='mt-65-45-25 mb-75-55-15 rule-blue-300' />
			<div className='div-grid-container'>
				<Grid columns={2} stackable>
					<Grid.Column mobile={16} tablet={16} computer={8}>
						<h3>
							{councilTopContent &&
								councilTopContent[0] &&
								councilTopContent[0].header}
						</h3>
					</Grid.Column>
					<Grid.Column mobile={16} tablet={16} computer={8}>
						{ReactHtmlParser(
							councilTopContent && councilTopContent[0] && councilTopContent[0].content
						)}
					</Grid.Column>
				</Grid>
			</div>
			<hr className='mt-75-55-35 mb-75-55-15 rule-blue-300' />
			<div className='div-grid-container'>
				<Grid columns={3} stackable>
					{listRows}
				</Grid>
			</div>
		</div>
	)
}

export default CouncilFillContent
