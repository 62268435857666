import { Container, Grid, Image, Button } from 'semantic-ui-react'
import React from 'react'
import { Link } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'
import './GARegistry.css'

function GARegistryFillContent({ data }) {
	console.log('GARegistryFillContent data', data)
	if (!data) {
		return null
	}
	const datas = data.filter(item => item.link === '/registries/ga-registry')
	if (datas && Array.isArray(datas) && datas.length > 0) {
		datas.sort((a, b) => a.index - b.index)
	} else {
		return null
	}
	return (
		<div id='GARegistry'>
			{' '}
			{/* container for whole page */}
			<div className='banner-with-text mt-80-80-40'>
				{' '}
				{/* container for banner section */}
				<Image
					src='/banners/ga-registry.svg'
					centered
					className='computer-tablet-only banner-computer-tablet'
				/>
				<Image
					src='/banners/ga-registry-mobile.svg'
					centered
					className='mobile-only banner-mobile'
				/>
				<div className='banner-text'>
					{' '}
					{/* container for text within the banner section */}
					<h1 className='white-text'>{datas[0].header}</h1>
					<p className='subtitle white-text'>{datas[0].content}</p>
				</div>
			</div>
			<Container className='container-ga-registry mt-120-60-40'>
				{' '}
				{/* container for second, third, and fourth sections */}
				<Grid columns={2}>
					{/* second section */}
					<Grid.Column mobile={16} tablet={16} computer={7}>
						<p id={datas[1].id} className='first-paragraph'>
							{datas[1].content}
						</p>
					</Grid.Column>
					<Grid.Column
						mobile={16}
						tablet={16}
						computer={9}
						className='computer-tablet-only'>
						<Image
							src='/images/registries/registry-diagram.png'
							className='diagram'
						/>
					</Grid.Column>
				</Grid>
				<hr className='rule-blue-800 mt-120-60-40 mb-120-60-40' />
				<Grid columns={2}>
					{' '}
					{/* third section */}
					<Grid.Row className='mb-80-60-40 padding-0'>
						<Grid.Column mobile={16} tablet={8} computer={8}>
							<h3 id={datas[2].id} className='h3 mb-25'>
								{datas[2].header}
							</h3>
						</Grid.Column>
						<Grid.Column mobile={16} tablet={8} computer={8}>
							<p className='subtitle third-section'>{datas[2].content}</p>
							<ul className='mb-0'>{ReactHtmlParser(datas[2].listContent)}</ul>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className='mb-80-60-40 padding-0'>
						<Grid.Column mobile={16} tablet={8} computer={8}>
							<h3 id={datas[3].id} className='h3 mb-25'>
								{datas[3].header}
							</h3>
						</Grid.Column>
						<Grid.Column mobile={16} tablet={8} computer={8}>
							<p className='subtitle third-section'>{datas[3].content}</p>
							<ul className='mb-0'>{ReactHtmlParser(datas[3].listContent)}</ul>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className='mb-80-60-40 padding-0'>
						<Grid.Column mobile={16} tablet={8} computer={8}>
							<h3 id={datas[4].id} className='h3 mb-25'>
								{datas[4].header}
							</h3>
						</Grid.Column>
						<Grid.Column mobile={16} tablet={8} computer={8}>
							<p className='subtitle third-section'>{datas[4].content}</p>
							<ul className='mb-0'>{ReactHtmlParser(datas[4].listContent)}</ul>
							<a
								href='https://drive.google.com/file/d/1pWAudZT7D69CNMQHPoVI0XlFM0vI8DTh/view?usp=sharing'
								target='_blank'
								rel='noreferrer noopener'
								className='inline-block link-button'>
								<Button className='button-neutral-100 mt-25'>
									<Image src='/icons/document.png' className='icon-width-16px mr-8' />
									Learn more
								</Button>
							</a>
						</Grid.Column>
					</Grid.Row>
					<a
						href='https://forms.gle/obbe92uCDNQ6cdyDA'
						target='_blank'
						rel='noreferrer noopener'
						className='inline-block link-button pb-15'>
						<Button className='button-orange-500'>
							Contact us for more information&nbsp;&nbsp;
							<Image
								src='/icons/link-internal-white.png'
								className='icon-width-16px'
							/>
						</Button>
					</a>
				</Grid>
				<hr className='rule-blue-800 mt-80-60-40 mb-80-60-40' />
				<Grid columns={3} stackable>
					{' '}
					{/* fourth section */}
					<Grid.Column>
						<Link className='link-button scrim-white-background' to='/irb'>
							<Image
								height='279'
								src='/images/registries/IRB-Logo_Square.png'
								className='image-radius image-home scrim-white-effect'
							/>
							<h4 className='mt-15 mb-40 link-blue-800 link-underline-none home-tools-link'>
								Genetic Alliance IRB
							</h4>
						</Link>
					</Grid.Column>
					<Grid.Column>
						<Link className='link-button scrim-white-background' to='/biobank'>
							<Image
								height='279'
								src='/images/registries/BioBank.png'
								className='image-radius image-home scrim-white-effect'
							/>
							<h4 className='mt-15 mb-40 link-blue-800 link-underline-none home-tools-link'>
								Genetic Alliance BioBank
							</h4>
						</Link>
					</Grid.Column>
					<Grid.Column>
						<Link
							to='/registries/bootcamp'
							rel='noreferrer'
							className='link-button scrim-white-background'>
							<Image
								height='279'
								src='/images/registries/research-bootcamp.png'
								className='image-radius image-home scrim-white-effect'
							/>
							<h4 className='mt-15 link-blue-800 link-underline-none home-tools-link'>
								Registry Bootcamp
							</h4>
						</Link>
					</Grid.Column>
				</Grid>
			</Container>
		</div>
	)
}

export default GARegistryFillContent
