import React from 'react'
import { Container, Grid } from 'semantic-ui-react'

function JuliaAnnouncement() {
	return (
		<>
			<Container className='container-main'>
				<h1>
                    Genetic Alliance Welcomes Julia Ortega 
				</h1>
				<Grid columns={1} padded>
					<Grid.Row>
						<Grid.Column>
							<p>
								Genetic Alliance is delighted to welcome{' '}
								<a
									href='https://www.linkedin.com/in/ACoAAAE_B0sBNGdi4diGH5g_9rSdQVqQgQ86Al0'
									target='_blank'
									rel='noreferrer'>
									Julia Ortega
								</a>{' '}
								 to our team specifically to lead the iHope Genetic Health program. Julia assumes the new role of Vice President, iHope Genetic Health, a senior leadership position in Genetic Alliance. iHope will offer free whole genome sequencing to undiagnosed individuals in low to middle income areas. The data will be controlled by the people.{' '}
								 <a
									href='http://ihopegenetichealth.org/'
									target='_blank'
									rel='noreferrer'>
									ihopegenetichealth.org
								</a>
								 .
							</p>
							<p>
                                Julia brings more than thirty years of experience in the biotechnology and healthcare industry. She is a quintessential patient advocate in her various roles from clinical to research. Skilled in operational leadership, clinical translational research, and hands-on patient care, she seeks to develop large-scale effective systems in healthcare solely to improve the lives of people.  
							</p>
							<p>
                                Prior to joining Genetic Alliance, Julia was a Director of Scientific Research at Illumina, Inc where she ran the daily operational activities of the Illumina iHope Program as well as a portfolio of several clinical research genomic projects that utilized whole genome sequencing and multi-omic technologies.
							</p>
							<p>
                                Before Illumina, Julia developed clinical programs for populations in need based on medical and prescription aggregate claims data for United Health Group, Aetna, and Blue Shield of California.  Julia was honored to receive a Health Resources and Services Administration’s (HRSA/HHS) Bureau of Primary Care scholarship that enabled her to earn both a Physician Assistant and a Masters in Health Science degree from Duke University School of Medicine. She then practiced in internal medicine and geriatrics at a federally qualified health center and in skilled nursing facilities for over 15 years. Her baccalaureate degree is from the phenomenal Spelman College, a historically black liberal arts college for women located in Atlanta, Georgia, whose mission is to empower the whole person to engage the many cultures of the world and inspire a commitment to positive social change.
							</p>
							<p>
								“Genetic Alliance and the people we serve are so fortunate that Julia is leading iHope Genetic Health. Her passion, heart, and commitment move me greatly and is just what this program needs. iHope Genetic Health will change the world in all the ways that matter in health, particularly putting people in the driver’s seat. Julia has lived this quest her whole life in a variety of ways. We are thrilled to have her lead this team!“, states CEO Sharon Terry. Genetic Alliance will announce the iHope Genetic Health Team in a few weeks.
							</p>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Container>
		</>
	)
}
export default JuliaAnnouncement