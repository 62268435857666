import { Grid, Form } from 'semantic-ui-react'
import './IRBApplication.css'
import React, { useEffect, useState } from 'react'
import NextBackStep from './NextBackStep'

function StepThree({ step, setStep, totalSteps, updateFields, ...data }) {
	const {
		piName,
		piTitle,
		piDepartment,
		piEmail,
		piPhone,
		investigatorContact,
		investigatorOther,
	} = data

	const [nameError, setNameError] = useState(false)
	const [titleError, setTitleError] = useState(false)
	const [departmentError, setDepartmentError] = useState(false)
	const [emailError, setEmailError] = useState(false)
	const [phoneError, setPhoneError] = useState(false)
	const [investigatorError, setInvestigatorError] = useState(false)
	const [investigatorContactError, setInvestigatorContactError] = useState(false)

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	const isNameMissing = () => {
		if (piName === '') {
			setNameError(true)
			return true
		}
		return false
	}
	const isTitleMissing = () => {
		if (piTitle === '') {
			setTitleError(true)
			return true
		}
		return false
	}
	const isDepartmentMissing = () => {
		if (piDepartment === '') {
			setDepartmentError(true)
			return true
		}
		return false
	}

	const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
	const isEmailMissing = () => {
		if (piEmail === '') {
			setEmailError(true)
			return true
		}
		if (piEmail && !piEmail.match(mailformat)) {
			setEmailError(true)
			return true
		}
		return false
	}
	const isPhoneMissing = () => {
		if (piPhone === '') {
			setPhoneError(true)
			return true
		}
		return false
	}
	
	const isInvestigatorMissing = () => {
		if (!investigatorOther) {
			setInvestigatorError(true)
			return true
		}
		return false
	}
	const isInvestigatorContactMissing = () => {
		if (investigatorContact === '' && investigatorOther === 'Yes') {
			setInvestigatorContactError(true)
			return true
		}
		return false
	}

	let missingFields =
		nameError ||
		titleError ||
		departmentError ||
		emailError ||
		phoneError ||
		investigatorError ||
		investigatorContactError

	const next = () => {
		isNameMissing()
		isTitleMissing()
		isDepartmentMissing()
		isEmailMissing()
		isPhoneMissing()
		isInvestigatorMissing()
		isInvestigatorContactMissing()
		if (
			isNameMissing() === false &&
			isTitleMissing() === false &&
			isDepartmentMissing() === false &&
			isEmailMissing() === false &&
			isPhoneMissing() === false &&
			isInvestigatorMissing() === false &&
			isInvestigatorContactMissing() === false
		) {
			setStep(i => {
				console.log('step i', i)
				return i + 1
			})
		}
	}

	return (
		<Grid centered>
			<Grid.Column computer={10}>
				<h3 className='mb-40'>Principle Investigator (PI) </h3>
				<p>Enter the PI’s information below:</p>
				<hr className='rule-blue-300 mt-40 mb-40' />
				<Form>
					<Form.Input
						required
						label='Name (First Last, Degree)'
						placeholder='Name (First Last, Degree) *'
						type='text'
						value={piName}
						onChange={e => {
							setNameError(false)
							updateFields({ piName: e.target.value })
						}}
					/>
					{nameError && <p className='error'>Name is required.</p>}
					<Form.Input
						required
						label='Title/Position'
						placeholder='Title/Position *'
						type='text'
						value={piTitle}
						onChange={e => {
							setTitleError(false)
							updateFields({ piTitle: e.target.value })
						}}
					/>
					{titleError && <p className='error'>Title/Position is required.</p>}
					<Form.Input
						required
						label='Department/Institution/Company'
						placeholder='Department/Institution/Company *'
						type='text'
						value={piDepartment}
						onChange={e => {
							setDepartmentError(false)
							updateFields({ piDepartment: e.target.value })
						}}
					/>
					{departmentError && (
						<p className='error'>Department/Institution/Company is required.</p>
					)}
					<Form.Input
						required
						label='Email'
						placeholder='Email *'
						type='email'
						value={piEmail}
						onChange={e => {
							setEmailError(false)
							updateFields({ piEmail: e.target.value })
						}}
					/>
					{emailError && (
						<p className='error'>Email is required and needs to be valid.</p>
					)}
					<Form.Input
						required
						label='Phone'
						placeholder='Phone *'
						type='tel'
						value={piPhone}
						onChange={e => {
							let x = e.target.value
								.replace(/\D/g, '')
								.match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
							setPhoneError(false)
							updateFields({
								piPhone: !x[2]
									? x[1]
									: '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : ''),
							})
						}}
					/>
					{phoneError && <p className='error'>Phone is required.</p>}
					<p className='mt-40'>
						Is there another PI for this study? This means that there are Co-PI’s, all other Key Personnel are listed in Appendix A{' '}
						<span className='error-color'>*</span>
					</p>
					<Form.Group className='display-block mb-0'>
						<Form.Radio
							className='mb-15'
							label='Yes'
							type='radio'
							name='investigatorOther'
							checked={investigatorOther === 'Yes'}
							value='Yes'
							onChange={e => updateFields({ investigatorOther: 'Yes' })}
						/>
						<Form.Radio
							label='No'
							type='radio'
							name='investigatorOther'
							checked={investigatorOther === 'No'}
							value='No'
							onChange={e => updateFields({ investigatorOther: 'No' })}
						/>
					</Form.Group>
					{investigatorError && <p className='error'>Please select an option.</p>}
					{investigatorOther === 'Yes' && (
						<>
							<p className='mt-40'>
								If you answered yes to the above question, please note the other PI:
							</p>
							<Form.TextArea
								className='mb-0'
								required={investigatorOther === 'Yes'}
								label='Name, Title/Position, Department/Institution/Company, Email, Phone'
								placeholder='Name, Title/Position, Department/Institution/Company, Email, Phone *'
								type='text'
								value={investigatorContact}
								onChange={e => {
									setInvestigatorContactError(false)
									updateFields({ investigatorContact: e.target.value })
								}}
							/>
							{investigatorContactError && (
								<p className='error'>PI contact information is required.</p>
							)}
						</>
					)}
				</Form>
				{missingFields && (
					<p className='error missing-field'>
						Please fill all required fields above.
					</p>
				)}
				<NextBackStep
					step={step}
					totalSteps={totalSteps}
					next={next}
					setStep={setStep}
					data={data}
				/>
			</Grid.Column>
		</Grid>
	)
}

export default StepThree
