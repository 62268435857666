import { Container, Grid, GridColumn } from 'semantic-ui-react'

function PoliciesAndProcedures() {
	return (
		<Container className='container-main'>
			<h1>Policies and Procedures</h1>
			<Grid columns={1} padded>
				<GridColumn>
					Chapter I - Purpose, Overview, Scope, and Definitions Chapter II - Ethical
					and Regulatory Requirements For the Protection of Human Research
					Participants Chapter III - Purpose of the Genetic Alliance Institutional
					Review Board Chapter IV - Authority and Jurisdiction of the Genetic
					Alliance IRB Chapter V - Investigators and Research Staff - Roles and
					Responsibilities of the Principal Investigator Chapter VI - Conflict of
					Interest Chapter VII - IRB Management Chapter VIII - Determination if
					Planned Activity is not Research or Research not Involving Human
					Participants Chapter IX - Determination That an Activity is Exempt From the
					Common Rule Chapter X - General Procedures for IRB Submissions Involving
					Exempt Research Chapter XI - General Procedures for IRB Submission
					Involving Non-Exempt Huma Participant Research Chapter XII - Criteria for
					Approval of Human Participants Research Chapter XIII - Requirements for
					Informed Consent Chapter XIV - Participant Recruitment and Review of
					Outreach Materials Chapter XV - Knowledge of Local Research Context Chapter
					XVI - Special Populations Chapter XVII - Considerations For FDA Regulated
					Research Chapter XVIII - Protocol Life Cycle Issues: Amendments, Continuing
					Review and Closure Chapter XIX - Deviations and Violations Chapter XX -
					Compliance Monitoring and Oversight Activities Chapter XXI - Serious or
					Continuing Non-Compliance Chapter XXII - Reporting Unanticipated Problems,
					Adverse Events, Deviations, Noncompliance, and Suspensions and Terminations
					Chapter XXIII - Suspensions and Terminations Chapter XXIV - IRB Reliance
					Mechanisms Chapter XXV - Research Involving Data and/or Biological
					Specimens Chapter XXVI - Health Insurance Portability and Accountability
					Act in Research These written policies have been harmonized for alignment
					with Institutional Review Board Written Procedures: Guidance for
					Institutions and IRBs (2018) published by Division of Policy and
					Assurances, Office for Human Research Protections and have been further
					developed using policies from The University of Pittsburgh, Georgetown
					University Medical Center, United States Army Medical Research and Material
					Command, University of Massachusetts Lowell, Ohio State University,
					University of Cincinnati, and University of Virginia.
				</GridColumn>
			</Grid>
		</Container>
	)
}

export default PoliciesAndProcedures
