import { Container, Grid, GridColumn, Image, Icon} from 'semantic-ui-react'
import ShareSocialMedia from '../../../ShareSocialMedia'
import LinkButton from '../../../LinkButton'

function AdvocacyAndLeadershipSkills() {
	const shareUrl = 'https://geneticalliance.org/resources-and-information/advocacy-atlas/advocacy-leadership-skills'
	return (
		<Container className='container-main'>
			<h1>Advocacy & Leadership Skills</h1>
			<Grid columns={1} padded>
				<GridColumn>
					<hr />
					<ShareSocialMedia shareUrl={shareUrl} />
					<div>
						<p className='mt-20'>
							<i>When I look at my daughter today and think of how far we’ve come- that is why I advocate for other children and families – because when you are up to your neck in the mundane battle for your child’s life at the table, taking them to specialists and fighting with insurance companies – you do not have the time or knowledge to take that out to the world. Your world becomes your child’s health. But, for that family- one person’s advocacy can change their lives. That is why I advocate for Homocystinuria (HCU), metabolic and children’s healthcare.
							<br /><br />
							-Story from a parent advocate</i>
						</p>
					</div>

					<Grid stackable columns={2} className='mt-15 left aligned'>
						<Grid.Row>
							<Grid.Column>
							<h2 className='color-secondary mt-40 mb-25'>Resources: Advocacy Basics</h2>
								<p>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/advocacy-leadership-skills/civil-tips.pdf'
										target='_blank'>
										Advocacy Tip Sheet
									</a>
									- Kids as Self Advocates (KASA)
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/advocacy-leadership-skills/speaking_up_booklet_final1.pdf'
										target='_blank'>
										Speak Up - A guide to self-advocacy
									</a>
									- The Arc of Washington
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/advocacy-leadership-skills/BelievetoAchieve.pdf'
										target='_blank'>
										Believe to Achieve Worksheet
									</a>
									- A worksheet for parents and their children about setting and reaching goals
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/advocacy-leadership-skills/Empowering-Leadership.pdf'
										target='_blank'>
										Empowering Leadership A Systems Change Guide for Autistic College Students and Those with Other Disabilities
									</a>
									- Autistic Self Advocacy Network (ASAN)
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='https://leaf.expert/resources/civil.php'
										target='_blank'
										rel='noreferrer'>
										Civil Rights and Advocacy Resources
									</a>
									- Kids as Self Advocates (KASA)
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='https://wikiadvocacy.org/Main_Page'
										target='_blank'
										rel='noreferrer'>
										WikiAdvocacy
									</a>
									- Provides information on founding and growing an advocacy organization from fundraising to detailed explanations of issues, skills, and creating a registry and samples repository.
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/advocacy-leadership-skills/VotingGuidefinal.pdf'
										target='_blank'>
										Voting: It's Your Right
									</a>
									- The Boggs Center on Developmental Disabilities
								</p>
								<ul className='list-space list-right'>
									<li>
										<a className='link-blue-800'
										href='/pdf/advocacy-atlas/advocacy-leadership-skills/VotingBooklet-SPfinal.pdf'
										target='_blank'>
										Spanish Version
										</a>
									</li>
								</ul>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='https://familyvoicesofca.org/?s=tools+for+advocacy'
										target='_blank'
										rel='noreferrer'>
										Tools for Advocacy
									</a>
									- Family Voices of California Advocacy
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='https://www.nichq.org/resource/maternal-depression-first-steps'
										target='_blank'
										rel='noreferrer'>
										Maternal Depression: First Steps Families and Advocates Can Take to Help Mothers and Babies Thrive
									</a>
									- National Institute for Children's Health Quality
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='https://www.wrightslaw.com/'
										target='_blank'
										rel='noreferrer'>
										Wrightslaw Home
									</a>
									- Provides information about special education law, education law, and advocacy for children with disabilities for parents, educators, advocates, and attorneys.
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='https://www.youtube.com/watch?v=ubb1k5m3Ouc&ab_channel=anneharland'
										target='_blank'
										rel='noreferrer'>
										Assumptions
									</a>
									- Video about a woman's struggle with muscular dystrophy and the assumptions people make about her
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/advocacy-leadership-skills/Recruitment_White_Paper_Final.pdf'
										target='_blank'>
										The Life Rope: Engagement of Diverse Populations for Recruitment and Representation in Genetics
									</a>
									- This is a paper about methods of including diverse communities and underserved populations in planning, implementation and sustainability of genetic services 
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='https://www.nichq.org/resource/creating-patient-and-family-advisory-council-toolkit-pediatric-practices'
										target='_blank'
										rel='noreferrer'>
										Creating a Patient and Family Advisory Council: A Toolkit for Pediatric Practices
									</a>
									- National Initiative for Children's Healthcare Quality (NICHQ)
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='http://www.selfadvocacyonline.org/stories/'
										target='_blank'
										rel='noreferrer'>
										Self-Advocacy For All: Stories from Self Advocates
									</a>
									- Research and Training Center on Community Living at The University of Minnesota
								</p>
							</Grid.Column>

							<Grid.Column>
								<div>
									<figure className='mt-100'>
										<Image
											src='/images/resources-and-information/advocacy-atlas/advocacy-leadership-skills/cashicrop.jpeg'
											size='medium'
											centered
										/>
										<figcaption className='mt-20 text-center'>
											<i>Cashi brainstorms during a youth leadership meeting.</i>
										</figcaption>
									</figure>
									<figure className='mt-150'>
										<Image
											src='/images/resources-and-information/advocacy-atlas/advocacy-leadership-skills/ben and baila 1.jpeg'
											size='medium'
											centered
										/>
										<figcaption className='mt-20 text-center'>
											<i>Ben smiles with his dog, Baila.</i>
										</figcaption>
									</figure>
									<figure className='mt-150'>
										<Image
											src='/images/resources-and-information/advocacy-atlas/advocacy-leadership-skills/stephaniecart.jpeg'
											size='medium'
											centered
										/>
										<figcaption className='mt-20 text-center'>
											<i>Stephanie plays with her toy grocery cart at home.</i>
										</figcaption>
									</figure>
								</div>
							</Grid.Column>
						</Grid.Row>
					</Grid>

					<div>
					<h2 className='color-secondary mt-40 mb-25'>Resources: Leadership Skills and Trainings</h2>
								<p>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/advocacy-leadership-skills/parent_leadership_presentation_FV_2009.pdf'
										rel='noreferrer'
										target='_blank'>
										Urban, Underserved & Unbelievably Powerful!
									</a>
									- Parent Leaders of CYSHCN Parent Leadership Presentation
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/advocacy-leadership-skills/va_collaboration_ negotiation.pdf'
										target='_blank'>
										The Art of Collaboration and Negotiation
									</a>
									- Family Navigator Training from Family to Family Network of Virginia
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/advocacy-leadership-skills/leadership_training_seacs.pdf'
										target='_blank'>
										Leadership Training for Special Education Advisory Committees
									</a>
									- Building Local SEACs for Better Special Education Services and Student Outcomes
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='https://mn.gov/mnddc/pipm/'
										target='_blank'
										rel='noreferrer'>
										Partners in Policymaking®
									</a>
									- Online e-courses on advocacy, policymaking, and telling your story 
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='https://www.youtube.com/user/Pasosprograms/videos'
										target='_blank'
										rel='noreferrer'>
										Pasosprograms's Channel
									</a>
									- SPANISH - Videos on skills to educate peers, facilitate access to resources, and bring ideas for change from communities to health service providers and decision-makers.
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/advocacy-leadership-skills/guidedactionplan.pdf'
										target='_blank'>
										Guided Action Plan Worksheet
									</a>
									- A worksheet on creating a plan of action after making a decision
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/advocacy-leadership-skills/Creating_Agreement-Conflict_Resolution_2012_0.pdf'
										target='_blank'>
										Creating Agreement, Conflict Resolution, and Collaborative Problem Solving
									</a>
									- Statewide Parent Advocacy Network
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/advocacy-leadership-skills/creating_agreementsp.pdf'
										target='_blank'>
										Creating Agreement, Conflict Resolution, and Collaborative Problem Solving
									</a>
									- SPANISH - Statewide Parent Advocacy Network
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/advocacy-leadership-skills/va_familyinvolvement.pdf'
										target='_blank'>
										Nothing about Us without Us
									</a>
									- Presentation on Family Involvement from Parent to Parent of Virginia
								</p>
								<p className='mt-25'>
										<a className='link-blue-800'
										href='/pdf/advocacy-atlas/advocacy-leadership-skills/tash_trainingpacket_fs.pdf'
										target='_blank'>
										Family Support, Culture and Disability Training, Participant Workbook
										</a>
										- TASH
								</p>
								<p className='mt-25'>
										<a className='link-blue-800'
										href='/pdf/advocacy-atlas/advocacy-leadership-skills/tash_trainingpacketfs_trainer.pdf'
										target='_blank'>
										Family Support, Culture and Disability Training, Trainer Workbook
										</a>
										- TASH
								</p>
								<p className='mt-25'>
										<a className='link-blue-800'
										href='/pdf/advocacy-atlas/advocacy-leadership-skills/curriculum_fs_participant.pdf'
										target='_blank'>
										Family Support, Culture and Disability Training, Training Curriculum
										</a>
										- TASH
								</p>
								<p className='mt-25'>
										<a className='link-blue-800'
										href='/pdf/advocacy-atlas/advocacy-leadership-skills/tash_leadership.pdf'
										target='_blank'>
										Leadership in Advocacy, Participant Workbook
										</a>
										- TASH
								</p>
								<p className='mt-25'>
										<a className='link-blue-800'
										href='/pdf/advocacy-atlas/advocacy-leadership-skills/tash_leadership_trainerworkbook.pdf'
										target='_blank'>
										Leadership in Advocacy, Trainer Workbook
										</a>
										- TASH
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/advocacy-leadership-skills/advisorycommittees.pdf'
										target='_blank'>
										Best Practices for Joining and Participating on Advisory Committees
									</a>
									- Genetic Alliance
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/advocacy-leadership-skills/eng_culturally_diverse.pdf'
										target='_blank'>
										The Pebble in the Pond…Engaging Culturally Diverse Families in Advocacy
									</a>
									- Statewide Parent Advocacy Network
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='https://sackonline.org/sack-activities/'
										target='_blank'
										rel='noreferrer'>
										Promoting Empowerment and Independence: Self Advocacy Training Modules
									</a>
									- Self Advocate Coalition of Kansas (SACK)
								</p>

							<h2 className='color-secondary mt-40 mb-25'>Resources: Getting Involved as a Parent</h2>
								<p>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/advocacy-leadership-skills/Parents as Presenters - Workshop Participant Guide.pdf'
										rel='noreferrer'
										target='_blank'>
										Parents as Presenters Workshop Participant Guide
									</a>
									- Pennsylvania Natural Allies
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='https://www.dailymotion.com/video/xa4p76'
										target='_blank'
										rel='noreferrer'>
										Video Presentation on Parent Advocacy
									</a>
									- Diana Autin from the Statewide Parent Advocacy Network (SPAN)
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='https://servingongroups.org/'
										target='_blank'
										rel='noreferrer'>
										Family Guide for Serving on Groups that Make Decisions
									</a>
									- Wisconsin State Professional Development Grant (SPDG)
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='https://slideplayer.com/slide/4189630/'
										target='_blank'
										rel='noreferrer'>
										Making an Impact: Representing the Family Voice on Committees and Advisory Councils
									</a>
									- Statewide Parent Advocacy Network
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/advocacy-leadership-skills/board_member_agreement.pdf'
										target='_blank'>
										Board Member Agreement Form
									</a>
									- Family Connection of South Carolina
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/advocacy-leadership-skills/PFS_Parent_Leadership_101.pdf'
										target='_blank'>
										Parent Leadership 101
									</a>
									- Parent Leadership Presentation
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/advocacy-leadership-skills/together_move_mountains.pdf'
										target='_blank'>
										Together, We Can Move Mountains: Parents as Leaders in Improving Systems for Children and Families
									</a>
									- Statewide Parent Advocacy Network
								</p>

							<h2 className='color-secondary mt-40 mb-25'>Resources: Tools for Sharing Your Message</h2>
								<p>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/advocacy-leadership-skills/socialmedia_ppt_0.pdf'
										rel='noreferrer'
										target='_blank'>
										Further Your Mission: Using internet tools and Social Media
									</a>
									- Parent to Parent of Georgia
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/advocacy-leadership-skills/nys_advocthroughletters.pdf'
										target='_blank'>
										Advocating Through Letter Writing
									</a>
									- Parent to Parent NYS-Family to Family Health Care Information Center
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/advocacy-leadership-skills/effective_brochures.pdf'
										target='_blank'>
										Creating Effective Brochures
									</a>
									- Genetic Alliance 
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/advocacy-leadership-skills/social_media101mr.pdf'
										target='_blank'>
										Social Media 101: How to Get Started with Facebook and Twitter
									</a>
									- Family Voices, Contact: Jennifer Akers, f2fhic.fv.indiana@gmail.com
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/advocacy-leadership-skills/social_media110mr.pdf'
										target='_blank'>
										Social Media 110: Learning More about Facebook and Twitter
									</a>
									- Family Voices, Contact: Jennifer Akers, f2fhic.fv.indiana@gmail.com
								</p>
							</div>

					<div className='mt-40 text-center'>
						<LinkButton className='ui button' 
						to='/resources-and-information/advocacy-atlas'
						>
							<Icon name='arrow left' /> Return to Advocacy ATLAS Homepage
						</LinkButton>
					</div>
				</GridColumn>
			</Grid>
		</Container>
	)
}

export default AdvocacyAndLeadershipSkills