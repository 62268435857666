import { Container, Grid, Image, Accordion } from 'semantic-ui-react'
import React, { useState } from 'react'
import ReactHtmlParser from 'react-html-parser'


function IRBMembersFillContent({ data }) {
    console.log("IRBMembersFillContent")
    const [activeIndex, setActiveIndex] = useState(0)
    const [iconDropdowns, setIconDropdowns] = useState([
        false, true, true, true, true, true
    ]);

    const handleAccordion = (index) => {
        console.log("handleAccordion index", index)
        const updatedIconDropdowns = iconDropdowns.map((dropdown, i) => (
            i === index ? !dropdown : dropdown
        ));
        setActiveIndex(activeIndex === index ? -1 : index);
        setIconDropdowns(updatedIconDropdowns);
    };

    if (!data) {
        return null
    }
    const buildContent = []
    const datas = data.filter(item => item.link === '/irb/irb-members')
    if (datas && Array.isArray(datas) && datas.length > 0) {
        datas.sort((a, b) => a.index - b.index)
        console.log("datas", datas)
        datas.forEach((item, index) => {
            if (index > 0) {
                buildContent.push(<div key={index}>
                    <Accordion.Title
                        className='title-accordion'
                        active={activeIndex === item.indexTab}
                        index={item.indexTab}
                        onClick={() => handleAccordion(item.indexTab)}
                    >
                        <h4 className='title-dropdown' style={{ marginBottom: '0', padding: '5px 0' }}>{item.header}</h4>
                        <Image style={{ width: '24px' }} src='/icons/accordion-up.png' className={iconDropdowns[item.indexTab] ? 'icon-dropdown active' : 'icon-dropdown'} />
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === item.indexTab}>
                        <Image style={{ width: '160px' }} src={item.imageSrc} />
                        {ReactHtmlParser(item.content)}
                    </Accordion.Content>
                    <hr className='rule-blue-300 ' />
                </div>
                )
            }
        })
    } else {
        return null
    }

    return (
        <>
            <Container>
                <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
                <Grid columns={2} stackable>
                    <Grid.Column mobile={16} tablet={16} computer={8}>
                        <h3 className='irb'>{datas[0].header}</h3>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={8} className='mt-15'>
                        {ReactHtmlParser(datas[0].content)}
                        <hr className='rule-blue-300 mt-40' />
                        <Accordion>
                            {buildContent}
                        </Accordion>
                    </Grid.Column>
                </Grid>
            </Container>
        </>
    )
}

export default IRBMembersFillContent
