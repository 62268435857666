import { Container, Grid, Image, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'


function NETSCellLines() {
    return (
        <>
            <div>
                <Container className='container-main'>
                    <h1>NETS: Cell Lines</h1>
			        <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>What are cell lines and why are they important in drug development?</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>
                                Cell lines are collections of sample cells from blood, skin, and other tissues that are extracted from donors. They can be stored and maintained in an artificial, researcher-controlled environment and used for studies. Each cell line comes from a single source and therefore has a uniform genetic makeup. These living cells allow scientists to have continual access to specific cell types without needing additional sample donations.
                            </p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Testimonials: How we did it</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>Content not yet available for this section.</p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Relevant Publications</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <div className='inline-flex mb-15'>
                                <Image src='/icons/worksheet.png' className='icon-width-16px mr-8' style={{ height: '16px', top: '8px' }} />
                                <p>
                                    <a
                                        href='http://cancerres.aacrjournals.org/content/69/4/1302'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Breast Cancer Cell Lines Contain Functional Cancer Stem Cells with Metastatic Capacity and a Distinct Molecular Signature
                                    </a>
                                </p>
                            </div>
                            <div className='inline-flex'>
                                <Image src='/icons/worksheet.png' className='icon-width-16px mr-8' style={{ height: '16px', top: '8px' }} />
                                <p>
                                    <a
                                        href='https://link.springer.com/chapter/10.1007/978-94-007-0884-6_41'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Establishing Mammalian Production Cell Lines for Structural Biology by Site-Specific Recombination
                                    </a>
                                </p>
                            </div>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Related Tools</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            {/* <a
                                href='http://www.atcc.org/Products/Collections/Cell_Biology_Collections.aspx'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/toolkit.png' className='icon-width-16px' />ATCC: Cell Biology Collection</Button>
                            </a> */}
                            <a
                                href='https://www.coriell.org/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/toolkit.png' className='icon-width-16px' />The Coriell Institute: Cell Repository</Button>
                            </a>
                            <a
                                href='https://dtp.cancer.gov/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/toolkit.png' className='icon-width-16px' />NCI: Developmental Therapeutics Program</Button>
                            </a>
                            <a
                                href='https://www.mmrrc.org/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/toolkit.png' className='icon-width-16px' />NIH: Mutant Mouse Regional Resource Center</Button>
                            </a>
                            <a
                                href='https://www.komp.org/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/toolkit.png' className='icon-width-16px' />UC Davis: Knockout Mouse Project (KOMP) Repository</Button>
                            </a>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Link to='/resources-and-information/nets/full-view' rel='noopener noreferrer' className='link-button inline-block'><Button className='button-orange-500'><Image src='/icons/link-internal-white.png' className='icon-width-16px' style={{ transform: 'scaleX(-1)' }} />Back to NETS Map full view</Button></Link>
                </Container>
            </div>
        </>
    )
}

export default NETSCellLines