import { Container, Embed, Grid, GridColumn } from 'semantic-ui-react'
import { Link, useLocation } from 'react-router-dom'
import StaffFillContent from './staff/StaffFillContent'
import Council from './council/CouncilFillContent'
import { useRef, useEffect } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { progressIndicator } from '../../content/HelperFunction'



function AboutFillContent({ data }) {
	const staff = useRef();
	const location = useLocation();

	useEffect(() => {
		if (location.pathname === '/about/staff') {
			staff.current.scrollIntoView()
		}
	}, [location.pathname])

	const { pathname, hash, key } = useLocation()
	useEffect(() => {
		// if not a hash link, scroll to top
		if (hash === '') {
			window.scrollTo(0, 0)
		}
		// else scroll to id
		else {
			setTimeout(() => {
				const id = hash.replace('#', '')
				const element = document.getElementById(id)
				if (element) {
					element.scrollIntoView({ behavior: 'smooth' })
				}
			}, 0)
		}
	}, [pathname, hash, key])

	useEffect(() => {
		progressIndicator()
	}, [])

	const aboutTopContent = data.filter((item) => item.link === '/about' && item.part === 'top-content');
	const listRows = []
	if (aboutTopContent && Array.isArray(aboutTopContent) && aboutTopContent.length > 0) {
		aboutTopContent.sort((a, b) => a.orderBy - b.orderBy)
		let countRow = aboutTopContent.length / 3
		if (aboutTopContent.length % 3 > 0) {
			countRow++;
		}
		for (let i = 0; i < countRow; i++) {
			const listCols = []
			for (let j = 0; j < 3; j++) {
				listCols.push(
					<Grid.Column>
						<h3 id={aboutTopContent[i * 3 + j].id}>{aboutTopContent[i * 3 + j].header}</h3>
						{ReactHtmlParser(aboutTopContent[i * 3 + j].content)}
					</Grid.Column>
				)
			}
			listRows.push(
				<Grid.Row className='mb-80-40-20 grid-row'>
					{listCols}
				</Grid.Row>
			)
		}
	}

	return (
		<Container className='container-main'>
			<Grid columns={1}>
				<GridColumn>
					<Grid stackable columns={2}>
						<Grid.Column className='mb-0-20-0' mobile={16} tablet={16} computer={8}>
							<h1>About Genetic Alliance</h1>
						</Grid.Column>
						<GridColumn mobile={16} tablet={16} computer={8}>
							<Embed
								id='fscaRh2OtXk'
								source='youtube'
								placeholder='https://img.youtube.com/vi/fscaRh2OtXk/maxresdefault.jpg'
							/>
						</GridColumn>
					</Grid>
					<hr className='rule-orange-500 mt-80-60-40 mb-80-40-20' />
					<Grid stackable columns={3} className='computer-only'>
						{listRows}
					</Grid>
					<Grid stackable className='tablet-only'>
						<Grid.Column>
							<h3>Mission</h3>
							<p>
								Genetic Alliance engages individuals, families and communities to transform health. This requires that we as an organization understand what it means to transform systems, dissolve boundaries, create and sustain open space, and promote the process of openness. We understand that to transform systems, we must start with our own, beginning with our very person. Such work calls us, both personally and professionally, to explore the deepest truths about ourselves and the systems we impact.
							</p>
							<p className='mb-0-60-40'>
								Our core promise is articulated in our{' '}
								<Link to='/about/mission#Mandate' target='_blank' rel='noopener noreferrer' className='link-blue-800'>
									Mandate for Quality Genetic Services
								</Link>
								, our{' '}
								<Link to='/about/mission#Values' target='_blank' rel='noopener noreferrer' className='link-blue-800'>
									values
								</Link>
								, and further emphasized by our{' '}
								<Link to='/about/mission#Manifesto' target='_blank' rel='noopener noreferrer' className='link-blue-800'>
									Manifesto
								</Link>
								.
							</p>
							<h3>Vision</h3>
							<p>
								Individuals and families need and deserve opportunities to be active participants in health, from services in traditional health settings to groundbreaking research endeavors.
							</p>
							<p>
								Genetic Alliance is resolute that people come first. We partner with individuals and communities to transform health systems to respond to what people most need.
							</p>
							<p className='mb-0-60-40'>
								We convene powerful networks, deliver actionable information, build intuitive tools, and drive policy decisions.
							</p>
							<h3>Funding</h3>
							<p>
								Genetic Alliance is a 501 (c)(3) nonprofit organization. Genetic
								Alliance’s work is supported by a blend of funding from government grants
								and contracts, industry and corporate support, individual donations, and
								fees generated by services and events. We continually work to diversify
								our funding sources. A key part of this diversification plan is to secure
								strategic, fee-for-service partnerships that leverage our expertise, serve
								the needs of the field and community, and bring greater financial
								sustainability to the organization.
							</p>
						</Grid.Column>
					</Grid>
					<Grid stackable columns={3} className='mobile-only'>
						<Grid.Column>
							<h3>Mission</h3>
							<p>
								Genetic Alliance engages individuals, families and communities to transform health. This requires that we as an organization understand what it means to transform systems, dissolve boundaries, create and sustain open space, and promote the process of openness. We understand that to transform systems, we must start with our own, beginning with our very person. Such work calls us, both personally and professionally, to explore the deepest truths about ourselves and the systems we impact.
							</p>
							<p className='mb-0-60-40'>
								Our core promise is articulated in our{' '}
								<Link to='/about/mission#Mandate' target='_blank' rel='noopener noreferrer' className='link-blue-800'>
									Mandate for Quality Genetic Services
								</Link>
								, our{' '}
								<Link to='/about/mission#Values' target='_blank' rel='noopener noreferrer' className='link-blue-800'>
									values
								</Link>
								, and further emphasized by our{' '}
								<Link to='/about/mission#Manifesto' target='_blank' rel='noopener noreferrer' className='link-blue-800'>
									Manifesto
								</Link>
								.
							</p>
							<hr className='rule-blue-300' />
						</Grid.Column>
						<Grid.Column>
							<h3>Vision</h3>
							<p>
								Individuals and families need and deserve opportunities to be active participants in health, from services in traditional health settings to groundbreaking research endeavors.
							</p>
							<p>
								Genetic Alliance is resolute that people come first. We partner with individuals and communities to transform health systems to respond to what people most need.
							</p>
							<p className='mb-0-60-40'>
								We convene powerful networks, deliver actionable information, build intuitive tools, and drive policy decisions.
							</p>
							<hr className='rule-blue-300' />
						</Grid.Column>
						<Grid.Column>
							<h3>Funding</h3>
							<p>
								Genetic Alliance is a 501 (c)(3) nonprofit organization. Genetic
								Alliance’s work is supported by a blend of funding from government grants
								and contracts, industry and corporate support, individual donations, and
								fees generated by services and events. We continually work to diversify
								our funding sources. A key part of this diversification plan is to secure
								strategic, fee-for-service partnerships that leverage our expertise, serve
								the needs of the field and community, and bring greater financial
								sustainability to the organization.
							</p>
						</Grid.Column>
					</Grid>
				</GridColumn>
			</Grid>
			<div ref={staff} />
			<hr className='rule-blue-800 mt-120-60-40 mb-20-20-40' />
			<StaffFillContent data={data} />
			<hr className='rule-blue-800 mt-65-45-25 mb-20' />
			<Council data={data} />
		</Container>
	)
}

export default AboutFillContent
