import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

const LinkButton = props => {
	const { to, onClick, ...rest } = props
	const navigate = useNavigate()

	const handleClick = event => {
		onClick && onClick(event)
		navigate(to)
	}

	return <button {...rest} onClick={handleClick} />
}

LinkButton.propTypes = {
	to: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
}

export default LinkButton
