import { Container, Grid, GridColumn, Icon } from 'semantic-ui-react'
import LinkButton from '../LinkButton'

function Internships() {
	return (
		<Container className='container-main'>
			<h1>Internships</h1>
			<Grid columns={1} padded>
				<GridColumn>
					<p>
						Genetic Alliance internship offers real-world experience in health advocacy and research, enabled by an in-depth exploration of personal awareness. All interns contribute in a meaningful way to Genetic Alliance’s programs.
					</p>
					<p>
						This competitive program is a perfect start for anyone wanting to jump into the field of health advocacy, genetic counseling, medical school, public health, or citizen science.
					</p>
					<p>
						Genetic Alliance provides the most unique internship you can find in the health advocacy and research field. We know that people — groups of patients and activated communities — are the right people to be leading research efforts, empowered with infrastructure and expertise from Genetic Alliance. And, we know that no one can truly be effective without first tending their own awareness - their own capacity to be with whatever is.
					</p>
					<p>
						So first and foremost, Genetic Alliance Interns participate in an awareness workshop. This workshop provides tools to those interested in this work to help support Genetic Alliance’s tools, partnerships, and projects that build capacity in communities, enable new cross-sector partnerships, and place control in the hands of the people, empowering them with a greater sense of ownership over their health data.
					</p>
					<p>
						Genetic Alliance has cultivated a vast network in more than three decades of supporting advocacy organizations and collaborating with clinical, policy, research, and other health institutions at the federal, state, and local levels. We believe the participation and leadership of diverse stakeholders are imperative for elevating voices; leveraging stories; identifying the most impactful research questions; informing successful participation and retention; generating insight for value assessment, quality product development, and effective project execution; and — ultimately — ensuring that knowledge makes its way back to people for better care.
					</p>
					<p>
						We have projects for those interested in public health, advocacy, health and/or clinical research, health information technology, health education, community-based health work, novel healthcare approaches, formative research, writing, resource development, evaluation, community advocacy, engagement, and outreach.
					</p>
					<p>
						<b>We are seeking candidates with strong research and writing capabilities, excellent verbal and written communication skills, the ability to work independently on a project(s) and guide yourself.</b>
					</p>
					<p>
						Your expression of creativity and ingenuity begins with the application: use the cover letter to describe why you are an excellent fit for the Genetic Alliance internship. If selected to intern with Genetic Alliance, you will be encouraged to develop and propose your projects to the organization. See other requirements below.
					</p>
					<h3>Qualifications/Requirements</h3>
						<p>
							Genetic Alliance Interns come from a variety of academic and professional backgrounds, but all interns share a passion for disrupting health systems to create paths to better health.
						</p>
					<h3>Academic Credit</h3>
						<p>
							Interns may receive academic credit if an agreement is made between Genetic Alliance and the intern's college or university.
						</p>
					<h3>International Applicants</h3>
						<p>
							International applications are welcome; however, Genetic Alliance does not sponsor international applicants for a work visa or offer assistance in relocation fees.
						</p>
					<div className='text-center'>
						<LinkButton className='ui orange-button button mt-15' to='/about/internships/internship-application' style={{ color: '#fff' }}>
							APPLY &nbsp;&nbsp;
							<Icon name='arrow right' />
						</LinkButton>
					</div>
					<h4 className='text-center'>
						Thank you for your interest!
					</h4> 
				</GridColumn>
			</Grid>
		</Container>
	)
}
export default Internships
