import { Container, Tab, Menu } from 'semantic-ui-react'
import { useNavigate, useLocation } from 'react-router-dom'
import EngagementServices from './EngagementServicesFillContent'
import TrainingAndEducation from './TrainingAndEducationFillContent'
import ResearchServices from './ResearchServicesFillContent'
import './OurServices.css'
import { useEffect, useState } from 'react'
import {
	EngagementServicesIcon,
	ResearchServicesIcon,
	TrainingEducationIcon,
} from 'components/svg-icons'

function OurServicesFillContent({ data }) {

	const navigate = useNavigate()
	const location = useLocation()
	const panes = [
		{
			menuItem: (
				<Menu.Item>
					<span style={{ display: 'flex' }}>
						{EngagementServicesIcon}
						<span>Engagement Services</span>
					</span>
				</Menu.Item>
			),
			render: () => (
				<Tab.Pane attached={false}>
					<EngagementServices data={data} />
				</Tab.Pane>
			),
		},
		{
			menuItem: (
				<Menu.Item>
					<span style={{ display: 'flex' }}>
						{TrainingEducationIcon}
						<span>Training and Education</span>
					</span>
				</Menu.Item>
			),
			render: () => (
				<Tab.Pane attached={false}>
					<TrainingAndEducation data={data} />
				</Tab.Pane>
			),
		},
		{
			menuItem: (
				<Menu.Item>
					<span style={{ display: 'flex' }}>
						{ResearchServicesIcon}
						<span>Research Services</span>
					</span>
				</Menu.Item>
			),
			render: () => (
				<Tab.Pane attached={false}>
					<ResearchServices data={data} />
				</Tab.Pane>
			),
		},
	]

	const [activeTab, setActiveTab] = useState(0)

	const onTabChange = event => {
		const tabURL = event.target.textContent.toLowerCase().replaceAll(' ', '-')
		navigate(`/our-services/${tabURL}`)
	}

	useEffect(() => {
		if (location.pathname === '/our-services/engagement-services') {
			setActiveTab(0)
		}
		if (location.pathname === '/our-services/training-and-education') {
			setActiveTab(1)
		}
		if (location.pathname === '/our-services/research-services') {
			setActiveTab(2)
		}
	}, [location.pathname])

	return (
		<>
			<Container className='container-main'>
				<h1 className='mb-90-60-40'>Our Services</h1>
				<Tab
					menu={{ secondary: true, pointing: true }}
					panes={panes}
					onTabChange={onTabChange}
					className='tab-borderless our-services'
					activeIndex={activeTab}
				/>
				{/* <hr className='rule-blue-800 mt-80-40 mb-80-40' />
				<p className='mb-80-40'>
					Want to find out more? Contact us for additional information about the
					services you are interested in.
				</p>
				<div>
					<a
						href='https://forms.gle/2AMRo8cPQgo48Yv46'
						target='_blank'
						rel='noopener noreferrer'
						className='link-button'>
						<Button className='button-neutral-100' style={{ margin: '0' }}>
							<p style={{ margin: '0 auto' }}>Contact us</p> &nbsp;&nbsp;
							<Image
								className='link-internal-icon'
								src='/icons/link-internal-blue.png'
							/>
						</Button>
					</a>
				</div> */}
			</Container>
		</>
	)
}

export default OurServicesFillContent
