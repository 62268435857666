import { Container, Grid, GridColumn, Button, Image, Modal } from 'semantic-ui-react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { progressIndicator } from '../../content/HelperFunction'

function NetsFillContent({ data }) {

	const [openNeighborhoodView, setOpenNeighborhoodView] = useState(false)
	const [openFullView, setOpenFullView] = useState(false)

	console.log('NetsFillContent data', data)
	const { pathname, hash, key } = useLocation()
	useEffect(() => {
		// if not a hash link, scroll to top
		if (hash === '') {
			window.scrollTo(0, 0)
		}
		// else scroll to id
		else {
			setTimeout(() => {
				const id = hash.replace('#', '')
				const element = document.getElementById(id)
				if (element) {
					element.scrollIntoView({ behavior: 'smooth' })
				}
			}, 0)
		}
	}, [pathname, hash, key])

	useEffect(() => {
		progressIndicator()
	}, [])

	if (!data) {
		return null
	}
	const title = data.filter(item => item.link === '/resources-and-information/nets' && item.part === 'title')
	const body = data.filter(item => item.link === '/resources-and-information/nets' && item.part === 'body')

	if (title && Array.isArray(title) && title.length > 0) {
		title.sort((a, b) => a.index - b.index)
	} else {
		return null
	}
	if (body && Array.isArray(body) && body.length > 0) {
		body.sort((a, b) => a.index - b.index)
	} else {
		return null
	}

	return (
		<Container className='container-main'>
			<h1>{title[0].header}</h1>
			<Grid columns={1} padded>
				<GridColumn>
					{ReactHtmlParser(title[0].content)}
					<h2>{body[0].header}</h2>
					<div className='mt-40 mb-40'>
						<a
							href='https://youtu.be/CXCPmV2NjqA'
							target='_blank'
							rel='noopener noreferrer'
							className='link-button inline-block mr-30'
						>
							<Button className='button-neutral-100'><Image src='/icons/multimedia.png' className='icon-width-16px' />&nbsp;&nbsp;Introduction to NETS</Button>
						</a>
						<a
							href='https://youtu.be/93EmPk6QHm0'
							target='_blank'
							rel='noopener noreferrer'
							className='link-button inline-block'
						>
							<Button className='button-neutral-100'><Image src='/icons/multimedia.png' className='icon-width-16px' />&nbsp;&nbsp;How to Use NETS</Button>
						</a>
					</div>
					<h2 id={body[1].id}>{body[1].header}</h2>
					{ReactHtmlParser(body[1].content)}
					<h2 id={body[2].id}>{body[2].header}</h2>
					{ReactHtmlParser(body[2].content)}
					<h2 id={body[3].id}>{body[3].header}</h2>
					{ReactHtmlParser(body[3].content1)}
					<div className='display-flex wrap space-evenly mt-60 mb-40'>
						<div className='text-center mb-40'>
							<p className='text-center'>Neighborhood view</p>
							<Modal
								size='small'
								basic
								onClose={() => setOpenNeighborhoodView(false)}
								onOpen={() => setOpenNeighborhoodView(true)}
								open={openNeighborhoodView}
								trigger={
									<div className='text-center scrim-white-background'>
										<Image src='/images/resources-and-information/nets/NETS-neighborhood-view.png' className='mb-15' style={{ maxWidth: '375px' }} />
									</div>
								}
							>
								<div className='text-center'>
									<Image src='/images/resources-and-information/nets/NETS-neighborhood-view.png' className='pb-15 center' />
								</div>
							</Modal>
							<Link to='/resources-and-information/nets/neighborhood-view' rel='noreferrer noopener' className='inline-block link-button'>
								<Button className='button-neutral-100' style={{ margin: '0' }}>Learn more<Image src='/icons/link-internal-blue.png' className='icon-width-16px' /></Button>
							</Link>
						</div>
						<div className='text-center'>
							<p className='text-center'>Full view</p>
							<Modal
								basic
								onClose={() => setOpenFullView(false)}
								onOpen={() => setOpenFullView(true)}
								open={openFullView}
								trigger={
									<div className='text-center scrim-white-background'>
										<Image src='/images/resources-and-information/nets/NETS-Final-1900px.jpg' className='mb-15' style={{ maxWidth: '333px' }} />
									</div>
								}
							>
								<div className='text-center'>
									<Image src='/images/resources-and-information/nets/NETS-Final-1900px.jpg' className='pb-15 center' />
								</div>
							</Modal>
							<Link to='/resources-and-information/nets/full-view' rel='noreferrer noopener' className='inline-block link-button'>
								<Button className='button-neutral-100'>Learn more<Image src='/icons/link-internal-blue.png' className='icon-width-16px' /></Button>
							</Link>
						</div>
					</div>
					<p>
						The{' '}
						<Link to='/resources-and-information/nets/neighborhood-view' rel='noopener noreferrer'>
							neighborhood view
						</Link>
						{ReactHtmlParser(body[3].content2)}
					</p>
					<p>
						The{' '}
						<Link to='/resources-and-information/nets/full-view' rel='noopener noreferrer'>
							full view
						</Link>
						{ReactHtmlParser(body[3].content3)}
					</p>
					{ReactHtmlParser(body[3].content4)}
					<h2 id={body[4].id}>{body[4].header}</h2>
					{ReactHtmlParser(body[4].content)}
					<h2 id={body[5].id}>{body[5].header}</h2>
					{ReactHtmlParser(body[5].content)}
				</GridColumn>
			</Grid>
		</Container>
	)
}

export default NetsFillContent