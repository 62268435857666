import { Container, Tab, Menu, Grid, Button, Image } from 'semantic-ui-react'
import { useLocation, useNavigate } from 'react-router-dom'
import OnlineCurriculum from './OnlineCurriculumFillContent'
import LiveWorkshops from './LiveWorkshopsFillContent'
import RegistryServices from './RegistryServicesFillContent'
import './RegistryBootcamp.css'
import { useEffect, useState } from 'react'
import { progressIndicator } from '../../content/HelperFunction'
import { Link } from 'react-router-dom'

function RegistryBootcampFillContent({ data }) {
	console.log('RegistryBootcampFillContent data: ', data)
	const { pathname, hash, key } = useLocation()
    useEffect(() => {
        // if not a hash link, scroll to top
        if (hash === '') {
            window.scrollTo(0, 0)
        }
        // else scroll to id
        else {
            setTimeout(() => {
                const id = hash.replace('#', '')
                const element = document.getElementById(id)
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' })
                }
            }, 0)
        }
    }, [pathname, hash, key])

    useEffect(() => {
        progressIndicator()
    }, [])

	const navigate = useNavigate()
	const location = useLocation()
	const panes = [
		{
			menuItem: (
				<Menu.Item>
					<span style={{ display: 'flex' }}>
						<span>Online Curriculum</span>
					</span>
				</Menu.Item>
			),
			render: () => (
				<Tab.Pane attached={false}>
					<OnlineCurriculum data={data} />
				</Tab.Pane>
			),
		},
		{
			menuItem: (
				<Menu.Item>
					<span style={{ display: 'flex' }}>
						<span>Live Workshops</span>
					</span>
				</Menu.Item>
			),
			render: () => (
				<Tab.Pane attached={false}>
					<LiveWorkshops data={data} />
				</Tab.Pane>
			),
		},
		{
			menuItem: (
				<Menu.Item>
					<span style={{ display: 'flex' }}>
						<span>Registry Services</span>
					</span>
				</Menu.Item>
			),
			render: () => (
				<Tab.Pane attached={false}>
					<RegistryServices data={data} />
				</Tab.Pane>
			),
		},
	]

	const [activeTab, setActiveTab] = useState(0)

	const onTabChange = event => {
		const tabURL = event.target.textContent.toLowerCase().replaceAll(' ', '-')
		navigate(`/registries/bootcamp/${tabURL}`)
	}

	useEffect(() => {
		if (location.pathname === '/registries/bootcamp/online-curriculum') {
			setActiveTab(0)
		}
		if (location.pathname === '/registries/bootcamp/live-workshops') {
			setActiveTab(1)
		}
		if (location.pathname === '/registries/bootcamp/registry-services') {
			setActiveTab(2)
		}
	}, [location.pathname])

	if (!data) {
		return null
	}
	const datas = data.filter(item => item.link === '/registries/bootcamp')
	if (datas && Array.isArray(datas) && datas.length > 0) {
		datas.sort((a, b) => a.index - b.index)
	} else {
		return null
	}

	return (
		<>
			<Container className='container-main'>
				<Grid columns={2} stackable className='mb-80-60-40 align-center'>
					<Grid.Column mobile={16} tablet={8} computer={8}>
						<h1 id={datas[0].id} className='mb-30'>{datas[0].header}</h1>
						<p className='subtitle'>
							{datas[0].content}
						</p>
					</Grid.Column>
					<Grid.Column
						mobile={16}
						tablet={8}
						computer={8}
						className='min-768px display-flex space-evenly'>
						<Image
							src='/images/registries/registry-bootcamp-new.png'
							className='computer-only'
							style={{ maxWidth: '419px' }}
						/>
						<Image
							src='/images/registries/registry-bootcamp-new.png'
							className='tablet-only'
							style={{ maxWidth: '232px' }}
						/>
					</Grid.Column>
					<Grid.Column mobile={16} tablet={8} computer={8} className='max-767px'>
						<Image
							src='/images/registries/registry-bootcamp-new.png'
							style={{ maxWidth: '232px' }}
						/>
					</Grid.Column>
				</Grid>
				<Tab
					menu={{ secondary: true, pointing: true }}
					panes={panes}
					onTabChange={onTabChange}
					className='tab-borderless bootcamp'
					activeIndex={activeTab}
				/>
				<hr className='rule-blue-800 mt-80-60-40 mb-80-60-40' />
				<Grid columns={2} stackable>
					<Grid.Column mobile={16} tablet={16} computer={8}>
						<h3 id={datas[1].id}>{datas[1].header}</h3>
					</Grid.Column>
					<Grid.Column mobile={16} tablet={16} computer={8} className='mt-15'>
						<p>
							{datas[1].content1}
						</p>
						<p>
							{datas[1].content2}{' '}
							<Link to='/registries/ga-registry' rel='noreferrer'>
								GA Registry
							</Link>
							, the{' '}
							<Link to='/biobank' rel='noreferrer'>
								GA Biobank
							</Link>
							, and the{' '}
							<Link to='/irb' rel='noreferrer'>
								GA IRB
							</Link>
							{datas[1].content3}
						</p>
						<p className='mb-30'>
							{datas[1].content4}{' '}
						</p>
						<a
							href='https://forms.gle/5BWiPQNTRvBmmEE28'
							target='_blank'
							rel='noreferrer'
							className='inline-block link-button'>
							<Button className='button-neutral-100'>
								Contact us
								<Image
									src='/icons/link-internal-blue.png'
									className='icon-width-16px'
								/>
							</Button>
						</a>
					</Grid.Column>
				</Grid>
			</Container>
		</>
	)
}

export default RegistryBootcampFillContent
