import { useState, useEffect } from 'react'
import {
	Container,
	Grid,
	GridColumn,
	Form,
	Input,
	Icon,
	Button,
} from 'semantic-ui-react'
import { authenticate, isAuth, signIn } from 'actions/auth'
import { useNavigate, Link } from 'react-router-dom'

const SignIn = () => {
	const navigate = useNavigate()
	const [values, setValues] = useState({
		email: 'jay@aretetic.com',
		password: 'rrrrrr',
		error: '',
		loading: false,
		showForm: true,
	})
	const params = new Proxy(new URLSearchParams(window.location.search), {
		get: (searchParams, prop) => searchParams.get(prop),
	})
	let expirationMessage = params.expired
	useEffect(() => {
		if (expirationMessage) {
			setValues({
				...values,
				error: 'Your session has expired. Please sign in again.',
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [expirationMessage])

	const { email, password, error, loading, showForm } = values

	useEffect(() => {
		isAuth() && navigate('/')
	}, [navigate])

	const handleSubmit = e => {
		e.preventDefault()
		setValues({ ...values, loading: true, error: false })
		const user = { email, password }
		signIn(user).then(data => {
			if (data.error) {
				setValues({ ...values, error: data.error, loading: false })
			} else {
				// save user token to cookie
				// setCookie('token', data.token)

				// save user info to localstorage
				// localStorage.setItem('user', JSON.stringify(data.user))
				// authenticate user
				authenticate(data, () => {
					if (isAuth() && isAuth().role === 1) {
						navigate('/admin')
					} else {
						navigate('/user')
					}
				})
			}
		})
	}

	const handleChange = name => e => {
		setValues({ ...values, error: false, [name]: e.target.value })
	}

	const showError = () => (error ? <p className='error'>{error}</p> : '')
	return (
		<Container className='container-main'>
			<h1 className='text-center'>Sign In</h1>
			<p className='text-center mb-40'>
				Don't have an account? <Link to='/sign-up'>Sign Up</Link>
			</p>
			<Grid centered columns={2}>
				<GridColumn>
					{showForm && (
						<Form onSubmit={handleSubmit}>
							<Form.Field
								required
								label='Email'
								control={Input}
								id='email'
								value={email}
								onChange={handleChange('email')}
								type='email'
								className='form-control'
								placeholder='Type your email'
							/>
							<Form.Field
								required
								label='Password'
								id='password'
								control={Input}
								value={password}
								onChange={handleChange('password')}
								type='password'
								className='form-control'
								placeholder='Type your password'
							/>
							<Button className='button-neutral-100 mt-25'>
								{loading ? (
									<Icon name='circle notched' className='center' loading />
								) : (
									'Sign In'
								)}
							</Button>
							{showError()}
							<br />
							<Link to='/auth/password/forgot'>Forgot Password</Link>
						</Form>
					)}
				</GridColumn>
			</Grid>
		</Container>
	)
}

export default SignIn
