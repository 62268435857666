import React from 'react'
import { Container, Grid, GridColumn } from 'semantic-ui-react'

function CelebratingOurDirectors() {
	return (
		<>
			<Container className='container-main'>
				<h1>Celebrating Our Directors</h1>
				<Grid columns={1} padded>
					<GridColumn>
						<img
							alt='Genetic Alliance Directors'
							data-align='center'
							data-entity-type='file'
							data-entity-uuid='d0c85f00-2556-4fed-be71-0c2dc8cb3dcc'
							src='/geneticalliance.org/sites/default/files/inline-images/directors.jpeg'
						/>
						<p>
							<em>
								(from left to right): Joan Weiss, Sharon Terry, and Mary Davidson.
							</em>
						</p>

						<p>Genetic Alliance's 25th Anniversary Celebration in 2011</p>

						<p>
							<em>
								<img
									alt='Joan Weiss'
									data-entity-type='file'
									data-entity-uuid='a96af390-a867-4e13-bb34-464e14eabc54'
									src='/geneticalliance.org/sites/default/files/inline-images/JoanWeiss_Director.jpeg'
								/>
							</em>
						</p>

						<h3>Joan O. Weiss&nbsp;1986-1996</h3>

						<p>
							Joan Weiss, a social worker and genetic counselor, founded the Alliance
							for Genetic Support Groups in 1986. Beginning as a hotline, the Alliance
							was implemented as a support network for organizations dedicated to
							genetic and rare diseases as well as a public resource for questions
							about genetic conditions. Joan and her small staff worked to expand the
							organization over the years, hosting conferences and lectures about
							genetics education, policy, and how to run successful advocacy groups.
							Joan also became the co-director of Human Genome Education Model (HuGEM)
							and has published articles in numerous books and journals.
						</p>

						<p>
							Joan Weiss retired as director of the Alliance in 1996. During her ten
							years of leadership, the organization grew from a hotline to a staffed
							venture promoting policy change, improved research, and broader public
							education on the challenges and promises of genetics. She has been
							honored at the 10th, 20th, and 25th anniversaries of Genetic Alliance and
							continues to work on projects with the current staff in Washington,
							D.C.&nbsp;
						</p>

						<p>
							<img
								alt='Mary Davidson'
								data-entity-type='file'
								data-entity-uuid='b0be763b-a981-458a-a4ae-ba19a3d22355'
								src='/geneticalliance.org/sites/default/files/inline-images/davidson-mary.jpeg'
							/>
						</p>

						<h3>Mary Davidson&nbsp;1996-2003</h3>

						<p>
							Mary Davidson, a former Peace Corps volunteer and social worker, served
							as a second director of the Alliance of Genetic Support Groups during a
							critical period of the Alliance's history. When Mary began her
							directorship, a boom in attention towards genetic research and genetic
							diseases was being precipitated by the progress of the Human Genome
							Project. With a realization that the Alliance's organization required a
							transition to meet the challenge of genetic science in the 21st century,
							Mary spearheaded the creation of a thorough and comprehensive strategic
							plan for moving the organization into the new millennium. As part of
							these efforts, Mary led the rebranding of the organization, giving it the
							name is bears today: Genetic Alliance.
						</p>

						<p>
							Mary tirelessly expanded Alliance programs and launched numerous
							initiatives; under her tenure, the Alliance, in conjunction with Aspen
							Systems and the Office of Rare Disease Research, launched
							the&nbsp;Genetic and Rare Diseases&nbsp;(GARD) Information Center. The
							Center was a great success, and continues to this day under the
							supervision of the NIH's National Center for Advancing Translational
							Research. Mary was also responsible for the hiring of the Alliance's
							first on-staff genetic counselor, a critical step in keeping the
							organization moving in tandem with the progress of genetic science.&nbsp;
						</p>

						<p>
							<img
								alt='Sharon Terry'
								data-entity-type='file'
								data-entity-uuid='8fac06fd-fa18-4ea3-b694-c0ca9a488075'
								src='/geneticalliance.org/sites/default/files/inline-images/SFT_GuidottiHeadshot.jpeg'
							/>
						</p>

						<h3>Sharon Terry&nbsp;2004-Present</h3>

						<p>
							Sharon Terry became involved in advocacy after her two children were
							diagnosed with a rare genetic disease in 1994. Since then, she has worked
							tirelessly to promote public awareness and to further genetic research
							initiatives through her organization PXE International and later Genetic
							Alliance. She served on Genetic Alliance's board of directors for five
							years - two as president - before becoming President and Chief Executive
							Officer of the organization in 2004. During her tenure, Genetic Alliance
							has expanded its programs and partnerships. Numbering 600 in 2004,
							Genetic Alliance currently counts over 1,000 disease-specific
							organizations, as well as thousands more universities, hospitals, and
							research institutions in its network.
						</p>

						<p>
							During the past decade, Sharon has published numerous articles in
							journals and was part of the team that discovered the PXE gene. She has a
							background in religious studies and has an honorary doctorate from Iona
							College for her work in community engagement. In 2003, Sharon co-founded
							the&nbsp;
							<a
								href='http://www.biobank.org/'
								rel=' noopener noreferrer'
								target='_blank'>
								Genetic Alliance BioBank
							</a>
							, a sample repository available to researchers to promote translational
							research.
						</p>
					</GridColumn>
				</Grid>
			</Container>
		</>
	)
}

export default CelebratingOurDirectors
