export const MenuData = [
	{
		title: 'Programs',
		path: '/resources-and-information',
		children: [
			{
				title: 'iHope Genetic Health',
				path: '/ihope-genetic-health',
			},
			{
				title: 'Disease InfoSearch',
				path: '/disease-infosearch',
			},
			{
				title: 'Registry',
				path: '/registries/ga-registry',
			},
			{
				title: 'Registry Bootcamp',
				path: '/registries/bootcamp',
			},
			{
				title: 'BioBank',
				path: '/biobank',
			},
			{
				title: 'IRB',
				path: '/irb',
			},
			{
				title: 'NETS',
				path: '/resources-and-information/nets',
			},
			{
				title: 'WikiAdvocacy',
				path: '/wikiadvocacy',
			},
			{
				title: 'Expecting Health',
				path: '/expecting-health',
			},
			{
				title: 'View All',
				path: '/resources-and-information',
			},
		],
	},
	{
		title: 'Policy',
		path: '/policy',
	},
	{
		title: 'Events',
		path: '/events',
		children: [
			{
				title: 'Webinars',
				path: '/events/webinars',
			},
			{
				title: 'Webinar Archives',
				path: '/events/webinar-archives',
			},
		],
	},
	// {
	// 	title: 'Team',
	// 	path: '/about/staff',
	// },
	{
		title: 'About',
		path: '/about',
		children: [
			{
				title: 'News',
				path: '/about/news',
			},
		],
	},
	{
		title: 'Search',
		path: '/search',
	},
]

export const MobileMenuData = [
	{
		title: 'Programs',
		path: '/resources-and-information',
		children: [
			{
				title: 'Programs',
				path: '/resources-and-information',
			},
			{
				title: 'iHope Genetic Health',
				path: '/ihope-genetic-health',
			},
			{
				title: 'Disease InfoSearch',
				path: '/disease-infosearch',
			},
			{
				title: 'Registry',
				path: '/registries/ga-registry',
			},
			{
				title: 'Registry Bootcamp',
				path: '/registries/bootcamp',
			},
			{
				title: 'BioBank',
				path: '/biobank',
			},
			{
				title: 'IRB',
				path: '/irb',
			},
			{
				title: 'NETS',
				path: '/resources-and-information/nets',
			},
			{
				title: 'WikiAdvocacy',
				path: '/wikiadvocacy',
			},
			{
				title: 'Expecting Health',
				path: '/expecting-health',
			},
			// {
			// 	title: 'View All',
			// 	path: '/resources-and-information',
			// },
		],
	},
	{
		title: 'Policy',
		path: '/policy',
	},
	{
		title: 'Events',
		path: '/events',
		children: [
			{
				title: 'Events',
				path: '/events',
			},
			{
				title: 'Webinars',
				path: '/events/webinars',
			},
			{
				title: 'Webinar Archives',
				path: '/events/webinar-archives',
			},
		],
	},
	{
		title: 'Team',
		path: '/about/staff',
	},
	{
		title: 'About',
		path: '/about',
		children: [
			{
				title: 'About',
				path: '/about',
			},
			{
				title: 'News',
				path: '/about/news',
			},
		],
	},
]
