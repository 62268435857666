import { Container, Grid, GridColumn } from 'semantic-ui-react'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'


function Mission() {
    const { pathname, hash, key } = useLocation();
      useEffect(() => {
        // if not a hash link, scroll to top
        if (hash === '') {
          window.scrollTo(0, 0);
        }
        // else scroll to id
        else {
          setTimeout(() => {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
              element.scrollIntoView({behavior: 'smooth'});
            }
          }, 0);
        }
      }, [pathname, hash, key]);
    return (
		<Container className='container-main'>
			<Grid columns={1}>
				<GridColumn>
                    <Grid stackable columns={2}>
                        <Grid.Column>
                            <h1>Mission</h1>
                        </Grid.Column>
                        <Grid.Column>
                            <p className='subtitle'>
                                Genetic Alliance engages individuals, families and communities to transform health. This requires that we as an organization understand what it means to transform systems, dissolve boundaries, create and sustain open space, and promote the process of openness. We understand that to transform systems, we must start with our own, beginning with our very person. Such work calls us, both personally and professionally, to explore the deepest truths about ourselves and the systems we impact.
                            </p>
                        </Grid.Column>
                    </Grid>
                    <hr className='rule-orange-500 mt-80-40 mb-80-40 hidden-mobile' />
                    <h3 id='Mandate'>Mandate for Quality Genetic Services</h3>
                    <p className='subtitle mb-80-40'>Access to quality genetic services is critical to comprehensive healthcare.</p>
                    <Grid stackable>
                        <Grid.Row columns={3}>
                            <Grid.Column>
                                <hr className=' rule-blue-300 mt-hr-0' />
                                <p>Individuals and families partner with their healthcare providers to identify needs, develop and monitor treatment plans, and manage their genetic condition.</p>
                            </Grid.Column>
                            <Grid.Column>
                                <hr className=' rule-blue-300 mt-hr-0' />
                                <p>Genetic information, resources, and services are integrated in home-based, community-based, and hospital care for individuals across the life span.</p>
                            </Grid.Column>
                            <Grid.Column>
                                <hr className=' rule-blue-300 mt-hr-0' />
                                <p>Referrals to support groups and resources are offered at regular office visits.</p>
                            </Grid.Column>
                            <Grid.Column>
                                <hr className='mt-60 rule-blue-300 mt-hr-0' />
                                <p>Healthcare providers refer individuals to appropriate specialists, as needed, including those outside of their health insurance plan.</p>
                            </Grid.Column>
                            <Grid.Column>
                                <hr className='mt-60 rule-blue-300 mt-hr-0' />
                                <p>Providers and payers consider the psychosocial, as well as the medical, effects of a genetic condition—on both the individual and his or her family—at each stage of life.</p>
                            </Grid.Column>
                            <Grid.Column>
                                <hr className='mt-60 rule-blue-300 mt-hr-0' />
                                <p>Healthcare insurance plans reimburse diagnosis and treatment for all genetic conditions.</p>
                            </Grid.Column>
                            <Grid.Column>
                                <hr className='mt-60 rule-blue-300 mt-hr-0' />
                                <p>Quality resources are available to assist individuals and their families in understanding family health history, signs/symptoms, and screening/testing options along with their implications, diagnosis, treatment, and long-term follow-up, if needed.</p>
                            </Grid.Column>
                            <Grid.Column>
                                <hr className='mt-60 rule-blue-300 mt-hr-0' />
                                <p>Healthcare providers with experience in genetic services are available to all individuals.</p>
                            </Grid.Column>
                            <Grid.Column>
                                <hr className='mt-60 rule-blue-300 mt-hr-0' />
                                <p>Providers, payers, and employers have policies and procedures to ensure the appropriate use of genetic information and comply with GINA where applicable.</p>
                            </Grid.Column>
                            <Grid.Column>
                                <hr className='mt-60 rule-blue-300 mt-hr-0' />
                                <p>Information about genetic conditions is provided to individuals and families in a culturally competent manner, which may include: language, educational level, and appropriate media.</p>
                            </Grid.Column>
                            <Grid.Column>
                                <hr className='mt-60 rule-blue-300 mt-hr-0' />
                                <p>Information about genetic research and clinical trials is available to affected individuals and integrated into clinical practice when appropriate.</p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <hr  className='mt-80-40 mb-80-40 rule-blue-800'/>
                    <h3 id='Values'>Core Values</h3>
                    <p className='mb-80-40 subtitle' style={{maxWidth: '864px'}}>Genetic Alliance commits to accomplish our mission with integrity, and to live by these values in everything we do.</p>
                    <Grid >
                        <Grid.Row columns={2}>
                            <Grid.Column mobile={8} tablet={5} computer={5} className='text-right'>
                                <p className=' text-right-mobile '><b>Collectivity</b></p>     
                            </Grid.Column>
                            <Grid.Column mobile={8} tablet={8} computer={8}>
                                <p>We believe that everyone values health and well-being for themselves and those they love.</p>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column mobile={8} tablet={5} computer={5} className='text-right'>
                                <p className=' text-right-mobile '><b>Openness</b></p>     
                            </Grid.Column>
                            <Grid.Column mobile={8} tablet={8} computer={8}>
                                <p>To achieve better health for all, we must embrace the diverse priorities of our allies while contributing to a common goal.</p>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column mobile={8} tablet={5} computer={5} className='text-right'>
                                <p className=' text-right-mobile '><b>Transparency</b></p>     
                            </Grid.Column>
                            <Grid.Column mobile={8} tablet={8} computer={8}>
                                <p>We must actively understand and honestly communicate our biases and ambitions,</p>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column mobile={8} tablet={5} computer={5} className='text-right'>
                                <p className=' text-right-mobile '><b>Trustworthiness</b></p>     
                            </Grid.Column>
                            <Grid.Column mobile={8} tablet={8} computer={8}>
                                <p>practicing our mission, in order to cultivate and sustain trust.</p>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column mobile={8} tablet={5} computer={5} className='text-right'>
                                <p className=' text-right-mobile '><b>Vulnerability</b></p>     
                            </Grid.Column>
                            <Grid.Column mobile={8} tablet={8} computer={8}>
                                <p>And yet, we must also risk our comfort and be willing to trust in others,</p>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column mobile={8} tablet={5} computer={5} className='text-right'>
                                <p className=' text-right-mobile '><b>Intersectionality</b></p>     
                            </Grid.Column>
                            <Grid.Column mobile={8} tablet={8} computer={8}>
                                <p>for the factors that influence health and happiness are myriad and too great for any one organization to solve.</p>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column mobile={8} tablet={5} computer={5} className='text-right'>
                                <p className=' text-right-mobile '><b>Commitment to<br/>Agency</b></p>     
                            </Grid.Column>
                            <Grid.Column mobile={8} tablet={8} computer={8}>
                                <p>Indeed, we must support the inherent power of people’s choices and build systems that meet their needs,</p>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column mobile={8} tablet={5} computer={5} className='text-right'>
                                <p className=' text-right-mobile '><b>Inclusivity</b></p>     
                            </Grid.Column>
                            <Grid.Column mobile={8} tablet={8} computer={8}>
                                <p>to ensure that these benefits are available to all people.</p>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column mobile={8} tablet={5} computer={5} className='text-right'>
                                <p className=' text-right-mobile '><b>Responsibility</b></p>     
                            </Grid.Column>
                            <Grid.Column mobile={8} tablet={8} computer={8}>
                                <p>We are responsible for the health of ourselves and all others, and,</p>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column mobile={8} tablet={5} computer={5} className='text-right'>
                                <p className=' text-right-mobile '><b>Tempered Urgency</b></p>     
                            </Grid.Column>
                            <Grid.Column mobile={8} tablet={8} computer={8}>
                                <p>we must act with purpose and haste to create the change we seek within our lifetimes.</p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <hr className='mt-80-40 mb-80-40 rule-blue-800'/>
                    <h3 id='Manifesto' className='mb-80-40' >Genetic Alliance: A Manifesto</h3>
                    <div className='center' style={{maxWidth: '640px'}}>
                        <p>
                            Say: We are a loving person. Say: We will be a loving person. We are a network and we are here. We, fleet-footed allies. We, living along a continuum of vulnerability. Our path is lined with mirrors. Our signposts reflect deeper than the skin. Let us draw you up a society, or else look to some horizon. Language is a prism. So much governance balances on the body. There is no Smithsonian of the Self. Let us walk with you through the nucleotide wilds. Let us be transparent. Let us be the right amount of innovative or disruptive. Let us empower the I and embolden the Thou.
                        </p>
                        <p>
                            Let us be lost enough to develop the strength and dexterity of survivalists. Let us be resolute enough to help. Let us be us, individuals and families and communities. Let us tell a story. Let us have a conversation. Let us speak together as the autumn cools into winter. Let us understand a collectivity of kindness. And let us be present.
                        </p>
                    </div>
				</GridColumn>
			</Grid>
		</Container>
	)
}

export default Mission