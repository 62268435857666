import { Container, Grid, GridColumn, Image, Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

function IRBRoster() {
	return (
		<>
			<Container>
				<h1>IRB Roster</h1>
				<Grid columns={3} padded stackable>
					<GridColumn>
						<Segment raised stacked textAlign='center'>
							<Link className='link-blue-800' to='/irb/irb-roster/carter'>
								<Image
									avatar
									size='small'
									alt='Chris Carter'
									src='/images/IRB/CarterHeadshot.jpeg'
								/>
								<span>Chris Carter, Chair</span>
							</Link>
						</Segment>
					</GridColumn>
					<GridColumn>
						<Segment raised stacked textAlign='center'>
							<Link className='link-blue-800' to='/irb/irb-roster/angrist'>
								<Image
									avatar
									size='small'
									alt='Misha Angrist'
									src='/images/IRB/AngristHeadshot.jpeg'
								/>
								<span>Misha Angrist</span>
							</Link>
						</Segment>
					</GridColumn>
					<GridColumn>
						<Segment raised stacked textAlign='center'>
							<Link className='link-blue-800' to='/irb/irb-roster/baker'>
								<Image
									avatar
									size='small'
									alt='Dixie Baker'
									src='/images/IRB/BakerHeadshot.jpeg'
								/>
								<span>Dixie Baker</span>
							</Link>
						</Segment>
					</GridColumn>
					<GridColumn>
						<Segment raised stacked textAlign='center'>
							<Link className='link-blue-800' to='/irb/irb-roster/dreyfus'>
								<Image
									avatar
									size='small'
									alt='Jennifer Dreyfus'
									src='/images/IRB/DreyfusHeadshot.jpeg'
								/>
								<span>Jennifer Dreyfus</span>
							</Link>
						</Segment>
					</GridColumn>
					<GridColumn>
						<Segment raised stacked textAlign='center'>
							<Link className='link-blue-800' to='/irb/irb-roster/fitzgerald'>
								<Image
									avatar
									size='small'
									alt='Kevin FitzGerald'
									src='/images/IRB/FitzGeraldHeadshot.jpeg'
								/>
								<span>Kevin FitzGerald</span>
							</Link>
						</Segment>
					</GridColumn>
					<GridColumn>
						<Segment raised stacked textAlign='center'>
							<Link className='link-blue-800' to='/irb/irb-roster/patrick-lake'>
								<Image
									avatar
									size='small'
									alt='Bray Patrick-Lake'
									src='/images/IRB/Patrick-LakeHeadshot.png'
									className='mb-15'
								/>
								<span>Bray Patrick-Lake</span>
							</Link>
						</Segment>
					</GridColumn>
				</Grid>
			</Container>
		</>
	)
}

export default IRBRoster
