import { Container, Grid, Image, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'


function NETSIRB() {
    return (
        <>
            <div>
                <Container className='container-main'>
                    <h1>NETS: IRB Approval</h1>
			        <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>What is IRB Approval and why is it important in drug development?</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>
                                An institutional review board (IRB) is a committee that protects the rights and privacy of the individuals that participate in studies. Typically IRBs are composed of a handful of health professionals and researchers that are not involved in the proposed research, who can serve as an objective judge of the potential benefits and harms of a proposed research project. They review all documentation involved in research studies involving humans, including research protocols, informed consent documents, any materials developed for recruiting research participants, and security measures taken to protect sensitive participant data. Any research that involves human subjects must obtain IRB approval or an exemption before beginning the study. To learn more about IRB approval and why it is important for drug development, click on the links below.
                            </p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Testimonials: How we did it</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>Content not yet available for this section.</p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Relevant Publications</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8} style={{ display: 'flex' }}>
                            <p>Content not yet available for this section.</p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Related Tools</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <a
                                href='https://www.fda.gov/regulatoryinformation/guidances/ucm126420.htm'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/guide.png' className='icon-width-16px' />FDA: Institutional Review Boards Frequently Asked Questions - Information Sheet</Button>
                            </a>
                            {/* <a
                                href='https://www.commonweal.org/programs/irb-approval-process/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/toolkit.png' className='icon-width-16px' />Commonweal: IRB Approval Process (Forms &amp; FAQS)</Button>
                            </a> */}
                            <a
                                href='https://phrptraining.com/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />NIH Office of Extramural Research: Protecting Human Participants in Research Training</Button>
                            </a>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Link to='/resources-and-information/nets/full-view' rel='noopener noreferrer' className='link-button inline-block'><Button className='button-orange-500'><Image src='/icons/link-internal-white.png' className='icon-width-16px' style={{ transform: 'scaleX(-1)' }} />Back to NETS Map full view</Button></Link>
                </Container>
            </div>
        </>
    )
}

export default NETSIRB