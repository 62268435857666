import fetch from 'isomorphic-fetch'
import { handleResponse } from './auth'

export const create = (tag, token) => {
    return fetch(`${process.env.REACT_APP_API_URL}/tag`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(tag),
    })
        .then(response => {
            handleResponse(response)
            return response.json()
        })
        .catch(err => console.log(err))
}

export const getTags = () => {
    return fetch(`${process.env.REACT_APP_API_URL}/tags`, {
        method: 'GET',
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}

export const singleTag = slug => {
    return fetch(`${process.env.REACT_APP_API_URL}/tag/${slug}`, {
        method: 'GET',
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}

export const removeTag = (slug, token) => {
    return fetch(`${process.env.REACT_APP_API_URL}/tag/${slug}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then(response => {
            handleResponse(response)
            return response.json()
        })
        .catch(err => console.log(err))
}

export const update = (tag, token, slug) => {
    return fetch(`${process.env.REACT_APP_API_URL}/tag/${slug}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(tag),
    })
        .then(response => {
            handleResponse(response)
            return response.json()
        })
        .catch(err => console.log(err))
}