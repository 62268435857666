import { Container, Grid, Button, Image } from 'semantic-ui-react'

function EndingTheDiagnosticOdyssey() {
	return (
		<div>
			<Container className='container-main'>
				<Grid columns={2} stackable>
					<Grid.Column>
						<h1>Ending the Diagnostic Odyssey Act of 2021</h1>
					</Grid.Column>
					<Grid.Column>
						<p className='subtitle'>
							A correct diagnosis empowers parents to find support, participate in research, and ultimately get the right care. We have a strong passion to see that every child with a rare disease receives a diagnosis in a timely manner.
						</p>
					</Grid.Column>
				</Grid>
				<hr className='rule-orange-500 mt-80-40 mb-80-40' />
				<Grid columns={2} stackable>
					<Grid.Column>
						<p>
							Help families and doctors get answers. For a child suspected of having a
							rare genetic disease, getting a diagnosis takes 5 - 7 years on average.
							That’s time spent suffering, and thousands of dollars wasted on
							unnecessary tests, procedures, and hospital stays. The “Ending the
							Diagnostic Odyssey Act” (S. 2022) would help many of these families get a
							diagnosis sooner, by offering states the option to receive additional,
							temporary federal Medicaid funds when they reimburse doctors for whole
							genome sequencing (the most comprehensive test available) of children and
							infants suspected to have a genetic disease.
						</p>
					</Grid.Column>
					<Grid.Column>
						<p>
							The bill has been introduced in the Senate by Sen. Collins (R-ME), Sen.
							Mark Kelly (D-AZ), and Sen. Bob Menendez (D-NJ). This is a strong group of
							bipartisan members that sit on the relevant health committees, but they
							need our support to help get the "Ending the Diagnostic Odyssey Act" to
							the president’s desk.
						</p>
					</Grid.Column>
					<div className='mt-80 mb-80-40 div-grid-container text-center'>
						<a
							href='https://forms.gle/yNhW5mV2Jw9vZMTV7'
							target='_blank'
							rel='noreferrer'
							className='link-button'
							>
							<Button className='button-neutral-100'><p className='subtitle' style={{ margin: '0 auto' }}>Sign now</p> &nbsp;<Image style={{float: 'right'}} src='/icons/GA-link-internal-icon.png' /></Button>
						</a>
					</div>
				</Grid>
				<hr className='rule-blue-300 mb-80-40' />
				<Grid columns={2} stackable className='mb-80-60'>
					<Grid.Column>
						<h1>Write your own</h1>
					</Grid.Column>
					<Grid.Column>
						<p className='subtitle'>Feel free to also send a more personalized letter, perhaps from your members in any of these states. Borrow from this letter liberally!</p>
					</Grid.Column>
				</Grid>
			</Container>
			<div className='pb-120-40' style={{ backgroundColor:'#EDF0F2' }}>
				<Container>
					<Grid columns={3} stackable>
						<Grid.Row className='mt-120-40'>
							<Grid.Column>
								Senator Susan Collins
								<br />
								413 Dirksen Senate Office
								<br />
								Washington, DC 20510
							</Grid.Column>
							<Grid.Column>
								Senator Mark Kelly
								<br />
								516 Hart Senate Office Building
								<br />
								Washington, DC 20510
							</Grid.Column>
							<Grid.Column>
								Senator Bob Menendez
								<br />
								528 Hart Senate Office Building
								<br />
								Washington, DC 20510
							</Grid.Column>
						</Grid.Row>
					</Grid>
					<div className='mt-80-40' style={{ maxWidth: '800px', margin: 'auto' }}>
						<p className='mb-40'>Dear Senators Collins, Menendez, and Kelly,</p>
						<p>
							We are writing to thank you for introducing S.2022, the “Ending the
							Diagnostic Odyssey Act of 2021.” We, the undersigned organizations know
							well how critical a diagnosis is to a family. A correct diagnosis empowers
							parents to find support, participate in research, and ultimately get the
							right care. We have a strong passion to see that every child with a rare
							disease receives a diagnosis in a timely manner.
						</p>
						<p>
							Should you want to know more clearly and personally how important it is to
							stop the terrible suffering of families when there is no diagnosis, please
							reach out. We are happy to share our stories. All of the undersigned
							organizations have members, patients, in your state.
						</p>
						<p>
							As you know, nearly 80% of all rare diseases have a genetic cause, and
							half of rare disease cases impact children. The average diagnostic odyssey
							can last from anywhere from five to seven years. You can imagine the
							anguish of parents watching their children suffer, watching them endure
							one test after another. The toll this takes on the family, emotionally and
							financially, is a great travesty in an age where comprehensive genetic
							screening is available and affordable.
						</p>
						<p>
							Whole genome sequencing promises to alleviate an enormous part of a huge
							burden these families carry knowing the genetic cause of a disease can
							mean an actionable diagnosis – leading to changes in treatment and
							management of the condition, preventing additional unnecessary testing,
							and helping families find a support structure via other families and
							organizations. This benefits the child, the family, and society at large.
							And when there is no treatment at the ready, just having multiple kids
							diagnosed early on, with the hope of gathering data on their condition and
							those of other kids like them, accelerates treatment development.
						</p>
						<p>
							The “Ending the Diagnostic Odyssey Act” would allow states to conduct
							pilot programs via an increase in the Medicaid Federal Medical Assistance
							Percentage rate (FMAP) to provide whole genome sequencing clinical
							services for children on Medicaid with a disease that is suspected to have
							a genetic cause. We are eager to see this bill signed into law so these
							services can be offered to families, regardless of income.
						</p>
						<p>
							This legislation has the potential to further the emerging field of
							precision medicine, lower health care costs by facilitating better
							diagnoses, and improve the lives of every family affected by rare genetic
							conditions.
						</p>
						<p>Thank you again for your leadership on this important legislation.</p>
						<p className='mt-40'>Sincerely,</p>
					</div>
					<Grid columns={2} stackable className='mt-80-40' style={{ maxWidth: '800px', margin: 'auto' }}>
						<Grid.Column id='paddingOdysseyColumn1'>
							<ul className='list-space p-small' style={{ padding: '0', marginTop: '0', marginBottom: '0' }}>
								<li>22Q Texas</li>
								<li>Ago2 Association</li>
								<li>AliveAndKickn</li>
								<li>Alstrom Syndrome International</li>
								<li>American Behcet’s Disease Association</li>
								<li>Amyloidosis Support Groups</li>
								<li>Angioma Alliance</li>
								<li>APBD Research Foundation</li>
								<li>APS Foundation of America, Inc</li>
								<li>Arachnoiditis & Chronic Meningitis Collaborative Research Network</li>
								<li>ARPKD/CHF Alliance</li>
								<li>Asante Mariamu Foundation</li>
								<li>Association for X and Y Variations (AXYS)</li>
								<li>Asthma and Allergy Foundation of America</li>
								<li>Barth Syndrome Foundation</li>
								<li>Batten Disease Support and Research Association</li>
								<li>Blue Faery: The Adrienne Wilson Liver Cancer Association</li>
								<li>Bobby Jones Chiari &amp; Syringomyelia Foundation</li>
								<li>BPAN Warriors</li>
								<li>CFC International</li>
								<li>Children's Cardiomyopathy Foundation</li>
								<li>ClinWiki</li>
								<li>Coalition to Cure CHD2</li>
								<li>Cockayne Syndrome Network - Share &amp; Care</li>
								<li>Colon Cancer Alliance for Research &amp; Education for Lynch Syndrome</li>
								<li>Colorectal Cancer Alliance</li>
								<li>Columbia University Medical Center</li>
								Congenital Adrenal Hyperplasia Research, Education &amp; Support
								<li>Foundation</li>
								<li>Congenital Hyperinsulinism International</li>
								<li>CSNK2A1 Foundation</li>
								<li>Cure CMD</li>
								<li>Cure HHT</li>
								<li>Cure Sanfilippo Foundation</li>
								<li>CURED Nfp</li>
								<li>CureSHANK</li>
								<li>Dravet Syndrome Foundation</li>
								<li>Dup15q Alliance</li>
								<li>Epilepsy Foundation</li>
								<li>Fabry Support &amp; Information Group</li>
								<li>Fellman Studio Inc.</li>
								<li>Fibromuscular Dysplasia Society of America</li>
								<li>FOD Family Support Group</li>
								<li>FORCE: Facing Our Risk of Cancer Empowered</li>
								<li>Foundation for Prader-Willi Research</li>
								<li>Friedreich's Ataxia Research Alliance (FARA)</li>
								<li>Genetic Alliance</li>
								<li>Georgian Foundation for Genetic and Rare Diseases</li>
								<li>Glut1 Deficiency Foundation</li>
								<li>Guide Genetics Inc.</li>
								<li>HCU Network America</li>
								<li>Hemophilia Federation of America</li>
								<li>Hermansky-Pudlak Syndrome Network</li>
								<li>Histiocytosis Association</li>
								<li>Hope For Hypothalamic Hamartomas</li>
								<li>Hydrocephalus Association</li>
								<li>Hypertrophic Cardiomyopathy Association</li>
								<li>Idaho Parents Unlimited</li>
								<li>International Foundation for CDKL5 Research</li>
								<li>International Pemphigus Pemphigoid Foundation</li>
								<li>Kabuki Syndrome Foundation</li>
								<li>KCNT1 Epilepsy Foundation</li>
							</ul>
						</Grid.Column>
						<Grid.Column id='paddingOdysseyColumn2'>
							<ul className='list-space p-small' style={{ padding: '0', marginTop: '0', marginBottom: '0' }}>
								<li>Laboratory Corporation of America Holdings</li>
								<li>Lennox-Gastaut Syndrome Foundation Lupus and Allied Diseases
								Association, Inc.
								</li>
								<li>Lymphangiomatosis &amp; Gorham's Disease Alliance</li>
								<li>MEPAN Foundation</li>
								<li>Mississippi Metabolics Foundation</li>
								<li>MitoAction</li>
								<li>MLD Foundation</li>
								<li>Mowat-Wilson Syndrome Foundation</li>
								<li>National Ataxia Foundation</li>
								<li>National Fabry Disease Foundation</li>
								<li>National Foundation for Ectodermal Dysplasias</li>
								<li>National Neutropenia Network</li>
								<li>National Tay-Sachs &amp; Allied Diseases Association</li>
								<li>NBIA Disorders Association</li>
								<li>Neurofibromatosis Midwest</li>
								<li>No Stomach For Cancer</li>
								<li>Noah's Hope - Hope4Bridget Foundation</li>
								<li>NYU Clinical Genetic Services, Dept Pediatrics, NYU Grossman School of Medicine</li>
								<li>Organic Acidemia Association</li>
								<li>Pachyonychia Congenita Project</li>
								<li>Parent to Parent USA</li>
								<li>Parents and Researchers Interested in Smith-Magenis Syndrome</li>
								<li>Pathways for Rare and Orphan Studies</li>
								<li>Phelan-McDermid Syndrome Foundation</li>
								<li>Precision Healthcare Ecosystem</li>
								<li>Propionic Acidemia Foundation</li>
								<li>PSC Partners Seeking a Cure</li>
								<li>PTEN World</li>
								<li>PXE International</li>
								<li>Rare Epilepsy Network Rare New England</li>
								<li>Rare Trait Hope Fund</li>
								<li>RASopathies Network</li>
								<li>Ring14 USA</li>
								<li>SATB2 Gene Foundation</li>
								<li>SCID Angels for Life Foundation</li>
								<li>Shwachman-Diamond Syndrome Alliance</li>
								<li>SPAN Parent Advocacy Network</li>
								<li>Spastic Paraplegia Foundation</li>
								<li>Stickler Involved People</li>
								<li>Strategic Consulting Partners</li>
								<li>SYNGAP1 Foundation</li>
								<li>Team Sanfilippo Foundation</li>
								<li>Team Titin</li>
								<li>The 40 Percent Inc.</li>
								<li>The Ehlers-Danlos Society</li>
								<li>The Life Raft Group</li>
								<li>The Marfan Foundation</li>
								<li>The Oxalosis and Hyperoxaluria Foundation</li>
								<li>The Sudden Arrhythmia Death Syndromes (SADS) Foundation</li>
								<li>Timothy Syndrome Alliance</li>
								<li>Turner Syndrome Foundation</li>
								<li>TSC Alliance</li>
								<li>Unique</li>
								<li>United Leukodystrophy Foundation</li>
								<li>United Mitochondrial Disease Foundation</li>
								<li>United MSD Foundation, Inc</li>
								<li>US COPD Coalition</li>
								<li>Usher 1F Collaborative</li>
								<li>Usher Syndrome Coalition</li>
								<li>VHL Alliance</li>
								<li>WAGR Syndrome Association</li>
								<li>Wilson Disease Association</li>
								<li>Wiskott-Aldrich Foundation</li>
							</ul>
						</Grid.Column>
					</Grid>
				</Container>
			</div>
		</div>
	)
}

export default EndingTheDiagnosticOdyssey
