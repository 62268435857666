import React, { useState } from 'react'
import { resetPassword } from 'actions/auth'
import { Container, Form, Grid, GridColumn } from 'semantic-ui-react'

const ResetPassword = ({ match }) => {
	const [values, setValues] = useState({
		name: '',
		newPassword: '',
		error: '',
		message: '',
		showForm: true,
	})
	const { newPassword, error, message, showForm } = values

	const handleSubmit = e => {
		e.preventDefault()
		resetPassword({
			newPassword,
			resetPasswordLink: match.params.token,
		}).then(data => {
			if (data.error) {
				setValues({
					...values,
					error: data.error,
					showForm: false,
					newPassword: '',
				})
			} else {
				setValues({
					...values,
					message: data.message,
					error: '',
					showForm: false,
					newPassword: '',
				})
			}
		})
	}

	const showError = () =>
		error ? <div className='alert alert-info'>{error}</div> : ''
	const showMessage = () =>
		message ? <div className='alert alert-info'>{message}</div> : ''

	const passwordResetForm = () => (
		<Form onSubmit={handleSubmit}>
			<Form.Field
				label='New Password'
				control='input'
				type='password'
				onChange={e => setValues({ ...values, newPassword: e.target.value })}
				className='form-control'
				value={newPassword}
				placeholder='Type new password'
				required
			/>
			<Form.Button className='mt-15' color='blue'>
				Change Password
			</Form.Button>
		</Form>
	)

	return (
		<Container className='container-main'>
			<h1 className='text-center'>Forgot Password</h1>
			<Grid centered columns={2}>
				<GridColumn>
					{showMessage()}
					{showError()}
					{showForm && !error && passwordResetForm()}
				</GridColumn>
			</Grid>
		</Container>
	)
}

export default ResetPassword
