import React from 'react'

function IHopeGeneticHealthClinicians() {
    return (
        <>
            <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />  
            <div className='computer-display-flex computer-justify-center mb-80-60-40'>
                <p className='max-width-864px'>
                    If you are a clinician and have a patient with an undiagnosed genetic condition, and would like more information, please contact{' '}
                    <a  
                        href='mailto:ihopegenetichealth@geneticalliance.org'
                    >
                        ihopegenetichealth@geneticalliance.org
                    </a>
                </p>
            </div>
        </>
    )
}

export default IHopeGeneticHealthClinicians