import { Grid, Form } from 'semantic-ui-react'
import { useEffect, useState } from 'react'
import './IRBApplication.css'
import NextBackStep from './NextBackStep'

function StepThirteen({
	step,
	setStep,
	totalSteps,
	updateFields,
	// privacy,
	// futureUse,
	...data
}) {
	const { privacy, futureUse } = data

	const [privacyError, setPrivacyError] = useState(false)
	const [futureUseError, setFutureUseError] = useState(false)

	const isPrivacyMissing = () => {
		if (privacy === '') {
			setPrivacyError(true)
			return true
		}
		setPrivacyError(false)
		return false
	}
	const isFutureUseMissing = () => {
		if (futureUse === '') {
			setFutureUseError(true)
			return true
		}
		setFutureUseError(false)
		return false
	}

	let missingFields = privacyError || futureUseError

	const next = () => {
		isPrivacyMissing()
		isFutureUseMissing()
		if (isPrivacyMissing() === false && isFutureUseMissing() === false) {
			setStep(i => {
				return i + 1
			})
		}
		missingFields = true
	}

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<Grid centered>
			<Grid.Column computer={12}>
				<Form>
					<h3 className='mb-40'>Data Privacy, Security, and Confidentiality</h3>
					<p>
						Describe the ways in which data privacy, security, and confidentiality are
						maintained and protected in this study.{' '}
						<span className='error-color'>*</span>
					</p>
					<Form.TextArea
						label='Describe the ways in which data privacy, security, and confidentiality are
						maintained and protected in this study'
						type='text'
						placeholder='Data privacy, security, and confidentiality are governed by'
						required
						value={privacy}
						onChange={e => {
							setPrivacyError(false)
							updateFields({ privacy: e.target.value })
						}}
					/>
					{privacyError && (
						<p className='error'>
							Description of data privacy, security, and confidentiality is required.
						</p>
					)}
					<h3 className='mt-40 mb-25'>Future Data Use</h3>
					<p>
						Describe the ways in which data will be used in the future (include known
						or pre-established use cases as well as possible or potential ones). As
						mentioned above, future uses of this data shall have the purpose of
						advancing the human understanding of the issue/condition/disease] and all
						related and potentially related factors and information. Keep in mind that
						the extent, form, and method in which all data are to be used in the
						future is unknown. <span className='error-color'>*</span>
					</p>
					<Form.TextArea
						label='Describe the ways in which data will be used in the future'
						placeholder='Data will be used in the future to'
						required
						type='text'
						value={futureUse}
						onChange={e => {
							setFutureUseError(false)
							updateFields({ futureUse: e.target.value })
						}}
					/>
					{futureUseError && (
						<p className='error'>Description of future data use is required.</p>
					)}
				</Form>
				{missingFields && (
					<p className='error missing-field'>
						Please fill all required fields above.
					</p>
				)}

				<NextBackStep
					step={step}
					totalSteps={totalSteps}
					next={next}
					setStep={setStep}
					data={data}
				/>
			</Grid.Column>
		</Grid>
	)
}

export default StepThirteen
