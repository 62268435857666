import { Container, Grid, Accordion, Image } from 'semantic-ui-react'
import './Policy.css'
import { useState } from 'react'
import ListPolicy from 'components/content/ListPolicy.jsx'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { progressIndicator } from '../../content/HelperFunction'
import ReactHtmlParser from 'react-html-parser'


function PolicyFillContent({ data }) {
	// console.log('PolicyFillContent', data);

	const { pathname, hash, key } = useLocation()
	useEffect(() => {
		// if not a hash link, scroll to top
		if (hash === '') {
			window.scrollTo(0, 0)
		}
		// else scroll to id
		else {
			setTimeout(() => {
				const id = hash.replace('#', '')
				const element = document.getElementById(id)
				if (element) {
					element.scrollIntoView({ behavior: 'smooth' })
				}
			}, 0)
		}
	}, [pathname, hash, key])

	useEffect(() => {
		progressIndicator()
	}, [])

	const [activeIndex, setActiveIndex] = useState(0)

	const [iconDropdowns, setIconDropdowns] = useState([
		false, true, true, true, true, true, true, true, true, true, true, true
	]);

	const handleAccordion = (e, index) => {
		const updatedIconDropdowns = iconDropdowns.map((dropdown, i) => (
			i === index ? !dropdown : dropdown
		));
		setActiveIndex(activeIndex === index ? -1 : index);
		setIconDropdowns(updatedIconDropdowns);
	};

	if (!data) return null

	const topDatas = data.filter(item => item.menu === 'Policy' && item.part === 'top')
	const datas = data.filter(item => item.menu === 'Policy' && !item.part)
	// console.log('PolicyFillContent', datas);

	const policies = []

	if (datas && Array.isArray(datas)) {
		datas.sort((a, b) => a.index - b.index || b.dateNumber - a.dateNumber)
	}

	const groupedData = datas.reduce((grouped, item) => {
		const { groupBy } = item;
		if (!grouped.has(groupBy)) {
			grouped.set(groupBy, []);
		}
		grouped.get(groupBy).push(item);
		return grouped;
	}, new Map());
	// console.log(groupedData);

	if (groupedData && groupedData.size > 0) {
		groupedData.forEach((value, key) => (
			policies.push(
				<div key={key}>
					<Accordion.Title className='title-accordion'
						active={activeIndex === value[0].index}
						index={value[0].index}
						onClick={(e) => handleAccordion(e, value[0].index)}
					>
						<h3 id={value[0].index} className='title-dropdown'>{key}</h3>
						<Image src='/icons/accordion-up.png' style={{ width: '24px' }} className={iconDropdowns[value[0].index] ? 'icon-dropdown active' : 'icon-dropdown'} />
					</Accordion.Title>
					<Accordion.Content active={activeIndex === value[0].index}>
						<ListPolicy items={value} />
					</Accordion.Content>
					<hr className='rule-blue-300 ' />
				</div>
			)
		))
	}
	// console.log('policies', policies);

	return (
		<Container className='container-main' id='policy'>
			<Grid columns={2}>
				<Grid.Column mobile={16} tablet={8} computer={8}>
					<h1>{topDatas && topDatas[0] && topDatas[0].header}</h1>
				</Grid.Column>
				<Grid.Column mobile={16} tablet={8} computer={8}>
					{ReactHtmlParser(topDatas && topDatas[0] && topDatas[0].content)}
				</Grid.Column>
			</Grid>
			<hr className='rule-orange-500 mt-80-60-40 mb-65-55-25' />
			<Accordion>
				{policies}
			</Accordion>
		</Container>
	)
}

export default PolicyFillContent