import { Container, Grid, GridColumn, Image, Icon} from 'semantic-ui-react'
import ShareSocialMedia from '../../../ShareSocialMedia'
import LinkButton from '../../../LinkButton'

function CommunicatingAboutYourHealth() {
	const shareUrl = 'https://geneticalliance.org/resources-and-information/advocacy-atlas/communicating-health'
	return (
		<Container className='container-main'>
			<h1>Communicating About Your Health</h1>
			<Grid columns={1} padded>
				<GridColumn>
					<hr />
					<ShareSocialMedia shareUrl={shareUrl} />
					<Grid stackable columns={2} className='mt-15 left aligned'>
						<Grid.Row>
							<Grid.Column>
								<iframe width="500" height="305" src="https://www.youtube.com/embed/P8uVxRVRYXE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							</Grid.Column>
							<Grid.Column>
								<p>
									<i>"Professor B" made this video about his sister's suspected mitochondrial disease as part of a video contest through the United Mitochondrial Disease Foundation's awareness contest.
									<br /><br />
									-Story from a parent advocate</i>
								</p>
								<a className='link-blue-800'
									href='https://www.youtube.com/watch?v=P8uVxRVRYXE&t=9s&ab_channel=BabyFoodSteps'
									target='_blank'
									rel='noreferrer'>
									Click to watch Professor B's video!
								</a>
							</Grid.Column>
						</Grid.Row>
					</Grid>
					<h2 className='color-secondary mt-40'>Resources</h2>
					<Grid stackable columns={2} className='mt-15 left aligned'>
						<Grid.Row>
							<Grid.Column>
								<p>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/communicating-health/Care_Coordination_Notebook_-_English.pdf'
										target='_blank'
										rel='noreferrer'>
										Care Coordination Notebook: Financing and Managing Your Child’s Health Care
									</a>
									- Partnership for People with Disabilities and Parent to Parent of Virginia
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/communicating-health/vt_stories.pdf'
										target='_blank'
										rel='noreferrer'>
										In Our Own Words: Using Family Stories to Teach Family Centered Care
									</a>
									- Parent to Parent of Vermont
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='https://www.fvindiana.org/resources/care-coordination/'
										target='_blank'
										rel='noreferrer'>
										Care Coordination Resources
									</a>
									- Family Voices Indiana
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='https://oley.org/page/being_a_good_patient?&terms'
										target='_blank'
										rel='noreferrer'>
										Being a Good Patient
									</a>
									- The Oley Foundation
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='https://oley.org/page/speakingupbettercare?&terms'
										target='_blank'
										rel='noreferrer'>
										Speaking Up for Better Care
									</a>
									- The Oley Foundation 
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/communicating-health/FCIC_Health_Passport_Form_Typeable_English.pdf'
										target='_blank'
										rel='noreferrer'>
										My Health Passport - for Hospital and Clinic Visits (English Version)
									</a>
									- Florida Center for Inclusive Communities
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/communicating-health/FCIC_Health_Passport_Form_Typeable_spanish.pdf'
										target='_blank'
										rel='noreferrer'>
										My Health Passport - for Hospital and Clinic Visits (Spanish Version)
									</a>
									- Florida Center for Inclusive Communities
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/communicating-health/FCIC_Health_Passport_Chinese_101617_FILL.pdf'
										target='_blank'
										rel='noreferrer'>
										My Health Passport - for Hospital and Clinic Visits (Chinese Version)
									</a>
									- Florida Center for Inclusive Communities
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/communicating-health/FCIC_My_Health_Report_Fillable.pdf'
										target='_blank'
										rel='noreferrer'>
										My Health Report – For Regular Visits to Your Doctor
									</a>
									- Florida Center for Inclusive Communities
								</p>
							</Grid.Column>
							<Grid.Column>
								<div>
									<figure>
										<Image
											src='/images/resources-and-information/advocacy-atlas/communicating-health/alanacrop.jpeg'
											size='medium'
											centered
										/>
										<figcaption className='mt-20 text-center'>
											<i>“My daughter, Alana, was born with a chromosomal difference, Tetrasomy 9p. I wrote a story about Alana’s uniqueness to share with her peers. I used it to share her story with her cousins, classmates, and children at her after school program. The story is only 8 slides long, but it packs a huge message about understanding each person’s individual needs."</i>
										</figcaption>
									</figure>
								</div>
							</Grid.Column>
						</Grid.Row>
					</Grid>
					<div>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/communicating-health/FCIC_EFLH-4-Cancer Fact sheet.pdf'
								target='_blank'
								rel='noreferrer'>
								Education for Lifelong Health Series Factsheets - Cancer (English Version)
							</a>
							- Florida Center for Inclusive Communities
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/communicating-health/FCIC_EFLH-5-Cancer Treatment Fact sheet.pdf'
								target='_blank'
								rel='noreferrer'>
								Education for Lifelong Health Series Factsheets - Cancer Treatment (English Version)
							</a>
							- Florida Center for Inclusive Communities
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/communicating-health/FCIC_EFLH-2-Diabetes Fact sheet.pdf'
								target='_blank'
								rel='noreferrer'>
								Education for Lifelong Health Series Factsheets - Diabetes (English Version)
							</a>
							- Florida Center for Inclusive Communities
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/communicating-health/FCIC_EFLH-1-Hygiene Fact sheet.pdf'
								target='_blank'
								rel='noreferrer'>
								Education for Lifelong Health Series Factsheets - Good Health and Hygiene (English Version)
							</a>
							- Florida Center for Inclusive Communities
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/communicating-health/FCIC_EFLH-3-Osteoporosis Fact sheet.pdf'
								target='_blank'
								rel='noreferrer'>
								Education for Lifelong Health Series Factsheets - Osteoporosis (English Version)
							</a>
							- Florida Center for Inclusive Communities
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/communicating-health/FCIC_EFLH_7_Epilepsy.pdf'
								target='_blank'
								rel='noreferrer'>
								Education for Lifelong Health Series Factsheets - Seizures & Epilepsy (English Version)
							</a>
							- Florida Center for Inclusive Communities
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/communicating-health/FCIC_EFLH-6-TalkingToMyDoctor_Fact sheet.pdf'
								target='_blank'
								rel='noreferrer'>
								Education for Lifelong Health Series Factsheets - How Do I Talk to my Doctor? (English Version)
							</a>
							- Florida Center for Inclusive Communities
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/communicating-health/ELMH_Anxiety.pdf'
								target='_blank'
								rel='noreferrer'>
								Education for Lifelong Mental Health Series Factsheets - Anxiety
							</a>
							- Florida Center for Inclusive Communities
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/communicating-health/ELMH_Depression.pdf'
								target='_blank'
								rel='noreferrer'>
								Education for Lifelong Mental Health Series Factsheets - Depression
							</a>
							- Florida Center for Inclusive Communities
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/communicating-health/Partnering_with_Your_Doctor-A_Medical_Home_Guide.pdf'
								target='_blank'
								rel='noreferrer'>
								Partnering with Your Doctor: The Medical Home Approach
							</a>
							- Region 4 Genetics Collaborative 
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='https://www.engagedpatients.org/ten-ways-prepared-doctor-visit/'
								target='_blank'
								rel='noreferrer'>
								Ten Ways to Be Prepared at a Doctor Visit
							</a>
							- Empowered Patient, Ten Things Patients Should Know Series
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='https://www.engagedpatients.org/getting-help-need-hospital/'
								target='_blank'
								rel='noreferrer'>
								Getting the Help You Need In The Hospital
							</a>
							- Empowered Patient, Ten Things Patients Should Know Series
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='https://www.engagedpatients.org/empowered-patient-ways-patients-can-help-prevent-medication-errors-fact-sheet/'
								target='_blank'
								rel='noreferrer'>
								Ways Patients Can Help Prevent Medication Errors
							</a>
							- Empowered Patient, Ten Things Patients Should Know Series
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='https://www.engagedpatients.org/important-documents-forms/'
								target='_blank'
								rel='noreferrer'>
								Important Documents and Forms
							</a>
							- Empowered Patient, Ten Things Patients Should Know Series
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='https://www.engagedpatients.org/important-questions-hospital-care-team/'
								target='_blank'
								rel='noreferrer'>
								Important Questions for your Hospital Care Team
							</a>
							- Empowered Patient, Ten Things Patients Should Know Series
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='https://www.engagedpatients.org/empowered-patient-coalition-prepared-surgery-fact-sheet/'
								target='_blank'
								rel='noreferrer'>
								Be Prepared For Your Surgery
							</a>
							- Empowered Patient, Ten Things Patients Should Know Series
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/communicating-health/My_Health_My_Decision_Workbook.pdf'
								target='_blank'
								rel='noreferrer'>
								My Health, My Decision
							</a>
							- Resource Connections, Inc.
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/communicating-health/manual.pdf'
								target='_blank'
								rel='noreferrer'>
								Speak Up! Action Manual for Peer Leader Teams
							</a>
							- Acting for Advocacy
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='https://www.youtube.com/watch?v=qAqVL5We8X0&ab_channel=ICHPUF'
								target='_blank'
								rel='noreferrer'>
								Talking With Your Doctor and Other Health Care Professionals-Video
							</a>
							- Institute for Child Health Policy at the University of Florida, Health Care Transition Initiative
						</p>
						<p className='mt-25'>
								<a className='link-blue-800'
								href='/pdf/advocacy-atlas/communicating-health/Hospital-discharge-final-3.pdf'
								target='_blank'
								rel='noreferrer'>
								Hospital Discharge Questions for Families of Children with Special Health Care Needs (English)
								</a>
								- Family Voices of California
						</p>
						<p className='mt-25'>
								<a className='link-blue-800'
								href='/pdf/advocacy-atlas/communicating-health/HospitaDischargeFinal_Spanish.pdf'
								target='_blank'
								rel='noreferrer'>
								Hospital Discharge Questions for Families of Children with Special Health Care Needs (Spanish)
								</a>
								- Family Voices of California
						</p>
						<p className='mt-25'>
								<a className='link-blue-800'
								href='/pdf/advocacy-atlas/communicating-health/hospitaldischargequestions_Chinese.pdf'
								target='_blank'
								rel='noreferrer'>
								Hospital Discharge Questions for Families of Children with Special Health Care Needs (Chinese)
								</a>
								- Family Voices of California
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/communicating-health/TransitiontoAdultHealthcare-EN-complete.pdf'
								target='_blank'
								rel='noreferrer'>
								Being a Healthy Adult: How to Advocate for Your Health and Health Care
							</a>
							- Kathy Roberson, MSW, The Boggs Center on Developmental Disabilities
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='https://www.patientadvocate.org/explore-our-resources/national-financial-resource-directory/'
								target='_blank'
								rel='noreferrer'>
								National Financial Resource Directory
							</a>
							- Patient Advocate Foundation
						</p>
						<p className='mt-25'>
							<a className='link-blue-800'
								href='/pdf/advocacy-atlas/communicating-health/fcca_FamilyTool.pdf'
								target='_blank'
								rel='noreferrer'>
								Family-Centered Care Self-Assessment Tool
							</a>
							- Family Voices
						</p>
					</div>
					<div className='mt-40 text-center'>
						<LinkButton className='ui button' 
						to='/resources-and-information/advocacy-atlas'
						>
							<Icon name='arrow left' /> Return to Advocacy ATLAS Homepage
						</LinkButton>
					</div>
				</GridColumn>
			</Grid>
		</Container>
	)
}

export default CommunicatingAboutYourHealth