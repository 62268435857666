import React from 'react'
import { Container, Grid } from 'semantic-ui-react'

function ClinWikiMergeAnnouncement() {
	return (
		<>
			<Container className='container-main'>
				<h1>
                    Genetic Alliance and ClinWiki Announce Their Merge
				</h1>
				<Grid columns={1} padded>
					<Grid.Row>
						<Grid.Column>
							<p>
                                Genetic Alliance and ClinWiki are excited to announce that the two organizations have merged. ClinWiki provides a free, open-source, web-based application to help patients with catastrophic illnesses not well managed by the “standard of care” understand their clinical trial options. Under the vision and leadership of William Hoos, the founder of ClinWiki, the unique search tool was built to increase access to clinical trial information for everyone so that there can be improved cancer outcomes. After experiencing the death of his father from pancreatic cancer, William was motivated to help patients find and enroll in the best trials to meet their needs and situation. William stated, “Finding the right clinical trial leads to both better patient outcomes and more successful trials and contributes to the advancement of medical innovation.”
							</p>
							<p>
                                ClinWiki joins a suite of Genetic Alliance tools that share essential philosophy and principles. Chief among these is the recognition that individuals are the expert of their own experience. In this role, they need the right tools at the right time to solve the phenomenal challenges they face. In addition, Genetic Alliance believes that long-tail crowd-sourcing is the best way to advance people-centered resources. ClinWiki will be featured in Genetic Alliance’s{' '}
                                <a
									href='https://www.diseaseinfosearch.org/'
									target='_blank'
									rel='noreferrer'>
									Disease InfoSearch
								</a>
                                , a directory of 10,000 diseases and their subtypes, each condition is managed by the advocacy organization dedicated to that condition. Advocacy leaders regularly update the listing, so that newly diagnosed, those seeking clinical trials, academic researchers, and industry scientists can find this information easily. ClinWiki joins Genetic Alliance’s{' '}
                                <a
									href='https://wikiadvocacy.org/Main_Page'
									target='_blank'
									rel='noreferrer'>
									WikiAdvocacy
								</a>{' '}
                                – a 2000-page instruction manual for how to start and sustain an advocacy organization.
							</p>
							<p>
                                ClinWiki is supported by the 1440 Foundation, whose mission is creating hope for living well with the 1,440 minutes we have each day. Katey Kennedy, Executive Director, says, “We are really interested in ClinWiki helping people get to relevant clinical trials more quickly. The 1440 Foundation has a strong commitment to organizations and their leaders that enable people to take charge of their health and wellbeing – whether through addressing comprehensive, coordinated cancer care, or the mental health and wellness of our communities.”
							</p>
							<p>
                                Throughout its founding and early years, ClinWiki also enjoyed support from Cancer Commons. “In the suite of tools Genetic Alliance manages, this completes the picture,” said Genetic Alliance CEO, Sharon Terry. Newly diagnosed and seasoned veterans use Disease InfoSearch to find support and research. Individuals join registries and biobanks that communities create using Genetic Alliance tools. ClinWiki will provide a clear path to involvement in clinical trials so that we have excellent tools for the whole continuum someone with cancer or a rare genetic disease experiences.
							</p>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Container>
		</>
	)
}
export default ClinWikiMergeAnnouncement