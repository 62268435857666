import { Container, Grid, Image, Button } from 'semantic-ui-react'
// import { Link } from 'react-router-dom'
import './IRBApplicationProcess.css'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { progressIndicator } from '../../content/HelperFunction'
import ReactHtmlParser from 'react-html-parser'

function IRBApplicationProcessFillContent({ data }) {
    const { pathname, hash, key } = useLocation()
    useEffect(() => {
        // if not a hash link, scroll to top
        if (hash === '') {
            window.scrollTo(0, 0)
        }
        // else scroll to id
        else {
            setTimeout(() => {
                const id = hash.replace('#', '')
                const element = document.getElementById(id)
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' })
                }
            }, 0)
        }
    }, [pathname, hash, key])

    useEffect(() => {
        progressIndicator()
    }, [])

    if (!data) {
        return null
    }
    const datas = data.filter(item => item.link === '/irb/application-process')
    if (datas && Array.isArray(datas) && datas.length > 0) {
        datas.sort((a, b) => a.index - b.index)
    } else {
        return null
    }
    return (
        <>
            <Container>
                <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
                <Grid columns={2} stackable>
                    <Grid.Column mobile={16} tablet={16} computer={8}>
                        <h3 id={datas[0].id} className='irb'>{datas[0].header}</h3>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={8} className='mt-15'>
                        {ReactHtmlParser(datas[0].content)}
                    </Grid.Column>
                </Grid>
                <hr className='rule-blue-800 mt-80-60-40 mb-80-60-40' />
                <Grid columns={2} stackable>
                    <Grid.Column mobile={16} tablet={16} computer={8}>
                        <h3 className='irb'>{datas[1].header}</h3>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={8}>
                        <a
                            href='mailto:irbadmin@geneticalliance.org'
                            className='link-button'>
                            <Button className='button-neutral-100' style={{ margin: '0' }}>
                                <p className='button-text' style={{ margin: '0 auto' }}>Contact us for more information</p> &nbsp;&nbsp;
                                <Image
                                    className='link-internal-icon'
                                    src='/icons/link-internal-blue.png'
                                />
                            </Button>
                        </a>
                    </Grid.Column>
                </Grid>
                <hr className='rule-blue-800 mt-80-60-40 mb-80-60-40' />
                <Grid columns={2} stackable>
                    <Grid.Column mobile={16} tablet={16} computer={8}>
                        <h3 className='irb'>{datas[2].header}</h3>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={8} className='mt-15'>
                        {ReactHtmlParser(datas[2].content)}
                    </Grid.Column>
                </Grid>
                <hr className='rule-blue-800 mt-80-60-40 mb-80-60-40' />
                <Grid columns={2} stackable>
                    <Grid.Column mobile={16} tablet={16} computer={8}>
                        <h3 id={datas[3].id} className='irb'>{datas[3].header}</h3>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={8} className='mt-15'>
                        {ReactHtmlParser(datas[3].content)}
                    </Grid.Column>
                </Grid>
            </Container>
        </>
    )
}

export default IRBApplicationProcessFillContent
