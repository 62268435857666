import React from 'react'
import {
	Container,
	Grid,
	GridColumn,
	Segment,
	SegmentGroup,
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'

function GeneticAllianceThroughTheYears() {
	return (
		<>
			<Container className='container-main'>
				<h1>Genetic Alliance Through the Years</h1>
				<Grid columns={1} padded>
					<GridColumn>
						<SegmentGroup piled>
							<Segment>
								<h3>1983</h3>

								<h4>
									Mid-Atlantic Regional Human Genetics Network (MARHGN) held a
									symposium&nbsp;entitled&nbsp;
									<em>
										Genetic Disorders and Birth Defects in Families and Society: Toward
										Interdisciplinary Understanding.
									</em>
								</h4>
							</Segment>
							<Segment>
								<h3>1985</h3>

								<h4>
									Mid-Atlantic Regional Human Genetics Network (MARHGN) held a symposium
									entitled{' '}
									<em>
										Genetic Support Groups: Volunteers and Professionals as Partners.
									</em>
								</h4>
							</Segment>
							<Segment>
								<h3>1986</h3>

								<h4>
									<Link className='link-blue-800' to='/about/celebrating-our-directors'>Joan Weiss</Link> founds
									the Alliance for Genetic Support Groups.&nbsp;First office opens in
									Chevy Chase, Maryland.
								</h4>
							</Segment>
							<Segment>
								<h3>1988</h3>

								<h4>
									Non-profit 501 (c) (3)&nbsp;status granted to the Alliance for Genetic
									Support Groups.&nbsp;Joan Weiss officially named Executive Director by
									board of directors.
								</h4>
							</Segment>
							<Segment>
								<h3>1988</h3>

								<h4>
									1st edition of Directory of Genetic Support Groups published&nbsp;
									<em>Alliance Empowerment</em>&nbsp;conference.
								</h4>
							</Segment>
							<Segment>
								<h3>1989</h3>

								<h4>
									800 Number Genetic Information hotline established. Office moved in
									February 1989 to Washington, DC.
								</h4>
							</Segment>
							<Segment>
								<h3>1990</h3>

								<h4>
									Joan Weiss secures HRSA funding for the Alliance (continues to the
									present day)&nbsp;<em>New England Peer Support</em>&nbsp;conference.
								</h4>
							</Segment>
							<Segment>
								<h3>1991</h3>

								<h4>
									<em>Peer Support Guide</em>&nbsp;completed.
								</h4>
							</Segment>
							<Segment>
								<h3>1992</h3>

								<h4>
									<em>Health Insurance Resource Guide.</em>
								</h4>
							</Segment>
							<Segment>
								<h3>1993</h3>

								<h4>
									<em>
										Healthcare in Flux: How will Families with Special Needs Fit in?
									</em>
									&nbsp;conference.
								</h4>
							</Segment>
							<Segment>
								<h3>1994</h3>

								<h4>
									Alliance for Genetic Support Groups publishes&nbsp;
									<em>
										<a className='link-blue-800' href='/geneticalliance.org/sites/default/files/Archives/1994InformedConsent.pdf'>
											Informed Consent: Participation in&nbsp;Genetic Research Studies
										</a>
										.
									</em>
								</h4>
							</Segment>
							<Segment>
								<h3>1996</h3>

								<h4>
									<Link className='link-blue-800' to='/about/directors'>Mary Davidson</Link> took over as Executive
									Director of Alliance for Genetic Support Groups. A gala and fund-raiser
									was held on October 12, 1996 to honor the&nbsp;
									<a className='link-blue-800' href='/geneticalliance.org/sites/default/files/Archives/1996JoanWise.pdf'>
										Tenth Anniversary of the Alliance for Genetic Support
									</a>
									.
								</h4>
							</Segment>
							<Segment>
								<h3>1997</h3>

								<h4>
									Coalition for Genetic Fairness is founded to work for Genetic
									Information Non-discrimination Act.
									<br />
									Mary Davidson at White House kickoff for legislation protecting the
									privacy of genetic information.
								</h4>
							</Segment>
							<Segment>
								<h3>1998</h3>

								<h4>
									<em>
										Forging Genetic Partnerships: Research, Policymakers, and Consumers
									</em>
									&nbsp;conference.
								</h4>
							</Segment>
							<Segment>
								<h3>1999</h3>

								<h4>Name changed to Genetic Alliance.</h4>
							</Segment>
							<Segment>
								<h3>2000</h3>

								<h4>
									<em>Cultural Diversity in Genetics</em>&nbsp;conference.
									<br />
									White House recognizes Genetic Alliance’s role in Human Genome Project.
								</h4>
							</Segment>
							<Segment>
								<h3>2001</h3>

								<h4>
									<em>People’s Genome Celebration</em>&nbsp;at the Museum of Natural
									History.
								</h4>
							</Segment>
							<Segment>
								<h3>2002</h3>

								<h4>
									<em>Directory of Genetic Support Groups</em>&nbsp;becomes digital.
									<br />
									<em>Train the Trainer</em>&nbsp;conference.
								</h4>
							</Segment>
							<Segment>
								<h3>2003</h3>

								<h4>
									<em>Genetic Alliance BioBank </em>is founded.
									<br />
									Genetic Alliance spins off the{' '}
									<em>Genetics and Rare Disease Information Center</em> (GARD).
								</h4>
							</Segment>
							<Segment>
								<h3>2004</h3>

								<h4>
									<em>Joining Our Journeys: One Step at a Time </em>conference.
									<br />
									<a className='link-blue-800' href='http://geneticalliance.org/about/directors'>
										Sharon F. Terry
									</a>
									&nbsp;becomes President and CEO.
									<br />
									Wikiadvocacy.org created.
								</h4>
							</Segment>
							<Segment>
								<h3>2005</h3>

								<h4>
									<em>Institute for Advocacy</em>&nbsp;launched.
									<br />
									<em>Leadership in Alliance: Leveraging Voices, Advancing a Vision</em>
									&nbsp;conference.
									<br />
									Public campaign begins for NIH Open Access Policy.
								</h4>
							</Segment>
							<Segment>
								<h3>2006</h3>

								<h4>
									<em>20th Anniversary Celebration</em>&nbsp;at the National Geographic
									Society.
									<br />
									<em>Excellence in Advocacy</em>&nbsp;conference.
									<br />
									<em>Genetics Day on the Hill.</em>
									<br />
									<em>Genomics, Cancer Care and Advocac</em>y published.
									<br />
									Understanding Genetics: A Guide for Patients and Health Professionals
									published.
								</h4>
							</Segment>
							<Segment>
								<h3>2007</h3>

								<h4>
									<em>Eyes on the Prize: Truth Telling about Genetic Testing Summit</em>
									&nbsp;and monograph published.
									<br />
									<em>Genetics Day on the Hill.</em>
									<br />
									Genetic Testing Stories monograph published.
									<br />
									<em>National Consumer Center for Genetics Resources and Services</em>
									&nbsp;established.
									<br />
									<em>The Year of Advocate</em>&nbsp;conference.
								</h4>
							</Segment>
							<Segment>
								<h3>2008</h3>

								<h4>
									<em>Family Health History Tool</em>&nbsp;is released.
									<br />
									<em>Genetics Day on the Hill.</em>
									<br />
									Genetic Information Non-discrimination Act passed.
									<br />
									<em>Making Sense of Your Genes: A Guide to Genetic Counseling</em>
									&nbsp;published.
									<br />
									<em>Transformational Leadership</em>&nbsp;conference.
								</h4>
							</Segment>
							<Segment>
								<h3>2009</h3>

								<h4>
									<em>Discovering Openness in Health Systems</em>&nbsp;conference.
									<br />
									<em>Genetics Day on the Hill.</em>
									<br />
									<em>
										GeneScreen: A Night of Film on Health and Genetics Newborn Screening
										Summit.
									</em>
									<br />
									<em>
										Trust It or Trash It: Creating &amp; Assessing Health Information
									</em>
									&nbsp;meeting and monograph.
								</h4>
							</Segment>
							<Segment>
								<h3>2010</h3>

								<h4>
									<em>Advancing Novel Partnerships</em>&nbsp;conference.
									<br />
									<em>
										Community-Centered Family Health History. Collaboration Across
										Communities: How Do You Make Research Community-Specific and
										Universally Relevant?
									</em>
									&nbsp;published.
									<br />
									<em>Gene Screen: A Night of Film on Health and Genetics</em>&nbsp;
									<em>Genetics Day on the Hill.</em>
									<br />
									Research!America award: Paul G. Rogers Distinguished Organization
									Advocacy Award.
									<br />
									Testimony before the House Committee on Oversight and Government Reform
									about Public Access to Federally Funded Research.
									<br />
									Testimony before the House Appropriations Committee about gene
									patenting.
									<br />
									Testimony before the House Committee on Science and Technology about
									standards.
									<br />
									<em>Translational Research Enterprise—What Are We To Do?</em> Meeting.
								</h4>
							</Segment>
							<Segment>
								<h3>2011</h3>

								<h4>
									<em>25th Anniversary Celebration</em>&nbsp;at the National Geographic
									Society.
									<br />
									<em>25th Anniversary Year </em>conference.
									<br />
									<em>Baby’s First Test</em>&nbsp;is launched.
									<br />
									Clinical Research Forum’s Public Advocacy Award.
									<br />
									<em>Genetics Day on the Hill.</em>
									<br />
									<em>Future of Public Health Genomics</em>&nbsp;meeting.
									<br />
									<em>Platform for Engaging Everyone Responsibly (PEER)</em>
									&nbsp;created.
								</h4>
							</Segment>
							<Segment>
								<h3>2012:</h3>

								<h4>
									<em>Directory of Diseases</em>&nbsp;online becomes&nbsp;
									<em>DiseaseInfoSearch.org.</em>
									<br />
									<em>
										Genetic Testing and Data Management: Improving Health Outcomes,
										Disease Management, and Accountable Care Delivery
									</em>
									&nbsp;Summit
									<br />
									PEER wins $350,000 to create crowdsourced cross-condition registry.
								</h4>
							</Segment>
							<Segment>
								<h3>2013</h3>

								<h4>
									<em>
										Advocacy ATLAS: Accessible Tools for Leadership and Advocacy Success
									</em>
									&nbsp;launched.
									<br />
									Ashoka Changemakers First Prize for PEER.
									<br />
									<em>Beyond the Bloodspot</em>&nbsp;summit.
									<br />
									<em>GenesInLife.org</em>&nbsp;is launched Forbes Best Business Model
									for Transforming Health Systems for PEER.
									<br />
									<em>Newborn Screening Saves Lives Act</em> reauthorized.
									<br />
									<em>
										Powerful Patient Data: Genomics and Family Health History in Health IT
									</em>
									&nbsp;summit.
									<br />
									Testimony before the Senate HELP committee on newborn screening.
								</h4>
							</Segment>
							<Segment>
								<h3>2014:</h3>

								<h4>
									<em>Community Engaged Network for All</em>&nbsp;launched.
									<br />
									Multimillion-Dollar Newborn Screening Award.
									<br />
									Robert Wood Johnson Foundation Pioneer Award for PEER.
								</h4>
							</Segment>
							<Segment>
								<h3>2015</h3>

								<h4>
									<em>Baby’s First Test</em>&nbsp;available in Spanish.
									<br />
									Fielded a national needs assessment&nbsp;
									<em>Understanding Access to and Quality of Genetic Services.</em>
									<br />
									Genetic Alliance becomes part of the&nbsp;
									<em>PCORnet Coordinating Center.</em>
									<br />
									<em>Perinatal Nutrition Working Group</em>&nbsp;finds new home in
									Expecting Health.
									<br />
									Platform for Engaging Everyone Responsibly(PEER) recognized in White
									House conference.
								</h4>
							</Segment>
							<Segment>
								<h3>2016</h3>

								<h4>
									<em>Building Trustworthiness in PCORnet</em>&nbsp;conference.
									<br />
									Sharon Terry presents at TEDMED.
									<br />
									<em>White House Precision Medicine</em>&nbsp;Summit.
								</h4>
							</Segment>
							<Segment>
								<h3>2017</h3>

								<h4>
									<em>Co-creating a Healthy Future: 30th Anniversary</em>
									&nbsp;conference.
								</h4>
							</Segment>
						</SegmentGroup>
					</GridColumn>
				</Grid>
			</Container>
		</>
	)
}

export default GeneticAllianceThroughTheYears
