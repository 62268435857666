import { useState } from "react"
import { listSearch } from "actions/blog"
import { Form, Input } from "semantic-ui-react"
import {Link} from 'react-router-dom'

const Search = () => {
    const [values, setValues] = useState({
        search: undefined,
        results: [],
        searched: false,
        message: ''
    })
    
    const { search, results, searched, message } = values

    const searchSubmit = (e) => {
        e.preventDefault()
        listSearch({ search }).then(data => {
            setValues({ ...values, results: data, searched: true, message: `${data.length} blogs found` })
        })
    }

    const handleChange = (e) => {
        setValues({ ...values, searched: false, search: e.target.value, results: [] })
    }

    const searchedBlogs = (results = []) => {
        return (
            <div className='jumbotron bg-white'>
                {message && <p className='pt-4 text-muted font-italic'>{message}</p>}
                {results.map((blog, i) => (
                    <div key={i}>
                        <Link to={`/news/${blog.slug}`}><p>{blog.title}</p></Link>
                    </div>
                ))}
            </div>
        )
    }

    const searchForm = () => (
        <Form onSubmit={searchSubmit} className='pb-20'>
            <Input type="search" placeholder='Search News...' onChange={handleChange} action={{ icon: 'search' }} />
        </Form>
    )

    return (
        <>
        {searchForm()}
        {searched && <div style={{ marginTop: '-1rem', marginBottom: '1rem' }}>{searchedBlogs(results)}</div>}
        </>
    )
}

export default Search