import { Grid, Button, Image, Form, Progress } from 'semantic-ui-react'
import { useEffect, useState } from 'react'
import { create, update } from 'actions/application'
import './IRBApplication.css'
import { toast } from 'react-toastify'

function StepFourteen({
	step,
	setStep,
	totalSteps,
	updateFields,
	...data
}) {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	//for file upload error message (only for required files)
	const [uploadAppendixAError, setUploadAppendixAError] = useState(false)
	const [uploadAppendixBError, setUploadAppendixBError] = useState(false)
	const [uploadAppendixCError, setUploadAppendixCError] = useState(false)
	const [uploadSignatureError, setUploadSignatureError] = useState(false)
	const [uploadPiBiosketchError, setUploadPiBiosketchError] = useState(false)
	const [uploadPiTrainingCertificateError, setUploadPiTrainingCertificateError] =
		useState(false)

	//for file upload (any file, required or not)
	const [appendixAFile, setAppendixAFile] = useState(null)
	const [appendixBFile, setAppendixBFile] = useState(null)
	const [appendixCFile, setAppendixCFile] = useState(null)
	const [signatureFile, setSignatureFile] = useState(null)
	const [piBiosketchFile, setPiBiosketchFile] = useState(null)
	const [piTrainingCertificateFile, setPiTrainingCertificateFile] =
		useState(null)
	const [otherDocumentsFile1, setOtherDocumentsFile1] = useState(null)
	const [otherDocumentsFile2, setOtherDocumentsFile2] = useState(null)
	const [otherDocumentsFile3, setOtherDocumentsFile3] = useState(null)
	const [otherDocumentsFile4, setOtherDocumentsFile4] = useState(null)
	// const [otherBiosketchFile, setOtherBiosketchFile] = useState(null)
	// const [otherTrainingCertificateFile, setOtherTrainingCertificateFile] =
	// 	useState(null)
	const [otherKeyBiosketchFile1, setOtherKeyBiosketchFile1] = useState(null)
	const [otherKeyBiosketchFile2, setOtherKeyBiosketchFile2] = useState(null)
	const [otherKeyBiosketchFile3, setOtherKeyBiosketchFile3] = useState(null)
	const [otherKeyBiosketchFile4, setOtherKeyBiosketchFile4] = useState(null)
	const [otherKeyBiosketchFile5, setOtherKeyBiosketchFile5] = useState(null)
	const [otherKeyTrainingCertificateFile1, setOtherKeyTrainingCertificateFile1] =
		useState(null)
	const [otherKeyTrainingCertificateFile2, setOtherKeyTrainingCertificateFile2] =
		useState(null)
	const [otherKeyTrainingCertificateFile3, setOtherKeyTrainingCertificateFile3] =
		useState(null)
	const [otherKeyTrainingCertificateFile4, setOtherKeyTrainingCertificateFile4] =
		useState(null)
	const [otherKeyTrainingCertificateFile5, setOtherKeyTrainingCertificateFile5] =
		useState(null)	

	//for uploaded file size error message (any file, required or not)
	const [uploadAppendixASizeError, setUploadAppendixASizeError] = useState(false)
	const [uploadAppendixBSizeError, setUploadAppendixBSizeError] = useState(false)
	const [uploadAppendixCSizeError, setUploadAppendixCSizeError] = useState(false)
	const [uploadSignatureSizeError, setUploadSignatureSizeError] = useState(false)
	const [uploadPiBiosketchSizeError, setUploadPiBiosketchSizeError] =
		useState(false)
	const [
		uploadPiTrainingCertificateSizeError,
		setUploadPiTrainingCertificateSizeError,
	] = useState(false)
	const [uploadOtherDocumentsSizeError1, setUploadOtherDocumentsSizeError1] =
		useState(false)
	const [uploadOtherDocumentsSizeError2, setUploadOtherDocumentsSizeError2] =
		useState(false)
	const [uploadOtherDocumentsSizeError3, setUploadOtherDocumentsSizeError3] =
		useState(false)
	const [uploadOtherDocumentsSizeError4, setUploadOtherDocumentsSizeError4] =
		useState(false)
	// const [uploadOtherBiosketchSizeError, setUploadOtherBiosketchSizeError] =
	// 	useState(false)
	// const [
	// 	uploadOtherTrainingCertificateSizeError,
	// 	setUploadOtherTrainingCertificateSizeError,
	// ] = useState(false)
	const [uploadOtherKeyBiosketchSizeError1, setUploadOtherKeyBiosketchSizeError1] =
		useState(false)
	const [uploadOtherKeyBiosketchSizeError2, setUploadOtherKeyBiosketchSizeError2] =
		useState(false)
	const [uploadOtherKeyBiosketchSizeError3, setUploadOtherKeyBiosketchSizeError3] =
		useState(false)
	const [uploadOtherKeyBiosketchSizeError4, setUploadOtherKeyBiosketchSizeError4] =
		useState(false)
	const [uploadOtherKeyBiosketchSizeError5, setUploadOtherKeyBiosketchSizeError5] =
		useState(false)
	const [
		uploadOtherKeyTrainingCertificateSizeError1,
		setUploadOtherKeyTrainingCertificateSizeError1,
	] = useState(false)
	const [
		uploadOtherKeyTrainingCertificateSizeError2,
		setUploadOtherKeyTrainingCertificateSizeError2,
	] = useState(false)
	const [
		uploadOtherKeyTrainingCertificateSizeError3,
		setUploadOtherKeyTrainingCertificateSizeError3,
	] = useState(false)
	const [
		uploadOtherKeyTrainingCertificateSizeError4,
		setUploadOtherKeyTrainingCertificateSizeError4,
	] = useState(false)
	const [
		uploadOtherKeyTrainingCertificateSizeError5,
		setUploadOtherKeyTrainingCertificateSizeError5,
	] = useState(false)

	let missingFields =
		uploadAppendixAError ||
		uploadAppendixBError ||
		uploadAppendixCError ||
		uploadSignatureError ||
		uploadPiBiosketchError ||
		uploadPiTrainingCertificateError
	let oversizeFiles =
		uploadAppendixASizeError ||
		uploadAppendixBSizeError ||
		uploadAppendixCSizeError ||
		uploadSignatureSizeError ||
		uploadPiBiosketchSizeError ||
		uploadPiTrainingCertificateSizeError ||
		uploadOtherDocumentsSizeError1 ||
		uploadOtherDocumentsSizeError2 ||
		uploadOtherDocumentsSizeError3 ||
		uploadOtherDocumentsSizeError4 ||
		// uploadOtherBiosketchSizeError ||
		// uploadOtherTrainingCertificateSizeError ||
		uploadOtherKeyBiosketchSizeError1 ||
		uploadOtherKeyBiosketchSizeError2 ||
		uploadOtherKeyBiosketchSizeError3 ||
		uploadOtherKeyBiosketchSizeError4 ||
		uploadOtherKeyBiosketchSizeError5 ||
		uploadOtherKeyTrainingCertificateSizeError1 ||
		uploadOtherKeyTrainingCertificateSizeError2 ||
		uploadOtherKeyTrainingCertificateSizeError3 ||
		uploadOtherKeyTrainingCertificateSizeError4 ||
		uploadOtherKeyTrainingCertificateSizeError5

	const [sent, setSent] = useState(false);

	const submit = () => {
		//if file is not uploaded, set error to true
		if (appendixAFile == null) {
			setUploadAppendixAError(true)
		}
		if (appendixBFile == null) {
			setUploadAppendixBError(true)
		}
		if (appendixCFile == null) {
			setUploadAppendixCError(true)
		}
		if (signatureFile == null) {
			setUploadSignatureError(true)
		}
		if (piBiosketchFile == null) {
			setUploadPiBiosketchError(true)
		}
		if (piTrainingCertificateFile == null) {
			setUploadPiTrainingCertificateError(true)
		}
		//if any of the uploaded files are larger than 5MB, set error to true
		if (appendixAFile != null && appendixAFile.size > 5242880) {
			setUploadAppendixASizeError(true)
		}
		if (appendixBFile != null && appendixBFile.size > 5242880) {
			setUploadAppendixBSizeError(true)
		}
		if (appendixCFile != null && appendixCFile.size > 5242880) {
			setUploadAppendixCSizeError(true)
		}
		if (signatureFile != null && signatureFile.size > 5242880) {
			setUploadSignatureSizeError(true)
		}
		if (piBiosketchFile != null && piBiosketchFile.size > 5242880) {
			setUploadPiBiosketchSizeError(true)
		}
		if (
			piTrainingCertificateFile != null &&
			piTrainingCertificateFile.size > 5242880
		) {
			setUploadPiTrainingCertificateSizeError(true)
		}
		if (otherDocumentsFile1 != null && otherDocumentsFile1.size > 5242880) {
			setUploadOtherDocumentsSizeError1(true)
		}
		if (otherDocumentsFile2 != null && otherDocumentsFile2.size > 5242880) {
			setUploadOtherDocumentsSizeError2(true)
		}
		if (otherDocumentsFile3 != null && otherDocumentsFile3.size > 5242880) {
			setUploadOtherDocumentsSizeError3(true)
		}
		if (otherDocumentsFile4 != null && otherDocumentsFile4.size > 5242880) {
			setUploadOtherDocumentsSizeError4(true)
		}
		// if (otherBiosketchFile != null && otherBiosketchFile.size > 5242880) {
		// 	setUploadOtherBiosketchSizeError(true)
		// }
		// if (
		// 	otherTrainingCertificateFile != null &&
		// 	otherTrainingCertificateFile.size > 5242880
		// ) {
		// 	setUploadOtherTrainingCertificateSizeError(true)
		// }
		if (otherKeyBiosketchFile1 != null && otherKeyBiosketchFile1.size > 5242880) {
			setUploadOtherKeyBiosketchSizeError1(true)
		}
		if (otherKeyBiosketchFile2 != null && otherKeyBiosketchFile2.size > 5242880) {
			setUploadOtherKeyBiosketchSizeError2(true)
		}
		if (otherKeyBiosketchFile3 != null && otherKeyBiosketchFile3.size > 5242880) {
			setUploadOtherKeyBiosketchSizeError3(true)
		}
		if (otherKeyBiosketchFile4 != null && otherKeyBiosketchFile4.size > 5242880) {
			setUploadOtherKeyBiosketchSizeError4(true)
		}
		if (otherKeyBiosketchFile5 != null && otherKeyBiosketchFile5.size > 5242880) {
			setUploadOtherKeyBiosketchSizeError5(true)
		}
		if (
			otherKeyTrainingCertificateFile1 != null &&
			otherKeyTrainingCertificateFile1.size > 5242880
		) {
			setUploadOtherKeyTrainingCertificateSizeError1(true)
		}
		if (
			otherKeyTrainingCertificateFile2 != null &&
			otherKeyTrainingCertificateFile2.size > 5242880
		) {
			setUploadOtherKeyTrainingCertificateSizeError2(true)
		}
		if (
			otherKeyTrainingCertificateFile3 != null &&
			otherKeyTrainingCertificateFile3.size > 5242880
		) {
			setUploadOtherKeyTrainingCertificateSizeError3(true)
		}
		if (
			otherKeyTrainingCertificateFile4 != null &&
			otherKeyTrainingCertificateFile4.size > 5242880
		) {
			setUploadOtherKeyTrainingCertificateSizeError4(true)
		}
		if (
			otherKeyTrainingCertificateFile5 != null &&
			otherKeyTrainingCertificateFile5.size > 5242880
		) {
			setUploadOtherKeyTrainingCertificateSizeError5(true)
		}
		console.log('sSubmit')

		if (appendixAFile != null
			&& appendixBFile != null
			&& appendixCFile != null
			&& signatureFile != null
			&& piBiosketchFile != null
			&& piTrainingCertificateFile != null
		) { // if all files are uploaded
			console.log('no missing files')
			createApplication()
			setSent(true)
		}

		// if all required files are uploaded, create application
		// if (
		// 	appendixAFile != null && setUploadAppendixASizeError(false) &&
		// 	appendixBFile != null && setUploadAppendixBSizeError(false) &&
		// 	appendixCFile != null && setUploadAppendixCSizeError(false) &&
		// 	signatureFile != null && setUploadSignatureSizeError(false) &&
		// 	piBiosketchFile != null && setUploadPiBiosketchSizeError(false) &&
		// 	piTrainingCertificateFile != null && setUploadPiTrainingCertificateSizeError(false)
		// ) {
		// 	console.log('no missing files')
		// 	createApplication()
		// 	console.log('1CreateApplication')
		// 	setSent(true)
		// }

		// if (
		// 	setUploadAppendixASizeError(false) &&
		// 	setUploadAppendixBSizeError(false) &&
		// 	setUploadAppendixCSizeError(false) &&
		// 	setUploadSignatureSizeError(false) &&
		// 	setUploadPiBiosketchSizeError(false) &&
		// 	setUploadPiTrainingCertificateSizeError(false) &&
		// 	setUploadOtherDocumentsSizeError1(false) &&
		// 	setUploadOtherDocumentsSizeError2(false) &&
		// 	setUploadOtherDocumentsSizeError3(false) &&
		// 	setUploadOtherDocumentsSizeError4(false) &&
		// 	// setUploadOtherBiosketchSizeError(false) &&
		// 	// setUploadOtherTrainingCertificateSizeError(false) &&
		// 	setUploadOtherKeyBiosketchSizeError1(false) &&
		// 	setUploadOtherKeyBiosketchSizeError2(false) &&
		// 	setUploadOtherKeyBiosketchSizeError3(false) &&
		// 	setUploadOtherKeyBiosketchSizeError4(false) &&
		// 	setUploadOtherKeyBiosketchSizeError5(false) &&
		// 	setUploadOtherKeyTrainingCertificateSizeError1(false) &&
		// 	setUploadOtherKeyTrainingCertificateSizeError2(false) &&
		// 	setUploadOtherKeyTrainingCertificateSizeError3(false) &&
		// 	setUploadOtherKeyTrainingCertificateSizeError4(false) &&
		// 	setUploadOtherKeyTrainingCertificateSizeError5(false)
		// ) {
		// 	console.log('no oversize files')
		// 	createApplication() 
		// 	console.log('2CreateApplication')
		// 	setSent(true)
		// }
	}

	function createApplication() {
		const files = [
			appendixAFile,
			appendixBFile,
			appendixCFile,
			signatureFile,
			piBiosketchFile,
			piTrainingCertificateFile,
			otherDocumentsFile1,
			otherDocumentsFile2,
			otherDocumentsFile3,
			otherDocumentsFile4,
			// otherBiosketchFile,
			// otherTrainingCertificateFile,
			otherKeyBiosketchFile1,
			otherKeyBiosketchFile2,
			otherKeyBiosketchFile3,
			otherKeyBiosketchFile4,
			otherKeyBiosketchFile5,
			otherKeyTrainingCertificateFile1,
			otherKeyTrainingCertificateFile2,
			otherKeyTrainingCertificateFile3,
			otherKeyTrainingCertificateFile4,
			otherKeyTrainingCertificateFile5,
		]
		console.log('data', data)
		console.log('files', files)
		const dataSubmit = { ...data, ...{ status: 'completed', step: step } }
		if (data._id) {
			update(dataSubmit, files, 'token').then(res => {
				console.log(res)
				toast.success('Application saved successfully')
			})
		} else {
			create(dataSubmit, files, 'token').then(res => {
				console.log('res', res)
				if (res._id) {
					console.log('success')
					toast.success('Application saved successfully')
				} else {
					console.log('error')
					toast.error('Application failed to save')
				}
			})
		}
	}

	function handleChange(event, name) {
		// console.log(files)
		// setFiles(prev => {
		// 	// updateFiles(...prev, ...event.target.files)
		// 	const newFiles = { ...prev, ...event.target.files }
		// 	console.log(newFiles)
		// 	return newFiles
		// })
		// const file = { ...event.target.files[0] }
		// console.log(file)
		// if (file !== undefined && file !== null) {
		// 	file.name = name
		// 	let isFileNew = true
		// 	for (let i = 0; i < files.length; i++) {
		// 		if (files[i].name = name) {
		// 			files[i] = file
		// 			isFileNew = false
		// 		}
		// 	}
		// 	if (isFileNew) {
		// 		files.push(file)
		// 	}
		// 	setFiles(files)
		// }
		// if (event.target.files.name === 'appendixA.docx') {
		// 	setUploadAppendixA(true)
		// } else if (event.target.files.name === 'appendixB.docx') {
		// 	setUploadAppendixB(true)
		// } else if (event.target.files.name === 'appendixC.docx') {
		// 	setUploadAppendixC(true)
		// } else if (event.target.files.name === 'otherDocs.docx') {
		// 	setUploadOtherDocs(true)
		// } else if (event.target.files.name === 'signature.docx') {
		// 	setUploadSignature(true)
		// } else if (event.target.files.name === 'piBiosketch.docx') {
		// 	setUploadPiBiosketch(true)
		// } else if (event.target.files.name === 'piTrainingCertificate.docx') {
		// 	setUploadPiTrainingCertificate(true)
		// } else if (event.target.files.name === 'otherBiosketch.docx') {
		// 	setUploadOtherBiosketch(true)
		// } else if (event.target.files.name === 'otherTrainingCertificate.docx') {
		// 	setUploadOtherTrainingCertificate(true)
		// } else if (event.target.files.name === 'otherKeyBiosketch.docx') {
		// 	setUploadOtherKeyBiosketch(true)
		// } else if (event.target.files.name === 'otherKeyTrainingCertificate.docx') {
		// 	setUploadOtherKeyTrainingCertificate(true)
		// }
	}

	let percentage = (step / totalSteps) * 100
	const back = () => {
		setStep(i => {
			return i - 1
		})
	}

	return (
		<Grid centered>
			{!sent && <Grid.Column computer={9} tablet={16} mobile={16}>
				<Form>
					<h3 className='mb-40'>Uploads (Appendicies, Signature Page, and Credentials)</h3>
					<p className='mb-40'>
						<a
							href='https://drive.google.com/drive/u/0/folders/1NDKrcytLSjmJvGsjKrBH73M9g2rc_6iC'
							target='_blank'
							rel='noreferrer'>
							Appendices can be downloaded here.
						</a>
					</p>
					<Form.Field>
						<p className='field-title mt-40'>
							Upload Appendix A - Key Personnel <span className='error-color'>*</span>
						</p>
						<input
							className='p-compact inner-border-form'
							type='file'
							required
							id='appendixA'
							accept='application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
							onChange={e => {
								handleChange(e, 'appendixA.docx')
								setAppendixAFile(e.target.files[0])
								setUploadAppendixAError(false)
								setUploadAppendixASizeError(false)
							}}
						/>
					</Form.Field>
					{uploadAppendixAError && <p className='error'>Appendix A is required.</p>}
					{uploadAppendixASizeError && (
						<p className='error'>Appendix A file size must be less than 5 MB.</p>
					)}
					<Form.Field className='mt-40'>
						<p className='field-title'>
							Upload Appendix B - Outreach Plan <span className='error-color'>*</span>
						</p>
						<input
							className='p-compact inner-border-form'
							type='file'
							required
							id='appendixB'
							accept='application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
							onChange={e => {
								handleChange(e, 'appendixB.docx')
								setAppendixBFile(e.target.files[0])
								setUploadAppendixBError(false)
								setUploadAppendixBSizeError(false)
							}}
						/>
					</Form.Field>
					{uploadAppendixBError && <p className='error'>Appendix B is required.</p>}
					{uploadAppendixBSizeError && (
						<p className='error'>Appendix B file size must be less than 5 MB.</p>
					)}
					<Form.Field className='mt-40'>
						<p className='field-title'>
							Upload Appendix C - Participant Outreach and Recruitment Samples <span className='error-color'>*</span>
						</p>
						<input
							className='p-compact inner-border-form'
							type='file'
							required
							id='appendixC'
							accept='application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
							onChange={e => {
								setAppendixCFile(e.target.files[0])
								setUploadAppendixCError(false)
								setUploadAppendixCSizeError(false)
							}}
						/>
					</Form.Field>
					{uploadAppendixCError && <p className='error'>Appendix C is required.</p>}
					{uploadAppendixCSizeError && (
						<p className='error'>Appendix C file size must be less than 5 MB.</p>
					)}
					<Form.Field className='mt-40'>
						<p className='field-title'>
							Upload Survey Instruments, Questionnaires, or other Participant-Facing documents. If you have more than four files, please email additional files to{' '} 
							<a
								href='mailto:irbadmin@geneticalliance.org'
								className='field-title'
							>
								irbadmin@geneticalliance.org
							</a>.
						</p>
						<input
							className='p-compact inner-border-form'
							type='file'
							id='otherDocs1'
							accept='application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
							onChange={e => {
								setOtherDocumentsFile1(e.target.files[0])
								setUploadOtherDocumentsSizeError1(false)
							}}
						/>
					</Form.Field>
					{uploadOtherDocumentsSizeError1 && (
						<p className='error'>
							Survey Instruments, Questionnaires, or Other Participant-Facing Documents file size must be less than 5 MB.
						</p>
					)}
					<Form.Field>
						<input
							className='p-compact inner-border-form'
							type='file'
							id='otherDocs2'
							accept='application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
							onChange={e => {
								setOtherDocumentsFile2(e.target.files[0])
								setUploadOtherDocumentsSizeError2(false)
							}}
						/>
					</Form.Field>
					{uploadOtherDocumentsSizeError2 && (
						<p className='error'>
							Survey Instruments, Questionnaires, or Other Participant-Facing Documents file size must be less than 5 MB.
						</p>
					)}
					<Form.Field>
						<input
							className='p-compact inner-border-form'
							type='file'
							id='otherDocs3'
							accept='application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
							onChange={e => {
								setOtherDocumentsFile3(e.target.files[0])
								setUploadOtherDocumentsSizeError3(false)
							}}
						/>
					</Form.Field>
					{uploadOtherDocumentsSizeError3 && (
						<p className='error'>
							Survey Instruments, Questionnaires, or Other Participant-Facing Documents file size must be less than 5 MB.
						</p>
					)}
					<Form.Field>
						<input
							className='p-compact inner-border-form'
							type='file'
							id='otherDocs4'
							accept='application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
							onChange={e => {
								setOtherDocumentsFile4(e.target.files[0])
								setUploadOtherDocumentsSizeError4(false)
							}}
						/>
					</Form.Field>
					{uploadOtherDocumentsSizeError4 && (
						<p className='error'>
							Survey Instruments, Questionnaires, or Other Participant-Facing Documents file size must be less than 5 MB.
						</p>
					)}
					<Form.Field className='mt-40'>
						<p className='field-title'>
							Upload Principal Investigator’s NIH-style biosketch (or CV). If there are two Co-PIs then upload under “Key Personnel” below. <span className='error-color'>*</span>
						</p>
						<input
							className='p-compact inner-border-form'
							type='file'
							required
							id='piBiosketch'
							accept='application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
							onChange={e => {
								setPiBiosketchFile(e.target.files[0])
								setUploadPiBiosketchError(false)
								setUploadPiBiosketchSizeError(false)
							}}
						/>
					</Form.Field>
					{uploadPiBiosketchError && (
						<p className='error'>Principal Investigator NIH-Style Biosketch (or CV) is required.</p>
					)}
					{uploadPiBiosketchSizeError && (
						<p className='error'>
							Principal Investigator NIH-Style Biosketch (or CV) file size must be less than 5 MB.
						</p>
					)}
					<Form.Field className='mt-40'>
						<p className='field-title'>
							Upload Principal Investigator’s Human Research Protection Training Certificate (see page 4). If there are two Co-PIs, then upload them under “Key Personnel” below.{' '}
							<span className='error-color'>*</span>
						</p>
						<input
							className='p-compact inner-border-form'
							type='file'
							required
							id='piTrainingCertificate'
							accept='application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
							onChange={e => {
								setPiTrainingCertificateFile(e.target.files[0])
								setUploadPiTrainingCertificateError(false)
								setUploadPiTrainingCertificateSizeError(false)
							}}
						/>
					</Form.Field>
					{uploadPiTrainingCertificateError && (
						<p className='error'>
							Principal Investigator Human Research Protection Training Certificate is required.
						</p>
					)}
					{uploadPiTrainingCertificateSizeError && (
						<p className='error'>
							Principal Investigator Human Research Protection Training Certificate file size must be less
							than 5 MB.
						</p>
					)}
					{/* <Form.Field className='mt-40'>
						<p className='field-title'>
							Other Investigator NIH-Style Biosketch (or CV)
						</p>
						<input
							className='p-compact inner-border-form'
							type='file'
							id='otherBiosketch'
							accept='application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
							onChange={e => {
								setOtherBiosketchFile(e.target.files[0])
								setUploadOtherBiosketchSizeError(false)
							}}
						/>
					</Form.Field>
					{uploadOtherBiosketchSizeError && (
						<p className='error'>
							Other Investigator NIH-Style Biosketch (or CV) file size must be less
							than 5 MB.
						</p>
					)}
					<Form.Field className='mt-40'>
						<p className='field-title'>
							Other Investigator Human Research Protection Training Certificate
						</p>
						<input
							className='p-compact inner-border-form'
							type='file'
							id='otherTrainingCertificate'
							accept='application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
							onChange={e => {
								setOtherTrainingCertificateFile(e.target.files[0])
								setUploadOtherTrainingCertificateSizeError(false)
							}}
						/>
					</Form.Field>
					{uploadOtherTrainingCertificateSizeError && (
						<p className='error'>
							Other Investigator Human Research Protection Training Certificate file
							size must be less than 5 MB.
						</p>
					)} */}
					<Form.Field className='mt-40'>
						<p className='field-title'>
							Upload all Key Personnel (persons listed in Appendix A) NIH-Style Biosketch (or CV). If you have more than five additional key personnel, please email additional files to{' '}
							<a 
								href='mailto:irbadmin@geneticalliance.org'
								className='field-title'
							>
								irbadmin@geneticalliance.org
							</a>
						</p>
						<input
							className='p-compact inner-border-form'
							type='file'
							id='otherKeyBiosketch1'
							accept='application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
							onChange={e => {
								setOtherKeyBiosketchFile1(e.target.files[0])
								setUploadOtherKeyBiosketchSizeError1(false)
							}}
						/>
					</Form.Field>
					{uploadOtherKeyBiosketchSizeError1 && (
						<p className='error'>
							Key Personnel NIH-Style Biosketch (or CV) file size must be less
							than 5 MB.
						</p>
					)}
					<Form.Field>
						<input
							className='p-compact inner-border-form'
							type='file'
							id='otherKeyBiosketch2'
							accept='application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
							onChange={e => {
								setOtherKeyBiosketchFile2(e.target.files[0])
								setUploadOtherKeyBiosketchSizeError2(false)
							}}
						/>
					</Form.Field>
					{uploadOtherKeyBiosketchSizeError2 && (
						<p className='error'>
							Key Personnel NIH-Style Biosketch (or CV) file size must be less than 5 MB.
						</p>
					)}
					<Form.Field>
						<input
							className='p-compact inner-border-form'
							type='file'
							id='otherKeyBiosketch3'
							accept='application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
							onChange={e => {
								setOtherKeyBiosketchFile3(e.target.files[0])
								setUploadOtherKeyBiosketchSizeError3(false)
							}}
						/>
					</Form.Field>
					{uploadOtherKeyBiosketchSizeError3 && (
						<p className='error'>
							Key Personnel NIH-Style Biosketch (or CV) file size must be less than 5 MB.
						</p>
					)}
					<Form.Field>
						<input
							className='p-compact inner-border-form'
							type='file'
							id='otherKeyBiosketch4'
							accept='application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
							onChange={e => {
								setOtherKeyBiosketchFile4(e.target.files[0])
								setUploadOtherKeyBiosketchSizeError4(false)
							}}
						/>
					</Form.Field>
					{uploadOtherKeyBiosketchSizeError4 && (
						<p className='error'>
							Key Personnel NIH-Style Biosketch (or CV) file size must be less than 5 MB.
						</p>
					)}
					<Form.Field>
						<input
							className='p-compact inner-border-form'
							type='file'
							id='otherKeyBiosketch5'
							accept='application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
							onChange={e => {
								setOtherKeyBiosketchFile5(e.target.files[0])
								setUploadOtherKeyBiosketchSizeError5(false)
							}}
						/>
					</Form.Field>
					{uploadOtherKeyBiosketchSizeError5 && (
						<p className='error'>
							Key Personnel NIH-Style Biosketch (or CV) file size must be less than 5 MB.
						</p>
					)}
					<Form.Field className='mt-40'>
						<p className='field-title'>
							Upload all Key Personnel (persons listed in Appendix A) Human Research Protection Training Certificate (see page 4 for more details). If you have more than five additional key personnel, please email additional files to{' '}
							<a
								href='mailto:irbadmin@geneticalliance.org'
								className='field-title'
							>
								irbadmin@geneticalliance.org
							</a>
						</p>
						<input
							className='p-compact inner-border-form'
							type='file'
							id='otherKeyTrainingCertificate1'
							accept='application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
							onChange={e => {
								setOtherKeyTrainingCertificateFile1(e.target.files[0])
								setUploadOtherKeyTrainingCertificateSizeError1(false)
							}}
						/>
					</Form.Field>
					{uploadOtherKeyTrainingCertificateSizeError1 && (
						<p className='error'>
							Key Personnel Human Research Protection Training Certificate file size must be less than 5 MB.
						</p>
					)}
					<Form.Field>
						<input
							className='p-compact inner-border-form'
							type='file'
							id='otherKeyTrainingCertificate2'
							accept='application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
							onChange={e => {
								setOtherKeyTrainingCertificateFile2(e.target.files[0])
								setUploadOtherKeyTrainingCertificateSizeError2(false)
							}}
						/>
					</Form.Field>
					{uploadOtherKeyTrainingCertificateSizeError2 && (
						<p className='error'>
							Key Personnel Human Research Protection Training Certificate file size must be less than 5 MB.
						</p>
					)}
					<Form.Field>
						<input
							className='p-compact inner-border-form'
							type='file'
							id='otherKeyTrainingCertificate3'
							accept='application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
							onChange={e => {
								setOtherKeyTrainingCertificateFile3(e.target.files[0])
								setUploadOtherKeyTrainingCertificateSizeError3(false)
							}}
						/>
					</Form.Field>
					{uploadOtherKeyTrainingCertificateSizeError3 && (
						<p className='error'>
							Key Personnel Human Research Protection Training Certificate file size must be less than 5 MB.
						</p>
					)}
					<Form.Field>
						<input
							className='p-compact inner-border-form'
							type='file'
							id='otherKeyTrainingCertificate4'
							accept='application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
							onChange={e => {
								setOtherKeyTrainingCertificateFile4(e.target.files[0])
								setUploadOtherKeyTrainingCertificateSizeError4(false)
							}}
						/>
					</Form.Field>
					{uploadOtherKeyTrainingCertificateSizeError4 && (
						<p className='error'>
							Key Personnel Human Research Protection Training Certificate file size must be less than 5 MB.
						</p>
					)}
					<Form.Field>
						<input
							className='p-compact inner-border-form'
							type='file'
							id='otherKeyTrainingCertificate5'
							accept='application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
							onChange={e => {
								setOtherKeyTrainingCertificateFile5(e.target.files[0])
								setUploadOtherKeyTrainingCertificateSizeError5(false)
							}}
						/>
					</Form.Field>
					{uploadOtherKeyTrainingCertificateSizeError5 && (
						<p className='error'>
							Key Personnel Human Research Protection Training Certificate file size must be less than 5 MB.
						</p>
					)}
					<Form.Field className='mt-40'>
						<p className='field-title'>
							Upload Signature Page <span className='error-color'>*</span>
						</p>
						<input
							className='p-compact inner-border-form'
							type='file'
							required
							id='signature'
							accept='application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
							onChange={e => {
								setSignatureFile(e.target.files[0])
								setUploadSignatureError(false)
								setUploadSignatureSizeError(false)
							}}
						/>
					</Form.Field>
					<p className='p-small'>Offered in both Word and PDF versions</p>
					{uploadSignatureError && (
						<p className='error'>Signature Page is required.</p>
					)}
					{uploadSignatureSizeError && (
						<p className='error'>Signature Page file size must be less than 5 MB.</p>
					)}
				</Form>
				{missingFields && (
					<p className='error missing-field'>
						Please upload all required documents above.
					</p>
				)}
				{oversizeFiles && (
					<p className='error missing-field'>
						All file sizes must be less than 5 MB.
					</p>
				)}
				<div
					className='mt-40 mb-40'
					style={{ display: 'flex', justifyContent: 'space-between' }}>
					<>
						<Button className='button-orange-500 button-quarter-width' onClick={back}>
							<Image
								src='/icons/link-internal-white.png'
								className='icon-width-16px'
								style={{ transform: 'scaleX(-1)' }}
							/>
							&nbsp;&nbsp;Back
						</Button>
					</>
					{/* <Button className='button-neutral-100' onClick={save}>Save</Button> */}
					<Button
						className='button-orange-500 button-quarter-width'
						onClick={submit}>
						Submit&nbsp;&nbsp;
						<Image src='/icons/link-internal-white.png' className='icon-width-16px' />
					</Button>
				</div>
				<div className='mb-8'>
					<Progress percent={percentage} className='progress-bar center' />
				</div>
				<p className='p-small text-center'>
					Page {step} of {totalSteps}
				</p>
			</Grid.Column>}
			{sent && <p>We have received your application. Thank you for your interest in the IRB Application. </p>}
		</Grid>
	)
}

export default StepFourteen
