import { Grid, Image } from 'semantic-ui-react'
import ReactHtmlParser from 'react-html-parser'


function IHopeGeneticHealthFamiliesFillContent({ data }) {

	console.log('IHopeGeneticHealthFamiliesFillContent data', data)
	if (!data) {
		return null
	}
	const listDatas = []
	const datas = data.filter(item => item.link === '/ihope-genetic-health/families')
	if (datas && Array.isArray(datas) && datas.length > 0) {
		datas.sort((a, b) => a.index - b.index)
		datas.forEach((item, index) => {

			const buildContent = item.imagePosition === 'left' ?
				<Grid columns={2} stackable className='mb-80-60-40'>
					<Grid.Column mobile={16} tablet={16} computer={8}>
						<Image
							src={item.imageSrc}
							alt={item.imageAlt}
						/>
						{ReactHtmlParser(item.image)}
					</Grid.Column>
					<Grid.Column
						mobile={16}
						tablet={16}
						computer={8}
						className='display-flex flex-direction-column computer-align-items-end'>
						{ReactHtmlParser(item.content)}
					</Grid.Column>
				</Grid>
				:
				<Grid columns={2} stackable className='mb-80-60-40'>
					<Grid.Column
						mobile={16}
						tablet={16}
						computer={8}>
						{ReactHtmlParser(item.content)}
					</Grid.Column>
					<Grid.Column mobile={16} tablet={16} computer={8}>
						<Image
							src={item.imageSrc}
							alt={item.imageAlt}
						/>
						{ReactHtmlParser(item.image)}
					</Grid.Column>
				</Grid>
			if (datas.length > 1 && index === datas.length - 1) {
				listDatas.push(<hr className='rule-blue-300 mt-80-60-40 mb-80-60-40' />)
			}
			listDatas.push(buildContent);
		})
	}

	return (
		<>
			<div className='mb-80-60-40 div-grid-container'>
				<hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
				{listDatas}
			</div>
		</>
	)
}

export default IHopeGeneticHealthFamiliesFillContent
