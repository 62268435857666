import { Grid, Form } from 'semantic-ui-react'
import { useEffect, useState } from 'react'
import './IRBApplication.css'
import NextBackStep from './NextBackStep'

function StepEleven({
	step,
	setStep,
	totalSteps,
	updateFiles,
	updateFields,
	...data
}) {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	//for file upload error message
	const [uploadConsentDocumentError, setUploadConsentDocumentError] =
		useState(false)

	//for file upload
	const [consentDocumentFile, setConsentDocumentFile] = useState(null)

	//for uploaded file size error message
	const [uploadConsentDocumentSizeError, setUploadConsentDocumentSizeError] =
		useState(false)

	const next = () => {
		//if file is not uploaded, set error to true
		if (consentDocumentFile === null) {
			setUploadConsentDocumentError(true)
		}
		//if file is larger than 5MB, set error to true
		if (consentDocumentFile !== null && consentDocumentFile.size > 5242880) {
			setUploadConsentDocumentSizeError(true)
		}
		if (consentDocumentFile !== null && consentDocumentFile.size <= 5242880) {
			setStep(i => {
				return i + 1
			})
		}
	}

	return (
		<Grid centered>
			<Grid.Column computer={12}>
				<Form>
					<h3 className='mb-40'>Informed Consent</h3>
					<p>
						Please upload your consenting document. If you do not already have a
						consenting document that you have used in the past/intend to use, the
						following resources will assist you in developing your own (we highly
						recommend the ones with the <span className='error-color'>*</span>):
					</p>
					<ul>
						<li>
							<a
								href='https://www.genome.gov/about-genomics/policy-issues/Informed-Consent'
								target='_blank'
								rel='noreferrer'>
								General Informed Consent Background and Information
							</a>
						</li>
						<li>
							<a
								href='https://www.genome.gov/about-genomics/policy-issues/Informed-Consent/Required-Elements-of-Consent-Form'
								target='_blank'
								rel='noreferrer'>
								Required Elements of a Consent Form
							</a>
						</li>
						<li>
							<a
								href='https://irb.research.chop.edu/consent-templates'
								target='_blank'
								rel='noreferrer'>
								A Sample Consent Form derived from the Children's Hospital of
								Philadelphia
							</a>
						</li>
						<li>
							<a
								href='https://files.nccih.nih.gov/s3fs-public/CR-Toolbox/NCCIH_Informed_Consent_Template_07-17-2015.docx'
								target='_blank'
								rel='noreferrer'>
								NCCIH Informed Consent Template<span className='error-color'>*</span>
							</a>
						</li>
						<li>
							<a
								href='https://research.uci.edu/wp-content/uploads/informed-consent-biomedical.docx'
								target='_blank'
								rel='noreferrer'>
								UCI Consent Template: Biomedical<span className='error-color'>*</span>
							</a>
						</li>
					</ul>
					<Form.Field>
						<p className='field-title mt-40'>
							Upload Consent Document <span className='error-color'>*</span>
						</p>
						<input
							className='p-compact inner-border-form'
							type='file'
							required
							id='consentDocument'
							accept='application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
							onChange={e => {
								setConsentDocumentFile(e.target.files[0])
								setUploadConsentDocumentError(false)
								setUploadConsentDocumentSizeError(false)
							}}
						/>
					</Form.Field>
					{uploadConsentDocumentError && (
						<p className='error'>Consent Document is required.</p>
					)}
					{uploadConsentDocumentSizeError && (
						<p className='error'>
							Consent Document file size must be less than 5 MB.
						</p>
					)}
				</Form>
				<NextBackStep
					step={step}
					totalSteps={totalSteps}
					next={next}
					setStep={setStep}
					data={data}
					files={[consentDocumentFile]}
				/>
			</Grid.Column>
		</Grid>
	)
}

export default StepEleven
