import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Container, Grid, Tab, Menu } from 'semantic-ui-react'
import CrossConditionBurdenOfDiseaseIndividual from './CrossConditionBurdenOfDiseaseIndividual.jsx'
import CrossConditionBurdenOfDiseaseCommunity from './CrossConditionBurdenOfDiseaseCommunity.jsx'
import './CrossConditionBurdenOfDiseaseStudy.css'

function CrossConditionBurdenOfDiseaseStudy() {
	const navigate = useNavigate()
	const location = useLocation()
	const panes = [
		{
			menuItem: (
				<Menu.Item>
					<span style={{ display: 'flex' }}>
						<span>Individual Participation</span>
					</span>
				</Menu.Item>
			),
			render: () => (
				<Tab.Pane attached={false}>
					<CrossConditionBurdenOfDiseaseIndividual />
				</Tab.Pane>
			),
		},
		{
			menuItem: (
				<Menu.Item>
					<span style={{ display: 'flex' }}>
						<span>Community Participation</span>
					</span>
				</Menu.Item>
			),
			render: () => (
				<Tab.Pane attached={false}>
					<CrossConditionBurdenOfDiseaseCommunity />
				</Tab.Pane>
			),
		},
	]

	const [activeTab, setActiveTab] = useState(0)

	const onTabChange = event => {
		const tabURL = event.target.textContent.toLowerCase().replaceAll(' ', '-')
		navigate(`/studies/cross-condition-burden-of-disease/${tabURL}`)
	}

	useEffect(() => {
		if (
			location.pathname ===
			'/studies/cross-condition-burden-of-disease/individual-participation'
		) {
			setActiveTab(0)
		}
		if (
			location.pathname ===
			'/studies/cross-condition-burden-of-disease/community-participation'
		) {
			setActiveTab(1)
		}
	}, [location.pathname])
	return (
		<div>
			<Container className='container-main'>
				<Grid columns={2} stackable>
					<Grid.Column mobile={16} tablet={16} computer={8}>
						<h1>Cross Condition Burden of Disease Study</h1>
					</Grid.Column>
					<Grid.Column mobile={16} tablet={16} computer={8}>
						<p className='subtitle'>
							Genetic Alliance launched a burden of disease study by the people and for
							the people.
						</p>
					</Grid.Column>
				</Grid>
				{/* <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' /> */}
				<Tab
					menu={{ secondary: true, pointing: true }}
					panes={panes}
					onTabChange={onTabChange}
					className='tab-menu cross-condition-burden-of-disease-study mt-80-60-40'
					activeIndex={activeTab}
				/>
			</Container>
		</div>
	)
}

export default CrossConditionBurdenOfDiseaseStudy
