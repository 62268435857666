import {
	Container,
	Grid,
	GridColumn,
	Image
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'

function LessonsFromTheField() {
	return (
		<Container className='container-main'>
			<h1>Lessons From the Field Webinar Series</h1>
			<Grid columns={1} padded>
				<GridColumn>
					<p>
						In this series, advocacy leaders and other experts share their
						experiences: successes and failures, challenges and opportunities, and
						simply celebrating the good work that is happening all around us - in our
						field. Our hope is that you will gain information that you can leverage
						and it will catapult your own endeavors, accelerating your progress. Join
						us or listen to the recorded sessions. And if you have something to share,
						even a small success or learning, imagine how much that will help one of
						your peers: tell us about your good work{' '}
						<Link className='link-blue-800' to='/events/webinars'>here</Link> and we will schedule a webinar
						showcasing you and your organization, or expertise.
					</p>
					<hr className='mt-80 mb-20' />
					{/*<h2>Upcoming Webinars</h2>
					 <div>
						<Grid textAlign='left' columns={2} className='mt-15'>
							<Grid.Row>
								<Grid.Column>
									<Image
										src='/images/events/lessons-from-the-field/theresa.png'
										size='large'
										centered
									/>
								</Grid.Column>
								<Grid.Column>
									<p><b>Apr 14, 2022 | 2-2:30 PM ET</b></p>
									<p>
										This webinar will discuss the development of a registry-based, prospective natural history study for the rare disorder, Prader-Willi syndrome (PWS). Our patient advocacy group collaborated with an industry partner to develop the study, with the goal of gathering critical information to support the development of a new drug to treat PWS, and then took over all aspects of study execution when the company left the PWS drug development space.
									</p>
									<p>
										<b>Presenter</b>. Theresa Strong, PhD, is co-founder and Director of Research Programs at the Foundation for Prader-Willi Research (FPWR), a nonprofit that advances research for the rare neurodevelopmental disorder, Prader-Willi syndrome (PWS). Prior to joining FPWR full time, she had an academic career in genetics/cancer gene therapy and remains an Adjunct Professor of Genetics at the University of Alabama at Birmingham. She is the principal investigator for the Global PWS Registry and is active in patient advocacy. She has four children, including a young adult son with PWS.
									</p>
								</Grid.Column>
							</Grid.Row>
						</Grid>
						<div className='mt-40 text-center'>
							<Button className='orange-button'>
								<a
									href='https://us02web.zoom.us/meeting/register/tZMuf--grTMjGtKvseHVdIBJ_iQVC6c0uduB'
									target='_blank'
									rel='noreferrer'
									style={{ color: '#fff' }}>
									REGISTER &nbsp;&nbsp;
									<Icon name='arrow right' />
								</a>
							</Button>
						</div>
					</div> */}
					<div>
						<h2 className='mb-80'>Past Webinars</h2>
						<Grid columns={3} className='mt-15 center aligned'>
							<a
								href='https://youtu.be/ehC8JNnafuk'
								target='_blank'
								rel='noreferrer'
								className='mb-40'>
								<Image
									src='/images/events/lessons-from-the-field/theresa.png'
									size='medium'
									centered
								/>
							</a>
							<a
								href='https://youtu.be/Zs4ltY77g5k'
								target='_blank'
								rel='noreferrer'
								className='mb-40'>
								<Image
									src='/images/events/lessons-from-the-field/julie.png'
									size='medium'
									centered
								/>
							</a>
							<a
								href='https://youtu.be/BGCGwehtgYY'
								target='_blank'
								rel='noreferrer'
								className='mb-40'>
								<Image
									src='/images/events/lessons-from-the-field/beth.png'
									size='medium'
									centered
								/>
							</a>
							<a
								href='https://youtu.be/I3lTGDCl0Zk'
								target='_blank'
								rel='noreferrer'
								className='mb-40'>
								<Image
									src='/images/events/lessons-from-the-field/craig.png'
									size='medium'
									centered
								/>
							</a>
							<a
								href='https://youtu.be/EMPChJZfe3s'
								target='_blank'
								rel='noreferrer'
								className='mb-40'>
								<Image
									src='/images/events/lessons-from-the-field/jon.png'
									size='medium'
									centered
								/>
							</a>
							<a
								href='https://youtu.be/72in3vqNXis'
								target='_blank'
								rel='noreferrer'
								className='mb-40'>
								<Image
									src='/images/events/lessons-from-the-field/maria.png'
									size='medium'
									centered
								/>
							</a>
							<a
								href='https://youtu.be/2dY7mdRo40Q'
								target='_blank'
								rel='noreferrer'
								className='mb-40'>
								<Image
									src='/images/events/lessons-from-the-field/robin.png'
									size='medium'
									centered
								/>
							</a>
							<a
								href='https://youtu.be/2GM07w9o9K4'
								target='_blank'
								rel='noreferrer'
								className='mb-40'>
								<Image
									src='/images/events/lessons-from-the-field/brenna.png'
									size='medium'
									centered
								/>
							</a>
							<a
								href='https://youtu.be/WqLy48tuz9M'
								target='_blank'
								rel='noreferrer'
								className='mb-40'>
								<Image
									src='/images/events/lessons-from-the-field/stephanie.png'
									size='medium'
									centered
								/>
							</a>
							<a
								href='https://youtu.be/JtjlxN3BTmw'
								target='_blank'
								rel='noreferrer'
								className='mb-40'>
								<Image
									src='/images/events/lessons-from-the-field/betty.png'
									size='medium'
									centered
								/>
							</a>
							<a
								href='https://youtu.be/FOqJ8NejCb0'
								target='_blank'
								rel='noreferrer'
								className='mb-40'>
								<Image
									src='/images/events/lessons-from-the-field/judi.png'
									size='medium'
									centered
								/>
							</a>
							<a
								href='https://youtu.be/rlR21eKriuY'
								target='_blank'
								rel='noreferrer'
								className='mb-40'>
								<Image
									src='/images/events/lessons-from-the-field/shannah.png'
									size='medium'
									centered
								/>
							</a>
							<a
								href='https://youtu.be/DidVElJO9Z8'
								target='_blank'
								rel='noreferrer'
								className='mb-40'>
								<Image
									src='/images/events/lessons-from-the-field/amada.png'
									size='medium'
									centered
								/>
							</a>
						</Grid>
					</div>
				</GridColumn>
			</Grid>
		</Container>
	)
}

export default LessonsFromTheField
