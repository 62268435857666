import { Container, Grid, Image, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'


function NETSPathway() {
    return (
        <>
            <div>
                <Container className='container-main'>
                    <h1>NETS: Molecular Pathway</h1>
			        <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>What is disease pathophysiology and why is it important in drug development?</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>
                                A molecular pathway is a series of interactions that occurs between the molecules and proteins within a cell and between cells. Understanding the molecular pathways that contribute to or are affected by a disease plays a critical role in helping scientists select the best{' '} 
                                <Link to='/resources-and-information/nets/targets' rel='noopener noreferrer'>
                                    therapeutic targets
                                </Link>
                                .
                            </p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Testimonials: How we did it</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>Content not yet available for this section.</p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Relevant Publications</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <div className='inline-flex mb-15'>
                                <Image src='/icons/worksheet.png' className='icon-width-16px mr-8' style={{ height: '16px', top: '8px' }} />
                                <p>
                                    <a
                                        href='/pdf/nets/Developing_Predictive_Molecular_Maps.pdf'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Sage Bionetworks: Developing Predictive Molecular Maps of Human Disease through Community-Based Models
                                    </a>
                                </p>
                            </div>
                            <div className='inline-flex mb-15'>
                                <Image src='/icons/worksheet.png' className='icon-width-16px mr-8' style={{ height: '16px', top: '8px' }} />
                                <p>
                                    <a
                                        href='https://www.ncbi.nlm.nih.gov/pubmed/19741703'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Sage Bionetworks: Molecular Networks as Sensors and Drivers of Common Human Diseases [Paywall]
                                    </a>
                                </p>
                            </div>
                            <div className='inline-flex mb-15'>
                                <Image src='/icons/worksheet.png' className='icon-width-16px mr-8' style={{ height: '16px', top: '8px' }} />
                                <p>
                                    <a
                                        href='https://www.ncbi.nlm.nih.gov/pubmed/20407459'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Sage Bionetworks: The Need for Precompetitive Integrative Bionetwork Disease Model Building [Paywall]
                                    </a>
                                </p>
                            </div>
                            <div className='inline-flex'>
                                <Image src='/icons/worksheet.png' className='icon-width-16px mr-8' style={{ height: '16px', top: '8px' }} />
                                <p>
                                    <a
                                        href='https://www.ncbi.nlm.nih.gov/pubmed/19337271'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Sage Bionetworks: A Network View of Disease and Compound Screening [Paywall]
                                    </a>
                                </p>
                            </div>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Related Tools</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <a
                                href='https://humancyc.org/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />Biocyc Database Collection: Humancyc</Button>
                            </a>
                            <a
                                href='http://www.genome.jp/kegg/pathway.html'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />Kyoto Encyclopedia of Genes and Genomes: KEGG Pathway Database</Button>
                            </a>
                            <a
                                href='https://www.drugbank.ca/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />The Metabolomics Innovation Centre: Drugbank</Button>
                            </a>
                            <a
                                href='https://www.ncbi.nlm.nih.gov/pubmed/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />NCBI: PubMed</Button>
                            </a>
                            <a
                                href='https://www.reactome.org/?page_id=1266'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />Reactome</Button>
                            </a>
                            <a
                                href='http://smpdb.ca/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />University of Alberta: Small Molecule Pathway Database</Button>
                            </a>
                            <a
                                href='https://www.wikipathways.org/index.php/WikiPathways'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />WikiPathways</Button>
                            </a>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Link to='/resources-and-information/nets/full-view' rel='noopener noreferrer' className='link-button inline-block'><Button className='button-orange-500'><Image src='/icons/link-internal-white.png' className='icon-width-16px' style={{ transform: 'scaleX(-1)' }} />Back to NETS Map full view</Button></Link>
                </Container>
            </div>
        </>
    )
}

export default NETSPathway