import {
	Container,
	Grid,
	GridColumn,
	Form,
	TextArea,
	Input,
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import emailjs from 'emailjs-com'

function ContactUs() {
	const sendEmail = e => {
		e.preventDefault()
		emailjs
			.sendForm(
				'service_715mwtp',
				'template_33yqjwc',
				e.target,
				'user_RvBcfNskpg2k1v812rots'
			)
			.then(
				result => {
					console.log(result.text)
				},
				error => {
					console.log(error.text)
				}
			)
		e.target.reset()
	}
	return (
		<>
			<Container className='container-main'>
				<h1>Contact Us</h1>
				<Grid columns={2} stackable padded>
					<GridColumn>
						<h3>Genetic Alliance, Inc.</h3>
						<p>
							Telephone: 202.966.5557
							<br /> Fax: 202.966.8553
							<br />
							Email:{' '}
							<a className='link-blue-800' href='mailto: info@geneticalliance.org'>Info@geneticalliance.org</a>
							<br />
							Hours: 9am - 5pm ET
						</p>
						<h3>Mailing Address</h3>
						<p>
							Genetic Alliance, Inc.
							<br />
							26400 Woodfield Road #189
							<br />
							Damascus, MD 20872
						</p>
						<Link to='/about/staff' className='link-blue-800'>Genetic Alliance Staff List</Link>
					</GridColumn>
					<GridColumn>
						<h3>Contact Form</h3>
						<Form onSubmit={sendEmail}>
							<Form.Group widths='equal'>
								<Form.Field
									required
									id='form-input-control-first-name'
									control={Input}
									label='Full Name'
									placeholder='Full Name'
									name='full_name'
								/>
								<Form.Field
									required
									id='form-input-control-email'
									control={Input}
									label='Email'
									placeholder='Email Address'
									name='email_address'
								/>
								<Form.Field
									required
									id='form-input-control-subject'
									control={Input}
									label='Subject'
									placeholder='Subject'
									name='subject'
								/>
							</Form.Group>
							<Form.Group>
								<Form.Field required width={14}>
									<label>Message</label>
									<TextArea name='message' rows='6' />
								</Form.Field>
							</Form.Group>
							<Form.Field>
								<div
									className='g-recaptcha'
									data-sitekey='6LdVkTgdAAAAAM9nHQfZXPpf-xwrofJT7-JVOLQu'></div>
							</Form.Field>
							<Form.Button type='submit' className='mt-15'>
								Send Message
							</Form.Button>
						</Form>
					</GridColumn>
				</Grid>
			</Container>
		</>
	)
}

export default ContactUs
