import { Container, Grid, GridColumn } from 'semantic-ui-react'
import ReactHtmlParser from 'react-html-parser'

function PressReleasesFillContent({ data }) {
	console.log('PressReleasesFillContent data: ', data);
	
	if (!data || !Array.isArray(data)) return null;

	const datas = data.filter((item) => item.link === '/about/press-releases');

	const buildContent = [];
	if (datas && Array.isArray(datas)) {
		datas.sort((a, b) => b.date - a.date);

		datas.forEach((item) => (
			buildContent.push(
				<p id={item.id} key={item.id}>
					{item.dateStr} - &nbsp;
					<a className='link-blue-800'
						href={item.href}
						target='_blank'
						rel='noreferrer'>
						{ReactHtmlParser(item.content)}
					</a>
					{ReactHtmlParser(item.content1)}
				</p>
			)
		))
	}

	return (
		<>
			<Container className='container-main'>
				<h1>Press Releases</h1>
				<Grid columns={1} padded>
					<GridColumn>

						{buildContent}

						<p>
							<strong>
								<em>
									<a className='link-blue-800' href='http://www.geneticalliance.org/about/prarchive'>
										[2014-1996 Press Release Archives]
									</a>
								</em>
							</strong>
						</p>
					</GridColumn>
				</Grid>
			</Container>
		</>
	)
}

export default PressReleasesFillContent
