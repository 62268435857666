import { Container, Grid, Image, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'


function NETSAnimalModels() {
    return (
        <>
            <div>
                <Container className='container-main'>
                    <h1>NETS: Animal Models</h1>
			        <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>What are animal models and why are they important in drug development?</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>
                                Animal models are organisms, often mice or rats, that have been engineered to reproduce the physical or molecular changes that occur during the course of a disease in humans. These models are used to study the biology of the disease, including the genetics and the cellular or{' '} 
                                <Link to='/resources-and-information/nets/pathway' rel='noopener noreferrer'>
                                    molecular pathways 
                                </Link>
                                {' '}involved in the disease. Animal models also have a critical role in developing and testing new therapies before they are tested in humans.
                            </p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Testimonials: How we did it</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>Content not yet available for this section.</p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Relevant Publications</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>Content not yet available for this section.</p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Related Tools</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <a
                                href='http://www.informatics.jax.org/mgihome/projects/aboutmgi.shtml'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />The Jackson Laboratory: Mouse Genome Informatics</Button>
                            </a>
                            {/* <a
                                href='https://www.nih.gov/science/models/mouse/deltagenlexicon/index.html'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />NIH: Deltagen and Lexicon Knockout Mice and Phenotypic Data</Button>
                            </a> */}
                            <a
                                href='https://www.mmrrc.org/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/toolkit.png' className='icon-width-16px' />NIH: Mutant Mouse Regional Resource Center</Button>
                            </a>
                            {/* <a
                                href='https://dtp.cancer.gov/branches/btb/services.html'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/toolkit.png' className='icon-width-16px' />NIH NCI: Developmental Therapeutics Program Biological Testing Branch</Button>
                            </a> */}
                            {/* <a
                                href='http://www.niaid.nih.gov/about/organization/dait/pages/geneknockout.aspx'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/toolkit.png' className='icon-width-16px' />NIH NIAID: Gene Knockout / Transgenic Mice Exchange Program</Button>
                            </a> */}
                            <a
                                href='https://www.komp.org/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/toolkit.png' className='icon-width-16px' />UC Davis: Knockout Mouse Project Repository</Button>
                            </a>
                            {/* <a
                                href='https://www.als.net/Media/8/PodcastDetails/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/multimedia.png' className='icon-width-16px' />ALS Therapy Development Institute: Screening Potential Therapeutics</Button>
                            </a> */}
                            {/* <a
                                href='http://modelorganisms.nih.gov/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/guide.png' className='icon-width-16px' />NIH: Model Organisms for Biomedical Research</Button>
                            </a> */}
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Link to='/resources-and-information/nets/full-view' rel='noopener noreferrer' className='link-button inline-block'><Button className='button-orange-500'><Image src='/icons/link-internal-white.png' className='icon-width-16px' style={{ transform: 'scaleX(-1)' }} />Back to NETS Map full view</Button></Link>
                </Container>
            </div>
        </>
    )
}

export default NETSAnimalModels