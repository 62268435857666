import { Container, Grid, Image, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'


function NETSRecruitment() {
    return (
        <>
            <div>
                <Container className='container-main'>
                    <h1>NETS: Recruitment</h1>
			        <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>What is recruitment and why is it important in drug development?</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>
                                Recruitment is the identification and enrollment of participants for a study. Typically, participants must fit certain criteria in order to be recruited for a particular study. During the recruitment process potential participants will go through the process of{' '} 
                                <Link to='/resources-and-information/nets/consent'>
                                    informed consent
                                </Link>
                                , in which they learn more about the study and their rights as study participants. Investigators can recruit within specific communities or the general population through outreach and advertisement.
                            </p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Testimonials: How we did it</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>Content not yet available for this section.</p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Relevant Publications</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8} style={{ display: 'flex' }}>
                            <p>Content not yet available for this section.</p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Related Tools</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            {/* <a
                                href='http://www.trialsfinder.org/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/service.png' className='icon-width-16px' />TrialsFinder</Button>
                            </a> */}
                            {/* <a
                                href='https://www.recruitsource.com/Pages/Home.aspx'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/service.png' className='icon-width-16px' />RecruitSource</Button>
                            </a> */}
                            {/* <a
                                href='http://www.bumc.bu.edu/crro/recruitment/resources/letters/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/toolkit.png' className='icon-width-16px' />Boston University: Recruitment Letter Templates</Button>
                            </a> */}
                            <a
                                href='https://www.fda.gov/RegulatoryInformation/Guidances/ucm126428.htm'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/guide.png' className='icon-width-16px' />FDA: Recruiting Study Subjects - Information Sheet: Guidance for IRBs and Clinical Investigators</Button>
                            </a>
                            <a
                                href='https://www.youtube.com/watch?v=Z8JIRRIiQHE&feature=youtu.be'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25'
                            >
                                <Button className='button-white'><Image src='/icons/multimedia.png' className='icon-width-16px' />Genetic Alliance: The Importance of Participation - Rethinking Clinical Trials</Button>
                            </a>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Link to='/resources-and-information/nets/full-view' rel='noopener noreferrer' className='link-button inline-block'><Button className='button-orange-500'><Image src='/icons/link-internal-white.png' className='icon-width-16px' style={{ transform: 'scaleX(-1)' }} />Back to NETS Map full view</Button></Link>
                </Container>
            </div>
        </>
    )
}

export default NETSRecruitment