import { Container, Tab, Menu } from 'semantic-ui-react'
import { useNavigate, useLocation } from 'react-router-dom'
import OverviewIRB from './OverviewIRBFillContent'
import IRBMembers from './IRBMembersFillContent'
import IRBApplicationProcess from './IRBApplicationProcessFillContent'
import './IRB.css'
import { useEffect, useState } from 'react'
import { progressIndicator } from '../../content/HelperFunction'

function GAInstitutionalReviewBoardFillContent({ data }) {
	const navigate = useNavigate()
	const location = useLocation()
	const panes = [
		{
			menuItem: (
				<Menu.Item>
					<span style={{ display: 'flex' }}>
						<span>Overview</span>
					</span>
				</Menu.Item>
			),
			render: () => (
				<Tab.Pane attached={false}>
					<OverviewIRB data={data} />
				</Tab.Pane>
			),
		},
		{
			menuItem: (
				<Menu.Item>
					<span style={{ display: 'flex' }}>
						<span>IRB Members</span>
					</span>
				</Menu.Item>
			),
			render: () => (
				<Tab.Pane attached={false}>
					<IRBMembers data={data} />
				</Tab.Pane>
			),
		},
		{
			menuItem: (
				<Menu.Item>
					<span style={{ display: 'flex' }}>
						<span>Application Process</span>
					</span>
				</Menu.Item>
			),
			render: () => (
				<Tab.Pane attached={false}>
					<IRBApplicationProcess data={data} />
				</Tab.Pane>
			),
		},
	]

	const [activeTab, setActiveTab] = useState(0)

	const onTabChange = event => {
		const tabURL = event.target.textContent.toLowerCase().replaceAll(' ', '-')
		navigate(`/irb/${tabURL}`)
	}

	useEffect(() => {
		if (location.pathname === '/irb/overview') {
			setActiveTab(0)
		}
		if (location.pathname === '/irb/irb-members') {
			setActiveTab(1)
		}
		if (location.pathname === '/irb/application-process') {
			setActiveTab(2)
		}
	}, [location.pathname])

	const { pathname, hash, key } = useLocation()
	useEffect(() => {
		// if not a hash link, scroll to top
		if (hash === '') {
			window.scrollTo(0, 0)
		}
		// else scroll to id
		else {
			setTimeout(() => {
				const id = hash.replace('#', '')
				const element = document.getElementById(id)
				if (element) {
					element.scrollIntoView({ behavior: 'smooth' })
				}
			}, 0)
		}
	}, [pathname, hash, key])

	useEffect(() => {
		progressIndicator()
	}, [])

	if (!data) {
		return null
	}
	const datas = data.filter(item => item.link === '/irb')
	if (datas && Array.isArray(datas) && datas.length > 0) {
		datas.sort((a, b) => a.index - b.index)
	} else {
		return null
	}

	return (
		<>
			<Container className='container-main'>
				<h1 className='mb-90-60-40'>{datas[0].header}</h1>
				<Tab
					menu={{ secondary: true, pointing: true }}
					panes={panes}
					onTabChange={onTabChange}
					className='tab-borderless irb'
					activeIndex={activeTab}
				/>
			</Container>
		</>
	)
}

export default GAInstitutionalReviewBoardFillContent
