import { Container, Grid, GridColumn, Image, Icon} from 'semantic-ui-react'
import ShareSocialMedia from '../../../ShareSocialMedia'
import LinkButton from '../../../LinkButton'

function AccessibleCommunities() {
	const shareUrl = 'https://geneticalliance.org/resources-and-information/advocacy-atlas/accessible-communities'
	return (
		<Container className='container-main'>
			<h1>Accessible Communities</h1>
			<Grid columns={1} padded>
				<GridColumn>
					<hr />
					<ShareSocialMedia shareUrl={shareUrl} />
					<div>
						<p className='mt-20'>
							<i>I first got involved in advocacy when [my son] Michael’s school refused to install a wheelchair lift. I visited a building with a platform lift that folded at the top of a landing when not in use. I spoke to contractors, got estimates, and figured out an ideal spot for it. Even though it required a long legal battle, we finally got a lift installed. I know that we made a difference in the community because, today, all sorts of students with injuries and disabilities use the lift. We also were able to start a new policy in the school system so that no student could be discriminated against because of his/her disability.
							<br /><br />
							-Story from a parent advocate</i>
						</p>
					</div>

					<h2 className='color-secondary mt-40'>Resources</h2>
					<Grid stackable columns={2} className='mt-15 left aligned'>
						<Grid.Row>
							<Grid.Column>
								<p>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/accessible-communities/together_move_mountains.pdf'
										target='_blank'>										
                                        Together, We Can Move Mountains: Parents as Leaders in Improving Systems for Children and Families
									</a>
									- Statewide Parent Advocacy Network (SPAN)
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='https://pdfslide.us/documents/finding-home-an-introduction-to-government-other-kinds-of-housing-assistance-a-presentation-by-the-national-youth-leadership-network-youth-to-youth.html'
										target='_blank'
                                        rel='noreferrer'>
										Finding A Home: An Introduction to Government & Other Kinds of Housing Assistance
									</a>
									- National Youth Leadership Network
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/accessible-communities/fha_checklist_single_page.pdf'
										target='_blank'>
										Fair Housing Act Checklist - A Guide to Accessible Design and Construction Compliance
									</a>
									- Equal Rights Center 
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/accessible-communities/accessible_healthcare_toolkit.pdf'
										target='_blank'>
										Accessible Health Care Self Advocacy Toolkit
									</a>
									- Equal Rights Center 
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/accessible-communities/assistance_animal_users_toolkit.pdf'
										target='_blank'>
										Assistance Animal Users' Public Accommodations and Fair Housing Toolkit
									</a>
									- Equal Rights Center 
								</p>
								<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/accessible-communities/veterans_toolkit.pdf'
										target='_blank'>
										Veterans with Disabilities
									</a>
									- Equal Rights Center 
								</p>
							</Grid.Column>
							<Grid.Column>
								<div>
									<figure>
										<Image
											src='/images/resources-and-information/advocacy-atlas/accessible-communities/jeremyhorse.jpeg'
											size='medium'
											centered
										/>
										<figcaption className='mt-20 text-center'>
											<i>Jeremy learning to ride a pony at a community event.</i>
										</figcaption>
									</figure>
								</div>
							</Grid.Column>
						</Grid.Row>
					</Grid>
					<div>
					<p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/accessible-communities/interior-design-and-the-fair-housing-act.pdf'
										target='_blank'>
										Interior Design and the Fair Housing Act 
									</a>
									- ENGLISH- Equal Rights Center
								</p>
                                <p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/accessible-communities/diseno-de-interiores-y-la-ley-de-vivienda-justa.pdf'
										target='_blank'>
										Interior Design and the Fair Housing Act 
									</a>
									- SPANISH- Equal Rights Center
								</p>
                                <p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/accessible-communities/interior-design-and-the-fair-housing-act-amharic.pdf'
										target='_blank'>
										Interior Design and the Fair Housing Act 
									</a>
									- AMHARIC- Equal Rights Center
								</p>
                                <p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/accessible-communities/accessible_healthcare_toolkit.pdf'
										target='_blank'>
										Reasonable Accommodations and Modifications: An Accessibility Self-Advocacy Toolkit
									</a>
									- Equal Rights Center 
								</p>
                                <p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/accessible-communities/NADTC-Accessible-Pathways-Livable-Communities-Pocket-Guide.pdf'
										target='_blank'>
										Accessible Pathways & Livable Communities Pocket Guide
									</a>
									- National Aging and Disability Transportation Center
								</p>
                                <p className='mt-25'>
									<a className='link-blue-800'
										href='https://equalrightscenter.org/news-posts/public-accommodations-self-advocacy-toolkit/'
										target='_blank'
                                        rel='noreferrer'>
										Public Accommodations Self-Advocacy Toolkit
									</a>
									- Equal Rights Center 
								</p>
                                <p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/accessible-communities/ADA-6-Action-Guide-Private-Post-Secondary1.pdf'
										target='_blank'>
										Action Guide for Students in Public Postsecondary Schools
									</a>
									- Arizona Center for Disability Law
								</p>
                                <p className='mt-25'>
									<a className='link-blue-800'
										href='https://www2.ed.gov/about/offices/list/ocr/docs/howto.html'
										target='_blank'
                                        rel='noreferrer'>
										How to File A Discrimination Complaint with the Office for Civil Rights Form
									</a>
									- US Department of Justice
								</p>
                                <p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/accessible-communities/NADTC-Toolkit-for-the-Assessment-of-Bus-Stop-Accessibility.pdf'
										target='_blank'>
										Toolkit for the Assessment of Bus Stop Accessibility and Safety
									</a>
									- Easter Seals Project Action
								</p>
                                <p className='mt-25'>
									<a className='link-blue-800'
										href='https://dredf.org/news/publications/ada-paratransit-eligibility/'
										target='_blank'
                                        rel='noreferrer'>
										ADA Paratransit Eligibility: How to Make Your Case
									</a>
									- Disability Rights Education and Defense Fund (DREDF)
								</p>
                                <p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/accessible-communities/Transportation- ride bus. 09.pdf'
										target='_blank'>
										How to Ride the Bus
									</a>
									- Kids As Self Advocates
								</p>
                                <p className='mt-25'>
									<a className='link-blue-800'
										href='https://www.cdc.gov/ncbddd/disabilityandhealth/materials/factsheets/fs-communicating-with-people.html'
										target='_blank'
                                        rel='noreferrer'>
										Communicating With and About People with Disabilities
									</a>
									- Centers for Disease Control and Prevention
								</p>
                                <p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/accessible-communities/Self-Advocacy_and_Assistive_Technology-Pat_Rodriguez_8-14.pdf'
										target='_blank'>
										Assistive Technology: A Self-Advocacy Guide
									</a>
									- Arizona Center for Disability Law
								</p>
                                <p className='mt-25'>
									<a className='link-blue-800'
										href='https://www.nchpad.org/discover/index.html'
										target='_blank'
                                        rel='noreferrer'>
										Discover Camp
									</a>
									- National Center on Health, Physical Activity, and Disability (NCHPAD), National Center on Accessibility  
								</p>
                                <p className='mt-25'>
									<a className='link-blue-800'
										href='https://www.acacamps.org/campers-families/planning-camp/preparing-camp/fun-safety-aca-accredited-camps-set-standard'
										target='_blank'
                                        rel='noreferrer'>
										ACA Accredited Camps
									</a>
									- American Camp Association
								</p>
                                <p className='mt-25'>
									<a className='link-blue-800'
										href='https://www.acacamps.org/campers-families'
										target='_blank'
                                        rel='noreferrer'>
										Camp in Your Child’s Future: The Year Ahead
									</a>
									- American Camp Association
								</p>
                                <p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/accessible-communities/span_grassrootsadvocacy.pdf'
										target='_blank'>
										Public Policy Advocacy: A Grassroots Guide Statewide Parent Advocacy Network
									</a>
									- Statewide Parent Advocacy Network (SPAN)
								</p>
                                <p className='mt-25'>
									<a className='link-blue-800'
										href='/pdf/advocacy-atlas/accessible-communities/immigrant_families.pdf'
										target='_blank'>
										The Role of Family Organizations in Reaching & Supporting Immigrant Families to Access Services
									</a>
									- Family to Family Health Information Center, Statewide Parent Advocacy Network of New Jersey (SPAN)
								</p>
					</div>
					<div className='mt-40 text-center'>
						<LinkButton className='ui button' 
						to='/resources-and-information/advocacy-atlas'
						>
							<Icon name='arrow left' /> Return to Advocacy ATLAS Homepage
						</LinkButton>
					</div>
				</GridColumn>
			</Grid>
		</Container>
	)
}

export default AccessibleCommunities