import { Container, Grid } from 'semantic-ui-react'
import ReactHtmlParser from 'react-html-parser'

function HealthDisclaimerFillContent({ data }) {
	if (!data) {
		return null
	}
	const datas = data.filter(item => item.link === '/health-disclaimer')
	if (datas && Array.isArray(datas) && datas.length > 0) {
		datas.sort((a, b) => a.index - b.index)
	} else {
		return null
	}
	return (
		<Container className='container-main'>
			<h1>{datas[0].header}</h1>
			<Grid columns={1} padded>
				<Grid.Column>
					{ReactHtmlParser(datas[0].content)}
				</Grid.Column>
			</Grid>
		</Container>
	)
}

export default HealthDisclaimerFillContent
