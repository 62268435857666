import { Container, Grid, Image, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'


function NETSPhenotypes() {
    return (
        <>
            <div>
                <Container className='container-main'>
                    <h1>NETS: Phenotypes</h1>
			        <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>What are phenotypes?</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>
                                An individual’s phenotype refers to their observable traits, such as height or eye color. This is the physical expression of an individual’s genes, or{' '} 
                                <Link to='/resources-and-information/nets/genotypes' rel='noopener noreferrer'>
                                    genotype
                                </Link>
                                . DNA is packaged into 23 chromosomes, and every person has two copies of each chromosome, with one copy inherited from each parent (46 total chromosomes). Each chromosome contains certain genes that code for specific molecular processes and traits, and different versions of the same gene are called alleles. Combinations of these alleles create genotypes, which translate to phenotypes. In addition to genetic factors, a person’s phenotype is also influenced by environmental factors.
                            </p>
                        </Grid.Column>
                    </Grid>
                    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Why are phenotypes important in drug development?</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>
                                Phenotypes have played a role in drug development for a long time. Researchers develop drugs and then monitor the observable, or phenotypical, changes as a response to the drug. If the drug produces positive phenotypical results, that means the drug is beneficial. 
                            </p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Testimonials: How we did it</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <p>Content not yet available for this section.</p>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Relevant Publications</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <div className='inline-flex mb-15'>
                                <Image src='/icons/worksheet.png' className='icon-width-16px mr-8' style={{ height: '16px', top: '8px' }} />
                                <p>
                                    <a
                                        href='https://www.ncbi.nlm.nih.gov/pubmed/18344981'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Sage Bionetworks: Genetics of Gene Expression and Its Effects on Disease
                                    </a>
                                </p>
                            </div>
                            <div className='inline-flex mb-15'>
                                <Image src='/icons/worksheet.png' className='icon-width-16px mr-8' style={{ height: '16px', top: '8px' }} />
                                <p>
                                    <a
                                        href='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2841396/'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Sage Bionetworks: An Integrative Genomics Approach to Infer Casual Associations between Gene Expression and Disease
                                    </a>
                                </p>
                            </div>
                            <div className='inline-flex mb-15'>
                                <Image src='/icons/worksheet.png' className='icon-width-16px mr-8' style={{ height: '16px', top: '8px' }} />
                                <p>
                                    <a
                                        href='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2841398/'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Sage Bionetworks: Variations in DNA Elucidate Molecular Networks that Cause Disease
                                    </a>
                                </p>
                            </div>
                            <div className='inline-flex'>
                                <Image src='/icons/worksheet.png' className='icon-width-16px mr-8' style={{ height: '16px', top: '8px' }} />
                                <p>
                                    <a
                                        href='https://www.ncbi.nlm.nih.gov/pubmed/20346437'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Sage Bionetworks: Integrating Pathway Analysis and Genetics of Gene Expression for Genome-Wide Association Studies
                                    </a>
                                </p>
                            </div>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <h3>Related Tools</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8}>
                            <a
                                href='https://www.cancer.gov/about-nci/organization/ccg/research/structural-genomics/tcga'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />NIH, NHGRI: The Cancer Genome Atlas Data Portal</Button>
                            </a>
                            <a
                                href='https://www.ncbi.nlm.nih.gov/gap'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />NCBI: dbGaP</Button>
                            </a>
                            <a
                                href='http://biosciencedbc.jp/moved-jsnp-biosciencedbc-jp'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />University of Tokyo and Japan Science and Technology Agency: Japanese SNP Database</Button>
                            </a>
                            <a
                                href='https://repository.niddk.nih.gov/home/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />NIDDK: Data, Biosample, and Genetics Repository</Button>
                            </a>
                            <a
                                href='https://biolincc.nhlbi.nih.gov/home/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />Biological Specimen and Data Repository Coordinating Center</Button>
                            </a>
                            <a
                                href='http://www.informatics.jax.org/mgihome/projects/aboutmgi.shtml'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='link-button inline-block mr-25 mb-25'
                            >
                                <Button className='button-white'><Image src='/icons/database.png' className='icon-width-16px' />The Jackson Laboratory: Mouse Genome Informatics</Button>
                            </a>
                        </Grid.Column>
                    </Grid>
				    <hr className='rule-blue-300 mt-40 mb-40' />
                    <Link to='/resources-and-information/nets/full-view' rel='noopener noreferrer' className='link-button inline-block'><Button className='button-orange-500'><Image src='/icons/link-internal-white.png' className='icon-width-16px' style={{ transform: 'scaleX(-1)' }} />Back to NETS Map full view</Button></Link>
                </Container>
            </div>
        </>
    )
}

export default NETSPhenotypes