import React, { useState } from 'react'
import { Grid, Image, Button } from 'semantic-ui-react'
import ModalVideo from 'react-modal-video'
import '../../../node_modules/react-modal-video/scss/modal-video.scss'
// import { Link } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'

function IHopeGeneticHealthOverviewFillContent({ data }) {

	const [openVideo, setOpenVideo] = useState(null);

	const handleOpenVideo = (videoId) => {
		setOpenVideo(videoId);
	};

	const handleCloseVideo = () => {
		setOpenVideo(null);
	};

	if (!data) {
		return null
	}

	const buildContentBottom = []
	const buildVideosOnTop = []

	const videosOnTop = data.filter(
		item =>
			item.link === '/ihope-genetic-health/overview' && item.part === 'videoOnTop'
	)
	const middleDatas = data.find(item => item.link === '/ihope-genetic-health/overview' && item.part === 'middle')
	const otherVideos = data.filter(
		item =>
			item.link === '/ihope-genetic-health/overview' && item.part === 'bottom'
	)

	// video on top
	if (videosOnTop && Array.isArray(videosOnTop) && videosOnTop.length > 0) {
		videosOnTop.sort((a, b) => b.index - a.index)
		videosOnTop.forEach((item, index) => {
			buildVideosOnTop.push(
				<Grid.Row key={index}>
					<Grid.Column mobile={16} tablet={8} computer={8}>
						<ModalVideo
							channel='youtube'
							autoplay
							isOpen={openVideo === item.videoIndex}
							videoId={item.videoId}
							onClose={handleCloseVideo}
						/>
						<Image
							src='/images/ihope-genetic-health/natasha-julia-youtube-image.png'
							className='modal-video-mb-30 cursor-pointer'
							size='big'
							onClick={() => handleOpenVideo(item.videoIndex)}
						/>
					</Grid.Column>
					<Grid.Column mobile={16} tablet={8} computer={8}>
						<h3 className='mb-30'>
							“{item.header}”
						</h3>
						<div className='display-flex gap-20px mb-30'>
							<Image src='/images/ihope-genetic-health/portraitNatasha.png' className='portrait-64px' />
							<div className='display-flex wrap flex-direction-column space-around'>
								<p className='mb-0'>
									<b>Natasha Bonhomme</b>
								</p>
								<p>
									Chief Strategy Officer, Genetic Alliance
								</p>
							</div>
						</div>
						<div className='display-flex gap-20px'>
							<Image src='/images/ihope-genetic-health/portraitJulia.png' className='portrait-64px' />
							<div className='display-flex wrap flex-direction-column space-around'>
								<p className='mb-0'>
									<b>Julia Ortega</b>
								</p>
								<p>
									Board Member, Genetic Alliance
								</p>
							</div>
						</div>
					</Grid.Column>
				</Grid.Row>
			)
		})
	}

	// video on bottom
	if (otherVideos && Array.isArray(otherVideos) && otherVideos.length > 0) {
		otherVideos.sort((a, b) => a.index - b.index)
		otherVideos.forEach((item, index) => {
			buildContentBottom.push(
				<Grid.Column key={index} mobile={16} tablet={8} computer={4}>
					<ModalVideo
						channel="youtube"
						autoplay
						isOpen={openVideo === item.videoIndex}
						videoId={item.videoId}
						onClose={handleCloseVideo}
					/>
					<Image
						src={item.imageSrcYoutube}
						className="modal-video-mb-30 cursor-pointer"
						size="big"
						onClick={() => handleOpenVideo(item.videoIndex)}
					/>
					<p className='subtitle'>
						"
						{item.content
							? ReactHtmlParser(item.content)
							: null}
						"
					</p>
					<div className='display-flex gap-20px mb-30'>
						<Image
							src={item.imageSrc}
							className='portrait-64px'
						/>
						<div className='display-flex wrap flex-direction-column space-around'>
							<p className='mb-0 p-small'>
								<b>
									{item.name
										? ReactHtmlParser(item.name)
										: null}
								</b>
							</p>
							<p className='p-small'>
								{item.title
									? ReactHtmlParser(item.title)
									: null}
							</p>
						</div>
					</div>
				</Grid.Column>
			)
		})
	}

	return (
		<>
			<div>
				<hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' />
				<Grid columns={2}>
					{buildVideosOnTop}
				</Grid>
				<hr className='rule-blue-800 mt-80-60-40 mb-80-60-40' />
				<Grid columns={2} stackable>
					<Grid.Column mobile={16} tablet={16} computer={8}>
						<h3 className='mb-30'>{middleDatas && middleDatas.header}</h3>
						{middleDatas && middleDatas.content ? ReactHtmlParser(middleDatas.content) : null}
						<a
							href='https://www.paypal.com/donate/?hosted_button_id=SJ89ZFTJBHJ6J'
							target='_blank'
							rel='noreferrer'
							className='inline-block link-button'
						>
							<Button className='button-neutral-100' style={{ margin: '0' }}>
								Donate now
								<Image src='/icons/link-internal-blue.png' className='icon-width-16px' />
							</Button>
						</a>
					</Grid.Column>
					<Grid.Column mobile={16} tablet={16} computer={8}>
						<h3>01</h3>
						<p>Diagnose the undiagnosed</p>
						<hr className='rule-blue-300 mt-30 mb-30' />
						<h3>02</h3>
						<p>Alleviate suffering</p>
						<hr className='rule-blue-300 mt-30 mb-30' />
						<h3>03</h3>
						<p>Enable rare disease research</p>
					</Grid.Column>
				</Grid>
				<Grid className='mt-80-60-40'>
					<Grid.Row columns={4}>
						{buildContentBottom}
					</Grid.Row>
				</Grid>
			</div>
		</>
	)
}

export default IHopeGeneticHealthOverviewFillContent
