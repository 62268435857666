import React, { useState, useEffect } from 'react'
import { NavLink, Link } from 'react-router-dom'
import './Navbar.css'
import { Image, Container, Accordion, Button } from 'semantic-ui-react'
import { MenuData } from '../navigation/MenuItems'
import { MobileMenuData } from '../navigation/MenuItems'
import { NavDownIcon } from 'components/svg-icons'
import { MenuBars } from 'components/svg-icons'
import { useLocation } from 'react-router-dom'

function Navbar() {
	const [click, setClick] = useState(false)
	const [hideMenu, setHideMenu] = useState(false)
	const handleClick = () => setClick(!click)
	const location = useLocation()

	useEffect(() => {
		const timer = setTimeout(() => {
			setHideMenu(false)
		}, 500)
		return () => {
			clearTimeout(timer)
		}
	}, [hideMenu])

	useEffect(() => {
		if (location.pathname === '/wikiadvocacy') {
			window.open('https://wikiadvocacy.org/Main_Page', '_blank')
		}
		if (location.pathname === '/expecting-health') {
			window.open('https://expectinghealth.org/', '_blank')
		}
	}, [location.pathname])

	const renderMenuItems = data => {
		return data.map((item, index) =>
			item?.children && item?.children.length ? (
				<li key={index} className='nav-item'>
					<NavLink
						exact
						to={item.path}
						className='nav-links sans-menu'
						activeClassName='active'>
						{item.title} {NavDownIcon}
					</NavLink>
					{!hideMenu && (
						<ul onClick={() => setHideMenu(true)}>
							{renderMenuItems(item.children)}
						</ul>
					)}
				</li>
			) : (
				<li key={index} className='nav-item'>
					<NavLink
						to={item.path}
						exact
						className='nav-links nested-links sans-menu'
						activeClassName='active'>
						{item.title}
					</NavLink>
				</li>
			)
		)
	}

	const renderMobileMenuItems = data => {
		return data.map((item, index) =>
			item?.children && item?.children.length ? (
				<li key={index} className='nav-item mobile'>
					<NavLink
						exact
						to={item.path}
						className='nav-links p-compact-bold mobile'
						activeClassName='active'>
						{item.title}
					</NavLink>
					{!hideMenu && (
						<ul onClick={() => setHideMenu(true)}>
							{renderMenuItems(item.children)}
						</ul>
					)}
				</li>
			) : (
				<li key={index} className='nav-item mobile'>
					<NavLink
						to={item.path}
						exact
						className='nav-links nested-links p-compact mobile'
						activeClassName='active'
						onClick={handleClick}>
						{item.title}
					</NavLink>
				</li>
			)
		)
	}

	const [activeIndex, setActiveIndex] = useState(null)
	const [iconDropdown, setIconDropdown] = useState(false)

	const handleAccordion = (e, index) => {
		setActiveIndex(activeIndex === index.index ? -1 : index.index)
		setIconDropdown(!iconDropdown) /* change one at a time */
	}

	const renderMenuAccordion = data => {
		return data.map((item, index) =>
			item?.children && item?.children.length ? (
				<Accordion key={index}>
					<Accordion.Title
						style={{ color: '#fff' }}
						className='p-compact-bold white title-accordion'
						active={activeIndex === index}
						index={index}
						onClick={handleAccordion}>
						{item.title}
						<Image
							src='/icons/menu-up.png'
							style={{ width: '10px' }}
							className={
								activeIndex === index ? 'icon-dropdown active' : 'icon-dropdown'
							}
						/>
					</Accordion.Title>
					<Accordion.Content
						active={activeIndex === index}
						style={{ paddingBottom: '0' }}>
						{renderMobileMenuItems(item.children)}
					</Accordion.Content>
					<hr className='rule-blue-500' style={{ margin: '15px 15px' }} />
				</Accordion>
			) : (
				<li key={index} className='nav-item mobile'>
					<NavLink
						to={item.path}
						exact
						className='nav-links nested-links p-compact-bold white mobile'
						activeClassName='active'
						onClick={handleClick}>
						{item.title}
					</NavLink>
					<hr className='rule-blue-500' style={{ margin: '15px 15px' }} />
				</li>
			)
		)
	}

	return (
		<>
			<div className='navbar-border navbar-fixed'>
				<Container className='navbar-container container-header'>
					<div className='navbar navbar-padding-right'>
						<div>
							<Link to='/' rel='noopener noreferrer'>
								<Image
									src='/logos/header.png'
									className='header-logo header-logo-full'
								/>
								<Image
									src='/logos/genetic-alliance-stacked.png'
									className='header-logo-stacked'
								/>
							</Link>
						</div>
						<div className='menu-icon' onClick={handleClick}>
							{MenuBars}
						</div>
						<div className='multilevelMenu hideNav inline-flex '>
							<ul
								className={
									click ? 'nav-menu active main-navigation ' : 'nav-menu main-navigation'
								}>
								{renderMenuItems(MenuData)}
							</ul>
							<Link
								to='https://www.paypal.com/donate/?hosted_button_id=W4GSV8T89QKBY'
								target='_blank'
								className='link-button'
								style={{ paddingLeft: '15px' }}>
								<Button className='donate-home donate-footer button-blue-500 display-contents mt-40 center'>
									Donate
								</Button>
							</Link>
						</div>
						<div className='multilevelMenu showNav' style={{ paddingLeft: '15px' }}>
							<Link
								to='https://www.paypal.com/donate/?hosted_button_id=W4GSV8T89QKBY'
								target='_blank'
								className='link-button'>
								<Button className='donate-home donate-footer button-blue-500 display-contents mt-40 center'>
									Donate
								</Button>
							</Link>
							<ul
								className={
									click ? 'nav-menu active main-navigation' : 'nav-menu main-navigation'
								}
								style={{ display: 'inline-block' }}>
								<div className='menu-icon' onClick={handleClick}>
									<Image src='/icons/menu-exit.png' className='menu-exit' />
								</div>
								{renderMenuAccordion(MobileMenuData)}
							</ul>
						</div>
					</div>
				</Container>
			</div>
		</>
	)
}

export default Navbar
