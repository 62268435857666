import { Container, Grid, Image, Button } from 'semantic-ui-react'
import './Home.css'
import { Link } from 'react-router-dom'
import Carousel from './Carousel'
import OurPrograms from './OurPrograms'

function Home() {
	return (
		<>
			<div>
				<Container className='container-main'>
					<h4 className='orange-500'>Our Goal</h4>
					<hr className='rule-orange-500' />
					<Grid columns={2} stackable className='mt-15'>
						<Grid.Column>
							<h1 className='h1-super'>
								To transform health systems to respond to what people need most.
							</h1>
						</Grid.Column>
						<Grid.Column className='pt-32-0-0'>
							<p className='subtitle'>
								Genetic Alliance, a non-profit organization founded in 1986, is a leader
								in deploying high tech and high touch programs for individuals,
								families, and communities to transform health systems by being
								responsive to the real needs of people in their quest for health.
							</p>
						</Grid.Column>
					</Grid>
					<hr className='rule-blue-800 mt-120-60-40' />
					<Carousel />
					<OurPrograms />
					{/* <hr className='rule-blue-800 mt-120-60-40' /> */}
				</Container>
			</div>
			<div className='mt-80-60-40 mb-80-60-40' style={{ background: '#EDF0F2' }}>
				<Container className='container-colored-section studies-margin-left-right pt-80-60-40 pb-80-60-40'>
					<h1 className='mb-50 text-center'>Support for Communities</h1>
					<p className='subtitle mb-50 text-center bold'>
						Genetic Alliance helps organizations and communities cultivate a
						supportive environment and do the research their community needs.
					</p>
					<Grid columns={3} stackable>
						<Grid.Column
							style={{ width: '33.33%', padding: '25px' }}
							className='centered-grid'>
							<div className='mb-25'>
								<Image src='/images/home/services.png' />
							</div>
							<h4>Support for Your Staff and Community</h4>
							<p>Take time to cultivate, culture, support, and growth.</p>
						</Grid.Column>
						<Grid.Column
							style={{ width: '33.33%', padding: '25px' }}
							className='centered-grid'>
							<div className='mb-25'>
								<Image src='/images/home/services-8.png' />
							</div>
							<h4>IRB Protocol Writing Support</h4>
							<p>Create effective research protocols for your next IRB submission.</p>
						</Grid.Column>
						<Grid.Column
							style={{ width: '33.33%', padding: '25px' }}
							className='centered-grid'>
							<div className='mb-25'>
								<Image src='/images/home/services-6.png' style={{ width: '80px' }} />
							</div>
							<h4>Biobanking Support</h4>
							<p>Build a successful biobank for your community.</p>
						</Grid.Column>
					</Grid>
					<div className='computer-button-center centered-grid'>
						<Link
							to='/our-services'
							rel='noreferrer'
							className='inline-block link-button mt-40'>
							<Button className='button-neutral-orange-500' style={{ margin: '0' }}>
								Learn More
							</Button>
						</Link>
					</div>
				</Container>
			</div>
			<div>
				<Container className='container-general'>
					<Grid columns={2} stackable className='mt-15'>
						<Grid.Column>
							<h1 className='h1-mobile'>Our Tools</h1>
						</Grid.Column>
						<Grid.Row columns={4} className='mt-80-60-40 pb-80-40-10'>
							<Grid.Column mobile={16} tablet={8} computer={4}>
								<Link
									to='/disease-infosearch'
									rel='noopener noreferrer'
									className='link-button scrim-white-background'>
									<Image
										className='image-radius image-home scrim-white-effect'
										src='/images/home/DIS.png'
									/>
									<h4
										className='link-underline-none link-blue-800 home-tools-link'
										style={{ marginTop: '10px' }}>
										Disease InfoSearch
									</h4>
								</Link>
							</Grid.Column>
							<Grid.Column className='home-tools' mobile={16} tablet={8} computer={4}>
								<Link
									to='/resources-and-information/nets'
									rel='noopener noreferrer'
									className='link-button scrim-white-background'>
									<Image
										className='image-radius image-home scrim-white-effect'
										src='/images/resources-and-information/nets-program.png'
									/>
									<h4
										className='link-underline-none link-blue-800 home-tools-link'
										style={{ marginTop: '10px' }}>
										NETS
									</h4>
								</Link>
							</Grid.Column>
							<Grid.Column className='home-tools' mobile={16} tablet={8} computer={4}>
								<Link
									to='/wikiadvocacy'
									rel='noopener noreferrer'
									className='link-button scrim-white-background'>
									<Image
										className='image-radius image-home scrim-white-effect'
										src='/images/resources-and-information/wikiadvocacy-program.png'
									/>
									<h4
										className='link-underline-none link-blue-800 home-tools-link'
										style={{ marginTop: '10px' }}>
										WikiAdvocacy
									</h4>
								</Link>
							</Grid.Column>
							<Grid.Column mobile={16} tablet={8} computer={4}>
								<Link
									to='/resources-and-information/advocacy-atlas'
									rel='noopener noreferrer'
									className='link-button scrim-white-background'>
									<Image
										className='image-radius image-home scrim-white-effect'
										src='/images/resources-and-information/atlas-program.png'
									/>
									<h4
										className='link-underline-none link-blue-800 home-tools-link'
										style={{ marginTop: '10px' }}>
										Advocacy ATLAS
									</h4>
								</Link>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row className='mb-70-10-0'>
							<Grid.Column>
								<Link
									to='/resources-and-information'
									rel='noopener noreferrer'
									className='link-button scrim-white-background display-contents'>
									<Button className='button-neutral-100' style={{ margin: '0' }}>
										<p style={{ margin: '0 auto' }}>View all tools</p> &nbsp;&nbsp;
										<Image
											className='link-internal-icon'
											src='/icons/link-internal-blue.png'
										/>
									</Button>
								</Link>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Container>
			</div>
		</>
	)
}

export default Home
