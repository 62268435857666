import React from 'react'
import { Grid, Button } from 'semantic-ui-react'
import './CrossConditionBurdenOfDiseaseIndividual.css'

function CrossConditionBurdenOfDiseaseIndividual() {
    return (
        <>
            <hr className='rule-orange-500 mt-80-60-40 mb-80-60-40' /> 
            <div className='cross-condition-burden-of-disease-individual-mq1 mb-80-60-40'>
                <p className='subtitle' style={{ maxWidth: '740px' }}>
                    Who knows best about the burden a disease places on individuals and families? Who can best assess the impact of a treatment or intervention?
                </p>
                <p className='subtitle' style={{ maxWidth: '650px' }}>
                    The people who experience the disease know best.
                </p>
            </div>
            <hr className='rule-blue-800 mt-80-60-40 mb-80-60-40' />
            <p>
                You live daily with the effects of your disease on your life: including your relationships, work, and play. This is burden – large or small. It may even change over time.
            </p>
            <p>
                This study leverages a scientifically validated instrument (or survey) to help assess the burden your disease places on you. Your experiences will form the foundation of a rigorous data set that enables researchers to focus on finding ways to ease your burden and understand the unmet medical need you and others with your disease have.
            </p>
            <p>
                <strong>Share how your disease affects you.</strong>
            </p>
            <div className='text-center mt-40'>
                <a 
                href='https://id.lunadna.com/referrer/peer-group-cross-condition-burden-of-disease-study?studyName=burden-of-disease&utm_source=GA&utm_medium=website&utm_campaign=study_launch' 
                target='_blank'
                rel='noreferrer' 
                className='inline-block link-button'>
                <Button className='button-orange-500' style={{margin: '0'}}>
                    Join the Study Today
                </Button>
            </a>
            </div>
            <hr className='rule-blue-800 mt-80-60-40 mb-80-60-40' />
            <Grid columns={2}>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                    <h3>Who is eligible?</h3>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                    <p>
                        Every adult living with a disease is eligible to participate in this study.  
                    </p>
                </Grid.Column>
            </Grid>
            <hr className='rule-blue-300 mt-40 mb-40' />
            <Grid>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                    <h3>Participation is simple, safe, and secure</h3>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                    <p>
                        1. Create or log in to your account
                    </p>
                    <p>
                        2. Complete 3 survey requests.
                    </p>
                </Grid.Column>
            </Grid>
            <hr className='rule-blue-800 mt-80-60-40 mb-80-60-40' />
            <Grid columns={2}>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                    <h3>Your privacy is important to us</h3>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                    <p>
                        Genetic Alliance understands that your health information is private. The surveys are completely confidential - all data, records, and survey responses collected in the surveys will be de-identified and held to the highest security and{' '}
                        <a
                            href='https://support.lunadna.com/support/solutions/articles/43000040805-lunadna-privacy-policy'
                            target='_blank'
                            rel='noreferrer'>
                            privacy
                        </a>
                        {' '}standards. You own your data every step of the way: you can view, download or delete your profile at any time, and your data cannot be shared outside of the platform without your permission.   
                    </p>
                    <p>
                        The surveys are hosted on LunaDNA. LunaDNA is HIPAA (Health Insurance Portability and Accountability Act) and GDPR (General Data Protection Regulation ) compliant, following both U.S. and E.U. privacy regulations. The LunaDNA platform allows you to answer questionnaires with state-of-the-art privacy and security. Genetic Alliance will never see your name, email or other personal identifying information. All of our communication with you related to the <strong>Genetic Alliance Studies</strong> is through the LunaDNA platform.
                    </p>
                </Grid.Column>
            </Grid>
            <hr className='rule-blue-800 mt-80-60-40 mb-80-60-40' />
            <Grid columns={2}>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                    <h3>FAQs</h3>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                    <p><b>Who can see/access the information I enter?</b></p>
                    <p>
                        The Genetic Alliance Institutional Review Board approved this study. Researchers will only see your de-identified data (i.e., none of your personal information like email addresses or names will be exposed to researchers) when they perform data analysis.
                    </p>
                    <hr className='rule-blue-300 mt-40 mb-40' />
                    <p><b>How is My Information Protected?</b></p>
                    <p>
                        All information you share is kept confidential. Shared Data (e.g., DNA data, Health, Surveys, etc.) is de-identified in the Luna platform. Data is encrypted in transit (i.e., during upload and download by the member) and at rest (i.e., while stored). Personal Data (e.g., information that identifies you like your name, email address, etc.) is stored separately from Shared Data. Different types of Shared Data are stored separately to make re-combing the data much more challenging. Please see the{' '} 
                        <a
                            href='https://support.lunadna.com/support/solutions/articles/43000040805-lunadna-privacy-policy'
                            target='_blank'
                            rel='noreferrer'>
                            Luna Privacy Policy
                        </a>
                        {' '}for additional details.
                    </p>
                    <p>
                        You control your data at all times. You can delete some or all of it, delete your entire Luna account, or revoke your consent at any time.
                    </p>
                </Grid.Column>
            </Grid>
        </>
    )
}

export default CrossConditionBurdenOfDiseaseIndividual