import { Container, Grid, Image } from 'semantic-ui-react'
import { Link } from 'react-router-dom'


function AnnualReportsArchive() {
    return (
        <>
            <div>
                <Container className='container-main'>
                    <Grid columns={2}>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                            <h1>Annual Reports Archive</h1>
                        </Grid.Column>
                    </Grid>
					<hr className='rule-orange-500 mt-80-60-40 mb-65-55-25' />
					<h3 className='mb-40'>Genetic Alliance Annual Reports (2010-2007)</h3>
					<Grid columns={4} padded stackable>
						<Grid.Column>
							<a href='/pdf/annual-reports/2010_Annual_Report.pdf' target='_blank'>
								<Image src='/pdf/annual-reports/2010_Annual_Report.png' alt=''  size='medium' centered />
							</a>
						</Grid.Column>
						<Grid.Column>
							<a href='/pdf/annual-reports/2009_Annual_Report.pdf' target='_blank'>
								<Image src='/pdf/annual-reports/2009_Annual_Report.png' alt=''  size='medium' centered />
							</a>
						</Grid.Column>
						<Grid.Column>
							<a href='/pdf/annual-reports/2008_Annual_Report.pdf' target='_blank'>
								<Image src='/pdf/annual-reports/2008_Annual_Report.png' alt=''  size='medium' centered />
							</a>
						</Grid.Column>
						<Grid.Column>
							<a href='/pdf/annual-reports/2007_Annual_Report.pdf' target='_blank'>
								<Image src='/pdf/annual-reports/2007_Annual_Report.png' alt=''  size='medium' centered />
							</a>
						</Grid.Column>
					</Grid>
					<hr className='rule-blue-300 mt-80-60-40 mb-65-55-25' />
					<Grid columns={2} className='mb-80-60-40'>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                            <h3>Alliance of Genetic Support Groups Annual Reports (1998-1991)</h3>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                            <p className='subtitle'>
								In the years we were known as the Alliance of Genetic Support Groups, we compiled annual reports from 1991 to 1998. These reports are provided below.
                            </p>
                        </Grid.Column>
                    </Grid>
					<Grid columns={4} padded stackable>
						<Grid.Column>
							<a href='/pdf/annual-reports-archive/1998annual.pdf' target='_blank'>
								<Image src='/pdf/annual-reports-archive/AnnualReport1998.png' alt=''  size='medium' centered />
							</a>
						</Grid.Column>
						<Grid.Column>
							<a href='/pdf/annual-reports-archive/1997annual.pdf' target='_blank'>
								<Image src='/pdf/annual-reports-archive/AnnualReport1997.png' alt=''  size='medium' centered />
							</a>
						</Grid.Column>
						<Grid.Column>
							<a href='/pdf/annual-reports-archive/1996annual.pdf' target='_blank'>
								<Image src='/pdf/annual-reports-archive/AnnualReport1996.png' alt=''  size='medium' centered />
							</a>
						</Grid.Column>
						<Grid.Column>
							<a href='/pdf/annual-reports-archive/1995annual.pdf' target='_blank'>
								<Image src='/pdf/annual-reports-archive/AnnualReport1995.png' alt=''  size='medium' centered />
							</a>
						</Grid.Column>
						<Grid.Column>
							<a href='/pdf/annual-reports-archive/1994annual.pdf' target='_blank'>
								<Image src='/pdf/annual-reports-archive/AnnualReport1994.png' alt=''  size='medium' centered />
							</a>
						</Grid.Column>
						<Grid.Column>
							<a href='/pdf/annual-reports-archive/1993annual.pdf' target='_blank'>
								<Image src='/pdf/annual-reports-archive/AnnualReport1993.png' alt=''  size='medium' centered />
							</a>
						</Grid.Column>
						<Grid.Column>
							<a href='/pdf/annual-reports-archive/1992memberreport.pdf' target='_blank'>
								<Image src='/pdf/annual-reports-archive/MemberReport1992.png' alt=''  size='medium' centered />
							</a>
						</Grid.Column>
						<Grid.Column>
							<a href='/pdf/annual-reports-archive/1992annual.pdf' target='_blank'>
								<Image src='/pdf/annual-reports-archive/AnnualReport1992.png' alt=''  size='medium' centered />
							</a>
						</Grid.Column>
						<Grid.Column>
							<a href='/pdf/annual-reports-archive/1991annual.pdf' target='_blank'>
								<Image src='/pdf/annual-reports-archive/AnnualReport1991.png' alt=''  size='medium' centered />
							</a>
						</Grid.Column>
					</Grid>
					<hr className='rule-blue-300 mt-80-60-40 mb-65-55-25' />
					<p>Genetic Alliance annual reports from 2021 to 2011 are available {' '}
						<Link to='/about/annual-reports' rel='noreferrer'>
							here
						</Link>
					.</p>
                </Container>
            </div>
        </>
    )
}

export default AnnualReportsArchive