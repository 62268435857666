import { Container, Grid, GridColumn, Image, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import './AnnualReports.css'

function AnnualReportsFillContent({ data }) {

	if (!data) {
		return null
	}
	const listDatas = []
	const datas = data.filter(item => item.link === '/about/annual-reports')
	if (datas && Array.isArray(datas) && datas.length > 0) {
		datas.sort((a, b) => a.index - b.index)
		datas.forEach((item, index) => {

			const buildContent = (
				<GridColumn key={index}>
					<a href={item.href} target='_blank' rel="noreferrer">
						<Image src={item.imageSrc} alt="" size='medium' centered />
					</a>
				</GridColumn>
			)
			listDatas.push(buildContent);
		})
	}

	return (
		<Container className='annual-reports container-main'>
			<h1 className='mb-50'>Genetic Alliance Reports 2021 - 2011</h1>
			<hr className='rule-orange-500 mt-80-60-40 mb-65-55-25' />
			<Grid columns={4} padded stackable>
				{listDatas}
			</Grid>
			<hr className='rule-blue-300 mt-80-60-40 mb-65-55-25' />
			<Grid columns={2} className='mb-80-60-40'>
				<Grid.Column mobile={16} tablet={8} computer={8} className='pb-0'>
					<h3>Annual Reports Archive</h3>
				</Grid.Column>
				<Grid.Column mobile={16} tablet={8} computer={8}>
					<p>Visit our archive to view Genetic Alliance annual reports from 1991 to 2010.</p>
					<Link to='/about/archives-and-history/annual-reports' rel='noreferrer' className='link-button'>
						<Button className='button-neutral-100'>
							Annual Reports Archive
							<Image src='/icons/link-internal-blue.png' className='icon-width-16px' />
						</Button>
					</Link>
				</Grid.Column>
			</Grid>
		</Container>
	)
}

export default AnnualReportsFillContent
