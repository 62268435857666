import { useEffect, useState } from 'react'
import { Button } from 'semantic-ui-react'
import { downloadFileGenerateById, downloadFileUploadedByPath, getApplicationFiles } from 'actions/application'
import './Popup.css'

function DownloadApplicationFiles({ applicationId }) {
	const [data, setData] = useState([])

	useEffect(() => {
		getApplicationFiles(applicationId).then(res => {
			setData(res)
			console.log('res', res)
		})
	}, [applicationId])

	function downloadFileByPath(pathFile, fileName) {
		console.log('pathFile1', pathFile)
		downloadFileUploadedByPath(pathFile, fileName)
	}

	return (
		<>
			<div className="popup-menu">
				<h3>List file</h3>
				{/* <div className=".header">List file</div> */}
				{data && data.map(item => (
					// <li key={item._id}>
					// 	<a onClick={() => downloadFileByPath(item.pathFile, item.fileName)}>{item.fileName}</a>
					// </li>
					<div className="popup-menu-item" onClick={() => downloadFileByPath(item.pathFile, item.fileName)}>
						{item.fileName}
					</div>
				))}
			</div>
			<Button
				className='button-neutral-100 mt-25'
				onClick={() => downloadFileGenerateById(applicationId)}
			>
				Download file generate
			</Button>
			{/* <button onClick={() => downloadFileGenerateById(applicationId)}>Download file generate</button> */}
		</>
	)
}

export default DownloadApplicationFiles