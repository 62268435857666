import {
	Container,
	Grid,
	GridColumn,
	Image,
	Button,
	Icon,
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import ShareSocialMedia from '../../../../ShareSocialMedia'
import './AdvocacyATLAS.css'

function AdvocacyATLAS() {
	const shareUrl =
		'https://geneticalliance.org/resources-and-information/advocacy-atlas/'
	return (
		<Container className='container-main'>
			<ShareSocialMedia shareUrl={shareUrl} />
			<Link to='/resources-and-information/advocacy-atlas/about'>
				<Image
					className='mt-40'
					src='/images/resources-and-information/advocacy-atlas/advocacyATLAS.png'
					alt='Advocacy ATLAS'
					fluid
				/>
			</Link>
			<Grid columns={1} padded>
				<GridColumn>
					<nav
						id='navigation-atlas'
						style={{
							background: `url('/images/resources-and-information/advocacy-atlas/bg-nav-atlas.png')`,
						}}>
						<ul>
							<Link to='/resources-and-information/advocacy-atlas/access-healthcare'>
								<li class='menu-1077'>Access to Healthcare</li>
							</Link>
							<Link to='/resources-and-information/advocacy-atlas/accessible-communities'>
								<li class='menu-1078'>Accessible Communities</li>
							</Link>
							<Link to='/resources-and-information/advocacy-atlas/advocacy-leadership-skills'>
								<li class='menu-1084'>Advocacy &amp; Leadership Skills</li>
							</Link>
							<Link to='/resources-and-information/advocacy-atlas/communicating-health'>
								<li class='menu-1082'>Communicating About Your Health</li>
							</Link>
							<Link to='/resources-and-information/advocacy-atlas/education-services'>
								<li class='menu-1075'>Education Services &amp; Supports</li>
							</Link>
							<Link to='/resources-and-information/advocacy-atlas/financial-assistance'>
								<li class='menu-1076'>Insurance &amp; Financial Assistance</li>
							</Link>
							<Link to='/resources-and-information/advocacy-atlas/legislation-action'>
								<li class='menu-1083'>Legislation &amp; Political Action</li>
							</Link>
							<Link to='/resources-and-information/advocacy-atlas/employment-success'>
								<li class='menu-1085'>Steps to Employment Success</li>
							</Link>
							<Link to='/resources-and-information/advocacy-atlas/transition-adulthood'>
								<li class='menu-1080'>Transition to Adulthood</li>
							</Link>
							<Link to='/resources-and-information/advocacy-atlas/youth-leadership'>
								<li class='menu-1081'>Youth Leadership</li>
							</Link>
						</ul>
					</nav>
					<div className='text-center mt-30'>
						<Button className='orange-button'>
							<a
								href='https://docs.google.com/forms/d/e/1FAIpQLSe0mGehXFROKZ5BTtN_qLDK7v44kLmyV-Mke539zGfMOrulrQ/viewform'
								target='_blank'
								rel='noreferrer'
								style={{ color: '#fff' }}>
								Was this helpful for you? Submit Feedback &nbsp;&nbsp;
								<Icon name='arrow right' />
							</a>
						</Button>
					</div>

					<p className='text-small mt-50'>
						Advocacy ATLAS was developed by Genetic Alliance in partnership with
						Parent to Parent USA and Family Voices with support from the Health
						Resources and Services Administration (HRSA), Grant No. U22MC04100,
						National Coordinating Center for the Regional Genetic Services
						Collaboratives. This project was funded in part by the Maternal and Child
						Health Bureau, Health Resources and Services Administration (HRSA) Grant
						No. U35MC16451, Congenital Conditions Program, in partnership with The Arc
						and Family Voices. The content of this webpage is solely the
						responsibility of Genetic Alliance and does not necessarily represent the
						official views of HRSA.{' '}
					</p>
					<Image
						className='mt-40'
						src='/images/resources-and-information/advocacy-atlas/atlas-bot-logos.jpg'
						alt='Advocacy ATLAS'
						size='big'
						centered
					/>
				</GridColumn>
			</Grid>
		</Container>
	)
}

export default AdvocacyATLAS
